import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';
// import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NoSsr from '@material-ui/core/NoSsr';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';

import WarningMessage from './MessageSnackbar';

// import { handleOrganismSelect } from '../actions/organismActions';

const module = "genes";
const moduleTable = "table"
const TO_RETURN = 50;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // height: '100%', // should 100% to properly fix
    // marginBottom: 25, // otherwise collide

    paddingTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    boderColor: '#009688'
  },
  select: {

    boderColor: '#009688',
  },
  input: {
    display: 'flex',
    padding: 0,
    boderColor: '#009688'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 9999,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const NoOptionsMessage = (props) => {
  return (
    <div>
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        Enter 2 characters to display possible options
      </Typography>
      {/* Enter 2 characters.  */}
    </div>
  );
}

// const inputComponent = ({ inputRef, ...props }) => {
//   return <div ref={inputRef} {...props} />;
// }

// const Control = (props) => {
//   return (
//     <TextField
//       fullWidth
//       InputProps={{
//         inputComponent,
//         inputProps: {
//           className: props.selectProps.classes.input,
//           inputRef: props.innerRef,
//           children: props.children,
//           ...props.innerProps,
//         },
//       }}
//       {...props.selectProps.textFieldProps}
//     />
//   );
// }

const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

const Placeholder = (props) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const SingleValue = (props) => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

const ValueContainer = (props) => {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const MultiValue = (props) => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Menu = (props) => {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  // Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};


const GeneSelector = (props) => {
  
  // const { fetchGenes } = props;
  // useEffect(() => {
    // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!', 'Homo sapiens')
  //   props.fetchGenes('Homo sapiens');
  // }, [])
  
  const [openWarningMessage, setOpenWarningMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [placeholder, setPlaceholder] = useState("Select genes")
  const [count, setCount] = useState(0)

  useEffect(() => {
    // debugger
    if (props.parent === 'welcome page, from RNA' && count === 0) {
      if (props.selectedItemOrganism[0]['label'] === props.organism) {
        props.fetchGenes(props.selectedItemOrganism[0]['label']);
        setCount(1)
      }
    } else {
      props.fetchGenes(props.selectedItemOrganism[0]['label']);
    }
  }, [props.selectedItemOrganism])



  const handleChange = (value) => {
    if (props.parent === 'welcome page, from DNA' || props.parent === 'BasicFromRna') {
      if (props.selectedItem.length === 0 || (props.selectedItem.length === 1 && value.length === 0)) {
        props.handleGeneSelect(value)
      } else {
        setOpenWarningMessage(true)
        if (props.parent === 'welcome page, from DNA') {
          setMessage('You can only choose one gene')
        } else {
          setMessage('You can only choose one RNA')
        }
      }
    } else {
      props.handleGeneSelect(value)
    }
  };

  const getSuggestions = () => {
    // return this.props.suggestions.filter(suggestion => suggestion.includes())
    if (props.currentInput.length >= 2) {
      return props.suggestions.filter(suggestion => suggestion.label.toLowerCase().startsWith(props.currentInput.toLowerCase())).slice(0, TO_RETURN);
    } else {
      return [];
    }
  }

  const handleInputChange = (value) => {
    // console.log('selectedItem',props.selectedItem)
    // if (props.selectedItem.length !== 1) {
    props.changeCurrentGeneInput(value);
    // }
  }

  const classes = useStyles();

  useEffect(() => {
    if (props.disabled) {
      props.handleGeneSelect([]);
    }
  }, [props.disabled]);

  useEffect(() => {
    // console.log('props.placeholderText',props.placeholderText)
    if (typeof(props.placeholderText) !== 'undefined') {
      setPlaceholder(props.placeholderText)
    }
  }, [props.placeholderText]);

  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      // height: '20px',
      boxShadow: 'none',
      border: state.isFocused ? "2px solid #009688 !important" : "solid 2px #CED4DA",
      borderColor: state.isFocused ? "#009688" : "#CED4DA",
      "&:hover": {
        borderColor: state.isFocused ? "#009688" : "#CED4DA"
      }
    })
  };

  const handleClose = () => {
    setOpenWarningMessage(false)
    setMessage('')
  }

  // let selector = null;
  // if (props.selectedItemOrganism[0]['label'] === props.organism) {
  //   console.log('********************')
  //   selector = 
  // }

  return (
    <div className={classes.root}>
    <NoSsr>
      <Select
        classes={classes} 
        // textFieldProps={{
        //   label: 'Genes',
        //   InputLabelProps: {
        //     shrink: true,
        //   },
        //   variant: 'outlined',
        // }}
        isDisabled={props.disabled}
        options={getSuggestions()}
        components={components}
        value={props.selectedItem}
        onChange={handleChange}
        placeholder={placeholder}
        isMulti
        onInputChange={handleInputChange}
        styles={customStyles}
      />
    </NoSsr>
    <WarningMessage open={props.snackOpen} severity={"warning"} message={props.snackText} />
    <WarningMessage open={openWarningMessage} severity={"warning"} handleClose={handleClose} message={message} />
  </div>
  );
}


const mapStateToProps = (state) => {
  return {
      suggestions: state[module].suggestions,
      // selectedItem: state[module].selectedItem,
      
      // currentInput: state[module].currentGeneInput,
      // loading: state[module].loadingGenes,
      snackOpen: state[module].snackOpen,
      snackText: state[module].snackText,
      selectedItemOrganism: state[moduleTable].selectedItemOrganism,
  };
};

export default connect(mapStateToProps)(GeneSelector);

