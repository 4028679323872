import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
// import { Link } from 'react-router-dom';

import HeatmapsDATA from '../components/plots/Heatmap_data_layout';
import Profile from '../components/plots/RNA_DNA_profile';
import DistanceDistribution from '../components/plots/DistanceDistribution';

import {submitCheckedTracksForProfile} from '../actions/plotsActions';
import {submitCheckedTracksForHeatmap,submitCheckedTracksForHeatmapComparative} from '../actions/plotsActions';
import {submitCheckedTracksForDistanceDistribution} from '../actions/plotsActions';
import { handleHeader } from '../actions/generalActions';

import WarningMessage from '../components/MessageSnackbar';

const module = "genes";
const module_plots = "plots";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    textAlign: 'center',
    paddingTop: '30px !important',
    // backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    color: 'black',
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  // paper: {
  //   //marginTop: 50,
  //   //margin: '0 auto',
  //   padding: 20,
  //   width: '100%',
  //   textAlign: 'center',
  //   marginBottom: 0,
  // },
  wideButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '90%',
    marginBottom: 25,
    marginTop: 25,
  },
}));

const GraphicalSummary = (props) => {

  const [value, setValue] = useState(0);
  const [trackNameAndColor, setTrackNameAndColor] = useState({})

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data_graphical_summary"));
    // console.log('GraphicalSummary data',data);
    if (Object.keys(data).includes('Intersection_genes_req')) {
      document.title = `Graphical summary: ${data.selectedGeneTypes.join(', ')}`;
      props.handleHeader(': Graphical Summary')
      props.submitCheckedTracksForDistanceDistribution({'tracks': data.tracks, 'Intersection_genes_req': data.Intersection_genes_req}, 'DNA_part', data.selectedGeneTypes, data.selectedNormalization)
      props.submitCheckedTracksForProfile({'tracks': data.tracks, 'Intersection_genes_req': data.Intersection_genes_req}, 'DNA_part', data.selectedGeneTypes, data.selectedNormalization)
      props.submitCheckedTracksForHeatmapComparative({'tracks': data.tracks, 'Intersection_genes_req': data.Intersection_genes_req}, 'DNA_part', data.selectedGeneTypes, data.selectedNormalization)
      props.submitCheckedTracksForHeatmap({'tracks': data.tracks, 'Intersection_genes_req': data.Intersection_genes_req}, 'DNA_part', data.selectedGeneTypes, data.selectedNormalization)
    } else { 
      document.title = `Graphical summary: ${data.selectedTrackNames.join(', ')}`;
      props.handleHeader(': Graphical Summary')
      props.submitCheckedTracksForDistanceDistribution(data.selectedTracks,data.typeOfContacts, data.selectedTrackNames, data.selectedNormalization)
      props.submitCheckedTracksForProfile(data.selectedTracks,data.typeOfContacts, data.selectedTrackNames, data.selectedNormalization)
      props.submitCheckedTracksForHeatmapComparative(data.selectedTracks,data.typeOfContacts, data.selectedTrackNames, data.selectedNormalization)
      props.submitCheckedTracksForHeatmap(data.selectedTracks,data.typeOfContacts, data.selectedTrackNames, data.selectedNormalization)
  }
  }, [])

  useEffect(() => {
    if (Object.keys(props.genomeWideDataProfile).length !== 0) {
      let trackNameAndColorDict = {}
      for (var organism in props.genomeWideDataProfile) {
        for (var RNA_track in props.genomeWideDataProfile[organism]) {
          if (RNA_track !== '-111') {
            trackNameAndColorDict[props.genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0]] = props.genomeWideDataProfile[organism][RNA_track]['color']
          }
        }
      }
      // console.log('trackNameAndColorDict',trackNameAndColorDict)
      setTrackNameAndColor(trackNameAndColorDict)
    }
  }, [props.genomeWideDataProfile])

  const handleChange = (event, value) => {
    setValue(value)
  };
  
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="Comparative heatmap" style={{ border: '1px solid rgb(224, 224, 224)'}}/>
          <Tab label="Genome-wide contacts heatmap" style={{ border: '1px solid rgb(224, 224, 224)'}}/>
          <Tab label="Contacts distribution" style={{ border: '1px solid rgb(224, 224, 224)'}}/>
          <Tab label="Distance distribution" style={{ border: '1px solid rgb(224, 224, 224)'}}/>
        </Tabs>
      {value === 1 &&
        <div>
          {/* <Typography variant="h5" component="h1">Genome-wide contacts heatmap</Typography> */}
          {!props.loadingHeatmap ?
          <div>
            <HeatmapsDATA plotType={'genomeWide'} plotType_2={'genomeWide'} genomeWideData={props.genomeWideData} trackNameAndColor={trackNameAndColor}/>
            <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', textAlign: "justify"}}>
              <ul>
                <li>This heatmap displays contacts density distribution over chromosomes for RNAs from the track.</li>
                <li><b>Y-axis</b>: the chromosomes on which selected in the track RNA(s) are localised.</li>
                <li><b>X-axis</b>: the chromosomes with which selected in the track RNA(s) interact.</li>
                <li>See details on the <a href="/tutorial#Graphical-Summary" target="_blank">"Tutorial" page</a></li>
              </ul>
            </Typography>
          </div>
          :
          <div className={classes.loading}>
              <LinearProgress color={"primary"} />
              <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
              </Typography>
          </div>
          }
          <WarningMessage open={props.snackOpen} severity={"error"} message={props.snackText} />
        </div>}
      {value === 0 &&
        <div>
          {!props.loadingHeatmapComparative ?
          <div>
            <HeatmapsDATA plotType={'Comparative'} plotType_2={'Comparative'} genomeWideData={props.genomeWideDataComparative} trackNameAndColor={trackNameAndColor}/>
            <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', textAlign: "justify"}}>
              <ul>
                <li>This heatmap displays contacts density distribution over chromosomes for several tracks.</li>
                <li><b>Y-axis</b>: track names.</li>
                <li><b>X-axis</b>: the chromosomes with which selected in the track RNA(s) interact.</li>
                <li>See details on the <a href="/tutorial#Graphical-Summary" target="_blank">"Tutorial" page</a></li>
              </ul>
            </Typography>
          </div>
          :
          <div className={classes.loading}>
              <LinearProgress color={"primary"} />
              <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
              </Typography>
          </div>
          }
          <WarningMessage open={props.snackOpenComparative} severity={"error"} message={props.snackTextComparative} />
        </div>}
      {value === 2 &&
        <div>
            {/* <Typography variant="h5" component="h1">Genome-wide contacts profile</Typography> */}
          {!props.loading ?
          <div>
            <Profile trackNameAndColor={trackNameAndColor}/>
            <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', textAlign: "justify"}}>
              <ul>
                <li>This plot displays genome-wide contacts density distribution (genome bin size = 1500000 bp).</li>
                <li><b>Y-axis</b>: contacts density.</li>
                <li><b>X-axis</b>: the chromosomes with which selected in the track RNA(s) interact.</li>
                <li>See details on the <a href="/tutorial#Graphical-Summary" target="_blank">"Tutorial" page</a></li>
              </ul>
            </Typography>
          </div>
          :
          <div className={classes.loading}>
              <LinearProgress color={"primary"} />
              <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
              </Typography>
          </div>
          }
          <WarningMessage open={props.snackOpenGenomeWide} severity={"error"} message={props.snackTextGenomeWide} />
        </div>}
      {value === 3 &&
        <div>
          {/* <Paper className={classes.paper}> */}
          {/* <Typography variant="h5" component="h1">Distance distribution</Typography> */}
          {!props.loadingDistanceDistribution ?
          <div>
            <DistanceDistribution trackNameAndColor={trackNameAndColor}/>
            <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', textAlign: "justify"}}>
              <ul>
                <li>This line chart displays the contact density depending on the distance between the RNA-part of the contact and chromatin loci (on the same chromosome as the corresponding RNA gene). The interaction of RNA with its source gene is not included in the analysis.</li>
                <li><b>Y-axis 1</b>: Log2(Count RNAs) - the number of unique RNAs that contact with chromatin at an appropriate distance from their RNA-part of the contact.</li>
                <li><b>Y-axis 2</b>: Log2(Density).</li>
                <li><b>X-axis</b>: Log2(Distance between RNA gene(s) and loci).</li>
                <li>See details on the <a href="/tutorial#Graphical-Summary" target="_blank">"Tutorial" page</a></li>
              </ul>
            </Typography>
          </div>
          :
          <div className={classes.loading}>
              <LinearProgress color={"primary"} />
              <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
              </Typography>
          </div>
          }
          <WarningMessage open={props.snackOpenDistanceDistribution} severity={"error"} message={props.snackTextDistanceDistribution} />
          {/* </Paper> */}
        </div>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      //trackList: state[module].trackList,
      selectedTracks: state[module].selectedTracks,
      typeOfContacts: state[module].typeOfContacts,
      
      snackOpenGenomeWide: state[module].snackOpenGenomeWide,
      snackTextGenomeWide: state[module].snackTextGenomeWide,
      loading: state[module_plots].loadingGenomeWideData,

      genomeWideData: state[module_plots].genomeWideData,
      snackOpen: state[module_plots].snackOpen,
      snackText: state[module_plots].snackText,
      loadingHeatmap: state[module_plots].loadingGenomeWideHeatmap,

      genomeWideDataProfile: state[module_plots].genomeWideDataProfile,

      genomeWideDataComparative: state[module_plots].genomeWideDataComparative,
      snackOpenComparative: state[module_plots].snackOpenComparative,
      snackTextComparative: state[module_plots].snackTextComparative,
      loadingHeatmapComparative: state[module_plots].loadingGenomeWideHeatmapComparative,

      snackOpenDistanceDistribution: state[module].snackOpenDistanceDistribution,
      snackTextDistanceDistribution: state[module].snackTextDistanceDistribution,
      loadingDistanceDistribution: state[module_plots].loadingDistanceDistribution,
  }
}

// const GraphicalSummaryContainer = connect(mapStateToProps, {submitCheckedTracksForHeatmap,submitCheckedTracksForProfile,submitCheckedTracksForDistanceDistribution})(GraphicalSummary);
// export default GraphicalSummaryContainer;
export default connect(mapStateToProps, {submitCheckedTracksForHeatmap,submitCheckedTracksForHeatmapComparative,
  submitCheckedTracksForProfile,submitCheckedTracksForDistanceDistribution, handleHeader})(GraphicalSummary);