import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import ReactPlayer from 'react-player/youtube'
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
// import MUIDataTable from "mui-datatables";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import LinearProgress from '@material-ui/core/LinearProgress';

// import 'katex/dist/katex.min.css';
// import { BlockMath } from 'react-katex';

import { allExpsRequest, handleHeader } from '../actions/generalActions';

// import Contents from '../components/Contents'
// import TableMetadata from '../components/TableMetadata';
// import WarningMessage from '../components/MessageSnackbar';

import basicWorkflowNew from './svg/basicWorkflowNew.svg'
// import Fig1 from './svg/Fig1.svg'
// import important from './svg/important.png'

// import hm1 from './svg/hm1.svg'
import hm2 from './svg/hm2.svg'
import hm2Table from './svg/hm2Table.svg'
import ContactsDistribution from './svg/ContactsDistribution.svg'
import ContactsDistributionTable from './svg/ContactsDistributionTable.svg'
import ContactsDistributionTargetLocus from './svg/ContactsDistributionTargetLocus.svg'
import ContactsDistributionTargetLocusTable from './svg/ContactsDistributionTargetLocusTable.svg'

import hm4 from './svg/hm4.svg'
import hm4_non_centric from './svg/hm4_non_centric.svg'
import GB_plot from './svg/GB_plot.svg'
import hm4Table from './svg/hm4Table.svg'
// import fig7 from './svg/fig7.svg'

import metadata_information from './svg/metadata_information.svg'
import RNAs_distribution from './svg/RNAs_distribution.svg'

// import RNAdistribution from './svg/RNAdistribution.png'
// import download from './svg/download.png'

import one_to_all_method from './svg/one_to_all_method.svg'
import all_to_all_method from './svg/all_to_all_method.svg'
import upstream_downstream_DB from './svg/upstream_downstream_DB.svg'

import RNA_parts_of_contacts_profile from './svg/RNA_parts_of_contacts_profile.svg'
import cpkm from './svg/cpkm.svg'
import Comparative_HeatMap from './svg/Comparative_HeatMap.svg'
import genomeWideProfile from './svg/genomeWideProfile.svg'
import DistanceDistribution from './svg/DistanceDistribution.svg'


// import header from './svg/header.png'
import Number_of_contacts_distribution from './svg/Number_of_contacts_distribution.svg'
import Pie from './svg/Pie.svg'
import processing_data from './svg/processing_data.svg'
import processing_data_2 from './svg/processing_data_2.svg'

import AllTargetGenesRNAinfo from './svg/AllTargetGenesRNAinfo.svg'
import AllTargetGenesExample from './svg/AllTargetGenesExample.svg'
import AllTargetGenesTable from './svg/AllTargetGenesTable.svg'

import ContactsSummaryRNAinfo from './svg/ContactsSummaryRNAinfo.svg'
import ContactsSummary from './svg/ContactsSummary.svg'
import table_rna_from_rna from './svg/table_rna_from_rna.svg'
import table_rna_from_dna from './svg/table_rna_from_dna.svg'

import RNApartsDistrFromRNA from './svg/RNApartsDistrFromRNA.svg'
import RNApartsDistrFromDNA from './svg/RNApartsDistrFromDNA.svg'
import RNApartsDistrFromRNAtable from './svg/RNApartsDistrFromRNAtable.svg'
import RNApartsDistrFromDNAtable from './svg/RNApartsDistrFromDNAtable.svg'
import RNApartsDistrScheme from './svg/RNApartsDistrScheme.svg'

import downloadPlot from './svg/downloadPlot.png'
import downloadDataPlot from './svg/downloadDataPlot.png'

const module = "table"

const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        // padding: 50,
        // paddingTop: 70,
        ...theme.mixins.gutters(),
        // paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        //width: '40%',
        // textAlign: 'center',
        flexGrow: 1,
        // width: '96%',
        marginTop: theme.spacing(7),
    },
    title: {
        marginTop: 30,
        // marginBottom: 0,
        // textAlign: 'center !important',
        marginLeft: 23,
        textAlign: 'left',
        },
    divider: {
        marginTop: 20,
        marginBottom: 20,
        // margin: '10px auto',
        width: '100%',
        height: 2,
        backgroundColor: 'black',
      },
    link: {
      color: 'rgb(0, 150, 136)',
      '&:hover': {
        color: 'grey',
      }
    },
    heading: {
        // fontSize: theme.typography.pxToRem(15),
        flexBasis: '70%',
        flexShrink: 0,
      },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
    leftIcon: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        color: 'white',
        '&:hover': {
          textDecoration: 'none',
          color: 'grey',
        },
        //textAlign: 'right',
      },
}));

const OverviewBasic = (props) => {

//   var Latex = require('react-latex');
  const classes = useStyles();
  document.title = "Database overview";
  
  const { AllExps } = props;

  const [expanded, setExpanded] = useState('panel2')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  };

  useEffect(() => {
    props.handleHeader(': overview')
    // props.allExpsRequest();
  }, [])

  const handleClick = (event) => {
    window.open("/protocol", "_blank");
  };

  return (
    <div className={classes.root}>
        {/* <Grid container spasing={1} style={{marginTop: 20, flexWrap: 'nowrap'}}>
            <Grid item xs={1}>
                <Contents />
            </Grid>
            <Grid item xs={11} style={{width: `calc(100% - 200px)`, marginLeft: 120}}> */}
                <TableContainer style={{maxHeight: window.innerHeight*0.9}}>
                        <Typography variant="h5" id="RNA-chromatin-interactions-data" style={{fontWeight: 'bold', textAlign: "center", marginBottom: 15, marginTop: 10}}>
                            RNA-chromatin interactions data
                        </Typography>
                        <Typography variant="subtitle1" >
                            &emsp;&emsp;Many experimental techniques were recently developed to study the mechanisms whereby ncRNAs interact with chromatin. 
                            The techniques can be classified into two groups. 
                            One detects <b>the interactions of a particular RNA with all chromatin loci</b> (<span style={{fontWeight: 'bold'}}>"one RNA to all DNA loci"</span> or <span style={{fontWeight: 'bold'}}>"one-to-all"</span> methods, figure 1) and reports the binding sites of a particular RNA in the total genome, yielding a contact map. 
                            Methods of the other group detect <b>the interactions of all RNAs with all DNA loci</b> (<span style={{fontWeight: 'bold'}}>"all RNAs to all DNA loci"</span> or <span style={{fontWeight: 'bold'}}>"all-to-all"</span> methods, figure 2) and report all potential RNA–chromatin interactions in the cell.
                        </Typography>
                        <Grid container spasing={3}>
                            <Grid item xs={6} style={{marginTop: 15}}>
                                <div style={{marginLeft: 1, width: '99%'}}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{backgroundColor: '#b8b9ba'}}
                                        >
                                            <Typography variant={"subtitle1"}><b>One-to-all</b> methods (figure 1)</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>                                           
                                            <Typography variant="center" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                                                <ul>
                                                    <li>RAP, CHART-seq, ChIRP-seq, dChIRP-seq, ChOP-seq and CHIRT-seq</li>
                                                </ul>
                                                <img src={one_to_all_method} alt="one_to_all_method" style={{border: '1px solid #000000', backgroundColor: 'white'}} width='100%'/>
                                                <i>Fig. 1. A general workflow of "one-to-all" protocols [<a href="https://pubmed.ncbi.nlm.nih.gov/35403620/" target="_blank" rel="noopener noreferrer">PMID: 35403620</a>].</i>
                                            </Typography>      
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{marginTop: 15}}>
                                <div style={{marginLeft: 1, width: '99%'}}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{backgroundColor: '#b8b9ba'}}
                                        >
                                            <Typography variant={"subtitle1"}><b>All-to-all</b> methods (figure 2)</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>                                           
                                            <Typography variant="center" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                                                <ul>
                                                    <li>MARGI, GRID-seq, ChAR-seq, iMARGI, RADICL-seq, Red-C</li>
                                                </ul>
                                                <img src={all_to_all_method} alt="all_to_all_method" style={{border: '1px solid #000000', backgroundColor: 'white'}} width='100%'/>
                                                <i>Fig. 2. A general workflow of "all-to-all" protocols. Upper panel: GRID, RED-C, and RADICL (EcoP15I was used in place of MmeI in RADICL). Lower panel: MARGI and iMARGI (only the steps that follow reverse transcription are shown; the prior steps are the same as in GRID, RED-C, and RADICL) [<a href="https://pubmed.ncbi.nlm.nih.gov/35403620/" target="_blank" rel="noopener noreferrer">PMID: 35403620</a>].</i>
                                            </Typography>      
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle1" style={{marginBottom: 15, marginTop: 15}}>
                            &emsp;&emsp;All available genome-wide <b>RNA-DNA interaction data</b> was downloaded from the <a href="https://www.ncbi.nlm.nih.gov/geo/" target="_blank">Gene Expression Omnibus</a> and processed using a universal pipeline (details on the
                            <Button 
                                className={classes.leftIcon} 
                                variant="outlined"
                                size="small"
                                style={{backgroundColor: '#00695c'}} 
                                onClick={e => handleClick(e)}
                            >
                             "data processing" page   
                            </Button>
                            ).
                            The <span style={{color: 'red',fontWeight: 'bold'}}>RNA-Chrom</span> database <a href="/experiments" target="_blank" rel="noopener noreferrer">contains</a> data for more than 50 human and mouse RNAs (in the case of <b>"one-to-all"</b> methods) and for thousands of RNAs in the case of <b>"all-to-all"</b> methods. 
                            To find out detailed information on a particular experiment, click on <b>"Exp.ID"</b> and go to <b>"Metadata"</b> page.
                            {/* <a href="/protocol" target="_blank" rel="noopener noreferrer"><b>"data processing" page</b></a> */}
                            {/* <br /> */}
                            {/* &emsp;The <a href="/experiments" target="_blank" rel="noopener noreferrer"><b>"Database content"</b></a> page contains complete information about all database data.
                            If you want to know detailed information (<b>Metadata page</b>) of a specific dataset, you should click on <b>ID</b> link. 
                            You can find out the details of data processing <a href="/protocol" target="_blank" rel="noopener noreferrer">here</a>. */}
                        </Typography>
                        <div style={{marginBottom: 10, marginLeft: 1, width: '99%'}}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{backgroundColor: '#b8b9ba'}}
                            >
                                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Metadata page</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                <div style={{flexGrow: 0}}>
                                    <Grid container spasing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
                                                <ul>
                                                    <li style={{marginLeft: 20}}><b>All metadata information on a particular experiment:</b></li>
                                                    <img src={metadata_information} alt="metadata_information" style={{border: '1px solid #000000'}} width='100%'/>
                                                </ul>
                                            </Typography>
                                            <div style={{marginLeft: 40}}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{backgroundColor: '#b8b9ba'}}
                                                    >
                                                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about this table:</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                    <ul>
                                                        <li><b>Exp.ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database.</li>
                                                        <li><b>Data type</b> — "one-to-all" or "all-to-all".</li> 
                                                        <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                        <li><b>RNA name (RNA-Chrom DB</b>) — unique RNA name used in the database.</li>
                                                        <li><b>RNA type</b> — taken from the corresponding gene annotation.</li>
                                                        <li><b>Number of peaks (article)</b> — number of genome regions statistically significantly enriched in contacts of the RNA of interest with chromatin, which was obtained in the article.</li>
                                                        <li><b>Number of peaks (MACS2)</b> — number of genome regions statistically significantly enriched in contacts of the RNA of interest with chromatin, which was obtained by us using the MACS2 program (more details in the section <a href="/protocol" target="_blank" rel="noopener noreferrer">"Contacts normalization"</a>).</li>
                                                        <li><b>Reads in peaks (% of BlackList step)</b> — number of reads (not-normalized contacts) that intersect the MACS2 peaks in the corresponding experiment. The percentage of "Reads in peaks" of the total number of reads that passed the last processing step (BlackList) is indicated in brackets.</li>
                                                        <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                        <li><b>Cell type / Tissue</b> — the cell type / tissue on which the experiment was performed.</li>
                                                        <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                        <li><b>Organism</b></li>
                                                        <li><b>RNA name (gencode v35</b>) — RNA name used in GENCODE gene annotation.</li>
                                                        <li><b>RNA name (article)</b> — RNA name used in the article.</li>
                                                        <li><b>Synonyms</b> — alternative names for the RNA (the gene that encodes it.)</li>
                                                        <li><b>Full name RNA</b> — the full name is taken either from the article or from 
                                                            <a href="https://www.genecards.org/" target="_blank" rel="noopener noreferrer">"GeneCards: The Human Gene Database"</a> (for human RNAs) / 
                                                            <a href="http://www.informatics.jax.org/" target="_blank" rel="noopener noreferrer">"Mouse Genome Informatics"</a> (for mouse RNAs).</li>
                                                        <li><b>Crosslinking agent</b> — fixes RNA-DNA interactions.</li>
                                                        <li><b>Year</b> of publication of the article.</li>
                                                        <li><b>Article</b> name.</li>
                                                        <li><b>DOI</b></li>
                                                        <li><b>GSM</b> — samples.</li>
                                                        <li><b>GSE</b> — the NCBI GEO accession number for RNA-chromatin data reported in this paper.</li>
                                                        <li><b>Processing protocol</b> — is usually "default", but may have small changes in special cases.</li>
                                                        <li><b>Library layout</b> of RNA-chromatin data.</li>
                                                        <li><b>Genome version</b> — assembly version of the genome to which the reads were mapped.</li>
                                                    </ul>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
                                                <ul>
                                                    <li style={{marginLeft: 20}}><b>RNAs distribution:</b></li>
                                                    <img src={RNAs_distribution} alt="RNAs_distribution" style={{border: '1px solid #000000'}} width='100%'/>
                                                    <Typography variant="subtitle1" style={{ textAlign: "center"}}>
                                                        <i><b>"RNAs in the experiment"</b> displays the amount of RNAs of each type that interact with chromatin in the corresponding experiment. <b>"Annotated RNAs"</b> displays the amount of RNAs of each type available in the annotation of our database.</i>
                                                    </Typography>
                                                </ul>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{marginTop: 5, marginBottom: 5, width: '100%', height: 2, backgroundColor: 'black'}}/>
                                    <Grid container spasing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
                                            <ul>
                                                <li style={{marginLeft: 20}}><b>Shares of different RNA types in the total number of contacts (%):</b></li>
                                                <img src={Pie} alt="Pie" style={{border: '1px solid #000000'}} width='100%'/>
                                                <Typography variant="subtitle1" style={{ textAlign: "center"}}>                                               
                                                    <i>This bar chart displays only those RNA types that have a proportion {'>'} 0.5% of the total number of contacts. We can notice that in this particular experiment, protein coding RNA accounts for more than 74% of all RNA-chromatin interactions.</i>
                                                </Typography>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
                                            <ul>
                                                <li style={{marginLeft: 20}}><b>Distribution of the number of RNAs according to the number of contacts with the genome:</b></li>
                                                <img src={Number_of_contacts_distribution} alt="Number_of_contacts_distribution" style={{border: '1px solid #000000'}} width='100%'/>
                                                <Typography variant="subtitle1" style={{ textAlign: "center"}}>                                               
                                                    <i>This scatter plot displays the distribution of RNAs based on the number of contacts they make with chromatin. <b>RNAs were grouped by the number of contacts multiple of 10</b>. For example, the first point in the left on the scatter plot (without logarithm) is from 0 (not including) to 10 contacts (including), the second is from 10 (not including) to 20 contacts (including), etc. <b>The vertical dotted line</b> is drawn slightly to the right of <b>the median number of contacts</b>.</i>
                                                </Typography>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider style={{marginTop: 5, marginBottom: 5, width: '100%', height: 2, backgroundColor: 'black'}}/>
                                <Grid container spasing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
                                            <ul>
                                                <li style={{marginLeft: 20}}><b>Summary statistics of the data processing protocol</b> (for GSM4041591):</li>
                                                <img src={processing_data} alt="processing_data" style={{border: '1px solid #000000'}} width='100%'/>
                                                <Typography variant="subtitle1" style={{ textAlign: "center"}}>
                                                    <i>If the experiment contains multiple replicas, then the analogous summary will be displayed for each. 
                                                        The <b>"Processing steps"</b> column lists all data processing steps. 
                                                        <b>"N reads"</b> tells us how many reads left after the corresponding processing step and all the previous ones too (one-to-all: n-reads correspond to DNA-parts of contacts; all-to-all: n-reads correspond to RNA-DNA contacts, both RNA- and DNA-parts of which have passed the processing step). 
                                                        The last column shows the <b>percentage</b> of reads, processed in a particular stage, in relation to the initial number of reads in the data set.
                                                    </i>
                                                </Typography>
                                            </ul>
                                        </Typography>
                                        <div style={{marginLeft: 40}}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    style={{backgroundColor: '#b8b9ba'}}
                                                >
                                                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about processing step:</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                    <Typography variant="subtitle1" style={{ textAlign: "justify"}}>
                                                        <ul>
                                                            <li><b>N reads</b> (Raw data) — the initial number of reads in the data set.</li>
                                                            <li><b>N reads</b> (Remove duplicates) — number of reads left after the removal of possible PCR duplicates.</li> {/* after working out the program "Fastuniq" */}
                                                            <li><b>N reads</b> (Trimmomatic) — number of reads left after filtering out low-quality reads.</li>
                                                            <li><b>N reads</b> (Mapping) — number of reads left after unique mapping of reads on the genome.</li>
                                                            <li><b>N reads</b> (CIGAR filter) — number of contacts whose RNA-parts passed the appropriate filtering by the CIGAR field.</li>
                                                            <li><b>N reads</b> (BlackList) — number of contacts whose DNA-parts do not intersect with the BlackList annotation.</li>
                                                            <li><b>N reads</b> (Annotation of RNA-parts of contacts) — number of contacts for which a match was established between RNA-parts and genes from our general gene annotation.</li>
                                                            <li><b>N reads</b> (Singletons) — number of contacts for which no correspondence was established between RNA-parts and genes from our general gene annotation.</li>
                                                            <br/>See details of each processing step <a href="/protocol" target="_blank" rel="noopener noreferrer">here</a>.
                                                        </ul>
                                                        <b>N-reads</b> (BlackList) = <b>N-reads</b> (Annotation of RNA-parts of contacts) + <b>N-reads</b> (Singletons)
                                                        <br/><br/><b>Singletons</b> were not included in the database, but you can download them <a href="http://bioinf.fbb.msu.ru/rna_chrom/download/RNA-chromatin-interactions-data/All-to-all-data/" target="_blank" rel="noopener noreferrer">here</a>.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
                                                <ul>
                                                    <img src={processing_data_2} alt="processing_data_2" style={{border: '1px solid #000000'}} width='100%'/>
                                                    <Typography variant="subtitle1" style={{ textAlign: "center"}}>
                                                        <i>The bar chart shows the percentage of reads, processed in a particular stage, in relation to the initial number of reads in the data set.</i>
                                                    </Typography>
                                                </ul>
                                            </Typography>
                                    </Grid>
                                </Grid>
                                </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    {/* </Grid>
                    <Grid item xs={4}>
                        {!props.loadingAllExps && Object.keys(AllExps).length !== 0 ?
                        <div style={{marginTop: 20}}>
                            <TableMetadata 
                                title={'Database contain:'} 
                                data={AllExps['z']} 
                                columns={AllExps['x']}
                                />
                            <Typography variant="subtitle1" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                                <i>Table 1. Number of datasets in database</i>
                            </Typography>
                        </div>
                        :
                        <div> 
                            <LinearProgress color={"primary"} />
                            <Typography variant="h5">
                                Please, wait a while, it may take from a few seconds to a couple of minutes
                            </Typography>
                            <WarningMessage open={props.snackOpenAllExps} severity={"error"} message={props.snackTextAllExps} />
                        </div>
                        }
                    </Grid>
                </Grid> */}

                    <Divider className={classes.divider}/>
                        <Typography variant="h5" id="RNA-chrom-DB" style={{fontWeight: 'bold', textAlign: "center", marginBottom: 15, marginTop: 10}}>
                            The <span style={{color: 'red'}}>RNA-Chrom</span> database: its functionality and place in the scientific world
                        </Typography>
                        <Grid container spasing={3}>
                            <Grid item xs={6}>
                                <div style={{marginLeft: 1, width: '99%'}}>
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            style={{backgroundColor: '#b8b9ba'}}
                                        >
                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Upstream research</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                            <Typography variant="subtitle1">
                                                &emsp;&emsp;It is known that non-coding RNAs play an important role in the transcription regulation, remodelling and maintenance of the spatial structure of chromatin. 
                                                These processes can be studied, for example, by RNA-centric, chromatin-centric or protein-centric methods. 
                                                The RNA-centric method assumes the determination of RNA-chromatin interactions (contacts) by experimental approaches, such as "one-to-all" and "all-to-all".
                                                
                                                <br/>&emsp;&emsp;An important part and at the same time a disadvantage of the <b>one-to-all</b> approach is that the target RNA must be determined in advance. 
                                                If the interaction of a specific RNA with chromatin has not been established in earlier scientific work, it is possible to identify candidates using differential transcriptome analysis in the molecular biological process of interest to you, and then narrow this list by selecting only those RNAs that are predominantly localised in the cell nucleus. 
                                                After selecting RNA, you can study which RNA domains are responsible for interaction with chromatin (deletion analysis), with which genomic loci RNA interacts under normal or special conditions, e.g. after a heat shock, after a knockdown of the ncRNA partner protein, in different time points of cell differentiation, after additional cell treatment with the transcription elongation inhibitor flavopiridol or the bromodomain inhibitor JQ1.
                                                
                                                <br/>&emsp;&emsp;An advantage of the <b>"all-to-all"</b> over the "one-to-all" methods is that they do not require any prior knowledge about the sequences of the RNAs of interest and yield data on contacts with chromatin for all RNAs of the cell, which makes it possible to study and functionally characterise the entire groups of RNA.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2d-content"
                                            id="panel2d-header"
                                            style={{backgroundColor: '#b8b9ba'}}
                                        >
                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Database and real time analysis</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                            <Typography variant="subtitle1">
                                                &emsp;&emsp;<span style={{color: 'red'}}><b>RNA-Chrom</b></span> is a manually curated analytical database. It allows you not only to download data processed by an unified pipeline, but also to get a variety of analytical results for all data almost instantly. 
                                                These can be tables of RNAs contacting the entire genome ("from RNA") or the selected gene/locus ("from DNA"), with the corresponding characteristics of their contactability (item #1), as well as tables of genes with which one or another RNA contacts directly or in the vicinity of 50,000 nucleotides (item #3). 
                                                In addition to various analytical charts (items #2 and #5), <span style={{color: 'red'}}><b>RNA-Chrom</b></span> allows you to view contact maps in the <b><a href="https://genome.ucsc.edu/">UCSC Genome Browser</a></b>.
                                                <br/>&emsp;&emsp;Due to the special functionality of this resource, a user can move between "from RNA" and "from DNA" analyses, 
                                                thereby <b>performing the personalised real-time exploratory analysis</b> of RNA-chromatin data.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3d-content"
                                            id="panel3d-header"
                                            style={{backgroundColor: '#b8b9ba'}}
                                        >
                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Downstream analysis</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                            <Typography variant="subtitle1">
                                                &emsp;&emsp;In order to determine the functional role of RNA in the corresponding DNA locus, 
                                                additional genome-wide data are needed, for example, on the structure of chromatin, gene expression, 
                                                or the localisation of DNA-binding and chromatin-modifying proteins. <span style={{color: 'red'}}><b>RNA-Chrom</b></span> provides a variety of information on the interaction of RNA with chromatin, which can be used for comparative analysis or as a target for experimental refinements.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    {/* <Typography id='Upstream-research' variant={"h6"} style={{fontWeight: 'bold', textAlign: 'center'}}>
                                        Upstream research
                                    </Typography> */}
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{marginTop: 15}}>
                                <div style={{ marginBottom: 5, textAlign: 'left',border: '1px solid #000000'}}>
                                    <img src={upstream_downstream_DB} alt="upstream_downstream_DB" width='100%'/>
                                </div>
                                <Typography variant="subtitle1" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                                    <i><b>RNA-Chrom</b> functionality and place in the scientific world.</i>
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid container spasing={3}>
                            <Grid item xs={6}>
                                <div style={{marginLeft: 1, width: '99%'}}>
                                    <Typography variant={"h6"} style={{fontWeight: 'bold', textAlign: 'center'}}>
                                        Database and real time analysis
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{marginLeft: 1, width: '99%'}}>
                                    <Typography variant={"h6"} style={{fontWeight: 'bold', textAlign: 'center'}}>
                                        Downstream analysis
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid> */}
                    <Divider className={classes.divider}/>

                    <Typography id='Available-types-of-data-analysis' variant="h5" style={{fontWeight: 'bold', textAlign: "center", marginBottom: 20, marginTop: 10}}>
                        Available types of data analysis
                    </Typography>
                    <Grid container spasing={3}>
                        <Grid item xs={6} style={{marginBottom: 5}}>
                            <div style={{marginLeft: 1, width: '99%'}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            Complete table of RNAs
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                            <div style={{flexGrow: 0}}>
                                                <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>"from RNA"</b> analysis — a table with all RNAs (in this case <i>Homo sapiens</i>) that both contact and do not contact with chromatin.</li>
                                                        <img src={table_rna_from_rna} alt="table_rna_from_rna" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about this table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <ul>
                                                                    <li><b>RNA name</b> — unique RNA name used in the database.</li>
                                                                    <li><b>Total exps</b> — the total number of experiments ("one-to-all" and "all-to-all") that contain contacts of the corresponding RNA.</li>
                                                                    <li><b>max CPKM</b> — <b>for each "all-to-all" experiment</b> (if they have corresponding RNA contacts, otherwise "max CPKM" = 0) <b>the CPKM metric</b> is calculated and the maximum of them is displayed.</li>
                                                                    <li><b>max n-reads</b> — <b>for each "all-to-all" experiment</b> (if they have corresponding RNA contacts, otherwise "max n-reads" = 0) <b>the total number of contacts (n-reads)</b> is calculated and the maximum of them is displayed.</li>
                                                                    <li><b>Exps</b> — number of experiments ("one-to-all" or "all-to-all") that contain contacts of the corresponding RNA.</li>
                                                                    <li><b>RNA type</b> — matches the gene type, taken from the corresponding gene annotation.</li>
                                                                    <li><b>Gene location</b> — gene coordinates of the corresponding RNA.</li>
                                                                    <li><b>Length</b> — the length of the RNA, which is equal to the length of its source gene (we do not consider transcripts).</li>
                                                                    <li><b>Str.</b> — gene strand.</li>
                                                                    <li><b>Ann.source</b> — source of the gene annotation. The list of all sources can be found in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Annotation of RNA-parts of contacts"</a> section.</li>
                                                                    <img src={cpkm} alt="cpkm" style={{marginTop: 15, border: '1px solid #000000'}} width='100%'/>
                                                                    <b>CPKM</b> is the number of contacts divided by library size (sum of all contacts in the experiment) and RNA length.
                                                                </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                                                working with the table:
                                                            </Typography>
                                                        </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <div style={{flexGrow: 0}}>
                                                                        <ul>
                                                                            <li><span style={{color: 'red'}}><b>go to "Graphical Summary"</b></span> — by clicking on a certain "RNA name" you will be redirected to the "Graphical Summary" page.</li>
                                                                            <li><b>Select the normalization</b> by which the metrics will be calculated ("max CPKM", "max n-reads", "Exps").</li>
                                                                            <li><b>Sort the table</b> by the values of a particular column.</li>
                                                                            <li><b>Filter</b> records in table using the "APPLY FILTERS" button and the following text fields:</li>
                                                                            <ul>
                                                                                <li><b>Select RNAs</b> — the table will display only selected RNAs.</li>
                                                                                <li><b>Select RNA types</b> — the table will display only those RNAs that belong to the selected RNA types.</li>
                                                                                <li><b>Select annotation source</b> — the table will display only those RNAs that belong to the selected annotation sources.</li>
                                                                                <li><b>Select organism</b> — the table will display only those RNAs that belong to the selected organism.</li>
                                                                                <li><b>Search by "RNA name"</b> — the table will display only those RNAs that match the search pattern or include it.</li>
                                                                                <li><b>Enter genomic locus or loci</b> — the table will display only those RNAs whose source genes are intersected by the selected locus/loci.</li>
                                                                                <li><b>Minimum gene length</b> — the table will display only those RNAs whose genes are longer than or equal to the selected threshold.</li>
                                                                            </ul>
                                                                            <li><b>Download</b> the table <b>grouped</b> by RNA name (with applied filters) or full table in <b>ungrouped</b> format (without applied filters).</li>
                                                                        </ul>
                                                                </div>
                                                            </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <Divider style={{marginTop: 15, marginBottom: 5, width: '100%', height: 2, backgroundColor: 'black'}}/>
                                                <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>"from DNA"</b> analysis — a table with all RNAs (in this case <i>Mus musculus</i>) that contact with the selected gene/locus.</li>
                                                        <img src={table_rna_from_dna} alt="table_rna_from_dna" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about this table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>RNA name</b> — unique RNA name used in the database.</li>
                                                                <li><b>Total exps</b> — the total number of experiments ("one-to-all" and "all-to-all") that contain contacts of the corresponding RNA.</li>
                                                                <li><b>max CPKM</b> — <b>for each "all-to-all" experiment</b> that has corresponding RNA contacts with the target locus), <b>the CPKM metric</b> is calculated and the maximum of them is displayed.</li>
                                                                <li><b>max n-reads</b> — <b>for each "all-to-all" experiment</b> that has corresponding RNA contacts with the target locus), <b>the total number of contacts (n-reads)</b> is calculated and the maximum of them is displayed.</li>
                                                                <li><b>Exps</b> — number of experiments ("one-to-all" or "all-to-all") that contain contacts of the corresponding RNA with the target locus as compared with its genome-wide contacts.</li>
                                                                <li><b>RNA type</b> — matches the gene type, taken from the corresponding gene annotation.</li>
                                                                <li><b>Gene location</b> — gene coordinates of the corresponding RNA.</li>
                                                                <li><b>Length</b> — the length of the RNA, which is equal to the length of its source gene (we do not consider transcripts).</li>
                                                                <li><b>Str.</b> — gene strand.</li>
                                                                <li><b>Ann.source</b> — source of the gene annotation. The list of all sources can be found in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Annotation of RNA-parts of contacts"</a> section.</li>
                                                                <img src={cpkm} alt="cpkm" style={{marginTop: 15, border: '1px solid #000000'}} width='100%'/> {/* \textbf{CPKM}\text{(RNAi)}=\frac{10^{9}*\sum_{\text{all genome/target locus}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Gene length}\text{(RNAi)}*\sum_{\text{all genome}}^{}\textbf{reads}\text{(all RNAs from the experiment)}} */}
                                                                <b>CPKM</b> is the number of contacts of the selected RNA with the "Target locus" divided by library size (sum of all contacts in the experiment) and RNA length. 
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <Typography variant="subtitle1" style={{textAlign: 'justify', marginLeft: 40, marginTop: 15, marginBottom: 15}}>
                                                    &emsp;&emsp;<span style={{color: 'red', fontWeight: 'bold'}}>Important!</span> All metrics (<b>"max CPKM", "max n-reads", "Exps"</b>) are calculated only for those RNAs whose contacts intersect the target locus in coordinates.
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                                                working with the table:
                                                            </Typography>
                                                        </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <div style={{flexGrow: 0}}>
                                                                        <ul>
                                                                            <li><span style={{color: 'red'}}><b>go to "Graphical Summary"</b></span> — by clicking on a certain "RNA name" you will be redirected to the "Graphical Summary" page.</li>
                                                                            <li><b>Select the normalization</b> by which the metrics will be calculated ("max CPKM", "max n-reads", "Exps").</li>
                                                                            <li><b>Sort the table</b> by the values of a particular column.</li>
                                                                            <li><b>Filter</b> records in table using the "APPLY FILTERS" button and the following text fields:</li>
                                                                            <ul>
                                                                                <li><b>Select RNAs</b> — the table will display only selected RNAs.</li>
                                                                                <li><b>Select RNA types</b> — the table will display only those RNAs that belong to the selected RNA types.</li>
                                                                                <li><b>Select annotation source</b> — the table will display only those RNAs that belong to the selected annotation sources.</li>
                                                                                <li><b>Select organism</b> — the table will display only those RNAs that belong to the selected organism.</li>
                                                                                <li><b>Search by "RNA name"</b> — the table will display only those RNAs that match the search pattern or include it.</li>
                                                                                <li><b>Enter genomic locus or loci</b> — the table will display only those RNAs whose genes are intersected by the selected locus/loci.</li>
                                                                                <li><b>Minimum gene length</b> — the table will display only those RNAs whose genes are longer than or equal to the selected threshold.</li>
                                                                            </ul>
                                                                            <li><b>Download</b> the table (with applied filters) <b>grouped</b> by RNA name or in <b>ungrouped</b> format.</li>
                                                                        </ul>
                                                                </div>
                                                            </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            Contacts Summary
                                        </Typography>
                                        <Typography className={classes.secondaryHeading}>
                                            Graphical Summary
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                            <div style={{flexGrow: 0}}>
                                                <Typography variant="subtitle1" style={{ marginBottom: 5}}>
                                                    <b>Contacts Summary</b> consists of 2 tables:
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>RNA information table</b> — contains all information on the selected RNA and "target locus"</li>
                                                        <img src={ContactsSummaryRNAinfo} alt="ContactsSummaryRNAinfo" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about this table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>RNA name</b> — unique RNA name used in the database.</li>
                                                                <li><b>RNA type</b> — matches the gene type, taken from the corresponding gene annotation.</li>
                                                                <li><b>Gene location</b> — gene coordinates of the corresponding RNA.</li>
                                                                <li><b>Str.</b> — gene strand.</li>
                                                                <li><b>Target locus</b> — region of the genome with which the RNA interacts.</li> {/* An empty "Target locus" field means that the analysis is carried out "from RNA" and that genome-wide contacts are considered */}
                                                                <li><b>Length</b> — the length of the RNA, which is equal to the length of its source gene (we do not consider transcripts).</li>
                                                                <li><b>Organism</b></li>
                                                                <li><b>Genome</b> — assembly version of the genome to which the reads were mapped.</li>
                                                                <li><b>Ann.source</b> — source of the gene annotation. The list of all sources can be found in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Annotation of RNA-parts of contacts"</a> section.</li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <Typography variant="subtitle1" style={{ marginBottom: 5}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>Contacts Summary table</b></li>
                                                        <img src={ContactsSummary} alt="ContactsSummary" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about this table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>Exp.ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database.</li>
                                                                <li><b>Type</b> — one of two methods for detecting RNA-chromatin interactions data ("one-to-all" or "all-to-all").</li>
                                                                <li><b>n-reads (normalized)</b> — number of background-normalized contacts of the selected RNA with the entire genome or with the selected "target locus" in the corresponding experiment.</li>
                                                                <li><b>n-reads (raw)</b> — number of not-normalized contacts of the selected RNA with the entire genome or with the selected "target locus" in the corresponding experiment.</li>
                                                                <li><b>CPKM (normalized)</b> — number of background-normalized contacts divided by library size (sum of all contacts in the experiment) and RNA length.</li>
                                                                <li><b>CPKM (raw)</b> — number of not-normalized contacts divided by library size (sum of all contacts in the experiment) and RNA length.</li>
                                                                <li><b>n-reads (norm., peaks)</b> and <b>n-reads (raw, peaks)</b> — number of normalized/raw contacts that intersected coordinate-wise with the peaks calculated by the MACS2 program only for the corresponding one-to-all data (more details in the section <a href="/protocol" target="_blank" rel="noopener noreferrer">"Contacts normalization"</a>). In the case of "from RNA" analysis <b>NA means</b> that in the corresponding experiment there are no peaks at all. In the case of "from DNA" analysis <b>zero means</b> that in the corresponding experiment there are no peaks at all or they are absent in the DNA locus selected for analysis.</li>
                                                                <li><b>Contacts</b> — you can download csv-table with the coordinates of the DNA-parts of the contacts (grouped by bins, bin size = 200 bp): chromosome, start, end, n-reads, density. For more information on density calculation, see the "UCSC Genome Browser" section, in the "More about plotting" subsection.</li>
                                                                <img src={cpkm} alt="cpkm" style={{marginTop: 15, border: '1px solid #000000'}} width='100%'/>
                                                                <b>CPKM</b> is the number of contacts divided by library size and RNA length. <b>If "Target locus"</b> is selected, the numerator will be the sum of contacts of the selected RNA with the "Target locus", and the denominator will be the sum of all contacts in the experiment (library size).
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <Typography variant="subtitle1" style={{ marginTop: 20, marginBottom: 5}}>
                                                    and 4 buttons: 
                                                    <ul>
                                                        <li><b>Distribution of "selected RNA" RNA-parts across their source gene body</b> — allows you to plot the distribution of RNA-parts of contacts of selected RNA across their source gene body for all data type "all-to-all".</li>
                                                        <li><b>All target genes</b> — allows you to get a table with genes and near-gene regions with that the selected RNA contacts in the corresponding experiment.</li>
                                                        <li><b>Selective graphical summary</b>. If the contacts of the selected RNA were found in a large number of experiments, the graphs on the "Graphical Summary" page will be overloaded and will be difficult to perceive. 
                                                        In this case, you can select the experiments you are interested in in the "Contacts Summary" table and plot all charts from the "Graphical Summary" only for the selected experiments.</li>
                                                        <li><b>UCSC Genome Browser</b> — allows you to view the contact experiments (selected in the "Contacts Summary" table) in the UCSC Genome Browser.</li>
                                                    </ul>
                                                    In case of the analysis <b>"from DNA"</b>, the fifth button "All contacts" will appear:
                                                    <ul>
                                                        <li><b>All contacts</b> — allows you to reset the "target locus" filter and display on the "Graphical Summary" page genome-wide graphs of contacts of the selected RNA with chromatin.</li>
                                                    </ul>
                                                </Typography>
                                            </div>
                                        </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            Contacts Distribution
                                        </Typography>
                                        <Typography className={classes.secondaryHeading}>
                                            Graphical Summary
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                            <div style={{flexGrow: 0}}>
                                                <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>"from RNA"</b>: genome-wide contacts density distribution of SRA1 RNA (<i>Homo sapiens</i>)</li>
                                                        <img src={ContactsDistribution} alt="ContactsDistribution" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>More about plotting:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>Y-axis</b>: contacts density.<br/></li>
                                                                <li><b>X-axis</b>: chromosomes with which the selected RNA interacts (bin size = 5000000 bp).</li>
                                                                <img src={genomeWideProfile} alt="genomeWideProfile" style={{marginTop: 15}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li><span style={{color: 'red', fontWeight: 'bold'}}>It is important to note</span> that <b>"all bins"</b> over which the sum in the denominator is calculated are determined by the whole genome.</li>
                                                                <li>A DNA-part of the contact belongs to a specific bin if the center coordinate of the DNA-part belongs to it.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>What can we derive from this chart?</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li>The shapes of genome-wide contact distribution profiles differ greatly between the all-to-all (Exp.ID: 1-5, 7-10) and one-to-all (Exp.ID: 62, 200) experiments.</li>
                                                                <li>The chromosome on which the selected RNA gene is encoded is marked in <span style={{color: 'red'}}>red</span> (in this example it is "chr5").</li>
                                                                <li>We see a pronounced peak on the parental chromosome ("chr5"). These RNA-DNA contacts are due to transcription of the parental gene of the selected RNA and are trivial (polymerase trace).</li>
                                                                <li>For a more detailed view of the contacts density distribution, we recommend to view contacts in the <b>UCSC Genome Browser</b>. This can be done in the <b>"Contacts Summary"</b>.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Accompanying contacts table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <img src={ContactsDistributionTable} alt="ContactsDistributionTable" style={{marginTop: 5, border: '1px solid #000000'}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li><b>ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database. If you click on the <b>ID</b>, you will be taken to the <b>"Metadata"</b> page.</li>
                                                                <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                                <li><b>n-reads</b> — number of contacts (normalized / raw / norm. & in peaks / raw & in peaks) of the selected RNA with the entire genome in the corresponding experiment.</li>
                                                                <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                                <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <Divider style={{marginTop: 15, marginBottom: 5, width: '100%', height: 2, backgroundColor: 'black'}}/>
                                                <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>"from DNA"</b>: contacts density distribution of SRA1 RNA (<i>Homo sapiens</i>) at the target locus ("chr5")</li>
                                                        <img src={ContactsDistributionTargetLocus} alt="ContactsDistributionTargetLocus"style={{border: '1px solid #000000'}}  width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>More about plotting:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>Y-axis</b>: contacts density.</li>
                                                                <li><b>X-axis</b>: target locus.</li>
                                                                <img src={genomeWideProfile} alt="genomeWideProfile" style={{marginTop: 15}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li>If the length of the "Target locus" is greater than 40,000 bp, then the "bin size" will be such that the number of bins is 200 (calculated as (end-start+1)//200). Otherwise "bin size" is 200 bp.</li>
                                                                <li><span style={{color: 'red', fontWeight: 'bold'}}>It is important to note</span> that <b>"all bins"</b> over which the sum in the denominator is calculated are determined by the "Target locus" region.</li>
                                                                <li>A DNA-part of the contact belongs to a specific bin if the center coordinate of the DNA-part belongs to it.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>What can we derive from this chart?</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li>The shapes of contact distribution profiles at the "Target locus" differ greatly between the all-to-all (Exp.ID: 1-5, 7-10) and one-to-all (Exp.ID: 62, 200) experiments.</li>
                                                                <li>We see a pronounced peak in the parent gene region of the selected RNA (SRA1 gene location: chr5:140537340-140558252) in the "all-to-all" data. These RNA-DNA contacts are due to transcription of the parental gene of the selected RNA and are trivial (polymerase trace).</li>
                                                                <li>In the 62nd and 200th experiments, the contacts are distributed more or less evenly, we can notice signal subsidence in the area that corresponds to the centromeric region.</li>
                                                                <li>For a more detailed view of the contacts density distribution, we recommend to view contacts in the <b>UCSC Genome Browser</b>. This can be done in the <b>"Contacts Summary"</b>.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Accompanying contacts table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <img src={ContactsDistributionTargetLocusTable} alt="ContactsDistributionTargetLocusTable" style={{marginTop: 5, border: '1px solid #000000'}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li><b>ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database. If you click on the <b>ID</b>, you will be taken to the <b>"Metadata"</b> page.</li>
                                                                <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                                <li><b>n-reads</b> — number of contacts (normalized / raw / norm. & in peaks / raw & in peaks) of the selected RNA with with the selected "target locus" in the corresponding experiment.</li>
                                                                <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                                <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            Comparative Heatmap
                                        </Typography>
                                        <Typography className={classes.secondaryHeading}>
                                            Graphical Summary
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                        <div style={{flexGrow: 0}}>
                                            <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                <ul>
                                                    <li style={{marginLeft: 20}}>contacts density distribution of SRA1 RNA (<i>Homo sapiens</i>) over chromosomes for several experiments on the one heatmap</li>
                                                    <img src={hm2} alt="hm2" style={{border: '1px solid #000000'}} width='100%'/>
                                                </ul>
                                            </Typography>
                                            <div style={{marginLeft: 40}}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{backgroundColor: '#b8b9ba'}}
                                                    >
                                                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>More about plotting:</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                        <ul>
                                                            <li><b>Y-axis</b>: experiments that contain contacts of the selected RNA.</li>
                                                            <li><b>X-axis</b>: chromosomes with which the selected RNA interacts.</li>
                                                            <br/><img src={Comparative_HeatMap} alt="Comparative_HeatMap" width='100%'/> {/* \textbf{Density}\text{[RNAi,chr(j)]}=\frac{10^{11}*\sum_{\text{chr(j)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Chr.length}\text{(j)}*\left (\sum_{\text{all chrs}}^{}\textbf{reads}\text{(RNAi)}+100\right )} */}
                                                        </ul>
                                                        {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{marginLeft: 40}}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{backgroundColor: '#b8b9ba'}}
                                                    >
                                                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>What can we derive from this heatmap?</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                        <ul>
                                                            <li>Here we see a heterogeneous distribution of contacts across chromosomes more clearly than on the "Contacts Distribution" bar chart.</li>
                                                            <li>But just like on "Contacts Distribution" we see a pronounced peak on the "chr5" (parental chromosome). These RNA-DNA contacts are due to transcription of the parental gene of the selected RNA and are trivial (polymerase trace).</li>
                                                        </ul>
                                                        {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{marginLeft: 40}}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{backgroundColor: '#b8b9ba'}}
                                                    >
                                                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Accompanying contacts table:</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                        <ul>
                                                            <img src={hm2Table} alt="hm2Table" style={{marginTop: 5, border: '1px solid #000000'}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                            <li><b>ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database. If you click on the <b>ID</b>, you will be taken to the <b>"Metadata"</b> page.</li>
                                                            <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                            <li><b>n-reads</b> — number of contacts (normalized / raw / norm. & in peaks / raw & in peaks) of the selected RNA with the entire genome in the corresponding experiment.</li>
                                                            <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                            <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                        </ul>
                                                        {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            Distance Distribution
                                        </Typography>
                                        <Typography className={classes.secondaryHeading}>
                                            Graphical Summary
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                        <div style={{flexGrow: 0}}>
                                            <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                There are RNAs that tend to contact with chromatin <b>cis-</b> (mainly with the parental chromosome), <b>trans-</b> (mainly with other chromosomes), or <b>mixed</b> [<a href="https://pubmed.ncbi.nlm.nih.gov/35403620/" target="_blank" rel="noopener noreferrer">PMID: 35403620</a>]. This line chart examines the cis- contacts of RNA.
                                            </Typography>
                                            <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                <ul>
                                                    <li style={{marginLeft: 20}}><b>gene-centric</b> line chart of contacts density depending on the distance between the SRA1 gene (<i>Homo sapiens</i>) and chromatin loci (on the parent chromosome)</li>
                                                    <img src={hm4} alt="hm4" style={{border: '1px solid #000000'}} width='100%'/>
                                                </ul>
                                            </Typography>
                                            <div style={{marginLeft: 40}}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{backgroundColor: '#b8b9ba'}}
                                                    >
                                                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>More about plotting:</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                        <ul>
                                                            <li><b>Y-axis</b>: Log2(Density).</li>
                                                            <li><b>X-axis</b>: Log2(Distance between SRA1 gene and loci contacted by the SRA1 RNA).</li>
                                                            <br/><img src={DistanceDistribution} alt="DistanceDistribution" width='100%'/> {/* \textbf{Density}\text{[interval(i)]}=\frac{10^{9}*\sum_{\text{interval(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Interval length}\text{(i)}*\left (\sum_{\text{all intervals}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                            <li><span style={{color: 'red', fontWeight: 'bold'}}>It is important to note</span> that each <b>interval</b> does not include the previous ones and is part of the sequence: <b>[2^0; 2^4], (2^4; 2^5], (2^5; 2^6], (2^6; 2^7], (2^7; 2^8], (2^8; 2^9], (2^9; 2^10] ...</b></li>
                                                            <li>In this line chart, we take into account the gene strand, so the upstream-densities are shown on the left side of the chart, and the downstream-densities are shown on its right side.</li>
                                                        </ul>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{marginLeft: 40}}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{backgroundColor: '#b8b9ba'}}
                                                    >
                                                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>What can we derive from this chart?</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                        <ul>
                                                            <li>
                                                                We expect to see more signal in the downstream-region (polymerase trace). 
                                                                If we observe an alternative situation, we can assume that SRA1 RNA may be involved in the process of self-regulation. 
                                                                However, to confirm this hypothesis, a statistical analysis should be carried out.
                                                            </li>
                                                            <li>
                                                                You might notice here an asymmetry in the distribution of SRA1 RNA contacts along its chromosome relative to the source gene; it may be interesting to study the reason for this.
                                                            </li>
                                                            <li>For a more detailed view of the contacts density distribution, we recommend to view contacts in the <b>UCSC Genome Browser</b>. This can be done in the <b>"Contacts Summary"</b>.</li>
                                                        </ul>
                                                        {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{marginLeft: 40}}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{backgroundColor: '#b8b9ba'}}
                                                    >
                                                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Accompanying contacts table:</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                        <ul>
                                                            <img src={hm4Table} alt="hm4Table" style={{marginTop: 5, border: '1px solid #000000'}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                            <li><b>ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database. If you click on the <b>ID</b>, you will be taken to the <b>"Metadata"</b> page.</li>
                                                            <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                            <li><b>Local contacts</b> — number of contacts (normalized / raw / norm. & in peaks / raw & in peaks) of the selected RNA with its the parent chromosome in the corresponding experiment <span style={{color: 'red', fontWeight: 'bold'}}>(contacts with one's source gene are excluded)</span>.</li>
                                                            <li><b>Trans contacts</b> — number of contacts (normalized / raw / norm. & in peaks / raw & in peaks) of the selected RNA with all chromosomes except the parent one in the corresponding experiment.</li>
                                                            <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                            <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                        </ul>
                                                        {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <Divider style={{marginTop: 15, marginBottom: 5, width: '100%', height: 2, backgroundColor: 'black'}}/>
                                                <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>non gene-centric</b> line chart (<b>"Scaling"</b>) of contacts density depending on the distance between the SRA1 gene (<i>Homo sapiens</i>) and chromatin loci (on the parent chromosome)</li>
                                                        <img src={hm4_non_centric} alt="hm4_non_centric"style={{border: '1px solid #000000'}}  width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>More about plotting:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>Y-axis</b>: Log2(Density).</li>
                                                                <li><b>X-axis</b>: Log2(Distance between SRA1 gene and loci contacted by the SRA1 RNA).</li>
                                                                <br/><img src={DistanceDistribution} alt="DistanceDistribution" width='100%'/> {/* \textbf{Density}\text{[interval(i)]}=\frac{10^{9}*\sum_{\text{interval(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Interval length}\text{(i)}*\left (\sum_{\text{all intervals}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li><span style={{color: 'red', fontWeight: 'bold'}}>It is important to note</span> that each <b>interval</b> does not include the previous ones and is part of the sequence: <b>[2^0; 2^4], (2^4; 2^5], (2^5; 2^6], (2^6; 2^7], (2^7; 2^8], (2^8; 2^9], (2^9; 2^10] ...</b></li>
                                                                <li>In this line chart, we do not take into account the gene strand, so contacts from the equivalent upstream- and downstream-regions are summed.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>What can we derive from this chart?</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li>We see a decrease in the SRA1 RNA contactability signal as the distance between the source gene and the locus with which SRA1 contacts increases. We call this characteristic of RNA-chromatin contacts <b>"scaling"</b>.</li>
                                                                <li>Most often, in "log-log" coordinates, scaling has a linear trend (if you do not take into account the edge values).</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Accompanying contacts table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <img src={hm4Table} alt="hm4Table" style={{marginTop: 5, border: '1px solid #000000'}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li><b>ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database. If you click on the <b>ID</b>, you will be taken to the <b>"Metadata"</b> page.</li>
                                                                <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                                <li><b>Local contacts</b> — number of contacts (normalized / raw / norm. & in peaks / raw & in peaks) of the selected RNA with its the parent chromosome in the corresponding experiment <span style={{color: 'red', fontWeight: 'bold'}}>(contacts with one's source gene are excluded)</span>.</li>
                                                                <li><b>Trans contacts</b> — number of contacts (normalized / raw / norm. & in peaks / raw & in peaks) of the selected RNA with all chromosomes except the parent one in the corresponding experiment.</li>
                                                                <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                                <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            All target genes
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                        <div style={{flexGrow: 0}}>
                                                <Typography variant="subtitle1" style={{ marginBottom: 5}}>
                                                    <b>All target genes</b> consists of 2 tables:
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>RNA information table</b> — contains information on the selected RNA, "target locus" and the experiment</li>
                                                        <img src={AllTargetGenesRNAinfo} alt="AllTargetGenesRNAinfo" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about this table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>RNA name</b> — unique RNA name used in the database.</li>
                                                                <li><b>RNA type</b> — matches the gene type, taken from the corresponding gene annotation.</li>
                                                                <li><b>Gene location</b> — gene coordinates of the corresponding RNA.</li>
                                                                <li><b>Str.</b> — gene strand.</li>
                                                                <li><b>Target locus</b> — region of the genome with which the RNA interacts.</li> {/* An empty "Target locus" field means that the analysis is carried out "from RNA" and that genome-wide contacts are considered */}
                                                                <li><b>Length</b> — the length of the RNA, which is equal to the length of its source gene (we do not consider transcripts).</li>
                                                                <li><b>Organism</b></li>
                                                                <li><b>Genome</b> — assembly version of the genome to which the reads were mapped.</li>
                                                                <li><b>Ann.source</b> — source of the gene annotation. The list of all sources can be found in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Annotation of RNA-parts of contacts"</a> section.</li>
                                                                <li><b>Exp.ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database. If you click on the <b>Exp.ID</b>, you will be taken to the <b>"Metadata"</b> page.</li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <Typography variant="subtitle1" style={{ marginBottom: 5}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>All target genes</b> table</li>
                                                        <img src={AllTargetGenesTable} alt="AllTargetGenesTable" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about this table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <div style={{flexGrow: 0}}>
                                                                <ul>
                                                                    <li><b>Target gene</b> — the unique name of the gene with the body or the upstream- / downstream- regions of which SRA1 RNA interacts.</li>
                                                                    <li><b>up50</b> — number of RNA contacts with that region divided by the size of that region (i.e. contacts density). The "up50" (upstream 50 kb region) does not include the upstream 5 kb region. That is, <b>"up50" = [up50 kb; up5 kb)</b> or just 45,000 bp.</li>
                                                                    <li><b>up5</b> — contacts density in the upstream 5 kb region. That is, <b>"up5" = [up5 kb; GeneBody)</b> or just 5,000 bp.</li>
                                                                    <li><b>GeneBody</b> — region of the gene body that matches the coordinates from the <b>"Gene location"</b></li>
                                                                    <li><b>down5</b> — contacts density in the downstream 5 kb region. That is, <b>"down5" = (GeneBody; down5 kb]</b> or just 5,000 bp.</li>
                                                                    <li><b>down50</b> — contacts density in the downstream 50 kb region. The "down50" (downstream 50 kb region) does not include the downstream 5 kb region. That is, <b>"down50" = (down5 kb; down50 kb]</b> or just 45,000 bp.</li>
                                                                    <li><b>Gene type</b> — taken from the corresponding gene annotation.</li>
                                                                    <li><b>Gene locus</b> — coordinates of target gene.</li>
                                                                    <li><b>Length</b> — the length of the target gene.</li>
                                                                    <li><b>Str.</b> — gene strand.</li>
                                                                    <li><b>Ann.source</b> — source of the gene annotation. The list of all sources can be found in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Annotation of RNA-parts of contacts"</a> section.</li>
                                                                </ul>
                                                                <Typography variant="subtitle1" style={{textAlign: 'justify', marginLeft: 40, marginTop: 15, marginBottom: 15}}>
                                                                    <b>Five histograms</b> appearing immediately below the table describe the distribution of contacts density in the corresponding genomic intervals ("[Up50 kb; Up5 kb)", "[up 5 kb; gene body)", "[gene body]", "(gene body; down 5 kb]", "(down 5 kb; down 50 kb]") for all target genes that passed the selected filters. In order for <b>zero density</b> to also be displayed on a logarithmic scale (<b>Y-axis</b>: number of target genes, <b>X-axis</b>: Log10(Density)), <b>we added 0.0001 to each density value</b>.
                                                                </Typography>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                                                working with the table:
                                                            </Typography>
                                                        </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <div style={{flexGrow: 0}}>
                                                                        <ul>
                                                                            <li><span style={{color: 'red'}}><b>go to "from DNA" analysis</b></span> — by clicking on a certain target gene you will be redirected to the page with <b>"Complete table of RNAs"</b>, which will contain all the RNAs that contact with the selected gene.</li>
                                                                            <li><b>Select the normalization</b> by which the contacts density will be calculated ("up50", "up5", "GeneBody", "down5", "down50").</li>
                                                                            <li><b>Sort the table</b> by the values of a particular column.</li>
                                                                            <li><b>Filter</b> records in table using the "APPLY FILTERS" button and the following text fields:</li>
                                                                            <ul>
                                                                                <li><b>Search by "Target gene"</b> — the table will display only those genes that match the search pattern or include it.</li>
                                                                                <li><b>Target gene</b> — the table will display only selected genes.</li>
                                                                                <li><b>Gene types</b> — the table will display only those genes that belong to the selected gene types.</li>
                                                                                <li><b>Annotation source</b> — the table will display only those genes that belong to the selected annotation sources.</li>
                                                                                <li><b>Enter genomic locus or loci</b> — the table will display only those target genes whose genomic coordinates intersect with the selected locus/loci.</li>
                                                                                <li><b>Minimum Density</b> — the table will display only those target genes whose contacts density in the corresponding region (up50, up5, GeneBody, down5, down50) is greater than or equal to the selected threshold.</li>
                                                                                <li><b>Minimum gene length</b> — the table will display only those target genes whose length is greater than or equal to the selected threshold.</li>
                                                                            </ul>
                                                                            <li><b>Download the table</b> (with applied filters)</li>
                                                                            <div>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                        style={{backgroundColor: '#b8b9ba'}}
                                                                                    >
                                                                                        <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                                    <ul>
                                                                                        <li>"Target gene"</li>
                                                                                        <li>"Gene type"</li>
                                                                                        <li>"Chromosome"</li>
                                                                                        <li>"Gene start"</li>
                                                                                        <li>"Gene end"</li>
                                                                                        <li>"Gene strand"</li>
                                                                                        <li>"Gene length"</li>
                                                                                        <li>"Annotation source"</li>
                                                                                        <li>"Density [up 50 kb; up 5 kb)"</li>
                                                                                        <li>"Density [up 5 kb; gene body)"</li>
                                                                                        <li>"Density [gene body]"</li>
                                                                                        <li>"Density (gene body; down 5 kb]"</li>
                                                                                        <li>"Density (down 5 kb; down 50 kb]"</li>
                                                                                        <li>"Contacts [up 50 kb; up 5 kb)"</li>
                                                                                        <li>"Contacts [up 5 kb; gene body)"</li>
                                                                                        <li>"Contacts [gene body]"</li>
                                                                                        <li>"Contacts (gene body; down 5 kb]"</li>
                                                                                        <li>"Contacts (down 5 kb; down 50 kb]"</li>
                                                                                        <li>"RNA name"</li>
                                                                                        <li>"Experiment ID"</li>
                                                                                        <li>"Organism"</li>
                                                                                    </ul>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </div>
                                                                        </ul>
                                                                        <Typography variant="subtitle1" style={{textAlign: 'justify', marginLeft: 40, marginTop: 15, marginBottom: 15}}>
                                                                            <span style={{fontWeight: 'bold'}}>"Density (down 5 kb; down 50 kb]" = "Contacts (down 5 kb; down 50 kb]"/45000</span>
                                                                        </Typography>
                                                                </div>
                                                            </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                                                How was this table obtained?
                                                            </Typography>
                                                        </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                                                <div style={{flexGrow: 0}}>
                                                                <img src={AllTargetGenesExample} alt="AllTargetGenesExample" style={{border: '1px solid #000000', backgroundColor: 'white'}} width='100%'/>
                                                                    <ul>
                                                                        <li>We consider 5 gene intervals independently. If the contact coordinates (more precisely, the DNA part of the contact) intersect with any of the intervals by at least 1 nucleotide, this contact is assigned to the corresponding gene interval.</li>
                                                                        <li>In this example, "contact #1" was assigned to "up50" of "gene 1", as well as "up50" and "up5" of "gene 2".</li>
                                                                        <li><span style={{color: 'red'}}><b>Density = (n-reads/interval)*1000</b></span></li>
                                                                    </ul>
                                                                </div>
                                                            </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            Selective graphical summary
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                            <div style={{flexGrow: 0}}>
                                                <ul>
                                                    <li>Includes everything from "Graphical Summary" except "Contacts Summary".</li>
                                                    <li>If the contacts of the selected RNA were found in a large number of experiments and the plots on the "Graphical Summary" page are overloaded, it is <b>very convenient</b> in this case to select several experiments in the "Contact Summary" table and study them on the "Selective graphical summary" page.</li>
                                                </ul>
                                            </div>
                                        </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            UCSC Genome Browser
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                            <div style={{flexGrow: 0}}>
                                                <ul>
                                                    <li><b>Our service allows you to view the contacts</b> from the corresponding experiment (selected in the "Contacts Summary" table) <b>in the UCSC Genome Browser</b>.</li>
                                                    {/* <li>You can study in more detail the nature of the contact of the selected RNA with chromatin, as well as use all the features of the UCSC Genome Browser, for example:</li> */}
                                                    <li>The UCSC Genome Browser provides the ability: 
                                                        <ul>
                                                            <li><b>to view contacts profiles with the higher resolution;</b></li>
                                                            <li><b>to visually correlate them with a large number of genomic annotations</b> (gene annotations, chromatin states, epigenetic marks, etc) <b>or data</b> (ChIP-seq, RNA-seq, Hi-C, etc).</li>
                                                        </ul>
                                                    </li>
                                                    {/* <ul>
                                                        <li>a variety of genomic markings (gene sets, chromatin states, epigenetic marks, ..)</li>
                                                        <li>various genome-wide data (ChIP-seq, Hi-C, ..)</li>
                                                    </ul> */}
                                                    <img src={GB_plot} alt="GB_plot"style={{border: '1px solid #000000'}}  width='100%'/>
                                                    <Typography variant="subtitle1" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                                                        <i>Contacts of the <b>lnc-NR2F1 RNA</b> (long isoform, Exp.ID=140) with chromatin (genomic locus: chr2:96,005,602-96,028,295).</i>
                                                    </Typography>
                                                </ul>
                                                {/* <BlockMath math={'\\small \\textbf{Contacts Density(i)}=\\frac{10^{9}*\\sum_{\\text{bin i}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all bins}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} />     */}
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>More about plotting:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>Y-axis</b>: contacts density.</li>
                                                                <li><b>X-axis</b>: genomic coordinates (bin size = 200 bp).</li>
                                                                <img src={genomeWideProfile} alt="genomeWideProfile" style={{marginTop: 15}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li><span style={{color: 'red', fontWeight: 'bold'}}>It is important to note</span> that <b>"all bins"</b> over which the sum in the denominator is calculated are determined by the whole genome or the "Target locus" region.</li>
                                                                <li>A DNA-part of the contact belongs to a specific bin if the center coordinate of the DNA-part belongs to it.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>What can we derive from this figure?</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                                We observe 4 tracks in the corresponding genomic locus:
                                                                <ul>
                                                                    <li>lnc-NR2F1 RNA contacts density;</li>
                                                                    <li>the GENCODE Genes (version 41);</li>
                                                                    <li>ENCODE Candidate Cis-Regulatory Elements (cCREs) combined from all cell types;</li>
                                                                    <li>H3K4Me3 Mark (Often Found Near Promoters) on 7 cell lines from ENCODE.</li>
                                                                </ul>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                        {/* Distribution of RNA-parts of contacts across its genebody */}
                                            Distribution of RNA-parts of contacts across their source gene body
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                            <div style={{flexGrow: 0}}>
                                                <Typography variant="subtitle1" style={{ marginBottom: 5}}>
                                                    This bar chart is plotted only on the basis of the "all-to-all" data, since the "one-to-all" data contain information only about the belonging of the RNA-parts of the contacts to a particular gene, but not about their sequence.
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>RNA information table</b> — contains all information on the selected RNA and "target locus"</li>
                                                        <img src={ContactsSummaryRNAinfo} alt="ContactsSummaryRNAinfo" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about this table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>RNA name</b> — unique RNA name used in the database.</li>
                                                                <li><b>RNA type</b> — matches the gene type, taken from the corresponding gene annotation.</li>
                                                                <li><b>Gene location</b> — gene coordinates of the corresponding RNA.</li>
                                                                <li><b>Str.</b> — gene strand.</li>
                                                                <li><b>Target locus</b> — region of the genome with which the RNA interacts.</li> {/* An empty "Target locus" field means that the analysis is carried out "from RNA" and that genome-wide contacts are considered */}
                                                                <li><b>Length</b> — the length of the RNA, which is equal to the length of its source gene (we do not consider transcripts).</li>
                                                                <li><b>Organism</b></li>
                                                                <li><b>Genome</b> — assembly version of the genome to which the reads were mapped.</li>
                                                                <li><b>Ann.source</b> — source of the gene annotation. The list of all sources can be found in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Annotation of RNA-parts of contacts"</a> section.</li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>"from RNA"</b>: distribution of RNA-parts of contacts across their source gene body</li>
                                                        <img src={RNApartsDistrFromRNA} alt="RNApartsDistrFromRNA" style={{border: '1px solid #000000'}} width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>More about plotting:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>Y-axis</b>: density of RNA-parts of contacts in the corresponding bin.<br/></li>
                                                                <li><b>X-axis</b>: coordinate of the bin center relative to the gene.</li>
                                                                <img src={RNA_parts_of_contacts_profile} alt="RNA_parts_of_contacts_profile" style={{marginTop: 15}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}+100\right )} */}
                                                                <li><b>Bin size = 200 bp</b></li>
                                                                <li><b>Pseudocount = 100 </b></li><br/>
                                                                <li>An RNA-part of the contact belongs to a specific bin if the center coordinate of the RNA-part belongs to it.</li>
                                                                <span style={{color: 'red'}}><b>Formula in words:</b></span> density in the <b>i</b>-th bin is calculated as the sum of RNA-parts of the contacts (reads) whose centers lie in the coordinates of the corresponding bin, divided by the bin size and the total sum of RNA-parts (for the corresponding RNA in the corresponding experiment) and pseudocount.<br/>
                                                                <img src={RNApartsDistrScheme} alt="RNApartsDistrScheme" style={{border: '1px solid #000000', backgroundColor: 'white'}} width='100%'/>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Density(i)}=\\frac{10^{9}*\\sum_{\\text{bin(i)}}^{}\\textbf{N-norm}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all bins}}^{}\\textbf{N-norm}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>What can we derive from this chart?</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                                A variety of reasons can contribute to the uneven distribution:
                                                                <ul>
                                                                    <li>exon-intron structure;</li>
                                                                    <li>"preference" of a particular RNA domain to contact with chromatin more than others;</li>
                                                                    <li>multiple isoforms of the transcribed gene;</li>
                                                                    <li>some small RNAs are not included in our annotation, therefore they are not taken into account in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Annotation of RNA-parts of contacts"</a> procedure.</li>
                                                                </ul>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Accompanying contact and metadata table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <img src={RNApartsDistrFromRNAtable} alt="RNApartsDistrFromRNAtable" style={{marginTop: 5, border: '1px solid #000000'}} width='80%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li><b>Experiment ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database. If you click on the <b>Experiment ID</b>, you will be taken to the <b>"Metadata"</b> page.</li>
                                                                <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                                <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                                <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                                <li><b>Type of experiment</b> — "one-to-all" or "all-to-all".</li>
                                                                <li><b>N-reads (normalized)</b> — number of background-normalized contacts of the selected RNA with the entire genome in the corresponding experiment.</li>
                                                                <li><b>N-reads (raw)</b> — number of not-normalized contacts of the selected RNA with the entire genome in the corresponding experiment.</li>
                                                                <li><b>CPKM (normalized)</b> — number of background-normalized contacts divided by library size (sum of all contacts in the experiment) and RNA length.</li>
                                                                <li><b>CPKM (raw)</b> — number of not-normalized contacts divided by library size (sum of all contacts in the experiment) and RNA length.</li>
                                                                <li><b>RNA-parts of the contacts</b> — you can download csv-table of RNA-parts of the contacts grouped by bins (bin size = 200 bp): chromosome of the RNA source gene, start of <b>i</b>-th bin, end of <b>i</b>-th bin, n-reads (normalized / raw / norm. & in peaks / raw & in peaks), corresponding density.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <Divider style={{marginTop: 15, marginBottom: 5, width: '100%', height: 2, backgroundColor: 'black'}}/>
                                                <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                    <ul>
                                                        <li style={{marginLeft: 20}}><b>"from DNA"</b>: two distributions of RNA-parts of contacts with the entire genome ("all contacts") and with the target locus ("DNA filter": chr5)</li>
                                                        <img src={RNApartsDistrFromDNA} alt="RNApartsDistrFromDNA"style={{border: '1px solid #000000'}}  width='100%'/>
                                                    </ul>
                                                </Typography>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>More about plotting:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li><b>Y-axis</b>: density of RNA-parts of contacts in the corresponding bin.<br/></li>
                                                                <li><b>X-axis</b>: coordinate of the bin center relative to the gene.</li>
                                                                <img src={RNA_parts_of_contacts_profile} alt="RNA_parts_of_contacts_profile" style={{marginTop: 15}} width='100%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}+100\right )} */}
                                                                <li><b>Bin size = 200 bp</b></li>
                                                                <li><b>Pseudocount = 100 </b></li><br/>
                                                                <li>An RNA-part of the contact belongs to a specific bin if the center coordinate of the RNA-part belongs to it.</li>
                                                                <li><span style={{color: 'red'}}><b>The first track (all contacts)</b></span> is plotted as described above.</li>
                                                                <li><span style={{color: 'red'}}><b>The second track</b></span> is displayed only for those contacts whose DNA-parts intersected with the target locus (DNA filter).
                                                                In the denominator, the sum of reads is calculated only for the filtered reads.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Density(i)}=\\frac{10^{9}*\\sum_{\\text{bin(i)}}^{}\\textbf{N-norm}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all bins}}^{}\\textbf{N-norm}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>What can we derive from this chart?</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <li>We see that the profile plotted from the filtered RNA-parts differs little from the original profile, as expected. But if the RNA contacts with the target locus differently than on average, it would be an interesting find.</li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{backgroundColor: '#b8b9ba'}}
                                                        >
                                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Accompanying contact and metadata table:</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                            <ul>
                                                                <img src={RNApartsDistrFromDNAtable} alt="RNApartsDistrFromDNAtable" style={{marginTop: 5, border: '1px solid #000000'}} width='80%'/> {/* \textbf{Density}\text{[bin(i)]}=\frac{10^{9}*\sum_{\text{bin(i)}}^{}\textbf{reads}\text{(RNAi)}}{\textbf{Bin size}*\left (\sum_{\text{all bins}}^{}\textbf{reads}\text{(RNAi)}+100\right)} */}
                                                                <li><b>Experiment ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database. If you click on the <b>Experiment ID</b>, you will be taken to the <b>"Metadata"</b> page.</li>
                                                                <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                                <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                                <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                                <li><b>Type of experiment</b> — "one-to-all" or "all-to-all".</li>
                                                                <li><b>N-reads (normalized) (all contacts)</b> — number of background-normalized contacts of the selected RNA with the entire genome in the corresponding experiment.</li>
                                                                <li><b>N-reads (raw) (all contacts)</b> — number of not-normalized contacts of the selected RNA with the entire genome in the corresponding experiment.</li>
                                                                <li><b>CPKM.norm (all contacts)</b> — number of background-normalized contacts divided by library size (sum of all contacts in the experiment) and RNA length.</li>
                                                                <li><b>CPKM.raw (all contacts)</b> — number of not-normalized contacts divided by library size (sum of all contacts in the experiment) and RNA length.</li>
                                                                <li><b>RNA-parts of the contacts (all)</b> — you can download csv-table of all RNA-parts of the contacts grouped by bins (bin size = 200 bp): chromosome of the RNA source gene, start of <b>i</b>-th bin, end of <b>i</b>-th bin, n-reads (normalized / raw / norm. & in peaks / raw & in peaks), corresponding density.</li>
                                                                <li><b>N-reads (normalized) (DNA filter)</b> — number of background-normalized contacts of the selected RNA with the target locus in the corresponding experiment.</li>
                                                                <li><b>N-reads (raw) (DNA filter)</b> — number of not-normalized contacts of the selected RNA with the target locus in the corresponding experiment.</li>
                                                                <li><b>CPKM.norm (DNA filter)</b> — number of background-normalized contacts with the target locus divided by library size (sum of all contacts in the experiment) and RNA length.</li>
                                                                <li><b>CPKM.raw (DNA filter)</b> — number of not-normalized contacts with the target locus divided by library size (sum of all contacts in the experiment) and RNA length.</li>
                                                                <li><b>RNA-parts of the contacts (DNA filter)</b> — you can download csv-table of RNA-parts of contacts with the target locus grouped by bins (bin size = 200 bp): chromosome of the RNA source gene, start of <b>i</b>-th bin, end of <b>i</b>-th bin, n-reads (normalized / raw / norm. & in peaks / raw & in peaks), corresponding density.</li>
                                                            </ul>
                                                            {/* <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} /> */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{backgroundColor: '#b8b9ba'}}
                                    >
                                        <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                            Download
                                        </Typography>
                                    </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#d3d4d5'}}>
                                            <div style={{flexGrow: 0}}>
                                                <Typography variant="subtitle1" style={{ marginBottom: 5}}>
                                                    <ul>
                                                        <li><b><a href="http://bioinf.fbb.msu.ru/rna_chrom/download/RNA-chromatin-interactions-data/" target="_blank" rel="noopener noreferrer">All pre-processed RNA–chromatin interactions data</a></b>. Each experiment corresponds to an archive (you can find detailed metadata about the corresponding experiment on the <a href="/experiments" target="_blank" rel="noopener noreferrer">"Content"</a> page). <b>"Contents of archived data.tsv"</b> file describes the contents of each archive, in the names of which the main metadata of the experiment are encrypted: type of experiment ("one-to-all" or "all-to-all"), unique identifier of the corresponding RNA-chromatin interactions experiment in the database, name of the experimental protocol used to obtain the data, organism, tissue, target RNA. After downloading the archive, unzip it with the following command: <b>tar --force-local -xf 'archives.tar.gz' -C /path/to/directory</b></li>
                                                    </ul>
                                                    <div style={{marginLeft: 40}}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{backgroundColor: '#b8b9ba'}}
                                                            >
                                                                <Typography className={classes.heading} variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                                                                    Contacts with all normalizations.
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <ul>
                                                                    <li>A tab-separated file(s), it contains all RNA-DNA contacts for the corresponding experiment remaining after all processing steps. In this file, one line corresponds to one contact.</li>
                                                                    <div>
                                                                        <div>
                                                                            <Accordion>
                                                                                <AccordionSummary
                                                                                    expandIcon={<ExpandMoreIcon />}
                                                                                    aria-controls="panel1a-content"
                                                                                    id="panel1a-header"
                                                                                    style={{backgroundColor: '#b8b9ba'}}
                                                                                >
                                                                                    <Typography variant={"subtitle1"}>One-to-all experiment:</Typography>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                                <ul>
                                                                                    <li><b>contacts.normalized.bed</b>, columns in the following order: "chr_dna", "start_dna", "end_dna", "n-reads (background)", "n-reads (normalized)", "intersects_peak", "peak_name".</li>
                                                                                    <br/>
                                                                                    <li><b>"chr_dna"</b>, <b>"start_dna"</b>, <b>"end_dna"</b> — coordinates of the DNA-parts of the contacts.</li>
                                                                                    <li><b>"n-reads (background)"</b> — number of background contacts in the bin (500 bp) to which the contact in question belongs.</li>
                                                                                    <li><b>"n-reads (normalized)"</b> — normalized contact value.</li>
                                                                                    <li><b>"intersects_peak"</b> — <b>1</b> if the coordinates of the DNA-part intersects with the peak, otherwise <b>0</b></li>
                                                                                    <li><b>"peak_name"</b> — name of the peak with which the DNA-part of the contact intersected.</li>
                                                                                </ul>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </div>
                                                                        <div>
                                                                            <Accordion>
                                                                                <AccordionSummary
                                                                                    expandIcon={<ExpandMoreIcon />}
                                                                                    aria-controls="panel1a-content"
                                                                                    id="panel1a-header"
                                                                                    style={{backgroundColor: '#b8b9ba'}}
                                                                                >
                                                                                    <Typography variant={"subtitle1"}>All-to-all experiment:</Typography>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                                <ul>
                                                                                    <li>Files in the <b>"contacts" </b>folder, columns in the following order: "rna_chr", "rna_start", "rna_end", "dna_chr", "dna_start", "dna_end", "sample", "gene_name", "gene_type", "gene_strand", "source", "expected_value", "norm_value".</li>
                                                                                    <br/>
                                                                                    <li><b>"rna_chr"</b>, <b>"rna_start"</b>, <b>"rna_end"</b> — coordinates of the RNA-parts of the contacts.</li>
                                                                                    <li><b>"dna_chr"</b>, <b>"dna_start"</b>, <b>"dna_end"</b> — coordinates of the DNA-parts of the contacts.</li>
                                                                                    <li><b>"sample"</b> — GSM ID to which this contact belongs.</li>
                                                                                    <li><b>"gene_name"</b>, <b>"gene_type"</b>, <b>"gene_strand"</b>, <b>"source"</b> of the gene with which the corresponding RNA-part of the contact is annotated.</li>
                                                                                    <li><b>"expected_value"</b> (or "n-reads (background)") — number of background contacts in the bin (500 bp) to which the contact in question belongs.</li>
                                                                                    <li><b>"norm_value"</b> (or "n-reads (normalized)") — normalized contact value.</li>
                                                                                </ul>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </div>
                                                                    </div>
                                                                    <li>You can find more information about the normalization procedure in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Contacts normalization"</a> section.</li>
                                                                </ul>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    <div style={{marginLeft: 40}}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{backgroundColor: '#b8b9ba'}}
                                                            >
                                                                <Typography variant={"subtitle1"}><b>Singletons</b> (only for all-to-all data).</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <ul>
                                                                    <li><b>Singletons</b> — contacts for which no correspondence was established between RNA-parts and genes from our general gene annotation.</li>
                                                                    <li>Files in the <b>"singletons"</b> folder, columns in the following order: "rna_chr", "rna_start", "rna_end", "dna_chr", "dna_start", "dna_end", "gene_strand".</li>
                                                                    <br/>
                                                                    <li><b>"rna_chr"</b>, <b>"rna_start"</b>, <b>"rna_end"</b> — coordinates of the RNA-parts of the contacts.</li>
                                                                    <li><b>"dna_chr"</b>, <b>"dna_start"</b>, <b>"dna_end"</b> — coordinates of the DNA-parts of the contacts.</li>
                                                                    <li><b>"gene_strand"</b> — strand that would match the strand of a genes.</li>
                                                                    <br/>
                                                                    <li>You can find more information about singletons in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Annotation of RNA-parts of contacts"</a> section.</li>
                                                                </ul>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    <div style={{marginLeft: 40}}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{backgroundColor: '#b8b9ba'}}
                                                            >
                                                                <Typography variant={"subtitle1"}><b>FastQC files</b> and <b>reads</b> processed by all processing steps up to the BackList step (only for one-to-all data).</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <ul>
                                                                For each dataset from the experiment, there are the following files:
                                                                    <li><b>SRA_ID_fastqc_raw.html</b> — file received using fastQC tool immediately after downloading the raw data.</li>
                                                                    <li><b>SRA_ID_fastqc_trimm.html</b> — file received using fastQC tool after trimming data.</li>
                                                                    <li><b>contacts.bed</b> — coordinates of the DNA- or RNA-parts of the contacts. You can find a description of the format <a href="https://genome.ucsc.edu/FAQ/FAQformat.html" target="_blank" rel="noopener noreferrer">here</a>.</li>
                                                                </ul>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    <div style={{marginLeft: 40}}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{backgroundColor: '#b8b9ba'}}
                                                            >
                                                                <Typography variant={"subtitle1"}><b>MACS2 peaks</b> (only for one-to-all data).</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <ul>
                                                                    <li><b>peaks.narrowPeak</b>. You can find a description of the format <a href="https://hbctraining.github.io/Intro-to-ChIPseq/lessons/05_peak_calling_macs.html" target="_blank" rel="noopener noreferrer">here</a>.</li>
                                                                    <li>You can see more about peak calling in the <a href="/protocol" target="_blank" rel="noopener noreferrer">"Contacts normalization"</a> section.</li>
                                                                </ul>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    <ul>
                                                        <li>Using the <b>web-interface</b>, you can download:</li>
                                                    </ul>
                                                    <div style={{marginLeft: 40}}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{backgroundColor: '#b8b9ba'}}
                                                            >
                                                                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Tables:</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <ul>
                                                                    <li><b>Database content</b></li>
                                                                    <div>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                                style={{backgroundColor: '#b8b9ba'}}
                                                                            >
                                                                                <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                            <ul>
                                                                                <li><b>Exp.ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database.</li>
                                                                                <li><b>Data type</b> — "one-to-all" or "all-to-all".</li> 
                                                                                <li><b>Method</b> — name of the experimental protocol used to obtain the data.</li>
                                                                                <li><b>RNA name (RNA-Chrom DB</b>) — unique RNA name used in the database.</li>
                                                                                <li><b>RNA type</b> — taken from the corresponding gene annotation.</li>
                                                                                <li><b>Number of peaks (article)</b> — number of genome regions statistically significantly enriched in contacts of the RNA of interest with chromatin, which was obtained in the article.</li>
                                                                                <li><b>Number of peaks (MACS2)</b> — number of genome regions statistically significantly enriched in contacts of the RNA of interest with chromatin, which was obtained by us using the MACS2 program (more details in the section <a href="/protocol" target="_blank" rel="noopener noreferrer">"Contacts normalization"</a>).</li>
                                                                                <li><b>Reads in peaks (% of BlackList step)</b> — number of reads (not-normalized contacts) that intersect the MACS2 peaks in the corresponding experiment. The percentage of "Reads in peaks" of the total number of reads that passed the last processing step (BlackList) is indicated in brackets.</li>
                                                                                <li><b>Cell line</b> — the cell line on which the experiment was performed.</li>
                                                                                <li><b>Cell type / Tissue</b> — the cell type / tissue on which the experiment was performed.</li>
                                                                                <li><b>Exp.description</b> — additional step in the experimental protocol.</li>
                                                                                <li><b>Organism</b></li>
                                                                                <li><b>RNA name (gencode v35</b>) — RNA name used in GENCODE gene annotation.</li>
                                                                                <li><b>RNA name (article)</b> — RNA name used in the article.</li>
                                                                                <li><b>Synonyms</b> — alternative names for the RNA (the gene that encodes it.)</li>
                                                                                <li><b>Full name RNA</b> — the full name is taken either from the article or from <a href="https://www.genecards.org/" target="_blank" rel="noopener noreferrer">"GeneCards: The Human Gene Database"</a> (for human RNAs) / <a href="http://www.informatics.jax.org/" target="_blank" rel="noopener noreferrer">"Mouse Genome Informatics"</a> (for mouse RNAs).</li>
                                                                                <li><b>Crosslinking agent</b> — fixes RNA-DNA interactions.</li>
                                                                                <li><b>Year</b> of publication of the article.</li>
                                                                                <li><b>Article</b> name.</li>
                                                                                <li><b>DOI</b></li>
                                                                                <li><b>GSM</b> — samples.</li>
                                                                                <li><b>GSE</b> — the NCBI GEO accession number for RNA-chromatin data reported in this paper.</li>
                                                                                <li><b>Processing protocol</b> — is usually "default", but may have small changes in special cases.</li>
                                                                                <li><b>Library layout</b> of RNA-chromatin data.</li>
                                                                                <li><b>Genome version</b> — assembly version of the genome to which the reads were mapped.</li>
                                                                            </ul>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </div>
                                                                    <li><b>Complete table of RNAs</b></li>
                                                                    <ul>
                                                                        <li><b>from RNA</b></li>
                                                                        <ul>
                                                                            <li><b>grouped table</b> (with applied filters)</li>
                                                                            <div>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                        style={{backgroundColor: '#b8b9ba'}}
                                                                                    >
                                                                                        <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                                    <ul>
                                                                                        <li>"RNA name"</li>
                                                                                        <li>"max CPKM (All-to-all)"</li>
                                                                                        <li>"max n-reads (All-to-all)"</li>
                                                                                        <li>"Number of experiments (All-to-all)"</li>
                                                                                        <li>"max n-reads (One-to-all)"</li>
                                                                                        <li>"Experiments (One-to-all)"</li>
                                                                                        <li>"Total number of experiments"</li>
                                                                                        <li>"RNA type"</li>
                                                                                        <li>"Chromosome"</li>
                                                                                        <li>"Gene start"</li>
                                                                                        <li>"Gene end"</li>
                                                                                        <li>"Gene strand"</li>
                                                                                        <li>"Gene length"</li>
                                                                                        <li>"Annotation source"</li>
                                                                                        <li>"Organism"</li>
                                                                                    </ul>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </div>
                                                                            <li><b>full ungrouped table</b></li>
                                                                            <div>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                        style={{backgroundColor: '#b8b9ba'}}
                                                                                    >
                                                                                        <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                                    <ul>
                                                                                        <li>"RNA name"</li>
                                                                                        <li>"Experiment ID"</li>
                                                                                        <li>"Data type"</li>
                                                                                        <li>"CPKM (normalized)"</li>
                                                                                        <li>"CPKM (raw)"</li>
                                                                                        <li>"n-reads (normalized)"</li>
                                                                                        <li>"n-reads (raw)"</li>
                                                                                        <li>"n-reads (norm. & in peaks)"</li>
                                                                                        <li>"n-reads (raw) & in peaks"</li>
                                                                                        <li>"RNA type"</li>
                                                                                        <li>"Chromosome"</li>
                                                                                        <li>"Gene start"</li>
                                                                                        <li>"Gene end"</li>
                                                                                        <li>"Gene strand"</li>
                                                                                        <li>"Gene length"</li>
                                                                                        <li>"Organism"</li>
                                                                                        <li>"Annotation source"</li>
                                                                                    </ul>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </div>      
                                                                        </ul>
                                                                        <li><b>from DNA</b></li>
                                                                        <ul>
                                                                            <li><b>grouped table</b> (with applied filters)</li>
                                                                            <div>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                        style={{backgroundColor: '#b8b9ba'}}
                                                                                    >
                                                                                        <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                                    <ul>
                                                                                        <li>"RNA name"</li>
                                                                                        <li>"max CPKM (All-to-all)"</li>
                                                                                        <li>"max n-reads (All-to-all)"</li>
                                                                                        <li>"Number of experiments (All-to-all) (DNA filter)"</li>
                                                                                        <li>"Number of experiments (All-to-all)"</li>
                                                                                        <li>"max n-reads (One-to-all)"</li>
                                                                                        <li>"Number of experiments (One-to-all) (DNA filter)"</li>
                                                                                        <li>"Number of experiments (One-to-all)"</li>
                                                                                        <li>"Total number of experiments"</li>
                                                                                        <li>"RNA type"</li>
                                                                                        <li>"Chromosome"</li>
                                                                                        <li>"Gene start"</li>
                                                                                        <li>"Gene end"</li>
                                                                                        <li>"Gene strand"</li>
                                                                                        <li>"Gene length"</li>
                                                                                        <li>"Annotation source"</li>
                                                                                        <li>"Organism"</li>
                                                                                    </ul>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </div>
                                                                            <li><b>ungrouped table</b> (with applied filters)</li>
                                                                            <div>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                        style={{backgroundColor: '#b8b9ba'}}
                                                                                    >
                                                                                        <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                                    <ul>
                                                                                        <li>"RNA name"</li>
                                                                                        <li>"Experiment ID"</li>
                                                                                        <li>"n-reads"</li>
                                                                                        <li>"Data type"</li>
                                                                                        <li>"CPKM"</li>
                                                                                        <li>"RNA type"</li>
                                                                                        <li>"Chromosome"</li>
                                                                                        <li>"Gene start"</li>
                                                                                        <li>"Gene end"</li>
                                                                                        <li>"Gene strand"</li>
                                                                                        <li>"Gene length"</li>
                                                                                        <li>"Annotation source"</li>
                                                                                        <li>"Organism"</li>
                                                                                    </ul>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </div>
                                                                        </ul>
                                                                    </ul>
                                                                    <li><b>All target genes</b> table (with applied filters)</li>
                                                                    <div>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                                style={{backgroundColor: '#b8b9ba'}}
                                                                            >
                                                                                <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                            <ul>
                                                                                <li>"Target gene"</li>
                                                                                <li>"Gene type"</li>
                                                                                <li>"Chromosome"</li>
                                                                                <li>"Gene start"</li>
                                                                                <li>"Gene end"</li>
                                                                                <li>"Gene strand"</li>
                                                                                <li>"Gene length"</li>
                                                                                <li>"Annotation source"</li>
                                                                                <li>"Density [up 50 kb; up 5 kb)"</li>
                                                                                <li>"Density [up 5 kb; gene body)"</li>
                                                                                <li>"Density [gene body]"</li>
                                                                                <li>"Density (gene body; down 5 kb]"</li>
                                                                                <li>"Density (down 5 kb; down 50 kb]"</li>
                                                                                <li>"Contacts [up 50 kb; up 5 kb)"</li>
                                                                                <li>"Contacts [up 5 kb; gene body)"</li>
                                                                                <li>"Contacts [gene body]"</li>
                                                                                <li>"Contacts (gene body; down 5 kb]"</li>
                                                                                <li>"Contacts (down 5 kb; down 50 kb]"</li>
                                                                                <li>"RNA name"</li>
                                                                                <li>"Experiment ID"</li>
                                                                                <li>"Organism"</li>
                                                                            </ul>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </div>
                                                                </ul>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    <div style={{marginLeft: 40}}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{backgroundColor: '#b8b9ba'}}
                                                            >
                                                                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Contacts:</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <ul>
                                                                    <li>On the page <b>"Graphical Summary"</b> in the table <b>"Contacts Summary"</b> you can download the <b>CSV-file</b> with the coordinates of the <span style={{color: 'red',fontWeight: 'bold'}}>DNA-parts of the contacts</span> grouped by bins (bin size = 200 bp) for the selected RNA in the specific experiment</li>
                                                                    <div>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                                style={{backgroundColor: '#b8b9ba'}}
                                                                            >
                                                                                <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                            <ul>
                                                                                <li>"Chromosome"</li>
                                                                                <li>"DNA-part start"</li>
                                                                                <li>"DNA-part end"</li>
                                                                                <li>"n-reads (normalized)"</li>
                                                                                <li>"n-reads (raw)"</li>
                                                                                <li>"n-reads (normalized & in peaks) (one-to-all)"</li>
                                                                                <li>"n-reads (raw & in peaks) (one-to-all)"</li>
                                                                                <li>"Density (normalized)"</li>
                                                                                <li>"Density (raw)"</li>
                                                                                <li>"Density (normalized & in peaks) (one-to-all)"</li>
                                                                                <li>"Density (raw & in peaks) (one-to-all)"</li>
                                                                            </ul>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </div>
                                                                    <li>On the page <b>"Distribution of RNA-parts across their source gene body"</b> in the accompanying table to the right of the bar chart, you can download the <b>CSV-file</b> with the coordinates of the <span style={{color: 'red',fontWeight: 'bold'}}>RNA-parts of the contacts</span> grouped by bins (bin size = 200 bp) for the selected RNA in the specific experiment</li>
                                                                    <div>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                                style={{backgroundColor: '#b8b9ba'}}
                                                                            >
                                                                                <Typography variant={"subtitle1"}>with the following columns:</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                            <ul>
                                                                                <li>"Chromosome"</li>
                                                                                <li>"RNA-part start"</li>
                                                                                <li>"RNA-part end"</li>
                                                                                <li>"n-reads"</li>
                                                                                <li>"Density"</li>
                                                                            </ul>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </div>
                                                                </ul>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    <div style={{marginLeft: 40}}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{backgroundColor: '#b8b9ba'}}
                                                            >
                                                                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Plots in svg format:</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                                    <ul>
                                                                        <li style={{marginLeft: 20}}>The camera icon is located at the top right above the chart. To download the chart, click on it.</li>
                                                                        <img src={downloadPlot} alt="downloadPlot"style={{border: '1px solid #000000'}}  width='100%'/>
                                                                    </ul>
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    <div style={{marginLeft: 40}}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{backgroundColor: '#b8b9ba'}}
                                                            >
                                                                <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Values on which the graphs were plotted:</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                                                <Typography variant="subtitle1" style={{marginBottom: 5}}>
                                                                    <ul>
                                                                        <li style={{marginLeft: 20}}>If you want to change the color scheme or labels for plots, such as <b>"Contacts Distribution"</b>, <b>"Comparative Heatmap"</b>, <b>"Distance Distribution"</b>, <b>"Distribution of RNA-parts across their source gene body"</b>, you can download the values by which they were plotted.</li>
                                                                        <li style={{marginLeft: 20}}>The download icon is above the chart, next to the normalization type selection.</li>
                                                                        <img src={downloadDataPlot} alt="downloadDataPlot"style={{border: '1px solid #000000'}}  width='100%'/>
                                                                    </ul>
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                </Typography>
                                            </div>
                                        </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ marginBottom: 5, textAlign: 'left',border: '1px solid #000000'}}>
                                <img src={basicWorkflowNew} alt="basicWorkflowNew" width='100%'/>
                            </div>
                            <Typography variant="subtitle1" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                                <i>Full workflow of data analysis.</i>
                            </Typography>
                        </Grid>
                    </Grid>
                    
            {/* <Divider className={classes.divider}/>        

                <Typography id="Tutorial" variant="h5" style={{fontWeight: 'bold', marginBottom: 20, marginTop: 20, textAlign: "center"}}>
                    Tutorial (лишь набросок, будет доробатываться)
                </Typography>
                <Grid container spasing={0} style={{marginTop: 40}}>
                    <Grid item xs={5} style={{marginBottom: 5}}>
                        <Typography variant="h6" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                            <b>"From RNA" analysis</b>
                        </Typography>
                        <Typography variant="subtitle1" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                            *Краткое содержание видео по пунктам*
                        </Typography>
                    </Grid>
                    <Grid item xs={7} style={{marginBottom: 5, border: '2px solid #000000'}}>
                        <ReactPlayer url='https://youtu.be/YB8bpC5YtTM' /> 
                    </Grid>
                </Grid>
                <Grid container spasing={0} style={{marginTop: 40}}>
                    <Grid item xs={5} style={{marginBottom: 5}}>
                        <Typography variant="h6" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                            <b>"From DNA" analysis</b>
                        </Typography>
                        <Typography variant="subtitle1" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                            *Краткое содержание видео по пунктам*
                        </Typography>
                    </Grid>
                    <Grid item xs={7} style={{marginBottom: 5, border: '2px solid #000000'}}>
                        <ReactPlayer url='https://youtu.be/mrb0SM3fMCc' /> 
                    </Grid>
                </Grid>
                <Grid container spasing={0} style={{marginTop: 40}}>
                    <Grid item xs={5} style={{marginBottom: 5}}>
                        <Typography variant="h6" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                            <b>Metadata / DB-content / Download</b>
                        </Typography>
                        <Typography variant="subtitle1" style={{textAlign: "center",  marginLeft: 10, marginBottom: 5}}>
                            *Краткое содержание видео по пунктам*
                        </Typography>
                    </Grid>
                    <Grid item xs={7} style={{marginBottom: 5, border: '2px solid #000000'}}>
                        <ReactPlayer url='https://youtu.be/ItsabjNkUSk' /> 
                    </Grid>
                </Grid> */}
                </TableContainer>
            {/* </Grid>
        </Grid> */}
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        loadingAllExps: state[module].loadingAllExps,
        AllExps: state[module].AllExps,
        snackOpenAllExps: state[module].snackOpenAllExps,
        snackTextAllExps: state[module].snackTextAllExps,
    }
  }
  
export default connect(mapStateToProps, { allExpsRequest, handleHeader })(OverviewBasic);