import React, { useEffect }  from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';

import { clearText } from '../actions/generalActions';

// const moduleTable = "table";
// const moduleFilter = "filter";

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      paddingTop: theme.spacing(),
    //   paddingBottom: theme.spacing(),
      marginRight: theme.spacing(2),
      width: '100%',
    },
    container: {
        flexWrap: 'wrap',
    },
    input: {
        width: '100%',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%',
      },
    width: {
        width: '94%',
    },
}));

const ValidationTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#009688',
          },
        '& input': {
            width: '100%',
        },
        '& input:valid + fieldset': {
            borderColor: '#009688',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: '#009688',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            borderColor: '#009688',
        },
    },
  })(TextField);


const InputThree = (props) => {
    
    const { nameSelect, valueLocus } = props; //placeholder, selectedItemOrganism, organism_chrs
    const classes = useStyles();

    // useEffect(() => {
    //     for (const one of valueLocus.replace(/;$/g, '').split(';').map(function(item) {return item.trim();})) {
    //       var locus_array = one.replace(/,+|\s+/g, '').split(/r|:|-/i).map(function(item) {return item.trim();})
    //       if (selectedItemOrganism.length !== 0) {
    //         if (locus_array.length === 2 && locus_array[0].toLowerCase() === 'ch' && organism_chrs[selectedItemOrganism[0]['value']].indexOf(locus_array[1].replace(/^0*/,'').toLowerCase()) !== -1) {
    //           props.currentLocusChange(valueLocus.replace(/;$/g, '').replace(/,+|\s+/g, '').toLowerCase())
    //           props.locusWarningChangeFalse()
    //         } else if (locus_array.length === 4 && locus_array[3] !== '') {
    //           if (locus_array[0].toLowerCase() === 'ch' && Number(locus_array[2].replace(/^0*/,'')) < Number(locus_array[3].replace(/^0*/,'')) && organism_chrs[selectedItemOrganism[0]['label']].indexOf(locus_array[1].replace(/^0*/,'').toLowerCase()) !== -1) {
    //             props.currentLocusChange(valueLocus.replace(/;$/g, '').replace(/,+|\s+/g, '').toLowerCase())
    //             props.locusWarningChangeFalse()
    //           } else {
    //             props.currentLocusChange('')
    //             props.locusWarningChangeTrue()
    //             break;
    //           }
    //         } else {
    //           if (valueLocus.length === 0) {  
    //             props.locusWarningChangeFalse()
    //           } else {
    //             props.locusWarningChangeTrue()
    //             break;
    //           }
    //           props.currentLocusChange('')
    //         }
    //       }
    //     }
    //   }, [valueLocus,selectedItemOrganism])

    useEffect(() => {
        props.clearText()
    }, [])

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.width}>
                <form className={classes.container} >
                    <ValidationTextField
                        label={nameSelect}
                        value={valueLocus}
                        multiline
                        size="small"
                        className={classNames(classes.input, classes.textField)}
                        onChange={props.textChange}
                        variant="outlined"
                        id="validation-outlined-input"
                    />
                </form>
            </FormControl>
        </div>
    );
}
        

const mapStateToProps = (state) => {
    return {
        // organism_chrs: state[moduleFilter].organism_chrs,
        // selectedItemOrganism: state[moduleTable].selectedItemOrganism,
    }
  }
export default connect(mapStateToProps, {clearText})(InputThree);