import React from 'react';
import Plot from 'react-plotly.js';
// import Paper from '@material-ui/core/Paper';
// import { connect } from 'react-redux';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// import Decorator from '../../components/HtmlTooltip';
import EnhancedTABLEpart from '../../components/TableGraphicalSummary';

// const module = "plots";

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '99%',
      textAlign: 'center',
    },
  }));

// const Heatmap_description = (data) => {
//     let List = []
//     let List_final = []
//     for (var k = 0; k < data.length; k++) {
//         if (List.indexOf(data[k]) === -1) {
//             List.push(data[k])
//         }
//     }
//     for (var i = 0; i < List.length; i++) {
//         var res = List[i].split(", ")
//         List_final.push(<Typography key={i}>
//                             -the tracks from the experiment <b>({res[2]})</b> obtained by the <b>{res[0]}</b> method on the <b>{res[1]}</b> cell line
//                         </Typography>
//         )
//     }
//     return List_final
// }
export const normalizationName = (name) => {
    let normalization_name = ""
    if (name === "norm_value") {
        normalization_name = "background-normalized"
    } else if (name === "observed_value") {
        normalization_name = "not-normalized"
    } else if (name === "norm_value_in_peak") {
        normalization_name = "background-normalized & in peaks"
    } else if (name === "observed_value_in_peak") {
        normalization_name = "not-normalized & in peaks"
    }
    return normalization_name
}

export const Track_description = (data) => {
    let track_description = []
    // [ "RNA filter: XIST_1 | DNA filter: Gene body: XIST_1, $Upstream: all genes$Downstream: all genes" ]
    let description_track_name = []
    // console.log('data',data)
    for (var k = 0; k < data.length; k++) {
        // console.log(data[k],k)
        // debugger
        description_track_name = data[k]
        let RNA_filter = description_track_name.split(" | ")[0].split("RNA filter: ")[1]
        let DNA_filter = description_track_name.split(" | ")[1].split("DNA filter: ")[1]
        let DNA_filter_inser = ""
        let intersection = ''
        // console.log('description_track_name',description_track_name)
        // console.log('RNA_filter',RNA_filter)
        // console.log('DNA_filter',DNA_filter)
        if (description_track_name.split("Intersection").length > 1) {
            intersection = description_track_name.split("Intersection")[1]
        }
        if (DNA_filter.indexOf('DNA locus') !== -1) {
            DNA_filter_inser = DNA_filter_inser + "DNA locus"
            DNA_filter_inser = DNA_filter_inser + DNA_filter.split('DNA locus')[1].split('$')[0] + ' '
        }
        if (DNA_filter.indexOf('Gene body') !== -1) {
            DNA_filter_inser = DNA_filter_inser + "Gene body"
            DNA_filter_inser = DNA_filter_inser + DNA_filter.split('Gene body')[1].split('$')[0] + ' '
        }
        if (DNA_filter.indexOf('Upstream:') !== -1) {
            DNA_filter_inser = DNA_filter_inser + "Upstream"
            DNA_filter_inser = DNA_filter_inser + DNA_filter.split('Upstream')[1].split('$')[0] + ' '
        }
        if (DNA_filter.indexOf('Downstream:') !== -1) {
            DNA_filter_inser = DNA_filter_inser + "Downstream"
            DNA_filter_inser = DNA_filter_inser + DNA_filter.split('Downstream')[1].split('$')[0] + ' '
        }
        if (DNA_filter.indexOf('Upstream50kb:') !== -1) {
            DNA_filter_inser = DNA_filter_inser + "Upstream50kb"
            DNA_filter_inser = DNA_filter_inser + DNA_filter.split('Upstream50kb')[1].split('$')[0] + ' '
        }
        if (DNA_filter.indexOf('Downstream50kb:') !== -1) {
            DNA_filter_inser = DNA_filter_inser + "Downstream50kb"
            DNA_filter_inser = DNA_filter_inser + DNA_filter.split('Downstream50kb')[1].split('$')[0] + ' '
        }
        if (DNA_filter.indexOf('GeneDeserts:') !== -1) {
            DNA_filter_inser = DNA_filter_inser + "GeneDeserts"
            DNA_filter_inser = DNA_filter_inser + DNA_filter.split('GeneDeserts')[1].split('$')[0] + ' '
        }
        if (DNA_filter_inser === '') {
            DNA_filter_inser = 'NaN'
        }
        if (intersection.length === 0) {
            track_description.push(`RNA filter: ${RNA_filter}, DNA filter: ${DNA_filter_inser}`)
        } else {
            track_description.push(`RNA filter: ${RNA_filter}, DNA filter: ${DNA_filter_inser}, Intersection ${intersection}`)
        }
        
    }
    // console.log('track_descriptiontrack_descriptiontrack_description', data)
    // console.log('track_description',track_description)
    return track_description
}

const HeatmapsDATA = (props) => {

    let data_left = []
    let data_right = []
    let data_list = []
    
    data_list = [data_left ,data_right]
    let plot_count = 0

    let who = 'hz'
    var ololo = []
        
    const classes = useStyles();
    const { genomeWideData, trackNameAndColor } = props;
    // console.log('genomeWideData',genomeWideData)
    for (var key in genomeWideData) {
        plot_count += 1
        // console.log('key!!!!!!!!!!!!!!!!________',key)
        // console.log('key.split(_)',key.split('_'))
        // let for_description = Heatmap_description(genomeWideData[key]['count'][1])
        // console.log('for_description HEATMAP', for_description)
        let data = plot_count % 2 === 1 ? data_list[0] : data_list[1]
        var data_for_profile = []
        let trackDescription = Track_description(genomeWideData[key].count[0].map(function(item) {return item.split('|*|')}).map(function(item) {return item[1]}))
        // console.log('trackDescription!!!!!!!!!!!!!!!!!!!!!!!',trackDescription)
        let trackkk = genomeWideData[key].count[0].map(function(item) {return item.split('|*|')}).map(function(item) {return item[0]})
        // console.log('trackkk!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!',trackkk)
        for (var numberOfcells = 0; numberOfcells < trackkk.length; numberOfcells ++) {
            // console.log('numberOfcells!!!!!!!!!!!!!!!!',numberOfcells)
            data_for_profile.push(
                {
                'track': [trackkk[numberOfcells].replace(/<br>/g, ' '), "ID"],
                'method': [genomeWideData[key].count[1][numberOfcells].replace(/<br>/g, ' ').split('exp.protocol: ')[1].split('; extra:')[0], "Method"],
                // 'color': 'none',
                'totalContactsCount': [genomeWideData[key].count[2][numberOfcells], "n-reads"],
                'trackDescription': [trackDescription[numberOfcells].replace(/<br>/g, ' '), "Track description"],
                // 'expID': [genomeWideData[key].count[1][numberOfcells].replace(/<br>/g, ' ').split('; cell line:')[0].split('ID: ')[1], "Exp.ID"],
                'cellLine': [genomeWideData[key].count[1][numberOfcells].replace(/<br>/g, ' ').split('cell line: ')[1].split('; exp.protocol:')[0], "Cell line"],
                'datasetDescription': [genomeWideData[key].count[1][numberOfcells].replace(/<br>/g, ' ').split('extra: ')[1], "Exp.description"],
                'typeOfExperiment': [genomeWideData[key]['typeOfExperiment_true'][numberOfcells], "Data type"],
                }
            )
        }
        // console.log('genomeWideData genomeWideData[key].count[1]',genomeWideData[key].count[1])
        if (props.plotType_2 === 'Basic') {
            ololo.push(
                <div id={123}>
                    <Grid container spacing={0} className={classes.root}>
                        <Grid item xs={6}>
                        <Plot
                                    data={[
                                        {
                                            z: genomeWideData[key].z,
                                            x: genomeWideData[key].x,
                                            y: genomeWideData[key].count[1].map(value => {return "Exp." + value.split('; cell line:')[0]+ ", " + value.split('exp.protocol: ')[1].split('; extra:')[0]}),
                                            type: 'heatmap',
                                            colorscale: 'YIOrRd', //hot
                                            reversescale: true,
                                            name: `${key.split('_')[0]}`,
                                            xaxis: 'x2',
                                            yaxis: 'y2',
                                            colorbar: {
                                                    title: '<b>Contacts</b><br><b>Density</b>',
                                                    titlefont: { size: 15 },
                                            }
                                        },
                                    ]}
                                    layout={{
                                        height: props.plotType === "genomeWide" ? 450 : genomeWideData[key].y.length > 10 ? 40*genomeWideData[key].y.length : 450,
                                        width: 600, //1200
                                        title:`<b>${genomeWideData[key].y[0].split('(')[1].split(')')[0]}</b>, <i>${key.split('_')[0]}</i>, <b>${normalizationName(genomeWideData[key]['selectedNormalization'])}</b>`, //, ${contactsType}
                                        titlefont: { size: 20 },
                                        yaxis2: {
                                            domain: genomeWideData[key].y.length < 5 ? [0, 0.2*genomeWideData[key].y.length] : [0, 1],
                                            title: `${key.split('_')[2]}` === "Tracks" ? "" : `${key.split('_')[2]}`,
                                            titlefont: { size: 20 },
                                        },
                                        xaxis2: {
                                            title: 'Chromosome targets',
                                            titlefont: { size: 20 },
                                            tickangle: -90,
                                            domain: [0.1,1],
                                        },
                                    }}
                                config = {{
                                    modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian'],
                                    // displayModeBar: true,
                                    displaylogo: false,
                                    doubleClickDelay: 1000,
                                    toImageButtonOptions: {format: 'svg', filename: 'Heatmap', scale: 1},
                                }}
                                />
                        </Grid>
                        <Grid item xs={6}>
                            <EnhancedTABLEpart  from={'basic'} data={data_for_profile} trackNameAndColor={trackNameAndColor}/>
                        </Grid>
                    </Grid>
                </div>
            )
        } else { 
            data.push(
                <div key={key}>
                    {/* <Decorator 
                        header = {<u>{'This plot shows:'}</u>}
                        text = {for_description}
                        component = {
                            <div> */}
                            <div>
                                <EnhancedTABLEpart  data={data_for_profile} trackNameAndColor={trackNameAndColor}/>
                            </div>
                            <div>
                                <Plot
                                    data={[
                                        // {
                                        //     type: 'table',
                                        //     header: {
                                        //         values: [["<b>Track (RNAs)</b>"],
                                        //                 ["<b>Track description</b>"],
                                        //                 ["<b>Dataset description</b>"], 
                                        //                 ["<b>Normalized number of contacts</b>"]],
                                        //         align: ["center", "center", "center", "center"],
                                        //         line: { width: 1, color: 'black' },
                                        //         fill: { color: 'grey' },
                                        //         font: { family: "Arial", size: 12, color: "black" },
                                        //         height: 27,
                                        //     },
                                        //     cells: {
                                        //         values: [genomeWideData[key].count[0].map(function(item) {return item.split('|*|')}).map(function(item) {return item[0]}),
                                        //                 Track_description(genomeWideData[key].count[0].map(function(item) {return item.split('|*|')}).map(function(item) {return item[1]})),
                                        //                 genomeWideData[key].count[1],
                                        //                 genomeWideData[key].count[2]],
                                        //         align: ["left", "left", "left", "center"],
                                        //         line: { color: "black", width: 1 },
                                        //         format: ['', '', '', ",.r"],
                                        //         // fill: { color: ['#25FEFD', 'white'] },
                                        //         font: { family: "Arial", size: 12, color: ["black"] },
                                        //         height: 27,
                                        //     },
                                        //     yaxis: 'y1',
                                        //     domain: {x: [0,1], y: [0.7,1]}
                                        // },
                                        {
                                            z: genomeWideData[key].z,
                                            x: genomeWideData[key].x,
                                            y: genomeWideData[key].y, 
                                            type: 'heatmap',
                                            colorscale: 'YIOrRd', //hot
                                            reversescale: true,
                                            // tickangle: 180,
                                            name: `${key.split('_')[0]}`,
                                            xaxis: 'x2',
                                            yaxis: 'y2',
                                            colorbar: {
                                                    title: '<b>Contacts</b><br><b>Density</b>',
                                                    titlefont: { size: 15 },
                                                    // y: 0.35,
                                                    // len: 0.8}
                                            }
                                            // domain: {x: [0,1], y: [0,1]}
                                        },
                                    ]}
                                    layout={{
                                        // grid: {
                                        //     rows: 2,
                                        //     columns: 1,
                                        //     // pattern: 'coupled',//'independent',
                                        //     // roworder: 'bottom to top',
                                        //     },
                                        // yaxis1: {domain: [0, 0.1]},
                                        // yaxis2: genomeWideData[key].y.length < 7 ? {domain: [0, 0.1*genomeWideData[key].y.length]} : {domain: [0, 0.65]},
                                        height: props.plotType === "genomeWide" ? 450 : genomeWideData[key].y.length > 10 ? 50*genomeWideData[key].y.length : 450,
                                        // height: genomeWideData[key].y.length > 10 ? 70*genomeWideData[key].y.length : 650,
                                        width: 650, //1200
                                        title:`<b>${genomeWideData[key].y[0].split('(')[1].split(')')[0]}</b>, <i>${key.split('_')[0]}</i>, <b>${normalizationName(genomeWideData[key]['selectedNormalization'])}</b>`, //, ${contactsType}
                                        titlefont: { size: 20 },
                                        yaxis2: {
                                            domain: genomeWideData[key].y.length < 5 ? [0, 0.2*genomeWideData[key].y.length] : [0, 1],
                                            // domain: [0, 0.3*genomeWideData[key].y.length],
                                            // title: 'RNA genes localization',
                                            title: `${key.split('_')[2]}` === "Tracks" ? "" : `${key.split('_')[2]}`,
                                            titlefont: { size: 20 },
                                        },
                                        xaxis2: {
                                            title: 'Chromosome targets',
                                            titlefont: { size: 20 },
                                            tickangle: -90,
                                            domain: [0.1,1],
                                        },
                                    }}
                                config = {{
                                    modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian'],
                                    // displayModeBar: true,
                                    // scrollZoom: true,
                                    displaylogo: false,
                                    // editable: true,
                                    doubleClickDelay: 1000,
                                    toImageButtonOptions: {format: 'svg', filename: 'Heatmap', scale: 1},
                                }}
                                />
                            </div>
                            {/* </div>
                        }
                    /> */}
                </div>
            )
        }
    }
    if (props.plotType_2 !== 'Basic') {
        if (data_left.length < data_right.length) {
            who = 'data_right'
        } else {
            who = 'data_left'
        }
        
        for (var i = 0; i < Math.trunc((data_left.length + data_right.length) / 2) ; i++) {
            // console.log('i',i)
            ololo.push(
                <div id={i}>
                    <Grid container spacing={0} className={classes.root}>
                        <Grid item xs={6}>
                            {data_left[i]}
                        </Grid>
                        <Grid item xs={6}>
                            {data_right[i]}
                        </Grid>
                    </Grid>
                </div>
            )
        }
        // console.log('who',who)
        // console.log('i!!!!!',i)
        // console.log('data_left',data_left)
        // console.log('data_left.length',data_left.length)
        // console.log('data_right.length',data_right.length)
        if (who !== 'hz') {
            if (who === 'data_right') {
                ololo.push(
                    <div id={i}>
                        <Grid container spacing={0} className={classes.root}>
                            <Grid item xs={6}>
                                {data_right[i]}
                            </Grid>
                            <Grid item xs={6}>
                                {null}
                            </Grid>
                        </Grid>
                    </div>
                )
            } else {
                ololo.push(
                    <div id={i}>
                        <Grid container spacing={0} className={classes.root}>
                            <Grid item xs={6}>
                                {data_left[i]}
                            </Grid>
                            <Grid item xs={6}>
                                {null}
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        }
    }
    
    return (
        <div id='myDiv'>
            {ololo}
            {/* <Grid container spacing={1} className={classes.root}>
                <Grid item xs={6}>
                    {data_left}
                </Grid>
                <Grid item xs={6}>
                    {data_right}
                </Grid>
            </Grid> */}
        </div>
    )
}

  
// const mapStateToProps = (state) => {
//     return {
//         genomeWideData: state[module].genomeWideData,
//     }
// }
    
  
// export default connect(mapStateToProps, )(HeatmapsDATA);
export default HeatmapsDATA;