export const HANDLE_CURRENT_ADD_TO_TRACK_LIST = "HANDLE_CURRENT_ADD_TO_TRACK_LIST"

export const changeCurrent_add_to_Track_list = (value) => {
    return {
        type: HANDLE_CURRENT_ADD_TO_TRACK_LIST,
        value,
    };
};

export const TEXT_FILTER_DNA_CHANGE = "TEXT_FILTER_DNA_CHANGE";
export const CURRENT_LOCUS_FILTER_DNA_CHANGE = "CURRENT_LOCUS_FILTER_DNA_CHANGE";

export const textFilterDnaChange = event => {
    return {
        type: TEXT_FILTER_DNA_CHANGE,
        newValue: event.target.value,
    };
};

export const currentLocusFilterDnaChange = (input_value) => {
    return {
        type: CURRENT_LOCUS_FILTER_DNA_CHANGE,
        newLocus: input_value,
    };
};

export const LOCUS_WARNING_GENE_BODY_FILTER_FALSE = "LOCUS_WARNING_GENE_BODY_FILTER_FALSE"
export const LOCUS_WARNING_GENE_BODY_FILTER_TRUE = "LOCUS_WARNING_GENE_BODY_FILTER_TRUE"

export const locusWarningFilterDnaFalse = event => {
    return {
        type: LOCUS_WARNING_GENE_BODY_FILTER_FALSE,
    };
};

export const locusWarningFilterDnaTrue = event => {
    return {
        type: LOCUS_WARNING_GENE_BODY_FILTER_TRUE,
    };
};

//Gene Body

export const HANDLE_GENE_SELECT_GENE_BODY_FILTER = "HANDLE_GENE_SELECT_GENE_BODY_FILTER";
export const HANDLE_CURRENT_GENE_INPUT_GENE_BODY_FILTER = "HANDLE_CURRENT_GENE_INPUT_GENE_BODY_FILTER"

export const handleGeneSelectForGeneBodyFilter = (geneName) => {
    return {
        type: HANDLE_GENE_SELECT_GENE_BODY_FILTER,
        geneName,
    };
};

export const changeCurrentGeneInputForGeneBodyFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_INPUT_GENE_BODY_FILTER,
        value,
    };
};

export const HANDLE_GENE_TYPES_SELECT_GENE_BODY_FILTER = "HANDLE_GENE_TYPES_SELECT_GENE_BODY_FILTER";
export const HANDLE_CURRENT_GENE_TYPES_INPUT_GENE_BODY_FILTER = "HANDLE_CURRENT_GENE_TYPES_INPUT_GENE_BODY_FILTER"

export const handleGeneTypesSelectForGeneBodyFilter = (geneName) => {
    return {
        type: HANDLE_GENE_TYPES_SELECT_GENE_BODY_FILTER,
        geneName,
    };
};

export const changeCurrentGeneTypesInputForGeneBodyFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_TYPES_INPUT_GENE_BODY_FILTER,
        value,
    };
};

//Upstream

export const HANDLE_GENE_SELECT_UPSTREAM_FILTER = "HANDLE_GENE_SELECT_UPSTREAM_FILTER";
export const HANDLE_CURRENT_GENE_INPUT_UPSTREAM_FILTER = "HANDLE_CURRENT_GENE_INPUT_UPSTREAM_FILTER"

export const handleGeneSelectForUpstreamFilter = (geneName) => {
    return {
        type: HANDLE_GENE_SELECT_UPSTREAM_FILTER,
        geneName,
    };
};

export const changeCurrentGeneInputForUpstreamFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_INPUT_UPSTREAM_FILTER,
        value,
    };
};

export const HANDLE_GENE_TYPES_SELECT_UPSTREAM_FILTER = "HANDLE_GENE_TYPES_SELECT_UPSTREAM_FILTER";
export const HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM_FILTER = "HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM_FILTER"

export const handleGeneTypesSelectForUpstreamFilter = (geneName) => {
    return {
        type: HANDLE_GENE_TYPES_SELECT_UPSTREAM_FILTER,
        geneName,
    };
};

export const changeCurrentGeneTypesInputForUpstreamFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM_FILTER,
        value,
    };
};

//Downstream

export const HANDLE_GENE_SELECT_DOWNSTREAM_FILTER = "HANDLE_GENE_SELECT_DOWNSTREAM_FILTER";
export const HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM_FILTER = "HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM_FILTER"

export const handleGeneSelectForDownstreamFilter = (geneName) => {
    return {
        type: HANDLE_GENE_SELECT_DOWNSTREAM_FILTER,
        geneName,
    };
};

export const changeCurrentGeneInputForDownstreamFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM_FILTER,
        value,
    };
};

export const HANDLE_GENE_TYPES_SELECT_DOWNSTREAM_FILTER = "HANDLE_GENE_TYPES_SELECT_DOWNSTREAM_FILTER";
export const HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM_FILTER = "HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM_FILTER"

export const handleGeneTypesSelectForDownstreamFilter = (geneName) => {
    return {
        type: HANDLE_GENE_TYPES_SELECT_DOWNSTREAM_FILTER,
        geneName,
    };
};

export const changeCurrentGeneTypesInputForDownstreamFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM_FILTER,
        value,
    };
};


//Upstream 50kb

export const HANDLE_GENE_SELECT_UPSTREAM50KB_FILTER = "HANDLE_GENE_SELECT_UPSTREAM50KB_FILTER";
export const HANDLE_CURRENT_GENE_INPUT_UPSTREAM50KB_FILTER = "HANDLE_CURRENT_GENE_INPUT_UPSTREAM50KB_FILTER"

export const handleGeneSelectForUpstream50kbFilter = (geneName) => {
    return {
        type: HANDLE_GENE_SELECT_UPSTREAM50KB_FILTER,
        geneName,
    };
};

export const changeCurrentGeneInputForUpstream50kbFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_INPUT_UPSTREAM50KB_FILTER,
        value,
    };
};

export const HANDLE_GENE_TYPES_SELECT_UPSTREAM50KB_FILTER = "HANDLE_GENE_TYPES_SELECT_UPSTREAM50KB_FILTER";
export const HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM50KB_FILTER = "HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM50KB_FILTER"

export const handleGeneTypesSelectForUpstream50kbFilter = (geneName) => {
    return {
        type: HANDLE_GENE_TYPES_SELECT_UPSTREAM50KB_FILTER,
        geneName,
    };
};

export const changeCurrentGeneTypesInputForUpstream50kbFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM50KB_FILTER,
        value,
    };
};

//Downstream 50kb

export const HANDLE_GENE_SELECT_DOWNSTREAM50KB_FILTER = "HANDLE_GENE_SELECT_DOWNSTREAM50KB_FILTER";
export const HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM50KB_FILTER = "HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM50KB_FILTER"

export const handleGeneSelectForDownstream50kbFilter = (geneName) => {
    return {
        type: HANDLE_GENE_SELECT_DOWNSTREAM50KB_FILTER,
        geneName,
    };
};

export const changeCurrentGeneInputForDownstream50kbFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM50KB_FILTER,
        value,
    };
};

export const HANDLE_GENE_TYPES_SELECT_DOWNSTREAM50KB_FILTER = "HANDLE_GENE_TYPES_SELECT_DOWNSTREAM50KB_FILTER";
export const HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM50KB_FILTER = "HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM50KB_FILTER"

export const handleGeneTypesSelectForDownstream50kbFilter = (geneName) => {
    return {
        type: HANDLE_GENE_TYPES_SELECT_DOWNSTREAM50KB_FILTER,
        geneName,
    };
};

export const changeCurrentGeneTypesInputForDownstream50kbFilter = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM50KB_FILTER,
        value,
    };
};



export const CHANGE_INCREASE_MAX_NUMBER_TRACK_NAME = "CHANGE_INCREASE_MAX_NUMBER_TRACK_NAME"

export const changeIncreaseMaxNumberTrackName = (value) => {
    return {
        type: CHANGE_INCREASE_MAX_NUMBER_TRACK_NAME,
        value,
    };
};

export const CHANGE_DECREASE_MAX_NUMBER_TRACK_NAME = "CHANGE_DECREASE_MAX_NUMBER_TRACK_NAME"

export const changeDecreaseMaxNumberTrackName = (selected_list) => {
    return {
        type: CHANGE_DECREASE_MAX_NUMBER_TRACK_NAME,
        selected_list,
    };
};

export const CLEAR_CONTACTS = "CLEAR_CONTACTS";

export const clearContacts = () => {
    return {
        type: CLEAR_CONTACTS,
    };
};

export const CLEAR_RNA_CHECKBOXES = "CLEAR_RNA_CHECKBOXES";

export const clearRNACheckboxes = () => {
    return {
        type: CLEAR_RNA_CHECKBOXES,
    };
};

export const SET_CONTACTS_CHECKBOX = "SET_CONTACTS_CHECKBOX";

export const setContactsCheckbox = (name, checked) => {
    return {
        type: SET_CONTACTS_CHECKBOX,
        name, checked,
    };
};

export const SET_RNA_CHECKBOX = "SET_RNA_CHECKBOX";

export const setRNACheckbox = (name, checked) => {
    return {
        type: SET_RNA_CHECKBOX,
        name, checked,
    };
};

export const SET_TRACK_LIST_CHECKBOX = "SET_TRACK_LIST_CHECKBOX";

export const setTrackListCheckbox = (name) => {
    return {
        type: SET_TRACK_LIST_CHECKBOX,
        name
    };
};







export const SET_TRACK_LIST_CHECKBOX_STATE = "SET_TRACK_LIST_CHECKBOX_STATE";

export const setTrackListCheckboxState = (name, checked) => {
    return {
        type: SET_TRACK_LIST_CHECKBOX_STATE,
        name, checked,
    };
};