import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
// import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid'; 
// import Paper from '@material-ui/core/Paper';
// import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

// import TableNewSuper from '../components/TableNewSuper';
import WarningMessage from '../components/MessageSnackbar';

import { submitTracksComparison, handleHeader } from '../actions/generalActions';
import { submitCheckedTracks,clearGenomeBrowserURL } from '../actions/genomeBrowserActions';

const module = "table";
const moduleGenomeBrowser = "genomeBrowser";

const TooltipCell = React.forwardRef((props, ref) => 
        <TableCell {...props} ref={ref}>
            {props.children}
        </TableCell>
);

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
const stableSort = (array, cmp) =>{
  // console.log('array',array)
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  

const TableHeader = (props) => {
  
  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };
  
  const { order, orderBy, data} = props;
  const classes = useStyles();
  const [rows, setRows] = useState([])

  useEffect(() => {  
    if (data.length !== 0) {
      // console.log('Data',data) 
      // console.log('length',data['x'].length)    
      // const keysData = Object.keys(data[0])
      // console.log('keysData',keysData)
      setRows([])
      for (var numberOfcolumns = 0; numberOfcolumns < data['x'].length; numberOfcolumns ++) {
        // console.log('numberOfcolumns',numberOfcolumns,data['x'][numberOfcolumns])
        let value = data['x'][numberOfcolumns]
        setRows(rows => [...rows, { id: value.replace(/\s+/g, ''), numeric: false, disablePadding: false, label: value}])
        // console.log('data[x][numberOfcolumns]',data['x'][numberOfcolumns])
        // console.log('rows!!!!!!!!',rows)
      }
    }
  }, [data])  

  let headCell = null
  // console.log('data[selectedNormalization]****************************************************',data['selectedNormalization'])  
  return (
    <TableHead>
      <TableRow > 
        {rows.map(row => {
          if (Object.keys(data['y']).includes(row['label'])) {
            let description_track_name = data['y'][row['label']]
            let RNA_filter = description_track_name.split(" | ")[0].split("RNA filter: ")[1]
            let DNA_filter = description_track_name.split(" | ")[1].split("DNA filter: ")[1]
            let DNA_filter_inser = []
            let normalization = [data['selectedNormalization']]
            if (DNA_filter.indexOf('Gene body') !== -1) {
                DNA_filter_inser.push(<u>Gene body</u>)
                DNA_filter_inser.push(DNA_filter.split('Gene body')[1].split('$')[0] + ' ')
            }
            if (DNA_filter.indexOf('Upstream:') !== -1) {
                DNA_filter_inser.push(<u>Upstream</u>)
                DNA_filter_inser.push(DNA_filter.split('Upstream')[1].split('$')[0] + ' ')
            }
            if (DNA_filter.indexOf('Downstream:') !== -1) {
                DNA_filter_inser.push(<u>Downstream</u>)
                DNA_filter_inser.push(DNA_filter.split('Downstream')[1].split('$')[0] + ' ')
            }
            if (DNA_filter.indexOf('Upstream50kb:') !== -1) {
                DNA_filter_inser.push(<u>Upstream50kb</u>)
                DNA_filter_inser.push(DNA_filter.split('Upstream50kb')[1].split('$')[0] + ' ')
            }
            if (DNA_filter.indexOf('Downstream50kb:') !== -1) {
                DNA_filter_inser.push(<u>Downstream50kb</u>)
                DNA_filter_inser.push(DNA_filter.split('Downstream50kb')[1].split('$')[0] + ' ')
            }
            if (DNA_filter.indexOf('GeneDeserts:') !== -1) {
                DNA_filter_inser.push(<u>GeneDeserts</u>)
                DNA_filter_inser.push(DNA_filter.split('GeneDeserts')[1].split('$')[0] + ' ')
            }
            if (DNA_filter.indexOf('DNA locus:') !== -1) {
                DNA_filter_inser.push(<u>DNA locus</u>)
                DNA_filter_inser.push(DNA_filter.split('DNA locus')[1].split('$')[0] + ' ')
            }
            if (DNA_filter_inser.length === 0) {
                DNA_filter_inser.push('NaN')
            }
            headCell =
              <Tooltip
                placement={"right-start"}
                title={
                      <React.Fragment>
                        <div className={classes.tooltip}>
                          <Typography color="inherit" variant="h5" component="h1"><u>{`Track description:`}</u></Typography>
                          <Typography>
                            <b>RNA filter: </b>{RNA_filter}<br/><b>DNA filter: </b>{DNA_filter_inser}<br/><b>Normalization: </b>{normalization}
                          </Typography>
                        </div>
                      </React.Fragment>
                      }
              >
                <TooltipCell
                  className={classes.sizeSmall}
                  style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5'}} //,whiteSpace: 'nowrap'
                  key={row.id}
                  // size="small"
                  align={row.numeric ? 'right' : 'center'}
                  padding={row.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={orderBy === row.id ? order : 'asc'}
                    onClick={createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </TooltipCell>
              </Tooltip>
          } else {
            headCell =
            <TableCell
            className={classes.sizeSmall}
            style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5'}} //,whiteSpace: 'nowrap'
            key={row.id}
            // size="small"
            align={row.numeric ? 'right' : 'center'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
                active={orderBy === row.id}
                direction={orderBy === row.id ? order : 'asc'}
                onClick={createSortHandler(row.id)}
            >
                 {row.label}
              </TableSortLabel>
          </TableCell>
          }
          return (
            headCell
          );
        })}
      </TableRow>
    </TableHead>
  );
}
  
TableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      textAlign: 'center',
      flexGrow: 1,
      // marginBottom: theme.spacing(5),
      // marginTop: 50,
      // maxHeight: window.innerHeight*0.7,
      paddingTop: "30px !important",
      // backgroundColor: theme.palette.background.paper,
      
    },
    container: {
      maxHeight: window.innerHeight*0.82,
    },
    table: {
      minWidth: 50,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    loading: {
      marginTop: 5,
      textAlign: 'center',
      // marginLeft: theme.spacing()
    },
    sizeSmall: {
      padding: '0px 5px 0px 5px',
    },
    wideButton: {
      // padding: theme.spacing(),
      marginLeft: theme.spacing(),
      // marginRight: theme.spacing(),
      marginTop: theme.spacing(),
      // marginBottom: theme.spacing(),
      width: '100%',
      height: '98%',
      color: 'white',
    },
}));

const TableTracksComparison = (props) => {
  
  const [dataPerPage, setDataPerPage] = useState([])
  const [order,setOrder] = useState('asc')
  const [percentage, setPercentage] = useState('100%')
  const [orderBy, setOrderBy] = useState('Obtaining data method')
  const [selected, setSelected] = useState([]);
  const [buttonColor, setButtonColor] = useState('#bdbdbd')
  const [buttonTextColor, setButtonTextColor] = useState('black')
  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setMessage('')
  }

  const classes = useStyles();  
  
  const { TracksComparisonData, loadingTracksComparison } = props

  useEffect(() => {
    // console.log('TracksComparisonData',TracksComparisonData)
    if (TracksComparisonData.length !== 0) {
      setDataPerPage(TracksComparisonData)
      if (TracksComparisonData['x'].length > 8) {
        setPercentage('99%')
      } else {
        setPercentage(10*TracksComparisonData['x'].length.toString() + '%')
      } 
    }
  }, [TracksComparisonData])
  // console.log('dataPerPage',dataPerPage)
  // console.log('TracksComparisonData',TracksComparisonData)


  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data_comparison"));
    document.title = `Comparison: ${data.selectedTrackNames.join(', ')}`;
    props.handleHeader(': RNAs distribution')
    // console.log('data',data)
    // props.fetchTableTracksComparisonData(data.selectedExperiments)
    props.submitTracksComparison(data.selectedTracks,data.typeOfContacts, data.selectedTrackNames, data.organism, data.selectedNormalization)
  }, [])
  // console.log('TracksComparisonData',TracksComparisonData)
  // console.log('loadingTracksComparison',loadingTracksComparison)
  // let table = null
  
  const handleRequestSort = (event, property) => {
    const const_orderBy = property;
    let let_order = 'desc';
    if (orderBy === property && order === 'desc') {
      let_order = 'asc';
    }
    setOrder(let_order)
    setOrderBy(const_orderBy)
  };

  useEffect(() => {
    if (selected.length > 0) {
      setButtonColor('#96000E')
      setButtonTextColor('white')
    } else {
      setButtonColor('#bdbdbd')
      setButtonTextColor('black')
    }
  }, [selected])

  const handleClick = (event, GeneType, Intersection) => {
    if (Intersection !== 0) {
      let newSelected = selected.slice()
      // console.log('newSelected',newSelected)
      // console.log('GeneType',GeneType)
      // let name_exp = name+ '___' +experiment_id + '&' + track_name+ '^' + track_description
      if (newSelected.indexOf(GeneType) === -1) {
          newSelected.push(GeneType)
          setSelected(newSelected);
      } else {
          newSelected.splice(selected.indexOf(GeneType), 1)
          setSelected(newSelected)
      }
    }
  };

  useEffect(() => {
    // console.log('&&&&&&&&&&&&-')
    if (props.URLtoGenomeBrowser !== '') {
      // console.log('TracksCompare')
      openRequestedPopup()
      props.clearGenomeBrowserURL()
    }
  }, [props.URLtoGenomeBrowser])

  const openRequestedPopup = () => {
    // console.log(',,/!!')
    let empty_tracks = ''
    let i = []
    let url_massege = ''
    if (props.URLtoGenomeBrowser.split("|||").length === 1) {
      i = props.URLtoGenomeBrowser.split("_____")
      url_massege = 'Otherwise manually copy and paste these links: ' + i.toString()
    } else {
      i = props.URLtoGenomeBrowser.split("|||")[0].split("_____")
      if (i !== '') {
        url_massege = 'Otherwise manually copy and paste these links: ' + i.toString()
      }
      empty_tracks = props.URLtoGenomeBrowser.split("|||")[1]
      empty_tracks = "       (Tracks: " + empty_tracks + " — don't have contacts with chromatin)"
    }
    for (var k in i) {
      // console.log('i/,,',i)
      if (i[k] !== '') {
        // console.log('pppppppppppp______')
        var newWin = window.open(i[k])
        if (!newWin || newWin.closed || typeof newWin.closed=='undefined') { 
          alert('You need to allow pop-up windows to open. ' + url_massege + empty_tracks)
          break;
        } else {
          // console.log('uuuuu')
          newWin.focus()
        }
      }
    }
    if (empty_tracks !== '') {
      alert(empty_tracks)
    }
  }

  const handleNewWindowClick = (event, label) => {
    if (selected.length !== 0) {
      // console.log('dataPerPage[selectedNormalization]',dataPerPage['selectedNormalization'])
      if (label === "Send tracks to Genome Browser") {
        props.submitCheckedTracks(
          {'tracks': dataPerPage['tracks'], 'Intersection_genes_req': dataPerPage['Intersection genes req']},
          'DNA_part',
          selected,
          dataPerPage['selectedNormalization']
        )
        // console.log('dw')
        // props.submitCheckedTracks(props.selectedTracks,props.typeOfContacts, props.selectedTrackNames, props.trackListCheckbox)
      } else {
        const data = {
          selectedGeneTypes: selected,
          tracks: dataPerPage['tracks'],
          Intersection_genes_req: dataPerPage['Intersection genes req'],
          // trackNames: dataPerPage['x']
          selectedNormalization: dataPerPage['selectedNormalization'],
        } 

        if (label === "Graphical summary") {
          localStorage.setItem("data_graphical_summary",  JSON.stringify(data));
          window.open("/graphical_summary", "_blank");
        } else if (label === "RNA list") {
          localStorage.setItem("data_rna_list",  JSON.stringify(data));
          window.open("/rna_list", "_blank");
        }
        // console.log('!!!!!!!!!!!!!!!!!!!!', data)
      }
    } else {
      setOpen(true)
      setMessage('Choose some  RNA types')
    }
  }
  
  const isSelected = name => selected.indexOf(name) !== -1

  let column = null

  return (
    <div className={classes.root}>
      {loadingTracksComparison === true || dataPerPage.length === 0? 
      <div className={classes.loading}>
        <LinearProgress color={"primary"} />
        <Typography variant="h5">
          Please, wait a while, it may take from a few seconds to a couple of minutes
        </Typography> 
      </div>
      :
      <div>
        {/* title={"Experiment comparison:"}*/}
        {/* {console.log(dataPerPage,'((((((((((99999987ytf')} */}
        {dataPerPage['x'].length === 0 ?
          <Typography color="error" variant="h2">These tracks don't have contacts with a chromatin</Typography>
          :
          <div>
            <div style={{width: percentage, margin: '0 auto', border: '1px solid rgb(224, 224, 224)'}}>
            {/* <Paper elevation={0} style={{border: '2px solid #009688'}} square> */}
              <div className={classes.tableWrapper}>
                <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={'small'}>
                  <TableHeader
                    // numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    // rowCount={dataPerPage.length}
                    data={dataPerPage}
                  />
                  <TableBody>
                    {stableSort(dataPerPage['z'], getSorting(order, orderBy))
                      .map(row => {
                        const isSelectedID = isSelected(row.Genetypes);
                        // console.log('row',row)
                        // console.log('row.Intersection',row['Intersection'])
                        // console.log('')
                        return (
                          <TableRow 
                            hover 
                            role="checkbox" 
                            checked={isSelectedID} 
                            tabIndex={-1} 
                            key={row.id} 
                            size='small'
                            onClick={event => handleClick(event, row.Genetypes, row.Intersection)}
                            className={classes.sizeSmall}
                            selected={isSelectedID}
                          >
                            {dataPerPage['x'].map((key, index) => {
                              // console.log('key',key)
                              if (key === 'Intersection') {
                                column=
                                      <TableCell 
                                          key={index}
                                          align="left" 
                                          className={classes.sizeSmall}
                                          // size='small' 
                                          style={{fontSize: 15, 
                                              whiteSpace: 'nowrap', 
                                              border: '1px solid #d3d4d5',
                                              borderTop: '1px solid #d3d4d5',
                                              borderLeft: '1px solid #d3d4d5',
                                          }}>
                                          <FormControlLabel
                                              control={
                                                  <Checkbox 
                                                    disabled = {row[key] === 0 ? true : false}
                                                    style={{padding: 0, marginLeft: 10}}
                                                    checked={isSelectedID} 
                                                    color="secondary"
                                                    // onClick={event => handleClick(event, row.Genetypes)}
                                                    // onChange={}
                                                  />
                                              }
                                          />
                                          {row[key]}
                                      </TableCell>
                              } else {
                                // let cellColor = 'white'
                                // {row[key.replace(/\s+/g, '')] === 0 ? cellColor = '#d3d4d5' : cellColor = 'white'}
                                column=
                                      <TableCell 
                                          key={index}
                                          // align="left" 
                                          align = { key === 'Gene types' ? "left" : "center"}
                                          className={classes.sizeSmall}
                                          style={{fontSize: 15, 
                                                  whiteSpace: 'nowrap', 
                                                  // backgroundColor: cellColor,
                                                  border: '1px solid #d3d4d5',
                                                  borderTop: '1px solid #d3d4d5',
                                                  borderLeft: '1px solid #d3d4d5',
                                              }} 
                                      >
                                        {row[key.replace(/\s+/g, '')]}
                                </TableCell>
                              }
                              return (
                                  column
                              );
                            })}
                            {/* <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>{n.Genetypes}</TableCell>
                            <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>{n.Track1}</TableCell>
                            <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>{n.AnnotatedRNAs}</TableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                </TableContainer>
                
              </div>
              {/* </Paper> */}
            </div>
            <div style={{width: '99%'}}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Button 
                    variant={"contained" }
                    className={classes.wideButton}
                    style={{backgroundColor: buttonColor, color: buttonTextColor, textTransform: 'none'}}
                    onClick={e => handleNewWindowClick(e, "Graphical summary")}
                  >
                    Graphical Summary
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant={"contained" }
                    className={classes.wideButton}
                    style={{backgroundColor: buttonColor, color: buttonTextColor, textTransform: 'none'}}
                    onClick={e => handleNewWindowClick(e, "RNA list")}
                  >
                    Send to RNA-list
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  {!props.loading ?
                    <Button 
                      variant={"contained"}
                      className={classes.wideButton}
                      style={{backgroundColor: buttonColor, color: buttonTextColor, textTransform: 'none'}}
                      onClick={e => handleNewWindowClick(e, "Send tracks to Genome Browser")}
                    >
                      Genome Browser
                    </Button>
                    :
                    <div className={classes.loading}>
                      <LinearProgress color={"primary"} />
                      <Typography variant="subtitle1">
                        Please, wait a while
                      </Typography>
                    </div>
                  }
                </Grid>
              </Grid>
            </div>
          </div>
        }
      </div>
      }
      <WarningMessage open={open} severity={"warning"} handleClose={handleClose} message={message} />
      <WarningMessage open={props.snackTracksComparison} severity={"error"} message={props.snackTextTracksComparison} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      TracksComparisonData: state[module].TracksComparisonData,
      loadingTracksComparison: state[module].loadingTracksComparison,
      loading: state[moduleGenomeBrowser].loadingURL,
      URLtoGenomeBrowser: state[moduleGenomeBrowser].URLtoGenomeBrowser,

      snackTracksComparison: state[module].snackTracksComparison,
      snackTextTracksComparison: state[module].snackTextTracksComparison,
      selected: state[module].selected,
      // selectedItemOrganism: state[module].selectedItemOrganism,
  }
}

export default connect(mapStateToProps, {submitTracksComparison, handleHeader, submitCheckedTracks, clearGenomeBrowserURL})(TableTracksComparison);