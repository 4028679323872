import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
// import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
// import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
// import HelpIcon from '@material-ui/icons/Help';
// import Typography from '@material-ui/core/Typography';

// import Decorator from '../components/HtmlTooltip';
import TableMetadata from '../components/TableMetadata';
import WarningMessage from './MessageSnackbar';
import DownloadTrack from '../components/DownloadTrack';

import { handleSelectedColumns } from '../actions/generalActions';
import { allExpsRequest } from '../actions/generalActions';

const module = "table";

const useStyles = makeStyles(theme => ({
    formControl: {
    margin: theme.spacing(1),
    width: '99%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  dialog: {
    maxWidth: '100%',
    // width: '1000px'
  },
  root: {
    paddingLeft: theme.spacing(),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),    
    color: theme.palette.grey[500],
  },
}));

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ColumnSelectorAllExperiments = (props) => {
    const classes = useStyles();
    const { AllExps } = props;
    
    let [columnsMap, handleColumnsMap] = useState({});
    const [columns, handleColumns] = useState([]);

    useEffect(() => {
        if (props.selectedColumns.length === 0) {
            props.handleSelectedColumns(props.selectedColumnsInit)
        }
        handleColumnsMap(props.columnsMap);
        handleColumns(props.columns);
    }, [])

    const handleChange = event => {
        // console.log('handleColumnsChange event.target.value',event.target.value)
        props.handleSelectedColumns(event.target.value);
        let fake_array = []
        fake_array = fake_array.concat(event.target.value)
        // console.log('handleColumnsChange columnsMap',columnsMap)
        let newColumns = fake_array.map(value => {
            return columnsMap[value];
        });
        props.setColumns(newColumns);
    };
    
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
        if (open === true) {
          props.allExpsRequest();
        }
    }, [open])    

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClickDownloadData = () => {
      // window.open("http://bioinf.fbb.msu.ru/rna_chrom/Trek_list_hubs_tmp/download/", "_blank")
      window.open("http://bioinf.fbb.msu.ru/rna_chrom/download/RNA-chromatin-interactions-data/", "_blank")
    }
  
    const handleClose = value => {
      setOpen(false);
    };
    
    // console.log('handleColumnsChange props.selectedColumns',props.selectedColumns)
    return (
        <div>
            <Grid
                container
                direction={"row"}
                justify={"center"}
                alignItems={"center"}
                spacing={2}
            >
                <Grid item xs={7}>
                    <FormControl className={classes.formControl}>
                        <InputLabel style={{color: '#009688'}}><u>Select columns to display:</u></InputLabel>
                        <Select
                            multiple
                            value={props.selectedColumns}
                            onChange={handleChange}
                            input={<Input />}
                            renderValue={selected => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                        {columns.map(column => {
                            if ((column !== "Data type") && (column !== "Exp.ID") && (column !== "RNA name (RNA-Chrom DB)") && (column !== "Method")) {
                                return (
                                    <MenuItem key={column} value={column}>
                                        <Checkbox checked={props.selectedColumns.indexOf(column) > -1} />
                                    <ListItemText primary={column} />
                                    </MenuItem>
                                );
                            }
                        })}
                        </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Download table">
                      <div>
                        <DownloadTrack typeOfContacts={""} all_insert={""} expId_and_name={""}/>
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Button
                        variant={"contained" }
                        color={"secondary"}
                        onClick={handleClickOpen}
                    >
                        Data summary{/* Database content */}
                    </Button>
                    <div className={classes.root}>
            {/* <SimpleDialog open={open} onClose={handleClose} /> */}
                      <Dialog 
                        onClose={handleClose} 
                        open={open} 
                        className={classes.dialog}
                        fullWidth={true}
                        maxWidth={'sm'} //'sm' || 'md' || 'lg' || 'xl'
                      > 
                        <DialogTitle id="simple-dialog-title" style={{ padding: 10, textAlign: 'center' }}><Typography variant="h5">Database contain:</Typography></DialogTitle>
                          {handleClose ? (
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                              <CloseIcon />
                            </IconButton>
                            ) 
                          : 
                            null
                          }
                          {!props.loadingAllExps && Object.keys(AllExps).length !== 0 ?
                            <TableMetadata 
                                    title={''} 
                                    data={AllExps['z']} 
                                    columns={AllExps['x']}
                                  />
                          :
                          <div> 
                              <LinearProgress color={"primary"} />
                              <Typography variant="h5">
                                Please, wait a while, it may take from a few seconds to a couple of minutes
                              </Typography>
                              <WarningMessage open={props.snackOpenAllExps} severity={"error"} message={props.snackTextAllExps} />
                          </div>
                        }
                          </Dialog>
                      <WarningMessage open={props.snackOpenGenomeBrowser} severity={"error"} message={props.snackTextGenomeBrowser} />
                    </div>
                  </Grid>
                  <Grid item>
                    <Button
                      variant={"contained" }
                      color={"secondary"}
                      onClick={handleClickDownloadData}
                    >
                      Download data
                    </Button>
                  </Grid>
                  {/* <Grid item>
                    <Button
                      variant={"contained" }
                      color={"secondary"}
                      // onClick={handleClickDownloadData}
                    >
                      Additional info.
                    </Button>
                  </Grid> */}
                </Grid>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      selectedColumns: state[module].selectedColumns,
      
      loadingAllExps: state[module].loadingAllExps,
      AllExps: state[module].AllExps,
      snackOpenAllExps: state[module].snackOpenAllExps,
      snackTextAllExps: state[module].snackTextAllExps,
    }
}


export default connect(mapStateToProps, {handleSelectedColumns, allExpsRequest})(ColumnSelectorAllExperiments);