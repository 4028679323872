import React from 'react';
// import { amber } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
// import SnackbarContent from '@material-ui/core/SnackbarContent';
// import WarningIcon from '@material-ui/icons/Warning';

import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

// const useStyles = makeStyles(theme => ({
//     warning: {
//         backgroundColor: amber[700],
//     },
//     warningIcon: {
//         fontSize: 20,
//         marginRight: 10,
//         verticalAlign: "middle",
//     },
// }));
const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

const WarningMessage = (props) => {
    
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar open={props.open} autoHideDuration={6000} onClose={props.handleClose}>
                <Alert onClose={props.handleClose} severity={props.severity} > {/* "error","warning","info","success" */}
                    {props.message}
                </Alert>
            </Snackbar>
        </div>
        // <Snackbar
        //     open={props.open}
        //     onClose={props.handleClose}
        //     autoHideDuration={6000}
        // >
        //     <SnackbarContent
        //     className={classes.warning}
        //     message={
        //         <span>
        //             <WarningIcon className={classes.warningIcon} />
        //             {props.message}
        //         </span>
        //     }
        //     />
        // </Snackbar>
    );
};

export default WarningMessage;
