import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//import { PersistGate } from 'redux-persist/integration/react'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import configureStore from './store';
import rootSaga from './sagas';
import { Provider } from 'react-redux';

const {store} = configureStore(); //, persistor

store.runSaga(rootSaga);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#3232c1',//#80db9e
        },
        secondary: {
            main: '#d81e11',//#f98866
        },
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store} >
            <div>
                {<App />} 
            </div>
        </Provider>
    </MuiThemeProvider>
, document.getElementById('root'));
//     <MuiThemeProvider theme={theme}>
//         <Provider store={store}>
//             <PersistGate loading={null} persistor={persistor}>
//                 <div>
//                     {<App />} 
//                 </div>
//             </PersistGate>
//         </Provider>
//     </MuiThemeProvider> 
// , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
