import React, { useEffect } from 'react'
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import MUIDataTable from "mui-datatables";
import Grid from '@material-ui/core/Grid';
import { BlockMath } from 'react-katex';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';

import Divider from '@material-ui/core/Divider';

// import TableNewSuper from '../components/TableNewSuper';
import ContentsProtocol from '../components/ContentsProtocol'

import { handleHeader } from '../actions/generalActions';
import protocol from './svg/protocol.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // padding: 50,
    // paddingTop: 70,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    //width: '40%',
    // textAlign: 'center',
    flexGrow: 1,
    // width: '96%',
  },
  table: {
    minWidth: 650,
  },
  divider: {
    marginTop: 20,
    marginBottom: 5,
    // margin: '10px auto',
    width: '100%',
    // marginLeft: 300,
    height: 2,
    backgroundColor: 'black',
  },
}));


function createData(Method, pmid, Organism, Type, Tissue, Genome_ver,	Fastuniq, Restriction_sites, Trimmomatic, Mapped, Mapped_2mism, Mapped_unique, rna_part_annot,	dna_part_annot) {
  return {Method, pmid, Organism, Type, Tissue, Genome_ver,	Fastuniq, Restriction_sites, Trimmomatic, Mapped, Mapped_2mism, Mapped_unique, rna_part_annot,	dna_part_annot};
}

const rows = [
  createData('GRID-seq',	'28922346',	'H. sapiens',	'All vs all',	'MDA-MB-231 (HTB-26 ATCC)',	'hg38 p12',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('GRID-seq',	'28922346',	'H. sapiens',	'All vs all',	'MM.1S',	'hg38 p12',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('GRID-seq',	'28922346',	'M. musculus',	'All vs all',	'ES cells',	'mm10',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('iMARGI',	'30718424',	'H. sapiens',	'All vs all',	'HEK293T',	'hg38 p12',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('iMARGI',	'30718424',	'H. sapiens',	'All vs all',	'HFFc6',	'hg38 p12',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('RADICL-seq',	'32094342',	'M. musculus',	'All vs all',	'mES R08',	'mm10',	'yes',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('RADICL-seq',	'32094342',	'M. musculus',	'All vs all',	'mOPC R08',	'mm10',	'yes',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('RED-C',	'32479626',	'H. sapiens',	'All vs all',	'Fibroblast',	'hg38 p12',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('RED-C',	'32479626',	'H. sapiens',	'All vs all',	'K562',	'hg38 p12',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
];

const rows_2 = [
  createData('RAP',	'23828888',	'M. musculus',	'One vs all',	'ES cells',	'mm10',	'yes',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
  createData('CHART',	'25155612',	'H. sapiens',	'One vs all',	'MCF-7 (ATCC# HTB-22)',	'hg38 p12',	'yes',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'),
];


// let data = [['GRID-seq',	28922346,	'H. sapiens',	'All vs all',	'MDA-MB-231 breast cancer cells (HTB-26 ATCC)',	'hg38 p12',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'],
// ['GRID-seq',	28922346,	'H. sapiens',	'All vs all',	'MM.1S multiple myeloma cells',	'hg38 p12',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'],
// ['GRID-seq',	28922346,	'M. musculus',	'All vs all',	'ES cells',	'mm10',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'],
// ['iMARGI',	30718424,	'H. sapiens',	'All vs all',	'HEK293T',	'hg38 p12',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'],
// ['iMARGI',	30718424,	'H. sapiens',	'All vs all',	'HFFc6',	'hg38 p12',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'],
// ['RADICL-seq',	32094342,	'M. musculus',	'All vs all',	'mES R08',	'mm10',	'yes',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes'],
// ['RADICL-seq',	32094342,	'M. musculus',	'All vs all',	'mOPC R08',	'mm10',	'yes',	'no',	'yes',	'yes',	'yes',	'yes',	'yes',	'yes']]

const Protocol = (props) => {
  const classes = useStyles();
  document.title = "Data processing";

  useEffect(() => {
    props.handleHeader(': data processing')
  }, [])
  // const columns = ["Type", "Description"];
  // const data = [
  //     ["Genome-wide contacts Heatmap", "Presents a heatmaps for contacts distribution over chromosomes"],
  //     ["Comparative Heatmap", "Presents a heatmaps for contacts distribution over chromosomes for every experiment in the track"],
  //     ["Contacts distribution", "A distribution of the contacts over the chromosomes"],
  //     ["Distance distribution", "A histogram of the distribution of distance between RNA gene and DNA contacts positions"]
  // ];
  return (
    <div className={classes.root}>
      <Grid container spasing={1} style={{marginTop: 20, flexWrap: 'nowrap'}}>
            <Grid item xs={1}>
                <ContentsProtocol />
            </Grid>
            <Grid item xs={11} style={{width: `calc(100% - 200px)`, marginLeft: 120}}>
            <TableContainer style={{maxHeight: window.innerHeight*0.85}}>
      <Typography id="Data-processing" variant="h5" style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 20, marginTop: 20}}>
        In this work, we preprocessed raw reads using a single protocol
      </Typography> 
      {/* In this work, we used only raw reads provided by the authors of publications. */}
      <div style={{marginBottom: 10, textAlign: 'center', marginTop: 20}}>
        <img src={protocol} alt="protocol" width='50%'/>
      </div>
      
      <Divider className={classes.divider}/>

      <Typography id="All-to-all-Processing-steps" variant="h5" style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 15, marginTop: 30}}>
        Processing genome-wide RNA-chromatin interactome data (data type "All vs all")
      </Typography>
      <Grid container spasing={3} style={{ marginTop: 20,}}>
        <Grid item xs={3} >
            <Typography variant="h5" style={{fontWeight: 'bold', marginTop: 150, marginBottom: 5,}}>
              Processing steps
            </Typography>
        </Grid>
        <Grid item xs={9} >
            <Typography variant={"subtitle1"} >
                <ul>
                    <li>We used <b>Fastuniq</b> for filtering out possible PCR duplicates for paired reads. From each group of duplicates, only one pair was retained. Since GRID-seq protocol produces raw data without PCR duplicates, fastuniq step was omitted.</li>
                    <li>We run <b>fastQC</b> for sequencing quality control.</li>
                    <li>We used <b>TRIMMOMATIC</b> for detection low-quality position in each forward and reverse read (parameters: <b>window size: 5, quality threshold: 26</b>).</li>
                    <li>DNA-parts and RNA-parts were independently mapped to the genome with <b>hisat2</b> program (parameters for DNA-parts: <b>k 100 --no-spliced-alignment --no-softclip and -k 100 --no-softclip --dta-cufflinks</b>, for RNA-parts: <b>--known-splicesite-infile</b>). Splicing sites annotation was taken from <a href="https://www.gencodegenes.org/" className={classes.link}>GENCODE</a>.</li>
                    <li>SAM files were filtered for unique mappings with at most 2 mismatches relative to the reference genome with <b>samtools</b>.</li>
                    <li>Only contacts for which both the RNA- and DNA-parts passed all filters were taken for further analysis.</li>
                    <li>Biological replicas were combined by tissue for each data set.</li>
                </ul>
            </Typography>
            <Divider className={classes.divider}/>
        </Grid>
      </Grid>

      <Grid id="All-to-all-Annotation-of-RNA-parts-in-contacts" container spasing={3} style={{ marginTop: 0,}}>
        <Grid item xs={3} >
            <Typography variant="h5" style={{fontWeight: 'bold', marginTop: 350, marginBottom: 5,}}>
              Annotation of RNA-parts <br />in contacts
            </Typography>
        </Grid>
        <Grid item xs={9} >
            <Typography variant={"subtitle1"} >
                <ul>
                    <li>RNA-parts of the contacts were annotated using gene annotation collected from various sources:</li>
                    <ul>
                      <li><b>For human data sets:</b> <a href="https://lncipedia.org/" className={classes.link}>LNCipedia</a>, <a href="https://www.gencodegenes.org/" className={classes.link}>GENCODE</a>, <a href="http://scottgroup.med.usherbrooke.ca/snoDB/" className={classes.link}>snoDB</a>, <a href="https://www.ncbi.nlm.nih.gov/pubmed/23876380" className={classes.link}>vlink</a> and <a href="https://genome.ucsc.edu/" className={classes.link}>Genome Browser</a> (wgRNA, trna and repeatMasker tracks).</li>
                      <li><b>For murine data sets:</b> <a href="https://www.gencodegenes.org/" className={classes.link}>GENCODE</a> and <a href="https://genome.ucsc.edu/" className={classes.link}>Genome Browser</a> (trna and repeatMasker tracks)</li>
                    </ul>
                    <li>Some long names of RNA types are truncated as follows:</li>
                    <Typography variant={"subtitle1"} style={{marginLeft: 60, marginTop: 15, marginBottom: 15,}}>
                      unprocessed_pseudogene: <b>pseudogene_U1</b>
                      <br/> unitary_pseudogene: <b>pseudogene_U2</b>
                      <br/> translated_unprocessed_pseudogene: <b>pseudogene_TU1</b>
                      <br/> transcribed_unprocessed_pseudogene: <b>pseudogene_TU2</b>
                      <br/> transcribed_unitary_pseudogene: <b>pseudogene_TU3</b>
                      <br/> translated_processed_pseudogene: <b>pseudogene_TP1</b>
                      <br/> transcribed_processed_pseudogene: <b>pseudogene_TP2</b>
                      <br/> processed_pseudogene: <b>pseudogene_P1</b>
                      <br/> polymorphic_pseudogene: <b>pseudogene_P2</b>
                    </Typography>
                    <li>In case the RNA-part of the contact intersects a gene by at least 1 nucleotide, this RNA-part is assigned to this gene. If the RNA-part of the contact intersects more than one gene at the same strand, this RNA-part is assigned to the gene showing the highest coverage by RNA-parts, which is determined as the total number of RNA-parts mapped to the gene normalized to the gene length. Only contacts with RNA parts that fell into genes (including X-RNAs) were used (<b>"After RNA-part annotation"</b> on the <b>Metadata page</b>). The others were named <b>"Singletons"</b> and were not used.</li>
                </ul>
            </Typography>
            <Typography variant="h5" style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 15, marginTop: 30}}>
              X-RNAs
            </Typography>
            <Typography variant={"subtitle1"} style={{marginLeft: 40}}>
              A substantial amount of RNA-parts was not annotated by any gene annotation used. Some of these unknown parts may belong to unknown ncRNAs. They have a high amount of chromatin contacts and can be involved in nuclear processes like chromatin remodelling or nuclear bodies formation. We called these unknown parts X-RNAs. Transcripts not corresponding to any known gene were assembled using <b>stringtie</b> and then filtered by several criteria e.g. length, distance to closest known gene, conservation and high coverage. Transcripts that passed all filters were included in the database.
            </Typography>
            <Divider className={classes.divider}/>
        </Grid>
      </Grid>

      <Grid id="All-to-all-Contact-counts-normalization" container spasing={3} style={{ marginTop: 0,}}>
        <Grid item xs={3} >
            <Typography variant="h5" style={{fontWeight: 'bold', marginTop: 150, marginBottom: 5,}}>
              Contact counts normalization
            </Typography>
        </Grid>
        <Grid item xs={9} >
          <Typography variant={"subtitle1"} >
              <ul>
                <li><b>“Observed contact counts” (N-obs)</b> = 1, because the contact corresponds to 1 pair of RNA- and DNA-parts.</li>
                {/* <li>To account for the level of background ligation in the procedure, we estimated the total number of mRNA trans-contacts with each genomic site. We divided the genome into 500 bp bins, and for each bin, we summed the number of contacts made with this bin by protein-coding RNAs originating from all over the genome except the chromosome where the bin belongs. We smoothed the obtained signal using <b>Stereogene</b> and used it as a background signal – <b>“Expected contact counts” (N-exp)</b>. We then normalized the raw counts <b>(N-exp)</b> of individual RNA–DNA contacts by the value of the background in the genomic coordinate where the DNA part was mapped. To work with DNA-parts mapped to the regions with zero value of the background signal, we added to the denominator a pseudocount. Finally, we divided the sum of the raw counts by the sum of normalized counts and multiply each normalized count by the obtained coefficient. So we obtained <b>"Normalized contact counts" (N-norm)</b>.</li> */}
                {/* <li>To account for the level of background ligation in the procedure, we estimated the total number of mRNA trans-contacts with each genomic site. We divided the genome into 500 bp bins, and for each bin, we summed the number of contacts made with this bin by protein-coding RNAs originating from all over the genome except the chromosome where the bin belongs. We smoothed the obtained signal using <b>Stereogene</b> and used it as a background signal – <b>“Expected contact counts” (N-exp)</b>. We then normalized the raw counts <b>(N-obs)</b> of individual RNA–DNA contacts by the sum of the raw counts and by the value of the background in the genomic coordinate where the DNA parts were mapped. To work with DNA-parts mapped to the regions with zero value of the background signal, we added to the denominator a pseudocount. So we obtained <b>"Normalized contact counts" (N-norm)</b>.</li> */}
                <li>To account for the level of background ligation in the experimental procedure, we estimated the total number of mRNA trans-contacts with each genomic site. We divided the genome into 500 bp bins, and for each bin, we summed the number of contacts made with this bin by protein-coding mRNAs originating from all over the genome except the chromosome where the bin belongs. We smoothed the obtained signal using <b>Stereogene</b> and used it as a background signal – <b>“Expected contact counts” (N-exp)</b>. We then normalized the raw counts <b>(N-obs)</b> of individual RNA–DNA contacts by the sum of the raw counts and by the value of the background signal in the genomic coordinate where the DNA parts were mapped. To work with DNA-parts mapped to the regions with zero value of the background signal, we added to the denominator a pseudocount. So we obtained <b>"Normalized contact counts" (N-norm)</b></li>
              </ul>
              <BlockMath math={'\\small \\textbf{N-obs}=1'} />
              {/* <BlockMath math={'\\small \\textbf{N-exp}=\\frac{\\text{ololo}}{\\frac{\\text{aaa}}{sss}+\\frac{qqq}{222}}'} /> */}
              <BlockMath math={'\\small \\textbf{N-norm}=\\frac{\\text{N-obs}}{\\sum \\text{N-obs}*\\left (\\frac{\\text{N-exp}}{\\sum \\text{N-exp}}+10^{-9} \\right )}'} />
          </Typography>
        </Grid>
      </Grid>
          <Paper elevation={0} style={{border: '2px solid #009688'}} square>
            <div className={classes.title}>
              <Toolbar>
                <Typography variant="h6" id="tableTitle">
                  Raw data processing
                </Typography>
              </Toolbar>
            </div>
            <TableContainer> {/* component={Paper} */}
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Method</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>PMID</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Organism</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Data type</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Cell type</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Genome version</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Fastuniq</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Restriction sites</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Trimmomatic</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Mapped</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Mapped with 2 mismatchs</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Mapped unique</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>RNA-part annotation</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>DNA-part annotation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={row.Method + index}>
                      <TableCell component="th" scope="row" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>
                        {row.Method}
                      </TableCell>
                      <TableCell align="left" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.pmid}</TableCell>
                      <TableCell align="left" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Organism}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Type}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Tissue}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Genome_ver}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Fastuniq}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Restriction_sites}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Trimmomatic}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Mapped}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Mapped_2mism}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Mapped_unique}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.rna_part_annot}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.dna_part_annot}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

      
      <Divider className={classes.divider}/>

      <Typography id="One-to-all-Processing-steps" variant="h5" style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 15, marginTop: 30}}>
        Processing a particular RNA (for example XIST) with chromatin data (data type "One vs all")
      </Typography>
      <Grid container spasing={3} style={{ marginTop: 20,}}>
        <Grid item xs={3} >
            <Typography variant="h5" style={{fontWeight: 'bold', marginTop: 170, marginBottom: 5,}}>
              Processing steps
            </Typography>
        </Grid>
        <Grid item xs={9} >
            <Typography variant={"subtitle1"} >
                <ul>
                  <li>All steps before alignment are the same like in <b>Processing genome-wide RNA-chromatin interactome data</b></li>
                  <li>Paired end reads (DNA-parts of contacts) were aligned as pairs with <b>hisat2</b> program (<b>--no-discordant --no-mixed</b> flags to filter out improperly paired reads during the alignment stage).</li>
                  <li>The coverage for each sample for the corresponding genome was obtained using the tool <b>bedtools</b> (parameter: <b>genomecov</b>).</li>
                  <li>For the inputs coverage was smoothed using the tool <b>Smoother</b> (parameters: <b>bin=500, wSize=10000000, flankSize=10000, kernelSigma=3000, kernelType=NORMAL, verbose=1</b>), so we got <b>“Expected contact counts” (N-exp)</b> for all genomic loci.</li>
                  <li>Genome loci of non-input samples were filtered if they had non-zero coverage. So the coverage of the corresponding locus is <b>“Observed contact counts” (N-obs)</b>.</li>
                  <li>Then non-input and appropriate input samples were intersected using the tool <b>bedtools</b> (parameters: <b>-wa -wb</b>).</li>
                  <li>Biological replicas were merged.</li>
                  <li>Using <a href="https://www.gencodegenes.org/" className={classes.link}>GENCODE</a> markup we added coordinates of the RNA gene (RNA-parts of contacts) studied in the appropriate data set to each locus (DNA-parts of contacts).</li>
                </ul>
            </Typography>
            <Divider className={classes.divider}/>
        </Grid>
      </Grid>

      {/* <Grid container spasing={3} style={{ marginTop: 0,}}>
        <Grid item xs={3} >
            <Typography variant="h5" style={{fontWeight: 'bold', marginTop: 15, marginBottom: 5,}}>
              Annotation of RNA-parts <br />in contacts
            </Typography>
        </Grid>
        <Grid item xs={9} >
            <Typography variant={"subtitle1"} >
                <ul>
                    <li>Using <a href="https://www.gencodegenes.org/" className={classes.link}>GENCODE</a> markup we added to each locus (DNA-parts of contacts) coordinates of the RNA gene (RNA-parts of contacts) studied in appropriate experiment.</li>
                </ul>
            </Typography>
            <Divider className={classes.divider}/>
        </Grid>
      </Grid> */}

      <Grid id="One-to-all-Contact-counts-normalization" container spasing={3} style={{ marginTop: 0,}}>
        <Grid item xs={3} >
            <Typography variant="h5" style={{fontWeight: 'bold', marginTop: 50, marginBottom: 5,}}>
              Contact counts normalization
            </Typography>
        </Grid>
        <Grid item xs={9} >
          <Typography variant={"subtitle1"} >
              <ul>
                  <li><b>"Normalized contact counts" (N-norm)</b> was calculated for the appropriate locus (DNA-part of the contact) as:</li>
              </ul>
              <BlockMath math={'\\small \\textbf{N-norm}=\\frac{\\text{N-obs}}{\\frac{\\text{N-exp}}{K}+\\frac{K}{20}}'} />
              <BlockMath math={'\\small \\textbf{K}=\\frac{\\sum \\text{N-exp}}{\\text{Genome length}}'} />
          </Typography>
        </Grid>
      </Grid>
          <Paper elevation={0} style={{border: '2px solid #009688'}} square>
            <div className={classes.title}>
              <Toolbar>
                <Typography variant="h6" id="tableTitle">
                  Raw data processing
                </Typography>
              </Toolbar>
            </div>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Method</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>PMID</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Organism</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Data type</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Cell type</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Genome version</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Fastuniq</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Restriction sites</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Trimmomatic</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Mapped</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Mapped with 2 mismatchs</TableCell>
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>Mapped unique</TableCell>
                    {/* <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>RNA-part annotation</TableCell> */}
                    <TableCell align="center" style={{padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#009688', color: 'white'}}>DNA-part annotation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows_2.map((row) => (
                    <TableRow key={row.Method}>
                      <TableCell component="th" scope="row" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>
                        {row.Method}
                      </TableCell>
                      <TableCell align="left" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.pmid}</TableCell>
                      <TableCell align="left" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Organism}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Type}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Tissue}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Genome_ver}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Fastuniq}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Restriction_sites}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Trimmomatic}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Mapped}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Mapped_2mism}</TableCell>
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.Mapped_unique}</TableCell>
                      {/* <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.rna_part_annot}</TableCell> */}
                      <TableCell align="center" style={{whiteSpace: 'nowrap',padding: 3, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}>{row.dna_part_annot}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
      
      <Divider className={classes.divider}/>

      <Typography id="Annotation-of-DNA-parts" variant="h5" style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 15, marginTop: 30}}>
        Annotation of DNA-parts in contacts (contacts annotation)
      </Typography>
      <Typography variant={"subtitle1"}>
        For each gene we calculated these intervals: <b>gene body</b>, <b>5Kb upstream</b> and <b>5Kb downstream</b> from the gene and <b>since 5 to 50Kb upstream</b> and <b>since 5 to 50Kb downstream</b> from the gene according to the gene strand. So all of these intervals don't intersect. DNA-parts of the contacts were annotated by all the above intervals.
      </Typography>
      {/* <Grid container spasing={1} style={{ marginTop: 20,}}>
        <Grid item xs={6}>
          <BlockMath math={'\\small \\textbf{N-norm}=\\frac{\\text{N-obs}}{\\frac{\\text{N-exp}}{K}+\\frac{K}{20}}'} />
        </Grid>
        <Grid item xs={6}>
          <BlockMath math={'\\small \\textbf{K}=\\frac{\\int \\text{N-exp}}{\\text{Appropriate genome length}}'} />
        </Grid>
      </Grid> */}
      {/* <TableNewSuper
            data={data} 
            columns={['Experiment',	'PMID',	'Organism',	'Type',	'Tissue',	'Genome_ver',	'Fastuniq',	'Restriction sites',	'Trimmomatic',	'Mapped',	'Mapped_2mism',	'Mapped_unique',	'rna-part annot',	'dna-part annot']}
            italicColumn={-1} 
            filter={true}
          /> */}
      </TableContainer>
      </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  }
}

export default connect(mapStateToProps, { handleHeader })(Protocol);