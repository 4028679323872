import React, { useState, useEffect } from 'react';
import  { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import Input from '@material-ui/core/Input';
// import FilledInput from '@material-ui/core/FilledInput';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import InputLabel from '@material-ui/core/InputLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import TextField from '@material-ui/core/TextField';
// import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';

import Checkbox from '@material-ui/core/Checkbox';
// import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
// import Toolbar from '@material-ui/core/Toolbar';
import DownloadTrack from '../components/DownloadTrack';
import RnaListButtonsBasic from '../components/RnaListButtonsBasic';
import RnaListInfoButtonsBasic from '../components/RnaListInfoButtonsBasic';
import WarningMessage from '../components/MessageSnackbar';
import {LocusFilterParser} from '../components/TableAllTargetGenes';
// import ColumnSelectorBasic from './ColumnSelectorBasic';
import RnaListPaginationBasic from '../components/RnaListPaginationBasic';
import SimpleDialogDemo from '../components/OpenSimpleDialog';
import AllTargetGenes from '../components/AllTargetGenes'
import RnaTableInfo from '../components/TableRnaListRnaBasic';


import { submitTableRnaList } from '../actions/generalActions'; //, SendListGeneType, SendListTrackName

const module = "table";
const moduleFilter = "filter";

const headCells = [
    // {id:'name', numeric: false, disablePadding: false, label: 'Name' },
    // {id:'gene_type', numeric: false, disablePadding: true, label: 'Type'},
    {id:'track_name',numeric: false, disablePadding: true, label: 'Track'},
    {id:'experiment_id', numeric: false, disablePadding: true, label: 'Exp.ID'},
    {id:'expID_typeOfExperiment',numeric: false, disablePadding: true, label: 'Type'},
    {id:'cpkm', numeric: false, disablePadding: true, label: 'CPKM (normalized)'}, //CPKM (background norm.) //CPKM(N-norm)
    {id:'cpkm_observed', numeric: false, disablePadding: true, label: 'CPKM (raw)'}, //CPKM (without norm.) //CPKM(N-obs)
    {id:'sum_value', numeric: false, disablePadding: true, label: 'n-reads (normalized)'}, //n-reads (background norm.) //N-norm
    {id:'sum_observed_value', numeric: false, disablePadding: true, label: 'n-reads (raw)'}, //n-reads (without norm.) //N-obs
    {id:'sum_norm_value_in_peak', numeric: false, disablePadding: true, label: 'n-reads (norm., peaks)'},
    {id:'sum_observed_value_in_peak', numeric: false, disablePadding: true, label: 'n-reads (raw, peaks)'}, 
    // {id:'locus', numeric: false, disablePadding: true, label: 'Gene locus'},
    // {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
    // {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
    // {id:'organism', numeric: false, disablePadding: true, label: 'Organism'},
    // {id:'build', numeric: false, disablePadding: true, label: 'Genome'},
    // {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},

    // {id:'sum_expected_value', numeric: false, disablePadding: true, label: 'N-exp'}, 
    // {id:'chr_ID', numeric: false, disablePadding: true, label: 'Chromosome' },
    // {id:'start', numeric: false, disablePadding: true, label: 'Start' },
    // {id:'end', numeric: false, disablePadding: true, label: 'End' },
    // {id:'track_description',numeric: false, disablePadding: true, label: 'Track description'},
    // {id:'track_description_for_tooltip',numeric: false, disablePadding: true, label: 'Track description for tooltip'},
    // {id:'description_for_plots',numeric: false, disablePadding: true, label: 'Track description for plots'},
];

const makeID = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

const EnhancedTableHead = (props) => {
    
    const createSortHandler = property => event => {
        props.onRequestSort(event, property);
    };

    const { classes, order, orderBy, columnsToDisplay} = props;

    let column = null
    return (
        <TableHead size='small'>
            <TableRow size='small' className={classes.sizeSmall}>
                {columnsToDisplay.map((headCell) => {
                // {Object.keys(row).map((key, index) => {
                    if (headCell.label !== 'Track description' && headCell.label !== 'Track description for plots' && headCell.label !== 'Track description for tooltip' && headCell.label !== 'Track') {
                        // console.log('!')
                        if (headCell.label === "CPKM (normalized)") { //CPKM (background norm.)
                            column=
                                <Tooltip
                                    placement={"right-start"}
                                    title={
                                    <React.Fragment>
                                        <div >
                                        <Typography>
                                            <b>CPKM (normalized)</b> — number of background-normalized contacts divided by library size and RNA length
                                        </Typography>
                                        </div>
                                    </React.Fragment>
                                    }
                                >
                                   <TableCell
                                    key={headCell.id}
                                    style={{whiteSpace: 'wrap', backgroundColor: '#009688', color: 'white', border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}
                                    align='center'
                                    className={headCell.label === 'Track' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                    sortDirection={orderBy === headCell.id ? order : false} //false
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={order}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {orderBy === headCell.id ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell> 
                                </Tooltip>
                        } else if (headCell.label === "CPKM (raw)") { //CPKM (without norm.)
                            column=
                            <Tooltip
                                placement={"right-start"}
                                title={
                                <React.Fragment>
                                    <div >
                                    <Typography>
                                        <b>CPKM (raw)</b> — number of not-normalized contacts divided by library size and RNA length
                                    </Typography>
                                    </div>
                                </React.Fragment>
                                }
                            >
                               <TableCell
                                key={headCell.id}
                                style={{whiteSpace: 'wrap', backgroundColor: '#009688', color: 'white', border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}
                                align='center'
                                className={headCell.label === 'Track' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                sortDirection={orderBy === headCell.id ? order : false} //false
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={order}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell> 
                            </Tooltip>
                        } else if (headCell.label === "n-reads (norm., peaks)" || headCell.label === "n-reads (raw, peaks)") {
                            column=
                            <Tooltip
                                placement={"right-start"}
                                title={
                                <React.Fragment>
                                    <div >
                                        <Typography>
                                            Number of normalized/raw contacts that intersected coordinate-wise with the peaks calculated by the MACS2 program only for the corresponding one-to-all data. 
                                        </Typography>
                                        {props.geneNameTarget === "" && props.allInsert === "" ? 
                                            <Typography>
                                                <b>NA means</b> that in the corresponding experiment there are no peaks at all. 
                                            </Typography>
                                        : 
                                            <Typography>
                                                <b>Zero means</b> that in the corresponding experiment there are no peaks at all or are absent in the DNA locus selected for analysis.
                                            </Typography>
                                        }
                                    </div>
                                </React.Fragment>
                                }
                            >
                               <TableCell
                                key={headCell.id}
                                style={{whiteSpace: 'wrap', backgroundColor: '#009688', color: 'white', border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}
                                align='center'
                                className={headCell.label === 'Track' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                sortDirection={orderBy === headCell.id ? order : false} //false
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={order}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell> 
                            </Tooltip>
                        } else if (headCell.label === "Exp.ID") {
                            column=
                            <Tooltip
                                placement={"right-start"}
                                title={
                                <React.Fragment>
                                    <div >
                                    <Typography>
                                        <b>Exp.ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database
                                    </Typography>
                                    </div>
                                </React.Fragment>
                                }
                            >
                               <TableCell
                                key={headCell.id}
                                style={{whiteSpace: 'wrap', backgroundColor: '#009688', color: 'white', border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}
                                align='center'
                                className={headCell.label === 'Track' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                sortDirection={orderBy === headCell.id ? order : false} //false
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={order}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell> 
                            </Tooltip>
                        } else {
                            column=
                                <TableCell
                                    // size='small'
                                    key={headCell.id}
                                    style={{whiteSpace: 'wrap', backgroundColor: '#009688', color: 'white', border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}
                                    align='center'
                                    // className={headCell.label === 'Name' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                    className={headCell.label === 'Track' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                    // className={classes.sizeSmall}
                                    sortDirection={orderBy === headCell.id ? order : false} //false
                                >
                                    <TableSortLabel
                                        // hideSortIcon={true}
                                        active={orderBy === headCell.id}
                                        direction={order}
                                        onClick={createSortHandler(headCell.id)}
                                        // label={<span style={{ fontSize: 15, whiteSpace: 'nowrap'}}>{headCell.label}</span>}
                                    >
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell>
                        }
                    } else {
                        column = null
                    } 
                return (
                    column
                    );
                })}
                <Tooltip
                    placement={"right-start"}
                    title={
                    <React.Fragment>
                        <div >
                        <Typography>
                            <b>Download</b> CSV-file with the coordinates of the DNA-parts of the contacts: chromosome, start, end, n-reads, density.
                        </Typography>
                        </div>
                    </React.Fragment>
                    }
                >
                   <TableCell
                    key={98765}
                    style={{whiteSpace: 'nowrap', backgroundColor: '#009688', color: 'white', border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}
                    align='center'
                    className={classes.sizeSmall}
                    >
                        Contacts
                    </TableCell>
                </Tooltip>
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['desc','asc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        textAlign: 'center',
        flexGrow: 1,
        width: '100%',
    },
    paper: {
        width: '100%',
    },
    loading: {
        marginTop: 50,
        textAlign: 'center',
    },
    sizeSmall: {
        padding: '0px 5px 0px 5px',
        textAlign: 'left',
    },
    sizeSmallChackBox: {
        padding: '0px 0px 0px 0px',
    },
    stickyHeader: {
        'border-collapse': 'inherit',
    },
    tableWrapper: {
        margin: '0 auto',
        maxWidth: '100%',
        // '& table:first-child': {
        //     '& tr': {
        //         '& td:nth-child(1), td:nth-child(1)': {
        //             backgroundColor: 'white',
        //             position: 'sticky',
        //             left: 0,
        //             zIndex: 99
        //         },
        //         '& th:nth-child(1)': {
        //             zIndex: 999
        //         },
        //         // '& td:nth-child(2), td:nth-child(2)': {
        //         //     backgroundColor: 'white',
        //         //     position: 'sticky',
        //         //     left: 0,
        //         //     zIndex: 99
        //         // },
        //         // '& th:nth-child(2)': {
        //         //     zIndex: 999
        //         // },
        //     }
        // },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    textField: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: theme.spacing(),
        width: '85%',
        color: 'black !important',
    },
    locationLeft: {
        marginLeft: 20,
        textAlign: 'left',
    },
    locationBottom: {
        marginTop: theme.spacing(3),
        textAlign: 'center !important',
    },
    container: {
        maxHeight: window.innerHeight*0.65,
    },
    popper: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: "auto",
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        height: '100%',
    },
    updateButton: {
        margin: '20px 5px 0px 0px',
        float: 'right',
    },
    searchIcon: {
        color: 'black',
    },
    input: {
        color: 'black',
    }
}));

const TooltipCell = React.forwardRef((props, ref) => 
        <TableCell {...props} ref={ref}>
            {props.children}
        </TableCell>
);

const TableRnaListBasic = (props) => {

    const classes = useStyles();
    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [allInsert, setAllInsert] = useState('');
    const [geneNameTarget, setGeneNameTarget] = useState('');
    const [warningMessageOpen, setWarningMessageOpen] = useState(false);

    const [data_table_value, setData_table_value] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('cpkm');
    const [rnaListDataFirst, setRnaListDataFirst] = useState({})

    const [rnaListID, setRnaListID] = useState('')
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [rowsCount, setRowsCount] = useState(0);


    const [rowsToDisplay, setRowsToDisplay] = useState([]);

    const [rnaName, setRnaName] = useState('')
    const [currentOrganism, setCurrentOrganism] = useState('')
    const [type, setType] = useState('')
    const [geneLocus, setGeneLocus] = useState('')
    // const [dnaFilter, setDnaFilter] = useState('')
    const [str, setStr] = useState('')
    const [length, setLength] = useState('')
    const [genome, setGenome] = useState('')
    const [from, setFrom] = useState('')
    const [expID_typeOfExperiment, setExpID_typeOfExperiment] = useState([])
    const [current_expID_typeOfExperiment, setCurrent_expID_typeOfExperiment] = useState([])

    const [columnsToDisplay, setColumnsToDisplay] = useState([]);

    const [selected, setSelected] = useState([]);
    // const [selectedNormalization, setSelectedNormalization] = useState('')

    const [all_track_description, setAll_track_description] = useState({})

    const { submitTableRnaList, RnaListData } = props; //, SendListGeneType, SendListTrackName

    const rnaListIDmodifier = () => {
        return rnaListID
    }

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    
    useEffect(() => {
        
        let ID = makeID(15)
        setRnaListID(ID)
        
        if (queryParameters.get("organism") !== null && queryParameters.get("name") !== null) {
            let organism_selectedTrackNames = queryParameters.get("organism")
            let rnaID_selectedTracks = -666
            if (queryParameters.get("rnaID") !== null && queryParameters.get("locus") !== null) {
                organism_selectedTrackNames = queryParameters.get("organism") + "____" + queryParameters.get("locus")
                rnaID_selectedTracks = Number(queryParameters.get("rnaID"))
                
            } else {

            }
            const dataRnaList = {
                'gene_name_target': "", 
                'selectedNormalization': "norm_value", 
                'selectedTrackNames': organism_selectedTrackNames, 
                'selectedTracks': rnaID_selectedTracks, 
                'typeOfContacts': "DNA_part___" + queryParameters.get("name")
            }
            setRnaName(queryParameters.get("name"))
            setCurrentOrganism(queryParameters.get("organism"))
            setAllInsert(dataRnaList.selectedTrackNames.split('____').length === 2 ? dataRnaList.selectedTrackNames.split('____')[1] : '')
            setGeneNameTarget('')
            setRnaListDataFirst(dataRnaList)
            submitTableRnaList(
                dataRnaList.selectedTracks, 
                dataRnaList.typeOfContacts, 
                dataRnaList.selectedTrackNames, 
                dataRnaList.selectedNormalization, 
                {
                    'rnaListID' : ID,
                    'page' :page, 
                    'rowsPerPage': rowsPerPage, 
                    'order': order,
                    'orderBy': orderBy,
                    'searchTerm': "",

                    'trackName': "",
                    'geneType': "",
                    'norm_value': "",
                    'minLength': "",
                    'maxLength': "",
                    'cpkm': "",
                    'locusFilter': [],
                }
            )
        } else {
            const dataRnaList = JSON.parse(localStorage.getItem("data_rna_list"));
            // console.log('######dataRnaList',dataRnaList)
            setRnaName(dataRnaList['typeOfContacts'].split("___")[1])
            setCurrentOrganism(dataRnaList['selectedTrackNames'].split("___")[0])
            // setRnaListDataFirst(dataRnaList)  
            setAllInsert(dataRnaList.selectedTrackNames.split('____').length === 2 ? dataRnaList.selectedTrackNames.split('____')[1] : '')
            setGeneNameTarget(dataRnaList.gene_name_target)
            // console.log('dataRnaList.gene_name_target',dataRnaList.gene_name_target)
            if (Object.keys(dataRnaList).includes('Intersection_genes_req')) {
                // console.log('############',Object.keys(dataRnaList).includes('Intersection_genes_req'))
                setRnaListDataFirst({
                    'selectedTracks': {'tracks': dataRnaList.tracks, 'Intersection_genes_req': dataRnaList.Intersection_genes_req}, 
                    'typeOfContacts': 'DNA_part', 
                    'selectedTrackNames': dataRnaList.selectedGeneTypes, 
                    'selectedNormalization': dataRnaList.selectedNormalization
                })
                submitTableRnaList(
                    {'tracks': dataRnaList.tracks, 'Intersection_genes_req': dataRnaList.Intersection_genes_req},
                    'DNA_part', 
                    dataRnaList.selectedGeneTypes, 
                    dataRnaList.selectedNormalization,
                    {
                        'rnaListID' : ID,
                        'page' :page, 
                        'rowsPerPage': rowsPerPage, 
                        'order': order,
                        'orderBy': orderBy,
                        'searchTerm': "",
    
                        'trackName': "",
                        'geneType': "",
                        'norm_value': "",
                        'minLength': "",
                        'maxLength': "",
                        'cpkm': "",
                        'locusFilter': [],
                    }
                )
            } else { 
                setRnaListDataFirst(dataRnaList)
                // console.log('##################dataRnaListdataRnaList',dataRnaList)
                submitTableRnaList(
                    dataRnaList.selectedTracks, 
                    dataRnaList.typeOfContacts, 
                    dataRnaList.selectedTrackNames, 
                    dataRnaList.selectedNormalization, 
                    {
                        'rnaListID' : ID,
                        'page' :page, 
                        'rowsPerPage': rowsPerPage, 
                        'order': order,
                        'orderBy': orderBy,
                        'searchTerm': "",
    
                        'trackName': "",
                        'geneType': "",
                        'norm_value': "",
                        'minLength': "",
                        'maxLength': "",
                        'cpkm': "",
                        'locusFilter': [],
                    }
                )
            }
        }
    }, [])

    // console.log('set_typeOfExperimenttygigiugjg',expID_typeOfExperiment)
    useEffect (() => {
        // console.log('RnaListData******************',RnaListData)
        let data_table_value_2 = []
        if (typeof(RnaListData['1']) === 'string') {
            setMessage(RnaListData['1'])
        } else {
            if (RnaListData['1']) {
                data_table_value_2 = RnaListData['1']
                data_table_value_2.map(value => {
                    value['locus'] = `${value['chr_ID']}:${value['start']}-${value['end']}`
                })
                setData_table_value(data_table_value_2)
                setRowsCount(RnaListData['2'])
                setType(RnaListData['1'][0]['gene_type'])
                setGeneLocus(RnaListData['1'][0]['locus'])
                // setDnaFilter('cscscscs')
                setStr(RnaListData['1'][0]['strand'])
                setLength(RnaListData['1'][0]['len'])
                setGenome(RnaListData['1'][0]['build'])
                setFrom(RnaListData['1'][0]['from'])
                
                let typExps = []
                for (var i = 0; i < RnaListData['1'].length; i++) {
                    typExps.push(RnaListData['1'][i]['expID_typeOfExperiment'])
                }
                // console.log('RnaListDataq typExps',typExps)
                // console.log('RnaListDataq new Set',new Set(typExps.map(i => i === 'All-to-all' ? 'All vs all' : 'One vs all')))
                setExpID_typeOfExperiment(Array.from(new Set(typExps.map(i => i === 'All-to-all' ? 'All vs all' : 'One vs all'))))
                // let expTypeList = []
                // console.log('RnaListDataq[set_typeOfExperiment]',RnaListData['set_typeOfExperiment'])
                // console.log('RnaListDataq',RnaListData)
                // for (var i = 0; i < RnaListData['1'].length; i++) {
                //     expTypeList.push(RnaListData['1'][i]['expID_typeOfExperiment'])
                // }
                // setExpID_typeOfExperiment([...new Set(expTypeList)])
            }
            if (RnaListData['all_track_description']) {
                setAll_track_description(RnaListData['all_track_description'])
            }
        }

        let selectedColumnsInit = [
            // "Name",
            "Track",
            // "Type",
            "n-reads (raw)",//n-reads (without norm.) //"N-obs",
            "CPKM (raw)",//CPKM (without norm.) //"CPKM(N-obs)",
            "n-reads (normalized)", //n-reads (background norm.) //"N-norm",
            "CPKM (normalized)", //CPKM (background norm.) //"CPKM(N-norm)",
            "n-reads (norm., peaks)",
            "n-reads (raw, peaks)", 
            "Type",
            // "Organism",
            // "Gene locus",
            // "Str.",
            "Exp.ID",
            // 'Track description',
            // 'Track description for tooltip',
            // 'Track description for plots',
        ];
        if (columnsToDisplay.length === 0) {
            setColumnsToDisplay(headCells.filter(cell => selectedColumnsInit.indexOf(cell.label) !== -1));
            // setColumnsToDisplay(headCells);
        } 
        let data = filterDataForColumns(data_table_value_2)
        setRowsToDisplay(data.slice(0, rowsPerPage))
    }, [RnaListData])

    // console.log('RnaListDataq expID_typeOfExperiment',expID_typeOfExperiment)

    useEffect(() => {
        if (Object.keys(RnaListData).length > 0) {
            let locusFilter = []
            submitTableRnaList(
                rnaListDataFirst.selectedTracks, 
                rnaListDataFirst.typeOfContacts, 
                rnaListDataFirst.selectedTrackNames, 
                rnaListDataFirst.selectedNormalization, 
                {
                    'rnaListID' : rnaListIDmodifier() + "_result",
                    'page': page, 
                    'rowsPerPage': rowsPerPage, 
                    'order': '',
                    'orderBy': '',
                    'searchTerm': "",

                    'trackName': [],
                    'geneType': [],
                    'norm_value': "",
                    'minLength': "",
                    'maxLength': "",
                    'cpkm': "",
                    'locusFilter': locusFilter,

                    'if_chach_empty': {
                        'page': page,
                        'rowsPerPage': rowsPerPage, 
                        'order': '',
                        'orderBy': '',
                        'searchTerm': "",
                        'trackName': [],
                        'geneType': [],
                        'norm_value': "",
                        'minLength': "",
                        'maxLength': "",
                        'cpkm': "",
                        'locusFilter': locusFilter,
                        }
                }
            )
        }
    }, [page, rowsPerPage])


    useEffect(() => {
        setSelected([])
        setPage(0)
        let locusFilter = []
        submitTableRnaList(
            rnaListDataFirst.selectedTracks, 
            rnaListDataFirst.typeOfContacts, 
            rnaListDataFirst.selectedTrackNames, 
            rnaListDataFirst.selectedNormalization, 
            {
                'rnaListID' : rnaListIDmodifier() + "_result",
                'page' :0, 
                'rowsPerPage': rowsPerPage, 
                'order': order,
                'orderBy': orderBy,
                'searchTerm': '',

                'trackName': [],
                'geneType': [],
                'norm_value': "",
                'minLength': "",
                'maxLength': "",
                'cpkm': "",
                'locusFilter': locusFilter,

                'if_chach_empty': {
                                'page' : 0, 
                                'rowsPerPage': rowsPerPage, 
                                'order': order,
                                'orderBy': orderBy,
                                'searchTerm': "",
                                'trackName': [],
                                'geneType': [],
                                'norm_value': "",
                                'minLength': "",
                                'maxLength': "",
                                'cpkm': "",
                                'locusFilter': locusFilter,
                                }
            }
        )
    }, [order,orderBy])


    // let columnsMap = {};
    // headCells.map(cell => {
    //     columnsMap[cell.label] = cell.id;
    // })


    // const handleColumnsChange = (cols) => {
    //     let newColumns = headCells.filter(cell => cols.indexOf(cell.id) !== -1);
    //     setColumnsToDisplay(newColumns);
    //     let data = filterDataForColumns(data_table_value, newColumns);
    //     setRowsToDisplay(data.slice(0, rowsPerPage));
    // }


    const handleRequestSort = (event, property) => {
        const isAsc =  orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
   

    const handleChangePage = (newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = value => {
        setRowsPerPage(parseInt(value, 10))
        setPage(0);
    };

    const filterDataForColumns = (data, cols) => {
        let colIds = columnsToDisplay.map(col => col.id);
        if (cols !== undefined) {
            colIds = cols.map(col => col.id);
        }
        let newData = data.map((row, i) => {
            let newRow = {};
            colIds.map(id => newRow[id] = row[id]);
            return newRow;
        })
        return newData;
    }
    
    
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setWarningMessageOpen(false)
        setOpen(false)
    }
    
    // console.log('current_expID_typeOfExperiment',current_expID_typeOfExperiment)

    const handleClick = (event, experiment_id, track_name, expID_typeOfExperiment) => { //name,       
        let newSelected = selected.slice()
        let newSelected2 = current_expID_typeOfExperiment.slice()
        let name_exp = rnaName + '___' +experiment_id + '&' + track_name + '^' + all_track_description[track_name]['track_description']
        if (selected.length < 20) {
            if (newSelected.indexOf(name_exp) === -1) {
                newSelected.push(name_exp)
                setSelected(newSelected)
                newSelected2.push(expID_typeOfExperiment)
                setCurrent_expID_typeOfExperiment(newSelected2)
            } else {
                newSelected.splice(selected.indexOf(name_exp), 1)
                setSelected(newSelected)
                newSelected2.splice(selected.indexOf(name_exp), 1)
                setCurrent_expID_typeOfExperiment(newSelected2)
            }
        } else {
            if (newSelected.indexOf(name_exp) !== -1) {
                newSelected.splice(selected.indexOf(name_exp), 1)
                setSelected(newSelected)
            } else {
                setOpen(true)
            }
        }
    };

    const isSelected = name => selected.indexOf(name) !== -1

    const onClick = (id) => {
        // console.log('onClick_id',id)
        let dataType = ""
        if (id.split('___')[1] === "One-to-all") {
            dataType = 'One vs all'
        } else {
            dataType = 'All vs all'
        }
        const data = {
          selectedExperiment: [parseInt(id.split('___')[0],10)],
          dataType: dataType
        }
        localStorage.setItem("metadata",  JSON.stringify(data));
        window.open("/metadata", "_blank");
      }
    
    let column = null;
    let table = null;
    if (message === '') {
        table = 
            <div>
                    <Grid
                        container
                        spacing={0}
                        direction={"row"}
                        justify={"center"}
                        alignItems={"center"}
                    >
                        <Grid item xs={9}>
                        <RnaTableInfo
                            name={rnaName}
                            type={type}
                            geneLocus={geneLocus}
                            dnaFilter={allInsert}
                            geneNameTarget={geneNameTarget}
                            str={str}
                            expID_typeOfExperiment={expID_typeOfExperiment}
                            length={length}
                            currentOrganism={currentOrganism}
                            genome={genome}
                            from={from}
                            // experiment_id={'10'}
                            track_name={'wffw'}
                            track_description={all_track_description} //all_track_description[row.track_name]['track_description']
                            // allInsert={1}
                        />
                <div className={classes.tableWrapper}>               
                    <TableContainer className={classes.container}>
                        <Table
                            className={classes.stickyHeader}
                            aria-labelledby="tableTitle"
                            size={'small'}
                            stickyHeader
                            aria-label="enhanced table"
                            style={{ width: "auto", tableLayout: "auto",}}
                        >
                            <EnhancedTableHead
                                geneNameTarget={geneNameTarget}
                                allInsert={allInsert}
                                numSelected={selected.length}
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                columnsToDisplay={columnsToDisplay}
                            />
                            <TableBody>
                            {rowsToDisplay.map((row, indexR) => {
                                // console.log('current_expID_typeOfExperiment row',row)
                                // const isSelectedID = isSelected(row.name+'___'+row.experiment_id + '&'+ row.track_name + '^' + all_track_description[row.track_name]['track_description']); //row.track_description
                                const isSelectedID = isSelected(rnaName + '___'+row.experiment_id + '&'+ row.track_name + '^' + all_track_description[row.track_name]['track_description']); //row.track_description
                                // console.log('qqq isSelectedID',isSelectedID)
                                // console.log('qqq selected', selected)
                                // console.log('qqq ', rnaName + '___'+row.experiment_id + '&'+ row.track_name + '^' + all_track_description[row.track_name]['track_description'])
                                return (
                                    <TableRow
                                        hover 
                                        tabIndex={-1} 
                                        className={classes.sizeSmall}
                                        key={indexR}
                                        onClick={event => handleClick(event, row.experiment_id, row.track_name, row.expID_typeOfExperiment)} //row.name , row.track_description
                                        role="checkbox"
                                        aria-checked={isSelectedID}
                                        selected={isSelectedID} 
                                    >
                                        {Object.keys(row).map((key, index) => {
                                            // console.log('key',key)
                                            if (key !== 'track_name') { //if (key === 'track_name') {
                                                // column=
                                                //     <TableCell 
                                                //         key={index}
                                                //         className={classes.sizeSmall} 
                                                //         size='small' 
                                                //         align="left" 
                                                //         style={{fontSize: 15, 
                                                //             whiteSpace: 'nowrap', 
                                                //             border: '1px solid #d3d4d5',
                                                //             borderTop: '1px solid #d3d4d5',
                                                //             borderLeft: '1px solid #d3d4d5',
                                                //         }}>
                                                //             <Grid container spacing={0} direction="row" style={{flexWrap: 'nowrap'}}>
                                                //                 <Grid item xs={2}>
                                                //                 <FormControlLabel
                                                //                     control={
                                                //                         <Checkbox 
                                                //                             style={{padding: 0, marginLeft: 10}}
                                                //                             checked={isSelectedID} 
                                                //                             onClick={event => handleClick(event, row.experiment_id, row.track_name)} //row.name,
                                                //                             color="secondary"
                                                //                         />
                                                //                     }
                                                //                 />
                                                //                 </Grid>
                                                //                 <Grid item xs={10} style={{marginRight: 1}}>
                                                //                     <div >
                                                //                         {row[key]}
                                                //                         {/* <SimpleDialogDemo 
                                                //                             // RnaSelected={[row.name+ '___' +row.experiment_id + '&' + row.track_name + '^' + all_track_description[row.track_name]['track_description']]} 
                                                //                             RnaSelected={[rnaName+ '___' +row.experiment_id + '&' + row.track_name + '^' + all_track_description[row.track_name]['track_description']]} 
                                                //                             // selectedNormalization={selectedNormalization} 
                                                //                             title={row[key]} 
                                                //                             selectedTrackNames={[row.track_name]}
                                                //                             experiment_id={row.experiment_id} 
                                                //                             // locus={row.locus} 
                                                //                             // gene_name={row.name}
                                                //                             gene_name={rnaName}
                                                //                             // gene_type={row.gene_type}
                                                //                             track_name={row.track_name}
                                                //                             all_insert={allInsert}
                                                //                             // experiment_id={row.experiment_id}
                                                //                             // sum_observed_value={row.sum_observed_value}
                                                //                             // sum_expected_value={row.sum_expected_value}
                                                //                             // sum_value={row.sum_value}
                                                //                             // cpkm={row.cpkm}
                                                //                             // locus={row.locus}
                                                //                             // strand={row.strand}
                                                //                             // len={row.len}
                                                //                             organism={currentOrganism}
                                                //                             // build={row.build}
                                                //                             // from={row.from}
                                                //                             /> */}
                                                //                     </div>
                                                //                 </Grid>
                                                //             </Grid>
                                                //     </TableCell>
                                            if (key === "experiment_id") {
                                                let description = all_track_description[row.track_name]['description_for_plots']
                                                let res = description.split(", ");
                                                column = 
                                                    <Tooltip
                                                        placement={"right-start"}
                                                        title={
                                                            <React.Fragment>
                                                                <div className={classes.tooltip}>
                                                                    {/* <Typography color="inherit" variant="h5" component="h1"><u>{`Experiment`}</u></Typography> */}
                                                                    <Typography>
                                                                        experiment ID: <b>{res[0]}</b><br/>organism: <b>{res[1]}</b><br/>cell line: <b>{res[2]}</b><br/>exp.protocol: <b>{res[3]}</b><br/>extra: <b>{res[4]}</b>
                                                                    </Typography>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <TooltipCell
                                                            key={index}
                                                            // padding="none" 
                                                            // size='small' 
                                                            align="center" 
                                                            // className={key === 'name' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                whiteSpace: 'nowrap', 
                                                                border: '1px solid #d3d4d5',
                                                                borderTop: '1px solid #d3d4d5',
                                                                borderLeft: '1px solid #d3d4d5',
                                                            }} 
                                                        >
                                                            {/* <Tooltip title="Go to Metadata page"> */}
                                                            <Grid container spacing={0} direction="row" style={{flexWrap: 'nowrap'}}>
                                                                <Grid item xs>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox 
                                                                            style={{padding: 0, marginLeft: 10}}
                                                                            checked={isSelectedID} 
                                                                            onClick={event => handleClick(event, row.experiment_id, row.track_name, row.expID_typeOfExperiment)} //row.name,
                                                                            color="secondary"
                                                                        />
                                                                    }
                                                                />
                                                                </Grid>
                                                                <Grid item xs style={{marginRight: 1}}>
                                                                    <Link component="button" onClick={() => onClick(row[key] + "___" + row['expID_typeOfExperiment'] )}>{row[key]}</Link> 
                                                                </Grid>
                                                            </Grid>
                                                        </TooltipCell>
                                                    </Tooltip>
                                            // } else if (key === "track_name") {
                                            //     let intersection = ''
                                            //     let description_track = all_track_description[row.track_name]['track_description_for_tooltip']
                                            //     let description_track_name = description_track.split("Intersection")[0]
                                            //     if (description_track.split("Intersection").length > 1) {
                                            //         intersection = description_track.split("Intersection")[1]
                                            //     }
                                            //         // if (description_track_name !== 'Genome wide') {
                                            //             // console.log('description_track_name: ',description_track_name)
                                            //     let RNA_filter = description_track_name.split(" | ")[0].split("RNA filter: ")[1]
                                            //     let DNA_filter = description_track_name.split(" | ")[1].split("DNA filter: ")[1]
                                            //     let DNA_filter_inser = []
                                            //     if (DNA_filter.indexOf('Gene body') !== -1) {
                                            //         DNA_filter_inser.push(<u>Gene body</u>)
                                            //         DNA_filter_inser.push(DNA_filter.split('Gene body')[1].split('$')[0] + ' ')
                                            //     }
                                            //     if (DNA_filter.indexOf('Upstream:') !== -1) {
                                            //         DNA_filter_inser.push(<u>Upstream</u>)
                                            //         DNA_filter_inser.push(DNA_filter.split('Upstream')[1].split('$')[0] + ' ')
                                            //     }
                                            //     if (DNA_filter.indexOf('Downstream:') !== -1) {
                                            //         DNA_filter_inser.push(<u>Downstream</u>)
                                            //         DNA_filter_inser.push(DNA_filter.split('Downstream')[1].split('$')[0] + ' ')
                                            //     }
                                            //     if (DNA_filter.indexOf('Upstream50kb:') !== -1) {
                                            //         DNA_filter_inser.push(<u>Upstream50kb</u>)
                                            //         DNA_filter_inser.push(DNA_filter.split('Upstream50kb')[1].split('$')[0] + ' ')
                                            //     }
                                            //     if (DNA_filter.indexOf('Downstream50kb:') !== -1) {
                                            //         DNA_filter_inser.push(<u>Downstream50kb</u>)
                                            //         DNA_filter_inser.push(DNA_filter.split('Downstream50kb')[1].split('$')[0] + ' ')
                                            //     }
                                            //     if (DNA_filter.indexOf('GeneDeserts:') !== -1) {
                                            //         DNA_filter_inser.push(<u>GeneDeserts</u>)
                                            //         DNA_filter_inser.push(DNA_filter.split('GeneDeserts')[1].split('$')[0] + ' ')
                                            //     }
                                            //     if (DNA_filter.indexOf('DNA locus:') !== -1) {
                                            //         DNA_filter_inser.push(<u>DNA locus</u>)
                                            //         DNA_filter_inser.push(DNA_filter.split('DNA locus')[1].split('$')[0] + ' ')
                                            //     }
                                            //     if (DNA_filter_inser.length === 0) {
                                            //         DNA_filter_inser.push('NaN')
                                            //     } 
                                            //     column = 
                                            //         <Tooltip
                                            //             placement={"right-start"}
                                            //             title={
                                            //                 <React.Fragment>
                                            //                     <div className={classes.tooltip}>
                                            //                         <Typography color="inherit" variant="h5" component="h1"><u>{`Track description:`}</u></Typography>
                                            //                         {intersection.length > 0 ?
                                            //                             <Typography>
                                            //                                 RNA filter: <b>{RNA_filter}</b><br/>DNA filter: <b>{DNA_filter_inser}</b><br/>Intersection<b>{intersection.split("between tracks:")[0]}</b>between tracks:<b>{intersection.split("between tracks:")[1]}</b>
                                            //                             </Typography> //<br/>Normalization: <b>{selectedNormalization}</b>
                                            //                             :
                                            //                             <Typography>
                                            //                                 RNA filter: <b>{RNA_filter}</b><br/>DNA filter: <b>{DNA_filter_inser}</b>
                                            //                             </Typography> //<br/>Normalization: <b>{selectedNormalization}</b>
                                            //                         }
                                            //                     </div>
                                            //                 </React.Fragment>
                                            //             }
                                            //         >
                                            //             <TooltipCell
                                            //                 key={index} 
                                            //                 align="center" 
                                            //                 className={classes.sizeSmall}
                                            //                 style={{fontSize: 15, 
                                            //                     whiteSpace: 'nowrap', 
                                            //                     border: '1px solid #d3d4d5',
                                            //                     borderTop: '1px solid #d3d4d5',
                                            //                     borderLeft: '1px solid #d3d4d5',
                                            //                 }} 
                                            //             >
                                            //                 {row[key]}
                                            //             </TooltipCell>
                                            //         </Tooltip>
                                            } else  if (key === "organism") {
                                                column=
                                                        <TableCell 
                                                            key={index}
                                                            // padding="none" 
                                                            // size='small' 
                                                            align="center" 
                                                            // className={key === 'name' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                    whiteSpace: 'nowrap', 
                                                                    border: '1px solid #d3d4d5',
                                                                    borderTop: '1px solid #d3d4d5',
                                                                    borderLeft: '1px solid #d3d4d5',
                                                                }} 
                                                            // key={key}
                                                        >
                                                                <i>{row[key]}</i>
                                                        </TableCell>
                                            } else  if (key === "cpkm_observed" || key === "cpkm") {
                                                let column_value = ''
                                                if (row['expID_typeOfExperiment'] === 'One-to-all') {
                                                    column_value = 'NA'
                                                } else {
                                                    column_value = row[key]
                                                }
                                                column=
                                                        <TableCell 
                                                            key={index} 
                                                            align="center" 
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                    whiteSpace: 'nowrap',border: '1px solid #d3d4d5',
                                                                    borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',
                                                                }} 
                                                        >
                                                                {column_value}
                                                        </TableCell>
                                            } else {
                                                column=
                                                        <TableCell 
                                                            key={index}
                                                            // padding="none" 
                                                            // size='small' 
                                                            align="center" 
                                                            // className={key === 'name' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                    whiteSpace: 'nowrap', 
                                                                    border: '1px solid #d3d4d5',
                                                                    borderTop: '1px solid #d3d4d5',
                                                                    borderLeft: '1px solid #d3d4d5',
                                                                    backgroundColor: row[key] === 0 ? '#d3d4d5' : null,
                                                                }} 
                                                            // key={key}
                                                        >
                                                                {row[key] === 0 && geneNameTarget === "" && allInsert === "" ? "NA" : row[key]}
                                                        </TableCell>
                                            }
                                            } else {
                                                column = null
                                            }
                                            return (
                                                column
                                            );
                                        })}
                                        <TableCell 
                                            key={123218276627} 
                                            align="center" 
                                            // className={classes.sizeSmall}
                                            style={{fontSize: 15, 
                                                    whiteSpace: 'nowrap',
                                                    padding: '0px 0px 0px 0px', 
                                                    textAlign: 'center',
                                                    border: '1px solid #d3d4d5',
                                                    borderTop: '1px solid #d3d4d5',
                                                    borderLeft: '1px solid #d3d4d5',
                                                }} 
                                            >
                                                <DownloadTrack 
                                                    typeOfContacts={"DNA_part"} 
                                                    all_insert={allInsert} 
                                                    expId_and_name={row['experiment_id'] + "___" + row['track_name'].split("(")[1].split(")")[0]}
                                                    dataTypeRow={allInsert + geneNameTarget + row['expID_typeOfExperiment']}
                                                />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                
                        <RnaListPaginationBasic
                            rowsPerPageOptions={[25, 50, 100]}
                            count={rowsCount} //rows.length
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    
                        </Grid>
                        <Grid item xs={3} style={{borderLeft: '2px solid #000000', marginBottom: 10}}>
                            <RnaListInfoButtonsBasic 
                                name={rnaName}
                                type={type}
                                geneLocus={geneLocus}
                                dnaFilter={allInsert}
                                geneNameTarget={geneNameTarget}
                                str={str}
                                expID_typeOfExperiment={expID_typeOfExperiment}
                                length={length}
                                currentOrganism={currentOrganism}
                                genome={genome}
                                from={from}
                                track_description={all_track_description} //all_track_description[row.track_name]['track_description']
                                parent={props.parent}
                            />
                            <RnaListButtonsBasic 
                                RnaSelected={selected} 
                                selectedNormalization={props.trackListCheckbox}
                                // experiment_id={row.experiment_id} 
                                gene_name={rnaName}
                                all_insert={allInsert}
                                organism={currentOrganism}

                                type={type}
                                geneLocus={geneLocus}
                                geneNameTarget={geneNameTarget}
                                str={str}
                                expID_typeOfExperiment={current_expID_typeOfExperiment}
                                length={length}
                                genome={genome}
                                from={from}
                                track_description={all_track_description} //all_track_description[row.track_name]['track_description']
                                parent={props.parent}
                            />          
                        </Grid>
                    </Grid> 
            {/* </Paper> */}
        </div>
    }

    return (
        <div className={classes.root}>
            {!props.loadingRnaList ?
            <div className={classes.paper}>
                <Typography color="error" variant="h5">
                    {message}
                </Typography>
                {table}
            </div>
            : 
            <div className={classes.loading}>
                <LinearProgress color={"primary"}/>
                <Typography variant="h5">
                    Please, wait a while, it may take from a few seconds to a couple of minutes
                </Typography>
            </div>
            }
            <WarningMessage open={props.snackRnaList} severity={"error"} message={props.snackTextRnaList} />
            <WarningMessage open={open} handleClose={handleClose} severity={"warning"} message={'You have reached the threshold of 20 selected RNAs'} />
            <WarningMessage open={warningMessageOpen} handleClose={handleClose} severity={"warning"} message={warningMessage} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        snackRnaList: state[module].snackRnaList,
        snackTextRnaList: state[module].snackTextRnaList,
        loadingRnaList: state[module].loadingRnaList,
        RnaListData: state[module].RnaListData,

        trackListCheckbox: state[moduleFilter].trackListCheckbox,
    }
}

export default connect(mapStateToProps, { submitTableRnaList })(TableRnaListBasic); //, SendListGeneType, SendListTrackName