import React, { useEffect } from 'react'
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
// import MUIDataTable from "mui-datatables";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
// import { Link } from 'react-router-dom'
// import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgress from '@material-ui/core/LinearProgress';

import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

import { allExpsRequest, handleHeader } from '../actions/generalActions';

import Contents from '../components/Contents'
import TableMetadata from '../components/TableMetadata';
import WarningMessage from '../components/MessageSnackbar';

import workflow from './svg/workflow_new.svg'
import Fig1 from './svg/Fig1.svg'
import important from './svg/important.png'

import hm1 from './svg/hm1.svg'
import hm2 from './svg/hm2.svg'
import hm3 from './svg/hm3.svg'
import hm4 from './svg/hm4.svg'
import fig7 from './svg/fig7.svg'

import metadata_information from './svg/metadata_information.svg'
import RNAs_distribution from './svg/RNAs_distribution.svg'

import RNAdistribution from './svg/RNAdistribution.png'
import download from './svg/download.png'

// import header from './svg/header.png'
import Number_of_contacts_distribution from './svg/Number_of_contacts_distribution.svg'
import Pie from './svg/Pie.svg'
import processing_data from './svg/processing_data.svg'

const module = "table"

const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        // padding: 50,
        // paddingTop: 70,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        //width: '40%',
        // textAlign: 'center',
        flexGrow: 1,
        // width: '96%',
    },
    title: {
        marginTop: 30,
        // marginBottom: 0,
        // textAlign: 'center !important',
        marginLeft: 23,
        textAlign: 'left',
        },
    divider: {
        marginTop: 20,
        marginBottom: 20,
        // margin: '10px auto',
        width: '100%',
        height: 2,
        backgroundColor: 'black',
      },
    link: {
      color: 'rgb(0, 150, 136)',
      '&:hover': {
        color: 'grey',
      }
    },
}));

const Overview = (props) => {

//   var Latex = require('react-latex');
  const classes = useStyles();
  document.title = "Database overview";
  
  const { AllExps } = props;

  useEffect(() => {
    props.handleHeader(': Database overview')
    props.allExpsRequest();
  }, [])

  return (
    <div className={classes.root}>
        <Grid container spasing={1} style={{marginTop: 20, flexWrap: 'nowrap'}}>
            <Grid item xs={1}>
                <Contents />
            </Grid>
            <Grid item xs={11} style={{width: `calc(100% - 200px)`, marginLeft: 120}}>
                <TableContainer style={{maxHeight: window.innerHeight*0.85}}>
                <Grid id='Start-of-work-process' container spasing={3} style={{border: '2px solid red'}}>
                    <Grid item xs={1}>
                        <div style={{marginBottom: 5}}>
                            <img src={important} alt="important" width='100%'/>
                        </div>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="h6" style={{marginTop: 20, marginBottom: 5, marginLeft: 15}}>
                            The main object of our resource is a <b style={{color: 'red'}}>contacts track</b> so if you have ever used the resource <b>"Genome Browser"</b>, you are already familiar with such an object as a <b>track</b>. The simplest track is an entire set of contacts from the given experiment.
                        </Typography> 
                    </Grid>
                </Grid>
                <Grid container spasing={1} style={{ marginTop: 20}}>
                    <Grid item xs={6}>
                        <Typography variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 10}}>
                            Start of work process
                        </Typography>
                        <Typography variant={"subtitle1"} style={{textAlign: "justify", fontWeight: 'lighter', marginBottom: 5, marginRight: 20}}>
                            The analysis starts with the <b>contacts track determining</b>. The user should select organism (by default, it is <i>Homo sapiens</i>) and experiment(s). Then, the user can select some of the available filters: <b>"RNA Filter"</b> (suitable for the experiment type "All vs all") and/or <b>"Contacts Filter"</b>, which make it possible to refine the sample of the studied RNAs and genomic loci (by default, a track with empty "Сontacts Filter" is genome wide). <b>It is important to emphasize: <u style={{color: 'red'}}>between "RNA Filter" and "Contacts Filter" a logical AND is implied, while a logical OR is inside each of them!</u></b> After <b>contacts track determining</b>, the user should add this track to the <b>"Track list"</b> clicking the <b>"CREATE TRACK"</b> button (see <i>Fig. 1: stages 1 to 5</i>).
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{marginBottom: 5,  border: '1px solid #d3d4d5'}}>
                            <img src={Fig1} alt="Fig1" width='100%'/>
                        </div>
                        <Typography variant={"subtitle1"} style={{textAlign: "center", fontWeight: 'lighter', marginLeft: 10, marginBottom: 5}}>
                            <i>Fig. 1. Determining and creating a contacts track</i>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spasing={1} style={{ marginTop: 5}}>
                    <Grid item xs={7}>
                        <Typography variant="h6" style={{fontWeight: 'bold', marginBottom: 5, marginLeft: 20}}>
                            RNA Filter:
                        </Typography>
                        <Typography variant={"subtitle1"} style={{textAlign: "justify", fontWeight: 'lighter',marginBottom: 5, marginLeft: 10}}>
                            <ul>
                                <li>Select specific RNAs (e.g. XIST)</li>
                                <li>Select specific RNA types (e.g. lncRNA)</li>
                                <li>Select RNAs transcribed from given genome loci (e.g "chrX:73820649-73852723")</li>
                                <li>Select RNAs that have many contacts in the experiment <u>in general</u> — <b>"Upper quartile" (Q4)</b></li>
                                <li>Select RNAs that have medium number of contacts in the experiment <u>in general</u> — <b>"Second & Third quartiles" (Q2 + Q3)</b></li>
                                <li>Select RNAs that have few contacts in the experiment <u>in general</u> — <b>"Lower quartile" (Q1)</b></li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" style={{fontWeight: 'bold', marginBottom: 5, marginLeft: 20}}>
                            Contacts Filter:
                        </Typography>
                        <Typography variant={"subtitle1"} style={{textAlign: "justify", fontWeight: 'lighter', marginBottom: 5, marginLeft: 10}}>
                            <ul>
                                <li>Select contacts that interact with given loci (e.g "chrX")</li>
                                <li>Select contacts that interact with the annotated <b>"Gene bodies"</b> (with all or with selected), <b>"Upstream"</b> or <b>"Downstream"</b> regions (of all or selected genes): 0-5 kb or 5-50 kb from the gene, <b>"Gene deserts"</b> (areas of the genome that do not overlap with any of the previously given intervals)</li>
                            </ul>
                        </Typography>
                    </Grid>
                </Grid>   





















                
                     
                <Typography variant={"subtitle1"} style={{textAlign: "justify", fontWeight: 'lighter', marginBottom: 5, marginLeft: 50}}>
                    If the user has selected multiple RNAs, the <b>contacts track</b> will represent the combined interaction of those RNAs with chromatin.
                </Typography>

                <Divider className={classes.divider}/>
        
                <Typography id='Available-types-of-data-analysis' variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                    Available types of data analysis
                </Typography>
                <Typography variant={"subtitle1"} style={{textAlign: "justify", fontWeight: 'lighter', marginBottom: 5, marginRight: 30}}>
                    Having the tracks in the <b>"Track list"</b>-table the user can select them for further analysis (see <i>Fig. 2</i>):
                </Typography>

                <Grid container spasing={3}>
                    <Grid item xs={7} style={{marginBottom: 5}}>
                        <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', marginBottom: 5, marginLeft: 50, marginRight: 30}}>
                            <ul>
                                <li><b>Download</b> the raw/normalized contacts' data in order to make user’s own normalization, filtering or any other analysis</li>
                                <li>Send track(s) to the <b>"UCSC Genome Browser"</b></li>
                                <li>Show the <b>"RNAs Distribution"</b> by RNA types in comparison with full annotation as well as intersecting RNAs in selected tracks</li> 
                                <li><b>"Graphical Summary"</b> — contacts' distributions over the genome, namely:</li>
                                <ul>
                                    <li><b>HeatMap</b> — displays the contacts density distribution across the genome for each track separately</li>
                                    <li><b>Comparative HeatMap</b> — displays the contacts density distribution across the genome (all tracks on the one graph)</li>
                                    <li><b>Contacts Distribution</b> — different displays the contacts density distribution across the genome (all tracks on the one graph)</li>
                                    <li><b>Distance Distribution</b> — displays the contacts density distribution depending on the distance between the RNA genes and chromatin loci (all tracks on the one graph)</li>
                                </ul>
                                <li>Show the <b>"RNA list"</b> that has number of contacts with the chromatin of all RNAs in selected tracks and other supplementary information</li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{marginTop: 50, marginBottom: 5, textAlign: 'left', border: '1px solid #d3d4d5'}}>
                            <img src={workflow} alt="workflow" width='100%'/>
                        </div>
                        <Typography variant={"subtitle1"} style={{textAlign: "center", fontWeight: 'lighter', marginLeft: 10, marginBottom: 5}}>
                            <i>Fig. 2. Full workflow of data analysis</i>
                        </Typography>
                    </Grid>
                </Grid>
                                        
                <Divider className={classes.divider}/>

                <Typography id="Use-case" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                    Basic use case
                </Typography>
                <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', marginBottom: 5}}>
                    Let's suppose we are interested in contacts of long non-coding RNAs with promoter regions (upstream 5kb) in two mouse experiments 
                    <ol>
                        <li>We select the <i>Mus musculus</i> and two experiments of interest (say, <b>"ES GRID"</b> and <b>"mES RADICL, Proteinase K"</b>) (<i>Fig. 1: stages 1 and 2</i>)</li>
                        <li>We define RNA filters: <b>"lncRNA, Xrna"</b> (<i>Fig. 1: stage 3</i>)</li>
                        <li>We define the contact filter: <b>"Upstream 5kb"</b> (<i>Fig. 1: stage 3</i>)</li>
                        <li>We define the track name (say, <b>"Upsrt_5kb"</b>)</li>
                        <li>Using the <b>"CREATE TRACK"</b> button, we add a new track to the <b>"Track list"</b> (<i>Fig. 1: stages 4 and 5</i>)</li>                
                        <li>We leave normalization at default <b>(N-norm)</b>, go to the <b>"Graphical Summary"</b> and find that in the <b>RADICL experiment</b> the RNAs from the chromosome 17 are significantly overrepresented (<i>Fig. 3</i>)</li>
                        <li>Looking at the <b>"Comparative HeatMap"</b> we can see that the contact landscapes are different (<i>Fig. 4</i>)</li>
                        <li>And on the <b>"Distance Distribution"</b> we can see that the contacts density in the RADICL experiment decreases faster with an increase in the distance between RNA genes and chromatin loci than in the GRID experiment (<i>Fig. 6</i>)</li>
                        <li>We return to the <b>"Tracks page"</b> and go to the <b>"RNA list"</b></li>
                        <li>We see that there are too many RNAs. We apply the filter on <b>CPKM</b> (see below)<b> = 100</b></li>
                        <li>We find that several RNAs <b>("Gm42418", "Gm26917", "X_17_3984_b_mm10", "Malat1")</b> have many contacts on the upstream regions of the genes</li>
                        <li>To have a look at the details we select these RNAs and send them to the <b>"Graphical Summary"</b> and <b>Genome Browser</b>. Only the filtered contacts will be shown (<b>upstream 5kb</b> in our case)</li>
                    </ol>
                </Typography>            
                <a href="/tutorial" target="_blank">Advanced use case</a>

                <Divider className={classes.divider}/>
        
        {/* <Typography id="Buttons-on-the-header" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
            Buttons on the header
        </Typography>
        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginBottom: 15}}>
            In the header of the each web-page there are four buttons:
        </Typography>
        <div style={{textAlign: 'left', marginLeft: 25}}>
            <img src={header} alt="header" width='60%'/>
        </div>
        <Typography variant={"subtitle1"} style={{fontWeight: 'lighter'}}>
            <ul>
                <li><b>PROTOCOL</b> — go to the page with the description of the data processing pipeline</li>
                <li><b>EXPERIMENTS</b> — go to the page that lists full information about experiments in the database</li>
                <li><b>OVERVIEW</b> — the description of the functionality of this resource</li>
                <li><b>TRACKS</b> — the start page for RNA-Chromatin interaction analysis</li>
            </ul>
        </Typography>

        <Divider className={classes.divider}/> */}
                <Grid container spasing={1}>
                    <Grid item xs={8}>
                        <Typography id="Experiment-metadata-page" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                            All data information
                        </Typography>
                        <Typography variant={"subtitle1"} style={{fontWeight: 'lighter'}}>
                            The <a href="/experiments" target="_blank"><b>"Data list"</b></a> page contains complete information about all database data.
                            If you want to know detailed information (<b>Metadata page</b>) of a specific data set, you should click on <b>ID</b> link.
                        </Typography>
                        <Typography variant={"subtitle1"} style={{fontWeight: 'lighter'}}>
                            You can find out the details of data processing <a href="/protocol_old" target="_blank">here</a>.
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {!props.loadingAllExps && Object.keys(AllExps).length !== 0 ?
                        <div style={{marginTop: 20}}>
                            <TableMetadata 
                                title={'Database content:'} 
                                data={AllExps['z']} 
                                columns={AllExps['x']}
                                />
                            <Typography variant={"subtitle1"} style={{textAlign: "center", fontWeight: 'lighter', marginLeft: 10, marginBottom: 5}}>
                                <i>Table 1. Number of data sets in database</i>
                            </Typography>
                        </div>
                        :
                        <div> 
                            <LinearProgress color={"primary"} />
                            <Typography variant="h5">
                                Please, wait a while, it may take from a few seconds to a couple of minutes
                            </Typography>
                            <WarningMessage open={props.snackOpenAllExps} severity={"error"} message={props.snackTextAllExps} />
                        </div>
                        }
                    </Grid>
                </Grid>
                <div style={{marginLeft: 1, width: '99%'}}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant={"subtitle3"} style={{fontWeight: 'bold'}}>Metadata page</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{flexGrow: 0}}>
                                <Grid container spasing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle1"} style={{textAlign: 'justify',fontWeight: 'lighter'}}>
                                            <ul>
                                                <li style={{marginLeft: 20}}><b>"Metadata information"</b> table</li>
                                                <img src={metadata_information} alt="metadata_information" width='100%'/>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant={"subtitle1"} style={{textAlign: 'justify',fontWeight: 'lighter'}}>
                                            <ul>
                                                <li style={{marginLeft: 20}}><b>"RNAs distribution"</b> table</li>
                                                <img src={RNAs_distribution} alt="RNAs_distribution" width='100%'/>
                                                <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', textAlign: "center"}}>
                                                    <i><b>"RNAs in the data set"</b> displays the amount of RNAs of each type that interact with chromatin in a selected data set. <b>"Annotated RNAs"</b> displays the amount of RNAs of each type that available in the annotation of our database.</i>
                                                </Typography>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spasing={1}>
                                <Grid item xs={6}>
                                    <Typography variant={"subtitle1"} style={{textAlign: 'justify',fontWeight: 'lighter'}}>
                                        <ul>
                                            <li style={{marginLeft: 20}}>RNA types shares of the total number of contacts (%)</li>
                                            <img src={Pie} alt="Pie" width='100%'/>
                                            <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', textAlign: "center"}}>                                               
                                                <i>This pie chart displays only those RNA types that have a proportion {'>'} 0.2% of the total number of contacts.</i>
                                            </Typography>
                                        </ul>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"subtitle1"} style={{textAlign: 'justify',fontWeight: 'lighter'}}>
                                        <ul>
                                            <li style={{marginLeft: 20}}>Distribution of the amount of RNA depending on the number of contacts</li>
                                            <img src={Number_of_contacts_distribution} alt="Number_of_contacts_distribution" width='100%'/>
                                            <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', textAlign: "center"}}>                                               
                                                <i>This graph can help with the choice of the threshold for the number of contacts when analysing the entire data set or individual RNA types. RNAs that have less or equal 10 contacts are merged.</i>
                                            </Typography>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant={"subtitle1"} style={{textAlign: 'justify',fontWeight: 'lighter'}}>
                                <ul>
                                    <li style={{marginLeft: 20}}>Full summary statistics of the biological replicates processing protocol (in this example only for GSM3852760, GSM3852761)</li>
                                    <img src={processing_data} alt="processing_data" width='100%'/>
                                    {/* <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', textAlign: "center"}}>                                               
                                        <i>This graph can help with the choice of the threshold for the number of contacts when analysing the entire data set or individual RNA types. RNAs that have less or equal 10 contacts are merged.</i>
                                    </Typography> */}
                                </ul>
                            </Typography>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>




















                

                <Divider className={classes.divider}/>

                <Typography id="Create-contacts'-tracks" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                    Create contacts' tracks
                </Typography>
                <Typography variant={"subtitle1"} style={{fontWeight: 'lighter'}}>
                    The analysis starts with the contacts track determining and creation. The <strong>algorithm</strong> is the following (see <i>Fig. 1: stages 1 to 5</i>):
                    <ul>
                        <li>Select the organism genome</li>
                        <li>Select the experiment(s) (the contacts from different experiments are independent)</li>
                        <li>Select filters (or not), for example select all contacts of <b>long non-coding RNAs</b> with <b>upstream 5kb regions</b> of the genes</li>
                        <li>Define the track name (by default, it is <b>"Track N"</b>)</li>
                        <li>Using the button <b>"CREATE TRACK"</b> the user creates the contacts' track and adds the track to the <b>"Track list"</b></li>
                    </ul>
                    Having a set of the contacts' track(s) you can continue in different ways (using one of the contacts normalization type):
                    <ul>
                        <li><b>Download</b> the tracks</li>
                        <li>View the <b>"Graphical Summary"</b></li>
                        <li>Show the <b>"RNA list"</b> that has number of contacts with the chromatin of all RNAs in selected track(s) and other supplementary information</li>
                        <li>Send the contacts' track(s) to the <b>UCSC Genome Browser</b></li>
                        <li>Show the <b>"RNAs Distribution"</b> by RNA types in comparison with full annotation as well as intersecting RNAs in selected track(s)</li>
                    </ul>
                </Typography>

                <Divider className={classes.divider}/>

                <Typography id="Download-tracks" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                    Download tracks
                </Typography>
                <Typography variant="subtitle1" style={{textAlign: "justify", fontWeight: 'lighter', marginBottom: 5,}}>
                    The user can download contacts' data only for one track  at a time and this track has to contain only one data set ID.
                </Typography>
                <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', marginBottom: 5}}>
                    The downloaded CSV file will contain the following information:
                    <ul>
                        <li>The coordinates of the loci with which selected in the track RNA(s) interact</li>
                        <li><b>Normalized count</b> of contacts with the given locus (see below)</li>
                    </ul>
                </Typography>
                <div style={{textAlign: 'left'}}>
                    <img src={download} alt="download" width='70%'/>
                </div>



















                
                
                <Divider className={classes.divider}/>

                <Typography id="RNAs-Distribution" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                    "RNAs Distribution"
                </Typography>
                <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginBottom: 5}}>
                    The table shows RNAs distribution by RNA types in comparison with full annotation as well as intersecting RNAs in selected tracks. The user can select intersecting RNAs and send groupped by RNA types contacts tracks to the <b>"Graphical Summary"</b> and to the <b>"RNA list"</b> or to the <b>"Genome Browser"</b>.
                </Typography>
                <div style={{textAlign: 'left'}}>
                    <img src={RNAdistribution} alt="RNAdistribution" width='70%' style={{border: '1px solid #d3d4d5'}}/>
                </div>

                <Divider className={classes.divider}/>

                <Typography id="RNA-list" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                    "RNA list"
                </Typography>
                <Typography variant={"subtitle1"} style={{fontWeight: 'lighter', marginBottom: 5}}>
                    This page presents the list of annotated RNAs from selected tracks. For every RNA the table contains following information: <br/>
                    <ul>
                        <li>Gene name</li>
                        <li>RNA type</li>
                        <li>Track name</li>
                        <li>data set ID</li>
                        <li>Observed contact counts (<b>N-obs</b>) — see <a href="/protocol_old" className={classes.link}>Protocol page</a></li>
                        <li>Expected contact counts (<b>N-exp</b>) — see <a href="/protocol_old" className={classes.link}>Protocol page</a></li>
                        <li>Normalized contact counts (<b>N-norm</b>) — see <a href="/protocol_old" className={classes.link}>Protocol page</a></li>
                        <li>CPKM — number of contacts normalized to library size and RNA length (<b style={{color: 'red'}}>is calculated according to the selected normalization</b><b>, by default it is N-norm!</b>)</li>
                        <li>Locus and strand — gene localisation</li>
                        <li>Gene length</li>
                        <li>Organism</li>
                        <li>Genome version</li>
                        <li>Annotation source</li>
                    </ul>
                    <BlockMath math={'\\small \\textbf{CPKM}\\text{(RNAi)}=\\frac{10^{9}*\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(RNAi)}}{\\textbf{Gene length}\\text{(RNAi)}*\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the experiment)}}'} />
                    The list can be sorted and filtered in different ways. The user can select RNAs and send them like independed contacts tracks to the <strong>"Graphical Summary"</strong> and to the <strong>"Genome Browser"</strong>.
                </Typography>
 
                <Grid container spasing={1} style={{ marginTop: 10}}>
                    <Grid item xs={7} style={{ marginTop: 10}}>
                        <Typography variant="h6" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 10, textAlign: 'center'}}>
                            RNA-parts of contacts profile:
                        </Typography>
                        <Typography variant="subtitle1" style={{textAlign: "justify", fontWeight: 'lighter', marginLeft: 15, marginBottom: 5, marginRight: 20}}>
                            A graph that displays the RNA-parts distribution of the selected RNA in the coordinates of the corresponding gene (bin size = 200 bp).
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>Y-axis</b>: Density.
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>X-axis</b>: coordinate of the bin center relative to the gene. 
                        </Typography>
                        <BlockMath math={'\\small \\textbf{Density(i)}=\\frac{10^{9}*\\sum_{\\text{bin(i)}}^{}\\textbf{N-norm}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all bins}}^{}\\textbf{N-norm}+100\\right )}'} />
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{marginBottom: 5,  border: '1px solid #d3d4d5'}}>
                            <img src={fig7} alt="fig7" width='100%'/>
                        </div>
                        <Typography variant={"subtitle1"} style={{textAlign: "center", fontWeight: 'lighter', marginLeft: 10, marginBottom: 5}}>
                            <i>Fig. 7. RNA-parts profile of the selected RNA</i>
                        </Typography>
                    </Grid>
                </Grid>
        
                <Divider className={classes.divider}/>

                <Typography id="Graphical-Summary" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                    "Graphical Summary"
                </Typography>
                <Typography variant="h5" style={{fontWeight: 'lighter', textAlign: 'center' , marginBottom: 5}}>
                    <u>The service presents the following types of data representation:</u>
                </Typography>
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 30, marginBottom: 15, marginTop: 0}}>
                    Each of the metrics is calculated for a specific "Chr.target length" (<b>Genome-wide contacts' heatMap</b> and <b>Comparative heatMap</b>), "Bin size" (<b>Contacts distribution</b>), or "Interval length" (<b>Distance distribution</b>).
                </Typography>













                <Grid id='qp' container spasing={1} style={{ marginTop: 20}}>
                    <Grid item xs={7} style={{ marginTop: 50}}>
                        <Typography variant="h6" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 10, textAlign: 'center'}}>
                            Genome-wide contacts' HeatMap:
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5, marginRight: 20}}>
                            A heatmap that displays contacts density distribution over chromosomes for RNAs from the track.
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>Y-axis</b>: the chromosomes on which selected in the track RNA(s) are localised.
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>X-axis</b>: the chromosomes with which selected in the track RNA(s) interact.
                        </Typography>
                        <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{10}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(RNAs from corresponding chromosome)}}{\\textbf{Chr. length}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} />
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{marginBottom: 5,  border: '1px solid #d3d4d5'}}>
                            <img src={hm1} alt="hm1" width='100%'/>
                        </div>
                        <Typography variant={"subtitle1"} style={{textAlign: "center", fontWeight: 'lighter', marginLeft: 10, marginBottom: 5}}>
                            <i>Fig. 3. Contacts density distribution over chromosomes for RNAs from the track</i>
                        </Typography>
                    </Grid>
                </Grid>
                                
                <Divider className={classes.divider}/>

                <Grid container spasing={1} style={{ marginTop: 20}}>
                    <Grid item xs={7} style={{ marginTop: 50}}>
                        <Typography variant="h6" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 10, textAlign: 'center'}}>
                            Comparative HeatMap:
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5, marginRight: 20}}>
                            A heatmap that displays contacts density distribution over chromosomes for several tracks on the one graph.
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>Y-axis</b>: track names.
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>X-axis</b>: the chromosomes with which selected in the track RNA(s) interact.
                        </Typography>
                        <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{10}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Chr. length}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} />
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{marginBottom: 5,  border: '1px solid #d3d4d5'}}>
                            <img src={hm2} alt="hm2" width='100%'/>
                        </div>
                        <Typography variant={"subtitle1"} style={{textAlign: "center", fontWeight: 'lighter', marginLeft: 10, marginBottom: 5}}>
                            <i>Fig. 4. Contacts density distribution  over chromosomes for RNAs from tracks on the one graph</i>
                        </Typography>
                    </Grid>
                </Grid>

                            
                <Divider className={classes.divider}/>

                <Grid container spasing={1} style={{ marginTop: 20}}>
                    <Grid item xs={7}>
                        <Typography variant="h6" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 10, textAlign: 'center'}}>
                            Contacts Distribution:
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            A plot that displays genome-wide contacts density distribution (genome bin size = 1500000 bp).
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>Y-axis</b>: contacts density.
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>X-axis</b>: the chromosomes with which selected in the track RNA(s) interact.
                        </Typography>
                        <BlockMath math={'\\small \\textbf{Contacts Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} />
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{marginBottom: 5,  border: '1px solid #d3d4d5'}}>
                            <img src={hm3} alt="hm3" width='100%'/>
                        </div>
                        <Typography variant={"subtitle1"} style={{textAlign: "center", fontWeight: 'lighter', marginLeft: 10, marginBottom: 5}}>
                            <i>Fig. 5. Genome-wide contacts density distribution</i>
                        </Typography>
                    </Grid>
                </Grid>
                                
                <Divider className={classes.divider}/>

                <Grid container spasing={1} style={{ marginTop: 20}}>
                    <Grid item xs={7} style={{ marginTop: 50}}>
                        <Typography variant="h6" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 10, textAlign: 'center'}}>
                            Distance Distribution:
                        </Typography>
                        <Typography variant="subtitle1" style={{textAlign: "justify", fontWeight: 'lighter', marginLeft: 15, marginBottom: 5, marginRight: 20}}>
                            A graph that displays the contact density depending on the distance between the RNA-part of the contact and chromatin loci (on the same chromosome as the corresponding RNA gene). The interaction of RNA with its source gene is not included in the analysis.
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>Y-axis</b>: Log2(Density).
                        </Typography>
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            <b>X-axis</b>: Log2(Distance between RNA-part of the contact and loci). 
                        </Typography>
                        <BlockMath math={'\\small \\textbf{Density}=\\frac{10^{9}*\\sum_{\\text{loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Interval length}*\\left (\\sum_{\\text{all local loci}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} />
                        <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                            Each <b>interval</b> does not include the previous ones and is part of the sequence: <br /><b>[2^0; 2^4], (2^4; 2^5], (2^5; 2^6], (2^6; 2^7], (2^7; 2^8], (2^8; 2^9], (2^9; 2^10] ...</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{marginBottom: 5,  border: '1px solid #d3d4d5'}}>
                            <img src={hm4} alt="hm4" width='100%'/>
                        </div>
                        <Typography variant={"subtitle1"} style={{textAlign: "center", fontWeight: 'lighter', marginLeft: 10, marginBottom: 5}}>
                            <i>Fig. 6. Contacts density distribution depending on the distance between the RNA genes and chromatin loci</i>
                        </Typography>
                    </Grid>
                </Grid>

                <Divider className={classes.divider}/>

                <Typography id="Genome-Browser" variant="h5" style={{fontWeight: 'bold', marginBottom: 15, marginTop: 20}}>
                    "Genome Browser"
                </Typography>
                <Typography variant={"subtitle1"} style={{textAlign: "justify", fontWeight: 'lighter', marginBottom: 5}}>
                    The service allows the user to send the contacts' track(s) to the <b>UCSC Genome Browser</b>. The track that is sent to the Genome Browser contains a normalized contact signal (see <a href="/protocol_old" className={classes.link}>Protocol page</a>) binned by 200 bp (bin size = 200 bp). If the track contains contacts of several RNAs, all the contacts of all RNAs will be presented in the track. There are two ways to send data to the Genome Browser. The first one – the user can go to the Genome Browser from the <b>Track page</b>. In this case, the entire selected track will be sent to the Genome Browser. Another way – to select one or more RNAs in the <b>RNA list</b> and send the contacts of the selected RNAs.
                </Typography>    
                <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                    <b>Y-axis</b>: contacts density.
                </Typography>
                <Typography variant="subtitle1" style={{fontWeight: 'lighter', marginLeft: 15, marginBottom: 5}}>
                    <b>X-axis</b>: the chromosomes with which selected in the track RNA(s) interact.
                </Typography>
                <BlockMath math={'\\small \\textbf{Contacts Density(i)}=\\frac{10^{9}*\\sum_{\\text{bin i}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}}{\\textbf{Bin size}*\\left (\\sum_{\\text{all bins}}^{}\\textbf{N-norm}\\text{(all RNAs from the track)}+100\\right )}'} />    

                <Divider style={{marginTop: 20,marginBottom: 0,width: '100%',height: 2,backgroundColor: 'black'}}/>
                </TableContainer>
            </Grid>
        </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        loadingAllExps: state[module].loadingAllExps,
        AllExps: state[module].AllExps,
        snackOpenAllExps: state[module].snackOpenAllExps,
        snackTextAllExps: state[module].snackTextAllExps,
    }
  }
  
export default connect(mapStateToProps, { allExpsRequest, handleHeader })(Overview);