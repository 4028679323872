export const FETCH_BASIC_GENE_TABLE_REQUEST = "FETCH_BASIC_GENE_TABLE_REQUEST";
export const FETCH_BASIC_GENE_TABLE_SUCCESS = "FETCH_BASIC_GENE_TABLE_SUCCESS";
export const FETCH_BASIC_GENE_TABLE_FAILURE = "FETCH_BASIC_GENE_TABLE_FAILURE";

export const fetchBasicGeneTable = (params) => {
    return {
        type: FETCH_BASIC_GENE_TABLE_REQUEST,
        params,
    };
};


export const EXP_TABLE_SELECT_ONE_ID = "EXP_TABLE_SELECT_ONE_ID";

export const selectOneId = (id) => {
    return {
        type: EXP_TABLE_SELECT_ONE_ID,
        id,
    };
};

export const FETCH_BASIC_GENE_TABLE_FROM_DNA_REQUEST = "FETCH_BASIC_GENE_TABLE_FROM_DNA_REQUEST";
export const FETCH_BASIC_GENE_TABLE_FROM_DNA_SUCCESS = "FETCH_BASIC_GENE_TABLE_FROM_DNA_SUCCESS";
export const FETCH_BASIC_GENE_TABLE_FROM_DNA_FAILURE = "FETCH_BASIC_GENE_TABLE_FROM_DNA_FAILURE";

export const fetchBasicGeneTableFromDNA = (params) => {
    return {
        type: FETCH_BASIC_GENE_TABLE_FROM_DNA_REQUEST,
        params,
    };
};


export const CLEAR_BASIC_GENE_TABLE_DATA_FROM_DNA = "CLEAR_BASIC_GENE_TABLE_DATA_FROM_DNA";

export const clearBasicGeneTableDataFromDNA = () =>{ 
    return {
        type: CLEAR_BASIC_GENE_TABLE_DATA_FROM_DNA,
    };
};

