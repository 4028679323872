import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    tooltip: {
        // title: "Add", 
        // placement: "top",
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: "auto",
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        height: '100%',
    },
}))

const HtmlTooltipDecorator = (props) => {
    
    const classes = useStyles();
    return (
        <Tooltip
            placement="right-start"
            title={
                <React.Fragment>
                    <div className={classes.tooltip}>
                        <Typography color="inherit" variant="h5" component="h1" style={{whiteSpace: 'nowrap', textAlign: "center"}}>{props.header}</Typography>
                        {props.text}
                    </div>
                </React.Fragment>
            }
        >
            {props.component}
        </Tooltip>
    )}

export default HtmlTooltipDecorator