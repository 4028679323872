import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import  { useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'; //withStyles
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
// import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import TextField from '@material-ui/core/TextField';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import logo from './svg/RNA-chrom.png'
// import Data_types from './svg/Data_types.svg'
// import from_dna from './svg/from_rna.svg'
import workflow_from_dna from './svg/workflow_from_dna.svg'
// import browseRNAs_from_dna from './svg/browseRNAs_from_dna.svg'
import browseAllRNAs2 from './svg/browseAllRNAs2.svg'
import Decorator from '../components/HtmlTooltip';
import Tooltip from '@material-ui/core/Tooltip';
// import Divider from '@material-ui/core/Divider';
import InputsTwo from '../components/TextAdd_two';
import InputsLenght from '../components/InputsLenght';
import InputsTwoUpstream from '../components/InputsTwoUpstream';
import InputsTwoDownstream from '../components/InputsTwoDownstream';
import InputTwoFromDNA from '../components/InputTwoFromDNA';
import InputThree from '../components/InputThree';
import TableFromDna from '../components/TableFromDna';
// import classNames from 'classnames';
// import { connect } from 'react-redux';
// import TableNewSuper from '../components/TableNewSuper';
import WarningMessage from '../components/MessageSnackbar';
// import TableMetadata from '../components/TableMetadata';
import BasicTableRNA from '../components/TABLEpartBasic';
import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';
import DownloadTrack from '../components/DownloadTrack';

import { fetchGenes, handleGeneSelect, changeCurrentGeneInput } from '../actions/genesActions';
import {textChange, locusWarningChangeFalse,locusWarningChangeTrue,currentLocusChange} from '../actions/generalActions';
import {textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA} from '../actions/generalActions';
import {textLengthChange, lengthWarningChangeFalse,lengthWarningChangeTrue,currentLengthChange} from '../actions/generalActions';
import {textChangeUpstream, textUpstreamWarningChangeFalse, textUpstreamWarningChangeTrue, clearTextInputsTwoUpstream} from '../actions/generalActions';
import {textChangeDownstream, textDownstreamWarningChangeFalse, textDownstreamWarningChangeTrue} from '../actions/generalActions';
import { textChangeSearchGeneName, SendListGeneType, SendListAnnotationSource, SendListGeneName } from '../actions/generalActions';
import { handleHeader } from '../actions/generalActions';
import { handleOrganismSelect } from '../actions/organismActions';

import OrganismSelectorSimple from '../components/AutoSuggestOrganismSimple';
import GeneSelector from '../components/AutoSuggest';
// import GeneTypesSelector from '../components/AutoSuggestTypeGenes';
// import AnnotationSelector from '../components/AutoSuggestAnnotation';
import RNAAutocomplete from '../components/RNAAutocomplete';

import { fetchBasicGeneTableFromDNA, selectOneId } from '../actions/basicAnalysisActions';
import { clearText } from '../actions/generalActions';
import { clearTextInputsTwo } from '../actions/generalActions';
import { clearRNAGeneSelectorFromDNA } from '../actions/genesActions';



const module = "genes";
const moduleTable = "table";
const moduleBasicAnalysis = "basicAnalysis";
const moduleFilter = "filter";
const moduleText = "text";

const makeID = (length) => {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


const LocusFilterParser = (valueLocus) => {
  let loci_array = []
  for (const one of valueLocus.replace(/;$/g, '').split(';').map(function(item) {return item.trim();})) {
      let chr = 0
      let start = 0
      let end = 0
      var locus_array = one.replace(/,+|\s+/g, '').split(/r|:|-/i).map(function(item) {return item.trim();})
      // console.log('locus_array',locus_array)
      if (locus_array.length === 2 && locus_array[0].toLowerCase() === 'ch' && (isNaN(Number(locus_array[1])) !== true || locus_array[1].toLowerCase() === 'x' || locus_array[1].toLowerCase() === 'y')) {
          {locus_array[1].toLowerCase() === 'x' ? chr = 33 : locus_array[1].toLowerCase() === 'y' ? chr = 44 : chr = Number(locus_array[1])}
          end = 1000000000000000
          loci_array.push([chr,start,end])
          // props.locusWarningChangeFalse()
      } else if (locus_array.length === 4 && locus_array[2] !== '' && locus_array[3] !== '' && isNaN(Number(locus_array[2])) !== true && isNaN(Number(locus_array[3])) !== true) {
          if (locus_array[0].toLowerCase() === 'ch' && Number(locus_array[2].replace(/^0*/,'')) < Number(locus_array[3].replace(/^0*/,''))) {
              {locus_array[1].toLowerCase() === 'x' ? chr = 33 : locus_array[1].toLowerCase() === 'y' ? chr = 44 : chr = Number(locus_array[1])}
              start = Number(locus_array[2])
              end = Number(locus_array[3])
              loci_array.push([chr,start,end])
          } else {
              loci_array = []
              break;
          }
      } else {
          loci_array = []
          break;
      }
  }
  return loci_array
}



const rows_fromDNA = [
  // {id: 'id', numeric: false, disablePadding: false, label: 'ID'},
  {id:'gene_name', numeric: false, disablePadding: false, label: 'RNA name' },
  {id:'total_experiments', numeric: false, disablePadding: true, label: 'Total exps'},
  {id:'max_cpkm_all_vs_all', numeric: false, disablePadding: true, label: 'max CPKM'}, //All-to-all max(sum[contacts])
  {id:'max_sum_norm_value_all_vs_all', numeric: false, disablePadding: true, label: 'max n-reads'}, //All-to-all max(sum[contacts])
  {id:'all_vs_all_from_dna', numeric: false, disablePadding: true, label: 'Exps'}, //All-to-all
  // {id:'max_cpkm_one_vs_all', numeric: false, disablePadding: true, label: 'max CPKM'}, //One-to-all max(sum(contacts))
  {id:'max_sum_norm_value_one_vs_all', numeric: false, disablePadding: true, label: 'max n-reads'}, //One-to-all max(sum(contacts))
  {id:'one_vs_all_from_dna', numeric: false, disablePadding: true, label: 'Exps'}, //One-to-all
  // {id:'number_of_all_vs_all_dna_filter', numeric: false, disablePadding: true, label: 'All-to-all (with dna filter)'},
  // {id:'number_of_one_vs_all_dna_filter', numeric: false, disablePadding: true, label: 'One-to-all (with dna filter)'},
  // {id:'number_of_all_vs_all', numeric: false, disablePadding: true, label: 'All-to-all'},
  // {id:'number_of_one_vs_all', numeric: false, disablePadding: true, label: 'One-to-all'},
  {id:'gene_type', numeric: false, disablePadding: true, label: 'RNA type'},
  {id:'locus', numeric: false, disablePadding: true, label: 'Gene location'},
  {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
  {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
  {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},
];


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 40,
    marginTop: theme.spacing(3),
  },
  link: {
    color: 'rgb(0, 150, 136)',
    '&:hover': {
      color: 'grey',
    }
  },
  locationCheckbox: {
    padding: theme.spacing(),
    // width: '90%',
    // marginLeft: theme.spacing(),
    // marginTop: theme.spacing(),
    // textAlign: 'center',
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
  tabs: {
    color: 'black',
  },
  loading: {
    marginTop: 150,
    textAlign: 'center',
  },
  button: {
    padding: theme.spacing(),
  },
  button2: {
    padding: theme.spacing(),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    textAlign: 'center',
    marginBottom: theme.spacing(),
  },
  wideButton: {
    padding: theme.spacing(),
    width: '98%',
    height: theme.spacing(12), //'100%'
    marginTop: theme.spacing(),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),    
    color: theme.palette.grey[500],
  },
  dialog: {
    maxWidth: '100%',
    // width: '1000px'
  },
  autocomplete: {
    flexGrow: 1,
    paddingTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
}));

const BasicFromDna = (props) => {

  const classes = useStyles();
  document.title = "RNA-Chrom: from DNA";
  
  const [open, setOpen] = useState(false)
  const [openDiolog, setOpenDiolog] = useState(false)
  const [message, setMessage] = useState('')
  const [extraParams, setExtraParams] = useState({'from': [], 'gene_type': [], 'gene_name': [], 'SearchGeneName': '', 'LocusCoordinatesForRNAs': '', 'LengthForRNAs': ''})
  const [currentGeneNameForDNA, setCurrentGeneNameForDNA] = useState([])
  const [currentValueUpstream, setCurrentValueUpstream] = useState('')
  const [currentValueDownstream, setCurrentValueDownstream] = useState('')
  const [currentLocusCoordinatesForDNA, setCurrentLocusCoordinatesForDNA] = useState('')
  const [currentOrganism, setCurrentOrganism] = useState('Homo sapiens')
  const [currentRnaListID, setCurrentRnaListID] = useState('')

  const [geneName, setGeneName] = useState([]);
  const [geneNameInput, setGeneNameInput] = useState('');
  const [geneType, setGeneType] = useState([]);
  const [geneTypeInput, setGeneTypeInput] = useState('');
  const [annotationSource, setAnnotationSource] = useState([]);
  const [annotationSourceInput, setAnnotationSourceInput] = useState('');
  const [currentAllInsert, setCurrentAllInsert] = useState([]);
  const [startAnalysis, setStartAnalysis] = useState(0);
  const [currentApply, setCurrentApply] = useState(0)
  const [applyOrNormalization, setApplyOrNormalization] = useState('')

  const [geneBody, setGeneBody] = useState(false)

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)

  useEffect(() => {
    props.handleHeader(": what RNAs contact with the selected target locus?")
    const params_from_all_target_genes = JSON.parse(localStorage.getItem("from_all_target_genes"))
    
    if (queryParameters.get("organism") !== null && queryParameters.get("locus") !== null) {
      let ID = makeID(15)
      props.selectOneId(0)
      props.clearText()
      setCurrentApply(1)
      props.fetchBasicGeneTableFromDNA({
          'gene_name_for_dna': [''],
          'gene_name_upstream': '',
          'gene_name_downstream': '',
          'rnaListID': ID,
          'LocusCoordinatesForDNA': queryParameters.get("locus"),
          'organism': queryParameters.get("organism"),
          'selectedNormalization': props.trackListCheckbox,
          'page' : 0, 
          'rowsPerPage': 25, 
          'order': 'asc',
          'orderBy': '',
          'from': [], 
          'gene_name': [],
          'gene_type': [], 
          'SearchGeneName': "",
          'LocusCoordinatesForRNAs': "",
          'LengthForRNAs': "",
        })
      setCurrentValueUpstream('')
      setCurrentValueDownstream('')
      setCurrentGeneNameForDNA([])
      setCurrentLocusCoordinatesForDNA(queryParameters.get("locus"))
      setCurrentOrganism(queryParameters.get("organism"))
      props.handleOrganismSelect([{label: queryParameters.get("organism"), value: queryParameters.get("organism")}])
      setCurrentRnaListID(ID)
      setStartAnalysis(1)
      setGeneBody(true)
      setGeneName([])
      setGeneType([])
      setAnnotationSource([])
    } else {
      if (localStorage.getItem("from_all_target_genes") !== null) {
        if (Object.keys(params_from_all_target_genes).length !== 0) {
          let ID = makeID(15)
          props.selectOneId(0)
          props.clearText()
          setCurrentApply(1)
          props.fetchBasicGeneTableFromDNA({
              'gene_name_for_dna': [params_from_all_target_genes.gene_name+ '___true'],
              'gene_name_upstream': '',
              'gene_name_downstream': '',
              'rnaListID': ID,
              'LocusCoordinatesForDNA': '',
              'organism': params_from_all_target_genes.organism,
              'selectedNormalization': props.trackListCheckbox,
              'page' : 0, 
              'rowsPerPage': 25, 
              'order': 'asc',
              'orderBy': '',
              'from': [], 
              'gene_name': [],
              'gene_type': [], 
              'SearchGeneName': "",
              'LocusCoordinatesForRNAs': "",
              'LengthForRNAs': "",
            })
          setCurrentValueUpstream('')
          setCurrentValueDownstream('')
          setCurrentGeneNameForDNA([params_from_all_target_genes.gene_name])
          setCurrentLocusCoordinatesForDNA('')
          setCurrentOrganism(params_from_all_target_genes.organism)
          props.handleOrganismSelect([{label: params_from_all_target_genes.organism, value: params_from_all_target_genes.organism}])
          setCurrentRnaListID(ID)
          setStartAnalysis(1)
          setGeneBody(true)
          setGeneName([])
          setGeneType([])
          setAnnotationSource([])
        }
      }
    }
  }, [])


  useEffect(() => {
    if (currentRnaListID.length > 0) {
      let ID = makeID(15)
      if (Object.keys(props.basicGeneTableDataFromDNA).length !== 0) {
        setCurrentRnaListID(ID)
        setApplyOrNormalization('Normalization')
      }
    }
  }, [props.trackListCheckbox])


  useEffect (() => {
    if (Object.keys(props.basicGeneTableDataFromDNA).length !== 0 && Object.keys(props.basicGeneTableDataFromDNA['description']).length !== 0) {
      setCurrentAllInsert(props.basicGeneTableDataFromDNA['all_insert'].join(','))
      props.SendListGeneName(props.basicGeneTableDataFromDNA['description']['gene_name'])
      props.SendListGeneType(props.basicGeneTableDataFromDNA['description']['gene_type'])
      props.SendListAnnotationSource(props.basicGeneTableDataFromDNA['description']['from'])
    } else {
      if (Object.keys(props.basicGeneTableDataFromDNA).length !== 0) {
        setCurrentAllInsert(props.basicGeneTableDataFromDNA['all_insert'].join(','))
      }
      props.SendListGeneName([])
      props.SendListGeneType([])
      props.SendListAnnotationSource([])
    }

  }, [props.basicGeneTableDataFromDNA])



  const handleGeneName = (gt) => {
    setGeneName(gt);
  };

  const handleGeneNameInput = (value) => {
    setGeneNameInput(value);
  };
  
  const handleGeneType = (gt) => {
    setGeneType(gt);
  };

  const handleGeneTypeInput = (value) => {
    setGeneTypeInput(value);
  };

  const handleAnnotationSource = (gt) => {
    setAnnotationSource(gt);
  };

  const handleAnnotationSourceInput = (value) => {
    setAnnotationSourceInput(value);
  };

  const handleClickOpen = () => {
    setOpenDiolog(true);
  };

  const handleCloseDiolog = value => {
    setOpenDiolog(false);
  };



  const handleClickApplyFromDNA = (e,id) => {   
    // console.log('handleClickApplyFromDNA')
    if (props.locusWarningFromDNA === false && props.lengthWarning === false) { 
      setExtraParams({'from': annotationSource, 
                      'gene_type': geneType, 
                      'gene_name': geneName,
                      'SearchGeneName': props.valueSearchGeneName, 
                      'LocusCoordinatesForRNAs': props.currentLocusFromDNA,
                      'LengthForRNAs': props.currentLength
                    })
    } else {
      setMessage("'Mininum gene length' or 'Locus coordinates' were entered in the wrong format");
      setOpen(true)
    }
  };

  const handleClickTargetApply = () => {
    // console.log('handleClickGetAllRNAsThatHaveTheDesiredTarget')
    let ID = makeID(15)
    if (props.locusWarning === false && props.valueWarningUpstream === false && props.valueWarningDownstream === false && (props.selectedItem.length !== 0 || props.currentLocus.length !== 0)) {
      if (props.currentLocus.split(';').length === 1) {
        props.selectOneId(0)
        props.clearText()
        setCurrentApply(1)
        setApplyOrNormalization('Apply')
        setCurrentValueUpstream(props.valueUpstream)
        setCurrentValueDownstream(props.valueDownstream)
        setCurrentGeneNameForDNA(props.selectedItem.map(n => n['value']))
        setCurrentLocusCoordinatesForDNA(props.currentLocus)
        setCurrentOrganism(props.selectedItemOrganism[0]['value'])
        setCurrentRnaListID(ID)
        setStartAnalysis(1)
  
        setGeneName([])
        setGeneType([])
        setAnnotationSource([])
        handleCloseDiolog()
      } else {
        setMessage(" You can only choose one locus");
        setOpen(true)
      }
    } else {
      if (props.selectedItem.length === 0 && props.currentLocus.length === 0 && props.locusWarning === false) {
        setMessage("You should either enter а genomic locus or select target gene");
        setOpen(true)
      } else {
        if (props.locusWarning === true) {
          setMessage("Locus coordinates were entered in the wrong format");
          setOpen(true)
        } else if (props.valueWarningUpstream === true || props.valueWarningDownstream === true) {
          setMessage("Upstream / downstream was entered in the wrong format");
          setOpen(true)
        } 
      }
    } 
  }

  
  // const handleNewWindowClick = (event, label) => {
  //   if (props.selectedId !== 0) {
  //       if (props.basicGeneTableDataFromDNA['all_insert'].length !== 0) {
  //         const data_2 = {
  //           selectedTracks: props.selectedId,
  //           typeOfContacts: props.typeOfContacts,
  //           selectedTrackNames: currentOrganism + "____" + props.basicGeneTableDataFromDNA['all_insert'],
  //           selectedNormalization: props.trackListCheckbox,
  //           // 'all_insert': props.basicGeneTableDataFromDNA['all_insert'],
  //         }
  //         localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
          
  //         const data = {
  //               'selectedId': props.selectedId,
  //               'typeOfContacts': props.typeOfContacts,
  //               'organism': currentOrganism + "____" + props.basicGeneTableDataFromDNA['all_insert'],
  //               'selectedNormalization': props.trackListCheckbox,
  //             } 
  //         console.log('QWJKJKOFKJ!!!!!!!!!!! DATA', data)
  //         localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
  //         window.open("/basic_graphical_summary", "_blank");
  //       } else {
  //         const data_2 = {
  //           selectedTracks: props.selectedId,
  //           typeOfContacts: props.typeOfContacts,
  //           selectedTrackNames: currentOrganism,//props.selectedItemOrganism[0]['value'], //currentOrganism
  //           selectedNormalization: props.trackListCheckbox,
  //         }
  //         localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
          
  //         const data = {
  //               'selectedId': props.selectedId,
  //               'typeOfContacts': props.typeOfContacts,
  //               'organism': currentOrganism, //props.selectedItemOrganism[0]['value'], //currentOrganism
  //               'selectedNormalization': props.trackListCheckbox,
  //             } 
  //         console.log('QWJKJKOFKJ!!!!!!!!!!! DATA', data)
  //         localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
  //         window.open("/basic_graphical_summary", "_blank");
  //       }
  //   } else {
  //     setMessage('Choose some RNA')
  //     setOpen(true)
  //   }
  // }

  const handleClose = () => {
    setOpen(false)
    setMessage('')
  }

  useEffect(() => {
    // console.log('www2 props.selectedItem',props.selectedItem)
    if (props.selectedItem.length > 0) {
      props.clearTextInputsTwo()
      setGeneBody(true)
    } 
    // else if (props.selectedItem.length === 0) {
    //   const data = JSON.parse(localStorage.getItem("from_all_target_genes"))
    //   console.log('debugger data', data)
    //   debugger
    //   if (Object.keys(data).length !== 0) {
    //     props.clearTextInputsTwo()
    //     setGeneBody(true)
    //   } else {
    //     props.clearTextInputsTwoUpstream()
    //     setGeneBody(false)
    //   }
    // }
  }, [props.selectedItem])
  
  useEffect(() => {
    if (props.valueUpstream.length === 0 && props.valueDownstream.length === 0 && props.selectedItem.length > 0) {
      setGeneBody(true)
    }
  }, [props.valueUpstream, props.valueDownstream])


  useEffect(() => {
    if (props.valueLocus.length > 0) {
      props.clearRNAGeneSelectorFromDNA();
      props.clearTextInputsTwoUpstream()
    }
  }, [props.valueLocus])

  const handleChange = name => event => {
    setGeneBody(!geneBody)
  }


  return (
    <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={Object.keys(props.basicGeneTableDataFromDNA).length === 0 ? 9 : 3} style={{borderRight: Object.keys(props.basicGeneTableDataFromDNA).length === 0 ? '2px solid #000000' : '2px solid #000000'}}>
                    <Paper 
                      elevation={0} 
                      square 
                      style={{
                        borderLeft: Object.keys(props.basicGeneTableDataFromDNA).length === 0 ? '0px solid #d3d4d5' : '1px solid #000000', 
                        borderTop: Object.keys(props.basicGeneTableDataFromDNA).length === 0 ? '0px solid #d3d4d5' : '1px solid #000000', 
                        borderBottom: Object.keys(props.basicGeneTableDataFromDNA).length === 0 ? '0px solid #d3d4d5' : '1px solid #000000'
                      }} 
                    >
                        <a id="from_dna" >
                            <img 
                              // width={Object.keys(props.basicGeneTableDataFromDNA).length === 0 ? 900 : 300} 
                              width='100%' 
                              src={Object.keys(props.basicGeneTableDataFromDNA).length === 0 ? workflow_from_dna : browseAllRNAs2} 
                              alt="from_dna" />
                        </a>
                        </Paper>
                        {Object.keys(props.basicGeneTableDataFromDNA).length !== 0 ?
                        <div>
                          <div>
                            <InputThree 
                              nameSelect='Search by "RNA name"'
                              textChange={props.textChangeSearchGeneName}
                              valueLocus={props.valueSearchGeneName}
                            />
                          </div>
                          <div style={{marginRight: 2}}>
                            <RNAAutocomplete
                              nameClass={classes.autocomplete}
                              disabled={props.loadingBasicGeneTableFromDNA}
                              suggestions={props.BasicTableGeneName}
                              handleChange={handleGeneName}
                              selectedItems={geneName}
                              currentInput={geneNameInput}
                              onInputChange={handleGeneNameInput}
                              title={"Select RNAs"}
                            />
                          </div>
                          <div style={{marginRight: 2}}>
                            <RNAAutocomplete
                              nameClass={classes.autocomplete}
                              disabled={props.loadingBasicGeneTableFromDNA}
                              suggestions={props.RnaListGeneType}
                              handleChange={handleGeneType}
                              selectedItems={geneType}
                              currentInput={geneTypeInput}
                              onInputChange={handleGeneTypeInput}
                              title={"Select RNA types"}
                            />
                          </div>
                          <div style={{marginRight: 2}}>
                            <RNAAutocomplete
                              nameClass={classes.autocomplete}
                              disabled={props.loadingBasicGeneTableFromDNA}
                              suggestions={props.BasicTableAnnotationSource}
                              handleChange={handleAnnotationSource}
                              selectedItems={annotationSource}
                              currentInput={annotationSourceInput}
                              onInputChange={handleAnnotationSourceInput}
                              title={"Select annotation source"}
                            />
                          </div>
                          <div>
                            <Decorator 
                              key = {1111111}
                              text = {<Typography color="inherit">Enter coordinates of <b>genomic loci</b> to get all RNAs from them</Typography>}
                              component = {
                                <div>
                                  <InputTwoFromDNA 
                                    nameSelect='chr4; chr2:10000-23000'
                                    textChange={props.textChangeFromDNA}
                                    valueLocus={props.valueLocusFromDNA}
                                    currentOrganism={currentOrganism}
                                    locusWarningChangeFalse={props.locusWarningChangeFalseFromDNA}
                                    locusWarningChangeTrue={props.locusWarningChangeTrueFromDNA}
                                    currentLocusChange={props.currentLocusChangeFromDNA}
                                  />
                                </div>
                                } // nameLabel='All RNAs from locus' 
                            />
                          </div>
                          <div style={{marginLeft: 8}}>
                            <InputsLenght
                              nameSelect='Minimum gene length'
                              textLengthChange={props.textLengthChange}
                              valueLength={props.valueLength}
                              lengthWarningChangeFalse={props.lengthWarningChangeFalse}
                              lengthWarningChangeTrue={props.lengthWarningChangeTrue}
                              currentLengthChange={props.currentLengthChange}
                            />
                          </div>
                          <div style={{textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                            <Button
                              disabled={props.loadingBasicGeneTableFromDNA}
                              className={classes.button}
                              variant="contained"
                              size="small"
                              color={"secondary"}
                              // style={{backgroundColor: '#96000E', color: 'white'}}
                              onClick={e => handleClickApplyFromDNA(e, 'igfdsfghjk')}
                            >
                              Apply filters
                            </Button>
                          </div>
                        </div>
                        :
                        <div>
                            {null}
                        </div>
                        }
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={Object.keys(props.basicGeneTableDataFromDNA).length === 0 ? 3 : 9}>
                  {/* {currentApply === 1 ?
                  <Grid container spacing={0}>
                    <Grid item xs={7}>
                      <Typography style={{textAlign: "center"}} variant="h5" component="h1">
                          What RNAs contact with this target locus?
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title="Download grouped table">
                        <div style={{textAlign: "left", marginTop: 5}}>
                          <DownloadTrack typeOfContacts={""} whatType={'grouped table'} all_insert={currentAllInsert} expId_and_name={currentOrganism}/>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title="Download ungrouped table">
                        <div style={{textAlign: "left", marginTop: 5}}>
                          <DownloadTrack typeOfContacts={""} whatType={'ungrouped table'} all_insert={currentAllInsert} expId_and_name={currentOrganism}/>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        {null}
                    </Grid>
                  </Grid>
                  :
                  <Typography style={{textAlign: "center"}} variant="h5" component="h1">
                      What RNAs contact with this target locus?
                  </Typography>
                  } */}
                    {currentApply === 1 ?
                          null
                      :
                          <div style={{textAlign: 'center'}}>
                            <Button
                              disabled={props.loadingBasicGeneTableFromDNA}
                              className={classes.button2}
                              variant="contained"
                              size="small"
                              color={"secondary"}
                              // style={{backgroundColor: '#96000E', color: 'white'}} 
                              onClick={handleClickOpen}
                            >
                                Choose a DNA locus
                            </Button>
                            <Typography variant="subtitle1" style={{marginBottom: 5, textAlign: 'left'}}>
                              <ul>
                                  <li>you will get a list of RNAs contacting the DNA locus and their contacts maps</li>
                              </ul>
                            </Typography>
                          </div>
                        }
                  {currentApply === 1 ?
                    <Grid container spacing={0}>
                        <Grid item xs={7}>
                          <TableFromDna 
                              currentGeneNameForDNA={currentGeneNameForDNA}
                              currentValueUpstream={currentValueUpstream}
                              currentValueDownstream={currentValueDownstream}
                              currentLocusCoordinatesForDNA={currentLocusCoordinatesForDNA}
                              currentOrganism={currentOrganism}
                              startAnalysis={startAnalysis}
                              geneBody={geneBody}
                          />
                        </Grid>
                        <Grid item xs={2}>
                        
                            <Button
                              disabled={props.loadingBasicGeneTableFromDNA}
                              className={classes.button2}
                              variant="contained"
                              size="small"
                              color={"secondary"}
                              // style={{backgroundColor: '#96000E', color: 'white'}}
                              onClick={handleClickOpen}
                            >
                                Choose a DNA locus
                            </Button>
                            
                        </Grid>
                        <Grid item style={{marginLeft: 25, marginTop: 10}} xs={2}>
                            <Grid container spacing={0}>
                              <Grid item xs={6}>
                                <Tooltip title="Download grouped table">
                                  <div style={{textAlign: "center"}}>
                                    <DownloadTrack 
                                      typeOfContacts={""} 
                                      whatType={'grouped table'} 
                                      all_insert={currentAllInsert} 
                                      expId_and_name={currentOrganism}
                                      params={{
                                        'from': annotationSource.map(n => n['value']), 
                                        'gene_type': geneType.map(n => n['value']), 
                                        'gene_name': geneName.map(n => n['value']),
                                        'SearchGeneName': props.valueSearchGeneName, 
                                        'LocusCoordinatesForRNAs': props.currentLocusFromDNA.length !== 0 ? LocusFilterParser(props.currentLocusFromDNA) : props.currentLocusFromDNA,
                                        'LengthForRNAs': props.currentLength
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={6}>
                                <Tooltip title="Download ungrouped table">
                                  <div style={{textAlign: "center"}}>
                                    <DownloadTrack 
                                      typeOfContacts={""} 
                                      whatType={'ungrouped table'} 
                                      all_insert={currentAllInsert} 
                                      expId_and_name={currentOrganism}
                                      params={{
                                        'from': annotationSource.map(n => n['value']), 
                                        'gene_type': geneType.map(n => n['value']), 
                                        'gene_name': geneName.map(n => n['value']),
                                        'SearchGeneName': props.valueSearchGeneName, 
                                        'LocusCoordinatesForRNAs': props.currentLocusFromDNA.length !== 0 ? LocusFilterParser(props.currentLocusFromDNA) : props.currentLocusFromDNA,
                                        'LengthForRNAs': props.currentLength
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <TrackListCheckboxesNew disabled={props.loadingBasicGeneTableFromDNA} />
                        </Grid>
                        <Grid item xs={1}>
                            {null}
                        </Grid>
                        
                        {/* <Grid item>
                          <div className={classes.button2} >
                              <TrackListCheckboxes />
                          </div>
                        </Grid> */}
                        
                </Grid>
                :
                null
              }
              {localStorage.getItem("from_all_target_genes") === null ?
              null
              :
                    <div>
                      <BasicTableRNA 
                          extraParams={extraParams} 
                          valueTab={1} 
                          geneBody={currentGeneNameForDNA.length > 0 || Object.keys(JSON.parse(localStorage.getItem("from_all_target_genes"))).length !== 0 ? '___' + geneBody : ''}
                          rows={rows_fromDNA}
                          basicGeneTableData={props.basicGeneTableDataFromDNA}
                          loadingBasicGeneTable={props.loadingBasicGeneTableFromDNA}
                          snackOpenBasicGeneTable={props.snackOpenBasicGeneTableFromDNA}
                          snackTextBasicGeneTable={props.snackTextBasicGeneTableFromDNA}
                          fetchBasicGeneTableFromDNA={props.fetchBasicGeneTableFromDNA}
                          currentGeneNameForDNA={currentGeneNameForDNA}
                          currentValueUpstream={currentValueUpstream}
                          currentValueDownstream={currentValueDownstream}
                          currentLocusCoordinatesForDNA={currentLocusCoordinatesForDNA}
                          currentOrganism={currentOrganism}
                          currentRnaListID={currentRnaListID}
                          applyOrNormalization={applyOrNormalization}
                      />
                    </div>
            }
                    {/* {Object.keys(props.basicGeneTableDataFromDNA).length !== 0 ?
                        <div style={{textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                            <TrackListCheckboxes />
                            <Button
                              disabled={props.loadingBasicGeneTableFromDNA}
                              className={classes.button}
                              variant="contained"
                              size="small"
                              // style={{backgroundColor: '#96000E', color: 'white'}}
                              color={"secondary"}
                              onClick={e => handleNewWindowClick(e, "from DNA")}
                            >
                                Start analysis
                            </Button>
                        </div>
                    :
                    <div>
                        {null}
                    </div>
                    } */}
                </Grid>
            </Grid>
        <Dialog 
            onClose={handleCloseDiolog} 
            open={openDiolog} 
            className={classes.dialog}
            fullWidth={true}
            maxWidth={'sm'} //'sm' || 'md' || 'lg' || 'xl'
        > 
            <DialogTitle id="simple-dialog-title" style={{ padding: 10, textAlign: 'center' }}><Typography variant="h5">Choose a DNA locus or target gene</Typography></DialogTitle>
            {handleCloseDiolog ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDiolog}>
                <CloseIcon />
                </IconButton>
                ) 
            : 
                null
            }
            <div style={{ margin: '8px'}}>
                <OrganismSelectorSimple currentOrganism={currentOrganism} first_working_page={false}/>
            </div>
            <div>
                <Decorator 
                    key = {4000}
                    text = {<Typography color="inherit">Enter coordinates of <b>genomic target</b></Typography>}
                    component = {
                    <div>
                        <InputsTwo 
                            nameSelect='chrX or chr1:10000-23000'
                            textChange={props.textChange}
                            valueLocus={props.valueLocus}
                            locusWarningChangeFalse={props.locusWarningChangeFalse}
                            locusWarningChangeTrue={props.locusWarningChangeTrue}
                            currentLocusChange={props.currentLocusChange}
                            parent={'welcome page, from DNA'}
                        />
                    </div>
                    } // nameLabel='All RNAs from locus' 
                />
            </div>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <GeneSelector 
                  disabled={props.loadingGenes}
                  fetchGenes={props.fetchGenes}
                  handleGeneSelect={props.handleGeneSelect}
                  changeCurrentGeneInput={props.changeCurrentGeneInput}
                  // suggestions={props.suggestions}
                  selectedItem={props.selectedItem}
                  placeholderText={'Select target gene'}
                  currentInput={props.currentInput}
                  parent={'welcome page, from DNA'}
                />
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Decorator 
                    key = {1000}
                    text = {<Typography color="inherit">By selecting this option, you can include or exclude the <b>gene body</b> from further analysis.</Typography>}
                    component = {
                      <div className={classes.locationCheckbox}>
                        <FormControlLabel
                          disabled={(props.selectedItem.length !== 1 || props.valueUpstream.length === 0) && props.valueDownstream.length === 0}
                          checked={geneBody}
                          onChange={handleChange('lalal')}
                          labelPlacement="end"
                          label="Gene body"
                          control={
                            <Checkbox
                              // className={classes.locationCheckbox}
                              // checked={norm_value}
                              // onChange={handleChange('norm_value,expected_value,observed_value')}
                              // value="norm_value"
                              // color="primary"
                            />
                          }
                          // label="Norm value"
                        />
                      </div>
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={6}>  
                    <div>
                        <Decorator 
                            key = {2000}
                            text = {<Typography color="inherit">You can expand your target locus <b>upstream</b></Typography>}
                            component = {
                            <div>
                                <InputsTwoUpstream
                                    nameSelect='+ X bp upstream'
                                    disabled={props.selectedItem.length !== 1}
                                    textChange={props.textChangeUpstream}
                                    valueLocus={props.valueUpstream}
                                    locusWarningChangeFalse={props.textUpstreamWarningChangeFalse}
                                    locusWarningChangeTrue={props.textUpstreamWarningChangeTrue}
                                    // currentLocusChange={props.currentLocusChange}
                                    parent={'welcome page, from DNA'}
                                />
                            </div>
                            }
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <Decorator 
                            key = {3000}
                            text = {<Typography color="inherit">You can expand your target locus <b>downstream</b></Typography>}
                            component = {
                            <div>
                                <InputsTwoDownstream
                                    nameSelect='+ X bp downstream'
                                    disabled={props.selectedItem.length !== 1}
                                    textChange={props.textChangeDownstream}
                                    valueLocus={props.valueDownstream}
                                    locusWarningChangeFalse={props.textDownstreamWarningChangeFalse}
                                    locusWarningChangeTrue={props.textDownstreamWarningChangeTrue}
                                    // currentLocusChange={props.currentLocusChange}
                                    parent={'welcome page, from DNA'}
                                />
                            </div>
                            }
                        />
                    </div>
                </Grid>
            </Grid>
            <div style={{textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                <Button
                    className={classes.button}
                    variant="contained"
                    size="small"
                    color={"primary"}
                    onClick={handleClickTargetApply}
                >
                    Apply
                </Button>
            </div>
        </Dialog>
        <Typography variant={"subtitle1"} style={{padding: 5}}>
            If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
        </Typography>
        <WarningMessage open={open} severity={"warning"} handleClose={handleClose} message={message} />
    </div>
  );
};


const mapStateToProps = (state) => {
  return {

      loadingGenes: state[module].loadingGenes,
      selectedItem: state[module].selectedItem,
      currentInput: state[module].currentGeneInput,

      selectedItemOrganism: state[moduleTable].selectedItemOrganism,

      selectedId: state[moduleBasicAnalysis].selectedId,

      typeOfContacts: state[module].typeOfContacts,

      trackListCheckbox: state[moduleFilter].trackListCheckbox,

      valueLocus: state[moduleText].value,
      valueSearchGeneName: state[moduleText].valueSearchGeneName,

      valueLocusFromDNA: state[moduleText].valueFromDNA,
      locusWarningFromDNA: state[moduleText].locusWarningFromDNA,
      currentLocusFromDNA: state[moduleText].currentLocusFromDNA,

      valueUpstream: state[moduleText].valueUpstream,
      valueWarningUpstream: state[moduleText].valueWarningUpstream,

      valueDownstream: state[moduleText].valueDownstream,
      valueWarningDownstream: state[moduleText].valueWarningDownstream,

      locusWarning: state[moduleText].locusWarning,
      currentLocus: state[moduleText].currentLocus,

      currentLength: state[moduleText].currentLength,
      lengthWarning: state[moduleText].lengthWarning,
      valueLength: state[moduleText].valueLength,

      basicGeneTableData: state[moduleBasicAnalysis].basicGeneTableData,
      loadingBasicGeneTable: state[moduleBasicAnalysis].loadingBasicGeneTable,
      snackOpenBasicGeneTable: state[moduleBasicAnalysis].snackOpenBasicGeneTable,
      snackTextBasicGeneTable: state[moduleBasicAnalysis].snackTextBasicGeneTable,

      basicGeneTableDataFromDNA: state[moduleBasicAnalysis].basicGeneTableDataFromDNA,
      loadingBasicGeneTableFromDNA: state[moduleBasicAnalysis].loadingBasicGeneTableFromDNA,
      snackOpenBasicGeneTableFromDNA: state[moduleBasicAnalysis].snackOpenBasicGeneTableFromDNA,
      snackTextBasicGeneTableFromDNA: state[moduleBasicAnalysis].snackTextBasicGeneTableFromDNA,

      RnaListGeneType: state[moduleTable].RnaListGeneType,
      BasicTableAnnotationSource: state[moduleTable].BasicTableAnnotationSource,
      BasicTableGeneName: state[moduleTable].BasicTableGeneName,
  }
}

export default connect(mapStateToProps, 
  { handleHeader,
    fetchGenes, handleGeneSelect, changeCurrentGeneInput,
    textChange, locusWarningChangeFalse,locusWarningChangeTrue,currentLocusChange,
    textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA,
    textLengthChange, lengthWarningChangeFalse,lengthWarningChangeTrue,currentLengthChange,
    textChangeUpstream, textUpstreamWarningChangeFalse, textUpstreamWarningChangeTrue, clearTextInputsTwoUpstream,
    textChangeDownstream, textDownstreamWarningChangeFalse, textDownstreamWarningChangeTrue,
    textChangeSearchGeneName,
    fetchBasicGeneTableFromDNA,selectOneId,
    SendListGeneType, SendListAnnotationSource, SendListGeneName,
    clearText, clearTextInputsTwo, clearRNAGeneSelectorFromDNA,
    handleOrganismSelect
    // fetchBasicGeneTable,
  })(BasicFromDna);