import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import { Link } from 'react-router-dom';

import HeatmapsDATA from '../components/plots/Heatmap_data_layout';
// import Profile from '../components/plots/RNA_DNA_profile';
import DNAprofile from '../components/plots/DNAprofile';
import DistanceDistribution from '../components/plots/DistanceDistribution';
// import TableRnaListBasic from '../components/TableRnaListBasic';
import DNApartProfileBasic from '../components/plots/DnaFilterDNAprofile';
import DownloadPlotData from '../components/DownloadPlotData';

import {submitCheckedTracksForProfile} from '../actions/plotsActions';
import {submitCheckedTracksForHeatmap,submitCheckedTracksForHeatmapComparative} from '../actions/plotsActions';
import {submitCheckedTracksForDistanceDistribution} from '../actions/plotsActions';
import { submitCheckedTracksForDNApartProfile } from '../actions/plotsActions';
import { handleHeader } from '../actions/generalActions';

import WarningMessage from '../components/MessageSnackbar';
import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';

const module = "genes";
const module_plots = "plots";
const moduleFilter = "filter";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    textAlign: 'center',
    paddingTop: '30px !important',
    marginTop: theme.spacing(3),
    // backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    color: 'black',
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  wideButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '90%',
    marginBottom: 25,
    marginTop: 25,
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const BasicGraphicalSummaryFromRnaList = (props) => {

  // const [value, setValue] = useState(0);
  const [trackNameAndColor, setTrackNameAndColor] = useState({})
  const [dataFromLocalStorage, setDataFromLocalStorage] = useState({})

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data_graphical_summary"));
    setDataFromLocalStorage(data)
    // console.log('BasicGraphicalSummary data',data);
    document.title = `Selective graphical summary`;
    props.handleHeader(': selective graphical summary')
    // console.log('data.parent',data.parent)
    if (data.parent === 'RnaListButtonDasic + DNAfilter') {
      props.submitCheckedTracksForDNApartProfile(
        data.selectedId,
        data.typeOfContacts, 
        data.organism, 
        data.selectedNormalization
      )
    } else {
      props.submitCheckedTracksForDistanceDistribution(data.selectedTracks,data.typeOfContacts, data.selectedTrackNames, data.selectedNormalization)
      props.submitCheckedTracksForProfile(data.selectedTracks,data.typeOfContacts, data.selectedTrackNames, data.selectedNormalization)
      props.submitCheckedTracksForHeatmapComparative(data.selectedTracks,data.typeOfContacts, data.selectedTrackNames, data.selectedNormalization)
    }
  }, [])
  

  useEffect(() => {
    if (Object.keys(props.genomeWideDataProfile).length !== 0 && Object.keys(props.genomeWideDataProfile).indexOf('exps') === -1) {
      let trackNameAndColorDict = {}
      for (var organism in props.genomeWideDataProfile) {
        for (var RNA_track in props.genomeWideDataProfile[organism]) {
          if (RNA_track !== '-111') {
            trackNameAndColorDict[props.genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0]] = props.genomeWideDataProfile[organism][RNA_track]['color']
          }
        }
      }
      setTrackNameAndColor(trackNameAndColorDict)
    }
  }, [props.genomeWideDataProfile])

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data_graphical_summary")).parent === "RnaListButtonDasic + DNAfilter") {
      props.submitCheckedTracksForDNApartProfile(
        dataFromLocalStorage.selectedId,
        dataFromLocalStorage.typeOfContacts, 
        dataFromLocalStorage.organism, 
        props.trackListCheckbox
      )
    } else {
      // console.log('parent!!!!!!!!!!!!!!!!!!!')
      props.submitCheckedTracksForProfile(dataFromLocalStorage.selectedTracks, dataFromLocalStorage.typeOfContacts, dataFromLocalStorage.selectedTrackNames, props.trackListCheckbox)
      props.submitCheckedTracksForDistanceDistribution(dataFromLocalStorage.selectedTracks, dataFromLocalStorage.typeOfContacts, dataFromLocalStorage.selectedTrackNames, props.trackListCheckbox)
      props.submitCheckedTracksForHeatmapComparative(dataFromLocalStorage.selectedTracks, dataFromLocalStorage.typeOfContacts, dataFromLocalStorage.selectedTrackNames, props.trackListCheckbox)
    }
  },[props.trackListCheckbox])

  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {dataFromLocalStorage.parent !== 'RnaListButtonDasic + DNAfilter' ?
      <div>
        <div style={{marginBottom: 10}}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography style={{textAlign: "right"}} variant="h4">
                    <b>Contacts Distribution</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {props.loading 
                    || Object.keys(props.genomeWideDataProfile).length === 0 
                    ?
                      <Tooltip title="Download plot data">
                        <div style={{textAlign: "center", marginTop: 10}}>
                          <IconButton style={{padding: 0}} disabled={true}>
                            <CloudDownloadIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    :
                      Object.keys(props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]]).length === 1
                      ?
                        <Tooltip title="Download plot data">
                          <div style={{textAlign: "center", marginTop: 10}}>
                            <IconButton style={{padding: 0}} disabled={true}>
                              <CloudDownloadIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      :
                        <Tooltip title="Download plot data">
                          <div style={{textAlign: "center",marginTop: 10}}>
                            <DownloadPlotData data={props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]]}
                                              plot={'ContactsDistributionSelective'}
                              />
                          </div>
                        </Tooltip> 
                  }
                </Grid>
                <Grid item xs={5}>
                  <div style={{textAlign: "left", marginLeft: 5}}> 
                    <TrackListCheckboxesNew disabled={props.loading || props.loadingHeatmapComparative || props.loadingDistanceDistribution}/>
                  </div>
                </Grid>
              </Grid>
          {props.loading || Object.keys(props.genomeWideDataProfile).length === 0 ?
            <div className={classes.loading}>
              <LinearProgress color={"primary"} />
              <Typography variant="h5">
                  Please, wait a while, it may take from a few seconds to a couple of minutes
              </Typography>
            </div>
            :
              Object.keys(props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]]).length === 1
              ?
                <Typography color="error" variant="h5">
                  Contacts with the selected normalization were not found
                </Typography>
              :
                <div>
                  <DNAprofile from={'basic'} trackNameAndColor={trackNameAndColor}/>
                  <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                    <ul>
                      <li>This bar chart displays genome-wide contacts density distribution (genome bin size = 5000000 bp).</li>
                      <li><b>Y-axis</b>: contacts density.</li>
                      <li><b>X-axis</b>: the chromosomes with which <b>{props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]][Object.keys(props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]])[0]]['name'].split("(")[1].split(")")[0]}</b> RNA interact.</li>
                      <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                    </ul>
                  </Typography>
                </div>
          }
          <WarningMessage open={props.snackOpenGenomeWide} severity={"error"} message={props.snackTextGenomeWide} />
          <Divider className={classes.divider} />
        </div>
        <div style={{marginBottom: 10}}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography style={{textAlign: "right"}} variant="h4">
                <b>Comparative Heatmap</b>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {props.loadingHeatmapComparative 
                || Object.keys(props.genomeWideDataComparative).length === 0 
                ?
                  <Tooltip title="Download plot data">
                    <div style={{textAlign: "center", marginTop: 10}}>
                      <IconButton style={{padding: 0}} disabled={true}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                :
                  props.genomeWideDataComparative[Object.keys(props.genomeWideDataComparative)[0]]['y'].length === 0 
                  ?
                    <Tooltip title="Download plot data">
                      <div style={{textAlign: "center", marginTop: 10}}>
                        <IconButton style={{padding: 0}} disabled={true}>
                          <CloudDownloadIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  :
                    <Tooltip title="Download plot data">
                      <div style={{textAlign: "center",marginTop: 10}}>
                        <DownloadPlotData data={[
                                                  props.genomeWideDataComparative[Object.keys(props.genomeWideDataComparative)[0]]['x'],
                                                  props.genomeWideDataComparative[Object.keys(props.genomeWideDataComparative)[0]].count[1].map(value => {return "Exp." + value.split('; cell line:')[0]+ "; " + value.split('exp.protocol: ')[1].split('; extra:')[0] + " (" + props.genomeWideDataComparative[Object.keys(props.genomeWideDataComparative)[0]]['y'][0].split("(")[1].split(")")[0] + ")"}),
                                                  props.genomeWideDataComparative[Object.keys(props.genomeWideDataComparative)[0]]['z']
                                                ]}
                                          plot={'ComparativeHeatmapSelective'}
                          />
                      </div>
                    </Tooltip> 
              }
            </Grid>
            <Grid item xs={5}>
              <div style={{textAlign: "left", marginLeft: 5}}> 
                <TrackListCheckboxesNew disabled={props.loading || props.loadingHeatmapComparative || props.loadingDistanceDistribution}/>
              </div>
            </Grid>
          </Grid>
          {props.loadingHeatmapComparative || Object.keys(props.genomeWideDataComparative).length === 0 ?
            <div className={classes.loading}>
              <LinearProgress color={"primary"} />
              <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
              </Typography>
            </div>
            :
              props.genomeWideDataComparative[Object.keys(props.genomeWideDataComparative)[0]]['y'].length === 0 
              ?
                <Typography color="error" variant="h5">
                  Contacts with the selected normalization were not found
                </Typography>
              :
                <div>
                  <HeatmapsDATA plotType={'Comparative'} plotType_2={'Basic'} genomeWideData={props.genomeWideDataComparative} trackNameAndColor={trackNameAndColor}/>
                  <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                    <ul>
                      <li>This heatmap displays contacts density distribution over chromosomes.</li>
                      <li><b>Y-axis</b>: track names.</li>
                      <li><b>X-axis</b>: the chromosomes with which <b>{props.genomeWideDataComparative[Object.keys(props.genomeWideDataComparative)[0]]['y'][0].split("(")[1].split(")")[0]}</b> RNA interact.</li>
                      <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                    </ul>
                  </Typography>
                </div>
          }
          <WarningMessage open={props.snackOpenComparative} severity={"error"} message={props.snackTextComparative} />
          <Divider className={classes.divider} />
        </div> 
        <div style={{marginBottom: 10}}>
          <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography style={{textAlign: "right"}} variant="h4">
                  <b>Distance Distribution</b>
                </Typography>
              </Grid>
              <Grid item xs={1}>
              {props.loadingDistanceDistribution 
                || Object.keys(props.genomeWideDataDistanceDistribution).length === 0 
                ?
                  <Tooltip title="Download gene-centric line chart data">
                    <div style={{textAlign: "center", marginTop: 10}}>
                      <IconButton style={{padding: 0}} disabled={true}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                :
                  [].concat.apply([], Object.keys(props.genomeWideDataDistanceDistribution['exps']).map(value => {return props.genomeWideDataDistanceDistribution['exps'][value]['y']})).length === 0
                  ?
                    <Tooltip title="Download gene-centric line chart data">
                      <div style={{textAlign: "center", marginTop: 10}}>
                        <IconButton style={{padding: 0}} disabled={true}>
                          <CloudDownloadIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  :
                    <Tooltip title="Download gene-centric line chart data">
                      <div style={{textAlign: "center",marginTop: 10}}>
                        <DownloadPlotData data={props.genomeWideDataDistanceDistribution['exps']}
                                          plot={'Gene-centric DistanceDistribution Selective'}
                          />
                      </div>
                    </Tooltip> 
              }
            </Grid>
            <Grid item xs={1}>
              {props.loadingDistanceDistribution 
                || Object.keys(props.genomeWideDataDistanceDistribution).length === 0 
                ?
                  <Tooltip title="Download non gene-centric line chart data">
                    <div style={{textAlign: "center", marginTop: 10}}>
                      <IconButton style={{padding: 0}} disabled={true}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                :
                  [].concat.apply([], Object.keys(props.genomeWideDataDistanceDistribution['exps']).map(value => {return props.genomeWideDataDistanceDistribution['exps'][value]['y']})).length === 0
                  ?
                    <Tooltip title="Download non gene-centric line chart data">
                      <div style={{textAlign: "center", marginTop: 10}}>
                        <IconButton style={{padding: 0}} disabled={true}>
                          <CloudDownloadIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  :
                    <Tooltip title="Download non gene-centric line chart data">
                      <div style={{textAlign: "center",marginTop: 10}}>
                        <DownloadPlotData data={props.genomeWideDataDistanceDistribution['exps']}
                                          plot={'non Gene-centric DistanceDistribution Selective'}
                          />
                      </div>
                    </Tooltip> 
              }
            </Grid>
            <Grid item xs={5}>
              <div style={{textAlign: "left", marginLeft: 5}}> 
                <TrackListCheckboxesNew disabled={props.loading || props.loadingHeatmapComparative || props.loadingDistanceDistribution}/>
              </div>
            </Grid>
          </Grid>
          {props.loadingDistanceDistribution || Object.keys(props.genomeWideDataDistanceDistribution).length === 0 ?
            <div className={classes.loading}>
              <LinearProgress color={"primary"} />
              <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
              </Typography>
            </div>
            :
                <div>
                  <DistanceDistribution from={'basic'} trackNameAndColor={trackNameAndColor}/>
                  <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                    <ul>
                      <li>This line chart displays the contact density depending on the distance between the RNA gene and chromatin target loci (on the same chromosome as the corresponding RNA gene). The interaction of RNA with its source gene is not included in the analysis.</li>
                      {/* <li><b>Y-axis 1</b>: Log2(Count RNAs) - the number of unique RNAs that contact with chromatin at an appropriate distance from their RNA-part of the contact.</li> */}
                      <li><b>Y-axis</b>: Log2(Density).</li>
                      <li><b>X-axis</b>: Log2(Distance between <b>{props.genomeWideDataDistanceDistribution['exps'][Object.keys(props.genomeWideDataDistanceDistribution['exps'])[0]]['count'][0][0].split("(")[1].split(")")[0]}</b> gene and loci).</li>
                      <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                    </ul>
                  </Typography>
                </div>
              
            }
            <WarningMessage open={props.snackOpenDistanceDistribution} severity={"error"} message={props.snackTextDistanceDistribution} />
          </div>
        </div>
      :
      <div>
        {props.loadingDNApartData === true || Object.keys(props.geneDNAdataProfile).length === 0 ? 
                    <div className={classes.loading}>
                      <LinearProgress color={"primary"} />
                      <Typography variant="h5">
                        Please, wait a while, it may take from a few seconds to a couple of minutes
                      </Typography>
                    </div>
                  :
                    <div >
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <Typography style={{textAlign: "right"}} variant="h4">
                            <b>Contacts Distribution</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {Object.keys(props.geneDNAdataProfile).indexOf('exps') !== -1  
                            ?
                              <Tooltip title="Download plot data">
                                <div style={{textAlign: "center", marginTop: 10}}>
                                  <IconButton style={{padding: 0}} disabled={true}>
                                    <CloudDownloadIcon />
                                  </IconButton>
                                </div>
                              </Tooltip>
                            :
                              <Tooltip title="Download plot data">
                                <div style={{textAlign: "center",marginTop: 10}}>
                                  <DownloadPlotData data={props.geneDNAdataProfile}
                                                    plot={'ContactsDistributionDnaFilterSelective'}
                                    />
                                </div>
                              </Tooltip> 
                          }
                        </Grid>
                        <Grid item xs={5}>
                          <div style={{textAlign: "left", marginLeft: 5}}> 
                            <TrackListCheckboxesNew disabled={props.loadingDNApartData}/>
                          </div>
                        </Grid>
                      </Grid>
                      {props.loadingDNApartData !== true 
                        && Object.keys(props.geneDNAdataProfile).length !== 0 
                        && Object.keys(props.geneDNAdataProfile).indexOf('exps') === -1  
                        ?
                          <div>
                            <DNApartProfileBasic 
                                // gene_name={'dataForRnaPartsDistribution.gene_name'}
                                // experiment_id={'dataForRnaPartsDistribution.experiment_id'}
                                organism={dataFromLocalStorage.organism}
                                geneDNAdataProfile={props.geneDNAdataProfile} 
                                trackListCheckbox={props.trackListCheckbox}
                                // dnaFilter={dataForRnaPartsDistribution.dnaFilter}
                            />
                            <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                              <ul>
                                <li>This bar chart displays contacts density distribution on target locus (bin size = {props.geneDNAdataProfile[Object.keys(props.geneDNAdataProfile)[0]]['result_filter_contacts']}).</li>
                                <li><b>Y-axis</b>: contacts density.</li>
                                <li><b>X-axis</b>: genomic locus with which <b>{props.geneDNAdataProfile[Object.keys(props.geneDNAdataProfile)[0]]['result']['RNAname']}</b> RNA interact.</li>
                                <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                              </ul>
                            </Typography>
                          </div>
                        :
                          <Typography color="error" variant="h5">
                            Contacts with the selected normalization were not found
                          </Typography>
                      }
                    </div>
        }
      </div>
      }
        <WarningMessage open={props.snackOpenDNApart} severity={"error"} message={props.snackTextDNApart} />
      <Divider className={classes.divider} />
      <Typography variant={"subtitle1"} style={{marginLeft: 10,  padding: 5}}>
        If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
      </Typography>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      geneDNAdataProfile: state[module_plots].geneDNAdataProfile,
      loadingDNApartData: state[module_plots].loadingDNApartData,
      snackOpenDNApart: state[module_plots].snackOpenDNApart,
      snackTextDNApart: state[module_plots].snackTextDNApart,
      //trackList: state[module].trackList,
      selectedTracks: state[module].selectedTracks,
      typeOfContacts: state[module].typeOfContacts,
      
      snackOpenGenomeWide: state[module].snackOpenGenomeWide,
      snackTextGenomeWide: state[module].snackTextGenomeWide,
      loading: state[module_plots].loadingGenomeWideData,

      genomeWideData: state[module_plots].genomeWideData,
      snackOpen: state[module_plots].snackOpen,
      snackText: state[module_plots].snackText,
      loadingHeatmap: state[module_plots].loadingGenomeWideHeatmap,

      genomeWideDataProfile: state[module_plots].genomeWideDataProfile,

      genomeWideDataComparative: state[module_plots].genomeWideDataComparative,
      snackOpenComparative: state[module_plots].snackOpenComparative,
      snackTextComparative: state[module_plots].snackTextComparative,
      loadingHeatmapComparative: state[module_plots].loadingGenomeWideHeatmapComparative,

      genomeWideDataDistanceDistribution: state[module_plots].genomeWideDataDistanceDistribution,
      // snackOpenDistanceDistribution: state[module].snackOpenDistanceDistribution,
      // snackTextDistanceDistribution: state[module].snackTextDistanceDistribution,
      loadingDistanceDistribution: state[module_plots].loadingDistanceDistribution,

      trackListCheckbox: state[moduleFilter].trackListCheckbox,
  }
}

// const GraphicalSummaryContainer = connect(mapStateToProps, {submitCheckedTracksForHeatmap,submitCheckedTracksForProfile,submitCheckedTracksForDistanceDistribution})(GraphicalSummary);
// export default GraphicalSummaryContainer;
export default connect(mapStateToProps, {submitCheckedTracksForHeatmap,submitCheckedTracksForHeatmapComparative,
  submitCheckedTracksForProfile,submitCheckedTracksForDistanceDistribution, handleHeader, submitCheckedTracksForDNApartProfile})(BasicGraphicalSummaryFromRnaList);