import * as ActionTypes from '../actions/filterActions';

const initialState = {
    MaxNumberTrackName: 0,
    numberTrackNameArray: [],

    organism_chrs: {
        'Mus musculus': ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','x','y'],
        'Homo sapiens': ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','x','y'],
        'Drosophila melanogaster': [],},
    add_to_Track_list: '',
    locusValueFilterDna: '',
    locusWarningFilterDna: false,
    currentLocusFilterDna: "",

    selectedItemForGeneBodyFilter: [],
    currentInputForGeneBodyFilter: "",
    selectedItemGeneTypesForGeneBodyFilter: [],
    currentInputGeneTypesForGeneBodyFilter: "",

    selectedItemForUpstreamFilter: [],
    currentInputForUpstreamFilter: "",
    selectedItemGeneTypesForUpstreamFilter: [],
    currentInputGeneTypesForUpstreamFilter: "",

    selectedItemForDownstreamFilter: [],
    currentInputForDownstreamFilter: "",
    selectedItemGeneTypesForDownstreamFilter: [],
    currentInputGeneTypesForDownstreamFilter: "",

    selectedItemForUpstream50kbFilter: [],
    currentInputForUpstream50kbFilter: "",
    selectedItemGeneTypesForUpstream50kbFilter: [],
    currentInputGeneTypesForUpstream50kbFilter: "",

    selectedItemForDownstream50kbFilter: [],
    currentInputForDownstream50kbFilter: "",
    selectedItemGeneTypesForDownstream50kbFilter: [],
    currentInputGeneTypesForDownstream50kbFilter: "",

    contactsCheckbox: {
        GeneBody: false,
        Upstream: false,
        Downstream: false,
        Upstream50kb: false,
        Downstream50kb: false,
        GeneDeserts: false,
    },
    rnaCheckbox: {
        Active: false,
        Middle: false,
        Low: false,
        // GeneBody: false,
        // Upstream: false,
        // Downstream: false,
    },
    trackListCheckboxState: {
        peacks_log_pvalue: false,
        peacks_enrichment: false,
        observed_value: false,
        expected_value: false,
        norm_value: true,
    },
    trackListCheckbox: 'norm_value',
}

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CHANGE_INCREASE_MAX_NUMBER_TRACK_NAME:
            // console.log('CHANGE_INCREASE_MAX_NUMBER_TRACK_NAME', [...state.numberTrackNameArray, action.value])
            return {
                ...state,
                MaxNumberTrackName: Math.max(...[...state.numberTrackNameArray, action.value]),
                numberTrackNameArray: [...state.numberTrackNameArray, action.value],
            };
        // case ActionTypes.CHANGE_DECREASE_MAX_NUMBER_TRACK_NAME:
        //     let newMaxNumberTrackName = state.MaxNumberTrackName;
        //     console.log('state.MaxNumberTrackName',state.MaxNumberTrackName)
        //     console.log('action.value',action.value)
        //     if (action.value === newMaxNumberTrackName) {
        //         newMaxNumberTrackName = newMaxNumberTrackName - 1
        //     }
        //     return {
        //         ...state,
        //         MaxNumberTrackName: newMaxNumberTrackName,
        //     };
        case ActionTypes.CHANGE_DECREASE_MAX_NUMBER_TRACK_NAME:
            let track_name_munbers_list = state.numberTrackNameArray.slice();
            // console.log('state.numberTrackNameArray',state.numberTrackNameArray)
            for (let i = 0; i < action.selected_list.length; i++) {
                // console.log('action.selected_list[i]',action.selected_list[i])
                if (action.selected_list[i].split(' ')[0] === 'Track' && track_name_munbers_list.indexOf(parseInt(action.selected_list[i].split(' ')[1], 10)) !== -1) {
                    // props.changeDecreaseMaxNumberTrackName(parseInt(props.selectedTracks[i].split('^')[0].split(' ')[1], 10))
                    track_name_munbers_list.splice(track_name_munbers_list.indexOf(parseInt(action.selected_list[i].split(' ')[1], 10)), 1)
                }
            }
            let max_num = 0
            // console.log('track_name_munbers_list',track_name_munbers_list)
            if (track_name_munbers_list.length !== 0) {
                max_num = Math.max(...track_name_munbers_list)
            }
            // console.log('Math.max(...track_name_munbers_list)',Math.max(...track_name_munbers_list))
            return {
                ...state,
                MaxNumberTrackName: max_num,
                numberTrackNameArray: track_name_munbers_list,
            };
        case ActionTypes.HANDLE_CURRENT_ADD_TO_TRACK_LIST:
            return {
                ...state,
                add_to_Track_list: action.value,
            };
        /* AUTOSUGGEST GENES FOR GENE BODY FILTER*/
        case ActionTypes.HANDLE_GENE_SELECT_GENE_BODY_FILTER:
            return {
                ...state,
                selectedItemForGeneBodyFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_INPUT_GENE_BODY_FILTER:
            return {
                ...state,
                currentInputForGeneBodyFilter: action.value,
            };
        /* AUTOSUGGEST GENE TYPES FOR GENE BODY FILTER*/
        case ActionTypes.HANDLE_GENE_TYPES_SELECT_GENE_BODY_FILTER:
            return {
                ...state,
                selectedItemGeneTypesForGeneBodyFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_TYPES_INPUT_GENE_BODY_FILTER:
            return {
                ...state,
                currentInputGeneTypesForGeneBodyFilter: action.value,
            };
        // /* AUTOSUGGEST GENES FOR UPSTREAM FILTER*/
        // case ActionTypes.HANDLE_GENE_SELECT_UPSTREAM_FILTER:
        //     return {
        //         ...state,
        //         selectedItemForUpstreamFilter: action.geneName,
        //     };
        // case ActionTypes.HANDLE_CURRENT_GENE_INPUT_UPSTREAM_FILTER:
        //     return {
        //         ...state,
        //         currentInputForUpstreamFilter: action.value,
        //     };
        // /* AUTOSUGGEST GENE TYPES FOR UPSTREAM FILTER*/
        // case ActionTypes.HANDLE_GENE_TYPES_SELECT_UPSTREAM_FILTER:
        //     return {
        //         ...state,
        //         selectedItemGeneTypesForUpstreamFilter: action.geneName,
        //     };
        // case ActionTypes.HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM_FILTER:
        //     return {
        //         ...state,
        //         currentInputGeneTypesForUpstreamFilter: action.value,
        //     };
        // /* AUTOSUGGEST GENES FOR DOWNSTREAM FILTER*/
        // case ActionTypes.HANDLE_GENE_SELECT_DOWNSTREAM_FILTER:
        //     return {
        //         ...state,
        //         selectedItemForDownstreamFilter: action.geneName,
        //     };
        // case ActionTypes.HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM_FILTER:
        //     return {
        //         ...state,
        //         currentInputForDownstreamFilter: action.value,
        //     };
        /* AUTOSUGGEST GENE TYPES FOR DOWNSTREAM FILTER*/
        // case ActionTypes.HANDLE_GENE_TYPES_SELECT_DOWNSTREAM_FILTER:
        //     return {
        //         ...state,
        //         selectedItemGeneTypesForDownstreamFilter: action.geneName,
        //     };
        // case ActionTypes.HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM_FILTER:
        //     return {
        //         ...state,
        //         currentInputGeneTypesForDownstreamFilter: action.value,
        //     };
        /* AUTOSUGGEST GENES FOR UPSTREAM FILTER*/
        case ActionTypes.HANDLE_GENE_SELECT_UPSTREAM_FILTER:
            return {
                ...state,
                selectedItemForUpstreamFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_INPUT_UPSTREAM_FILTER:
            return {
                ...state,
                currentInputForUpstreamFilter: action.value,
            };
        /* AUTOSUGGEST GENE TYPES FOR UPSTREAM FILTER*/
        case ActionTypes.HANDLE_GENE_TYPES_SELECT_UPSTREAM_FILTER:
            return {
                ...state,
                selectedItemGeneTypesForUpstreamFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM_FILTER:
            return {
                ...state,
                currentInputGeneTypesForUpstreamFilter: action.value,
            };
        /* AUTOSUGGEST GENES FOR DOWNSTREAM FILTER*/
        case ActionTypes.HANDLE_GENE_SELECT_DOWNSTREAM_FILTER:
            return {
                ...state,
                selectedItemForDownstreamFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM_FILTER:
            return {
                ...state,
                currentInputForDownstreamFilter: action.value,
            };
        /* AUTOSUGGEST GENE TYPES FOR DOWNSTREAM FILTER*/
        case ActionTypes.HANDLE_GENE_TYPES_SELECT_DOWNSTREAM_FILTER:
            return {
                ...state,
                selectedItemGeneTypesForDownstreamFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM_FILTER:
            return {
                ...state,
                currentInputGeneTypesForDownstreamFilter: action.value,
            };
        /* AUTOSUGGEST GENES FOR UPSTREAM50kb FILTER*/
        case ActionTypes.HANDLE_GENE_SELECT_UPSTREAM50KB_FILTER:
            return {
                ...state,
                selectedItemForUpstream50kbFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_INPUT_UPSTREAM50KB_FILTER:
            return {
                ...state,
                currentInputForUpstream50kbFilter: action.value,
            };
        /* AUTOSUGGEST GENE TYPES FOR UPSTREAM FILTER*/
        case ActionTypes.HANDLE_GENE_TYPES_SELECT_UPSTREAM50KB_FILTER:
            return {
                ...state,
                selectedItemGeneTypesForUpstream50kbFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_TYPES_INPUT_UPSTREAM50KB_FILTER:
            return {
                ...state,
                currentInputGeneTypesForUpstream50kbFilter: action.value,
            };
        /* AUTOSUGGEST GENES FOR DOWNSTREAM FILTER*/
        case ActionTypes.HANDLE_GENE_SELECT_DOWNSTREAM50KB_FILTER:
            return {
                ...state,
                selectedItemForDownstream50kbFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_INPUT_DOWNSTREAM50KB_FILTER:
            return {
                ...state,
                currentInputForDownstream50kbFilter: action.value,
            };
        /* AUTOSUGGEST GENE TYPES FOR DOWNSTREAM50kb FILTER*/
        case ActionTypes.HANDLE_GENE_TYPES_SELECT_DOWNSTREAM50KB_FILTER:
            return {
                ...state,
                selectedItemGeneTypesForDownstream50kbFilter: action.geneName,
            };
        case ActionTypes.HANDLE_CURRENT_GENE_TYPES_INPUT_DOWNSTREAM50KB_FILTER:
            return {
                ...state,
                currentInputGeneTypesForDownstream50kbFilter: action.value,
            };
        /* LOCUS TEXT FOR GENE BODY FILTER*/
        case ActionTypes.TEXT_FILTER_DNA_CHANGE: 
            return {
                ...state,
                locusValueFilterDna: action.newValue,
            };
        case ActionTypes.LOCUS_WARNING_GENE_BODY_FILTER_FALSE: 
            return {
                ...state,
                locusWarningFilterDna: false,
            };
        case ActionTypes.CURRENT_LOCUS_FILTER_DNA_CHANGE: 
            return {
                ...state,
                currentLocusFilterDna: action.newLocus,
            };
        case ActionTypes.LOCUS_WARNING_GENE_BODY_FILTER_TRUE: 
            return {
                ...state,
                locusWarningFilterDna: true,
            };
        case ActionTypes.CLEAR_CONTACTS: 
            let bxs = {
                GeneBody: false,
                Upstream: false,
                Downstream: false,
                Upstream50kb: false,
                Downstream50kb: false,
                GeneDeserts: false,
            }
            return {
                ...state,
                contactsCheckbox: bxs,
                locusValueFilterDna: '',
                currentLocusFilterDna: "",
                selectedItemForGeneBodyFilter: [],
                currentInputForGeneBodyFilter: "",
                selectedItemGeneTypesForGeneBodyFilter: [],
                currentInputGeneTypesForGeneBodyFilter: "",
            
                selectedItemForUpstreamFilter: [],
                currentInputForUpstreamFilter: "",
                selectedItemGeneTypesForUpstreamFilter: [],
                currentInputGeneTypesForUpstreamFilter: "",
            
                selectedItemForDownstreamFilter: [],
                currentInputForDownstreamFilter: "",
                selectedItemGeneTypesForDownstreamFilter: [],
                currentInputGeneTypesForDownstreamFilter: "",
            
                selectedItemForUpstream50kbFilter: [],
                currentInputForUpstream50kbFilter: "",
                selectedItemGeneTypesForUpstream50kbFilter: [],
                currentInputGeneTypesForUpstream50kbFilter: "",
            
                selectedItemForDownstream50kbFilter: [],
                currentInputForDownstream50kbFilter: "",
                selectedItemGeneTypesForDownstream50kbFilter: [],
                currentInputGeneTypesForDownstream50kbFilter: "",
            }
        case ActionTypes.CLEAR_RNA_CHECKBOXES:
            let rnaBxs = {
                Active: false,
                Middle: false,
                Low: false,
                // GeneBody: false,
                // Upstream: false,
                // Downstream: false,
            };
            return {
                ...state,
                rnaCheckbox: rnaBxs,
            };
        case ActionTypes.SET_CONTACTS_CHECKBOX:
            let boxes = Object.assign({}, state.contactsCheckbox);
            boxes[action.name] = action.checked;
            return {
                ...state,
                contactsCheckbox: boxes,
            };
        case ActionTypes.SET_RNA_CHECKBOX:
            let rnaBoxes = Object.assign({}, state.rnaCheckbox);
            rnaBoxes[action.name] = action.checked;
            return {
                ...state,
                rnaCheckbox: rnaBoxes,
            };
        case ActionTypes.SET_TRACK_LIST_CHECKBOX_STATE:
            // let normalizationBoxes = Object.assign({}, state.trackListCheckboxState);
            let normalizationBoxes = {
                peacks_log_pvalue: false,
                peacks_enrichment: false,
                observed_value: false,
                expected_value: false,
                norm_value: false,
            }
            // console.log('normalizationBoxes 1',normalizationBoxes)
            normalizationBoxes[action.name] = action.checked;
            // console.log('normalizationBoxes 2',normalizationBoxes)
            return {
                ...state,
                trackListCheckboxState: normalizationBoxes,
            };
        case ActionTypes.SET_TRACK_LIST_CHECKBOX:
            // console.log('trackListCheckbox before',state.trackListCheckbox)
            return {
                ...state,
                trackListCheckbox: action.name
            };
        default:
            return state;
    }
};
    
export default filterReducer;