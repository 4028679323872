import React, { useState, useEffect } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Paper from '@material-ui/core/Paper';
// import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// const descriptionModification = (description) => {
//   let intersection = ''
  
//   let description_track = description
//   let description_track_name = description_track.split("Intersection")[0]
//   if (description_track.split("Intersection").length > 1) {
//       intersection = description_track.split("Intersection")[1]
//   }
//   let RNA_filter = description_track_name.split("RNA filter: ")[1].split("DNA filter: ")[0]
//   let DNA_filter = description_track_name.split("DNA filter: ")[1]
//   let DNA_filter_inser = []
//   if (DNA_filter.indexOf('DNA locus:') !== -1) {
//       DNA_filter_inser.push(<u>DNA locus</u>)
//       DNA_filter_inser.push(DNA_filter.split('DNA locus')[1].split('$')[0] + ' ')
//   }
//   if (DNA_filter_inser.length === 0) {
//       DNA_filter_inser.push('NaN')
//   }
//   return [RNA_filter, DNA_filter_inser, intersection]
// }

const desc = (a, b, orderBy) => {
  // debugger
  if (b[orderBy].length > 0 && a[orderBy].length > 0) {
    if (orderBy === 'track') {
      if (parseInt(b[orderBy][0].split(' (')[0].split('ID: ')[1],10) < parseInt(a[orderBy][0].split(' (')[0].split('ID: ')[1],10)) {
        return -1;
      }
      if (parseInt(b[orderBy][0].split(' (')[0].split('ID: ')[1],10) > parseInt(a[orderBy][0].split(' (')[0].split('ID: ')[1],10)) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy][0] < a[orderBy][0]) {
        return -1;
      }
      if (b[orderBy][0] > a[orderBy][0]) {
        return 1;
      }
      return 0;
    }
  } 
  // else {
  //   if (b[orderBy] < a[orderBy]) {
  //     return -1;
  //   }
  //   if (b[orderBy] > a[orderBy]) {
  //     return 1;
  //   }
  //   return 0;
  // }
}
  
const stableSort = (array, cmp) =>{
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  

const EnhancedTableHead = (props) => {
  
    const createSortHandler = property => event => {
      props.onRequestSort(event, property);
    };
  
    const { order, orderBy, data} = props;
    const classes = useStyles();

    const [rows, setRows] = useState([])

    useEffect(() => {
        
        if (data.length !== 0) {
            // console.log('Data',data)    
            const keysData = Object.keys(data[0])
            // console.log('keysData',keysData)
            setRows([])
            for (var numberOfrows = 0; numberOfrows < keysData.length; numberOfrows ++) {
              let key_id = keysData[numberOfrows]
              if (key_id !== 'color') {
                setRows(rows => [...rows, { id: key_id, numeric: false, disablePadding: false, label: data[0][key_id][1]}])
              }
                // if (keysData[numberOfrows] === 'track') {
                //     setRows(rows => [...rows, { id: 'track', numeric: false, disablePadding: false, label: 'Track (RNAs)' }])
                // } else if (keysData[numberOfrows] === 'trackDescription') {
                //     setRows(rows => [...rows, { id: 'trackDescription', numeric: false, disablePadding: false, label: 'Track description' }])
                // } else if (keysData[numberOfrows] === 'datasetDescription') {
                //     setRows(rows => [...rows, { id: 'datasetDescription', numeric: false, disablePadding: false, label: 'Dataset description' }])
                // } else if (keysData[numberOfrows] === 'totalContactsCount') {
                //     setRows(rows => [...rows, { id: 'totalContactsCount', numeric: false, disablePadding: false, label: 'Total contacts count' }]) //Normalized number of contacts
                // } 
            }
        }
    }, [data])  
    // console.log('rows****************************************************',rows) 
    let column = null
    
    return (
      <TableHead>
        <TableRow > 
          {rows.map(row => {
            if (props.from === 'basic') {
              if (row.label !== 'Track description' && row.label !== 'Data type') {
                // if (row.label !== 'Dataset description') {
                  // console.log('qqq row.label',row.label)
                  if (row.label === 'ID') {
                      column =
                        <Tooltip
                            placement={"right-start"}
                            title={
                            <React.Fragment>
                                <div >
                                <Typography>
                                    <b>ID (Exp.ID)</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database
                                </Typography>
                                </div>
                            </React.Fragment>
                            }
                        >
                          <TableCell
                            className={classes.sizeSmall}
                            style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5'}} //,whiteSpace: 'nowrap'
                            key={row.id}
                            align={row.numeric ? 'right' : 'center'}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === row.id ? order : false}
                          >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={orderBy === row.id ? order : 'asc'}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                              </TableSortLabel>
                          </TableCell>
                        </Tooltip>
                  } else {
                      column =
                          <TableCell
                            className={classes.sizeSmall}
                            style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5'}} //,whiteSpace: 'nowrap'
                            key={row.id}
                            // size="small"
                            align={row.numeric ? 'right' : 'center'}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === row.id ? order : false}
                          >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={orderBy === row.id ? order : 'asc'}
                                // onClick={createSortHandler(parseInt(row.id),10)}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                              </TableSortLabel>
                          </TableCell>
                  }
              } else {
                column=null
              }
            } else {
              column =
                        <TableCell
                          className={classes.sizeSmall}
                          style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5'}} //,whiteSpace: 'nowrap'
                          key={row.id}
                          // size="small"
                          align={row.numeric ? 'right' : 'center'}
                          padding={row.disablePadding ? 'none' : 'default'}
                          sortDirection={orderBy === row.id ? order : false}
                        >
                          <TableSortLabel
                              active={orderBy === row.id}
                              direction={orderBy === row.id ? order : 'asc'}
                              onClick={createSortHandler(row.id)}
                          >
                              {row.label}
                            </TableSortLabel>
                        </TableCell>
            }
            return (
              column
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
  
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: 20, //theme.spacing(),
    },
    container: {
      maxHeight: window.innerHeight*0.9 //window.innerHeight*0.8
    },
    table: {
      minWidth: 50,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    loading: {
      marginTop: 50,
      textAlign: 'center',
    },
    sizeSmall: {
      padding: '0px 5px 0px 5px',
    },
}));

const EnhancedTABLEpart = (props) => {

    const [order,setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('track')
  
    const { data } = props; 
    const [dataPerPage, setDataPerPage] = useState([])
  
    const classes = useStyles();  

    useEffect(() => {
        setDataPerPage(data)
        // console.log('n= data',data)
      }, [data])

    const onClick = (id) => {
        const data = {
          selectedExperiment: [parseInt(id.split('___')[0],10)],
          dataType: id.split('___')[1]
        }
        localStorage.setItem("metadata",  JSON.stringify(data));
        window.open("/metadata", "_blank");
      }
    // console.log('dataPerPage',dataPerPage)
    const handleRequestSort = (event, property) => {
      const const_orderBy = property;
      let let_order = 'desc';
  
      if (orderBy === property && order === 'desc') {
        let_order = 'asc';
      }
      setOrder(let_order)
      setOrderBy(const_orderBy)
    };
  
    let column = null
    // const isSelected = id => props.selected.indexOf(id) !== -1;
    // console.log("qqq trackNameAndColor[n[key][0]]",props.trackNameAndColor)
    // console.log('qqq data_for_profile',props.propsdata_for_profile)
    // console.log('props.trackNameAndColor',props.trackNameAndColor)
    return (
          <div className={classes.root}>
            {/* <Paper elevation={0} style={{border: '2px solid #009688'}} square> */}
            <div className={classes.tableWrapper}>
            <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={'small'}>
              <EnhancedTableHead
                // numSelected={selected.length}
                from={props.from}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                // rowCount={dataPerPage.length}
                data={dataPerPage}
              />
              <TableBody>
                {stableSort(dataPerPage, getSorting(order, orderBy))
                  .map((n, indexR) => {
                    // console.log('n=',n)
                    return (
                      <TableRow 
                        hover 
                        tabIndex={-1} 
                        key={indexR}
                        size='small'
                        className={classes.sizeSmall}
                      >
                        {Object.keys(n).map((key, index) => {
                              if (key !== 'color') {
                                if (key === 'track') {
                                  // console.log('props.trackNameAndColor props.trackNameAndColor[n[key][0]] === undefined',props.trackNameAndColor[n[key][0]] === undefined)
                                  column =  
                                    <TableCell 
                                      key={index} 
                                      className={classes.sizeSmall} 
                                      // size='small' 
                                      align="left" //"center" 
                                      style={{
                                        fontSize: 15, 
                                        border: '1px solid #d3d4d5',
                                        borderTop: '1px solid #d3d4d5',
                                        borderLeft: '1px solid #d3d4d5',
                                        opacity: 1, 
                                        color:  Object.keys(props.trackNameAndColor).length === 0 ? 'black' : 'white', 
                                        backgroundColor: props.trackNameAndColor[n[key][0]]
                                      }}
                                    >
                                      {/* {n['method'][0] + ', Exp.' + n[key][0].split(' (')[0]} */}
                                      <Tooltip title="Go to Metadata page">
                                        <Link 
                                          style={{color:  Object.keys(props.trackNameAndColor).length === 0 
                                                          ? 
                                                            'black' 
                                                          :
                                                            props.trackNameAndColor[n[key][0]] === undefined
                                                            ?
                                                              'black'
                                                            :
                                                              'white'}} 
                                          component="button" 
                                          onClick={() => onClick(n[key][0].split(' (')[0].split('ID: ')[1] + "___" + n['typeOfExperiment'][0])}
                                        >
                                          {n[key][0].split(' (')[0].split('ID: ')[1]}
                                        </Link> 
                                      </Tooltip>
                                    </TableCell>
                                  // {n.color === 'none' 
                                  //   ?
                                  //   column =  <TableCell 
                                  //               key={index} 
                                  //               className={classes.sizeSmall} 
                                  //               size='small' 
                                  //               align="left" //"center" 
                                  //               style={{
                                  //                 fontSize: 15, 
                                  //                 border: '1px solid #d3d4d5',
                                  //                 borderTop: '1px solid #d3d4d5',
                                  //                 borderLeft: '1px solid #d3d4d5'
                                  //               }}
                                  //             >
                                  //               {n[key][0]}
                                  //             </TableCell>
                                  //   :
                                  //   column =  <TableCell 
                                  //               key={index} 
                                  //               className={classes.sizeSmall} 
                                  //               size='small' 
                                  //               align="left" //"center" 
                                  //               style={{
                                  //                 fontSize: 15, 
                                  //                 border: '1px solid #d3d4d5',
                                  //                 borderTop: '1px solid #d3d4d5',
                                  //                 borderLeft: '1px solid #d3d4d5',
                                  //                 opacity: 1, 
                                  //                 color: 'white', 
                                  //                 backgroundColor: n.color
                                  //               }}
                                  //             >
                                  //               {n[key][0]}
                                  //             </TableCell>
                                  // }
                                } else {
                                  if (props.from === 'basic') {
                                    let contactsColumn = Object.keys(n).indexOf('totalContactsCount') !== -1 ? 'totalContactsCount' : 'localContactsCount'
                                    // if (key === 'trackDescription') {
                                    //   let list_filters = descriptionModification(n[key][0])
                                    //   let RNA_filter = list_filters[0]
                                    //   let DNA_filter_inser = list_filters[1]
                                    //   let intersection = list_filters[2]
                                    //   column=
                                    //   <TableCell 
                                    //       key={index}
                                    //       size='small' 
                                    //       align="left" //"center"
                                    //       className={classes.sizeSmall}
                                    //       style={{fontSize: 15, 
                                    //               border: '1px solid #d3d4d5',
                                    //               borderTop: '1px solid #d3d4d5',
                                    //               borderLeft: '1px solid #d3d4d5',
                                    //           }} 
                                    //     >
                                    //       {intersection.length > 0 ?
                                    //         <Typography>
                                    //           RNA filter: <b>{RNA_filter}</b>, DNA filter: <b>{DNA_filter_inser}</b>, Intersection<b>{intersection.split("between tracks:")[0]}</b>between tracks:<b>{intersection.split("between tracks:")[1]}</b>
                                    //         </Typography>
                                    //         :
                                    //         <Typography>
                                    //           RNA filter: <b>{RNA_filter}</b>, DNA filter: <b>{DNA_filter_inser}</b>
                                    //         </Typography>
                                    //       }
                                    //   </TableCell> 
                                    // } else 


                                    // if (key === 'datasetDescription') {
                                    //   let res = n[key][0].split("; ");
                                    //   column=
                                    //     <TableCell 
                                    //         key={index}
                                    //         size='small' 
                                    //         align="left" //"center"
                                    //         className={classes.sizeSmall}
                                    //         style={{fontSize: 15, 
                                    //                 border: '1px solid #d3d4d5',
                                    //                 borderTop: '1px solid #d3d4d5',
                                    //                 borderLeft: '1px solid #d3d4d5',
                                    //                 backgroundColor: n[contactsColumn][0] === 0 || n[contactsColumn] === 0 ? '#d3d4d5' : 'white',
                                    //             }} 
                                    //     >
                                    //       <Typography>
                                    //         ID: <b>{res[0].split('ID: ')[1]}</b>, cell line: <b>{res[1].split('cell line: ')[1]}</b>, exp.protocol: <b>{res[2].split('exp.protocol: ')[1]}</b>, extra: <b>{res[3].split('extra: ')[1]}</b>
                                    //       </Typography>
                                    //     </TableCell> 
                                    // } else  



                                    
                                    // if (key === 'method') {
                                    //   column =  
                                    //       <TableCell 
                                    //         key={index} 
                                    //         className={classes.sizeSmall} 
                                    //         // size='small' 
                                    //         align="left" //"center" 
                                    //         style={{
                                    //           fontSize: 15, 
                                    //           border: '1px solid #d3d4d5',
                                    //           borderTop: '1px solid #d3d4d5',
                                    //           borderLeft: '1px solid #d3d4d5',
                                    //           opacity: 1, 
                                    //           color: Object.keys(props.trackNameAndColor).length === 0 ? 'black' : 'white', 
                                    //           backgroundColor: props.trackNameAndColor[n['track'][0]]
                                    //         }}
                                    //       >
                                    //         {n[key][0]}
                                    //       </TableCell>
                                    // } else {
                                      if (key !== 'trackDescription' && key !== 'typeOfExperiment') {
                                        column=
                                        <TableCell 
                                            key={index}
                                            size='small' 
                                            align="left" //"center"
                                            // align="left" 
                                            // align = { key === 'Gene types' ? "left" : "center"}
                                            className={classes.sizeSmall}
                                            style={{fontSize: 15, 
                                                    // whiteSpace: 'nowrap', 
                                                    border: '1px solid #d3d4d5',
                                                    borderTop: '1px solid #d3d4d5',
                                                    borderLeft: '1px solid #d3d4d5',
                                                    backgroundColor: n[contactsColumn][0] === 0 || n[contactsColumn] === 0 ? '#d3d4d5' : null, //? '#d3d4d5' : 'white'
                                                }} 
                                        >
                                          {n[key][0]}
                                        </TableCell> 
                                      } else {
                                        column=null
                                      }
                                    // }
                                  } else {
                                    column=
                                    <TableCell 
                                        key={index}
                                        size='small' 
                                        align="left" //"center"
                                        // align="left" 
                                        // align = { key === 'Gene types' ? "left" : "center"}
                                        className={classes.sizeSmall}
                                        style={{fontSize: 15, 
                                                // whiteSpace: 'nowrap', 
                                                border: '1px solid #d3d4d5',
                                                borderTop: '1px solid #d3d4d5',
                                                borderLeft: '1px solid #d3d4d5',
                                            }} 
                                    >
                                      {n[key][0]}
                                    </TableCell> 
                                  }
                                }
                              } else {
                                column = null
                              }
                              return (
                                  column
                              );
                            })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            </TableContainer>
          </div>
          {/* </Paper> */}
        </div>
    );
  }

export default EnhancedTABLEpart