import React, {useState} from 'react'; //useEffect
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
// import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

const module = "plots"

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      textAlign: 'center',
      flexGrow: 1,
      // paddingTop: "10px !important",
      
    },
    container: {
      maxHeight: window.innerHeight*0.55,
    },
    table: {
      minWidth: 50,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    loading: {
      marginTop: 5,
      textAlign: 'center',
    },
    sizeSmall: {
      padding: '0px 5px 0px 5px',
    },
}));

const TableMetadataBasic = (props) => {

  // const [order,setOrder] = useState('asc')
  // const [orderBy, setOrderBy] = useState('Obtaining data method')
  // const [RNAdataTable, setRNAdataTable] = useState([])
  // const [steps, setSteps] = useState([])
  const [columns, setColumns] = useState(["Name", "Description"])

  const classes = useStyles();  
  const {title} = props;    //, data, columns

  // console.log('title, data, columns}',title, data, columns)  
  const onClick = (id) => {
    const data = {
      selectedExperiment: [Number(id)],
    }
    localStorage.setItem("metadata",  JSON.stringify(data));
    window.open("/metadata", "_blank");
  }

    let column = null
    
    // console.log('RNAdataTable@@@@@@@@@@@@@@@@@@@@@@@@@@',props.RNAdataTable)
    // console.log('steps',steps)
    return (
        <div id='myDiv' >
          {/* className={classes.root} */}
          {/* {steps} */}
          {/* {/* <div  style={{margin: '0 auto', border: '1px solid rgb(224, 224, 224)'}}>  */}
              <div className={classes.tableWrapper}>
                {title !== '' ? 
                  <Toolbar variant="dense">
                    <Typography variant="h6">
                      {title}
                    </Typography>
                  </Toolbar>
                :
                  null
                }
                <TableContainer className={classes.container}>
                  <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={'small'}>
                    <TableBody>
                      {props.RNAdataTable.map((row, indexL) => {
                        return (
                          <TableRow 
                            hover 
                            tabIndex={-1} 
                            key={indexL} 
                            size='small'
                            className={classes.sizeSmall}
                          >
                            {columns.map((key, index) => {
                              // console.log(row,key)
                                if (index === 0) {
                                  if (row[key.replace(/\s+/g, '')] === "RNA-parts of the contacts" ||
                                      row[key.replace(/\s+/g, '')] === "RNA-parts of the contacts (all)" ||
                                      row[key.replace(/\s+/g, '')] === "RNA-parts of the contacts (DNA filter)") {
                                    column=
                                      <Tooltip
                                        placement={"right-start"}
                                        title={
                                          <React.Fragment>
                                            <div>
                                            <Typography>
                                                <b>Download</b> CSV-file with the coordinates of the RNA-parts of the contacts: chromosome, start, end, n-reads, density.
                                            </Typography>
                                            </div>
                                          </React.Fragment>
                                        }
                                      > 
                                        <TableCell 
                                          key={index}
                                          align="left" 
                                          className={classes.sizeSmall}
                                          style={{fontSize: 15, 
                                                    // whiteSpace: 'nowrap', 
                                                    backgroundColor: row[key.replace(/\s+/g, '')] === 'N-reads (raw) (DNA filter)' || 
                                                                    row[key.replace(/\s+/g, '')] === 'CPKM.raw (DNA filter)' || 
                                                                    row[key.replace(/\s+/g, '')] === 'N-reads (normalized) (DNA filter)' ||
                                                                    row[key.replace(/\s+/g, '')] === 'CPKM.norm (DNA filter)' ||
                                                                    row[key.replace(/\s+/g, '')] === 'RNA-parts of the contacts (DNA filter)' 
                                                                    ? '#1f77b4' 
                                                                    
                                                                    : row[key.replace(/\s+/g, '')] === 'N-reads (raw) (all contacts)' ||
                                                                      row[key.replace(/\s+/g, '')] === 'CPKM.raw (all contacts)' ||
                                                                      row[key.replace(/\s+/g, '')] === 'N-reads (normalized) (all contacts)' ||
                                                                      row[key.replace(/\s+/g, '')] === 'CPKM.norm (all contacts)' ||
                                                                      row[key.replace(/\s+/g, '')] === 'RNA-parts of the contacts (all)'
                                                                    ? '#ff7f0e' 
                                                                    : 'white',
                                                    border: '1px solid #000000',
                                                    borderTop: '1px solid #000000',
                                                    borderLeft: '1px solid #000000', //#d3d4d5
                                                }} 
                                        >
                                          <b>{row[key.replace(/\s+/g, '')]}</b>
                                        </TableCell>
                                      </Tooltip>
                                  } else {
                                    column=
                                      <TableCell 
                                        key={index}
                                        align="left" 
                                        className={classes.sizeSmall}
                                        style={{fontSize: 15, 
                                                  // whiteSpace: 'nowrap', 
                                                  backgroundColor: row[key.replace(/\s+/g, '')] === 'N-reads (raw) (DNA filter)' || 
                                                                  row[key.replace(/\s+/g, '')] === 'CPKM.raw (DNA filter)' || 
                                                                  row[key.replace(/\s+/g, '')] === 'N-reads (normalized) (DNA filter)' ||
                                                                  row[key.replace(/\s+/g, '')] === 'CPKM.norm (DNA filter)' ||
                                                                  row[key.replace(/\s+/g, '')] === 'RNA-parts of the contacts (DNA filter)' 
                                                                  ? '#1f77b4' 
                                                                  
                                                                  : row[key.replace(/\s+/g, '')] === 'N-reads (raw) (all contacts)' ||
                                                                    row[key.replace(/\s+/g, '')] === 'CPKM.raw (all contacts)' ||
                                                                    row[key.replace(/\s+/g, '')] === 'N-reads (normalized) (all contacts)' ||
                                                                    row[key.replace(/\s+/g, '')] === 'CPKM.norm (all contacts)' ||
                                                                    row[key.replace(/\s+/g, '')] === 'RNA-parts of the contacts (all)'
                                                                  ? '#ff7f0e' 
                                                                  : 'white',
                                                  border: '1px solid #000000',
                                                  borderTop: '1px solid #000000',
                                                  borderLeft: '1px solid #000000', //#d3d4d5
                                              }} 
                                      >
                                        <b>{row[key.replace(/\s+/g, '')]}</b>
                                      </TableCell>
                                  }
                                } else {
                                  // console.log('row[key]',row[key.replace(/\s+/g, '')])
                                  if (row['Name'] === "Experiment ID") {
                                        column=
                                          <TableCell 
                                              key={index}
                                              align="left" 
                                              className={classes.sizeSmall}
                                              style={{fontSize: 15, 
                                                      // whiteSpace: 'nowrap', 
                                                      // backgroundColor: cellColor,
                                                      border: '1px solid #000000',
                                                      borderTop: '1px solid #000000',
                                                      borderLeft: '1px solid #000000',
                                                  }} 
                                          >
                                            <Link component="button" onClick={() => onClick(row[key.replace(/\s+/g, '')])}>{row[key.replace(/\s+/g, '')]}</Link> 
                                          </TableCell>
                                  } else {
                                          column=
                                            <TableCell 
                                                key={index}
                                                align="left" 
                                                className={classes.sizeSmall}
                                                style={{fontSize: 15, 
                                                        // whiteSpace: 'nowrap', 
                                                        // backgroundColor: '#bbdefb',
                                                        border: '1px solid #000000',
                                                        borderTop: '1px solid #000000',
                                                        borderLeft: '1px solid #000000',
                                                    }} 
                                            >
                                                {row[key.replace(/\s+/g, '')]}
                                            </TableCell>
                                  }
                                }
                              return (
                                  column
                              );
                            })}
                          </TableRow>

                        );
                      })}
                          <TableRow 
                            hover 
                            tabIndex={-1} 
                            key={7434654} 
                            size='small'
                            className={classes.sizeSmall}
                          >
                            {/* <TableCell 
                              key={43241}
                              align="left" 
                              className={classes.sizeSmall}
                              style={{fontSize: 15, 
                                      border: '1px solid #000000',
                                      borderTop: '1px solid #000000',
                                      borderLeft: '1px solid #000000', //#d3d4d5
                                    }} 
                            >
                              <b>{'Type of experiment'}</b>
                            </TableCell>
                            <TableCell 
                              key={43242}
                              align="left" 
                              className={classes.sizeSmall}
                              style={{fontSize: 15, 
                                      border: '1px solid #000000',
                                      borderTop: '1px solid #000000',
                                      borderLeft: '1px solid #000000',
                                  }} 
                            >
                              {"All-to-all"}
                            </TableCell> */}
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>    
              </div>
            </div>
        // </div>
    );
}

const mapStateToProps = (state) => {
    return {
      geneRNAdataProfileTable: state[module].geneRNAdataProfileTable,
    }
}

export default connect(mapStateToProps, {})(TableMetadataBasic);