import React, { useState, useEffect } from 'react';
import { CSVDownload} from 'react-csv'; //CSVLink,
// import axios from 'axios';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CircularProgress from '@material-ui/core/CircularProgress';

// import Checkbox from '@material-ui/core/Checkbox';
// import classNames from 'classnames';
// import Typography from '@material-ui/core/Typography';
// import Toolbar from '@material-ui/core/Toolbar';

// import ShowGraphics from '../components/ShowGraphics';
import WarningMessage from '../components/MessageSnackbar';
// import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';

const module = "genes";
const moduleFilter = "filter";
const moduleBasicAnalysis = "basicAnalysis";
const modulePlots = "plots"



const useStyles = makeStyles(theme => ({
    root: {
        // ...theme.mixins.gutters(),
        paddingTop: 0,//theme.spacing(),
        // paddingBottom: theme.spacing(),
        textAlign: 'center',
        flexGrow: 1,
        width: '100%',
    },
    download: {
        textAlign: 'right',
        padding: 0,
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(),
        // paddingTop: 10
    },
}));

const getChromosome = (coordinate, chrDict) => {
    for (var chrIndex in chrDict) {
        if (chrDict[chrIndex]['start'] < coordinate && coordinate < chrDict[chrIndex]['end']) {
            return chrDict[chrIndex]['name_chr']
        }
    }
}


const DownloadPlotData = (props) => {

    // const classes = useStyles();

    // const [rows, setRows] = useState([]);
    
    
    // const [disabledDOWNLOAD, setDisabledDOWNLOAD] = useState(false)
    const [axiosState, setAxiosState] = useState(false)
    const [data, setData] = useState([])
    // const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    // const [message, setMessage] = useState('')
    // const [downloadERROR, setDownloadERROR] = useState(false)
    
    

    // const handleClose = () => {
    //     setOpen(false)
    // }

    useEffect(() => {
        if (axiosState === true) {
            let csvData = []
            if (props.plot === 'ComparativeHeatmap' || props.plot === "ComparativeHeatmapSelective") {
                for (var trackNameIndex in props.data[1]) {
                    for (var chrIndex in props.data[0]) {
                        csvData.push([props.data[1][trackNameIndex],props.data[0][chrIndex],props.data[2][trackNameIndex][chrIndex]])
                    }
                  }
            } else if (props.plot === 'ContactsDistribution' || props.plot === 'ContactsDistributionSelective') {
                let chrDict = props.data['-111']
                for (var trackIndex in props.data) {
                    if (trackIndex !== "-111") {
                        for (var binIndex in props.data[trackIndex]['x']) {
                            let chromosome = getChromosome(props.data[trackIndex]['x'][binIndex], chrDict)
                            csvData.push([props.data[trackIndex]['description_for_plots'].split("; extra:")[0].split("exp.protocol: ")[1] + "; " + 'Exp.' + props.data[trackIndex]['name'].split('|*|')[0].split(" (")[0] + " (" + props.data[trackIndex]['name'].split(")")[0].split("(")[1] + ")",
                                          props.data[trackIndex]['x'][binIndex],
                                          chromosome, 
                                          props.data[trackIndex]['y'][binIndex]])
                        }
                        
                    }
                }
            } else if (props.plot === "Gene-centric DistanceDistribution" || props.plot === "Gene-centric DistanceDistribution Selective") {
                for (var trackIndex in props.data) {
                    for (var binIndex in props.data[trackIndex]['x']) {
                        csvData.push([props.data[trackIndex].count[4].map(value => {return "Exp." + value.split('; cell line:')[0]+ "; " + value.split('exp.protocol: ')[1].split('; extra:')[0] + " (" + props.data[trackIndex].count[0][0].split("(")[1].split(")")[0] + ")"}),
                                          props.data[trackIndex]['x'][binIndex],
                                          props.data[trackIndex]['y'][binIndex]])
                    }

                }
            } else if (props.plot === "non Gene-centric DistanceDistribution" || props.plot === "non Gene-centric DistanceDistribution Selective") {
                for (var trackIndex in props.data) {
                    for (var binIndex in props.data[trackIndex]['x_non_gene_centric']) {
                        csvData.push([props.data[trackIndex].count[4].map(value => {return "Exp." + value.split('; cell line:')[0]+ "; " + value.split('exp.protocol: ')[1].split('; extra:')[0] + " (" + props.data[trackIndex].count[0][0].split("(")[1].split(")")[0] + ")"}),
                                          props.data[trackIndex]['x_non_gene_centric'][binIndex],
                                          props.data[trackIndex]['y_non_gene_centric'][binIndex]])
                    }

                }
            } else if (props.plot === "RNA-parts profile") {
                // console.log('qqq data',props.data)
                for (var trackIndex in props.data) {
                    for (var binIndex in props.data[trackIndex]['profile']['result']['x']) {
                        csvData.push(["Exp." + props.data[trackIndex]['table']['description_for_plots'].split('; cell line:')[0]+ "; " + props.data[trackIndex]['table']['description_for_plots'].split('exp.protocol: ')[1].split('; extra:')[0] + ` (${props.data[trackIndex]['table']['gene_name']})`,
                                        props.data[trackIndex]['profile']['result']['x'][binIndex],
                                        props.data[trackIndex]['profile']['result']['y'][binIndex]])
                    }

                }
                if (Object.keys(props.data[Object.keys(props.data)[0]]['profile']['result_filter_contacts']).length !== 0) {
                    for (var trackIndex in props.data) {
                        for (var binIndex in props.data[trackIndex]['profile']['result_filter_contacts']['x']) {
                            csvData.push(["Exp." + props.data[trackIndex]['table']['description_for_plots'].split('; cell line:')[0]+ "; " + props.data[trackIndex]['table']['description_for_plots'].split('exp.protocol: ')[1].split('; extra:')[0] + ` (${props.data[trackIndex]['table']['gene_name']}) (target locus: ${props.dnaFilter})` ,
                                            props.data[trackIndex]['profile']['result_filter_contacts']['x'][binIndex],
                                            props.data[trackIndex]['profile']['result_filter_contacts']['y'][binIndex]])
                        }
    
                    }
                }
            } else if (props.plot === "ContactsDistributionDnaFilter" || props.plot === "ContactsDistributionDnaFilterSelective") {
                for (var trackIndex in props.data) {
                    for (var binIndex in props.data[trackIndex]['result']['x']) {
                        csvData.push(["Exp." + props.data[trackIndex]['result']['description_for_plots'].split('; cell line:')[0]+ "; " + props.data[trackIndex]['result']['description_for_plots'].split('exp.protocol: ')[1].split('; extra:')[0] + ` (${props.data[trackIndex]['result']['RNAname']})`,
                                        props.data[trackIndex]['result']['x'][binIndex],
                                        props.data[trackIndex]['result']['y'][binIndex]])
                    }

                }
            }
            // setData([[['response.data.all_data','sdfv','asv'],['response.data.all_data','sdfv','asv']]])    
            setData([csvData])
            setLoader(true)
            setAxiosState(false)
        }
    }, [axiosState]);

    useEffect(() => {
        if (loader === true) {
          setLoader(false)
        }
      }, [loader]);

    const fetchData = () => {
        setAxiosState(true)
    }
    // console.log('dataaaaaaa',loader,data)
    return (
        <div>
            {loader ?
                <CSVDownload 
                  data={data} 
                  separator={"\n"} 
                //   filename="my-file.csv"
                  target="_self"//"_blank" 
                /> 
                :
                null
            }
            {axiosState
                ?
                <LinearProgress />
                :
                    <IconButton 
                        style={{padding: 0}}
                        // disabled={loader || props.loadingBasicGeneTable || props.loadingBasicGeneTableFromDNA || props.loadingAllTargetGenesTable}
                        onClick={e => fetchData()}
                        >
                        <CloudDownloadIcon />
                    </IconButton>
            }
        
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedTrackNames: state[module].selectedTrackNames,
        trackListCheckbox: state[moduleFilter].trackListCheckbox,

        loadingBasicGeneTableFromDNA: state[moduleBasicAnalysis].loadingBasicGeneTableFromDNA,
        loadingBasicGeneTable: state[moduleBasicAnalysis].loadingBasicGeneTable,
        loadingAllTargetGenesTable: state[modulePlots].loadingAllTargetGenesTable,
    }
}

export default connect(mapStateToProps, {})(DownloadPlotData);