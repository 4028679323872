import React from 'react';
import {
    Typography,
    MenuItem,
    NoSsr,
    Chip,
    Paper,
} from '@material-ui/core';

import { emphasize } from '@material-ui/core/styles/colorManipulator';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from 'react-select';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';


const TO_RETURN = 50;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        boderColor: '#009688'
    },
    select: {
        boderColor: '#009688',
    },
    input: {
        display: 'flex',
        padding: 0,
        boderColor: '#009688'
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 9999,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

const Option = (props) => {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
            fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
};

const NoOptionsMessage = (props) => {
    return (
        <div>
            <Typography
                color="textSecondary"
                className={props.selectProps.classes.noOptionsMessage}
                {...props.innerProps}
            >
                Enter 2 character to display possible options
            </Typography>
            {/* Enter 2 character.  */}
        </div>
    )
};

const Placeholder = (props) => {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
};
  
const SingleValue = (props) => {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
      </Typography>
    );
};
  
const ValueContainer = (props) => {
    return (
        <div className={props.selectProps.classes.valueContainer}>
                {props.children}
        </div>
    );
};
  
const MultiValue = (props) => {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
            [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
};
  
const Menu = (props) => {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
};
  
const components = {
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  };
  
const RNAAutocomplete = (props) => {

    const classes = useStyles();

    // {label, value}
    const getSuggestions = () => {
        let suggestions = [];
        let n = (props.title === 'Select RNAs' || props.title === 'Target gene')? 2 : 0
        if (props.currentInput.length >= n) {
            props.suggestions.filter(el => {
                if (el.toLowerCase().startsWith(props.currentInput.toLowerCase())) {
                    suggestions.push({
                        value: el,
                        label: el,
                    });
                }
            })
        }
        return suggestions.slice(0, TO_RETURN);
    };

    // const getSuggestions = () => {
    //     if (props.currentInput.length >= 2) {
    //       return props.suggestions.filter(suggestion => suggestion.toLowerCase().startsWith(props.currentInput.toLowerCase())).slice(0, TO_RETURN);
    //     } else {
    //       return [];
    //     }
    //   }

    const customStyles = {
        control: (styles, state) => ({
            ...styles,
            // height: '20px',
            boxShadow: 'none',
            border: state.isFocused ? "2px solid #009688 !important" : "solid 2px #CED4DA",
            borderColor: state.isFocused ? "#009688" : "#CED4DA",
            "&:hover": {
                borderColor: state.isFocused ? "#009688" : "#CED4DA"
            }
        })
    };

    const handleChange = (v) => {
        props.handleChange(v);
    };

    const handleInputChange = (v) => {
        props.onInputChange(v);
    };

    return (
        <div>
            <NoSsr>
                <Select
                    classes={classes} 
                    className={props.nameClass}
                    isDisabled={props.disabled}
                    options={getSuggestions()}
                    components={components}
                    value={props.selectedItems}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    placeholder={props.title}
                    isMulti
                    styles={customStyles}
                />
                </NoSsr>
        </div>
    )
};

export default RNAAutocomplete;