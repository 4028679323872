import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';
// import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NoSsr from '@material-ui/core/NoSsr';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';

import WarningMessage from './MessageSnackbar';

// import { handleOrganismSelect } from '../actions/organismActions';

const module = "genes";
const moduleTable = "table";
const TO_RETURN = 60;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // height: '100%', // should 100% to properly fix
    // marginBottom: 25, // otherwise collide

    paddingTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 9999,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const NoOptionsMessage = (props) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Enter 1 character to display possible options
      {/* {props.children} */}
    </Typography>
    // Enter 1 character.
  );
}

// const inputComponent = ({ inputRef, ...props }) => {
//   return <div ref={inputRef} {...props} />;
// }

// const Control = (props) => {
//   return (
//     <TextField
//       fullWidth
//       InputProps={{
//         inputComponent,
//         inputProps: {
//           className: props.selectProps.classes.input,
//           inputRef: props.innerRef,
//           children: props.children,
//           ...props.innerProps,
//         },
//       }}
//       {...props.selectProps.textFieldProps}
//     />
//   );
// }

const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

const Placeholder = (props) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const SingleValue = (props) => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

const ValueContainer = (props) => {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const MultiValue = (props) => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

const Menu = (props) => {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  // Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};


const GeneTypesSelector = (props) => {

  const [count, setCount] = useState(0)
  const [placeholder, setPlaceholder] = useState("Select gene types")

  useEffect(() => {
    if (props.parent === 'welcome page, from RNA' && count === 0) {
      if (props.selectedItemOrganism[0]['label'] === props.organism) {
        props.fetchGeneTypes(props.selectedItemOrganism[0]['label'])
        setCount(1)
      }
    } else {
      props.fetchGeneTypes(props.selectedItemOrganism[0]['label'])
    }
  }, [props.selectedItemOrganism])

  useEffect(() => {
    // console.log('props.placeholderText',props.placeholderText)
    if (typeof(props.placeholderText) !== 'undefined') {
      setPlaceholder(props.placeholderText)
    }
  }, [props.placeholderText]);

  const handleChange = (value) => {
    props.handleGeneTypesSelect(value)
  };

  const getSuggestions = () => {
    // return this.props.suggestions.filter(suggestion => suggestion.includes())
    if (props.currentGeneTypesInput.length >= 0) {
      return props.suggestionTypes.filter(suggestion => suggestion.label.toLowerCase().startsWith(props.currentGeneTypesInput.toLowerCase())).slice(0, TO_RETURN);
    } else {
      return [];
    }
  }
  // console.log('props.suggestionTypes',props.suggestionTypes)
  const handleInputChange = (value) => {
    props.changeCurrentGeneTypesInput(value);
  }

  // const { classes } = props;
  const classes = useStyles();

  useEffect(() => {
    // console.log('props.disabled',props.disabled)
    if (props.disabled) {
      // console.log('props.handleGeneTypesSelect([])')
      props.handleGeneTypesSelect([]);
    }
  }, [props.disabled]);

  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      // height: '20px',
      boxShadow: 'none',
      border: state.isFocused ? "2px solid #009688 !important" : "solid 2px #CED4DA",
      borderColor: state.isFocused ? "#009688" : "#CED4DA",
      "&:hover": {
        borderColor: state.isFocused ? "#009688" : "#CED4DA"
      }
    })
  };


  return (
    <div className={classes.root}>
    <NoSsr>
      <Select
        classes={classes}
        // textFieldProps={{
        //   label: 'GeneTypes',
        //   InputLabelProps: {
        //     shrink: true,
        //   },
        //   variant: 'outlined',
        //   borderColor: '#009688',
        // }}
        isDisabled={props.disabled}
        options={getSuggestions()}
        components={components}
        value={props.selectedItemGeneTypes}
        onChange={handleChange}
        placeholder={placeholder}
        isMulti
        onInputChange={handleInputChange}
        styles={customStyles}
      />
    </NoSsr>
    <WarningMessage open={props.snackOpenGeneTypes} severity={"warning"} message={props.snackTextGeneTypes} />
  </div>
  );
}


const mapStateToProps = (state) => {
  return {
    suggestionTypes: state[module].suggestionTypes,
    snackOpenGeneTypes: state[module].snackOpenGeneTypes,
    snackTextGeneTypes: state[module].snackTextGeneTypes,
    selectedItemOrganism: state[moduleTable].selectedItemOrganism,
  };
};

export default connect(mapStateToProps)(GeneTypesSelector);

