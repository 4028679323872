import { take, put, call, fork, all } from 'redux-saga/effects'; //select,takeEvery
import * as genesActions from '../actions/genesActions';
import * as organismActions from '../actions/organismActions';
import * as generalActions from '../actions/generalActions';
import * as genomeBrowserActions from '../actions/genomeBrowserActions';
import * as plotsActions from '../actions/plotsActions';
import * as basicAnalysisActions from '../actions/basicAnalysisActions';
import axios from 'axios';

// export const checkLogged = function *() {
//     while (true) {
//         const action = yield take(generalActions.IS_LOGGED_REQUEST);
//         try {
//             const result = yield call(
//                 checkLoggedFlask
//             );
//             if (result.status === 200) {
//                 let logged = false;
//                 if (result.data === "logged") logged = true;
//                 yield put({
//                     type: generalActions.IS_LOGGED_SUCCESS,
//                     logged: logged,
//                 });
//             }
//         } catch (err) {
//             console.log(err);
//         }
//     }
// }

// const checkLoggedFlask = function* (genes) {
//     try {
//         const result = yield call(
//             axios.get,
//             '/is_logged_in/', ///is_logged_in/
//             { headers: { "Access-Control-Allow-Origin": "*", }},
//         );
//         return result.data
//     }catch (err) {
//         console.log(err);
//     }
// }

// export const logout = function *() {
//     while (true) {
//         const action = yield take(generalActions.LOGOUT_REQUEST);
//         try {
//             const result = yield call(
//                 logoutFlask
//             );
//             if (result.status === 200) {
//                 let logged = false;
//                 // window.location.reload();
//                 yield put({
//                     type: generalActions.LOGOUT_SUCCESS,
//                 });
//             }
//         } catch (err) {
//             console.log(err);
//         }
//     }
// }

// const logoutFlask = function* () {
//     try {
//         const result = yield call(
//             axios.get,
//             '/logout/',
//             { headers: { "Access-Control-Allow-Origin": "*", }},
//         );
//         return result.data
//     }catch (err) {
//         console.log(err);
//     }
// }

// export const login = function *() {
//     while (true) {
//         const action = yield take(generalActions.LOGIN_REQUEST);
//         try {
//             const result = yield call(
//                 loginQuart,
//                 action.username,
//                 action.password,
//             );
//             if (result.status === 200) {
//                 // window.location.reload();
//                 yield put({
//                     type: generalActions.LOGIN_SUCCESS,
//                 });
//             }
//             else {
//                 yield put({
//                     type: generalActions.LOGIN_FAIL,
//                     errorMessage: result.error,
//                 })
//             }
//         } catch (err) {
//             yield put({
//                 type: generalActions.LOGIN_FAIL,
//             });
//         }
//     }
// }

// const loginQuart = function* (username, password) {
//     try {
//         const data = { username, password };
//         const result = yield call(
//             axios.post,
//             '/login/', ///login/
//             data,
//             { headers: { "Access-Control-Allow-Origin": "*", }},
//         );
//         return result.data
//     }catch (err) {
//         yield put({
//             type: generalActions.LOGIN_FAIL,
//         });
//     }
// }

export const fetchGenes = function *() {
    while (true) {
        const action = yield take(genesActions.FETCH_GENES_REQUEST);
        try {
            const result = yield call(
                fetchGenesQuart,
                action.selectedOrganism
            );
            if (result.status === 200) {
                yield put({
                    type: genesActions.FETCH_GENES_SUCCESS,
                    text: "Successfully submitted.",
                    // heatmap_data_result: result.data.heatmapDataDict,
                    // genes: result.data.genes,
                    genes: result.data.genes.map(function(n) { return {'value': n, 'label': n}})
                });
            }
            else { 
                yield put({
                    type: genesActions.FETCH_GENES_FAILURE,
                    error: "Whoooops..."
                });
            }
        } catch (err) {
            yield put({
                type: genesActions.FETCH_GENES_FAILURE,
                error: "An error occurred in the @app.route(/fetch_genes/, methods=[POST]), genes data was not sent to the web"
            });
        }
    }
};

const fetchGenesQuart = function* (selectedOrganism) {
    try {
        let data = {selectedOrganism};
        const result = yield call(
            axios.post,
            '/fetch_genes/', ///fetch_genes/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: genesActions.FETCH_GENES_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}


export const fetchGeneTypes = function *() {
    while (true) {
        const action = yield take(genesActions.FETCH_GENE_TYPES_REQUEST);
        // console.log('action.selectedOrganism',action.selectedOrganism)
        try {
            const result = yield call(
                fetchGeneTypesQuart,
                action.selectedOrganism
            );
            if (result.status === 200) {
                yield put({
                    type: genesActions.FETCH_GENE_TYPES_SUCCESS,
                    text: "Successfully submitted.",
                    // heatmap_data_result: result.data.heatmapDataDict,
                    geneTypes: result.data.geneTypes,
                });
            }
            else { 
                yield put({
                    type: genesActions.FETCH_GENE_TYPES_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: genesActions.FETCH_GENE_TYPES_FAILURE,
                error: "An error occurred in the @app.route(/fetch_gene_types/, methods=[POST]), gene types data was not sent to the web"
            });
        }
    }
};

const fetchGeneTypesQuart = function* (selectedOrganism) {
    try {
        let data = {selectedOrganism};
        // console.log('fetchGeneTypesFlask',selectedOrganism)
        const result = yield call(
            axios.post,
            '/fetch_gene_types/', ///fetch_gene_types/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: genesActions.FETCH_GENE_TYPES_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}

/* ANNOTATION */

export const fetchAnnotation = function *() {
    while (true) {
        const action = yield take(genesActions.FETCH_ANNOTATION_REQUEST);
        // console.log('action.selectedOrganism',action.selectedOrganism)
        try {
            const result = yield call(
                fetchAnnotationQuart,
                action.selectedOrganism
            );
            if (result.status === 200) {
                yield put({
                    type: genesActions.FETCH_ANNOTATION_SUCCESS,
                    text: "Successfully submitted.",
                    // heatmap_data_result: result.data.heatmapDataDict,
                    annotations: result.data.annotations,
                });
            }
            else { 
                yield put({
                    type: genesActions.FETCH_ANNOTATION_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: genesActions.FETCH_ANNOTATION_FAILURE,
                error: "An error occurred in the @app.route(/fetch_annotation/, methods=[POST]), annotation data was not sent to the web"
            });
        }
    }
};

const fetchAnnotationQuart = function* (selectedOrganism) {
    try {
        let data = {selectedOrganism};
        // console.log('fetchGeneTypesFlask',selectedOrganism)
        const result = yield call(
            axios.post,
            '/fetch_annotation/', ///fetch_annotation/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: genesActions.FETCH_ANNOTATION_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}

/* ORGANISM */

// export const fetchOrganism = function *() {
//     while (true) {
//         const action = yield take(organismActions.FETCH_ORGANISM_REQUEST);
//         try {
//             const result = yield call(
//                 fetchOrganismQuart,
//                 action.logged,
//             );
//             if (result.status === 200) {
//                 yield put({
//                     type: organismActions.FETCH_ORGANISM_SUCCESS,
//                     text: "Successfully submitted.",
//                     // heatmap_data_result: result.data.heatmapDataDict,
//                     organism: result.data.organism,
//                 });
//             }
//             else { 
//                 yield put({
//                     type: organismActions.FETCH_ORGANISM_FAILURE,
//                     error: "Page error, please contact the administrator."
//                 });
//             }
//         } catch (err) {
//             yield put({
//                 type: organismActions.FETCH_ORGANISM_FAILURE,
//                 error: "Page error, please contact the administrator."
//             });
//         }
//     }
// };

// const fetchOrganismQuart = function* (logged) {
//     try {
//         let data = {logged};
//         const result = yield call(
//             axios.post,
//             '/fetch_organism/', ///fetch_organism/
//             data,
//             { headers: { "Access-Control-Allow-Origin": "*", }},
//         );
//         return result
//     }catch (err) {
//         yield put({
//             type: organismActions.FETCH_ORGANISM_FAILURE,
//             error: "Page error, please contact the administrator.",
//         });
//     }
// }

const fetchExpTable = function *() {
    while (true) {
        const action = yield take(generalActions.FETCH_EXP_TABLE_REQUEST);
        try {
            const result = yield call(
                fetchExpTableQuart_post, //fetchExpTableFlask
                action.input_organism,
                // action.logged,
            );
            if (result.data.status === 200) {
                yield put({
                    type: generalActions.FETCH_EXP_TABLE_SUCCESS,
                    data: result.data.data,
                });
            }
            else { 
                yield put({
                    type: generalActions.FETCH_EXP_TABLE_FAILURE,
                });
            }
        } catch (err) {
            yield put({
                type: generalActions.FETCH_EXP_TABLE_FAILURE,
            });
        }
    }
}

const fetchExpTableQuart_post = function* (input_organism) { //logged
    // console.log('input_organism and logged',input_organism,logged)
    try {
        let data = {input_organism}; //,logged
        const result = yield call(
            axios.post,
            '/fetch_experiment_table_post/', ///fetch_experiment_table_post/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: generalActions.FETCH_EXP_TABLE_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}

export const submitCheckedTracks = function *() {
    while (true) {
        const action = yield take(genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_REQUEST);
        try {
            const result = yield call(
                submitCheckedTracksQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.status === 200) {
                yield put({
                    type: genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_SUCCESS,
                    text: "Successfully submitted.",
                    URLtoGenomeBrowser: result.data.URL,
                });
            }
            else { 
                yield put({
                    type: genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_Genome_Browser/, methods=[POST]), data was not sent to the GENOME BROWSER"
            });
        }
    }
};

const submitCheckedTracksQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization};
        const result = yield call(
            axios.post,
            '/submit_tracks_for_Genome_Browser/', ///submit_tracks_for_Genome_Browser/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
        // console.log('result GB',result)
    }catch (err) {
        yield put({
            type: genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}























export const submitCheckedTracksRnaPart = function *() {
    while (true) {
        const action = yield take(genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_REQUEST);
        try {
            const result = yield call(
                submitCheckedTracksRnaPartQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.status === 200) {
                yield put({
                    type: genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_SUCCESS,
                    text: "Successfully submitted.",
                    URLtoGenomeBrowserRnaPart: result.data.URL,
                });
            }
            else { 
                yield put({
                    type: genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_Genome_Browser/, methods=[POST]), data was not sent to the GENOME BROWSER"
            });
        }
    }
};

const submitCheckedTracksRnaPartQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization};
        const result = yield call(
            axios.post,
            '/submit_tracks_for_Genome_Browser/', ///submit_tracks_for_Genome_Browser/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
        // console.log('result GB',result)
    }catch (err) {
        yield put({
            type: genomeBrowserActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}


















export const submitCheckedTracksForHeatmap = function *() {
    while (true) {
        const action = yield take(plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_REQUEST);
        try {
            //console.log('action.typeOfContacts',action.typeOfContacts)
            //console.log('action.checkedTracks',action.checkedTracks)
            const result = yield call(
                submitCheckedTracksForHeatmapQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_SUCCESS,
                    text: "Successfully submitted.",
                    genomeWideData: result.data.all_data,
                });
            }
            else { 
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_Heatmap/, methods=[POST]), Heatmap data was not sent to the web"
            });
        }
    }
};

const submitCheckedTracksForHeatmapQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization};
        const result = yield call(
            axios.post,
            '/submit_tracks_for_Heatmap/', ///submit_tracks_for_Heatmap/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
        // console.log('result.data',result.data)
    }catch (err) {
        yield put({
            type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}

export const submitCheckedTracksForHeatmapComparative = function *() {
    while (true) {
        const action = yield take(plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_REQUEST);
        try {
            const result = yield call(
                submitCheckedTracksForHeatmapComparativeQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_SUCCESS,
                    text: "Successfully submitted.",
                    genomeWideData: result.data.all_data,
                });
            }
            else { 
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_Comparative_Heatmap/, methods=[POST]), Heatmap data was not sent to the web"
            });
        }
    }
};

const submitCheckedTracksForHeatmapComparativeQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization};
        const result = yield call(
            axios.post,
            '/submit_tracks_for_Comparative_Heatmap/',
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}








export const submitTracksComparison = function *() {
    while (true) {
        const action = yield take(generalActions.SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_REQUEST);
        try {
            //console.log('action.typeOfContacts',action.typeOfContacts)
            const result = yield call(
                submitTracksComparisonQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.organism,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: generalActions.SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_SUCCESS,
                    text: "Successfully submitted.",
                    TracksComparisonData: result.data.all_data,
                });
            }
            else { 
                // console.log('result.data.status',result.data.status)
                yield put({
                    type: generalActions.SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: generalActions.SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_RNA_list/, methods=[POST]), RNA list data profile was not sent to the web"
            });
        }
    }
};

const submitTracksComparisonQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,organism,selectedNormalization) {
    try {
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,organism,selectedNormalization};
        const result = yield call(
            axios.post,
            '/submit_data_for_tracks_comparison/', ///submit_tracks_for_RNA_list/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
        // console.log('result.data',result.data)
    }catch (err) {
        // console.log('err',err)
        yield put({
            type: generalActions.SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}









export const submitTableRnaList = function *() {
    while (true) {
        const action = yield take(generalActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_REQUEST);
        try {
            //console.log('action.typeOfContacts',action.typeOfContacts)
            const result = yield call(
                submitTableRnaListQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
                action.params,
            );
            if (result.data.status === 200) {
                yield put({
                    type: generalActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_SUCCESS,
                    text: "Successfully submitted.",
                    RnaListData: result.data.all_data,
                });
            }
            else { 
                // console.log('result.data.status',result.data.status)
                yield put({
                    type: generalActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: generalActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_RNA_list/, methods=[POST]), RNA list data profile was not sent to the web"
            });
        }
    }
};

const submitTableRnaListQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization, params) {
    try {
        let data = {
                    checkedTracks,
                    typeOfContacts,
                    selectedTrackNames,
                    selectedNormalization,
                    params
                };
        const result = yield call(
            axios.post,
            '/submit_tracks_for_RNA_list/', ///submit_tracks_for_RNA_list/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        // console.log('result.data',result.data)
        return result
    }catch (err) {
        // console.log('err',err)
        yield put({
            type: generalActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}

export const submitCheckedTracksForDNApartProfile = function *() {
    while (true) {
        const action = yield take(plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_REQUEST);
        try {
            const result = yield call(
                submitCheckedTracksForDNApartProfileQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_SUCCESS,
                    text: "Successfully submitted.",
                    geneDNAdataProfile: result.data.all_data,
                });
            }
            else { 
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_DNA_part_Profile/, methods=[POST]), Genome data profile was not sent to the web"
            });
        }
    }
};

const submitCheckedTracksForDNApartProfileQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization} //selectedTrackNames,
        const result = yield call(
            axios.post,
            '/submit_tracks_for_DNA_part_Profile/', ///submit_tracks_for_Profile/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
        // console.log('result.data',result.data)
    }catch (err) {
        yield put({
            type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}


export const submitCheckedTracksForRNApartProfile = function *() {
    while (true) {
        const action = yield take(plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_REQUEST);
        try {
            const result = yield call(
                submitCheckedTracksForRNApartProfileQuart,
                // action.experiment_id,
                // action.gene_name,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_SUCCESS,
                    text: "Successfully submitted.",
                    geneRNAdataProfile: result.data.all_data,
                });
            }
            else { 
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_RNA_part_Profile/, methods=[POST]), Genome data profile was not sent to the web"
            });
        }
    }
};

const submitCheckedTracksForRNApartProfileQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        // let data = {experiment_id,gene_name};
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization} //selectedTrackNames,
        const result = yield call(
            axios.post,
            '/submit_tracks_for_RNA_part_Profile/', ///submit_tracks_for_Profile/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
        // console.log('result.data',result.data)
    }catch (err) {
        yield put({
            type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}


export const submitCheckedTracksForRNApartProfileTable = function *() {
    while (true) {
        const action = yield take(plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_REQUEST);
        try {
            const result = yield call(
                submitCheckedTracksForRNApartProfileTableQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_SUCCESS,
                    text: "Successfully submitted.",
                    geneRNAdataProfileTable: result.data.all_data,
                });
            }
            else { 
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_RNA_part_Profile_table/, methods=[POST]), Genome data profile was not sent to the web"
            });
        }
    }
};

const submitCheckedTracksForRNApartProfileTableQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        // let data = {experiment_id,gene_name};
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization} //selectedTrackNames,
        const result = yield call(
            axios.post,
            '/submit_tracks_for_RNA_part_Profile_table/', ///submit_tracks_for_Profile/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
        // console.log('result.data',result.data)
    }catch (err) {
        yield put({
            type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}










export const submitCheckedTracksForProfile = function *() {
    while (true) {
        const action = yield take(plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_REQUEST);
        try {
            const result = yield call(
                submitCheckedTracksForProfileQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_SUCCESS,
                    text: "Successfully submitted.",
                    genomeWideDataProfile: result.data.all_data,
                });
            }
            else { 
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_Profile/, methods=[POST]), Genome data profile was not sent to the web"
            });
        }
    }
};

const submitCheckedTracksForProfileQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization};
        const result = yield call(
            axios.post,
            '/submit_tracks_for_Profile/', ///submit_tracks_for_Profile/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
        // console.log('result.data',result.data)
    }catch (err) {
        yield put({
            type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}

export const submitCheckedTracksForDistanceDistribution = function *() {
    while (true) {
        const action = yield take(plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_REQUEST);
        try {
            const result = yield call(
                submitCheckedTracksForDistanceDistributionQuart,
                action.checkedTracks,
                action.typeOfContacts,
                action.selectedTrackNames,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_SUCCESS,
                    text: "Successfully submitted.",
                    genomeWideDataDistanceDistribution: result.data.all_data,
                });
            }
            else { 
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_FAILURE,
                error: "An error occurred in the @app.route(/submit_tracks_for_Distance_Distribution/, methods=[POST]), Distansce distribution data was not sent to the web"
            });
        }
    }
};

const submitCheckedTracksForDistanceDistributionQuart = function* (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) {
    try {
        let data = {checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization};
        const result = yield call(
            axios.post,
            '/submit_tracks_for_Distance_Distribution/', ///submit_tracks_for_Distance_Distribution/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}

const fetchTableAnnotationRNA = function *() {
    while (true) {
        const action = yield take(generalActions.FETCH_ANNOTATION_RNA_REQUEST);
        try {
            const result = yield call(
                fetchTableAnnotationRNAQuart_post,
                action.id,
            );
            if (result.data.status === 200) {
                yield put({
                    type: generalActions.FETCH_ANNOTATION_RNA_SUCCESS,
                    annotationRNA: result.data.annotationRNA,
                });
            }
            else { 
                yield put({
                    type: generalActions.FETCH_ANNOTATION_RNA_FAILURE,
                });
            }
        } catch (err) {
            yield put({
                type: generalActions.FETCH_ANNOTATION_RNA_FAILURE,
            });
        }
    }
}

const fetchTableAnnotationRNAQuart_post = function* (id) {
    try {
        let data = {id};
        const result = yield call(
            axios.post,
            '/fetch_annotation_rna/', ///fetch_annotation_rna/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: generalActions.FETCH_ANNOTATION_RNA_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}











const fetchallExps = function *() {
    while (true) {
        const action = yield take(generalActions.FETCH_ALL_EXPS_REQUEST);
        try {
            const result = yield call(
                fetchallExpsQuart_post
            );
            if (result.data.status === 200) {
                yield put({
                    type: generalActions.FETCH_ALL_EXPS_SUCCESS,
                    AllExps: result.data.AllExps,
                });
            }
            else { 
                yield put({
                    type: generalActions.FETCH_ALL_EXPS_FAILURE,
                });
            }
        } catch (err) {
            yield put({
                type: generalActions.FETCH_ALL_EXPS_FAILURE,
            });
        }
    }
}

const fetchallExpsQuart_post = function* () {
    try {
        const result = yield call(
            axios.get,
            '/ololo/',
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: generalActions.FETCH_ALL_EXPS_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}














const fetchProcessingMetadata = function *() {
    while (true) {
        const action = yield take(generalActions.FETCH_PROCESSING_METADA_REQUEST);
        try {
            const result = yield call(
                fetchProcessingMetadataQuart_post,
                action.id,
            );
            if (result.data.status === 200) {
                yield put({
                    type: generalActions.FETCH_PROCESSING_METADA_SUCCESS,
                    processingMetadata: result.data.processingMetadata,
                });
            }
            else { 
                yield put({
                    type: generalActions.FETCH_PROCESSING_METADA_FAILURE,
                });
            }
        } catch (err) {
            yield put({
                type: generalActions.FETCH_PROCESSING_METADA_FAILURE,
            });
        }
    }
}

const fetchProcessingMetadataQuart_post = function* (id) {
    try {
        let data = {id};
        const result = yield call(
            axios.post,
            '/fetch_processing_metadata/', ///fetch_processing_metadata/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: generalActions.FETCH_PROCESSING_METADA_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}


const fetchMetadataPlots = function *() {
    while (true) {
        const action = yield take(generalActions.FETCH_METADA_PLOTS_REQUEST);
        try {
            const result = yield call(
                fetchMetadataPlotsQuart_post,
                action.id,
                action.selectedNormalization,
            );
            if (result.data.status === 200) {
                yield put({
                    type: generalActions.FETCH_METADA_PLOTS_SUCCESS,
                    MetadataPlots: result.data.MetadataPlots,
                });
            }
            else { 
                yield put({
                    type: generalActions.FETCH_METADA_PLOTS_FAILURE,
                });
            }
        } catch (err) {
            yield put({
                type: generalActions.FETCH_METADA_PLOTS_FAILURE,
            });
        }
    }
}

const fetchMetadataPlotsQuart_post = function* (id,selectedNormalization) {
    try {
        let data = {id,selectedNormalization};
        const result = yield call(
            axios.post,
            '/fetch_metadata_plots/', ///fetch_processing_metadata/
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: generalActions.FETCH_METADA_PLOTS_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}
















const fetchBasicGeneTable = function *() {
    while (true) {
        const action = yield take(basicAnalysisActions.FETCH_BASIC_GENE_TABLE_REQUEST);
        try {
            const result = yield call(
                fetchBasicGeneTableQuart_post, //fetchExpTableFlask
                action.params,
            );
            if (result.data.status === 200) {
                yield put({
                    type: basicAnalysisActions.FETCH_BASIC_GENE_TABLE_SUCCESS,
                    data: result.data.genes,
                });
            }
            else { 
                yield put({
                    type: basicAnalysisActions.FETCH_BASIC_GENE_TABLE_FAILURE,
                });
            }
        } catch (err) {
            yield put({
                type: basicAnalysisActions.FETCH_BASIC_GENE_TABLE_FAILURE,
            });
        }
    }
}

const fetchBasicGeneTableQuart_post = function* (params) {
    // console.log('fetchBasicGeneTableQuart_post (params)',params)
    try {
        let data = {params};
        const result = yield call(
            axios.post,
            '/fetch_BasicGeneTable/',
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: basicAnalysisActions.FETCH_BASIC_GENE_TABLE_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}














const fetchBasicGeneTableFromDNA = function *() {
    while (true) {
        const action = yield take(basicAnalysisActions.FETCH_BASIC_GENE_TABLE_FROM_DNA_REQUEST);
        try {
            const result = yield call(
                fetchBasicGeneTableFromDNAQuart_post, //fetchExpTableFlask
                action.params,
            );
            if (result.data.status === 200) {
                yield put({
                    type: basicAnalysisActions.FETCH_BASIC_GENE_TABLE_FROM_DNA_SUCCESS,
                    data: result.data.genes,
                });
            }
            else { 
                yield put({
                    type: basicAnalysisActions.FETCH_BASIC_GENE_TABLE_FROM_DNA_FAILURE,
                });
            }
        } catch (err) {
            yield put({
                type: basicAnalysisActions.FETCH_BASIC_GENE_TABLE_FROM_DNA_FAILURE,
            });
        }
    }
}

const fetchBasicGeneTableFromDNAQuart_post = function* (params) {
    // console.log('fetchBasicGeneTableQuart_post (params)',params)
    try {
        let data = {params};
        const result = yield call(
            axios.post,
            '/fetch_BasicGeneTableFromDNA/',
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: basicAnalysisActions.FETCH_BASIC_GENE_TABLE_FROM_DNA_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}














export const submitAllTargetGenesTable = function *() {
    while (true) {
        const action = yield take(plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_REQUEST);
        try {
            const result = yield call(
                submitAllTargetGenesTableQuart,
                action.params,
            );
            if (result.data.status === 200) {
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_SUCCESS,
                    text: "Successfully submitted.",
                    allTargetGenesTableData: result.data.genes,
                });
            }
            else { 
                yield put({
                    type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_FAILURE,
                    error: "Page error, please contact the administrator."
                });
            }
        } catch (err) {
            yield put({
                type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_FAILURE,
                error: "An error occurred in the @app.route(/submit_all_targen_genes_table/, methods=[POST]), Genome data profile was not sent to the web"
            });
        }
    }
};


const submitAllTargetGenesTableQuart = function* (params) {
    try {
        let data = {params}
        const result = yield call(
            axios.post,
            '/submit_all_targen_genes_table/',
            data,
            { headers: { "Access-Control-Allow-Origin": "*", }},
        );
        return result
    }catch (err) {
        yield put({
            type: plotsActions.SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_FAILURE,
            error: "Page error, please contact the administrator.",
        });
    }
}












export default function* rootSaga() {
    yield all([
        // fork(checkLogged),
        // fork(login),
        // fork(logout),
        fork(fetchGenes),
        fork(fetchGeneTypes),
        fork(fetchAnnotation),
        // fork(fetchOrganism),
        fork(fetchExpTable),
        fork(fetchTableAnnotationRNA),
        fork(fetchallExps),
        fork(fetchProcessingMetadata),
        fork(fetchMetadataPlots),
        fork(submitCheckedTracks),
        fork(submitCheckedTracksRnaPart),
        fork(submitCheckedTracksForHeatmap),
        fork(submitCheckedTracksForHeatmapComparative),
        fork(submitTracksComparison),
        fork(submitTableRnaList),
        fork(submitCheckedTracksForDNApartProfile),
        fork(submitCheckedTracksForRNApartProfile),
        fork(submitCheckedTracksForRNApartProfileTable),
        fork(submitCheckedTracksForProfile),
        fork(submitCheckedTracksForDistanceDistribution),
        fork(fetchBasicGeneTable),
        fork(fetchBasicGeneTableFromDNA),
        fork(submitAllTargetGenesTable),
    ]);
};