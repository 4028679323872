import React, { useState, useEffect } from 'react';
// import classNames from 'classnames';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import Radio from '@material-ui/core/Radio';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
// import { lighten } from '@material-ui/core/styles/colorManipulator';
import LinearProgress from '@material-ui/core/LinearProgress';
// import FormGroup from '@material-ui/core/FormGroup';
// import Switch from '@material-ui/core/Switch';
// import { Link } from 'react-router-dom'

import WarningMessage from './MessageSnackbar';
// import OrganismSelectorSimple from '../components/AutoSuggestOrganismSimple';
import RnaListPagination from '../components/RnaListPagination';

import { fetchBasicGeneTable } from '../actions/basicAnalysisActions';
// import WarningMessage from '../components/MessageSnackbar';

// const module = "table";
// const module = "genes";
// const moduleFilter = "filter";
const module = "plots";
const moduleBasicAnalysis = "basicAnalysis";

const makeID = (length) => {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// const desc = (a, b, orderBy) => {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// const stableSort = (array, cmp) =>{
//   // console.log(array, cmp,'array, cmp')
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// const getSorting = (order, orderBy) => {
//   return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }

export const LocusFilterParser = (valueLocus) => {
  let loci_array = []
  for (const one of valueLocus.replace(/;$/g, '').split(';').map(function(item) {return item.trim();})) {
      let chr = 0
      let start = 0
      let end = 0
      var locus_array = one.replace(/,+|\s+/g, '').split(/r|:|-/i).map(function(item) {return item.trim();})
      // console.log('locus_array',locus_array)
      if (locus_array.length === 2 && locus_array[0].toLowerCase() === 'ch' && (isNaN(Number(locus_array[1])) !== true || locus_array[1].toLowerCase() === 'x' || locus_array[1].toLowerCase() === 'y')) {
          {locus_array[1].toLowerCase() === 'x' ? chr = 33 : locus_array[1].toLowerCase() === 'y' ? chr = 44 : chr = Number(locus_array[1])}
          end = 1000000000000000
          loci_array.push([chr,start,end])
          // props.locusWarningChangeFalse()
      } else if (locus_array.length === 4 && locus_array[2] !== '' && locus_array[3] !== '' && isNaN(Number(locus_array[2])) !== true && isNaN(Number(locus_array[3])) !== true) {
          if (locus_array[0].toLowerCase() === 'ch' && Number(locus_array[2].replace(/^0*/,'')) < Number(locus_array[3].replace(/^0*/,''))) {
              {locus_array[1].toLowerCase() === 'x' ? chr = 33 : locus_array[1].toLowerCase() === 'y' ? chr = 44 : chr = Number(locus_array[1])}
              start = Number(locus_array[2])
              end = Number(locus_array[3])
              loci_array.push([chr,start,end])
          } else {
              loci_array = []
              break;
          }
      } else {
          loci_array = []
          break;
      }
  }
  return loci_array
}

// const rows = [
//     // {id: 'id', numeric: false, disablePadding: false, label: 'ID'},
//     {id:'gene_name', numeric: false, disablePadding: false, label: 'Annotated name' },
//     {id:'gene_type', numeric: false, disablePadding: true, label: 'Gene type'},
//     {id:'locus', numeric: false, disablePadding: true, label: 'Gene locus'},
//     {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
//     {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
//     {id:'all_vs_all', numeric: false, disablePadding: true, label: 'All-vs-all (max CPKM)'}, 
//     {id:'one_vs_all', numeric: false, disablePadding: true, label: 'One-vs-all (max CPKM)'}, 
//     // {id:'build', numeric: false, disablePadding: true, label: 'Genome'},
//     {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},
// ];

// const data = [
//     {id: 1,name: "Xist",gene_type: "lncRNA",locus: "chr1:12-123",strand: "+",from: "sd"},
//     {id: 2,name: "Xsdfist",gene_type: "lncRNA",locus: "chr1:12-123",strand: "+",from: "QWdffs"},
// ];

const BasicTableRNAHead = (props) => {
  
  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };
  // debugger
  const { order, orderBy, rows } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow > 
        {rows.map(row => {
          // console.log('ooosww',row)
          let wrap = ''
          // if (row.label.includes('All-to-all') === true || row.label.includes('One-to-all') === true) {
          if (row.label === 'All-to-all' || row.label === 'One-to-all') {
            wrap = 'nowrap'
          } else {
            wrap = 'wrap'
          }
          if (row.label === "up50" || row.label === "up5" || row.label === "GeneBody" || row.label === "down5" || row.label === "down50") {
            let description_1 = ''
            let description_2 = ''
            let description_3 = ''
            if (row.label === "up50") {
              description_1 = `the number of RNA contacts with that region divided by the size of that region (i.e. contacts density). The upstream 50 kb region does not include the upstream 5 kb region. That is,`
              description_2 = ' "up50" = [up50 kb; up5 kb)'
              description_3 = ' or just 45,000 bp.'
            } else if (row.label === "up5") {
              description_1 = `upstream 5 kb contacts density.`
              description_2 = ' "up5" = [up5 kb; GeneBody)'
              description_3 = ' or just 5,000 bp.'
            } else if (row.label === "GeneBody") {
              description_1 = `region of the gene body that matches the coordinates from the `
              description_2 = '"Gene location"'
            } else if (row.label === "down5") {
              description_1 = `downstream 5 kb region. That is,`
              description_2 = ' "down5" = (GeneBody; down5 kb]'
              description_3 = ' or just 5,000 bp.'
            } else {
              description_1 = 'downstream 50 kb region that does not include the downstream 5 kb region. That is,'
              description_2 = ' "down50" = (down5 kb; down50 kb]'
              description_3 = ' or just 45,000 bp.'
            }
            return (
              <Tooltip
                  placement={"right-start"}
                  title={
                  <React.Fragment>
                      <div >
                      <Typography>
                          <b>{row.label}</b> — {description_1}<b>{description_2}</b>{description_3}
                      </Typography>
                      </div>
                  </React.Fragment>
                  }
              >
                <TableCell
                  className={classes.sizeSmall}
                  style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5', whiteSpace: wrap}} 
                  key={row.id}
                  align={row.numeric ? 'right' : 'center'}
                  padding={row.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === row.id ? order : false}
                >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={orderBy === row.id ? order : 'asc'}
                      onClick={createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                </TableCell>
              </Tooltip>
            );
          } else {
            return (
              <TableCell
                className={classes.sizeSmall}
                style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5', whiteSpace: wrap}} 
                key={row.id}
                align={row.numeric ? 'right' : 'center'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={orderBy === row.id ? order : 'asc'}
                    onClick={createSortHandler(row.id)}
                  >
                     {row.label}
                  </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

// BasicTableRNAHead.propTypes = {
//   // numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   order: PropTypes.string.isRequired,
//   orderBy: PropTypes.string.isRequired,
//   // rows: PropTypes.string.isRequired,
//   // rowCount: PropTypes.number.isRequired,
// };


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  title: {
    flex: '0 0 auto',
  },
  experiments_selected: {
    textAlign: 'left',
    // marginTop: theme.spacing(),
    whiteSpace: 'nowrap',
  },
  rootToolbar: {
    paddingRight: theme.spacing(),
  },
  // container: {
  //   // maxHeight: window.innerHeight*0.49,
  //   maxHeight: window.innerHeight*0.7,
  // },
  containerRfomDna : {
    maxHeight: window.innerHeight*0.4,
  },
  table: {
    minWidth: 50,
  },
  tableWrapper: {
    // overflowX: 'auto',
    margin: '0 auto',
    maxWidth: '100%',
    '& table:first-child': {
        '& tr': {
            '& td:nth-child(1), td:nth-child(1)': {
                backgroundColor: 'white',
                position: 'sticky',
                left: 0,
                zIndex: 99
            },
            '& th:nth-child(1)': {
                zIndex: 999
            },
        }
    },
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  sizeSmall: {
    padding: '0px 5px 0px 5px',
  },
  sizeSmall_chackBox: {
    padding: '1px 2px 3px 4px',
  },
  wideButton: {
    marginTop: 5,
    marginBottom: 5,
    // padding: 5,
    width: '80%',
    // height: 20, //'10%'
    // textAlign: 'center',
  },
}));


const TableAllTargetGenes = (props) => {

  const { extraParams, rows } = props;
  const [order,setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('norm_density_up5')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const [currentRnaListID, setCurrentRnaListID] = useState('')


  useEffect(() => {
    if (props.rnaListID.length > 0 && props.rnaListID !== "___") {
      // debugger
      setCurrentRnaListID(props.rnaListID)
      // let locusFilter = []
      // if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
      //     locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
      // }
      // props.submitAllTargetGenesTable({
      //   'selected_RNA': props.selected_RNA,
      //   'selectedNormalization': props.selectedNormalization,
      //   'experiment_id': props.experiment_id,
      //   'rnaListID': props.currentRnaListID,
      //   'organism': props.currentOrganism,
      //   'page' : page, 
      //   'rowsPerPage': rowsPerPage, 
      //   'order': order,
      //   'orderBy': orderBy,
      //   'from': extraParams['from'].map(n => n['value']), 
      //   'gene_type': extraParams['gene_type'].map(n => n['value']), 
      //   'gene_name': extraParams['gene_name'].map(n => n['value']),
      //   'SearchGeneName': extraParams['SearchGeneName'],
      //   'LocusCoordinatesForRNAs': locusFilter,
      //   'LocusCoordinatesForDNA': props.all_insert, //заход от ДНК, ДНК-фильтр
      // })
    }
  }, [props.rnaListID])  

  useEffect(() => {
    if (currentRnaListID.length > 0) {
      let ID = makeID(15)
      // debugger
      let locusFilter = []
      if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
          locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
      }
      props.submitAllTargetGenesTable({
        'selected_RNA': props.selected_RNA,
        'selectedNormalization': props.selectedNormalization,
        'experiment_id': props.experiment_id,
        'rnaListID': ID,
        'organism': props.currentOrganism,
        'page' : page, 
        'rowsPerPage': rowsPerPage, 
        'order': order,
        'orderBy': orderBy,
        'from': extraParams['from'].map(n => n['value']), 
        'gene_type': extraParams['gene_type'].map(n => n['value']), 
        'gene_name': extraParams['gene_name'].map(n => n['value']),
        'SearchGeneName': extraParams['SearchGeneName'],
        'LocusCoordinatesForRNAs': locusFilter,
        'LengthForRNAs': extraParams['LengthForRNAs'],
        'minDensityUp50kb': extraParams['minDensityUp50kb'],
        'minDensityUp5kb': extraParams['minDensityUp5kb'],
        'minDensityGeneBody': extraParams['minDensityGeneBody'],
        'minDensityDown5kb': extraParams['minDensityDown5kb'],
        'minDensityDown50kb': extraParams['minDensityDown50kb'],
        'LocusCoordinatesForDNA': props.all_insert, //заход от ДНК, ДНК-фильтр
      })
      setCurrentRnaListID(ID)
    }
  }, [props.selectedNormalization])  

// console.log('props.selectedNormalization ++++++++=',props.selectedNormalization)
// console.log('currentRnaListID',currentRnaListID)


  useEffect(() => {
    if (currentRnaListID.length > 0) {
      setPage(0)
      if (Object.keys(props.allTargetGenesTableData).length !== 0) {
        let locusFilter = []
        if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
            locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
        }
        props.submitAllTargetGenesTable({
          'selected_RNA': props.selected_RNA,
          'selectedNormalization': props.selectedNormalization,
          'experiment_id': props.experiment_id,
          'rnaListID': currentRnaListID,
          'organism': props.currentOrganism,
          'page' : 0, 
          'rowsPerPage': rowsPerPage, 
          'order': order,
          'orderBy': orderBy,
          'from': extraParams['from'].map(n => n['value']), 
          'gene_type': extraParams['gene_type'].map(n => n['value']), 
          'gene_name': extraParams['gene_name'].map(n => n['value']),
          'SearchGeneName': extraParams['SearchGeneName'],
          'LocusCoordinatesForRNAs': locusFilter, //extraParams['LocusCoordinatesForRNAs'],
          'LengthForRNAs': extraParams['LengthForRNAs'],
          'minDensityUp50kb': extraParams['minDensityUp50kb'],
          'minDensityUp5kb': extraParams['minDensityUp5kb'],
          'minDensityGeneBody': extraParams['minDensityGeneBody'],
          'minDensityDown5kb': extraParams['minDensityDown5kb'],
          'minDensityDown50kb': extraParams['minDensityDown50kb'],
          'LocusCoordinatesForDNA': props.all_insert, //заход от ДНК, ДНК-фильтр
        })
      }
    }
  }, [order,orderBy])


  useEffect(() => {
    if (currentRnaListID.length > 0) {
      if (Object.keys(props.allTargetGenesTableData).length !== 0) {
        let locusFilter = []
        if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
            locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
        }
        props.submitAllTargetGenesTable({
          'selected_RNA': props.selected_RNA,
          'selectedNormalization': props.selectedNormalization,
          'experiment_id': props.experiment_id,
          'rnaListID': currentRnaListID,
          'organism': props.currentOrganism,
          'page' : page, 
          'rowsPerPage': rowsPerPage, 
          'order': '',
          'orderBy': '',
          'from': extraParams['from'].map(n => n['value']), 
          'gene_type': extraParams['gene_type'].map(n => n['value']), 
          'gene_name': extraParams['gene_name'].map(n => n['value']),
          'SearchGeneName': extraParams['SearchGeneName'],
          'LocusCoordinatesForRNAs': locusFilter,
          'LengthForRNAs': extraParams['LengthForRNAs'],
          'minDensityUp50kb': extraParams['minDensityUp50kb'],
          'minDensityUp5kb': extraParams['minDensityUp5kb'],
          'minDensityGeneBody': extraParams['minDensityGeneBody'],
          'minDensityDown5kb': extraParams['minDensityDown5kb'],
          'minDensityDown50kb': extraParams['minDensityDown50kb'],
          'LocusCoordinatesForDNA': props.all_insert, //заход от ДНК, ДНК-фильтр
        })
      }
    }
  }, [page, rowsPerPage])
  

  useEffect(() => {
    if (currentRnaListID.length > 0) {
      setPage(0)
      if (Object.keys(props.allTargetGenesTableData).length !== 0) {
        let locusFilter = []
        if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
            locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
        }
        props.submitAllTargetGenesTable({
          'selected_RNA': props.selected_RNA,
          'selectedNormalization': props.selectedNormalization,
          'experiment_id': props.experiment_id,
          'rnaListID': currentRnaListID,
          'organism': props.currentOrganism,
          'page' : 0, 
          'rowsPerPage': rowsPerPage, 
          'order': '',
          'orderBy': '',
          'from': extraParams['from'].map(n => n['value']), 
          'gene_type': extraParams['gene_type'].map(n => n['value']), 
          'gene_name': extraParams['gene_name'].map(n => n['value']),
          'SearchGeneName': extraParams['SearchGeneName'],
          'LocusCoordinatesForRNAs': locusFilter,
          'LengthForRNAs': extraParams['LengthForRNAs'],
          'minDensityUp50kb': extraParams['minDensityUp50kb'],
          'minDensityUp5kb': extraParams['minDensityUp5kb'],
          'minDensityGeneBody': extraParams['minDensityGeneBody'],
          'minDensityDown5kb': extraParams['minDensityDown5kb'],
          'minDensityDown50kb': extraParams['minDensityDown50kb'],
          'LocusCoordinatesForDNA': props.all_insert, //заход от ДНК, ДНК-фильтр
        })
      }
    }
  }, [extraParams])

  const classes = useStyles();

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(parseInt(value, 10))
    setPage(0);
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc =  orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  }
  // console.log('allTargetGenesTableData',props.allTargetGenesTableData)

  const handleClick = (event, gene_name) => {
    // console.log('gene_name',gene_name)
    const data = {
          'gene_name': gene_name, //props.selectedId,
          // 'typeOfContacts': 'props.typeOfContacts',
          'organism': props.currentOrganism,
          // 'selectedNormalization': 'props.trackListCheckbox',
        } 
    localStorage.setItem("from_all_target_genes",  JSON.stringify(data));
    window.open("/from_dna", "_blank");
  //   if (props.allTargetGenesTableDataFromDNA['all_insert'].length !== 0) {
  //     const data_2 = {
  //       selectedTracks: id, //props.selectedId,
  //       typeOfContacts: props.typeOfContacts,
  //       selectedTrackNames: props.currentOrganism + "____" + props.allTargetGenesTableDataFromDNA['all_insert'],
  //       selectedNormalization: props.trackListCheckbox,
  //     }
  //     localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
      
  //     const data = {
  //           'selectedId': id, //props.selectedId,
  //           'typeOfContacts': props.typeOfContacts,
  //           'organism': props.currentOrganism + "____" + props.allTargetGenesTableDataFromDNA['all_insert'],
  //           'selectedNormalization': props.trackListCheckbox,
  //         } 
  //     console.log('QWJKJKOFKJ!!!!!!!!!!! DATA', data)
  //     localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
  //     window.open("/basic_graphical_summary", "_blank");
  //   } else {
  //     const data_2 = {
  //       selectedTracks: id, //props.selectedId,
  //       typeOfContacts: props.typeOfContacts,
  //       selectedTrackNames: props.currentOrganism,//props.selectedItemOrganism[0]['value'], //currentOrganism
  //       selectedNormalization: props.trackListCheckbox,
  //     }
  //     localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
      
  //     const data = {
  //           'selectedId': id, //props.selectedId,
  //           'typeOfContacts': props.typeOfContacts,
  //           'organism': props.currentOrganism, //props.selectedItemOrganism[0]['value'], //currentOrganism
  //           'selectedNormalization': props.trackListCheckbox,
  //         } 
  //     console.log('QWJKJKOFKJ!!!!!!!!!!! DATA 2', data)
  //     localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
  //     window.open("/basic_graphical_summary", "_blank");
  //   }
  };

  let table = null;
  let column = null;
  let rows_id = rows.map(n => n['id'])
  
  if (Object.keys(props.allTargetGenesTableData).length !== 0) { //if (Object.keys(props.allTargetGenesTableData).length !== 0) {
    table = 
            <div className={classes.root}>
            <div className={classes.tableWrapper}>
            <TableContainer className={classes.containerRfomDna}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={'small'}>
              <BasicTableRNAHead
                // numSelected={selected.length}
                rows={rows}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                // rowCount={data.length}
              />
              <TableBody>
                  {/* {props.allTargetGenesTableData['data'].map(n => { */}
                  {props.allTargetGenesTableData['data'].map((n, index) => {
                    // console.log('n.id',index)
                    return (
                      <TableRow 
                        hover 
                        // role="checkbox" 
                        tabIndex={-1} 
                        key={index} 
                        size='small'
                        className={classes.sizeSmall}
                      >
                        {rows_id.map((key, indexR) => {
                          if (key === 'gene_name') {
                            column=
                            <TableCell 
                              key={index*1000}
                              className={classes.sizeSmall} 
                              size='small' 
                              align="center" 
                              style={{
                                fontSize: 15,
                                textAlign: 'left',
                                whiteSpace: 'nowrap', 
                                border: '1px solid #d3d4d5',
                                borderTop: '1px solid #d3d4d5',
                                borderLeft: '1px solid #d3d4d5',}} 
                            >
                              {/* {n.gene_name} */}
                              <Tooltip title="What RNAs interact with this target gene?" >
                                <Link component="button" onClick={event => handleClick(event, n.gene_name)}>{n.gene_name}</Link>
                              </Tooltip>
                            </TableCell>
                          } else {
                            column=
                              <TableCell 
                                className={classes.sizeSmall} 
                                key={indexR*100}
                                size='small' 
                                align="left" 
                                style={{fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} //whiteSpace: 'nowrap',
                              >
                                {n[key]}
                              </TableCell>
                          }
                          return (
                            column
                          )
                        })}                        
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              </TableContainer>
            </div>
            <RnaListPagination
                    rowsPerPageOptions={[25, 50, 100]}
                    // count={props.allTargetGenesTableData['LEN']}
                    count={props.allTargetGenesTableData['LEN']}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            {/* </Paper> */}
          </div>
    }
  // }, [props.allTargetGenesTableData['data']])


  return (
    <div>
      {!props.loadingAllTargetGenesTable ? //&& Object.keys(props.allTargetGenesTableData).length !== 0
        <div className={classes.paper}>
            {table}
        </div>
        : 
        <div className={classes.loading}>
            <LinearProgress color={"primary"}/>
            <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
            </Typography>
        </div>
      }
      <WarningMessage open={props.snackOpenAllTargetGenesTable} severity={"error"} message={props.snackTextAllTargetGenesTable} />
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    selectedId: state[moduleBasicAnalysis].selectedId,
    // typeOfContacts: state[module].typeOfContacts,
    // trackListCheckbox: state[moduleFilter].trackListCheckbox,

    // selectedItemOrganism: state[module].selectedItemOrganism,

    allTargetGenesTableData: state[module].allTargetGenesTableData,
    // loadingBasicGeneTable: state[moduleBasicAnalysis].loadingAllTargetGenesTable,
    // snackOpenBasicGeneTable: state[moduleBasicAnalysis].snackOpenAllTargetGenesTable,
    // snackTextBasicGeneTable: state[moduleBasicAnalysis].snackTextAllTargetGenesTable,

    // basicGeneTableDataFromDNA: state[moduleBasicAnalysis].allTargetGenesTableDataFromDNA,
  };
};


export default connect(mapStateToProps, { fetchBasicGeneTable })(TableAllTargetGenes);//selectOne,