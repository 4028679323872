import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Decorator from '../components/HtmlTooltip';
import Divider from '@material-ui/core/Divider';
// import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
// import PersonIcon from '@material-ui/icons/Person';
// import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { blue } from '@material-ui/core/colors';
import LinearProgress from '@material-ui/core/LinearProgress';

import RNApartProfile from '../components/plots/RNA_list_profile';
import TableMetadata from '../components/TableMetadata';
import WarningMessage from './MessageSnackbar';
import TrackListCheckboxes from '../components/TrackListCheckboxes';
import TableAllTargetGenes from '../components/TableAllTargetGenes';
import RNAAutocomplete from '../components/RNAAutocomplete';
import InputTwoFromDNA from '../components/InputTwoFromDNA';
import InputThree from '../components/InputThree';

import { submitCheckedTracksForRNApartProfile, submitCheckedTracksForRNApartProfileTable, submitAllTargetGenesTable} from '../actions/plotsActions';
import { submitCheckedTracksRnaPart, clearGenomeBrowserRnaPartURL} from '../actions/genomeBrowserActions';
import { textChangeSearchGeneName, SendListGeneType, SendListAnnotationSource, SendListGeneName } from '../actions/generalActions';
import { textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA } from '../actions/generalActions';

const module = "plots"
const moduleGenomeBrowser = "genomeBrowser";
const moduleFilter = "filter";
const moduleTable = "table";
const moduleText = "text";

const makeID = (length) => {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const rows_allTargetGenesTable = [
  {id:'gene_name', numeric: false, disablePadding: false, label: 'Target gene' },
  {id:'gene_type', numeric: false, disablePadding: true, label: 'Gene type'},
  {id:'locus', numeric: false, disablePadding: true, label: 'Gene locus'},
  {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
  {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
  {id:'norm_density_up50', numeric: false, disablePadding: true, label: 'up50'}, //'[up50;up5)'
  {id:'norm_density_up5', numeric: false, disablePadding: true, label: 'up5'}, //'[up5;GeneBody)'
  {id:'norm_density_gene_body', numeric: false, disablePadding: true, label: 'GeneBody'}, 
  {id:'norm_density_down5', numeric: false, disablePadding: true, label: 'down5'}, //'(GeneBody;down5]'
  {id:'norm_density_down50', numeric: false, disablePadding: true, label: 'down50'}, //'(down5;down50]'
  {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},
];

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    paddingLeft: theme.spacing(),
  },
  dialog: {
    maxWidth: '100%',
    // width: '1000px'
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  loadingGB: {
    marginTop: 10,
    textAlign: 'center',
  },
  wideButton: {
    padding: theme.spacing(),
    width: '100%',
    height: '100%',
    // color: 'white',
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: theme.spacing(),
  },
  locationCenter: {
    margin: '0 auto',
  },
  autocomplete: {
    flexGrow: 1,
    paddingTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const SimpleDialogDemo = (props) => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [RNAdataTable, setRNAdataTable] = useState([])
  const [buttonColor, setButtonColor] = useState('#bdbdbd')
  const [buttonTextColor, setButtonTextColor] = useState('black')

  const [extraParams, setExtraParams] = useState({'from': [], 'gene_type': [], 'gene_name': [], 'SearchGeneName': '', 'LocusCoordinatesForRNAs': ''})
  const [currentRnaListID, setCurrentRnaListID] = useState('')
  const [geneName, setGeneName] = useState([]);
  const [geneNameInput, setGeneNameInput] = useState('');
  const [geneType, setGeneType] = useState([]);
  const [geneTypeInput, setGeneTypeInput] = useState('');
  const [annotationSource, setAnnotationSource] = useState([]);
  const [annotationSourceInput, setAnnotationSourceInput] = useState('');
  const [message, setMessage] = useState('')

  useEffect(() => {
      if (open === true) {
        props.submitCheckedTracksForRNApartProfileTable(props.RnaSelected, 'DNA_part___fromRnaList', props.selectedTrackNames, props.trackListCheckbox)
        props.submitCheckedTracksForRNApartProfile(props.RnaSelected, 'DNA_part___fromRnaList', props.selectedTrackNames, props.trackListCheckbox)
        let ID = makeID(15)
        props.submitAllTargetGenesTable({
          'selected_RNA': props.gene_name,
          'selectedNormalization': props.trackListCheckbox,
          'experiment_id': props.experiment_id,
          'rnaListID': ID,
          'organism': props.organism,
          'page' : 0, 
          'rowsPerPage': 25, 
          'order': 'asc',
          'orderBy': '',
          'from': [], 
          'gene_name': [],
          'gene_type': [], 
          'SearchGeneName': "",
          'LocusCoordinatesForRNAs': "",
          'LocusCoordinatesForDNA': props.all_insert, //заход от ДНК, ДНК-фильтр
        })
        setCurrentRnaListID(ID)
      }
  }, [open])

  useEffect (() => {
    if (Object.keys(props.allTargetGenesTableData).length !== 0 && Object.keys(props.allTargetGenesTableData['description']).length !== 0) {
      props.SendListGeneName(props.allTargetGenesTableData['description']['gene_name'])
      props.SendListGeneType(props.allTargetGenesTableData['description']['gene_type'])
      props.SendListAnnotationSource(props.allTargetGenesTableData['description']['from'])
      // setCurrentFilterContactsLocus(props.basicGeneTableDataFromDNA['all_insert'])
    } else {
      props.SendListGeneName([])
      props.SendListGeneType([])
      props.SendListAnnotationSource([])
    }

  }, [props.allTargetGenesTableData])

  useEffect(() => {
    if (open === true) {
      // console.log('wfwfwfwf!!!!!!!!!!!!!!!!!!!!')
      props.submitCheckedTracksForRNApartProfile(props.RnaSelected, 'DNA_part___fromRnaList', props.selectedTrackNames, props.trackListCheckbox)
      let ID = makeID(15)
      // props.submitAllTargetGenesTable({
      //   'selected_RNA': props.gene_name,
      //   'selectedNormalization': props.trackListCheckbox,
      //   'experiment_id': props.experiment_id,
      //   'rnaListID': ID,
      //   'organism': props.organism,
      //   'page' : 0, 
      //   'rowsPerPage': 25, 
      //   'order': 'asc',
      //   'orderBy': '',
      //   'from': [], 
      //   'gene_name': [],
      //   'gene_type': [], 
      //   'SearchGeneName': "",
      //   'LocusCoordinatesForRNAs': "",
      //   'LocusCoordinatesForDNA': props.all_insert, //заход от ДНК, ДНК-фильтр
      // })
      setCurrentRnaListID(ID)
    }
  }, [props.trackListCheckbox])  


  useEffect(() => {
    if (props.geneRNAdataProfileTable.length !== 0) {
      if (Object.keys(props.geneRNAdataProfileTable).indexOf('cpkm_filter_DNA') !== -1) {
        setRNAdataTable(
          [{"Name": "Gene name", "Description": props.gene_name},
          {"Name": "Gene type", "Description": props.geneRNAdataProfileTable['gene_type']},
          {"Name": "Track name", "Description": props.track_name},
          {"Name": "Data set ID", "Description": props.experiment_id},
          {"Name": "N-obs (all observed contacts)", "Description": props.geneRNAdataProfileTable['sum_observed_value']},
          {"Name": "N-obs (DNA filter)", "Description": props.geneRNAdataProfileTable['sum_observed_value_filter_DNA']},
          {"Name": "CPKM (all observed contacts)", "Description": props.geneRNAdataProfileTable['cpkm_observed']},
          {"Name": "CPKM (N-obs + DNA filter)", "Description": props.geneRNAdataProfileTable['cpkm_observed_filter_DNA']},
          // {"Name": "N-exp (all contacts)", "Description": props.geneRNAdataProfileTable['sum_expected_value']},
          // {"Name": "N-exp (filter DNA)", "Description": props.geneRNAdataProfileTable['sum_expected_value_filter_DNA']},
          {"Name": "N-norm (all normalized contacts)", "Description": props.geneRNAdataProfileTable['sum_value']},
          {"Name": "N-norm (DNA filter)", "Description": props.geneRNAdataProfileTable['sum_value_filter_DNA']},
          {"Name": "CPKM (all normalized contacts)", "Description": props.geneRNAdataProfileTable['cpkm']},
          {"Name": "CPKM (N-norm + DNA filter)", "Description": props.geneRNAdataProfileTable['cpkm_filter_DNA']},
          // {"Name": "Normalization", "Description": props.selectedNormalization},
          {"Name": "Gene locus", "Description": props.geneRNAdataProfileTable['chr_ID'] + ":" + props.geneRNAdataProfileTable['start'] + "-" + props.geneRNAdataProfileTable['end']},
          {"Name": "Strand", "Description": props.geneRNAdataProfileTable['strand']},
          {"Name": "Length", "Description": props.geneRNAdataProfileTable['len']},
          {"Name": "Organism", "Description": props.organism},
          {"Name": "Genome", "Description": props.geneRNAdataProfileTable['build']},
          {"Name": "Annotation source", "Description": props.geneRNAdataProfileTable['from']}]
        )
      } else {
        setRNAdataTable(
          [{"Name": "Gene name", "Description": props.gene_name},
          {"Name": "Gene type", "Description": props.geneRNAdataProfileTable['gene_type']},
          {"Name": "Track name", "Description": props.track_name},
          {"Name": "Data set ID", "Description": props.experiment_id},
          {"Name": "N-obs", "Description": props.geneRNAdataProfileTable['sum_observed_value']},
          {"Name": "CPKM (N-obs)", "Description": props.geneRNAdataProfileTable['cpkm_observed']},
          // {"Name": "N-exp", "Description": props.geneRNAdataProfileTable['sum_expected_value']},
          {"Name": "N-norm", "Description": props.geneRNAdataProfileTable['sum_value']},
          {"Name": "CPKM (N-norm)", "Description": props.geneRNAdataProfileTable['cpkm']},
          // {"Name": "Normalization", "Description": props.selectedNormalization},
          {"Name": "Gene locus", "Description": props.geneRNAdataProfileTable['chr_ID'] + ":" + props.geneRNAdataProfileTable['start'] + "-" + props.geneRNAdataProfileTable['end']},
          {"Name": "Strand", "Description": props.geneRNAdataProfileTable['strand']},
          {"Name": "Length", "Description": props.geneRNAdataProfileTable['len']},
          {"Name": "Organism", "Description": props.organism},
          {"Name": "Genome", "Description": props.geneRNAdataProfileTable['build']},
          {"Name": "Annotation source", "Description": props.geneRNAdataProfileTable['from']}]
        )
      }
    }
}, [props.geneRNAdataProfileTable])

  useEffect(() => {
    if (Object.keys(props.geneRNAdataProfile).length > 0) {
      // debugger
      // if (props.geneRNAdataProfile.result.x.length === 1) {
      //   setButtonDisabled(true)
      //   setButtonColor('#bdbdbd')
      //   setButtonTextColor('black')
      // } else {
        setButtonDisabled(false)
        setButtonColor('#96000E')
        setButtonTextColor('white')
      // }
    }
  }, [props.geneRNAdataProfile])
  
  const handleClickOpen = () => {
    if (props.gene_name.split(' ')[1] !== 'RNAs') {
      setOpen(true);
    } else {
      setOpenMessage(true)
      setTextMessage("Sorry, for this data set (multi-copy RNAs) we can't plot RNA-parts distribution")
    }
  };

  const handleCloseMessage = () => {
    setOpenMessage(false)
  }

  const handleClose = value => {
    setOpen(false);
  };

  const handleClick = (e) => { 
    debugger
    // if (props.geneRNAdataProfile.result.x.length > 1) {
      props.submitCheckedTracksRnaPart(
        props.RnaSelected,
        'RNA_part___fromRnaList',
        props.selectedTrackNames, 
        props.trackListCheckbox
      )
    // }
  };

  useEffect(() => {
    // console.log('props.URLtoGenomeBrowserRnaPart',props.URLtoGenomeBrowserRnaPart)
    if (props.URLtoGenomeBrowserRnaPart !== '') {
      // console.log('OpenSimple')
      openRequestedPopup()
    }
  }, [props.URLtoGenomeBrowserRnaPart])
    

  const openRequestedPopup = () => {
    let empty_tracks = ''
    let i = []
    let url_massege = ''
    if (props.URLtoGenomeBrowserRnaPart.split("|||").length === 1) {
      i = props.URLtoGenomeBrowserRnaPart.split("_____")
      url_massege = 'Otherwise manually copy and paste these links: ' + i.toString()
    } else {
      i = props.URLtoGenomeBrowserRnaPart.split("|||")[0].split("_____")
      if (i !== '') {
        url_massege = 'Otherwise manually copy and paste these links: ' + i.toString()
      }
      empty_tracks = props.URLtoGenomeBrowserRnaPart.split("|||")[1]
      empty_tracks = "       (Tracks: " + empty_tracks + " — don't have contacts with chromatin)"
    }
    for (var k in i) {
      // console.log('k',i)
      if (i[k] !== '') {
        var newWin = window.open(i[k])
        if (!newWin || newWin.closed || typeof newWin.closed=='undefined') { 
          alert('You need to allow pop-up windows to open. ' + url_massege + empty_tracks)
          break;
        } else {
          newWin.focus()
        }
      }
    }
    if (empty_tracks !== '') {
      alert(empty_tracks)
    }
    props.clearGenomeBrowserRnaPartURL()
  }

  const handleGeneName = (gt) => {
    setGeneName(gt);
  };

  const handleGeneNameInput = (value) => {
    setGeneNameInput(value);
  };
  
  const handleGeneType = (gt) => {
    setGeneType(gt);
  };

  const handleGeneTypeInput = (value) => {
    setGeneTypeInput(value);
  };

  const handleAnnotationSource = (gt) => {
    setAnnotationSource(gt);
  };

  const handleAnnotationSourceInput = (value) => {
    setAnnotationSourceInput(value);
  };

  const handleClickApplyFromDNA = (e,id) => {   
    if (props.locusWarningFromDNA === false) { 
      setExtraParams({'from': annotationSource, 
                      'gene_type': geneType, 
                      'gene_name': geneName,
                      'SearchGeneName': props.valueSearchGeneName, 
                      'LocusCoordinatesForRNAs': props.currentLocusFromDNA
                    })
    } else {
      setMessage("Locus coordinates were entered in the wrong format");
      setOpen(true)
    }
  };


  return (
    <div className={classes.root}>
        <Link component="button" color="primary"  onClick={handleClickOpen}>{props.title}</Link>
        {/* <SimpleDialog open={open} onClose={handleClose} /> */}
        <Dialog 
          onClose={handleClose} 
          open={open} 
          className={classes.dialog}
          fullWidth={true}
          maxWidth={'lg'} //'sm' || 'md' || 'lg' || 'xl'
        >
          <DialogTitle id="simple-dialog-title" style={{ padding: 10, textAlign: 'center' }}>
          
            <Typography variant="h4" style={{textAlign: 'center'}}>
                All target genes
            </Typography>
            <Grid container spacing={0}>
            <Grid item xs={2}>
                    <div>
                      <RNAAutocomplete
                            nameClass={classes.autocomplete}
                            disabled={props.loadingAllTargetGenesTable}
                            suggestions={props.BasicTableGeneName}
                            handleChange={handleGeneName}
                            selectedItems={geneName}
                            currentInput={geneNameInput}
                            onInputChange={handleGeneNameInput}
                            title={"Target gene"}
                        />
                        <RNAAutocomplete
                            nameClass={classes.autocomplete}
                            disabled={props.loadingAllTargetGenesTable}
                            suggestions={props.RnaListGeneType}
                            handleChange={handleGeneType}
                            selectedItems={geneType}
                            currentInput={geneTypeInput}
                            onInputChange={handleGeneTypeInput}
                            title={"Gene type"}
                        />
                        <RNAAutocomplete
                            nameClass={classes.autocomplete}
                            disabled={props.loadingAllTargetGenesTable}
                            suggestions={props.BasicTableAnnotationSource}
                            handleChange={handleAnnotationSource}
                            selectedItems={annotationSource}
                            currentInput={annotationSourceInput}
                            onInputChange={handleAnnotationSourceInput}
                            title={"Annotation source"}
                        />
                        <div>
                            <InputThree 
                                nameSelect='Serch by "Target gene"'
                                textChange={props.textChangeSearchGeneName}
                                valueLocus={props.valueSearchGeneName}
                            />
                        </div>
                        <div>
                            <Decorator 
                                key = {1111111}
                                text = {<Typography color="inherit">Enter coordinates of <b>gene loci</b> to get all genes from them</Typography>}
                                component = {
                                <div>
                                    <InputTwoFromDNA 
                                    nameSelect='chr4; chr2:10000-23000'
                                    textChange={props.textChangeFromDNA}
                                    valueLocus={props.valueLocusFromDNA}
                                    currentOrganism={props.organism}
                                    locusWarningChangeFalse={props.locusWarningChangeFalseFromDNA}
                                    locusWarningChangeTrue={props.locusWarningChangeTrueFromDNA}
                                    currentLocusChange={props.currentLocusChangeFromDNA}
                                    />
                                </div>
                                }
                            />
                        </div>
                        <div style={{textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                            <Button
                              disabled={props.loadingAllTargetGenesTable}
                              className={classes.button}
                              variant="contained"
                              size="small"
                              color={"secondary"}
                              onClick={e => handleClickApplyFromDNA(e, 'igfdsfghjk')}
                            >
                                Table filter apply
                            </Button>
                        </div>
                    </div>
            </Grid>
            <Grid item xs={10}>
              <TableAllTargetGenes 
                extraParams={extraParams} 
                rows={rows_allTargetGenesTable}
                allTargetGenesTableData={props.allTargetGenesTableData}
                loadingAllTargetGenesTable={props.loadingAllTargetGenesTable}
                snackOpenAllTargetGenesTable={props.snackOpenAllTargetGenesTable}
                snackTextAllTargetGenesTable={props.snackTextAllTargetGenesTable}
                submitAllTargetGenesTable={props.submitAllTargetGenesTable}
                selected_RNA={props.gene_name}
                // currentLocusCoordinatesForDNA={'currentLocusCoordinatesForDNA'}
                currentOrganism={props.organism}
                currentRnaListID={currentRnaListID}
                selectedNormalization={props.trackListCheckbox}
                experiment_id={props.experiment_id}
                all_insert={props.all_insert}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
















            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography variant="h4">RNA-parts of contacts profile</Typography>
              </Grid>
              <Grid item xs={6}>
                <div style={{textAlign: 'left'}}> 
                  <TrackListCheckboxes disabled={props.loadingGB || props.loadingRNApartData || props.loadingRNApartDataTable || props.loadingAllTargetGenesTable}/>
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
            {handleClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              ) 
            : 
              null
            }
            <Grid container spacing={0} className={classes.root}>
              <Grid item xs={8} className={classes.locationCenter}>
                <List style={{paddingTop: 0}}>
                  {props.loadingRNApartData === true ? 
                    <div className={classes.loading}>
                      <LinearProgress color={"primary"} />
                      <Typography variant="h5">
                        Please, wait a while, it may take from a few seconds to a couple of minutes
                      </Typography>
                    </div>
                  :
                    <div >
                      {/* <RNApartProfile 
                        geneRNAdataProfile={props.geneRNAdataProfile} 
                        // locus={props.locus} 
                        gene_name={props.gene_name} 
                        organism={props.organism} 
                        experiment_id={props.experiment_id}/
                      > */}
                      <div className={classes.wideButton}>
                        {!props.loadingGB ?
                          <Button
                            disabled={buttonDisabled}
                            variant="contained"
                            // style={{backgroundColor: '#96000E', color: 'white'}} 
                            style={{backgroundColor: buttonColor, color: buttonTextColor}}
                            onClick={e => handleClick()}
                          >
                            Genome Browser
                          </Button>
                          :
                          <div className={classes.loadingGB}>
                            <LinearProgress color={"primary"} />
                              <Typography variant="subtitle1">
                                Please, wait a while
                              </Typography>
                          </div>
                        }
                      </div>
                      <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                        <ul>
                          <li>This bar chart displays the RNA-parts distribution of the selected RNA in the coordinates of the source gene. See details on the <a href="/tutorial#RNA-list" target="_blank" rel="noopener noreferrer">"Tutorial" page</a>.</li>
                        </ul>
                      </Typography>
                    </div>
                  }
              </List>
            </Grid>
            <Grid item xs={4} className={classes.locationCenter}>
              {props.loadingRNApartDataTable === true || RNAdataTable.length === 0 ? 
                  <div className={classes.loading}>
                      <LinearProgress color={"primary"} />
                      <Typography variant="h5">
                          Please, wait a while, it may take from a few seconds to a couple of minutes
                      </Typography>
                  </div>
              :
                <div style={{width: '100%', marginTop: 30}}>
                    <TableMetadata 
                      title={''} 
                      data={RNAdataTable} 
                      columns={["Name", "Description","RNA_profile"]}
                    />
                  </div>
                }
              </Grid>
            </Grid>





            
          <Typography variant={"subtitle1"} style={{marginLeft: 10,  padding: 5}}>
              If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
          </Typography>
          </Dialog>
        <WarningMessage open={props.snackOpenGenomeBrowser} severity={"error"} message={props.snackTextGenomeBrowser} />
        <WarningMessage open={openMessage} severity={"warning"} handleClose={handleCloseMessage} message={textMessage} />
    </div>
  );
}

const mapStateToProps = (state) => {
    return {
      geneRNAdataProfile: state[module].geneRNAdataProfile,
      geneRNAdataProfileTable: state[module].geneRNAdataProfileTable,
      loadingRNApartData: state[module].loadingRNApartData,
      loadingRNApartDataTable: state[module].loadingRNApartDataTable,

      URLtoGenomeBrowserRnaPart: state[moduleGenomeBrowser].URLtoGenomeBrowserRnaPart,
      loadingGB: state[moduleGenomeBrowser].loadingURLRnaPart,
      snackOpenGenomeBrowser: state[moduleGenomeBrowser].snackOpenGenomeBrowserRnaPart,
      snackTextGenomeBrowser: state[moduleGenomeBrowser].snackTextGenomeBrowserRnaPart,

      trackListCheckbox: state[moduleFilter].trackListCheckbox,

      allTargetGenesTableData: state[module].allTargetGenesTableData,
      snackOpenAllTargetGenesTable: state[module].snackOpenAllTargetGenesTable,
      snackTextAllTargetGenesTable: state[module].snackTextAllTargetGenesTable,
      loadingAllTargetGenesTable: state[module].loadingAllTargetGenesTable,

      RnaListGeneType: state[moduleTable].RnaListGeneType,
      BasicTableAnnotationSource: state[moduleTable].BasicTableAnnotationSource,
      BasicTableGeneName: state[moduleTable].BasicTableGeneName,
      valueSearchGeneName: state[moduleText].valueSearchGeneName,
      valueLocusFromDNA: state[moduleText].valueFromDNA,
      locusWarningFromDNA: state[moduleText].locusWarningFromDNA,
      currentLocusFromDNA: state[moduleText].currentLocusFromDNA,
    }
}

export default connect(mapStateToProps, {
  submitCheckedTracksRnaPart, 
  clearGenomeBrowserRnaPartURL, 
  submitCheckedTracksForRNApartProfile, 
  submitCheckedTracksForRNApartProfileTable,
  submitAllTargetGenesTable,
  textChangeSearchGeneName, SendListGeneType, SendListAnnotationSource, SendListGeneName,
  textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA,
                                        })(SimpleDialogDemo);