import React, { useState } from 'react'; //useEffect
// import { CSVDownload} from 'react-csv'; //CSVLink,
// import axios from 'axios';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CircularProgress from '@material-ui/core/CircularProgress';

// import Checkbox from '@material-ui/core/Checkbox';
// import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
// import Toolbar from '@material-ui/core/Toolbar';

// import ShowGraphics from '../components/ShowGraphics';
import WarningMessage from '../components/MessageSnackbar';
// import TrackListCheckboxes from '../components/TrackListCheckboxes';
import Link from '@material-ui/core/Link';

// import {trackListDelete, trackListSelect, trackNameDelete, trackNameSelect, indexForCellTypeSelect, cellTypeDelete} from '../actions/genesActions';
// import {changeDecreaseMaxNumberTrackName} from '../actions/filterActions';

// const module = "genes";
// const moduleFilter = "filter";

const EnhancedTableHead = (props) => {
    
    const { classes } = props;

    return (
            <TableHead className={classes.sizeSmall}>
                <TableRow size='small'>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>RNA name</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>RNA type</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Gene location</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Str.</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#bbdefb', color: 'black'}}>Target locus</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Length</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Organism</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Genome</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Ann.source</TableCell>
                    {typeof(props.experiment_id) === "undefined" ? 
                        null
                        :
                        <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Exp.ID</TableCell>
                    }
                </TableRow>
            </TableHead>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        // ...theme.mixins.gutters(),
        paddingTop: theme.spacing(),
        // paddingBottom: theme.spacing(),
        textAlign: 'center',
        flexGrow: 1,
        width: '100%',
    },
    track_list_selected: {
        textAlign: 'left',
        padding: 0,
        marginTop: theme.spacing(),
        // marginBottom: theme.spacing(),
        // paddingBottom: theme.spacing(4),
    },
    download: {
        textAlign: 'right',
        padding: 0,
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(),
        // paddingTop: 10
    },
    locationTop: {
        // paddingLeft: theme.spacing(),
        // marginTop: 10,
        // marginBottom: 10,
        paddingBottom: theme.spacing(),
        textAlign: 'center !important',
    },
    sizeSmall: {
        padding: '0px 5px 0px 5px',
    },
    stickyHeader: {
        width: '100%',
        'border-collapse': 'inherit',
    },
    // tableWrapper: {
    //     overflowX: 'auto',
    //     margin: '0 auto',
    //     maxWidth: '100%',
        
    // },
    container: {
        maxHeight: window.innerHeight*0.7,
        '& table:nth-child(1)': {
            '& tr': {
                '& td:nth-child(1), td:nth-child(1)': {
                    color: 'white',
                    backgroundColor: '#009688',
                    position: 'sticky',
                    left: 0,
                    zIndex: 99
                },
                '& th:nth-child(2)': {
                    zIndex: 999
                },
            },
            '& thead': {
                '& tr:nth-child(1)': {
                    '& th:nth-child(1), th:nth-child(1)': {
                        backgroundColor: '#009688',
                        color: 'white',
                    },
                },
                '& tr:nth-child(2)': {
                    '& th:nth-child(1), th:nth-child(1)': {
                        color: 'white',
                        backgroundColor: '#009688',
                        position: 'sticky',
                        left: 0,
                        zIndex: 10
                    },
                    '& th:nth-child(1)': {
                        zIndex: 11
                    },
                }
            }
        },
    },
}));


const RnaTableInfo = (props) => {

    const classes = useStyles();

    // const [rows, setRows] = useState([]);
    const [warningMessage, setWarningMessage] = useState('');
    const [warningMessageOpen, setWarningMessageOpen] = useState(false);

    const handleClose = () => {
        setWarningMessageOpen(false)
    }
    // console.log('dataForAllTargetGenes11 TT',props.track_description)
    // const remake_track_list_data = () => {
    //     console.log('remake_track_list_data, TableFromDna')
    //     let rows_array = []
    //     let target_locus = props.currentLocusCoordinatesForDNA
    //     let target_gene = props.currentGeneNameForDNA
    //     let gene_body = ''
    //     let upstream = props.currentValueUpstream
    //     let downstream = props.currentValueDownstream

    //     if (target_locus.length === 0 && target_gene.length === 0 && props.startAnalysis === 1) {
    //         target_locus = 'whole genome'
    //     }
    //     if (target_gene.length !== 0) {
    //         gene_body = props.geneBody === true ? 'Gene body: yes' : 'Gene body: no'
    //         upstream = upstream.length !== 0 ? "Upstream: " + upstream + " bp" : upstream = "Upstream: no"
    //         downstream = downstream.length !== 0 ? downstream = "Downstream: " + downstream + " bp" : downstream = "Downstream: no"
    //     }
        
    //     rows_array.push({
    //         organism: props.currentOrganism,
    //         targetLocus: target_locus,
    //         geneBody: gene_body,
    //         currentValueUpstream: upstream,
    //         currentValueDownstream: downstream
    //                 })
    //     setRows(rows_array)
    // }

    // useEffect(() => {
    //     remake_track_list_data()
    //   }, [props.currentGeneNameForDNA, props.currentValueUpstream, props.currentValueDownstream, props.currentLocusCoordinatesForDNA, props.currentOrganism, props.geneBody]);
    
    // const handleClick = (label) => { 
    //     if (props.expID_typeOfExperiment.indexOf('All vs all') !== -1) {
    //         let fullDescription = props.track_description.track_name.map(track_name => {
    //             return track_name.split("(")[1].split(")")[0] + "___" + track_name.split(": ")[1].split(" (")[0] + "&" + track_name + "^" + props.track_description[track_name].track_description
    //         })
    //         let fullExpId = props.track_description.track_name.map(track_name => {
    //             return track_name.split("ID: ")[1].split(" (")[0] 
    //         })
    //         // console.log('fullDescription', fullDescription)  
    //         const data = {
    //             selectedTracks: fullDescription,//['XIST___10&ID: 10 (XIST)^||Homo sapiens : [gene_name in (XIST, ) or gene_type in ()]&{LocusFilterDna in ()}{Gene Body: [full:false, gene_name in (), gene_types in ()]}{Upstream: [full:false, gene_name in (), gene_types in ()]}{Downstream: [full:false, gene_name in (), gene_types in ()]}{Upstream50kb: [full:false, gene_name in (), gene_types in ()]}{Downstream50kb: [full:false, gene_name in (), gene_types in ()]}{GeneDeserts: [full:false]}'],
    //             typeOfContacts: 'DNA_part___fromRnaList',
    //             selectedTrackNames: props.track_description.track_name, //['ID: 10 (XIST)'],
    //             selectedNormalization: 'norm_value',
    //             organism: props.currentOrganism,
    //             experiment_id: fullExpId,//props.experiment_id,
    //             gene_name: props.name,

    //             type: props.type,
    //             geneLocus: props.geneLocus,
    //             dnaFilter: props.dnaFilter,
    //             geneNameTarget: props.geneNameTarget,
    //             str: props.str,
    //             expID_typeOfExperiment: props.expID_typeOfExperiment,
    //             length: props.length,
    //             genome: props.genome,
    //             from: props.from,
    //           }
    //         //   debugger
    //         localStorage.setItem("data_for_rna_parts",  JSON.stringify(data));
    //         window.open("/basic_graphical_summary/rna_parts", "_blank");
    //     } else {
    //         setWarningMessage('Profile of RNA-parts of contacts can only be plotted from the "All-to-all" data')
    //         setWarningMessageOpen(true)
    //     }
    //   };
    const onClick = (id) => {
        // console.log('props.expID_typeOfExperiment',props.expID_typeOfExperiment[0])
        let DataType = ""
        if (props.expID_typeOfExperiment[0] === "One-to-all") {
            DataType = "One vs all"
        } else if (props.expID_typeOfExperiment[0] === "All-to-all") {
            DataType = "All vs all"
        } else {
            DataType = props.expID_typeOfExperiment[0]
        }
        const data = {
          selectedExperiment: [id],
          dataType: DataType
        }
        localStorage.setItem("metadata",  JSON.stringify(data));
        window.open("/metadata", "_blank");
    }
    
    
    let column = null;
    let table = 
        <div className={classes.locationTop}>
        {/* <Paper elevation={0} style={{border: '2px solid #009688'}} square> */}
            {/* <div >  */}
            {/* className={classes.tableWrapper} */}
                <TableContainer className={classes.container}>
                    <Table
                        className={classes.stickyHeader}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        // stickyHeader
                        aria-label="enhanced table"
                        style={{ width: "auto", tableLayout: "auto",}}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            experiment_id={props.experiment_id}
                            // geneTarget={props.currentGeneNameForDNA}
                        />
                        <TableBody>
                        {/* {rows.map((n, indexR) => { */}
                            {/* const isSelectedID = isSelected(row.description); */}
                            {/* return ( */}
                                <TableRow
                                    hover 
                                    tabIndex={-1} 
                                    className={classes.sizeSmall}
                                    // padding="none"
                                    key={1234}
                                >
                                    <TableCell 
                                        key={4325}
                                        className={classes.sizeSmall} 
                                        size='small' 
                                        align="center" 
                                        style={{fontSize: 15, 
                                            backgroundColor: 'white', 
                                            color: 'black',
                                            whiteSpace: 'nowrap',
                                            border: '1px solid #d3d4d5',
                                            borderTop: '1px solid #d3d4d5',
                                            borderLeft: '1px solid #d3d4d5',
                                        }}>
                                        <b>{props.name}</b>
                                    </TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        {props.type}
                                    </TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        {props.geneLocus}
                                    </TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        {props.str}
                                    </TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        <b>{props.geneNameTarget !== "" ? props.geneNameTarget + " (" + props.dnaFilter + ")" : props.dnaFilter === "" ? "whole genome" : props.dnaFilter}</b>
                                    </TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        {props.length}
                                    </TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        <i>{props.currentOrganism}</i>
                                    </TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        {props.genome}
                                    </TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        {props.from}
                                    </TableCell>
                                    {typeof(props.experiment_id) === "undefined" ? 
                                        null
                                        :
                                        <Tooltip
                                            placement={"left-start"}
                                            title={
                                                <React.Fragment>
                                                    <div>
                                                        <Typography>
                                                            experiment ID: <b>{props.track_description.split(", ")[0]}</b><br/>organism: <b>{props.track_description.split(", ")[1]}</b><br/>cell line: <b>{props.track_description.split(", ")[2]}</b><br/>exp.protocol: <b>{props.track_description.split(", ")[3]}</b><br/>extra: <b>{props.track_description.split(", ")[4]}</b>
                                                        </Typography>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        >
                                            <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                                <Link component="button" onClick={() => onClick(parseInt(props.experiment_id))}>{props.experiment_id}</Link>
                                            </TableCell>
                                        </Tooltip>
                                    }
                                    {/* <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15, whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
                                        {'all contacts'}
                                    </TableCell> */}
                                    {/* {Object.keys(row).map((key, index) => {
                                        if (key === "organism") {
                                            column=
                                                <TableCell 
                                                    key={index}
                                                    // padding="none" 
                                                    // size='small' 
                                                    align="center" 
                                                    className={classes.sizeSmall}
                                                    style={{fontSize: 15, 
                                                            //  
                                                            border: '1px solid #d3d4d5',
                                                            borderTop: '1px solid #d3d4d5',
                                                            borderLeft: '1px solid #d3d4d5',
                                                        }} 
                                                >
                                                        <i>{row[key]}</i>
                                                </TableCell>
                                        } else {
                                            column=
                                                <TableCell 
                                                    key={index}
                                                    align="center" 
                                                    className={classes.sizeSmall}
                                                    style={{fontSize: 15, 
                                                            border: '1px solid #d3d4d5',
                                                            borderTop: '1px solid #d3d4d5',
                                                            borderLeft: '1px solid #d3d4d5',
                                                        }} 
                                                >
                                                        {row[key]}
                                                </TableCell>
                                        } 
                                    return (
                                        column
                                        );
                                    })} */}
                                </TableRow>
                                {/* );
                            })} */}
                        </TableBody>
                    </Table>
                </TableContainer>
            {/* </div> */}
        {/* </Paper> */}
        </div>
    return (
        <div className={classes.root}>
            <div className={classes.paper}>
                {table}
            </div>
            <WarningMessage open={warningMessageOpen} handleClose={handleClose} severity={"warning"} message={warningMessage} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        // trackNameList: state[module].trackNameList,
        // selectedTrackNames: state[module].selectedTrackNames,
        // // cellTypeList: state[module].cellTypeList,
        // // selectedIndexForCellType: state[module].selectedIndexForCellType,

        // trackList: state[module].trackList,
        // selectedTracks: state[module].selectedTracks,
        // trackListCheckbox: state[moduleFilter].trackListCheckbox,
    }
}

export default connect(mapStateToProps, {})(RnaTableInfo);