export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_FAILURE";

export const submitCheckedTracks = (checkedTracks,typeOfContacts,selectedTrackNames, selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};

export const CLEAR_GENOME_BROWSER_URL = "CLEAR_GENOME_BROWSER_URL";

export const clearGenomeBrowserURL = () => {
    return {
        type: CLEAR_GENOME_BROWSER_URL,
    }
}

export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_FAILURE";

export const submitCheckedTracksRnaPart = (checkedTracks,typeOfContacts,selectedTrackNames, selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};

export const CLEAR_GENOME_BROWSER_URL_RNA_PART = "CLEAR_GENOME_BROWSER_URL_RNA_PART";

export const clearGenomeBrowserRnaPartURL = () => {
    return {
        type: CLEAR_GENOME_BROWSER_URL_RNA_PART,
    }
}