import React, { useState, useEffect } from 'react';
import { CSVDownload} from 'react-csv'; //CSVLink,
import axios from 'axios';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CircularProgress from '@material-ui/core/CircularProgress';

// import Checkbox from '@material-ui/core/Checkbox';
// import classNames from 'classnames';
// import Typography from '@material-ui/core/Typography';
// import Toolbar from '@material-ui/core/Toolbar';

// import ShowGraphics from '../components/ShowGraphics';
import WarningMessage from '../components/MessageSnackbar';
// import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';

const module = "genes";
const moduleFilter = "filter";
const moduleBasicAnalysis = "basicAnalysis";
const modulePlots = "plots"



const useStyles = makeStyles(theme => ({
    root: {
        // ...theme.mixins.gutters(),
        paddingTop: 0,//theme.spacing(),
        // paddingBottom: theme.spacing(),
        textAlign: 'center',
        flexGrow: 1,
        width: '100%',
    },
    download: {
        textAlign: 'right',
        padding: 0,
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(),
        // paddingTop: 10
    },
}));


const DownloadTrack = (props) => {

    // const classes = useStyles();

    // const [rows, setRows] = useState([]);
    
    
    // const [disabledDOWNLOAD, setDisabledDOWNLOAD] = useState(false)
    const [axiosState, setAxiosState] = useState(false)
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [downloadERROR, setDownloadERROR] = useState(false)
    
    

    const handleClose = () => {
        setOpen(false)
        setMessage('')
    }
    // console.log('qqq props.all_insert',props.all_insert)
    // console.log('qqq props.expId_and_name',props.expId_and_name)
    // console.log('qqq props.whatType',props.whatType)
    // console.log('qqq props.params',props.params)
    // console.log('qqq props.dataType',props.dataType)
    // console.log('qqq props.dataType2',props.dataType2)
    // console.log('qqq props.dataTypeRow',props.dataTypeRow)

    useEffect(() => {
        if (axiosState === true) {
            if (props.dataTypeRow === 'One-to-all') {
                setMessage('You are trying to download a genome-wide contact map of the one-to-all experiment that contains a large number of contacts. You can download this experiment on the "Content" page.')
                setOpen(true)
                setAxiosState(false)
            } else {
                // alert('Please, wait a while, it may take from a few seconds to a couple of minutes')
                axios.post("/DownloadTrackBasic/",
                        {   'checkedTracks': props.expId_and_name, 
                            'typeOfContacts': props.typeOfContacts + "____" + props.all_insert, 
                        //  'selectedTrackNames': props.selectedTrackNames,
                            'whatType': props.whatType,
                            'selectedNormalization': props.trackListCheckbox,
                            'params': props.params
                        })
                .then(response => {
                    setData([response.data.all_data])
                    setLoader(true)
                    setAxiosState(false)
                })
                .catch(err => {
                    setDownloadERROR(true)
                    console.error(err)
                })
            }
        }
    }, [axiosState]);

    useEffect(() => {
        if (loader === true) {
          setLoader(false)
        }
      }, [loader]);

    const fetchData = () => {
        setAxiosState(true)
    }
    // console.log('dataaaaaaa',loader,data)
    return (
        <div>
            {loader ?
                <CSVDownload 
                  data={data} 
                  separator={"\n"} 
                //   filename="my-file.csv"
                  target="_self"//"_blank" 
                /> 
                :
                null
            }
            {axiosState
                ?
                <LinearProgress />
                :
                    <IconButton 
                        style={{padding: 0}}
                        disabled={loader || props.loadingBasicGeneTable 
                                         || props.loadingBasicGeneTableFromDNA 
                                         || props.basicGeneTableDataFromDNA['LEN'] === 0
                                         || props.loadingAllTargetGenesTable
                                         || props.allTargetGenesTableData['LEN'] === 0
                                    }
                        onClick={e => fetchData()}
                        >
                        <CloudDownloadIcon />
                    </IconButton>
            }
        <WarningMessage open={open} severity={"warning"} handleClose={handleClose} message={message} />                
        <WarningMessage open={downloadERROR} severity={"error"} message="Ups" />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedTrackNames: state[module].selectedTrackNames,
        trackListCheckbox: state[moduleFilter].trackListCheckbox,

        loadingBasicGeneTableFromDNA: state[moduleBasicAnalysis].loadingBasicGeneTableFromDNA,
        basicGeneTableDataFromDNA: state[moduleBasicAnalysis].basicGeneTableDataFromDNA,

        loadingBasicGeneTable: state[moduleBasicAnalysis].loadingBasicGeneTable,
        loadingAllTargetGenesTable: state[modulePlots].loadingAllTargetGenesTable,
        allTargetGenesTableData: state[modulePlots].allTargetGenesTableData,
    }
}

export default connect(mapStateToProps, {})(DownloadTrack);