import * as ActionType from '../actions/generalActions';
import * as ActionTypes from '../actions/organismActions';

const initialState = {
    expData: [],
    selected: [],
    loading: false,
    snackOpen: false,
    snackText: '',

    loadingAllExps: false,
    AllExps: [],
    snackOpenAllExps: false,
    snackTextAllExps: '',

    loadingAnnotationRNA: false,
    annotationRNA: [],
    snackOpenAnnotationRNA: false,
    snackTextAnnotationRNA: '',

    loadingTracksComparison: false,
    snackTracksComparison: false,
    snackTextTracksComparison: '',
    TracksComparisonData: [],

    loadingRnaList: false,
    snackRnaList: false,
    snackTextRnaList: '',
    RnaListData: {},
    RnaListGeneType: [],
    RnaListTrackName: [],

    BasicTableAnnotationSource: [],

    BasicTableGeneName: [],

    loadingProcessingMetadata: false,
    snackOpenProcessingMetadata: false,
    snackTextProcessingMetadata: '',
    processingMetadata: [],

    loadingMetadataPlots: false,
    snackOpenMetadataPlots: false,
    snackTextMetadataPlots: '',
    MetadataPlots: [],

    loadingOrganism: false,
    selectedItemOrganism: [{ label: "Homo sapiens", value: "Homo sapiens" }],
    currentOrganismInput: "",
    // suggestionTypes: [],

    selectedColumns: [],
};

const createData = (id, cellType, typeOfExperiment, meth, extra_information) => { //organism, tissue,  genome_version, name, geo,
  return { id, cellType, typeOfExperiment, meth, extra_information}; //organism, tissue, genome_version, name, geo,
}

const tableReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.FETCH_EXP_TABLE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case ActionType.FETCH_EXP_TABLE_SUCCESS: {
            let data1 = []
            let data2 = {}
            if (Array.isArray(action.data[0])) {
                data1 = action.data.map(element => createData(...element))
                return {
                    ...state,
                    expData: data1,
                    loading: false,
                };
            } else {
                data2 = action.data
                return {
                    ...state,
                    expData: data2,
                    loading: false,
                };
            }
        }
        case ActionType.FETCH_EXP_TABLE_FAILURE: {
            return {
                ...state,
                loading: true,
                snackOpen: true,
                snackText: 'An error occurred in the backend, data about this experiment was not sent to the web!',
            };
        }
        case ActionType.FETCH_ANNOTATION_RNA_REQUEST: {
            return {
                ...state,
                loadingAnnotationRNA: true,
            };
        }
        case ActionType.FETCH_ANNOTATION_RNA_SUCCESS: {
            return {
                ...state,
                annotationRNA: action.annotationRNA,
                loadingAnnotationRNA: false,
            };
        }
        case ActionType.FETCH_ANNOTATION_RNA_FAILURE: {
            return {
                ...state,
                loadingAnnotationRNA: true,
                snackOpenAnnotationRNA: true,
                snackTextAnnotationRNA: 'An error occurred in the backend, Annotation RNA data was not sent to the web!',
            };
        }
        case ActionType.FETCH_ALL_EXPS_REQUEST: {
            return {
                ...state,
                loadingAllExps: true,
            };
        }
        case ActionType.FETCH_ALL_EXPS_SUCCESS: {
            return {
                ...state,
                AllExps: action.AllExps,
                loadingAllExps: false,
            };
        }
        case ActionType.FETCH_ALL_EXPS_FAILURE: {
            return {
                ...state,
                loadingAllExps: true,
                snackOpenAllExps: true,
                snackTextAllExps: 'An error occurred in the backend, all exps data was not sent to the web!',
            };
        }
        case ActionType.FETCH_PROCESSING_METADA_REQUEST: {
            return {
                ...state,
                loadingProcessingMetadata: true,
            };
        }
        case ActionType.FETCH_PROCESSING_METADA_SUCCESS: {
            return {
                ...state,
                processingMetadata: action.processingMetadata,
                loadingProcessingMetadata: false,
            };
        }
        case ActionType.FETCH_PROCESSING_METADA_FAILURE: {
            return {
                ...state,
                loadingProcessingMetadata: true,
                snackOpenProcessingMetadata: true,
                snackTextProcessingMetadata: 'An error occurred in the backend, Metadata was not sent to the web!',
            };
        }
        case ActionType.FETCH_METADA_PLOTS_REQUEST: {
            return {
                ...state,
                loadingMetadataPlots: true,
            };
        }
        case ActionType.FETCH_METADA_PLOTS_SUCCESS: {
            return {
                ...state,
                MetadataPlots: action.MetadataPlots,
                loadingMetadataPlots: false,
            };
        }
        case ActionType.FETCH_METADA_PLOTS_FAILURE: {
            return {
                ...state,
                loadingMetadataPlots: true,
                snackOpenMetadataPlots: true,
                snackTextMetadataPlots: 'An error occurred in the backend, Metadata was not sent to the web!',
            };
        }







        case ActionType.SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_REQUEST: {
            return {
                ...state,
                loadingTracksComparison: true,
            };
        }
        case ActionType.SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_SUCCESS: {
            return {
                ...state,
                TracksComparisonData: action.TracksComparisonData,
                loadingTracksComparison: false,
            };
        }
        case ActionType.SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_FAILURE: {
            return {
                ...state,
                snackTracksComparison: true,
                snackTextTracksComparison: 'An error occurred in the backend, TRACKS COMPARISON data was not sent to the web!',
            };
        }













        case ActionType.SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_REQUEST: {
            return {
                ...state,
                loadingRnaList: true,
            };
        }
        case ActionType.SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_SUCCESS: {
            return {
                ...state,
                RnaListData: action.RnaListData,
                loadingRnaList: false,
            };
        }
        case ActionType.SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_FAILURE: {
            return {
                ...state,
                snackRnaList: true,
                snackTextRnaList: 'An error occurred in the backend, RNA list data was not sent to the web!',
            };
        }
        case ActionType.RNA_LIST_CURRENT_GENE_TYPES: {
            return {
                ...state,
                RnaListGeneType: action.RnaListGeneType,
            };
        }
        case ActionType.BASIC_TABLE_FROM_DNA_CURRENT_GENE_NAME: {
            return {
                ...state,
                BasicTableGeneName: action.BasicTableGeneName,
            };
        }
        case ActionType.BASIC_TABLE_FROM_DNA_CURRENT_ANNOTATION_SOURCE: {
            return {
                ...state,
                BasicTableAnnotationSource: action.BasicTableAnnotationSource,
            };
        }
        case ActionType.RNA_LIST_CURRENT_TRACK_NAMES: {
            // console.log('action.RnaListTrackName)))))))',action.RnaListTrackName)
            return {
                ...state,
                RnaListTrackName: action.RnaListTrackName,
            };
        }
        case ActionType.EXP_TABLE_SELECT_ALL: {
            let newSelected = [];
            const data = state.expData;
            if (action.checked) {
                newSelected = data.map(element => element.id);
            }
            return {
                ...state,
                selected: newSelected,
            }
        }
        case ActionType.EXP_TABLE_SELECT_ONE: {
            const selected = state.selected;
            const selectedIndex = selected.indexOf(action.id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, action.id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
            return {
                ...state,
                selected: newSelected,
            }
        }

        // Organisms

        // case ActionTypes.FETCH_ORGANISM_REQUEST:
        //     return {
        //         ...state,
        //         loadingOrganism: true,
        //     };
        // case ActionTypes.FETCH_ORGANISM_SUCCESS:
        //     return {
        //         ...state,
        //         suggestionTypes: action.organism,
        //         loadingOrganism: false,
        //     };
        // case ActionTypes.FETCH_ORGANISM_FAILURE:
        //     return {
        //         ...state,
        //         loadingOrganism: false,
        //     };

        /* AUTOSUGGEST ORGANISM */

        case ActionTypes.HANDLE_ORGANISM_SELECT:
            return {
                ...state,
                selectedItemOrganism: action.organismName,
                selected: []
            };
        
        case ActionTypes.HANDLE_CURRENT_ORGANISM_INPUT:
            return {
                ...state,
                currentOrganismInput: action.value,
                selected: []
            };
        
        /* HANDLE_SELECTED_COLUMNS in ColumnSelector.jsx*/
        case ActionType.HANDLE_SELECTED_COLUMNS:
            return {
                ...state,
                selectedColumns: action.columns,
            };

        
        case ActionTypes.CLEAR_EXPERIMENTS_CHACKBOX:
            return {
                ...state,
                selected: []
            };
        default:
            return state;
    }
}

export default tableReducer;