export const TOGGLE_MENU = "TOGGLE_MENU";

// export const IS_LOGGED_REQUEST = "IS_LOGGED_REQUEST";
// export const IS_LOGGED_SUCCESS = "IS_LOGGED_SUCCESS";
// export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
// export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// export const LOGIN_REQUEST = "LOGIN_REQUEST";
// export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
// export const LOGIN_FAIL = "LOGIN_FAIL"
// export const LOGOUT = "LOGOUT";

//export const BUTTON_CLICK = "BUTTON_CLICK";
export const FETCH_ANNOTATION_RNA_REQUEST = "FETCH_ANNOTATION_RNA_REQUEST";
export const FETCH_ANNOTATION_RNA_SUCCESS = "FETCH_ANNOTATION_RNA_SUCCESS";
export const FETCH_ANNOTATION_RNA_FAILURE = "FETCH_ANNOTATION_RNA_FAILURE";

export const FETCH_PROCESSING_METADA_REQUEST = "FETCH_PROCESSING_METADA_REQUEST";
export const FETCH_PROCESSING_METADA_SUCCESS = "FETCH_PROCESSING_METADA_SUCCESS";
export const FETCH_PROCESSING_METADA_FAILURE = "FETCH_PROCESSING_METADA_FAILURE";

export const FETCH_METADA_PLOTS_SUCCESS = "FETCH_METADA_PLOTS_SUCCESS";
export const FETCH_METADA_PLOTS_FAILURE = "FETCH_METADA_PLOTS_FAILURE";

// export const toggleMenu = (state) => {
//     return {
//         type: TOGGLE_MENU,
//         newState: state,
//     };
// };

// export const login = (uname, passwd) => {
//     return {
//         type: LOGIN_REQUEST,
//         username: uname,
//         password: passwd,
//     };
// };

// export const logout = () => {
//     return {
//         type: LOGOUT,
//     };
// };

// export const isLogged = () => {
//     return {
//         type: IS_LOGGED_REQUEST,
//     };
// };

// export const logout = () => {
//     return {
//         type: LOGOUT_REQUEST,
//     };
// };


export const TEXT_CHANGE_SEARCH_GENE_NAME = "TEXT_CHANGE_SEARCH_GENE_NAME";

export const textChangeSearchGeneName = event => {
    return {
        type: TEXT_CHANGE_SEARCH_GENE_NAME,
        newValueSearchGeneName: event.target.value,
    };
};


export const CURRENT_LOCUS_CHANGE = "CURRENT_LOCUS_CHANGE";
export const LOCUS_WARNING_CHANGE_FALSE = "LOCUS_WARNING_CHANGE_FALSE";
export const LOCUS_WARNING_CHANGE_TRUE = "LOCUS_WARNING_CHANGE_TRUE";
export const TEXT_CHANGE = "TEXT_CHANGE";

export const textChange = event => {
    return {
        type: TEXT_CHANGE,
        newValue: event.target.value,
    };
};

export const currentLocusChange = (input_value) => {
    return {
        type: CURRENT_LOCUS_CHANGE,
        newLocus: input_value,
    };
};

export const locusWarningChangeFalse = () => {
    return {
        type: LOCUS_WARNING_CHANGE_FALSE,
    };
};

export const locusWarningChangeTrue = () => {
    return {
        type: LOCUS_WARNING_CHANGE_TRUE,
    };
};


export const CURRENT_GENE_BODY_CHANGE = "CURRENT_GENE_BODY_CHANGE";
export const GENE_BODY_WARNING_CHANGE_FALSE = "GENE_BODY_WARNING_CHANGE_FALSE";
export const GENE_BODY_WARNING_CHANGE_TRUE = "GENE_BODY_WARNING_CHANGE_TRUE";
export const TEXT_GENE_BODY_CHANGE = "TEXT_GENE_BODY_CHANGE";

export const textGeneBodyChange = event => {
    return {
        type: TEXT_GENE_BODY_CHANGE,
        newValue: event.target.value,
    };
};

export const currentGeneBodyChange = (input_value) => {
    return {
        type: CURRENT_GENE_BODY_CHANGE,
        newGeneBody: input_value,
    };
};

export const GeneBodyWarningChangeFalse = () => {
    return {
        type: GENE_BODY_WARNING_CHANGE_FALSE,
    };
};

export const GeneBodyWarningChangeTrue = () => {
    return {
        type: GENE_BODY_WARNING_CHANGE_TRUE,
    };
};


export const CURRENT_UP50_CHANGE = "CURRENT_UP50_CHANGE";
export const UP50_WARNING_CHANGE_FALSE = "UP50_WARNING_CHANGE_FALSE";
export const UP50_WARNING_CHANGE_TRUE = "UP50_WARNING_CHANGE_TRUE";
export const TEXT_UP50_CHANGE = "TEXT_UP50_CHANGE";

export const textUp50Change = event => {
    return {
        type: TEXT_UP50_CHANGE,
        newValue: event.target.value,
    };
};

export const currentUp50Change = (input_value) => {
    return {
        type: CURRENT_UP50_CHANGE,
        newUp50: input_value,
    };
};

export const Up50WarningChangeFalse = () => {
    return {
        type: UP50_WARNING_CHANGE_FALSE,
    };
};

export const Up50WarningChangeTrue = () => {
    return {
        type: UP50_WARNING_CHANGE_TRUE,
    };
};

export const CURRENT_UP5_CHANGE = "CURRENT_UP5_CHANGE";
export const UP5_WARNING_CHANGE_FALSE = "UP5_WARNING_CHANGE_FALSE";
export const UP5_WARNING_CHANGE_TRUE = "UP5_WARNING_CHANGE_TRUE";
export const TEXT_UP5_CHANGE = "TEXT_UP5_CHANGE";

export const textUp5Change = event => {
    return {
        type: TEXT_UP5_CHANGE,
        newValue: event.target.value,
    };
};

export const currentUp5Change = (input_value) => {
    return {
        type: CURRENT_UP5_CHANGE,
        newUp5: input_value,
    };
};

export const Up5WarningChangeFalse = () => {
    return {
        type: UP5_WARNING_CHANGE_FALSE,
    };
};

export const Up5WarningChangeTrue = () => {
    return {
        type: UP5_WARNING_CHANGE_TRUE,
    };
};


export const CURRENT_DOWN50_CHANGE = "CURRENT_DOWN50_CHANGE";
export const DOWN50_WARNING_CHANGE_FALSE = "DOWN50_WARNING_CHANGE_FALSE";
export const DOWN50_WARNING_CHANGE_TRUE = "DOWN50_WARNING_CHANGE_TRUE";
export const TEXT_DOWN50_CHANGE = "TEXT_DOWN50_CHANGE";

export const textDown50Change = event => {
    return {
        type: TEXT_DOWN50_CHANGE,
        newValue: event.target.value,
    };
};

export const currentDown50Change = (input_value) => {
    return {
        type: CURRENT_DOWN50_CHANGE,
        newDown50: input_value,
    };
};

export const Down50WarningChangeFalse = () => {
    return {
        type: DOWN50_WARNING_CHANGE_FALSE,
    };
};

export const Down50WarningChangeTrue = () => {
    return {
        type: DOWN50_WARNING_CHANGE_TRUE,
    };
};


export const CURRENT_DOWN5_CHANGE = "CURRENT_DOWN5_CHANGE";
export const DOWN5_WARNING_CHANGE_FALSE = "DOWN5_WARNING_CHANGE_FALSE";
export const DOWN5_WARNING_CHANGE_TRUE = "DOWN5_WARNING_CHANGE_TRUE";
export const TEXT_DOWN5_CHANGE = "TEXT_DOWN5_CHANGE";

export const textDown5Change = event => {
    return {
        type: TEXT_DOWN5_CHANGE,
        newValue: event.target.value,
    };
};

export const currentDown5Change = (input_value) => {
    return {
        type: CURRENT_DOWN5_CHANGE,
        newDown5: input_value,
    };
};

export const Down5WarningChangeFalse = () => {
    return {
        type: DOWN5_WARNING_CHANGE_FALSE,
    };
};

export const Down5WarningChangeTrue = () => {
    return {
        type: DOWN5_WARNING_CHANGE_TRUE,
    };
};


export const CURRENT_LENGTH_CHANGE = "CURRENT_LENGTH_CHANGE";
export const LENGTH_WARNING_CHANGE_FALSE = "LENGTH_WARNING_CHANGE_FALSE";
export const LENGTH_WARNING_CHANGE_TRUE = "LENGTH_WARNING_CHANGE_TRUE";
export const TEXT_LENGTH_CHANGE = "TEXT_LENGTH_CHANGE";

export const textLengthChange = event => {
    return {
        type: TEXT_LENGTH_CHANGE,
        newValue: event.target.value,
    };
};

export const currentLengthChange = (input_value) => {
    return {
        type: CURRENT_LENGTH_CHANGE,
        newLength: input_value,
    };
};

export const lengthWarningChangeFalse = () => {
    return {
        type: LENGTH_WARNING_CHANGE_FALSE,
    };
};

export const lengthWarningChangeTrue = () => {
    return {
        type: LENGTH_WARNING_CHANGE_TRUE,
    };
};




export const CURRENT_LOCUS_CHANGE_FROM_DNA = "CURRENT_LOCUS_CHANGE_FROM_DNA";
export const LOCUS_WARNING_CHANGE_FALSE_FROM_DNA = "LOCUS_WARNING_CHANGE_FALSE_FROM_DNA";
export const LOCUS_WARNING_CHANGE_TRUE_FROM_DNA = "LOCUS_WARNING_CHANGE_TRUE_FROM_DNA";
export const TEXT_CHANGE_FROM_DNA = "TEXT_CHANGE_FROM_DNA";

export const currentLocusChangeFromDNA = (input_value) => {
    return {
        type: CURRENT_LOCUS_CHANGE_FROM_DNA,
        newLocus: input_value,
    };
};

export const locusWarningChangeFalseFromDNA = () => {
    return {
        type: LOCUS_WARNING_CHANGE_FALSE_FROM_DNA,
    };
};

export const locusWarningChangeTrueFromDNA = () => {
    return {
        type: LOCUS_WARNING_CHANGE_TRUE_FROM_DNA,
    };
};

export const textChangeFromDNA = event => {
    return {
        type: TEXT_CHANGE_FROM_DNA,
        newValue: event.target.value,
    };
};




export const TEXT_CHANGE_UPSTREAM_WARNING_FALSE = "TEXT_CHANGE_UPSTREAM_WARNING_FALSE";
export const TEXT_CHANGE_UPSTREAM_WARNING_TRUE = "TEXT_CHANGE_UPSTREAM_WARNING_TRUE";
export const TEXT_CHANGE_UPSTREAM = "TEXT_CHANGE_UPSTREAM";

export const textChangeUpstream = event => {
    return {
        type: TEXT_CHANGE_UPSTREAM,
        newValue: event.target.value,
    };
};

export const textUpstreamWarningChangeFalse = () => {
    return {
        type: TEXT_CHANGE_UPSTREAM_WARNING_FALSE,
    };
};

export const textUpstreamWarningChangeTrue = () => {
    return {
        type: TEXT_CHANGE_UPSTREAM_WARNING_TRUE,
    };
};



export const TEXT_CHANGE_DOWNSTREAM_WARNING_FALSE = "TEXT_CHANGE_DOWNSTREAM_WARNING_FALSE";
export const TEXT_CHANGE_DOWNSTREAM_WARNING_TRUE = "TEXT_CHANGE_DOWNSTREAM_WARNING_TRUE";
export const TEXT_CHANGE_DOWNSTREAM = "TEXT_CHANGE_DOWNSTREAM";

export const textChangeDownstream = event => {
    return {
        type: TEXT_CHANGE_DOWNSTREAM,
        newValue: event.target.value,
    };
};

export const textDownstreamWarningChangeFalse = () => {
    return {
        type: TEXT_CHANGE_DOWNSTREAM_WARNING_FALSE,
    };
};

export const textDownstreamWarningChangeTrue = () => {
    return {
        type: TEXT_CHANGE_DOWNSTREAM_WARNING_TRUE,
    };
};




export const FETCH_EXP_TABLE_REQUEST = "FETCH_EXP_TABLE_REQUEST";
export const FETCH_EXP_TABLE_SUCCESS = "FETCH_EXP_TABLE_SUCCESS";
export const FETCH_EXP_TABLE_FAILURE = "FETCH_EXP_TABLE_FAILURE";

export const fetchExpTable = (input_organism) => { //,logged
    return {
        type: FETCH_EXP_TABLE_REQUEST,
        input_organism: input_organism,
        // logged: logged,
    };
};

export const EXP_TABLE_SELECT_ALL = "EXP_TABLE_SELECT_ALL";

export const selectAll = (checked) => {
    return {
        type: EXP_TABLE_SELECT_ALL,
        checked,
    };
};

export const EXP_TABLE_SELECT_ONE = "EXP_TABLE_SELECT_ONE";

export const selectOne = (id) => {
    return {
        type: EXP_TABLE_SELECT_ONE,
        id,
    };
};

export const fetchTableAnnotationRNA = (id) => {
    return {
        type: FETCH_ANNOTATION_RNA_REQUEST,
        id,
    };
};

export const fetchTableProcessingMetada = (id) => {
    return {
        type: FETCH_PROCESSING_METADA_REQUEST,
        id,
    };
};


export const FETCH_METADA_PLOTS_REQUEST = "FETCH_METADA_PLOTS_REQUEST";

export const fetchMetadaPlots = (id,selectedNormalization) => {
    return {
        type: FETCH_METADA_PLOTS_REQUEST,
        id,
        selectedNormalization,
    };
};

export const SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_FAILURE";

export const submitTracksComparison = (checkedTracks,typeOfContacts,selectedTrackNames,organism,selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_TRACKS_COMPARISON_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        organism,
        selectedNormalization,
    };
};

export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_FAILURE";

export const submitTableRnaList = (checkedTracks,typeOfContacts,selectedTrackNames, selectedNormalization, params) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_RNA_LIST_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
        params,
    };
};

export const RNA_LIST_CURRENT_GENE_TYPES = "RNA_LIST_CURRENT_GENE_TYPES"
export const SendListGeneType = (RnaListGeneType) => {
    return {
        type: RNA_LIST_CURRENT_GENE_TYPES,
        RnaListGeneType,
    };
};

export const BASIC_TABLE_FROM_DNA_CURRENT_GENE_NAME = "BASIC_TABLE_FROM_DNA_CURRENT_GENE_NAME"
export const SendListGeneName = (BasicTableGeneName) => {
    return {
        type: BASIC_TABLE_FROM_DNA_CURRENT_GENE_NAME,
        BasicTableGeneName,
    };
};

export const BASIC_TABLE_FROM_DNA_CURRENT_ANNOTATION_SOURCE = "BASIC_TABLE_FROM_DNA_CURRENT_ANNOTATION_SOURCE"
export const SendListAnnotationSource = (BasicTableAnnotationSource) => {
    return {
        type: BASIC_TABLE_FROM_DNA_CURRENT_ANNOTATION_SOURCE,
        BasicTableAnnotationSource,
    };
};


export const RNA_LIST_CURRENT_TRACK_NAMES = "RNA_LIST_CURRENT_TRACK_NAMES"
export const SendListTrackName = (RnaListTrackName) => {
    return {
        type: RNA_LIST_CURRENT_TRACK_NAMES,
        RnaListTrackName,
    };
}

export const HANDLE_SELECTED_COLUMNS = "HANDLE_SELECTED_COLUMNS";

export const handleSelectedColumns = (columns) => {
    return {
        type: HANDLE_SELECTED_COLUMNS,
        columns,
    };
};
/* export const countChange = (geneCount) => {
    return {
        type: COUNT_CHANGE,
        newGeneCount: geneCount,
    };
}; */
/* export const SUBMIT_DATA_REQUEST = "SUBMIT_DATA_REQUEST";

export const countChange = (geneCount) => {
    return {
        type: SUBMIT_DATA_REQUEST,
        geneCount,
    };
};
 */

/* export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const dataRequest = () => {
    return {
        type: FETCH_DATA_REQUEST,
    };
};


export const SUBMIT_DATA_REQUEST = "SUBMIT_DATA_REQUEST";
export const SUBMIT_DATA_SUCCESS = "SUBMIT_DATA_SUCCESS";
export const SUBMIT_DATA_FAILURE = "SUBMIT_DATA_FAILURE";

export const submitData = (genes) => {
    return {
        type: SUBMIT_DATA_REQUEST,
        genes,
    };
}; */

/* export const buttonClick = (state) => {
    return {
        type: BUTTON_CLICK,
        newState: state,
    };
};  */

export const FETCH_ALL_EXPS_REQUEST = "FETCH_ALL_EXPS_REQUEST";
export const FETCH_ALL_EXPS_SUCCESS = "FETCH_ALL_EXPS_SUCCESS";
export const FETCH_ALL_EXPS_FAILURE = "FETCH_ALL_EXPS_FAILURE";

export const allExpsRequest = () => {
    return {
        type: FETCH_ALL_EXPS_REQUEST,
    };
};


export const HANDLE_HEADER = "HANDLE_HEADER";

export const handleHeader = (headerName) => {
    return {
        type: HANDLE_HEADER,
        headerName,
    };
};

export const CLEAR_RNA = "CLEAR_RNA";

export const clearRNAText = () =>{ 
    return {
        type: CLEAR_RNA,
    };
};

export const CLEAR_TEXT = "CLEAR_TEXT";

export const clearText = () =>{ 
    return {
        type: CLEAR_TEXT,
    };
};

export const CLEAR_TEXT_INPUTS_TWO = "CLEAR_TEXT_INPUTS_TWO";

export const clearTextInputsTwo = () =>{ 
    return {
        type: CLEAR_TEXT_INPUTS_TWO,
    };
};

export const CLEAR_TEXT_INPUTS_TWO_UPSTREAM = "CLEAR_TEXT_INPUTS_TWO_UPSTREAM";

export const clearTextInputsTwoUpstream = () =>{ 
    return {
        type: CLEAR_TEXT_INPUTS_TWO_UPSTREAM,
    };
};