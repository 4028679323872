import React from 'react';
// import Plotly from 'plotly.js'
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
// import Paper from '@material-ui/core/Paper';

const module = "table";

// const styles = theme => ({
//   root: {
//       ...theme.mixins.gutters(),
//       paddingTop: theme.spacing(),
//       paddingBottom: theme.spacing(1),
//       textAlign: 'center',
//   },
//   locationTop: {
//       marginTop: 70,
//       textAlign: 'center',
//       marginBottom: 50,
//   },
// })

const preprocessLabel = (label) => {
  let sLabel = label.split(" ");
  return sLabel.join("<br />");
}

const MetadataBarPlot = (props) => {

  const { procMeta } = props; // classes
  // console.log('procMeta',procMeta)
  let processing_metadata_label = [];
  let processing_metadata_value = [];
  let download = procMeta[0]['Nreads']; // always first
  for (var i in procMeta) {
    // console.log('procMeta[i][0]',procMeta[i])
    processing_metadata_label.push(preprocessLabel(procMeta[i]['Processingsteps']));
    processing_metadata_value.push(procMeta[i]['Nreads']/download * 100);
  }
  // console.log("VL", processing_metadata_value);
  // console.log("LBL", processing_metadata_label);
  return (
    <div style={{border: '1px solid #d3d4d5'}}>
        <Plot
          data={[{
            y: processing_metadata_value,
            x: processing_metadata_label,
            type: 'bar',
            // pull: 0.01,
            // textposition: 'auto',
            // rotation : 90,
            marker: {
              color: '#009688'
            },
          }]}
          layout={{
            height: 400,
            width: window.innerWidth/2.3,
            xaxis: {
              tickangle: 45,
              titlefont: { size: 20 },
            },
            yaxis: {
              title: '%',
              titlefont: { size: 20 },
            }
          }}
          config = {{
            modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian','lasso2d','select2d'],
            // displayModeBar: true,
            displaylogo: false,
            // editable: true,
            doubleClickDelay: 1000,
            toImageButtonOptions: {format: 'svg', filename: 'Processing data', scale: 1},
          }}
        />
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    processingMetadata: state[module].processingMetadata,
  }
}
  

export default connect(mapStateToProps, )(MetadataBarPlot);