import * as ActionTypes from '../actions/genomeBrowserActions';

const initialState = {
    URLtoGenomeBrowser: "",
    URLtoGenomeBrowserRnaPart: "",

    loadingURL: false,
    snackOpenGenomeBrowser: false,
    snackTextGenomeBrowser: '',

    loadingURLRnaPart: false,
    snackOpenGenomeBrowserRnaPart: false,
    snackTextGenomeBrowserRnaPart: '',
}


const genomeBrowserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_REQUEST:
            return {
                ...state,
                loadingURL: true,
                
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_SUCCESS:
            // console.log('SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_SUCCESS')
            // console.log('URLtoGenomeBrowser',initialState.URLtoGenomeBrowser)
            // console.log(action.URLtoGenomeBrowser)
            return {
                ...state,
                URLtoGenomeBrowser: action.URLtoGenomeBrowser,
                loadingURL: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_FAILURE:
            return {
                ...state,
                snackOpenGenomeBrowser: true,
                snackTextGenomeBrowser: "An error occurred in the backend, was not sent to the GENOME BROWSER!",
                loadingURL: true,
            };
        case ActionTypes.CLEAR_GENOME_BROWSER_URL:
            return {
                ...state,
                URLtoGenomeBrowser: ''
            }

        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_REQUEST:
            return {
                ...state,
                loadingURLRnaPart: true,
                
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_SUCCESS:
            // console.log('SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_SUCCESS')
            // console.log('URLtoGenomeBrowserRnaPart',initialState.URLtoGenomeBrowserRnaPart)
            // console.log(action.URLtoGenomeBrowserRnaPart)
            return {
                ...state,
                URLtoGenomeBrowserRnaPart: action.URLtoGenomeBrowserRnaPart,
                loadingURLRnaPart: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_BROWSER_RNA_PART_FAILURE:
            return {
                ...state,
                snackOpenGenomeBrowserRnaPart: true,
                snackTextGenomeBrowserRnaPart: "An error occurred in the backend, was not sent to the GENOME BROWSER RNA part!",
                loadingURLRnaPart: true,
            };
        case ActionTypes.CLEAR_GENOME_BROWSER_URL_RNA_PART:
            return {
                ...state,
                URLtoGenomeBrowserRnaPart: ''
            }

        default:
            return state;
    }
};

export default genomeBrowserReducer;