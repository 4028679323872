import React from 'react';
import Plot from 'react-plotly.js';
// import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
// import { CSVDownload} from 'react-csv'; //CSVLink,
// import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// import Decorator from '../../components/HtmlTooltip';
import { Track_description, normalizationName } from './Heatmap_data_layout';
import EnhancedTABLEpart from '../../components/TableGraphicalSummary';
import Typography from '@material-ui/core/Typography';

const module = "plots";

// const csvData = [
//     ["firstname", "lastname", "email"],
//     ["Ahmed", "Tomi", "ah@smthing.co.com"],
//     ["Raed", "Labes", "rl@smthing.co.com"],
//     ["Yezzi", "Min l3b", "ymin@cocococo.com"]
//   ]

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '99%',
      textAlign: 'center',
    },
  }));

// const Heatmap_description = (data) => {
//     let List = []
//     let List_final = []
//     for (var k = 0; k < data.length; k++) {
//         if (List.indexOf(data[k]) === -1) {
//             List.push(data[k])
//         }
//     }
//     for (var i = 0; i < List.length; i++) {
//         var res = List[i].split(", ")
//         List_final.push(<Typography key={i}>
//                             ID: <b>{res[0]}</b><br/>organism: <b>{res[1]}</b><br/>cell line: <b>{res[2]}</b><br/>exp.protocol: <b>{res[3]}</b><br/>extra: <b>{res[4]}</b>
//                         </Typography>
//                     )
//     }
//     return List_final
// }
function repeat(arr, n){
    var a = [];
    for (var i=0;i<n;[i++].push.apply(a,arr));
    return a;
  }

const DistanceDistribution = (props) => { //BarLine

    const { trackNameAndColor } = props

    const [state, setState] = React.useState({
        checkedB: true,
      });
      
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };

    let bin_lables = []
    let shapes_rect =[]
    // let bins = [2,4,8,16,32,64,128,256,512,1024,2048,4096,8192,16384,32768,65536,131072,262144,524288,1048576,2097152,4194304,8388608,16777216,33554432,67108864,134217728, 268435456]
    // let bins = [1.0, 2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0, 11.0, 12.0, 13.0, 14.0, 15.0, 16.0, 17.0, 18.0, 19.0, 20.0, 21.0, 22.0, 23.0, 24.0, 25.0, 26.0, 27.0, 28.0]
    // let bins_text = ["[0;64]","(64;128]","(256;512]","(1024;2048]","(4096;8192]","(16384;32768]","(65536;131072]","(262144;524288]","(1048576;2097152]","(4194304;8388608]","(16777216;33554432]","(67108864;134217728]","(134217728;268435456]"]
    
    let bins = [-28.0,-26.0,-24.0,-22.0,-20.0,-18.0,-16.0,-14.0,-12.0,-10.0,-8.0,-6.0,-4.0,4.0, 6.0, 8.0, 10.0, 12.0, 14.0, 16.0, 18.0, 20.0, 22.0, 24.0, 26.0, 28.0]
    // let bins_text = ["[-28;-27)","[-26;-25)","[-24;-23)","[-22;-21)","[-20;-19)","[-18;-17)","[-16;-15)","[-14;-13)","[-12;-11)","[-10;-9)","[-8;-7)","[-6;-5)","[-4;0]","[0;4]","(5;6]","(7;8]","(9;10]","(11;12]","(13;14]","(15;16]","(17;18]","(19;20]","(21;22]","(23;24]","(25;26]","(27;28]"]
    let bins_text = ["-28","-26","-24","-22","-20","-18","-16","-14","-12","-10","-8","-6","[-4;0]","[0;4]","6","8","10","12","14","16","18","20","22","24","26","28"]
    
    let bin_lables_non_gene_centric = []
    let bins_non_gene_centric = [4.0, 6.0, 8.0, 10.0, 12.0, 14.0, 16.0, 18.0, 20.0, 22.0, 24.0, 26.0, 28.0]
    // let bins_text_non_gene_centric = ["[0;4]","(5;6]","(7;8]","(9;10]","(11;12]","(13;14]","(15;16]","(17;18]","(19;20]","(21;22]","(23;24]","(25;26]","(27;28]"]
    let bins_text_non_gene_centric = ["[0;4]","6","8","10","12","14","16","18","20","22","24","26","28"]

    for (var index = 0; index < bins.length; index ++) {
        bin_lables.push(
            {
                x: bins[index], 
                y: 0,
                xref: 'x1',
                // yref: 'y2',
                yref: 'paper',
                text: bins_text[index],//bins[index],
                arrowhead: 0.7,
                arrowsize: 0.7,
                // showarrow: true,
                ax: 0,
                ay: 25,
                textangle: '-45',
                font: {
                    family: 'sans serif',
                    size: 8,
                    // color: '#ff7f0e'
                },
            }
        )
    }
    bin_lables.push(
        {
            x: 0, 
            y: 0.001,
            xref: 'x1',
            yref: 'paper',
            text: 'gene',
            showarrow: false,
        }
    )
    bin_lables.push(
        {
            x: -16, 
            y: 0.001,
            xref: 'x1',
            yref: 'paper',
            text: 'upstream area',
            showarrow: false,
        }
    )
    bin_lables.push(
        {
            x: 16, 
            y: 0.001,
            xref: 'x1',
            yref: 'paper',
            text: 'downstream area',
            showarrow: false,
        }
    )
    shapes_rect.push(
        {
            type: 'rect',
            yref: 'paper',
            x0: -4,
            y0: 0, //max_heigth, //0,
            x1: 4,
            y1: 0.05, //0.41, //0,
            // opacity: 1,
            line: {
                color: 'grey',
                width: 3
            },
            fillcolor: 'white'
        }
    )
    
    // console.log('bin_lables',bin_lables)
    for (var index = 0; index < bins_non_gene_centric.length; index ++) {
        bin_lables_non_gene_centric.push(
            {
                x: bins_non_gene_centric[index], 
                y: 0,
                xref: 'x1',
                // yref: 'y2',
                yref: 'paper',
                text: bins_text_non_gene_centric[index],//bins[index],
                arrowhead: 0.7,
                arrowsize: 0.7,
                // showarrow: true,
                ax: 0,
                ay: 25,
                textangle: '-45',
                font: {
                    family: 'sans serif',
                    size: 8,
                    // color: '#ff7f0e'
                },
            }
        )
    }
    
        
        let data_left = []
        let data_right = []
        let data_list = []
        data_list = [data_left ,data_right]
        let plot_count = 0
        const classes = useStyles();
        let Mus_musculus_plot = null
        let Homo_sapiens_plot = null
        var selectedNormalization = ''

        const { genomeWideDataDistanceDistribution } = props;
        // console.log('genomeWideDataDistanceDistribution',genomeWideDataDistanceDistribution)
        // console.log('genomeWideDataDistanceDistribution["plot_count_RNAs"]',genomeWideDataDistanceDistribution["plot_count_RNAs"])
        let color_list = []
        if (Object.keys(genomeWideDataDistanceDistribution).length !== 0) {
            color_list = repeat(['#1f77b4','#ff7f0e','#2ca02c','#d62728','#9467bd','#8c564b','#e377c2','#7f7f7f','#bcbd22','#17becf'], Math.floor(Object.keys(genomeWideDataDistanceDistribution['exps']).length / ['#1f77b4','#ff7f0e','#2ca02c','#d62728','#9467bd','#8c564b','#e377c2','#7f7f7f','#bcbd22','#17becf'].length) + 1) 
        }
        // console.log('color_list',color_list)

        var data_Homo_sapiens = {'data': [], 'organism': "Homo sapiens", 'data_for_table': [], 'data_non_gene_centric': []}
        var data_Mus_musculus = {'data': [], 'organism': "Mus musculus", 'data_for_table': [], 'data_non_gene_centric': []}
        // let oooooo = ['Xist 0hr', 'Xist 1hr','Xist 2hr','Xist 3hr','Xist 6hr']
        // console.log('Object.keys(genomeWideDataDistanceDistribution[exps])',genomeWideDataDistanceDistribution['exps'][Object.keys(genomeWideDataDistanceDistribution['exps'])[0]]['count'])
        for (var ID in genomeWideDataDistanceDistribution['exps']) {
                // id=id+1
                var data_for_profile = []
                plot_count += 1
                // let data = plot_count % 2 === 1 ? data_list[0] : data_list[1]
                selectedNormalization = genomeWideDataDistanceDistribution['exps'][ID]['selectedNormalization']
                // let for_description = Heatmap_description(genomeWideDataDistanceDistribution[experiment_id]['count'][4])
                let table_element_description = genomeWideDataDistanceDistribution['exps'][ID]['count'][4]
                var organism = genomeWideDataDistanceDistribution['exps'][ID].count[3]

                let trackDescription = Track_description(genomeWideDataDistanceDistribution['exps'][ID].count[0].map(function(item) {return item.split('|*|')}).map(function(item) {return item[1]}))
                
                let trackkk = genomeWideDataDistanceDistribution['exps'][ID].count[0].map(function(item) {return item.split('|*|')}).map(function(item) {return item[0]})
                // console.log('qqq trackkk',trackkk)
                for (var numberOfcells = 0; numberOfcells < trackkk.length; numberOfcells ++) {
                    data_for_profile.push(
                        {'track': [trackkk[numberOfcells].replace(/<br>/g, ' '), "ID"],
                        'method': [table_element_description[numberOfcells].replace(/<br>/g, ' ').split('exp.protocol: ')[1].split('; extra:')[0], "Method"],
                        'localContactsCount': [genomeWideDataDistanceDistribution['exps'][ID].count[1][numberOfcells], "Local contacts"],
                        'transContactsCount': [genomeWideDataDistanceDistribution['exps'][ID].count[2][numberOfcells], "Trans contacts"],
                        // 'color': 'none',
                        'trackDescription': [trackDescription[numberOfcells].replace(/<br>/g, ' '), "Track description"],
                        'cellLine': [table_element_description[numberOfcells].replace(/<br>/g, ' ').split('cell line: ')[1].split('; exp.protocol:')[0], "Cell line"],
                        'datasetDescription': [table_element_description[numberOfcells].replace(/<br>/g, ' ').split('extra: ')[1], "Exp.description"],
                        'typeOfExperiment': [genomeWideDataDistanceDistribution['exps'][ID]['typeOfExperiment'], "Data type"],
                        }
                    )
                }
                if (organism.slice(1,-1) === "Homo sapiens") {
                    data_Homo_sapiens['data_for_table'].push(data_for_profile[0])
                    data_Homo_sapiens['data'].push({
                                                        x: genomeWideDataDistanceDistribution['exps'][ID].x,
                                                        y: genomeWideDataDistanceDistribution['exps'][ID].y,
                                                        name: data_for_profile[0]['method'][0] + ", " + "Exp." + trackkk[0].split(" (")[0],
                                                        // backgroundColor: '#d3d4d5',
                                                        xaxis: 'x1',
                                                        yaxis: 'y1',
                                                        autobinx: false,
                                                        scale: false,
                                                        marker: {
                                                            // color: 'rgb(0,0,0)',
                                                            size: 4,
                                                            opacity: 1,
                                                            color: trackNameAndColor[trackkk[0]], //color_list[ID],
                                                            // line: {
                                                            //     color: 'rgb(0,0,0)',
                                                            //     width: 1
                                                            // }
                                                            },
                                                        mode: 'lines+markers', //lines+markers
                                                        type: 'scatter',
                                                        hovertemplate: '%{y:.3f}',
                                                    },
                                                )
                    data_Homo_sapiens['data_non_gene_centric'].push({
                                                        x: genomeWideDataDistanceDistribution['exps'][ID]['x_non_gene_centric'],
                                                        y: genomeWideDataDistanceDistribution['exps'][ID]['y_non_gene_centric'],
                                                        name: data_for_profile[0]['method'][0] + ", " + "Exp." + trackkk[0].split(" (")[0],
                                                        // backgroundColor: '#d3d4d5',
                                                        xaxis: 'x1',
                                                        yaxis: 'y1',
                                                        autobinx: false,
                                                        scale: false,
                                                        marker: {
                                                            // color: 'rgb(0,0,0)',
                                                            size: 4,
                                                            opacity: 1,
                                                            color: trackNameAndColor[trackkk[0]], //color_list[ID],
                                                            // line: {
                                                            //     color: 'rgb(0,0,0)',
                                                            //     width: 1
                                                            // }
                                                            },
                                                        mode: 'lines+markers', //lines+markers
                                                        type: 'scatter',
                                                        hovertemplate: '%{y:.3f}',
                                                },
                                            )
                    if (genomeWideDataDistanceDistribution["plot_count_RNAs"] === 1) {
                        data_Homo_sapiens['data'].push(
                                                        {
                                                            x: genomeWideDataDistanceDistribution['exps'][ID].x,
                                                            y: genomeWideDataDistanceDistribution['exps'][ID].count_gene_name,
                                                            name: trackkk[0] + ' (count RNAs)',
                                                            // autobinx: false, 
                                                            yaxis: 'y2',
                                                            marker: {
                                                                size: 4,
                                                                opacity: 0.7,
                                                                color: trackNameAndColor[trackkk[0]], //color_list[ID],
                                                                },
                                                            mode: 'lines+markers',
                                                            hovertemplate: '%{y:.3f}',
                                                            // xbins: {
                                                            //     end: 28, 
                                                            //     size: 1, 
                                                            //     start: 4
                                                            //   }
                                                        },
                                                    )
                    }
                } else {
                    data_Mus_musculus['data_for_table'].push(data_for_profile[0])
                    data_Mus_musculus['data'].push(
                                                    {
                                                        x: genomeWideDataDistanceDistribution['exps'][ID].x,
                                                        y: genomeWideDataDistanceDistribution['exps'][ID].y,
                                                        // name: trackkk[0], //oooooo[plot_count-1]
                                                        name: data_for_profile[0]['method'][0] + ", " + "Exp." + trackkk[0].split(" (")[0],
                                                        xaxis: 'x1',
                                                        yaxis: 'y1',
                                                        autobinx: false,
                                                        scale: false,
                                                        marker: {
                                                            size: 4,
                                                            opacity: 1,
                                                            color: trackNameAndColor[trackkk[0]], //color_list[ID],
                                                            },
                                                        mode: 'lines+markers', //lines+markers
                                                        type: 'scatter',
                                                        hovertemplate: '%{y:.3f}',
                                                    },
                                                )
                    data_Mus_musculus['data_non_gene_centric'].push({
                                                    x: genomeWideDataDistanceDistribution['exps'][ID]['x_non_gene_centric'],
                                                    y: genomeWideDataDistanceDistribution['exps'][ID]['y_non_gene_centric'],
                                                    name: data_for_profile[0]['method'][0] + ", " + "Exp." + trackkk[0].split(" (")[0],
                                                    // backgroundColor: '#d3d4d5',
                                                    xaxis: 'x1',
                                                    yaxis: 'y1',
                                                    autobinx: false,
                                                    scale: false,
                                                    marker: {
                                                        size: 4,
                                                        opacity: 1,
                                                        color: trackNameAndColor[trackkk[0]], //color_list[ID],
                                                        },
                                                    mode: 'lines+markers', //lines+markers
                                                    type: 'scatter',
                                                    hovertemplate: '%{y:.3f}',
                                            },
                                        )
                    if (genomeWideDataDistanceDistribution["plot_count_RNAs"] === 1) {
                        data_Mus_musculus['data'].push(
                                                        {
                                                            x: genomeWideDataDistanceDistribution['exps'][ID].x,
                                                            y: genomeWideDataDistanceDistribution['exps'][ID].count_gene_name,
                                                            name: trackkk[0] + ' (count RNAs)',
                                                            // autobinx: false, 
                                                            yaxis: 'y2',
                                                            marker: {
                                                                size: 4,
                                                                opacity: 0.7,
                                                                color: trackNameAndColor[trackkk[0]], //color_list[ID],
                                                                },
                                                            mode: 'lines+markers',
                                                            hovertemplate: '%{y:.3f}',
                                                            // xbins: {
                                                            //     end: 28, 
                                                            //     size: 1, 
                                                            //     start: 4
                                                            //   }
                                                        },
                                                    )
                    }
                }   
            }
            if (data_Homo_sapiens['data'].length !== 0) {
                Homo_sapiens_plot = 
                    <div id='Homo_sapiens'>
                        <Grid container spacing={0} className={classes.root}>
                            <Grid item xs={6}>
                                {genomeWideDataDistanceDistribution['exps'][Object.keys(genomeWideDataDistanceDistribution['exps'])[0]]['count'][0][0].split(")")[0].split("(")[1].includes("RNAs") ? 
                                    <Typography color="error" variant="h5">
                                        Since "{genomeWideDataDistanceDistribution['exps'][Object.keys(genomeWideDataDistanceDistribution['exps'])[0]]['count'][0][0].split(")")[0].split("(")[1]}" is multi-copy, we cannot determine the true parental gene for each contact. Therefore, we cannot plot "Distance Distribution" for "{genomeWideDataDistanceDistribution['exps'][Object.keys(genomeWideDataDistanceDistribution['exps'])[0]]['count'][0][0].split(")")[0].split("(")[1]}". 
                                    </Typography>
                                :
                                <div>
                                    {state.checkedB ? 
                                        <Plot
                                            data={data_Homo_sapiens['data']}
                                            layout = {{
                                                showlegend: true,
                                                autosize: false,
                                                width: 630,
                                                height: 550,
                                                title:`<b>${data_Homo_sapiens['data_for_table'][0]['track'][0].split("(")[1].split(")")[0]}</b>, <i>${data_Homo_sapiens['organism']}</i>, <b>${normalizationName(selectedNormalization)}</b>`, 
                                                titlefont: { size: 20 },
                                                // margin: {t: 50},
                                                // hovermode: 'closest',
                                                bargap: 0,
                                                xaxis: {
                                                    domain: [0, 1],
                                                    showgrid: true,
                                                    showticklabels: false,
                                                    zeroline: false,
                                                    title: "Log2(Distance)",
                                                    titlefont: { size: 20 },
                                                },
                                                yaxis: {
                                                    domain: [0, 0.85],
                                                    showgrid: true,
                                                    zeroline: false,
                                                    title: 'Log2(Density)',
                                                    titlefont: { size: 20 },
                                                },
                                                yaxis2: {
                                                    domain: [0.85, 1],
                                                    showgrid: true,
                                                    zeroline: false,
                                                    title: 'Log2(Count RNAs)',
                                                    titlefont: { size: 15 },
                                                },
                                                shapes: shapes_rect, //?????????
                                                annotations: bin_lables,
                                            }}
                                            config = {{
                                                modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','lasso2d', 'select2d'], //,'hoverClosestCartesian'
                                                // displayModeBar: true,
                                                // scrollZoom: true,
                                                displaylogo: false,
                                                // editable: true,
                                                doubleClickDelay: 1000,
                                                toImageButtonOptions: {format: 'svg', filename: 'Distance distribution', scale: 1},
                                            }}
                                        />
                                :
                                        <Plot
                                            data={data_Homo_sapiens['data_non_gene_centric']}
                                            layout = {{
                                                showlegend: true,
                                                autosize: false,
                                                width: 630,
                                                height: 550,
                                                title:`<b>${data_Homo_sapiens['data_for_table'][0]['track'][0].split("(")[1].split(")")[0]}</b>, <i>${data_Homo_sapiens['organism']}</i>, <b>${normalizationName(selectedNormalization)}</b>`, 
                                                titlefont: { size: 20 },
                                                // margin: {t: 50},
                                                // hovermode: 'closest',
                                                bargap: 0,
                                                xaxis: {
                                                    domain: [0, 1],
                                                    showgrid: true,
                                                    showticklabels: false,
                                                    zeroline: false,
                                                    title: "Log2(Distance)",
                                                    titlefont: { size: 20 },
                                                },
                                                yaxis: {
                                                    domain: [0, 0.85],
                                                    showgrid: true,
                                                    zeroline: false,
                                                    title: 'Log2(Density)',
                                                    titlefont: { size: 20 },
                                                },
                                                yaxis2: {
                                                    domain: [0.85, 1],
                                                    showgrid: true,
                                                    zeroline: false,
                                                    title: 'Log2(Count RNAs)',
                                                    titlefont: { size: 15 },
                                                },
                                                // shapes: shapes_rect, //?????????
                                                annotations: bin_lables_non_gene_centric,
                                            }}
                                            config = {{
                                                modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','lasso2d', 'select2d'], //,'hoverClosestCartesian'
                                                // displayModeBar: true,
                                                // scrollZoom: true,
                                                displaylogo: false,
                                                // editable: true,
                                                doubleClickDelay: 1000,
                                                toImageButtonOptions: {format: 'svg', filename: 'Distance distribution', scale: 1},
                                            }}
                                        />
                                }
                                <div style={{textAlign: "center"}}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={state.checkedB}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                        }
                                        label="gene-centric line chart"
                                    />
                                </div>
                                </div>
                            }
                            </Grid>
                            <Grid item xs={6}>
                                <EnhancedTABLEpart  from={props.from}  data={data_Homo_sapiens['data_for_table']} trackNameAndColor={trackNameAndColor}/>
                                {/* <Typography color="error" variant="h3">
                                    y = -0.003x + 121212
                                </Typography>
                                <Typography color="error" variant="h3">
                                    R2 = 0.72
                                </Typography>
                                <Typography color="error" variant="h3">                            
                                    Stats.mannwhitneyu = ....
                                </Typography> */}
                            </Grid>
                        </Grid>
                    </div>
            }
            if (data_Mus_musculus['data'].length !== 0) {
                Mus_musculus_plot = 
                    <div id='Mus_musculus'>
                        <Grid container spacing={0} className={classes.root}>
                            <Grid item xs={6}>
                                {genomeWideDataDistanceDistribution['exps'][Object.keys(genomeWideDataDistanceDistribution['exps'])[0]]['count'][0][0].split(")")[0].split("(")[1].includes("RNAs") ? 
                                    <Typography color="error" variant="h5">
                                        Since "{genomeWideDataDistanceDistribution['exps'][Object.keys(genomeWideDataDistanceDistribution['exps'])[0]]['count'][0][0].split(")")[0].split("(")[1]}" is multi-copy, we cannot determine the true parental gene for each contact. Therefore, we cannot plot "Distance Distribution" for "{genomeWideDataDistanceDistribution['exps'][Object.keys(genomeWideDataDistanceDistribution['exps'])[0]]['count'][0][0].split(")")[0].split("(")[1]}". 
                                    </Typography>
                                :
                                <div>
                                {state.checkedB ?
                                    <Plot
                                        data={data_Mus_musculus['data']}
                                        layout = {{
                                            showlegend: true,
                                            autosize: false,
                                            width: 630,
                                            height: 550,
                                            title:`<b>${data_Mus_musculus['data_for_table'][0]['track'][0].split("(")[1].split(")")[0]}</b>, <i>${data_Mus_musculus['organism']}</i>, <b>${normalizationName(selectedNormalization)}</b>`, 
                                            // title:`Organism: <b>${data_Mus_musculus['organism']}</b>, normalization: <b>${selectedNormalization}</b>`, 
                                            titlefont: { size: 20 },
                                            // margin: {t: 50},
                                            // hovermode: 'closest',
                                            bargap: 0,
                                            xaxis: {
                                                domain: [0, 1],
                                                showgrid: true,
                                                showticklabels: false,
                                                zeroline: false,
                                                title: "Log2(Distance)",
                                                titlefont: { size: 20 },
                                            },
                                            yaxis: {
                                                domain: [0, 0.85],//domain: [0, 0.1],
                                                showgrid: true,
                                                zeroline: false,
                                                title: 'Log2(Density)',
                                                titlefont: { size: 20 },
                                            },
                                            yaxis2: {
                                                domain: [0.85, 1],
                                                showgrid: true,
                                                zeroline: false,
                                                title: 'Log2(Number of RNAs)',
                                                titlefont: { size: 15 },
                                            },
                                            shapes: shapes_rect, //?????????
                                            annotations: bin_lables,
                                        }}
                                        config = {{
                                            modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','lasso2d', 'select2d'],
                                            // modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','lasso2d','select2d'],
                                            // displayModeBar: true,
                                            // scrollZoom: true,
                                            displaylogo: false,
                                            // editable: true,
                                            doubleClickDelay: 1000,
                                            toImageButtonOptions: {format: 'svg', filename: 'Distance distribution', scale: 1},
                                        }}
                                    />
                                :
                                <Plot
                                        data={data_Mus_musculus['data_non_gene_centric']}
                                        layout = {{
                                            showlegend: true,
                                            autosize: false,
                                            width: 630,
                                            height: 550,
                                            title:`<b>${data_Mus_musculus['data_for_table'][0]['track'][0].split("(")[1].split(")")[0]}</b>, <i>${data_Mus_musculus['organism']}</i>, <b>${normalizationName(selectedNormalization)}</b>`, 
                                            // title:`Organism: <b>${data_Mus_musculus['organism']}</b>, normalization: <b>${selectedNormalization}</b>`, 
                                            titlefont: { size: 20 },
                                            // margin: {t: 50},
                                            // hovermode: 'closest',
                                            bargap: 0,
                                            xaxis: {
                                                domain: [0, 1],
                                                showgrid: true,
                                                showticklabels: false,
                                                zeroline: false,
                                                title: "Log2(Distance)",
                                                titlefont: { size: 20 },
                                            },
                                            yaxis: {
                                                domain: [0, 0.85],//domain: [0, 0.1],
                                                showgrid: true,
                                                zeroline: false,
                                                title: 'Log2(Density)',
                                                titlefont: { size: 20 },
                                            },
                                            yaxis2: {
                                                domain: [0.85, 1],
                                                showgrid: true,
                                                zeroline: false,
                                                title: 'Log2(Number of RNAs)',
                                                titlefont: { size: 15 },
                                            },
                                            annotations: bin_lables_non_gene_centric,
                                        }}
                                        config = {{
                                            modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','lasso2d', 'select2d'],
                                            // modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','lasso2d','select2d'],
                                            // displayModeBar: true,
                                            // scrollZoom: true,
                                            displaylogo: false,
                                            // editable: true,
                                            doubleClickDelay: 1000,
                                            toImageButtonOptions: {format: 'svg', filename: 'Distance distribution', scale: 1},
                                        }}
                                    />
                                }
                                <div style={{textAlign: "center"}}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={state.checkedB}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                        }
                                        label="gene-centric line chart"
                                    />
                                </div>
                                </div>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <EnhancedTABLEpart  from={props.from} data={data_Mus_musculus['data_for_table']} trackNameAndColor={trackNameAndColor}/>
                                {/* <Typography color="error" variant="h3">
                                    y = -0.003x + 121212
                                </Typography>
                                <Typography color="error" variant="h3">
                                    R2 = 0.72
                                </Typography>
                                <Typography color="error" variant="h3">                            
                                    Stats.mannwhitneyu = ....
                                </Typography> */}
                            </Grid>
                        </Grid>
                    </div>
            }
            // console.log('data_Mus_musculus', data_Mus_musculus)
            // console.log('data_Homo_sapiens', data_Homo_sapiens)
        return (
            <div id='myDiv'>
                {/* <CSVDownload 
                  data={csvData} 
                  separator={"\n"} 
                  target="_self"
                /> */}
                {Homo_sapiens_plot}
                {Mus_musculus_plot}
                {/* <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={6}>
                        {data_left}
                    </Grid>
                    <Grid item xs={6}>
                        {data_right}
                    </Grid>
                </Grid> */}
            </div>
            // <div>
            //     {steps}
            // </div>

        )
}


const mapStateToProps = (state) => {
    return {
        genomeWideDataDistanceDistribution: state[module].genomeWideDataDistanceDistribution,
    }
}
    
  
export default connect( mapStateToProps )(DistanceDistribution);