import { combineReducers } from 'redux'
import menuReducer from './menuReducer';
import textReducer from './textReducer';
import genesReducer from './genes';
import tableReducer from './table';
import genomeBrowserReducer from './genomeBrowserReducer';
import plotsReducer from './plotsReducer';
import filterReducer from './filterReducer';
import basicAnalysisReducer from './basicAnalysisReducer';
// import downloadReducer from './downloadReducer';

const rootReducer = combineReducers({
    menu: menuReducer,
    text: textReducer,
    genes: genesReducer,
    table: tableReducer,
    genomeBrowser: genomeBrowserReducer,
    plots: plotsReducer,
    filter: filterReducer,
    basicAnalysis: basicAnalysisReducer,
    // download: downloadReducer,
})

export default rootReducer;