import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Decorator from '../components/HtmlTooltip';
import Grid from '@material-ui/core/Grid';

import { setTrackListCheckbox, setTrackListCheckboxState } from '../actions/filterActions';

const moduleFilter = "filter";
const module = "menu";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    // width: '100%',
    minHeight: 10,
    height: 40,
    padding: 0,
  },
  root: {
    flexGrow: 1,
    // paddingTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
  },
  input: {
    '.MuiOutlinedInput-input' : {
        // padding: '8px 8px',
        padding: 0,
    }
  },
  smallPadding: {
    height: 30,
    paddingTop: '5px !important',
    paddingRight: '30px !important',
    paddingBottom: '5px !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
}));

const TrackListCheckboxesNew = (props) => {
  
    const classes = useStyles();
    const [distributionOfRNAparts, setDistributionOfRNAparts] = useState(false)

    useEffect(() => {
      if (props.headerName === ": distribution of RNA-parts across their source gene body" || props.headerName === ": metadata") {
        setDistributionOfRNAparts(true)
      }
    }, [props.headerName])
    
    const handleChange = (event) => {
        // setItem(event.target.value);
        props.setTrackListCheckbox(event.target.value)
        props.setTrackListCheckboxState(event.target.value, true)
      };

    return (
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item>
          {/* <Tooltip
            placement={"right-start"}
            title={
            <React.Fragment>
              <div >
                <Typography>
                  <b>Choose one of four data normalizations:</b>
                  <ul>
                    <li><b>Normalized</b> — background normalized contacts</li>
                    <li><b>Raw</b> — contacts without background normalization</li>
                    <li><b>Norm. & in peaks (one-to-all)</b> — background normalized contacts crossing MACS2 peaks (only available for one-to-all data)</li>
                    <li><b>Raw & in peaks (one-to-all)</b> — contacts without background normalization crossing MACS2 peaks (only available for one-to-all data)</li>
                  </ul>
                </Typography>
              </div>
            </React.Fragment>
            }
          > */}
              <FormControl variant="outlined" className={classes.formControl} disabled={props.disabled}>
                <Select
                  value={props.trackListCheckbox}//{item}
                  onChange={handleChange}
                  classes={{
                    root: classes.smallPadding,
                  }}
                >
                  <MenuItem value={'norm_value'}><b>Normalized</b></MenuItem>  {/* Background norm. */} 
                  <MenuItem value={'observed_value'}><b>Raw</b></MenuItem> {/* Without norm. */}
                  {/* <MenuItem value={'expected_value'}><b>input</b></MenuItem>  */}
                  <MenuItem disabled={distributionOfRNAparts} value={'norm_value_in_peak'}><b>Norm. & in peaks (one-to-all)</b></MenuItem>                   
                  <MenuItem disabled={distributionOfRNAparts} value={'observed_value_in_peak'}><b>Raw & in peaks (one-to-all)</b></MenuItem>
                  {/* <MenuItem value={'observed_value'}><i>Enrichment normalization</i></MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
          {/* </Tooltip> */}
            <Grid item style={{marginLeft: 7, marginTop: 7,}}>
              <Decorator 
                header={<b>Choose one of 4 data normalizations</b>}
                text = {<Typography>
                          <ul>
                            <li><b>Normalized</b> — background-normalized contacts</li>
                            <li><b>Raw</b> — not-normalized contacts</li> {/* contacts without background normalization */}
                            <li><b>Norm. & in peaks (one-to-all)</b> — background-normalized contacts crossing MACS2 peaks (only available for one-to-all data)</li>
                            <li><b>Raw & in peaks (one-to-all)</b> — not-normalized contacts crossing MACS2 peaks (only available for one-to-all data)</li>
                          </ul>
                        </Typography>
                        }
                component = {
                    <HelpIcon />
                }
              />
            </Grid>
          </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        trackListCheckboxState: state[moduleFilter].trackListCheckboxState,  
        trackListCheckbox: state[moduleFilter].trackListCheckbox,  
        headerName: state[module].headerName,
    };
  };
  
  export default connect(mapStateToProps, {setTrackListCheckbox, setTrackListCheckboxState})(TrackListCheckboxesNew);