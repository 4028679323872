export const SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_FAILURE";

export const submitCheckedTracksForDNApartProfile = (checkedTracks, typeOfContacts, selectedTrackNames, selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};

export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_FAILURE";

export const submitCheckedTracksForRNApartProfile = (checkedTracks, typeOfContacts, selectedTrackNames, selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_REQUEST,
        // experiment_id,
        // gene_name
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};


export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_FAILURE";

export const submitCheckedTracksForRNApartProfileTable = (checkedTracks, typeOfContacts, selectedTrackNames, selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};

export const SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_FAILURE";

export const submitAllTargetGenesTable = (params) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_REQUEST,
        params,
    };
};


export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_FAILURE";

export const submitCheckedTracksForProfile = (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};

export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_FAILURE";

export const submitCheckedTracksForHeatmap = (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};

export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_FAILURE";

export const submitCheckedTracksForHeatmapComparative = (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};

export const SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_REQUEST = "SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_REQUEST";
export const SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_SUCCESS = "SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_SUCCESS";
export const SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_FAILURE = "SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_FAILURE";

export const submitCheckedTracksForDistanceDistribution = (checkedTracks,typeOfContacts,selectedTrackNames,selectedNormalization) => {
    return {
        type: SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_REQUEST,
        checkedTracks,
        typeOfContacts,
        selectedTrackNames,
        selectedNormalization,
    };
};