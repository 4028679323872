export const FETCH_GENES_REQUEST = "FETCH_GENES_REQUEST";
export const FETCH_GENES_SUCCESS = "FETCH_GENES_SUCCESS";
export const FETCH_GENES_FAILURE = "FETCH_GENES_FAILURE";

export const fetchGenes = (selectedOrganism) => {
    return {
        type: FETCH_GENES_REQUEST,
        selectedOrganism,
    };
};

export const HANDLE_GENE_SELECT = "HANDLE_GENE_SELECT";

export const handleGeneSelect = (geneName) => {
    return {
        type: HANDLE_GENE_SELECT,
        geneName,
    };
};

export const HANDLE_CURRENT_GENE_INPUT = "HANDLE_CURRENT_GENE_INPUT"

export const changeCurrentGeneInput = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_INPUT,
        value,
    };
};





/* export const SUBMIT_GENE_TYPES_REQUEST = "SUBMIT_GENE_TYPES_REQUEST";
export const SUBMIT_GENE_TYPES_SUCCESS = "SUBMIT_GENE_TYPES_SUCCESS";
export const SUBMIT_GENE_TYPES_FAILURE = "SUBMIT_GENE_TYPES_FAILURE";

export const submitGeneTypes = (geneTypes) => {
    return {
        type: SUBMIT_GENE_TYPES_REQUEST,
        geneTypes,
    };
}; */

export const FETCH_GENE_TYPES_REQUEST = "FETCH_GENE_TYPES_REQUEST";
export const FETCH_GENE_TYPES_SUCCESS = "FETCH_GENE_TYPES_SUCCESS";
export const FETCH_GENE_TYPES_FAILURE = "FETCH_GENE_TYPES_FAILURE";

export const fetchGeneTypes = (selectedOrganism) => {
    return {
        type: FETCH_GENE_TYPES_REQUEST,
        selectedOrganism,
    };
};

export const HANDLE_GENE_TYPES_SELECT = "HANDLE_GENE_TYPES_SELECT";

export const handleGeneTypesSelect = (geneTypeName) => {
    return {
        type: HANDLE_GENE_TYPES_SELECT,
        geneTypeName,
    };
};

export const HANDLE_CURRENT_GENE_TYPES_INPUT = "HANDLE_CURRENT_GENE_TYPES_INPUT"

export const changeCurrentGeneTypesInput = (value) => {
    return {
        type: HANDLE_CURRENT_GENE_TYPES_INPUT,
        value,
    };
};



export const FETCH_ANNOTATION_REQUEST = "FETCH_ANNOTATION_REQUEST";
export const FETCH_ANNOTATION_SUCCESS = "FETCH_ANNOTATION_SUCCESS";
export const FETCH_ANNOTATION_FAILURE = "FETCH_ANNOTATION_FAILURE";

export const fetchAnnotation = (selectedOrganism) => {
    return {
        type: FETCH_ANNOTATION_REQUEST,
        selectedOrganism,
    };
};

export const HANDLE_ANNOTATION_SELECT = "HANDLE_ANNOTATION_SELECT";

export const handleAnnotationSelect = (annotationName) => {
    return {
        type: HANDLE_ANNOTATION_SELECT,
        annotationName,
    };
};

export const HANDLE_CURRENT_ANNOTATION_INPUT = "HANDLE_CURRENT_ANNOTATION_INPUT"

export const changeCurrentAnnotationInput = (value) => {
    return {
        type: HANDLE_CURRENT_ANNOTATION_INPUT,
        value,
    };
};


export const TRACK_NAME_ADD = "TRACK_NAME_ADD";
export const TRACK_NAME_DELETE = "TRACK_NAME_DELETE";
export const TRACK_NAME_SELECT = "TRACK_NAME_SELECT";

export const trackNameAdd = (add_to_Track_name_list) => {
    return {
        type: TRACK_NAME_ADD,
        add_to_Track_name_list,
    };
};

export const trackNameDelete = (selected_list) => {
    return {
        type: TRACK_NAME_DELETE,
        selected_list,
    };
};

export const trackNameSelect = (selected_list) => {
    return {
        type: TRACK_NAME_SELECT,
        selected_list,
    };
};


export const CELL_TYPE_ADD = "CELL_TYPE_ADD";
export const CELL_TYPE_DELETE = "CELL_TYPE_DELETE";
export const CELL_TYPE_SELECT_ONE = "CELL_TYPE_SELECT_ONE";
export const INDEX_FOR_CELL_TYPE_SELECT_ONE = "INDEX_FOR_CELL_TYPE_SELECT_ONE";

export const cellTypeAdd = () => {
    return {
        type: CELL_TYPE_ADD,
    };
};

export const cellTypeDelete = (selected_indexes) => {
    return {
        type: CELL_TYPE_DELETE,
        selected_indexes,
    };
};

export const cellTypeSelect = (cellType) => {
    return {
        type: CELL_TYPE_SELECT_ONE,
        cellType,
    };
};

export const indexForCellTypeSelect = (i) => {
    return {
        type: INDEX_FOR_CELL_TYPE_SELECT_ONE,
        i,
    };
};


export const TRACK_LIST_ADD = "TRACK_LIST_ADD";
export const TRACK_LIST_DELETE = "TRACK_LIST_DELETE";
export const TRACK_LIST_SELECT = "TRACK_LIST_SELECT";

export const trackListAdd = (add_to_Track_list) => {
    return {
        type: TRACK_LIST_ADD,
        add_to_Track_list,
    };
};

export const trackListDelete = (selected_list) => {
    return {
        type: TRACK_LIST_DELETE,
        selected_list,
    };
};

export const trackListSelect = (selected_list) => {
    return {
        type: TRACK_LIST_SELECT,
        selected_list,
    };
};

export const TYPE_OF_CONTACTS_CHANGE = "TYPE_OF_CONTACTS_CHANGE";

export const typeOfContactsChange = (TypeOfContacts) => {
    return {
        type: TYPE_OF_CONTACTS_CHANGE,
        newTypeOfContacts: TypeOfContacts,
    };
};

/* export const ADD_GENE = "ADD_GENE";

export const addGene = (gene) => {
    return {
        type: ADD_GENE,
        gene,
    };
};

export const DELETE_GENE = "DELETE_GENE";

export const deleteGene = (index, last) => {
    return {
        type: DELETE_GENE,
        index,
        last
    };
};

export const CHANGE_INPUT = "CHANGE_INPUT";

export const changeInput = (value) => {
    return {
        type: CHANGE_INPUT,
        value,
    };
}; */

export const CLEAR_RNA = "CLEAR_RNA";

export const clearRNAGenes = () => {
    return {
        type: CLEAR_RNA,
    };
};


export const CLEAR_RNA_GENE_SELECTOR_FROM_DNA = "CLEAR_RNA_GENE_SELECTOR_FROM_DNA";

export const clearRNAGeneSelectorFromDNA = () =>{ 
    return {
        type: CLEAR_RNA_GENE_SELECTOR_FROM_DNA,
    };
};