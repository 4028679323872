import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';

import Decorator from '../components/HtmlTooltip';

import { handleSelectedColumns } from '../actions/generalActions';

const module = "table";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ColumnSelector = (props) => {
    const classes = useStyles();
    
    // let [selectedColumns, handleSelectedColumns] = React.useState([]);
    // const { columns, columnsMap } = props;
    let [columnsMap, handleColumnsMap] = useState({});
    const [columns, handleColumns] = useState([]);
    // console.log('selectedColumns',props.selectedColumns)

    useEffect(() => {
        // console.log('________________________________________________',props.selectedColumns)
        let selectedColumnsInit = [
            "Name",
            "Track",
            "Type",
            "N-norm",
            "CPKM(N-norm)",
            "Organism",
            "Gene locus",
            "Str.",
            "ID",
            // "Track description",
            // 'Track description for tooltip',
            // 'Track description for plots',
        ];
        if (props.selectedColumns.length === 0) {
            props.handleSelectedColumns(selectedColumnsInit)
        }
        // console.log(')))))))))))))))^&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7_props.columnsMap',props.columnsMap)
        // console.log(')))))))))))))))^&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7_props.columns',props.columns)    
        // console.log(')))))))))))))))^&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7',selectedColumnsInit)
        handleColumnsMap(props.columnsMap);
        handleColumns(props.columns);
    }, [])

    const swapElements = (first, second) => {
        let tmp = first;
        first = second;
        second = tmp;
    }

    const handleChange = event => {
        // console.log('event.target.value',event.target.value)
        props.handleSelectedColumns(event.target.value);
        let fake_array = ['Track description','Track description for tooltip','Track description for plots']
        fake_array = fake_array.concat(event.target.value)
        let newColumns = fake_array.map(value => {
            return columnsMap[value];
        });
        // console.log('newColumns',newColumns)
        let index = newColumns.indexOf("experiment_id");
        if (index === -1) {
            newColumns.push("experiment_id");
        } else {
            swapElements(newColumns[index], newColumns[newColumns.length- 1]);
        }
        props.setColumns(newColumns);
    };

    const showAll = () => {
        // console.log('columns',columns)
        let newColumnsHide = []
        for (var i = 0; i < columns.length; i++) {
            if (['Track description','Track description for tooltip','Track description for plots'].indexOf(columns[i]) === -1) {
                newColumnsHide.push(columns[i])
            }            
        }
        props.handleSelectedColumns(newColumnsHide);
        let newColumns = columns.map(value => {
            return columnsMap[value];
        });

        let index = newColumns.indexOf("experiment_id");
        if (index === -1) {
            newColumns.push("experiment_id");
        } else {
            swapElements(newColumns[index], newColumns[newColumns.length - 1]);
        }
        props.setColumns(newColumns);
    }

    const hideAll = () => {
        let newColumnsHide = ['Name', 'Track', 'ID', 'Organism'];//, 'Gene locus'
        props.handleSelectedColumns(newColumnsHide);
        let newColumns = ['Name', 'Track', 'ID','Organism','Track description','Track description for tooltip','Track description for plots'].map(value => { //,'Gene locus'
            return columnsMap[value];
        });
        props.setColumns(newColumns);
    }

    return (
        <div>
            <Grid
                container
                direction={"row"}
                justify={"center"}
                alignItems={"center"}
            >
                <Grid item xs={7}>
                    <FormControl className={classes.formControl}>
                        <InputLabel style={{color: '#009688'}}><u>Select columns to display:</u></InputLabel>
                        <Select
                            multiple
                            value={props.selectedColumns}
                            onChange={handleChange}
                            input={<Input />}
                            renderValue={selected => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                        {columns.map(column => {
                            if ((column !== "Name") && (column !== "Track") && (column !== "ID") && (column !== "Organism") && (column !== "Track description") && (column !== 'Track description for plots') && (column !== 'Track description for tooltip')) {//&& (column !== "Gene locus")
                                return (
                                    <MenuItem key={column} value={column}>
                                        <Checkbox checked={props.selectedColumns.indexOf(column) > -1} />
                                    <ListItemText primary={column} />
                                    </MenuItem>
                                );
                            }
                        })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant={"contained" }
                        color={"primary"}
                        onClick={showAll}
                    >
                        Show all
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant={"contained" }
                        color={"primary"}
                        onClick={hideAll}
                    >
                        Hide all
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Decorator 
                        header={'Full name — Short name:'}
                        text = {<Typography color="inherit">
                                      unprocessed_pseudogene: <b>pseudogene_U1</b>
                                <br/> unitary_pseudogene: <b>pseudogene_U2</b>
                                <br/> translated_unprocessed_pseudogene: <b>pseudogene_TU1</b>
                                <br/> transcribed_unprocessed_pseudogene: <b>pseudogene_TU2</b>
                                <br/> transcribed_unitary_pseudogene: <b>pseudogene_TU3</b>
                                <br/> translated_processed_pseudogene: <b>pseudogene_TP1</b>
                                <br/> transcribed_processed_pseudogene: <b>pseudogene_TP2</b>
                                <br/> processed_pseudogene: <b>pseudogene_P1</b>
                                <br/> polymorphic_pseudogene: <b>pseudogene_P2</b>
                            </Typography>
                        }
                        component = {
                            <HelpIcon />
                        }
                    />
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedColumns: state[module].selectedColumns,
    }
}


export default connect(mapStateToProps, {handleSelectedColumns})(ColumnSelector);