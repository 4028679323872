import React, {useState, useEffect} from 'react';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';

import EnhancedTABLEpart from '../../components/TableGraphicalSummary';
import { Track_description, normalizationName } from './Heatmap_data_layout';
import { getMax } from './DensityAllTargetGenes';

import Grid from '@material-ui/core/Grid';

const module = "plots";

const DNAprofile = (props) => {

    const { genomeWideDataProfile, trackNameAndColor } = props;
    // console.log('datadata genomeWideDataProfile',genomeWideDataProfile)

    var steps = [];
    var i = 1

    const [data, setData] = useState([])
    const [layout, setLayout] = useState({})
    const [dataForProfile, setDataForProfile] = useState([])

    useEffect(() => {
        if (Object.keys(genomeWideDataProfile).length !== 0) {
            var number = 0
            for (var organism in genomeWideDataProfile) {
                number = Object.keys(genomeWideDataProfile[organism]).length - 1
                var typeOfExperiment_array = []
                var table_element_names = []
                var table_rows_color = []
                var table_element_values = []
                var all = []
                var data_for_profile = []
                var contactsType = []
                var shapes_rect = []
                var max_array = []
                var table_element_description = []
                var selectedNormalization = ''
                var chromosomes = []
            
                var flag = true;
                
                var yaxes = 0
                for (var RNA_track in genomeWideDataProfile[organism]) {
                    if (RNA_track !== '-111') {
                        yaxes = yaxes + 1
                        typeOfExperiment_array.push(genomeWideDataProfile[organism][RNA_track]['typeOfExperiment'])
                        table_element_names.push(genomeWideDataProfile[organism][RNA_track]['name'])
                        table_rows_color.push(genomeWideDataProfile[organism][RNA_track]['color'])
                        selectedNormalization = genomeWideDataProfile[organism][RNA_track]['selectedNormalization']
                        table_element_values.push(genomeWideDataProfile[organism][RNA_track]['sum_value'])
                        contactsType.push(genomeWideDataProfile[organism][RNA_track]['typeOfContacts'])
                        var trace = genomeWideDataProfile[organism][RNA_track]['name']
                        table_element_description.push(genomeWideDataProfile[organism][RNA_track]['description_for_plots'])
                        if (flag === true && genomeWideDataProfile[organism][RNA_track]['typeOfContacts'] === 'RNA_part' && genomeWideDataProfile[organism][RNA_track]['type_RNAs'] !== 'exist') {
                            var bins = 2;
                        } else {
                            var bins = 2000;
                            var flag = false;
                        }
                        // max_array.push(Math.max(...genomeWideDataProfile[organism][RNA_track]['y'])*1.5)
                        max_array.push(getMax(...genomeWideDataProfile[organism][RNA_track]['y'])*1.5)
                        let a=Array.from(Array(genomeWideDataProfile[organism][RNA_track]['x'].length)).map(i=>5000000) ///15441349 //1500000
                        all.push(
                            { trace } = {
                                x: genomeWideDataProfile[organism][RNA_track]['x'],
                                y: genomeWideDataProfile[organism][RNA_track]['y'],
                                width: a,
                                // xaxis: 'x2',
                                yaxis: "y" + yaxes.toString(),//'y2',
                                name: genomeWideDataProfile[organism][RNA_track]['description_for_plots'].split("; extra:")[0].split("exp.protocol: ")[1] + ", " + 'Exp.' + genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0].split(" (")[0],
                                type: 'bar',
                                // autobinx: false,
                                // scale: false,
                                // histfunc: "sum",
                                // type: "histogram",
                                opacity: 1,//0.5,
                                hoverinfo: 'y+name',
                                // hoverinfo: 'y',
                                hovertemplate: '%{y:.3f}',
                                marker: {
                                    color: trackNameAndColor[genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0]]//genomeWideDataProfile[organism][RNA_track]['color'],
                                },
                                // nbinsx: bins,
                                }
                            )
                        // console.log('datadata genomeWideDataProfile[organism][RNA_track][name].split(|*|)[0]]',RNA_track, genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0],trackNameAndColor[genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0]])
                        // console.log('datadata trackNameAndColor',trackNameAndColor)
                        // console.log('datadata ___________________________________')
                        chromosomes.push(
                                {
                                    x: 1500000000,
                                    // y: Math.max(...genomeWideDataProfile[organism][RNA_track]['y']),
                                    y: getMax(genomeWideDataProfile[organism][RNA_track]['y']),
                                    xref: 'x1',
                                    yref: 'y' + yaxes.toString(),
                                    text: genomeWideDataProfile[organism][RNA_track]['description_for_plots'].split("; extra:")[0].split("exp.protocol: ")[1] + ", " + 'Exp.' + genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0].split(" (")[0] + ", " + genomeWideDataProfile[organism][RNA_track]['description_for_plots'].split('cell line: ')[1].split('; exp.protocol:')[0],
                                    showarrow: false,
                                }
                            )
                        } else {
                            // var max_heigth = Math.max(...max_array)
                            var chrom = []
                            var chrom_name = []
                            // var chromosomes = []
                            for (var chr in genomeWideDataProfile[organism][RNA_track]) {
                                chrom.push(genomeWideDataProfile[organism][RNA_track][chr]['title_chr'])
                                chrom_name.push(genomeWideDataProfile[organism][RNA_track][chr]['name_chr'])
                            }
                            for (var index = 0; index < chrom_name.length; index ++) {
                                chromosomes.push(
                                    {
                                        x: chrom[index], 
                                        y: 0, //-0.18
                                        xref: 'x1',
                                        // yref: 'y1',
                                        yref: 'paper',
                                        text: chrom_name[index],
                                        arrowhead: 0.7,
                                        arrowsize: 0.7,
                                        // showarrow: false,
                                        ax: 0,
                                        ay: 30,
                                        textangle: '-90',
                                        font: {
                                            family: 'sans serif',
                                            size: 11,
                                            // color: '#ff7f0e'
                                        },
                                    }
            
                                )
                            }
                            for (var chr_id in genomeWideDataProfile[organism][RNA_track]) {
                                shapes_rect.push(
                                    {
                                        type: 'rect',
                                        yref: 'paper',
                                        x0: genomeWideDataProfile[organism][RNA_track][chr_id]['start'],
                                        y0: 0, //max_heigth, //0,
                                        x1: genomeWideDataProfile[organism][RNA_track][chr_id]['end'],
                                        y1: 1, //0.41, //0,
                                        opacity: 0.2,
                                        line: {
                                            color: genomeWideDataProfile[organism][RNA_track][chr_id]['color'],
                                            width: 0
                                        },
                                        fillcolor: genomeWideDataProfile[organism][RNA_track][chr_id]['color'] //'rgba(128, 0, 128, 0.7)'
                                    }
                                )
                            }
                        // }
                    }
                }
                
                // }
                // all.push(chromosomes)
        
                
                let trackDescription = Track_description(table_element_names.map(function(item) {return item.split('|*|')}).map(function(item) {return item[1]}))
                // console.log('trackDescription',trackDescription)
                let trackkk = table_element_names.map(function(item) {return item.split('|*|')}).map(function(item) {return item[0]})
                // console.log('trackkk ',trackkk )
                // console.log('roww typeOfExperiment_array',typeOfExperiment_array)
                for (var numberOfcells = 0; numberOfcells < table_element_description.length; numberOfcells ++) {
                    // console.log('roww typeOfExperiment_array[numberOfcells]',typeOfExperiment_array[numberOfcells])
                    data_for_profile.push(
                        {'track': [trackkk[numberOfcells].replace(/<br>/g, ' '), "ID"],
                        'method': [table_element_description[numberOfcells].replace(/<br>/g, ' ').split('exp.protocol: ')[1].split('; extra:')[0], "Method"],
                        'totalContactsCount': [table_element_values[numberOfcells], "n-reads"],
                        // 'color': table_rows_color[numberOfcells],
                        'trackDescription': [trackDescription[numberOfcells].replace(/<br>/g, ' '), "Track description"],
                        'cellLine': [table_element_description[numberOfcells].replace(/<br>/g, ' ').split('cell line: ')[1].split('; exp.protocol:')[0], "Cell line"],
                        'datasetDescription': [table_element_description[numberOfcells].replace(/<br>/g, ' ').split('extra: ')[1], "Exp.description"],
                        'typeOfExperiment': [typeOfExperiment_array[numberOfcells], "Data type"],
                        }
                    )
                }
            }
            var Layoutt_2={
                height: number < 5 ? 400 : number*100,// 450,                            
                width: window.innerWidth*0.55,//window.innerWidth*0.99, //width: 1200,  
                titlefont: { size: 20 },
                // barmode: "overlay",
                // hovermode:'closest',
                // title: `Contacts profile, exp_id = ${organism}, ${contactsType}`,
                // title:`Organism: <i><b>${organism.slice(1,-1)}</b></i>, normalization: <b>${selectedNormalization}</b>`, //, ${contactsType}
                title:`<b>${table_element_names[0].split(")")[0].split("(")[1]}</b>, <i>${organism.slice(1,-1)}</i>, <b>${normalizationName(selectedNormalization)}</b>`,
                // textangle: -90,
                shapes: shapes_rect,
                annotations: chromosomes,
                legend: {traceorder: 'reversed'},
                xaxis: {
                    // ticks: '',
                    showticklabels: false
                  },
            }

            var start_index = 0
            var end_index = 0
            var step = 1/number
            for (var i = 0; i < number; i++) {
                let yaxis_name = "yaxis" + (i+1).toString() 
                if (start_index === 0 && end_index === 0) {
                    end_index = end_index + step
                } else {
                    start_index = start_index + step
                    end_index = end_index + step
                }
                // console.log('start_index, end_index')
                // console.log(start_index, end_index)
                // console.log('step',step)
                Layoutt_2[yaxis_name] = {
                                            domain: [start_index, end_index - step/10],
                                            // title: "Contacts Density 1", 
                                            // titlefont: { size: 15 }
                                        }
            }

            setData(all)
            setLayout(Layoutt_2)
            setDataForProfile(data_for_profile)
        }
    }, [genomeWideDataProfile, trackNameAndColor])

    // console.log('datadata',data)

    return (
        <div id='myDiv'>
            {/* <div> */}
            <Grid container spacing={0}>
                <Grid item xs={7}>
                    <Plot data={data} layout={layout} config={{
                        modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian','lasso2d','select2d'],
                        // displayModeBar: false,
                        // scrollZoom: true,
                        displaylogo: false,
                        doubleClickDelay: 1000,
                        toImageButtonOptions: {format: 'svg', filename: 'Contacts distribution', scale: 1}
                    }}/>
            {/* </div>
            <div> */}
                </Grid>
                <Grid item xs={5}>
                    <EnhancedTABLEpart  from={props.from} data={dataForProfile} trackNameAndColor={trackNameAndColor}/>
            {/* </div> */}
                </Grid>
            </Grid>
        </div>
    )
}

  
const mapStateToProps = (state) => {
    return {
        genomeWideDataProfile: state[module].genomeWideDataProfile,
    }
}
    
  
export default connect(mapStateToProps, )(DNAprofile);