import React, { useState, useEffect } from 'react';
import  { useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import { Link } from 'react-router-dom';

// import HeatmapsDATA from '../components/plots/Heatmap_data_layout';
// import Profile from '../components/plots/RNA_DNA_profile';
// import DistanceDistribution from '../components/plots/DistanceDistribution';
import TableRnaListBasic from '../components/TableRnaListBasic';
import DNApartProfileBasic from '../components/plots/DnaFilterDNAprofile';
import DownloadPlotData from '../components/DownloadPlotData';

import {submitCheckedTracksForProfile} from '../actions/plotsActions';

import { handleHeader } from '../actions/generalActions';
import { submitCheckedTracksForDNApartProfile } from '../actions/plotsActions';

import WarningMessage from '../components/MessageSnackbar';
import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';

// const module = "genes";
const module_plots = "plots";
const moduleFilter = "filter";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    textAlign: 'center',
    paddingTop: '30px !important',
    marginTop: theme.spacing(3),
    // backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    color: 'black',
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  // paper: {
  //   //marginTop: 50,
  //   //margin: '0 auto',
  //   padding: 20,
  //   width: '100%',
  //   textAlign: 'center',
  //   marginBottom: 0,
  // },
  wideButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '90%',
    marginBottom: 25,
    marginTop: 25,
  },
  divider: {
    // margin: '10px auto',
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const BasicGraphicalSummaryDnaFilter = (props) => {

  // const [value, setValue] = useState(0);
  // const [trackNameAndColor, setTrackNameAndColor] = useState({})
  const [dataFromLocalStorage, setDataFromLocalStorage] = useState({})

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)

  useEffect(() => {
    props.handleHeader(': graphical summary')
    // document.title = `Graphical summary, gene ID = ${data.selectedId}`;
    if (queryParameters.get("organism") !== null && queryParameters.get("rnaID") !== null && queryParameters.get("locus") !== null && queryParameters.get("name") !== null) {
      var data = {
        'RNAname': queryParameters.get("name"),
        'organism': queryParameters.get("organism") + "____" + queryParameters.get("locus"),
        'selectedId': Number(queryParameters.get("rnaID")),
        'selectedNormalization': "norm_value",
         'typeOfContacts': "DNA_part"
        }
    } else {
      var data = JSON.parse(localStorage.getItem("data_basic_graphical_summary_dna_filter"));
    }
    // console.log('BasicGraphicalSummaryDnaFilter data',data);
    setDataFromLocalStorage(data)
    document.title = `Graphical summary, ${data.RNAname}`
    // props.submitCheckedTracksForProfile(
    //   data.selectedId,
    //   data.typeOfContacts, 
    //   data.organism, 
    //   data.selectedNormalization
    // )
    props.submitCheckedTracksForDNApartProfile(
      data.selectedId,
      data.typeOfContacts, 
      data.organism, 
      data.selectedNormalization
    )
  }, [])

  // useEffect(() => {
  //   if (Object.keys(props.genomeWideDataProfile).length !== 0) {
  //     let trackNameAndColorDict = {}
  //     for (var organism in props.genomeWideDataProfile) {
  //       for (var RNA_track in props.genomeWideDataProfile[organism]) {
  //         if (RNA_track !== '-111') {
  //           trackNameAndColorDict[props.genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0]] = props.genomeWideDataProfile[organism][RNA_track]['color']
  //         }
  //       }
  //     }
  //     setTrackNameAndColor(trackNameAndColorDict)
  //   }
  // }, [props.genomeWideDataProfile])
  

  useEffect(() => {
    // console.log('props.trackListCheckbox',props.trackListCheckbox)
    props.submitCheckedTracksForDNApartProfile(dataFromLocalStorage.selectedId, dataFromLocalStorage.typeOfContacts, dataFromLocalStorage.organism, props.trackListCheckbox)
    // props.submitCheckedTracksForProfile(dataFromLocalStorage.selectedId, dataFromLocalStorage.typeOfContacts, dataFromLocalStorage.organism, props.trackListCheckbox)
  },[props.trackListCheckbox])

  // const handleChange = (event, value) => {
  //   setValue(value)
  // };
  
  const classes = useStyles();
//   console.log('props.loading',props.loading)
  return (
    <div className={classes.root}>
      <div style={{marginBottom: 10}}>
        <Typography variant="h4">
          <b>Contacts Summary</b>
          {/* — a list of annotated RNAs with different quantitative metrics */}
        </Typography>
        <TableRnaListBasic parent={"BasicGraphicalSummaryDnaFilter"}/>
        <Divider className={classes.divider} />
      </div>
      <div style={{marginBottom: 10}}>
        {/* <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="h4">
              <b>Contacts Distribution</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div style={{textAlign: 'left'}}> 
              <TrackListCheckboxes disabled={props.loading}/> */}
              {/* marginBottom: 10, marginTop: 10 */}
            {/* </div>
          </Grid>
        </Grid> */}
        {props.loadingDNApartData === true 
          || Object.keys(props.geneDNAdataProfile).length === 0 
          ? 
            <div className={classes.loading}>
              <LinearProgress color={"primary"} />
              <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
              </Typography>
            </div>
          :
          <div >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography style={{textAlign: "right"}} variant="h4">
                  <b>Contacts Distribution</b>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {props.loadingDNApartData === true 
                  || Object.keys(props.geneDNAdataProfile).length === 0 
                  ?
                    <Tooltip title="Download plot data">
                      <div style={{textAlign: "center", marginTop: 10}}>
                        <IconButton style={{padding: 0}} disabled={true}>
                          <CloudDownloadIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  :
                    Object.keys(props.geneDNAdataProfile).indexOf('exps') !== -1
                    ?
                      <Tooltip title="Download plot data">
                        <div style={{textAlign: "center", marginTop: 10}}>
                          <IconButton style={{padding: 0}} disabled={true}>
                            <CloudDownloadIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    :
                      <Tooltip title="Download plot data">
                        <div style={{textAlign: "center",marginTop: 10}}>
                          <DownloadPlotData data={props.geneDNAdataProfile}
                                            plot={'ContactsDistributionDnaFilter'}
                            />
                        </div>
                      </Tooltip> 
                }
              </Grid>
              <Grid item xs={5}>
                <div style={{textAlign: "left", marginLeft: 5}}> 
                  <TrackListCheckboxesNew disabled={props.loadingDNApartData}/>
                </div>
              </Grid>
            </Grid>
            {Object.keys(props.geneDNAdataProfile).indexOf('exps') !== -1
              ?
                <Typography color="error" variant="h5">
                  Contacts with the selected normalization were not found
                </Typography>
              :
              <div>
                <DNApartProfileBasic 
                    // gene_name={'dataForRnaPartsDistribution.gene_name'}
                    // experiment_id={'dataForRnaPartsDistribution.experiment_id'}
                    organism={dataFromLocalStorage.organism}
                    geneDNAdataProfile={props.geneDNAdataProfile} 
                    trackListCheckbox={props.trackListCheckbox}
                    // dnaFilter={dataForRnaPartsDistribution.dnaFilter}
                />
                <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                  <ul>
                    <li>This bar chart displays contacts density distribution on target locus (bin size = {props.geneDNAdataProfile[Object.keys(props.geneDNAdataProfile)[0]]['result_filter_contacts']}).</li>
                    <li><b>Y-axis</b>: contacts density.</li>
                    <li><b>X-axis</b>: genomic locus with which <b>{props.geneDNAdataProfile[Object.keys(props.geneDNAdataProfile)[0]]['result']['RNAname']}</b> RNA interact.</li>
                    <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                  </ul>
                </Typography>
              </div>
            }
          </div>
        }
        {/* {!props.loading ?
          <div>
            <Profile from={'basic'} trackNameAndColor={trackNameAndColor}/>
            <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
              <ul>
                <li>This is a plot that displays genome-wide contacts density distribution (genome bin size = 1500000 bp).</li>
                <li><b>Y-axis</b>: contacts density.</li>
                <li><b>X-axis</b>: the chromosomes with which selected in the track RNA(s) interact.</li>
                <li>See details on the <a href="/tutorial#Graphical-Summary" target="_blank" rel="noopener noreferrer">"Overview" page</a></li>
              </ul>
            </Typography>
          </div>
          :
          <div className={classes.loading}>
            <LinearProgress color={"primary"} />
            <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
            </Typography>
          </div>
        } */}
        <WarningMessage open={props.snackOpenDNApart} severity={"error"} message={props.snackTextDNApart} />
        <Divider className={classes.divider} />
      </div>
      <Typography variant={"subtitle1"} style={{marginLeft: 10,  padding: 5}}>
        If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
      </Typography>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      geneDNAdataProfile: state[module_plots].geneDNAdataProfile,
      snackOpenDNApart: state[module_plots].snackOpenDNApart,
      snackTextDNApart: state[module_plots].snackTextDNApart,
      geneDNAdataProfileTable: state[module_plots].geneDNAdataProfileTable,
      loadingDNApartData: state[module_plots].loadingDNApartData,
      loadingDNApartDataTable: state[module_plots].loadingDNApartDataTable,
      //trackList: state[module].trackList,
    //   selectedTracks: state[module].selectedTracks,
    //   typeOfContacts: state[module].typeOfContacts,
      
      // snackOpenGenomeWide: state[module].snackOpenGenomeWide,
      // snackTextGenomeWide: state[module].snackTextGenomeWide,
      // loading: state[module_plots].loadingGenomeWideData,
      
      // genomeWideDataProfile: state[module_plots].genomeWideDataProfile,

      trackListCheckbox: state[moduleFilter].trackListCheckbox,
  }
}

export default connect(mapStateToProps, {submitCheckedTracksForProfile, handleHeader,
  submitCheckedTracksForDNApartProfile})(BasicGraphicalSummaryDnaFilter);