import React, { useEffect }  from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';

import { clearRNAText } from '../actions/generalActions';

// const moduleTable = "table";
// const moduleFilter = "filter";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // marginLeft: theme.spacing(),
        // marginTop: theme.spacing(),
        marginBottom: 6,
    //   marginRight: theme.spacing(2),
        width: '100%',
    },
    container: {
        flexWrap: 'wrap',
    },
    input: {
        width: '100%',
    },
    textField: {
        // marginLeft: theme.spacing(),
        // marginRight: theme.spacing(),
        width: '100%',
      },
    width: {
        width: '95%',
    },
}));

const ValidationTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#009688',
          },
        '& input': {
            width: '100%',
        },
        '& input:valid + fieldset': {
            borderColor: '#009688',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: '#009688',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            borderColor: '#009688',
        },
    },
  })(TextField);


const InputsUp50 = (props) => {
    
    const { nameSelect, valueLength } = props; //placeholder, selectedItemOrganism, organism_chrs
    const classes = useStyles();

    useEffect(() => {
      props.clearRNAText();
    }, [])

    // console.log('valueLength 1',valueLength)
    
    useEffect(() => {
        // console.log('valueLength',valueLength, isNaN(Number(valueLength)))
        if (isNaN(Number(valueLength))) {
            props.currentLengthChange('')
            props.lengthWarningChangeTrue()
        } else {
            props.currentLengthChange(valueLength)
            props.lengthWarningChangeFalse()
        }
      }, [valueLength]) //,selectedItemOrganism

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.width}>
                <form className={classes.container} >
                    <ValidationTextField
                        label={nameSelect}
                        value={valueLength}
                        multiline
                        size="small"
                        onKeyPress={(ev) => {
                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                          }
                        }}
                        className={classNames(classes.input, classes.textField)}
                        onChange={props.textLengthChange}
                        variant="outlined"
                        id="validation-outlined-input"
                    />
                </form>
            </FormControl>
        </div>
    );
}
        

const mapStateToProps = (state) => {
    return {
        // organism_chrs: state[moduleFilter].organism_chrs,
        // selectedItemOrganism: state[moduleTable].selectedItemOrganism,
    }
  }
export default connect(mapStateToProps, {clearRNAText})(InputsUp50);