import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Avatar from '@material-ui/core/Avatar';
// import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemText from '@material-ui/core/ListItemText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
import Decorator from '../components/HtmlTooltip';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
// import DialogActions from '@material-ui/core/DialogActions';
// import CloseIcon from '@material-ui/icons/Close';
// import IconButton from '@material-ui/core/IconButton';
// import PersonIcon from '@material-ui/icons/Person';
// import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import { blue } from '@material-ui/core/colors';
import LinearProgress from '@material-ui/core/LinearProgress';

// import RNApartProfile from '../components/plots/RNA_list_profile';
// import TableMetadata from '../components/TableMetadata';
import DensityAllTargetGenes from '../components/plots/DensityAllTargetGenes';
import WarningMessage from './MessageSnackbar';
import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';
import TableAllTargetGenes from '../components/TableAllTargetGenes';
import RnaTableInfo from '../components/TableRnaListRnaBasic';
import RNAAutocomplete from '../components/RNAAutocomplete';
import InputTwoFromDNA from '../components/InputTwoFromDNA';
import InputThree from '../components/InputThree';
import InputsLenght from '../components/InputsLenght';
import InputsUp50 from '../components/InputsUp50';
import InputGeneBody from '../components/InputGeneBodyDensity';
import DownloadTrack from '../components/DownloadTrack';
import {LocusFilterParser} from '../components/TableAllTargetGenes';

import { submitAllTargetGenesTable} from '../actions/plotsActions';
import { textChangeSearchGeneName, SendListGeneType, SendListAnnotationSource, SendListGeneName } from '../actions/generalActions';

import {textGeneBodyChange, GeneBodyWarningChangeFalse,GeneBodyWarningChangeTrue,currentGeneBodyChange} from '../actions/generalActions';
import {textUp50Change, Up50WarningChangeFalse,Up50WarningChangeTrue,currentUp50Change} from '../actions/generalActions';
import {textUp5Change, Up5WarningChangeFalse,Up5WarningChangeTrue,currentUp5Change} from '../actions/generalActions';
import {textDown5Change, Down5WarningChangeFalse,Down5WarningChangeTrue,currentDown5Change} from '../actions/generalActions';
import {textDown50Change, Down50WarningChangeFalse,Down50WarningChangeTrue,currentDown50Change} from '../actions/generalActions';

import {textLengthChange, lengthWarningChangeFalse,lengthWarningChangeTrue,currentLengthChange} from '../actions/generalActions';
import { textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA } from '../actions/generalActions';
import { handleHeader } from '../actions/generalActions';

const module = "plots"
// const moduleGenomeBrowser = "genomeBrowser";
const moduleFilter = "filter";
const moduleTable = "table";
const moduleText = "text";

const makeID = (length) => {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const rows_allTargetGenesTable = [
  {id:'gene_name', numeric: false, disablePadding: false, label: 'Target gene' },
  {id:'norm_density_up50', numeric: false, disablePadding: true, label: 'up50'}, //'[up50;up5)'
  {id:'norm_density_up5', numeric: false, disablePadding: true, label: 'up5'}, //'[up5;GeneBody)'
  {id:'norm_density_gene_body', numeric: false, disablePadding: true, label: 'GeneBody'}, 
  {id:'norm_density_down5', numeric: false, disablePadding: true, label: 'down5'}, //'(GeneBody;down5]'
  {id:'norm_density_down50', numeric: false, disablePadding: true, label: 'down50'}, //'(down5;down50]'
  {id:'gene_type', numeric: false, disablePadding: true, label: 'Gene type'},
  {id:'locus', numeric: false, disablePadding: true, label: 'Gene locus'},
  {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
  {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
  {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},
];

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    paddingLeft: theme.spacing(),
  },
  dialog: {
    maxWidth: '100%',
    // width: '1000px'
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  loadingGB: {
    marginTop: 10,
    textAlign: 'center',
  },
  wideButton: {
    padding: theme.spacing(),
    width: '100%',
    height: '100%',
    // color: 'white',
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: theme.spacing(),
  },
  locationCenter: {
    margin: '0 auto',
  },
  autocomplete: {
    flexGrow: 1,
    paddingTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const AllTargetGenes = (props) => {

  const classes = useStyles();

  // const [open, setOpen] = useState(false);
  // const [message, setMessage] = useState('')
//   const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [textMessage, setTextMessage] = useState('');
//   const [RNAdataTable, setRNAdataTable] = useState([])
  const [currentOrganism, setCurrentOrganism] = useState('')
  const [expId, setExpId] = useState('')
  const [selectedRNA, setSelectedRNA] = useState('')
  const [allInsert, setAllInsert] = useState('')
  const [currentParametrsFromParent, setCurrentParametrsFromParent] = useState({})
  //   const [buttonColor, setButtonColor] = useState('#bdbdbd')

  const [extraParams, setExtraParams] = useState({'from': [], 'gene_type': [], 'gene_name': [], 'SearchGeneName': '', 'LocusCoordinatesForRNAs': '', 'LengthForRNAs': "", 'minDensityUp50kb': '','minDensityUp5kb': '','minDensityGeneBody': '','minDensityDown5kb': '','minDensityDown50kb': ''})
  const [rnaListID, setRnaListID] = useState('')
  const [geneName, setGeneName] = useState([]);
  const [geneNameInput, setGeneNameInput] = useState('');
  const [geneType, setGeneType] = useState([]);
  const [geneTypeInput, setGeneTypeInput] = useState('');
  const [annotationSource, setAnnotationSource] = useState([]);
  const [annotationSourceInput, setAnnotationSourceInput] = useState('');
  const [experimentDescription, setExperimentDescription] = useState('');

  useEffect(() => {
    const dataForAllTargetGenes = JSON.parse(localStorage.getItem("all_target_genes"))
    setCurrentParametrsFromParent(dataForAllTargetGenes)
    setExperimentDescription(dataForAllTargetGenes.track_description[dataForAllTargetGenes.RnaSelected[0].split('&')[1].split('^||')[0]]['description_for_plots'])
    // console.log('dataForAllTargetGenes11',dataForAllTargetGenes)
    setCurrentOrganism(dataForAllTargetGenes.organism)
    setExpId(dataForAllTargetGenes.RnaSelected[0].split('___')[1].split('&ID: ')[0])
    setSelectedRNA(dataForAllTargetGenes.gene_name)
    document.title = `RNA-Chrom: target genes`;
    props.handleHeader(': ' + dataForAllTargetGenes.gene_name + ' RNA target genes')
    setAllInsert(dataForAllTargetGenes.all_insert)
    // setExpId(dataForAllTargetGenes.RnaSelected[index].split('___')[1].split('&ID: ')[0])
    let ID = makeID(15)
    props.submitAllTargetGenesTable({
      'selected_RNA': dataForAllTargetGenes.gene_name,
      'selectedNormalization': props.trackListCheckbox,
      'experiment_id': dataForAllTargetGenes.RnaSelected[0].split('___')[1].split('&ID: ')[0],
      'rnaListID': ID + "___" + props.trackListCheckbox,
      'organism': dataForAllTargetGenes.organism,
      'page' : 0, 
      'rowsPerPage': 25, 
      'order': 'asc',
      'orderBy': '',
      'from': [], 
      'gene_name': [],
      'gene_type': [], 
      'SearchGeneName': "",
      'LocusCoordinatesForRNAs': "",
      'LengthForRNAs': "",
      'minDensityUp50kb': "",
      'minDensityUp5kb': "",
      'minDensityGeneBody': "",
      'minDensityDown5kb': "",
      'minDensityDown50kb': "",
      'LocusCoordinatesForDNA': dataForAllTargetGenes.all_insert, //заход от ДНК, ДНК-фильтр
    })
    setRnaListID(ID + "___" + props.trackListCheckbox)
  }, [])

  useEffect (() => {
    // console.log('props.allTargetGenesTableData',props.allTargetGenesTableData)
    if (Object.keys(props.allTargetGenesTableData).length !== 0 && Object.keys(props.allTargetGenesTableData['description']).length !== 0) {
      props.SendListGeneName(props.allTargetGenesTableData['description']['gene_name'])
      props.SendListGeneType(props.allTargetGenesTableData['description']['gene_type'])
      props.SendListAnnotationSource(props.allTargetGenesTableData['description']['from'])
      // setCurrentFilterContactsLocus(props.basicGeneTableDataFromDNA['all_insert'])
    } else {
      props.SendListGeneName([])
      props.SendListGeneType([])
      props.SendListAnnotationSource([])
    }

  }, [props.allTargetGenesTableData])

  useEffect(() => {
    if (rnaListID.split('___').length === 2 && rnaListID !== "___") {
      if (rnaListID.split('___')[1] !== props.trackListCheckbox) {
        setRnaListID('___')
      }
    }
  }, [props.trackListCheckbox])  
  // console.log('props.trackListCheckbox ++++++++=',props.trackListCheckbox)


  const handleCloseMessage = () => {
    setOpenMessage(false)
  }

  const handleGeneName = (gt) => {
    setGeneName(gt);
  };

  const handleGeneNameInput = (value) => {
    setGeneNameInput(value);
  };
  
  const handleGeneType = (gt) => {
    setGeneType(gt);
  };

  const handleGeneTypeInput = (value) => {
    setGeneTypeInput(value);
  };

  const handleAnnotationSource = (gt) => {
    setAnnotationSource(gt);
  };

  const handleAnnotationSourceInput = (value) => {
    setAnnotationSourceInput(value);
  };

  const handleClickApplyFromDNA = (e,id) => {   
    if (props.locusWarningFromDNA === false 
          && props.lengthWarning === false
          && props.GeneBodyWarning === false
          && props.Up50Warning === false
          && props.Up5Warning === false
          && props.Down50Warning === false
          && props.Down5Warning === false
        ) {
      setExtraParams({'from': annotationSource, 
                      'gene_type': geneType, 
                      'gene_name': geneName,
                      'SearchGeneName': props.valueSearchGeneName, 
                      'LocusCoordinatesForRNAs': props.currentLocusFromDNA,
                      'LengthForRNAs': props.currentLength,
                      'minDensityUp50kb': props.currentUp50,
                      'minDensityUp5kb': props.currentUp5,
                      'minDensityGeneBody': props.currentGeneBody,
                      'minDensityDown5kb': props.currentDown5,
                      'minDensityDown50kb': props.currentDown50,
                    })
    } else {
      if (props.GeneBodyWarning === true
            || props.Up50Warning === true
            || props.Up5Warning === true
            || props.Down50Warning === true
            || props.Down5Warning === true
          ) {
        setTextMessage("'Up50' / 'Up5' / 'Gene body' / 'Down5' / 'Down50' was entered in the wrong format");
        setOpenMessage(true)
      } else {
        setTextMessage("'Mininum gene length' or 'Locus coordinates' were entered in the wrong format");
        setOpenMessage(true)
      }
    }
  };

  
  return (
    <div className={classes.root}>
            {/* <Typography variant="h4" style={{textAlign: 'center', marginTop: 50}}>
              {selectedRNA} RNA target genes (dataset ID: {expId})
            </Typography> */}
            <Grid container spacing={0}>
              <Grid item xs={8} style={{marginTop: 50}}>
                <RnaTableInfo
                  name={selectedRNA}
                  type={currentParametrsFromParent.type}
                  geneLocus={currentParametrsFromParent.geneLocus}
                  dnaFilter={allInsert}
                  geneNameTarget={currentParametrsFromParent.geneNameTarget}
                  str={currentParametrsFromParent.str}
                  expID_typeOfExperiment={currentParametrsFromParent.expID_typeOfExperiment}
                  length={currentParametrsFromParent.length}
                  currentOrganism={currentOrganism}
                  genome={currentParametrsFromParent.genome}
                  from={currentParametrsFromParent.from}
                  experiment_id={expId}
                  track_description={experimentDescription} //currentParametrsFromParent.track_description //all_track_description[row.track_name]['track_description']
                  // allInsert={1}
                />
              </Grid>
              <Grid item xs={3}>
                <div style={{textAlign: 'center', marginTop: 70, marginLeft: 10}}>
                  <TrackListCheckboxesNew disabled={props.loadingAllTargetGenesTable }/>
                </div>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title='Download "all target genes" table'>
                  <div style={{textAlign: "left", marginTop: 75}}>
                    <DownloadTrack 
                      typeOfContacts={`${selectedRNA}&&${expId}`} 
                      whatType={'allTargetGenes'} 
                      all_insert={allInsert} 
                      expId_and_name={currentOrganism}
                      params={{ 'from': annotationSource.map(n => n['value']), 
                                'gene_type': geneType.map(n => n['value']), 
                                'gene_name': geneName.map(n => n['value']),
                                'SearchGeneName': props.valueSearchGeneName, 
                                'LocusCoordinatesForRNAs': props.currentLocusFromDNA.length !== 0 ? LocusFilterParser(props.currentLocusFromDNA) : props.currentLocusFromDNA,
                                'LengthForRNAs': props.currentLength,
                                'minDensityUp50kb': props.currentUp50,
                                'minDensityUp5kb': props.currentUp5,
                                'minDensityGeneBody': props.currentGeneBody,
                                'minDensityDown5kb': props.currentDown5,
                                'minDensityDown50kb': props.currentDown50,
                              }}
                      />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={4} style={{borderTop: '2px solid #000000', borderLeft: '2px solid #000000', borderRight: '2px solid #000000', marginTop: 5, marginBottom: 10}}>
                <div>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      {/* <Typography variant="subtitle2" style={{ padding: 0, marginTop: 5, marginBottom: 5, textAlign: 'center' }}>Filters</Typography> */}
                      <div>
                        <InputThree 
                          nameSelect='Serch by "Target gene"'
                          textChange={props.textChangeSearchGeneName}
                          valueLocus={props.valueSearchGeneName}
                        />
                      </div>
                      <RNAAutocomplete
                        nameClass={classes.autocomplete}
                        disabled={props.loadingAllTargetGenesTable}
                        suggestions={props.BasicTableGeneName}
                        handleChange={handleGeneName}
                        selectedItems={geneName}
                        currentInput={geneNameInput}
                        onInputChange={handleGeneNameInput}
                        title={"Target gene"}
                      />
                      <RNAAutocomplete
                        nameClass={classes.autocomplete}
                        disabled={props.loadingAllTargetGenesTable}
                        suggestions={props.RnaListGeneType}
                        handleChange={handleGeneType}
                        selectedItems={geneType}
                        currentInput={geneTypeInput}
                        onInputChange={handleGeneTypeInput}
                        title={"Gene type"}
                      />
                      <RNAAutocomplete
                        nameClass={classes.autocomplete}
                        disabled={props.loadingAllTargetGenesTable}
                        suggestions={props.BasicTableAnnotationSource}
                        handleChange={handleAnnotationSource}
                        selectedItems={annotationSource}
                        currentInput={annotationSourceInput}
                        onInputChange={handleAnnotationSourceInput}
                        title={"Annotation source"}
                      />
                      <div>
                        <Decorator 
                          key = {1111111}
                          text = {<Typography color="inherit">Enter coordinates of <b>gene loci</b> to get all genes from them</Typography>}
                          component = {
                            <div>
                              <InputTwoFromDNA 
                                nameSelect='chr4; chr2:10000-23000'
                                textChange={props.textChangeFromDNA}
                                valueLocus={props.valueLocusFromDNA}
                                currentOrganism={currentOrganism}
                                locusWarningChangeFalse={props.locusWarningChangeFalseFromDNA}
                                locusWarningChangeTrue={props.locusWarningChangeTrueFromDNA}
                                currentLocusChange={props.currentLocusChangeFromDNA}
                              />
                            </div>
                            }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>                
                      <div style={{borderLeft: '2px solid #000000'}}> 
                        <Typography variant="subtitle2" style={{ padding: 0, marginTop: 18, marginBottom: 18, textAlign: 'center' }}>Minimum Density</Typography>
                        <Grid container spacing={0}>
                          <Grid item xs={6}>
                            <InputsUp50
                              nameSelect='up50'
                              textLengthChange={props.textUp50Change}
                              valueLength={props.valueUp50}
                              lengthWarningChangeFalse={props.Up50WarningChangeFalse}
                              lengthWarningChangeTrue={props.Up50WarningChangeTrue}
                              currentLengthChange={props.currentUp50Change}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <InputsUp50
                              nameSelect='up5'
                              textLengthChange={props.textUp5Change}
                              valueLength={props.valueUp5}
                              lengthWarningChangeFalse={props.Up5WarningChangeFalse}
                              lengthWarningChangeTrue={props.Up5WarningChangeTrue}
                              currentLengthChange={props.currentUp5Change}
                            />
                          </Grid>
                        </Grid>
                        <div>
                          <InputGeneBody
                            nameSelect='Gene body'
                            textLengthChange={props.textGeneBodyChange}
                            valueLength={props.valueGeneBody}
                            lengthWarningChangeFalse={props.GeneBodyWarningChangeFalse}
                            lengthWarningChangeTrue={props.GeneBodyWarningChangeTrue}
                            currentLengthChange={props.currentGeneBodyChange}
                          />
                        </div>
                        <Grid container spacing={0}>
                          <Grid item xs={6}>
                            <InputsUp50
                              nameSelect='down5'
                              textLengthChange={props.textDown5Change}
                              valueLength={props.valueDown5}
                              lengthWarningChangeFalse={props.Down5WarningChangeFalse}
                              lengthWarningChangeTrue={props.Down5WarningChangeTrue}
                              currentLengthChange={props.currentDown5Change}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <InputsUp50
                              nameSelect='down50'
                              textLengthChange={props.textDown50Change}
                              valueLength={props.valueDown50}
                              lengthWarningChangeFalse={props.Down50WarningChangeFalse}
                              lengthWarningChangeTrue={props.Down50WarningChangeTrue}
                              currentLengthChange={props.currentDown50Change}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div >
                        <InputsLenght
                          nameSelect='Minimum gene length'
                          textLengthChange={props.textLengthChange}
                          valueLength={props.valueLength}
                          lengthWarningChangeFalse={props.lengthWarningChangeFalse}
                          lengthWarningChangeTrue={props.lengthWarningChangeTrue}
                          currentLengthChange={props.currentLengthChange}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div style={{textAlign: 'center', marginBottom: 0, marginTop: 0}}>
                    <Button
                      disabled={props.loadingAllTargetGenesTable}
                      className={classes.button}
                      variant="contained"
                      size="small"
                      color={"secondary"}
                      onClick={e => handleClickApplyFromDNA(e, 'igfdsfghjk')}
                    >
                      Apply filters
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={8}>
                <TableAllTargetGenes 
                  extraParams={extraParams} 
                  rows={rows_allTargetGenesTable}
                  // allTargetGenesTableData={props.allTargetGenesTableData}
                  loadingAllTargetGenesTable={props.loadingAllTargetGenesTable}
                  snackOpenAllTargetGenesTable={props.snackOpenAllTargetGenesTable}
                  snackTextAllTargetGenesTable={props.snackTextAllTargetGenesTable}
                  submitAllTargetGenesTable={props.submitAllTargetGenesTable}
                  selected_RNA={selectedRNA}
                  // currentLocusCoordinatesForDNA={'currentLocusCoordinatesForDNA'}
                  currentOrganism={currentOrganism}
                  rnaListID={rnaListID}
                  selectedNormalization={props.trackListCheckbox}
                  experiment_id={expId}
                  all_insert={allInsert}
                />
            </Grid>
          </Grid>
          {!props.loadingAllTargetGenesTable ?
            <Grid container spacing={0} style={{borderTop: '2px solid #000000'}}>
              <Grid> 
                  <DensityAllTargetGenes title={'Up 50kb'} genomeInterval={'norm_density_up50'} allTargetGenesTableData={props.allTargetGenesTableData}/>
              </Grid>
              <Grid>
                <DensityAllTargetGenes title={'Up 5kb'} genomeInterval={'norm_density_up5'} allTargetGenesTableData={props.allTargetGenesTableData}/>
              </Grid>
              <Grid>
                <DensityAllTargetGenes title={'Gene body'} genomeInterval={'norm_density_gene_body'} allTargetGenesTableData={props.allTargetGenesTableData}/>
              </Grid>
              <Grid>
                <DensityAllTargetGenes title={'Down 5kb'} genomeInterval={'norm_density_down5'} allTargetGenesTableData={props.allTargetGenesTableData}/>
              </Grid>
              <Grid>
                <DensityAllTargetGenes title={'Down 50kb'} genomeInterval={'norm_density_down50'} allTargetGenesTableData={props.allTargetGenesTableData}/>
              </Grid>
            </Grid>
          : 
            <div className={classes.loading}>
                <LinearProgress color={"primary"}/>
                <Typography variant="h5">
                    Please, wait a while
                </Typography>
            </div>
          }
          <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
            <ul>
              <li>These histograms describe 5 distributions of contacts density in the corresponding genomic intervals (for example, [Up50 kb; Up5 kb) or "Up 50kb") for all target genes that are displayed in the table above. </li>
              <li><b>Y-axis</b>: Number of target genes.</li>
              <li><b>X-axis</b>: Log10(Density).</li>
              <li><b>Density = (n-reads/interval)*1000</b></li>
              <li>In order for <b>zero density</b> to also be displayed on a logarithmic scale, <b>we added 0.0001 to each density value</b>. See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
            </ul>
          </Typography>
          <Divider className={classes.divider} />

          <Typography variant={"subtitle1"} style={{marginLeft: 10,  padding: 5}}>
              If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
          </Typography>
        <WarningMessage open={openMessage} severity={"warning"} handleClose={handleCloseMessage} message={textMessage} />
    </div>
  );
}

const mapStateToProps = (state) => {
    return {

      trackListCheckbox: state[moduleFilter].trackListCheckbox,

      allTargetGenesTableData: state[module].allTargetGenesTableData,
      snackOpenAllTargetGenesTable: state[module].snackOpenAllTargetGenesTable,
      snackTextAllTargetGenesTable: state[module].snackTextAllTargetGenesTable,
      loadingAllTargetGenesTable: state[module].loadingAllTargetGenesTable,

      RnaListGeneType: state[moduleTable].RnaListGeneType,
      BasicTableAnnotationSource: state[moduleTable].BasicTableAnnotationSource,
      BasicTableGeneName: state[moduleTable].BasicTableGeneName,
      valueSearchGeneName: state[moduleText].valueSearchGeneName,
      valueLocusFromDNA: state[moduleText].valueFromDNA,
      locusWarningFromDNA: state[moduleText].locusWarningFromDNA,
      currentLocusFromDNA: state[moduleText].currentLocusFromDNA,

      valueGeneBody: state[moduleText].valueGeneBody,
      GeneBodyWarning: state[moduleText].GeneBodyWarning,
      currentGeneBody: state[moduleText].currentGeneBody,

      valueUp50: state[moduleText].valueUp50,
      Up50Warning: state[moduleText].Up50Warning,
      currentUp50: state[moduleText].currentUp50,

      valueUp5: state[moduleText].valueUp5,
      Up5Warning: state[moduleText].Up5Warning,
      currentUp5: state[moduleText].currentUp5,

      valueDown5: state[moduleText].valueDown5,
      Down5Warning: state[moduleText].Down5Warning,
      currentDown5: state[moduleText].currentDown5,

      valueDown50: state[moduleText].valueDown50,
      Down50Warning: state[moduleText].Down50Warning,
      currentDown50: state[moduleText].currentDown50,
      
      valueLength: state[moduleText].valueLength,
      lengthWarning: state[moduleText].lengthWarning,
      currentLength: state[moduleText].currentLength,
    }
}

export default connect(mapStateToProps, {
  submitAllTargetGenesTable,
  textChangeSearchGeneName, SendListGeneType, SendListAnnotationSource, SendListGeneName,

  textGeneBodyChange, GeneBodyWarningChangeFalse,GeneBodyWarningChangeTrue,currentGeneBodyChange,
  textUp50Change, Up50WarningChangeFalse,Up50WarningChangeTrue,currentUp50Change,
  textUp5Change, Up5WarningChangeFalse,Up5WarningChangeTrue,currentUp5Change,
  textDown5Change, Down5WarningChangeFalse,Down5WarningChangeTrue,currentDown5Change,
  textDown50Change, Down50WarningChangeFalse,Down50WarningChangeTrue,currentDown50Change,
  
  textLengthChange, lengthWarningChangeFalse,lengthWarningChangeTrue,currentLengthChange,
  textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA,
  handleHeader,
                                        })(AllTargetGenes);