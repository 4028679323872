import * as ActionTypes from '../actions/plotsActions';

const initialState = {
    geneDNAdataProfile: {},
    geneRNAdataProfile: {},
    geneRNAdataProfileTable: {},
    genomeWideData: {},
    genomeWideDataComparative: {},
    genomeWideDataProfile: {},
    genomeWideDataDistanceDistribution: {},
    
    loadingDNApartData: false,
    loadingRNApartData: false,
    loadingRNApartDataTable: false,
    loadingGenomeWideHeatmap: false,
    loadingGenomeWideHeatmapComparative: false,
    loadingGenomeWideData: false,
    loadingDistanceDistribution: false,
    snackOpenDNApart: false,
    snackTextDNApart: '',
    snackOpenRNApart: false,
    snackTextRNApart: '',
    snackOpenRNApartTable: false,
    snackTextRNApartTable: '',
    snackOpen: false,
    snackOpenComparative: false,
    snackText: '',
    snackTextComparative: '',
    snackOpenGenomeWide: false,
    snackTextGenomeWide: '',
    snackOpenDistanceDistribution: false,
    snackTextDistanceDistribution: '',

    allTargetGenesTableData: {},
    snackOpenAllTargetGenesTable: false,
    snackTextAllTargetGenesTable: '',
    loadingAllTargetGenesTable: false,
}


const plotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_REQUEST:
            return {
                ...state,
                loadingGenomeWideHeatmap: true,
                
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_SUCCESS:
            return {
                ...state,
                genomeWideData: action.genomeWideData,
                loadingGenomeWideHeatmap: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_FAILURE:
            return {
                ...state,
                snackOpen: true,
                snackText: "An error occurred in the backend, Heatmap data was not sent to the web!",
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_REQUEST:
            return {
                ...state,
                loadingGenomeWideHeatmapComparative: true,
                
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_SUCCESS:
            return {
                ...state,
                genomeWideDataComparative: action.genomeWideData,
                loadingGenomeWideHeatmapComparative: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_HEATMAP_COMPARATIVE_FAILURE:
            return {
                ...state,
                snackOpenComparative: true,
                snackTextComparative: "An error occurred in the backend, Comparative Heatmap data was not sent to the web!",
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_REQUEST:
            return {
                ...state,
                loadingDNApartData: true,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_SUCCESS:
            return {
                ...state,
                geneDNAdataProfile: action.geneDNAdataProfile,
                loadingDNApartData: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_DNA_PART_PROFILE_FAILURE:
            return {
                ...state,
                snackOpenDNApart: true,
                snackTextDNApart: 'An error occurred in the backend, RNA part data profile was not sent to the web!',
        };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_REQUEST:
            return {
                ...state,
                loadingRNApartData: true,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_SUCCESS:
            return {
                ...state,
                geneRNAdataProfile: action.geneRNAdataProfile,
                loadingRNApartData: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_FAILURE:
            return {
                ...state,
                snackOpenRNApart: true,
                snackTextRNApart: 'An error occurred in the backend, RNA part data profile was not sent to the web!',
        };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_REQUEST:
            return {
                ...state,
                loadingRNApartDataTable: true,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_SUCCESS:
            return {
                ...state,
                geneRNAdataProfileTable: action.geneRNAdataProfileTable,
                loadingRNApartDataTable: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_RNA_PART_PROFILE_TABLE_FAILURE:
            return {
                ...state,
                snackOpenRNApartTable: true,
                snackTextRNApartTable: 'An error occurred in the backend, RNA part data profile was not sent to the web!',
            };
        //all target genes
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_REQUEST:
            return {
                ...state,
                loadingAllTargetGenesTable: true,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_SUCCESS:
            return {
                ...state,
                allTargetGenesTableData: action.allTargetGenesTableData,
                loadingAllTargetGenesTable: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_ALL_TARGET_GENENS_TABLE_FAILURE:
            return {
                ...state,
                snackOpenAllTargetGenesTable: true,
                snackTextAllTargetGenesTable: 'An error occurred in the backend, all target genes data was not sent to the web!',
            };
        //RNA_DNA_profile
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_REQUEST:
            return {
                ...state,
                loadingGenomeWideData: true,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_SUCCESS:
            return {
                ...state,
                genomeWideDataProfile: action.genomeWideDataProfile,
                loadingGenomeWideData: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_GENOME_WIDE_PROFILE_FAILURE:
            return {
                ...state,
                snackOpenGenomeWide: true,
                snackTextGenomeWide: 'An error occurred in the backend, Genome wide profile data was not sent to the web!',
            };
        
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_REQUEST:
            return {
                ...state,
                loadingDistanceDistribution: true,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_SUCCESS:
            return {
                ...state,
                genomeWideDataDistanceDistribution: action.genomeWideDataDistanceDistribution,
                loadingDistanceDistribution: false,
            };
        case ActionTypes.SUBMIT_TO_CLICKHOUSE_FOR_DISTANCE_DISTRIBUTION_FAILURE:
            return {
                ...state,
                snackOpenDistanceDistribution: true,
                snackTextDistanceDistribution: 'An error occurred in the backend, Distance Distribution data was not sent to the web!',
            };

        default:
            return state;
    }
};

export default plotsReducer;