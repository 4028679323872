import React, { useState, useEffect } from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import Radio from '@material-ui/core/Radio';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
// import { lighten } from '@material-ui/core/styles/colorManipulator';
import LinearProgress from '@material-ui/core/LinearProgress';
// import FormGroup from '@material-ui/core/FormGroup';
// import Switch from '@material-ui/core/Switch';
// import { Link } from 'react-router-dom'

import WarningMessage from './MessageSnackbar';
// import OrganismSelectorSimple from '../components/AutoSuggestOrganismSimple';
import RnaListPagination from '../components/RnaListPagination';

import { fetchBasicGeneTable, selectOneId } from '../actions/basicAnalysisActions';
// import WarningMessage from '../components/MessageSnackbar';

// const module = "table";
const module = "genes";
const moduleFilter = "filter";
const moduleBasicAnalysis = "basicAnalysis";

const makeID = (length) => {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// const stableSort = (array, cmp) =>{
//   // console.log(array, cmp,'array, cmp')
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// const getSorting = (order, orderBy) => {
//   return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }

const LocusFilterParser = (valueLocus) => {
  let loci_array = []
  for (const one of valueLocus.replace(/;$/g, '').split(';').map(function(item) {return item.trim();})) {
      let chr = 0
      let start = 0
      let end = 0
      var locus_array = one.replace(/,+|\s+/g, '').split(/r|:|-/i).map(function(item) {return item.trim();})
      // console.log('locus_array',locus_array)
      if (locus_array.length === 2 && locus_array[0].toLowerCase() === 'ch' && (isNaN(Number(locus_array[1])) !== true || locus_array[1].toLowerCase() === 'x' || locus_array[1].toLowerCase() === 'y')) {
          {locus_array[1].toLowerCase() === 'x' ? chr = 33 : locus_array[1].toLowerCase() === 'y' ? chr = 44 : chr = Number(locus_array[1])}
          end = 1000000000000000
          loci_array.push([chr,start,end])
          // props.locusWarningChangeFalse()
      } else if (locus_array.length === 4 && locus_array[2] !== '' && locus_array[3] !== '' && isNaN(Number(locus_array[2])) !== true && isNaN(Number(locus_array[3])) !== true) {
          if (locus_array[0].toLowerCase() === 'ch' && Number(locus_array[2].replace(/^0*/,'')) < Number(locus_array[3].replace(/^0*/,''))) {
              {locus_array[1].toLowerCase() === 'x' ? chr = 33 : locus_array[1].toLowerCase() === 'y' ? chr = 44 : chr = Number(locus_array[1])}
              start = Number(locus_array[2])
              end = Number(locus_array[3])
              loci_array.push([chr,start,end])
          } else {
              loci_array = []
              break;
          }
      } else {
          loci_array = []
          break;
      }
  }
  return loci_array
}

// const rows = [
//     // {id: 'id', numeric: false, disablePadding: false, label: 'ID'},
//     {id:'gene_name', numeric: false, disablePadding: false, label: 'Annotated name' },
//     {id:'gene_type', numeric: false, disablePadding: true, label: 'Gene type'},
//     {id:'locus', numeric: false, disablePadding: true, label: 'Gene locus'},
//     {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
//     {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
//     {id:'all_vs_all', numeric: false, disablePadding: true, label: 'All-vs-all (max CPKM)'}, 
//     {id:'one_vs_all', numeric: false, disablePadding: true, label: 'One-vs-all (max CPKM)'}, 
//     // {id:'build', numeric: false, disablePadding: true, label: 'Genome'},
//     {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},
// ];

// const data = [
//     {id: 1,name: "Xist",gene_type: "lncRNA",locus: "chr1:12-123",strand: "+",from: "sd"},
//     {id: 2,name: "Xsdfist",gene_type: "lncRNA",locus: "chr1:12-123",strand: "+",from: "QWdffs"},
// ];

const BasicTableRNAHead = (props) => {
  
  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };

  const { order, orderBy, rows } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow size='small'>
          <TableCell align="center" colSpan={1} style={{color: 'white', backgroundColor: 'white', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}></TableCell>
          <TableCell align="center" colSpan={1} style={{color: 'white', backgroundColor: 'white', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}></TableCell>
          <Tooltip
                placement={"right-start"}
                title={
                  <React.Fragment>
                    <div >
                      <Typography>
                        <b>All-to-all experiments</b> — data that were obtained by a group of methods (called "all-to-all") that can detect the interactions of all RNAs with all DNA loci
                      </Typography>
                    </div>
                  </React.Fragment>
                }
                >
                  <TableCell align="center" colSpan={3} style={{color: 'black', backgroundColor: '#bbdefb', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5', whiteSpace: 'nowrap'}}>All-to-all experiments</TableCell>
          </Tooltip>
          <Tooltip
                placement={"right-start"}
                title={
                  <React.Fragment>
                    <div >
                      <Typography>
                        <b>One-to-all experiments</b> — data that were obtained by a group of methods (called "one-to-all") that can detect the interactions of a particular RNA with all chromatin loci
                      </Typography>
                    </div>
                  </React.Fragment>
                }
                >
                  <TableCell align="center" colSpan={2} style={{color: 'black', backgroundColor: '#f0f4c3', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5', whiteSpace: 'nowrap'}}>One-to-all</TableCell>
          </Tooltip>
          <TableCell align="center" colSpan={5} style={{color: 'black', backgroundColor: 'white', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>
            Annotation
          </TableCell>
      </TableRow>
      <TableRow > 
        {rows.map(row => {
          let wrap = ''
          // if (row.label.includes('All-to-all') === true || row.label.includes('One-to-all') === true) {
          // if (row.label === 'All-to-all' || row.label === 'One-to-all') {
          //   wrap = 'nowrap'
          // } else {
          //   wrap = 'wrap'
          // }
          if (row.label === "max CPKM") {
            return (
              <Tooltip
                placement={"right-start"}
                title={
                  <React.Fragment>
                    <div >
                      <Typography>
                        <b>maximum CPKM among all experiments</b> (CPKM — number of contacts divided by library size and RNA length)
                      </Typography>
                    </div>
                  </React.Fragment>
                }
                >
                  <TableCell
                    className={classes.sizeSmall}
                    style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5', whiteSpace: wrap}} 
                    key={row.id}
                    align={row.numeric ? 'right' : 'center'}
                    padding={row.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={orderBy === row.id ? order : 'asc'}
                        onClick={createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                  </TableCell>
              </Tooltip>
            )
          } else if (row.label === "Exps") {
            return (
              <Tooltip
                placement={"right-start"}
                title={
                  <React.Fragment>
                    <div >
                      <Typography>
                        <b>Number of experiments</b> that contain contacts of the corresponding RNA
                      </Typography>
                    </div>
                  </React.Fragment>
                }
                >
                  <TableCell
                    className={classes.sizeSmall}
                    style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5', whiteSpace: wrap}} 
                    key={row.id}
                    align={row.numeric ? 'right' : 'center'}
                    padding={row.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={orderBy === row.id ? order : 'asc'}
                        onClick={createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                  </TableCell>
              </Tooltip>
            )
          } else {
            return (
              <TableCell
                className={classes.sizeSmall}
                style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5', whiteSpace: wrap}} 
                key={row.id}
                align={row.numeric ? 'right' : 'center'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={orderBy === row.id ? order : 'asc'}
                    onClick={createSortHandler(row.id)}
                  >
                     {row.label}
                  </TableSortLabel>
              </TableCell>
            )
          }
        })}
      </TableRow>
    </TableHead>
  );
}

BasicTableRNAHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rows: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(),
  },
  title: {
    flex: '0 0 auto',
  },
  experiments_selected: {
    textAlign: 'left',
    // marginTop: theme.spacing(),
    whiteSpace: 'nowrap',
  },
  rootToolbar: {
    paddingRight: theme.spacing(),
  },
  container: {
    // maxHeight: window.innerHeight*0.49,
    maxHeight: window.innerHeight*0.7,
  },
  containerRfomDna : {
    maxHeight: window.innerHeight*0.6,
  },
  table: {
    minWidth: 50,
  },
  tableWrapper: {
    // overflowX: 'auto',
    margin: '0 auto',
    maxWidth: '100%',
    '& table:first-child': {
        '& tr': {
            '& td:nth-child(1), td:nth-child(1)': {
                backgroundColor: 'white',
                position: 'sticky',
                left: 0,
                zIndex: 99
            },
            '& th:nth-child(1)': {
                zIndex: 999
            },
        }
    },
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  sizeSmall: {
    padding: '0px 5px 0px 5px',
  },
  sizeSmall_chackBox: {
    padding: '1px 2px 3px 4px',
  },
  wideButton: {
    marginTop: 5,
    marginBottom: 5,
    // padding: 5,
    width: '80%',
    // height: 20, //'10%'
    // textAlign: 'center',
  },
}));


const BasicTableRNA = (props) => {

  const { extraParams, selectedId, rows } = props; //data, len, rnaListID
  const [order,setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('max_cpkm_all_vs_all')
  const [page, setPage] = useState(0)
  const [rnaListID, setRnaListID] = useState('')
  // const [data, setData] = useState([])
  // const [len, setLen] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [rowsCount, setRowsCount] = useState(0);
  // const [basicTableRNAselected, setBasicTableRNAselected] = useState(0);
  const [currentOrganismFromRNA, setCurrentOrganismFromRNA] = useState('')
  // const [dataPerPage, setDataPerPage] = useState([])
  // const [dataToDisplay, setDataToDisplay] = useState([])

  // useEffect(() => {
  //   props.fetchBasicGeneTable(
  //     {
  //       'rnaListID' : rnaListID,
  //       'organism': props.selectedItemOrganism[0]['value'],
  //       'page' :page, 
  //       'rowsPerPage': rowsPerPage, 
  //       'order': order,
  //       'orderBy': orderBy,
  //     }
  //   )
  // }, [data])

  useEffect(() => {
    // console.log('selectedItemOrganism!!!!!!!!!!!!!',props.selectedItemOrganism)
    // setRnaListDataFirst(dataRnaList)
    if (props.valueTab === 0) {
      let ID = makeID(15)
      setRnaListID(ID)
      setCurrentOrganismFromRNA(props.currentOrganism) //props.selectedItemOrganism[0]['value']
      // console.log('rnaListID!!!!!!!!!!!!!',rnaListID)
      props.fetchBasicGeneTable(
        {
          'rnaListID' : ID,
          'organism': props.currentOrganism, //props.selectedItemOrganism[0]['value'],
          'selectedNormalization': props.trackListCheckbox,
          'page' : 0, 
          'rowsPerPage': 25, 
          'order': 'asc',
          'orderBy': '',
          'from': [], 
          'gene_type': [], 
          'gene_name': [],
          'SearchGeneName': "",
          'LocusCoordinatesForRNAs': "",
          'LengthForRNAs': "",
        }
      )
    }
    // props.fetchBasicGeneTable(props.selectedItemOrganism[0]['value'])
  }, [props.valueTab])

  useEffect(() => {
    if (rnaListID.length > 0 || props.currentRnaListID.length > 0) {
      props.selectOneId(0)
      setPage(0)
      // console.log('#########!!!!')
      // console.log('order',order)
      // console.log('orderBy',orderBy)
      // console.log('rnaListID![order,orderBy]',rnaListID)
      if (props.valueTab === 0) {
        let locusFilter = []
        if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
            locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
        }
        // debugger
        // console.log(props.currentOrganism)
        props.fetchBasicGeneTable(
          {
            'rnaListID' : rnaListID,
            'organism': props.currentOrganism, //props.selectedItemOrganism[0]['value'],
            'selectedNormalization': props.trackListCheckbox,
            'page' : 0, 
            'rowsPerPage': rowsPerPage, 
            'order': order,
            'orderBy': orderBy,
            'from': extraParams['from'].map(n => n['value']), 
            'gene_type': extraParams['gene_type'].map(n => n['value']), 
            'gene_name': extraParams['gene_name'].map(n => n['value']),
            'SearchGeneName': extraParams['SearchGeneName'],
            'LocusCoordinatesForRNAs': locusFilter, //extraParams['LocusCoordinatesForRNAs'],
            'LengthForRNAs': extraParams['LengthForRNAs'],
          } 
        )
      } else {
        if (Object.keys(props.basicGeneTableData).length !== 0) {
          // console.log('!!!!WWWWWWW order,orderBy',props.geneBody, 'geneBody')
          // console.log('props.currentGeneNameForDNA',props.currentGeneNameForDNA)
          let locusFilter = []
          if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
              locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
          }
          props.fetchBasicGeneTableFromDNA(
            {
              'gene_name_for_dna': [props.currentGeneNameForDNA + props.geneBody], //['NEAT1_1']
              'gene_name_upstream': props.currentValueUpstream,
              'gene_name_downstream': props.currentValueDownstream,
              'LocusCoordinatesForDNA': props.currentLocusCoordinatesForDNA, //'chr11:65422774-65445540'
              'rnaListID' : props.currentRnaListID,
              'organism': props.currentOrganism, //'Homo sapiens'
              'selectedNormalization': props.trackListCheckbox,

              'page' : 0, 
              'rowsPerPage': rowsPerPage, 
              'order': order,
              'orderBy': orderBy,
              'from': extraParams['from'].map(n => n['value']), 
              'gene_type': extraParams['gene_type'].map(n => n['value']), 
              'gene_name': extraParams['gene_name'].map(n => n['value']),
              'SearchGeneName': extraParams['SearchGeneName'],
              'LocusCoordinatesForRNAs': locusFilter, //extraParams['LocusCoordinatesForRNAs'],
              'LengthForRNAs': extraParams['LengthForRNAs'],
            } 
          )
        }
      }
    }
  }, [order,orderBy])
  // console.log('currentGeneNameForDNA',props.currentGeneNameForDNA)
  // console.log('currentLocusCoordinatesForDNA',props.currentLocusCoordinatesForDNA)
  // console.log('currentOrganism',props.currentOrganism)
  // console.log('props.currentRnaListID',props.currentRnaListID)


  useEffect(() => {
    if (rnaListID.length > 0 || props.currentRnaListID.length > 0) {
      props.selectOneId(0)
      // console.log('rnaListID![page, rowsPerPage]',rnaListID)
      if (props.valueTab === 0) {
        let locusFilter = []
        if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
            locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
        }
        props.fetchBasicGeneTable(
          {
            'rnaListID' : rnaListID,
            'organism': props.currentOrganism, //props.selectedItemOrganism[0]['value'],
            'selectedNormalization': props.trackListCheckbox,
            'page' : page, 
            'rowsPerPage': rowsPerPage, 
            'order': '',
            'orderBy': '',
            'from': extraParams['from'].map(n => n['value']), 
            'gene_type': extraParams['gene_type'].map(n => n['value']), 
            'gene_name': extraParams['gene_name'].map(n => n['value']),
            'SearchGeneName': extraParams['SearchGeneName'],
            'LocusCoordinatesForRNAs': locusFilter,
            // 'LocusCoordinatesForRNAs': extraParams['LocusCoordinatesForRNAs'],
            'LengthForRNAs': extraParams['LengthForRNAs'],
          } 
        )
      } else {
        if (Object.keys(props.basicGeneTableData).length !== 0) {
          // console.log('page, rowsPerPage, wwwwwwwwwwwwwwwwwwww')
          let locusFilter = []
          if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
              locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
          }
          props.fetchBasicGeneTableFromDNA(
            {
              'gene_name_for_dna': [props.currentGeneNameForDNA + props.geneBody], //['NEAT1_1']
              'gene_name_upstream': props.currentValueUpstream,
              'gene_name_downstream': props.currentValueDownstream,
              'LocusCoordinatesForDNA': props.currentLocusCoordinatesForDNA, //'chr11:65422774-65445540'
              'rnaListID' : props.currentRnaListID,
              'organism': props.currentOrganism, //'Homo sapiens'
              'selectedNormalization': props.trackListCheckbox,
              
              'page' : page, 
              'rowsPerPage': rowsPerPage, 
              'order': '',
              'orderBy': '',
              'from': extraParams['from'].map(n => n['value']), 
              'gene_type': extraParams['gene_type'].map(n => n['value']), 
              'gene_name': extraParams['gene_name'].map(n => n['value']),
              'SearchGeneName': extraParams['SearchGeneName'],
              'LocusCoordinatesForRNAs': locusFilter,
              // 'LocusCoordinatesForRNAs': extraParams['LocusCoordinatesForRNAs'],
              'LengthForRNAs': extraParams['LengthForRNAs'],
            } 
          )
        }
      }
    }
  }, [page, rowsPerPage])
  

  useEffect(() => {
    if (rnaListID.length > 0 || props.currentRnaListID.length > 0) {
      props.selectOneId(0)
      setPage(0)
      if (props.valueTab === 0) {
        let rnaListID_true = ''
        // debugger
        if (currentOrganismFromRNA !== props.currentOrganism) {
          rnaListID_true = makeID(15)
          setRnaListID(rnaListID_true)
          setCurrentOrganismFromRNA(props.currentOrganism)
        } else {
          rnaListID_true = rnaListID
        }
        if (rnaListID_true.length > 0 ) {
          let locusFilter = []
          if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
              locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
          }
          // console.log('rnaListID_true![extraParams]',rnaListID_true)
          props.fetchBasicGeneTable(
            {
              'rnaListID' : rnaListID_true,
              'organism': props.currentOrganism, //props.selectedItemOrganism[0]['value'],
              'selectedNormalization': props.trackListCheckbox,
              'page' : 0, 
              'rowsPerPage': rowsPerPage, 
              'order': '',
              'orderBy': '',
              'from': extraParams['from'].map(n => n['value']), 
              'gene_type': extraParams['gene_type'].map(n => n['value']), 
              'gene_name': extraParams['gene_name'].map(n => n['value']),
              'SearchGeneName': extraParams['SearchGeneName'],
              'LocusCoordinatesForRNAs': locusFilter,
              'LengthForRNAs': extraParams['LengthForRNAs'],
            } 
          )
        }
      } else {
        if (Object.keys(props.basicGeneTableData).length !== 0) {
        let locusFilter = []
        if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
            locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
        }
        // console.log('rnaListID_true![extraParams] FROM DNA')
        props.fetchBasicGeneTableFromDNA(
          {
            'gene_name_for_dna': [props.currentGeneNameForDNA + props.geneBody], //['NEAT1_1']
            'gene_name_upstream': props.currentValueUpstream,
            'gene_name_downstream': props.currentValueDownstream,
            'LocusCoordinatesForDNA': props.currentLocusCoordinatesForDNA, //'chr11:65422774-65445540'
            'rnaListID' : props.currentRnaListID,
            'organism': props.currentOrganism, //'Homo sapiens'
            'selectedNormalization': props.trackListCheckbox,

            'page' : 0, 
            'rowsPerPage': rowsPerPage, 
            'order': '',
            'orderBy': '',
            'from': extraParams['from'].map(n => n['value']), 
            'gene_type': extraParams['gene_type'].map(n => n['value']), 
            'gene_name': extraParams['gene_name'].map(n => n['value']),
            'SearchGeneName': extraParams['SearchGeneName'],
            'LocusCoordinatesForRNAs': locusFilter,
            'LengthForRNAs': extraParams['LengthForRNAs'],
          } 
        )
      }}
    }
  }, [extraParams])
  

  useEffect(() => {
    if (rnaListID.length > 0) { //|| props.currentRnaListID.length > 0
      let ID = makeID(15)
      setRnaListID(ID)
      if (props.valueTab === 0) {
        let locusFilter = []
        if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
            locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
        }
        props.fetchBasicGeneTable(
          {
            'rnaListID' : ID,
            'organism': props.currentOrganism, //props.selectedItemOrganism[0]['value'],
            'selectedNormalization': props.trackListCheckbox,
            'page' : page, 
            'rowsPerPage': rowsPerPage, 
            'order': order,//'',
            'orderBy': orderBy,//'',
            'from': extraParams['from'].map(n => n['value']), 
            'gene_type': extraParams['gene_type'].map(n => n['value']), 
            'gene_name': extraParams['gene_name'].map(n => n['value']),
            'SearchGeneName': extraParams['SearchGeneName'],
            'LocusCoordinatesForRNAs': locusFilter,
            'LengthForRNAs': extraParams['LengthForRNAs'],
          } 
        )
      }
    }
  }, [props.trackListCheckbox])


  useEffect(() => {
    if (props.currentRnaListID.length > 0 && props.applyOrNormalization !== "") {
      if (props.applyOrNormalization === "Normalization") {
        // console.log('UUUUUUUUUUU 2')
        let locusFilter = []
        if (extraParams['LocusCoordinatesForRNAs'].length !== 0) {
            locusFilter = LocusFilterParser(extraParams['LocusCoordinatesForRNAs'])
        }
        props.fetchBasicGeneTableFromDNA(
          {
            'gene_name_for_dna': [props.currentGeneNameForDNA + props.geneBody], //['NEAT1_1']
            'gene_name_upstream': props.currentValueUpstream,
            'gene_name_downstream': props.currentValueDownstream,
            'LocusCoordinatesForDNA': props.currentLocusCoordinatesForDNA, //'chr11:65422774-65445540'
            'rnaListID' : props.currentRnaListID,
            'organism': props.currentOrganism, //'Homo sapiens'
            'selectedNormalization': props.trackListCheckbox,
            'page' : page, 
            'rowsPerPage': rowsPerPage, 
            'order': order,
            'orderBy': orderBy,
            'from': extraParams['from'].map(n => n['value']), 
            'gene_type': extraParams['gene_type'].map(n => n['value']), 
            'gene_name': extraParams['gene_name'].map(n => n['value']),
            'SearchGeneName': extraParams['SearchGeneName'],
            'LocusCoordinatesForRNAs': locusFilter, //extraParams['LocusCoordinatesForRNAs'],
            'LengthForRNAs': extraParams['LengthForRNAs'],
          } 
        )
      } else {
        // console.log('UUUUUUUUUUU 3')
        props.fetchBasicGeneTableFromDNA({
          'gene_name_for_dna': [props.currentGeneNameForDNA + props.geneBody],
          'gene_name_upstream': props.currentValueUpstream,
          'gene_name_downstream': props.currentValueDownstream,
          'LocusCoordinatesForDNA': props.currentLocusCoordinatesForDNA,
          'rnaListID' : props.currentRnaListID,
          'organism': props.currentOrganism, 
          'selectedNormalization': props.trackListCheckbox,
          'page' : 0, 
          'rowsPerPage': 25, 
          'order': 'asc',
          'orderBy': '',
          'from': [], 
          'gene_name': [],
          'gene_type': [], 
          'SearchGeneName': "",
          'LocusCoordinatesForRNAs': "",
          'LengthForRNAs': "",
        })
        setPage(0)
        setRowsPerPage(25)
      }
    }
  }, [props.currentRnaListID])




  const classes = useStyles();

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(parseInt(value, 10))
    setPage(0);
  };
  
  const handleRequestSort = (event, property) => {
    // console.log('property',property)
    const isAsc =  orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id, gene_name) => {
    // debugger
    // props.selectOneId(id); //удалить, id никогда не будет = 0 (удалить ф-ции, которые обнуляют id)
    if (props.valueTab === 0) {
      const data_2 = {
        selectedTracks: id,
        typeOfContacts: props.typeOfContacts + "___" + gene_name,
        selectedTrackNames: props.currentOrganism, 
        selectedNormalization: "norm_value", //props.trackListCheckbox,
        gene_name_target: "",
      }
      localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
      
      const data = {
            'RNAname': gene_name,
            'selectedId': id,
            'typeOfContacts': props.typeOfContacts,
            'organism': props.currentOrganism, 
            'selectedNormalization': "norm_value", //props.trackListCheckbox,
          } 
      // console.log('QWJKJKOFKJ!!!!!!!!!!! DATA', data)
      localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
      window.open("/basic_graphical_summary", "_blank");
    } else {
      // if (props.basicGeneTableDataFromDNA['all_insert'].length !== 0) {
        const data_2 = {
          selectedTracks: id, //props.selectedId,
          typeOfContacts: props.typeOfContacts + "___" + gene_name,
          selectedTrackNames: props.currentOrganism + "____" + props.basicGeneTableDataFromDNA['all_insert'],
          selectedNormalization: "norm_value", //props.trackListCheckbox,
          gene_name_target: props.basicGeneTableDataFromDNA['gene_name_target'],
          // 'all_insert': props.basicGeneTableDataFromDNA['all_insert'],
        }
        localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
        
        const data = {
              'RNAname': gene_name,
              'selectedId': id, //props.selectedId,
              'typeOfContacts': props.typeOfContacts,
              'organism': props.currentOrganism + "____" + props.basicGeneTableDataFromDNA['all_insert'],
              'selectedNormalization': "norm_value", //props.trackListCheckbox,
            } 
        // console.log('QWJKJKOFKJ!!!!!!!!!!! DATA', data)
        // localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
        // window.open("/basic_graphical_summary", "_blank");
        localStorage.setItem("data_basic_graphical_summary_dna_filter",  JSON.stringify(data));
        window.open("/basic_graphical_summary_dna_filter", "_blank");
      // } else {
      //   const data_2 = {
      //     selectedTracks: id, //props.selectedId,
      //     typeOfContacts: props.typeOfContacts,
      //     selectedTrackNames: props.currentOrganism,//props.selectedItemOrganism[0]['value'], //currentOrganism
      //     selectedNormalization: props.trackListCheckbox,
      //   }
      //   localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
        
      //   const data = {
      //         'selectedId': id, //props.selectedId,
      //         'typeOfContacts': props.typeOfContacts,
      //         'organism': props.currentOrganism, //props.selectedItemOrganism[0]['value'], //currentOrganism
      //         'selectedNormalization': props.trackListCheckbox,
      //       } 
      //   console.log('QWJKJKOFKJ!!!!!!!!!!! DATA 2', data)
      //   localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
      //   window.open("/basic_graphical_summary", "_blank");
      // }
    }
  };
  // console.log('selectedId!!!!!!!!!!!!!!!!!!',selectedId)

  const handleChangePage = (newPage) => {
    setPage(newPage);
}

  // const isSelected = id => selectedId === id;

  let table = null;
  let column = null;
  let rows_id = rows.map(n => n['id'])
  // console.log('Object.keys(rows)',Object.keys(rows), rows)
  
  if (Object.keys(props.basicGeneTableData).length !== 0) { //if (Object.keys(props.basicGeneTableData).length !== 0) {
    table = 
            <div className={classes.root}>
            <div className={classes.tableWrapper}>
            <TableContainer className={props.valueTab === 1 ? classes.containerRfomDna : classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={'small'}>
              <BasicTableRNAHead
                // numSelected={selected.length}
                rows={rows}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                // rowCount={data.length}
              />
              <TableBody>
                  {/* {props.basicGeneTableData['data'].map(n => { */}
                  {props.basicGeneTableData['data'].map(n => {
                    return (
                      <TableRow 
                        hover 
                        // role="checkbox" 
                        tabIndex={-1} 
                        key={n.id} 
                        size='small'
                        className={classes.sizeSmall}
                      >
                        {rows_id.map((key, indexR) => {
                          if (key === 'gene_name') {
                            column=
                            <TableCell 
                              className={classes.sizeSmall} 
                              size='small' 
                              align="center" 
                              style={{
                                fontSize: 15,
                                textAlign: 'left',
                                whiteSpace: 'nowrap', 
                                border: '1px solid #d3d4d5',
                                borderTop: '1px solid #d3d4d5',
                                borderLeft: '1px solid #d3d4d5',}} 
                                // onClick={event => handleClick(event, n.id)}
                            >
                              <Tooltip title='Go to "Graphical Summary" page' >
                                <Link component="button" onClick={event => handleClick(event, n.id, n.gene_name)}>{n.gene_name}</Link>
                              </Tooltip>
                              {/* <FormControlLabel
                                control={
                                  <Radio 
                                    color="secondary" 
                                    style={{padding: 0, marginLeft: 10}}
                                  />
                                }
                                checked={true ? selectedId === n.id : false}
                              />
                              {n.gene_name} */}
                            </TableCell>
                          } else {
                            let backgroundColorForData = ''
                            if (indexR === 2 || indexR === 3 || indexR === 4) {
                              backgroundColorForData = "#bbdefb"
                            } else if (indexR === 5 || indexR === 6) {
                              backgroundColorForData = "#f0f4c3"
                            } else {
                              backgroundColorForData = "white"
                            }
                            column=
                              <TableCell 
                                className={classes.sizeSmall} 
                                key={indexR}
                                size='small' 
                                align="left" 
                                style={{backgroundColor: backgroundColorForData, fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',whiteSpace: 'nowrap'}}
                                // onClick={event => handleClick(event, n.id)}
                              >
                                {n[key]}
                              </TableCell>
                          }
                          return (
                            column
                          )
                        })}
                        {/* <TableCell 
                          className={classes.sizeSmall} 
                          size='small' 
                          align="center" 
                          style={{
                            fontSize: 15,
                            textAlign: 'left',
                            whiteSpace: 'nowrap', 
                            border: '1px solid #d3d4d5',
                            borderTop: '1px solid #d3d4d5',
                            borderLeft: '1px solid #d3d4d5',}} 
                            onClick={event => handleClick(event, n.id)}
                        >
                          <FormControlLabel
                            control={
                              <Radio 
                                color="secondary" 
                                style={{padding: 0, marginLeft: 10}}
                              />
                            }
                            checked={true ? selectedId === n.id : false}
                          />
                          {n.gene_name}
                        </TableCell>
                          <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} onClick={event => handleClick(event, n.id)}>{n.gene_type}</TableCell>
                          <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} onClick={event => handleClick(event, n.id)}>{n.locus}</TableCell>
                          <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} onClick={event => handleClick(event, n.id)}>{n.len}</TableCell>
                          <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} onClick={event => handleClick(event, n.id)}>{n.strand}</TableCell>
                          {props.valueTab === 1 ?
                          <div>
                              <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} onClick={event => handleClick(event, n.id)}>{n.all_vs_all}</TableCell>
                              <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} onClick={event => handleClick(event, n.id)}>{n.one_vs_all}</TableCell>
                          </div>
                              :
                              <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} onClick={event => handleClick(event, n.id)}>{n.max_sum_norm_value}</TableCell>
                          }
                          <TableCell className={classes.sizeSmall} size='small' align="center" style={{fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}} onClick={event => handleClick(event, n.id)}>{n.from}</TableCell> */}
                        </TableRow>





                      );
                    })}
                </TableBody>
              </Table>
              </TableContainer>
            </div>
            <RnaListPagination
                    rowsPerPageOptions={[25, 50, 100]}
                    // count={props.basicGeneTableData['LEN']}
                    count={props.basicGeneTableData['LEN']}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            {/* </Paper> */}
          </div>
    }
  // }, [props.basicGeneTableData['data']])


  return (
    <div>
      {!props.loadingBasicGeneTable ? //&& Object.keys(props.basicGeneTableData).length !== 0
        <div className={classes.paper}>
            {table}
        </div>
        : 
        <div className={classes.loading}>
            <LinearProgress color={"primary"}/>
            <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
            </Typography>
        </div>
      }
      <WarningMessage open={props.snackOpenBasicGeneTable} severity={"error"} message={props.snackTextBasicGeneTable} />
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    selectedId: state[moduleBasicAnalysis].selectedId,
    typeOfContacts: state[module].typeOfContacts,
    trackListCheckbox: state[moduleFilter].trackListCheckbox,

    // selectedItemOrganism: state[module].selectedItemOrganism,

    // basicGeneTableData: state[moduleBasicAnalysis].basicGeneTableData,
    // loadingBasicGeneTable: state[moduleBasicAnalysis].loadingBasicGeneTable,
    // snackOpenBasicGeneTable: state[moduleBasicAnalysis].snackOpenBasicGeneTable,
    // snackTextBasicGeneTable: state[moduleBasicAnalysis].snackTextBasicGeneTable,

    basicGeneTableDataFromDNA: state[moduleBasicAnalysis].basicGeneTableDataFromDNA,
  };
};


export default connect(mapStateToProps, { selectOneId, fetchBasicGeneTable })(BasicTableRNA);//selectOne,