import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
// import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import MenuIcon from '@material-ui/icons/Menu';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import title from '../pages/svg/title.svg'

import { Link } from 'react-router-dom'

import { connect } from 'react-redux';
// import { logout } from '../actions/generalActions'; //toggleMenu

const module = "menu";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // marginBottom: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(),
    color: 'white',
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
    },
    //textAlign: 'right',
  },
  grow: {
    flexGrow: 1,
    marginLeft: 20,
  },
  header: {
    height: '40px',
  },
  strong: {
    color: '#424242',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
  }
  // appBar: {
  //   // zIndex: 1000,
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  // },
  // menuButton: {
  //   marginLeft: 12,
  //   marginRight: 20,
  // },
  // headerButton: {
  //   marginLeft: 800,
  //   // float: 'right',
  // },
  // //mogno delete
  // button: {
  //   margin: theme.spacing(),
  // },
}));

const Menu = (props) => {

  // const { open } = props;
  const classes = useStyles();
  // console.log('window.location.href',window.location.href)
  // console.log('props.headerName',props.headerName)
  return (
    <div>
      {/* {props.headerName === " database" //|| props.headerName === ": tutorial"
        ?
          <div style={{textAlign: "center"}}>
            <img src={title} alt="title" width='90%'/> 
          </div>
        :
          null
      } */}
      <div className={classes.root}>
        {/* <AppBar position={props.headerName === " database" ? "unfixed" : "fixed"} //|| props.headerName === ": tutorial" */}
        <AppBar position={"fixed"}
          >
          <Toolbar style={{backgroundColor: '#009688'}} variant="dense" className={classes.header}> 
          <Tooltip title="Go to Start page" placement="bottom-start">
            <Typography style={{ color: 'white' }} variant="h5" component="h1" className={classes.grow}>
              <Link target="_blank" className={classes.link} to="/">
                RNA-Chrom{props.headerName}
              </Link>
              {/* <strong className={classes.strong}>RNA-Chrom</strong>: a chromatin-RNA interactions database */}
            </Typography>            
          </Tooltip>
          {/* {window.location.href === 'ofo' ?
            <Button  
              variant="outlined"
              style={{backgroundColor: '#00695c'}} 
              className={classes.leftIcon} 
              onClick={() =>  window.open("/tutorial", "_blank")}
            >
              Tutorial
            </Button>
          :
            <Button  
              variant="outlined"
              // variant="contained"
              style={{backgroundColor: '#00695c'}} 
              className={classes.leftIcon} 
              onClick={() =>  window.open("/tutorial", "_blank")}
            >
              Advanced tutorial
            </Button>
          } */}
            <Button  
              variant="outlined"
              style={{backgroundColor: '#00695c'}} 
              className={classes.leftIcon} 
              // component={Link} 
              // to="/....."
              onClick={() =>  window.open("/overview", "_blank")}
            >
              Overview
            </Button>
            {/* Tutorial */}
            <Button  
              variant="outlined"
              style={{backgroundColor: '#00695c'}} 
              className={classes.leftIcon} 
              onClick={() =>  window.open("/tutorial", "_blank")}
            >
              Tutorial
            </Button>

            {/* <Button  
              variant="outlined"
              // variant="contained"
              // style={{backgroundColor: 'secondary'}}
              style={{backgroundColor: '#00695c'}} 
              className={classes.leftIcon} 
              component={Link} 
              to="/tracks"
              // onClick={() =>  window.open("/tracks", "_blank")}
            >
              Advanced analysis
            </Button>  */}
            {/* <Button  
              variant="outlined"
              // variant="contained"
              style={{backgroundColor: '#00695c'}}
              className={classes.leftIcon} 
              // component={Link} 
              // to="/protocol"
              onClick={() =>  window.open("/protocol", "_blank")}
            >
              Data Processing
            </Button> */}
            <Button  
              variant="outlined"
              // variant="contained"
              style={{backgroundColor: '#00695c'}}
              className={classes.leftIcon} 
              // component={Link} 
              // to="/experiments"
              onClick={() =>  window.open("/experiments", "_blank")}
            >
              Database content{/* Experiments overview Data list */}
            </Button>
            <Button  
              variant="outlined"
              style={{backgroundColor: '#00695c'}}
              className={classes.leftIcon} 
              onClick={() =>  window.open("/about", "_blank")}
            >
              About
            </Button>
            {/* {props.logged && 
            <Button variant="outlined" style={{backgroundColor: '#bdbdbd'}} className={classes.leftIcon} component={Link} to="/register_form" onClick={props.logout}>Logout</Button> } */}
            {/* <IconButton color="inherit" title="GitHub repository" >
              <a href="https://github.com/ryabykh2018/Database" target="_blank" rel="noopener noreferrer">
                <AccountCircle className={classes.leftIcon} />
              </a>
            </IconButton>             */}
          </Toolbar>
        </AppBar>
      </div>
    </div>
    );
}

const mapStateToProps = (state) => {
  return {
    // open: state[module].menuOpen,
    headerName: state[module].headerName,
    // logged: state[module].isLogged,
  }
}

// export default connect(mapStateToProps, {logout})(Menu);
export default connect(mapStateToProps)(Menu);