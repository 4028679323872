import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'; //withStyles
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import TextField from '@material-ui/core/TextField';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import from_rna from './svg/from_rna.svg'
import browseAllRNAs from './svg/browseAllRNAs.svg'
import Decorator from '../components/HtmlTooltip';
// import Divider from '@material-ui/core/Divider';
import InputsTwo from '../components/TextAdd_two';
import InputsLenght from '../components/InputsLenght';
import InputThree from '../components/InputThree';
// import classNames from 'classnames';
// import { connect } from 'react-redux';
// import TableNewSuper from '../components/TableNewSuper';
import WarningMessage from '../components/MessageSnackbar';
import {LocusFilterParser} from '../components/TableAllTargetGenes';
// import TableMetadata from '../components/TableMetadata';
import BasicTableRNA from '../components/TABLEpartBasic';
// import TrackListCheckboxes from '../components/TrackListCheckboxes';
import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';

import { fetchGenes, handleGeneSelect, changeCurrentGeneInput } from '../actions/genesActions';
import { fetchGeneTypes, handleGeneTypesSelect, changeCurrentGeneTypesInput } from '../actions/genesActions';
import { fetchAnnotation, handleAnnotationSelect, changeCurrentAnnotationInput } from '../actions/genesActions';
import {textChange, locusWarningChangeFalse,locusWarningChangeTrue,currentLocusChange} from '../actions/generalActions';
import {textLengthChange, lengthWarningChangeFalse,lengthWarningChangeTrue,currentLengthChange} from '../actions/generalActions';
import { textChangeSearchGeneName } from '../actions/generalActions';
import { handleOrganismSelect } from '../actions/organismActions';
import { handleHeader } from '../actions/generalActions';

import OrganismSelectorSimple from '../components/AutoSuggestOrganismSimple';
import GeneSelector from '../components/AutoSuggest';
import GeneTypesSelector from '../components/AutoSuggestTypeGenes';
import AnnotationSelector from '../components/AutoSuggestAnnotation';
import DownloadTrack from '../components/DownloadTrack';



const module = "genes";
const moduleTable = "table";
const moduleBasicAnalysis = "basicAnalysis";
const moduleFilter = "filter";
const moduleText = "text";

const rows_fromRNA = [
  // {id: 'id', numeric: false, disablePadding: false, label: 'ID'},
  {id:'gene_name', numeric: false, disablePadding: false, label: 'RNA name' },
  {id:'count_id', numeric: false, disablePadding: true, label: 'Total exps'},
  {id:'max_cpkm_all_vs_all', numeric: false, disablePadding: true, label: 'max CPKM'}, // All-to-all max(CPKM norm)
  {id:'max_sum_norm_value_all_vs_all', numeric: false, disablePadding: true, label: 'max n-reads'}, // All-to-all max(CPKM obs)
  {id:'number_of_all_vs_all', numeric: false, disablePadding: true, label: 'Exps'}, //All-to-all
  // {id:'one_vs_all', numeric: false, disablePadding: true, label: 'max CPKM'}, //One-to-all max(CPKM norm)
  {id:'max_sum_norm_value_one_vs_all', numeric: false, disablePadding: true, label: 'max n-reads'}, //One-to-all max(CPKM obs)
  {id:'number_of_one_vs_all', numeric: false, disablePadding: true, label: 'Exps'},//One-to-all
  {id:'gene_type', numeric: false, disablePadding: true, label: 'RNA type'},
  {id:'locus', numeric: false, disablePadding: true, label: 'Gene location'},
  {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
  {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
  {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},
];


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 40,
    marginTop: theme.spacing(3),
  },
  link: {
    color: 'rgb(0, 150, 136)',
    '&:hover': {
      color: 'grey',
    }
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
  tabs: {
    color: 'black',
  },
  loading: {
    marginTop: 150,
    textAlign: 'center',
  },
  button: {
    padding: theme.spacing(),
  },
  wideButton: {
    padding: theme.spacing(),
    width: '98%',
    marginTop: theme.spacing(),
  },
  autocomplete: {
    flexGrow: 1,
    paddingTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
}));

const BasicFromRnaViewGenes = (props) => {

  const classes = useStyles();
  document.title = "RNA-Chrom: Browse RNAs";
  
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [extraParams, setExtraParams] = useState({'from': [], 'gene_type': [], 'gene_name': [], 'SearchGeneName': '', 'LocusCoordinatesForRNAs': '', 'LengthForRNAs': ''})
  const [currentOrganism, setCurrentOrganism] = useState(JSON.parse(localStorage.getItem("browse_rnas"))['organism'])
  // const [organism, setOrganism] = useState('')
  
  useEffect(() => {
    props.handleHeader(': where does the selected RNA contact with chromatin?')
    const organism = JSON.parse(localStorage.getItem("browse_rnas"))['organism']
    // setOrganism(JSON.parse(localStorage.getItem("browse_rnas"))['organism'])
    props.handleOrganismSelect([{label: organism, value: organism}])
    // setCurrentOrganism(organism)
  }, [])
  // console.log('props.selectedItemOrganism[0][value]!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!_____',props.selectedItemOrganism[0]['value'])
  // useEffect(() => {
  //   setCurrentOrganism(props.selectedItemOrganism[0]['value'])
  // }, [props.selectedItemOrganism])

  const handleClickApply = (e,id) => {   
    if (props.locusWarning === false && props.lengthWarning === false) { 
      setExtraParams({'from': props.selectedItemAnnotation, 
                      'gene_type': props.selectedItemGeneTypes, 
                      'gene_name': props.selectedItem, 
                      'SearchGeneName': props.valueSearchGeneName, 
                      'LocusCoordinatesForRNAs': props.currentLocus,
                      'LengthForRNAs': props.currentLength
                    })
      setCurrentOrganism(props.selectedItemOrganism[0]['value'])
    } else {
      setMessage("'Mininum gene length' or 'Locus coordinates' were entered in the wrong format");
      setOpen(true)
    }
  };
  // console.log('props.trackListCheckbox',props.trackListCheckbox)
  // const handleNewWindowClick = (event, label) => {
  //   if (props.selectedId !== 0) {
  //       const data_2 = {
  //         selectedTracks: props.selectedId,
  //         typeOfContacts: props.typeOfContacts,
  //         selectedTrackNames: currentOrganism, 
  //         selectedNormalization: props.trackListCheckbox,
  //       }
  //       localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
        
  //       const data = {
  //             'selectedId': props.selectedId,
  //             'typeOfContacts': props.typeOfContacts,
  //             'organism': currentOrganism, 
  //             'selectedNormalization': props.trackListCheckbox,
  //           } 
  //       console.log('QWJKJKOFKJ!!!!!!!!!!! DATA', data)
  //       localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
  //       window.open("/basic_graphical_summary", "_blank");
  //   } else {
  //     setMessage('Choose some RNA')
  //     setOpen(true)
  //   }
  // }


  const handleClickDownloadFullTable = () => {
    window.open("http://bioinf.fbb.msu.ru/rna_chrom/download/Complete-table-of-RNAs", "_blank")
  }

  const handleClose = () => {
    setOpen(false)
    setMessage('')
  }

  return (
    <div className={classes.root}>
              <div>
              <Grid container spacing={0}>
                <Grid item xs={3} style={{borderRight: '2px solid #000000'}}>
                  <Paper elevation={0} style={{borderLeft: '1px solid #000000', borderTop: '1px solid #000000', borderBottom: '1px solid #000000'}} square>
                    <a id="from_rna" >
                      <img width='100%' src={browseAllRNAs} alt="browseAllRNAs" />
                    </a>
                  </Paper>
                  <div>
                    <InputThree 
                      nameSelect='Search by "RNA name"'
                      textChange={props.textChangeSearchGeneName}
                      valueLocus={props.valueSearchGeneName}
                    />
                  </div>
                  <div style={{marginRight: 3}}>
                    <GeneSelector
                      disabled={props.loadingGenes}
                      fetchGenes={props.fetchGenes}
                      handleGeneSelect={props.handleGeneSelect}
                      changeCurrentGeneInput={props.changeCurrentGeneInput}
                      selectedItem={props.selectedItem}
                      currentInput={props.currentInput}
                      parent={'welcome page, from RNA'}
                      placeholderText={"Select RNAs"}
                      organism={currentOrganism}
                    />
                  </div>
                  <div style={{marginRight: 3}}>
                    <GeneTypesSelector 
                      disabled={props.loadingGeneTypes}
                      fetchGeneTypes={props.fetchGeneTypes}
                      handleGeneTypesSelect={props.handleGeneTypesSelect}
                      changeCurrentGeneTypesInput={props.changeCurrentGeneTypesInput}
                      selectedItemGeneTypes={props.selectedItemGeneTypes}
                      currentGeneTypesInput={props.currentGeneTypesInput}
                      parent={'welcome page, from RNA'}
                      placeholderText={"Select RNA types"}
                      organism={currentOrganism}
                    />
                  </div>
                  <div style={{marginRight: 3}}>
                    <AnnotationSelector
                      disabled={props.loadingAnnotation}
                      fetchAnnotation={props.fetchAnnotation}
                      handleAnnotationSelect={props.handleAnnotationSelect}
                      changeCurrentAnnotationInput={props.changeCurrentAnnotationInput}
                      selectedItemAnnotation={props.selectedItemAnnotation}
                      currentAnnotationInput={props.currentAnnotationInput}
                      parent={'welcome page, from RNA'}
                      organism={currentOrganism}
                    />
                  </div>
                  <div>
                    <Decorator 
                      key = {1000}
                      text = {<Typography color="inherit">Enter coordinates of <b>genomic loci</b> to get all RNAs from them</Typography>}
                      component = {
                      <div>
                        <InputsTwo 
                          nameSelect='chr1:10-230; chrY'
                          textChange={props.textChange}
                          valueLocus={props.valueLocus}
                          locusWarningChangeFalse={props.locusWarningChangeFalse}
                          locusWarningChangeTrue={props.locusWarningChangeTrue}
                          currentLocusChange={props.currentLocusChange}
                        />
                      </div>
                      }
                    />
                  </div>
                  <div style={{marginTop: 8, marginLeft: 8}}>
                    <InputsLenght
                      nameSelect='Minimum gene length'
                      textLengthChange={props.textLengthChange}
                      valueLength={props.valueLength}
                      lengthWarningChangeFalse={props.lengthWarningChangeFalse}
                      lengthWarningChangeTrue={props.lengthWarningChangeTrue}
                      currentLengthChange={props.currentLengthChange}
                    />
                  </div>
                  <div style={{marginLeft: 8, marginRight: 10}}>
                    <OrganismSelectorSimple first_working_page={false}/>
                  </div>
                  <div style={{textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                    <Button
                      //   className={classes.wideButton}
                      className={classes.button}
                      variant="contained"
                      size="small"
                      // style={{backgroundColor: '#96000E', color: 'white'}}
                      color={"secondary"}
                      disabled={props.loadingBasicGeneTable}
                      onClick={e => handleClickApply(e, 'igfdsfghjk')}
                    > 
                      Apply filters
                    </Button> 
                  </div>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <Typography style={{textAlign: 'right'}} variant="h5" component="h1">
                        <b>Complete table of RNAs</b>
                      </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Download grouped table">
                          <div style={{textAlign: "center"}}>
                            <DownloadTrack 
                              whatType={"grouped table"} 
                              typeOfContacts={""} 
                              all_insert={""} 
                              expId_and_name={currentOrganism}
                              params={{
                                'from': props.selectedItemAnnotation.map(n => n['value']), 
                                'gene_type': props.selectedItemGeneTypes.map(n => n['value']), 
                                'gene_name': props.selectedItem.map(n => n['value']), 
                                'SearchGeneName': props.valueSearchGeneName, 
                                'LocusCoordinatesForRNAs': props.currentLocus.length !== 0 ? LocusFilterParser(props.currentLocus) : props.currentLocus,
                                'LengthForRNAs': props.currentLength,
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                      {/* <Grid item xs={1}>
                        <Tooltip title="Download ungrouped table">
                          <div style={{textAlign: "center"}}>
                            <DownloadTrack 
                              whatType={"ungrouped table"} 
                              typeOfContacts={""} 
                              all_insert={""} 
                              expId_and_name={currentOrganism}
                              params={{
                                'from': props.selectedItemAnnotation.map(n => n['value']), 
                                'gene_type': props.selectedItemGeneTypes.map(n => n['value']), 
                                'gene_name': props.selectedItem.map(n => n['value']), 
                                'SearchGeneName': props.valueSearchGeneName, 
                                'LocusCoordinatesForRNAs': props.currentLocus.length !== 0 ? LocusFilterParser(props.currentLocus) : props.currentLocus,
                                'LengthForRNAs': props.currentLength,
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Grid> */}
                      <Grid item xs={1}>
                        <Tooltip title="Download full ungrouped table">
                          <div style={{textAlign: "center"}}>
                            <IconButton 
                                style={{padding: 0}}
                                onClick={e => handleClickDownloadFullTable()}
                                >
                                <CloudDownloadIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6}>
                        {/* <TrackListCheckboxes disabled={props.loadingBasicGeneTable} /> */}
                        <div style={{textAlign: "left", marginLeft: 5}}> 
                          <TrackListCheckboxesNew disabled={props.loadingBasicGeneTable}/>
                        </div>
                      </Grid>
                    </Grid>
                  <BasicTableRNA 
                    extraParams={extraParams} 
                    valueTab={0} 
                    geneBody={''}
                    rows={rows_fromRNA}
                    basicGeneTableData={props.basicGeneTableData}
                    loadingBasicGeneTable={props.loadingBasicGeneTable}
                    snackOpenBasicGeneTable={props.snackOpenBasicGeneTable}
                    snackTextBasicGeneTable={props.snackTextBasicGeneTable}
                    currentOrganism={currentOrganism}
                    currentRnaListID={''}
                />
                {/*<div style={{textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                    <TrackListCheckboxes />
                     <Button
                        className={classes.button}
                        variant="contained"
                        size="small"
                        // style={{backgroundColor: '#96000E', color: 'white'}}
                        color={"secondary"}
                        disabled={props.loadingBasicGeneTable}
                        onClick={e => handleNewWindowClick(e, "basic charts")}
                    >
                        Start analysis
                    </Button>  
                </div>*/}
                </Grid>
            </Grid>
            </div>
        <Typography variant={"subtitle1"} style={{ padding: 5}}>
            If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
        </Typography>
        <WarningMessage open={open} severity={"warning"} handleClose={handleClose} message={message} />
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
      AllExps: state[moduleTable].AllExps,

      loadingGenes: state[module].loadingGenes,
      selectedItem: state[module].selectedItem,
      currentInput: state[module].currentGeneInput,

      loadingGeneTypes: state[module].loadingGeneTypes,
      selectedItemGeneTypes: state[module].selectedItemGeneTypes,
      currentGeneTypesInput: state[module].currentGeneTypesInput,

      loadingAnnotation: state[module].loadingAnnotation,
      selectedItemAnnotation: state[module].selectedItemAnnotation,
      currentAnnotationInput: state[module].currentAnnotationInput,

      
      
      selectedItemOrganism: state[moduleTable].selectedItemOrganism,
      selectedId: state[moduleBasicAnalysis].selectedId,
      typeOfContacts: state[module].typeOfContacts,
      trackListCheckbox: state[moduleFilter].trackListCheckbox,
      
      valueLocus: state[moduleText].value,
      locusWarning: state[moduleText].locusWarning,
      currentLocus: state[moduleText].currentLocus,
      
      valueSearchGeneName: state[moduleText].valueSearchGeneName,
      
      valueLength: state[moduleText].valueLength,
      lengthWarning: state[moduleText].lengthWarning,
      currentLength: state[moduleText].currentLength,

      basicGeneTableData: state[moduleBasicAnalysis].basicGeneTableData,
      loadingBasicGeneTable: state[moduleBasicAnalysis].loadingBasicGeneTable,
      snackOpenBasicGeneTable: state[moduleBasicAnalysis].snackOpenBasicGeneTable,
      snackTextBasicGeneTable: state[moduleBasicAnalysis].snackTextBasicGeneTable,
  }
}

export default connect(mapStateToProps, 
  { handleHeader,
    fetchGenes, handleGeneSelect, changeCurrentGeneInput,
    fetchGeneTypes, handleGeneTypesSelect, changeCurrentGeneTypesInput,
    fetchAnnotation, handleAnnotationSelect, changeCurrentAnnotationInput,    
    textChange, locusWarningChangeFalse,locusWarningChangeTrue,currentLocusChange,
    textLengthChange, lengthWarningChangeFalse,lengthWarningChangeTrue,currentLengthChange,
    textChangeSearchGeneName,
    handleOrganismSelect,
  })(BasicFromRnaViewGenes);