import React, { useState } from 'react'; //useEffect,
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'; //lighten
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
// import Toolbar from '@material-ui/core/Toolbar';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import WarningMessage from './MessageSnackbar';

// import {submitCheckedTracks} from '../actions/genomeBrowserActions';

// const moduleGenomeBrowser = "genomeBrowser";
const module = "genes";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(),
    flexGrow: 1,
    width: '100%',
    // backgroundColor: lighten(theme.palette.secondary.light, 0.85), //theme.palette.background.paper
  },
  loading: {
    marginTop: 10,
    textAlign: 'center',
  },
  divider: {
    marginTop: 8,
    // marginLeft: theme.spacing(3),
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
  // paper: {
  //   //marginTop: 50,
  //   //margin: '0 auto',
  //   padding: 20,
  //   width: '100%',
  //   textAlign: 'center',
  //   marginBottom: 0,
  // },
  // wideButton: {
  //   paddingTop: theme.spacing(2),
  //   paddingBottom: theme.spacing(2),
  //   width: '90%',
  //   marginBottom: 25,
  //   marginTop: 25,
  // },
  wideButton: {
    padding: theme.spacing(),
    // paddingTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
    width: '95%',
    height: '80%',
    color: 'white',
  },
}));

const RnaListInfoButtonsBasic = (props) => {
  
    const classes = useStyles();
  
    const handleClick = (label) => { 
        if (label === "All contacts") {
            const data_2 = {
                selectedTracks: -666,
                typeOfContacts: "DNA_part" + "___" + props.name, //props.typeOfContacts + '___' + props.selectedItem.map(n => n['value']),
                selectedTrackNames: props.currentOrganism, //props.selectedItemOrganism[0]['value'], 
                selectedNormalization: "norm_value",//props.trackListCheckbox,
                gene_name_target: "",
                }

            localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
              
            const data = {
                'RNAname': props.name,
                'selectedId': -666,
                'typeOfContacts': "DNA_part" + "___" + props.name, //props.typeOfContacts + '___' + props.selectedItem.map(n => n['value']),
                'organism': props.currentOrganism,
                'selectedNormalization': "norm_value",//props.trackListCheckbox,
                } 
            // console.log('dataaa',data)
            localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
            window.open("/basic_graphical_summary", "_blank");
        } else {
            if (props.expID_typeOfExperiment.indexOf('All vs all') !== -1) {
                let fullDescription = props.track_description.track_name.map(track_name => {
                    return track_name.split("(")[1].split(")")[0] + "___" + track_name.split(": ")[1].split(" (")[0] + "&" + track_name + "^" + props.track_description[track_name].track_description
                })
                let fullExpId = props.track_description.track_name.map(track_name => {
                    return track_name.split("ID: ")[1].split(" (")[0] 
                })
                // console.log('fullDescription', fullDescription)  
                const data = {
                    selectedTracks: fullDescription,//['XIST___10&ID: 10 (XIST)^||Homo sapiens : [gene_name in (XIST, ) or gene_type in ()]&{LocusFilterDna in ()}{Gene Body: [full:false, gene_name in (), gene_types in ()]}{Upstream: [full:false, gene_name in (), gene_types in ()]}{Downstream: [full:false, gene_name in (), gene_types in ()]}{Upstream50kb: [full:false, gene_name in (), gene_types in ()]}{Downstream50kb: [full:false, gene_name in (), gene_types in ()]}{GeneDeserts: [full:false]}'],
                    typeOfContacts: 'DNA_part___fromRnaList',
                    selectedTrackNames: props.track_description.track_name, //['ID: 10 (XIST)'],
                    selectedNormalization: 'norm_value',
                    organism: props.currentOrganism,
                    experiment_id: fullExpId,//props.experiment_id,
                    gene_name: props.name,

                    type: props.type,
                    geneLocus: props.geneLocus,
                    dnaFilter: props.dnaFilter,
                    geneNameTarget: props.geneNameTarget,
                    str: props.str,
                    expID_typeOfExperiment: props.expID_typeOfExperiment,
                    length: props.length,
                    genome: props.genome,
                    from: props.from,
                }
                //   debugger
                localStorage.setItem("data_for_rna_parts",  JSON.stringify(data));
                window.open("/basic_graphical_summary/rna_parts", "_blank");
            } else {
                setMessage('Distribution of RNA-parts across their source gene body can only be plotted from the "All-to-all" data') //Profile of RNA-parts of contacts
                setOpen(true)
            }
        }
    };


    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    const handleClose = () => {
        setOpen(false)
        setMessage('')
    }

    const [buttonColor, setButtonColor] = useState('#96000E') //#bdbdbd
    const [buttonTextColor, setButtonTextColor] = useState('white') //black
    // console.log('RnaListDataq props.expID_typeOfExperiment',props.expID_typeOfExperiment)
    return (
    <div className={classes.root}> 
        { props.parent !== "BasicGraphicalSummaryDnaFilter" ?
            <div>
                <Button
                    variant="contained"
                    style={{backgroundColor: buttonColor, color: buttonTextColor}} 
                    className={classes.wideButton}
                    onClick={() => handleClick("All target genes")}
                    >
                        Distribution of {props.name} RNA-parts across their source gene body
                </Button>
                {/* Profile of RNA-parts of contacts */}
                <Divider className={classes.divider} />
            </div>
            :    
            // <Grid container spacing={0} direction="row">
            //     <Grid item xs={6}>
                <div>
                    <Button
                        variant="contained"
                        style={{backgroundColor: buttonColor, color: buttonTextColor}} 
                        className={classes.wideButton}
                        onClick={() => handleClick("All target genes")}
                        >
                            Distribution of {props.name} RNA-parts across their source gene body
                    </Button>
                    {/* Profile of RNA-parts of contacts */}
                {/* </Grid>
                <Grid item xs={6}> */}
                    <Button
                        variant="contained"
                        style={{backgroundColor: buttonColor, color: buttonTextColor, marginTop: 5}} 
                        className={classes.wideButton}
                        onClick={() => handleClick("All contacts")}
                        >
                        All contacts
                    </Button>
                    <Divider className={classes.divider} />
                {/* </Grid>
            </Grid> */}
            </div>
        }
        <WarningMessage open={open} severity={"warning"} handleClose={handleClose} message={message} />
    </div>
  );
}


const mapStateToProps = (state) => {
  return {      
    selectedTrackNames: state[module].selectedTrackNames,
  }
}



export default connect(mapStateToProps, {})(RnaListInfoButtonsBasic);