import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
// import FilledInput from '@material-ui/core/FilledInput';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';

import Checkbox from '@material-ui/core/Checkbox';
// import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
// import Toolbar from '@material-ui/core/Toolbar';

import RnaListButtons from '../components/RnaListButtons';
import WarningMessage from '../components/MessageSnackbar';
import ColumnSelector from './ColumnSelector';
import RnaListPagination from '../components/RnaListPagination';
import SimpleDialogDemo from '../components/OpenSimpleDialog';


import { submitTableRnaList, SendListGeneType, SendListTrackName } from '../actions/generalActions';

const module = "table";
const moduleFilter = "filter";

const headCells = [
    {id:'name', numeric: false, disablePadding: false, label: 'Name' },
    {id:'gene_type', numeric: false, disablePadding: true, label: 'Type'},
    {id:'track_name',numeric: false, disablePadding: true, label: 'Track'},
    {id:'experiment_id', numeric: false, disablePadding: true, label: 'ID'},
    {id:'sum_observed_value', numeric: false, disablePadding: true, label: 'N-obs'}, 
    {id:'cpkm_observed', numeric: false, disablePadding: true, label: 'CPKM(N-obs)'}, //sum_fdr
    // {id:'sum_expected_value', numeric: false, disablePadding: true, label: 'N-exp'}, 
    {id:'sum_value', numeric: false, disablePadding: true, label: 'N-norm'}, 
    {id:'cpkm', numeric: false, disablePadding: true, label: 'CPKM(N-norm)'}, //sum_fdr
    {id:'locus', numeric: false, disablePadding: true, label: 'Gene locus'},
    {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
    {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
    {id:'organism', numeric: false, disablePadding: true, label: 'Organism'},
    {id:'build', numeric: false, disablePadding: true, label: 'Genome'},
    {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},
    // {id:'chr_ID', numeric: false, disablePadding: true, label: 'Chromosome' },
    // {id:'start', numeric: false, disablePadding: true, label: 'Start' },
    // {id:'end', numeric: false, disablePadding: true, label: 'End' },
    // {id:'track_description',numeric: false, disablePadding: true, label: 'Track description'},
    // {id:'track_description_for_tooltip',numeric: false, disablePadding: true, label: 'Track description for tooltip'},
    // {id:'description_for_plots',numeric: false, disablePadding: true, label: 'Track description for plots'},
];

const makeID = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

const EnhancedTableHead = (props) => {
    
    const createSortHandler = property => event => {
        props.onRequestSort(event, property);
    };

    const { classes, order, orderBy, columnsToDisplay} = props;

    // console.log('columnsToDisplay',columnsToDisplay);
    let column = null
    return (
        <TableHead size='small'>
            <TableRow size='small' className={classes.sizeSmall}>
                {columnsToDisplay.map((headCell) => {
                // {Object.keys(row).map((key, index) => {
                    if (headCell.label !== 'Track description' && headCell.label !== 'Track description for plots' && headCell.label !== 'Track description for tooltip') {
                        // console.log('!')
                        column=
                            <TableCell
                                // size='small'
                                key={headCell.id}
                                style={{whiteSpace: 'nowrap', backgroundColor: '#009688', color: 'white', border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5'}}
                                // align= {headCell.label === 'Name' ? 'left' : 'center'}
                                align='center'
                                className={headCell.label === 'Name' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                // className={classes.sizeSmall}
                                sortDirection={orderBy === headCell.id ? order : false} //false
                            >
                                <TableSortLabel
                                    // active={true}
                                    // hideSortIcon={true}
                                    active={orderBy === headCell.id}
                                    direction={order}
                                    onClick={createSortHandler(headCell.id)}
                                    // label={<span style={{ fontSize: 15, whiteSpace: 'nowrap'}}>{headCell.label}</span>}
                                >
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                    {headCell.label}
                                </TableSortLabel>
                            </TableCell>
                    } else {
                        column = null
                    } 
                return (
                    column
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['desc','asc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles(theme => ({
    // root: {
    //     width: '100%',
    //     marginTop: theme.spacing(3),
    // },
    root: {
        // ...theme.mixins.gutters(),
        padding: 0,
        // paddingTop: theme.spacing(),
        // paddingBottom: theme.spacing(),
        textAlign: 'center',
        flexGrow: 1,
        width: '100%',
    },
    paper: {
        width: '100%',
        // marginBottom: theme.spacing(2),
    },
    loading: {
        marginTop: 50,
        textAlign: 'center',
    },
    sizeSmall: {
        padding: '0px 5px 0px 5px'
    },
    sizeSmallChackBox: {
        padding: '0px 0px 0px 0px',
    },
    stickyHeader: {
        'border-collapse': 'inherit',
    },
    tableWrapper: {
        overflowX: 'auto',
        margin: '0 auto',
        maxWidth: '100%',
        '& table:first-child': {
            '& tr': {
                '& td:nth-child(1), td:nth-child(1)': {
                    backgroundColor: 'white',
                    position: 'sticky',
                    left: 0,
                    zIndex: 99
                },
                '& th:nth-child(1)': {
                    zIndex: 999
                },
                // '& td:nth-child(2), td:nth-child(2)': {
                //     backgroundColor: 'white',
                //     position: 'sticky',
                //     left: 0,
                //     zIndex: 99
                // },
                // '& th:nth-child(2)': {
                //     zIndex: 999
                // },
            }
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    textField: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: theme.spacing(),
        width: '85%',
        color: 'black !important',
        // alignItems: 'center',
        // textAlign: 'center !important',
    },
    locationLeft: {
        marginLeft: 20,
        textAlign: 'left',
    },
    locationBottom: {
        marginTop: theme.spacing(3),
        // marginRight: 50,
        textAlign: 'center !important',
    },
    container: {
        maxHeight: window.innerHeight*0.65,
    },
    popper: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: "auto",
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        height: '100%',
    },
    updateButton: {
        margin: '20px 5px 0px 0px',
        float: 'right',
    },
    searchIcon: {
        color: 'black',
    },
    input: {
        color: 'black',
    }
}));

const TooltipCell = React.forwardRef((props, ref) => 
        <TableCell {...props} ref={ref}>
            {props.children}
        </TableCell>
);

const CustomTableWrapper = (props) => {

    const classes = useStyles();
    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [warningMessageOpen, setWarningMessageOpen] = useState(false);

    const [data_table_value, setData_table_value] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('cpkm');
    const [rnaListDataFirst, setRnaListDataFirst] = useState({})

    const [rnaListID, setRnaListID] = useState('')
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [rowsCount, setRowsCount] = useState(0);


    const [rowsToDisplay, setRowsToDisplay] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchWasPerformed, setSearchWasPerformed] = useState(false)
    const [applyWasPerformed, setApplyWasPerformed] = useState(false)

    const [columnsToDisplay, setColumnsToDisplay] = useState([]);

    const [selected, setSelected] = useState([]);
    // const [selectedNormalization, setSelectedNormalization] = useState('')

    const [all_track_description, setAll_track_description] = useState({})

    const { submitTableRnaList, SendListGeneType, SendListTrackName, RnaListData, filter_parameters } = props;

    const rnaListIDmodifier = () => {
        if (searchWasPerformed === false && applyWasPerformed === false) {
            return rnaListID
        } else {
            if (searchWasPerformed === false) {
                return rnaListID + "_search"
            } else {
                return rnaListID + "_SEARCH"
            }
        }
    }
    
    useEffect(() => {
        const dataRnaList = JSON.parse(localStorage.getItem("data_rna_list"));
        // console.log('dataRnaList',dataRnaList)
        let ID = makeID(15)
        setRnaListID(ID)
        // setRnaListDataFirst(dataRnaList)        
        if (Object.keys(dataRnaList).includes('Intersection_genes_req')) {
            setRnaListDataFirst({
                'selectedTracks': {'tracks': dataRnaList.tracks, 'Intersection_genes_req': dataRnaList.Intersection_genes_req}, 
                'typeOfContacts': 'DNA_part', 
                'selectedTrackNames': dataRnaList.selectedGeneTypes, 
                'selectedNormalization': dataRnaList.selectedNormalization
            })
            submitTableRnaList(
                {'tracks': dataRnaList.tracks, 'Intersection_genes_req': dataRnaList.Intersection_genes_req},
                'DNA_part', 
                dataRnaList.selectedGeneTypes, 
                dataRnaList.selectedNormalization,
                {
                    'rnaListID' : ID,
                    'page' :page, 
                    'rowsPerPage': rowsPerPage, 
                    'order': order,
                    'orderBy': orderBy,
                    'searchTerm': "",

                    'trackName': "",
                    'geneType': "",
                    'norm_value': "",
                    'minLength': "",
                    'maxLength': "",
                    'cpkm': "",
                    'locusFilter': [],
                }
            )
            // console.log('___=')
        } else { 
            setRnaListDataFirst(dataRnaList)
            submitTableRnaList(
                dataRnaList.selectedTracks, 
                dataRnaList.typeOfContacts, 
                dataRnaList.selectedTrackNames, 
                dataRnaList.selectedNormalization, 
                {
                    'rnaListID' : ID,
                    'page' :page, 
                    'rowsPerPage': rowsPerPage, 
                    'order': order,
                    'orderBy': orderBy,
                    'searchTerm': "",

                    'trackName': "",
                    'geneType': "",
                    'norm_value': "",
                    'minLength': "",
                    'maxLength': "",
                    'cpkm': "",
                    'locusFilter': [],
                }
            )
        }
    }, [])

    let columnsMap = {};
    headCells.map(cell => {
        columnsMap[cell.label] = cell.id;
    })

    useEffect (() => {
        // console.log('RnaListData******************',RnaListData)
        let data_table_value_2 = []
        if (typeof(RnaListData['1']) === 'string') {
            setMessage(RnaListData['1'])
        } else {
            if (RnaListData['1']) {
                // setData_table_value(RnaListData['1'])
                data_table_value_2 = RnaListData['1']
                data_table_value_2.map(value => {
                    value['locus'] = `${value['chr_ID']}:${value['start']}-${value['end']}`
                })
                setData_table_value(data_table_value_2)
                setRowsCount(RnaListData['2'])
            }
            if (RnaListData['all_track_description']) {
                setAll_track_description(RnaListData['all_track_description'])
                // setSelectedNormalization(RnaListData['all_track_description']['selectedNormalization'])
                // setRowsCount(RnaListData['all_track_description']['rowsCount'])
                SendListGeneType(RnaListData['all_track_description']['gene_type'])
                SendListTrackName(RnaListData['all_track_description']['track_name'])
            }
        }

        let selectedColumnsInit = [
            "Name",
            "Track",
            "Type",
            "N-norm",
            "CPKM(N-norm)",
            "Organism",
            "Gene locus",
            "Str.",
            "ID",
            'Track description',
            'Track description for tooltip',
            'Track description for plots',
        ];
        if (columnsToDisplay.length === 0) {
            setColumnsToDisplay(headCells.filter(cell => selectedColumnsInit.indexOf(cell.label) !== -1));
        } 
        let data = filterDataForColumns(data_table_value_2)
        // console.log('---------------------_!!!!!!!!!!!!!!!_OLKMNJML< ',columnsToDisplay)
        setRowsToDisplay(data.slice(0, rowsPerPage))
    }, [RnaListData])

    useEffect(() => {
        if (Object.keys(RnaListData).length > 0) {
            // console.log('@@@@@@@@@@@@@@@@@@@@@@@@',page,rnaListID)
            let breakApply = false
            let locusFilter = []
            if (filter_parameters.locusFilter.length !== 0) {
                locusFilter = LocusFilterParser(filter_parameters.locusFilter)
                breakApply = locusFilter.length === 0 ? true : false
            }
            submitTableRnaList(
                rnaListDataFirst.selectedTracks, 
                rnaListDataFirst.typeOfContacts, 
                rnaListDataFirst.selectedTrackNames, 
                rnaListDataFirst.selectedNormalization, 
                {
                    'rnaListID' : rnaListIDmodifier() + "_result",
                    'page': page, 
                    'rowsPerPage': rowsPerPage, 
                    'order': '',
                    'orderBy': '',
                    'searchTerm': "",

                    'trackName': [],
                    'geneType': [],
                    'norm_value': "",
                    'minLength': "",
                    'maxLength': "",
                    'cpkm': "",
                    'locusFilter': locusFilter,

                    'if_chach_empty': {
                        'page': page,
                        'rowsPerPage': rowsPerPage, 
                        'order': '',
                        'orderBy': '',
                        'searchTerm': searchTerm,
                        'trackName': filter_parameters['trackName'].map((x) => {let name = x.value; return(name)}),
                        'geneType': filter_parameters['geneType'].map((x) => {let type = x.value; return(type)}),
                        'norm_value': filter_parameters['value'],
                        'minLength': filter_parameters['minLength'],
                        'maxLength': filter_parameters['maxLength'],
                        'cpkm': filter_parameters['cpkm'],
                        'locusFilter': locusFilter,
                        }
                }
            )
        }
    }, [page, rowsPerPage])


    useEffect(() => {
        setSelected([])
        // console.log('orderBy',orderBy)
        // console.log('order',order)
        // console.log('rnaListDataFirst.selectedTracks',rnaListDataFirst.selectedTracks)
        // console.log('rnaListDataFirst.typeOfContacts',rnaListDataFirst.typeOfContacts)
        // console.log('rnaListDataFirst.selectedTrackNames',rnaListDataFirst.selectedTrackNames)
        // console.log('rnaListDataFirst.selectedNormalization',rnaListDataFirst.selectedNormalization)
        setPage(0)
        let breakApply = false
        let locusFilter = []
        if (filter_parameters.locusFilter.length !== 0) {
            locusFilter = LocusFilterParser(filter_parameters.locusFilter)
            breakApply = locusFilter.length === 0 ? true : false
        }
        submitTableRnaList(
            rnaListDataFirst.selectedTracks, 
            rnaListDataFirst.typeOfContacts, 
            rnaListDataFirst.selectedTrackNames, 
            rnaListDataFirst.selectedNormalization, 
            {
                'rnaListID' : rnaListIDmodifier() + "_result",
                'page' :0, 
                'rowsPerPage': rowsPerPage, 
                'order': order,
                'orderBy': orderBy,
                'searchTerm': '',

                'trackName': [],
                'geneType': [],
                'norm_value': "",
                'minLength': "",
                'maxLength': "",
                'cpkm': "",
                'locusFilter': locusFilter,

                'if_chach_empty': {
                                'page' : 0, 
                                'rowsPerPage': rowsPerPage, 
                                'order': order,
                                'orderBy': orderBy,
                                'searchTerm': searchTerm,
                                'trackName': filter_parameters['trackName'].map((x) => {let name = x.value; return(name)}),
                                'geneType': filter_parameters['geneType'].map((x) => {let type = x.value; return(type)}),
                                'norm_value': filter_parameters['value'],
                                'minLength': filter_parameters['minLength'],
                                'maxLength': filter_parameters['maxLength'],
                                'cpkm': filter_parameters['cpkm'],
                                'locusFilter': locusFilter,
                                }
            }
        )
    }, [order,orderBy])



    const handleColumnsChange = (cols) => {
        let newColumns = headCells.filter(cell => cols.indexOf(cell.id) !== -1);
        setColumnsToDisplay(newColumns);
        // console.log('newColumns',newColumns)
        let data = filterDataForColumns(data_table_value, newColumns);
        // console.log('data',data)
        setRowsToDisplay(data.slice(0, rowsPerPage));
    }


    const handleRequestSort = (event, property) => {
        // console.log('property',property)
        const isAsc =  orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
   

    const handleChangePage = (newPage) => {
        // console.log('newPage',newPage)
        // console.log('page',page)
        setPage(newPage);
    }

    const handleChangeRowsPerPage = value => {
        setRowsPerPage(parseInt(value, 10))
        setPage(0);
    };

    const filterDataForColumns = (data, cols) => {
        let colIds = columnsToDisplay.map(col => col.id);
        if (cols !== undefined) {
            colIds = cols.map(col => col.id);
        }
        let newData = data.map((row, i) => {
            let newRow = {};
            colIds.map(id => newRow[id] = row[id]);
            return newRow;
        })
        return newData;
    }

    const performSearch = () => {
        setSelected([]);
        if (searchTerm === "") {
            setSearchWasPerformed(false)
        } else {
            setSearchWasPerformed(true)
        }
        let ID = rnaListID
        // console.log('applyWasPerformed',applyWasPerformed)
        if (applyWasPerformed === true) {
            ID = ID + "_search"
        }
        let breakApply = false
        let locusFilter = []
        if (filter_parameters.locusFilter.length !== 0) {
            locusFilter = LocusFilterParser(filter_parameters.locusFilter)
            breakApply = locusFilter.length === 0 ? true : false
        }
        submitTableRnaList(
            rnaListDataFirst.selectedTracks, 
            rnaListDataFirst.typeOfContacts, 
            rnaListDataFirst.selectedTrackNames, 
            rnaListDataFirst.selectedNormalization, 
            {
                'rnaListID' : ID + "_result", //rnaListID.split("_search")[0],
                'page' : 0, 
                'rowsPerPage': rowsPerPage, 
                'order': "", //order,
                'orderBy': "", //orderBy,
                'searchTerm': searchTerm,
                
                'trackName': filter_parameters['trackName'].map((x) => {let name = x.value; return(name)}),
                'geneType': filter_parameters['geneType'].map((x) => {let type = x.value; return(type)}),
                'norm_value': filter_parameters['value'],
                'minLength': filter_parameters['minLength'],
                'maxLength': filter_parameters['maxLength'],
                'cpkm': filter_parameters['cpkm'],
                'locusFilter': locusFilter,
                // 'trackName': [],
                // 'geneType': [],
                // 'norm_value': "",
                // 'minLength': "",
                // 'maxLength': "",
                // 'cpkm': "",
                // 'locusFilter': locusFilter,

                'if_chach_empty': {
                    'page' : 0, 
                    'rowsPerPage': rowsPerPage, 
                    'order': "", //order,
                    'orderBy': "", //orderBy,
                    'searchTerm': searchTerm,
                    'trackName': filter_parameters['trackName'].map((x) => {let name = x.value; return(name)}),
                    'geneType': filter_parameters['geneType'].map((x) => {let type = x.value; return(type)}),
                    'norm_value': filter_parameters['value'],
                    'minLength': filter_parameters['minLength'],
                    'maxLength': filter_parameters['maxLength'],
                    'cpkm': filter_parameters['cpkm'],
                    'locusFilter': locusFilter,
                    }
            }
        )
        // let data = filterDataForColumns(newData);
        setPage(0);
        // setRowsToDisplay(data.slice(0, rowsPerPage));
       
    };




    

    useEffect(() => {
        setSelected([])
        // console.log('filter_parameters',filter_parameters)
        let breakApply = false
        let locusFilter = []
        if (filter_parameters.locusFilter.length !== 0) {
            locusFilter = LocusFilterParser(filter_parameters.locusFilter)
            breakApply = locusFilter.length === 0 ? true : false
        }
        // console.log('locusFilter',locusFilter)
        // console.log('breakApply',breakApply)
        if (breakApply === false) {
            if (searchTerm === "") {
                setSearchWasPerformed(false)
            } else {
                setSearchWasPerformed(true)
            }
            if (filter_parameters['trackName'].length === 0 && 
                filter_parameters['geneType'].length === 0 && 
                filter_parameters['value'] === "" && 
                filter_parameters['minLength'] === "" && 
                filter_parameters['maxLength'] === "" && 
                filter_parameters['cpkm'] === "" && 
                filter_parameters['locusFilter'] === "") {
                    setApplyWasPerformed(false)
                    // console.log('setApplyWasPerformed = false')
            } else {
                // console.log('setApplyWasPerformed = true')
                setApplyWasPerformed(true)
            }
            submitTableRnaList(
                rnaListDataFirst.selectedTracks, 
                rnaListDataFirst.typeOfContacts, 
                rnaListDataFirst.selectedTrackNames, 
                rnaListDataFirst.selectedNormalization, 
                {
                    'rnaListID' :rnaListID + "_result", //rnaListID.split("_search")[0],
                    'page' : 0, 
                    'rowsPerPage': rowsPerPage, 
                    'order': '',
                    'orderBy': '',
                    'searchTerm': searchTerm,

                    'trackName': filter_parameters['trackName'].map((x) => {let name = x.value; return(name)}),
                    'geneType': filter_parameters['geneType'].map((x) => {let type = x.value; return(type)}),
                    'norm_value': filter_parameters['value'],
                    'minLength': filter_parameters['minLength'],
                    'maxLength': filter_parameters['maxLength'],
                    'cpkm': filter_parameters['cpkm'],
                    'locusFilter': locusFilter,

                    'if_chach_empty': {
                        'page' : 0, 
                        'rowsPerPage': rowsPerPage, 
                        'order': "", //order,
                        'orderBy': "", //orderBy,
                        'searchTerm': searchTerm,
                        'trackName': filter_parameters['trackName'].map((x) => {let name = x.value; return(name)}),
                        'geneType': filter_parameters['geneType'].map((x) => {let type = x.value; return(type)}),
                        'norm_value': filter_parameters['value'],
                        'minLength': filter_parameters['minLength'],
                        'maxLength': filter_parameters['maxLength'],
                        'cpkm': filter_parameters['cpkm'],
                        'locusFilter': locusFilter,
                        }
                }
            )
        }
        setPage(0);
        // setRowsToDisplay(data.slice(0, rowsPerPage));
    }, [filter_parameters])

    
    
    const LocusFilterParser = (valueLocus) => {
        let loci_array = []
        for (const one of valueLocus.replace(/;$/g, '').split(';').map(function(item) {return item.trim();})) {
            let chr = 0
            let start = 0
            let end = 0
            var locus_array = one.replace(/,+|\s+/g, '').split(/r|:|-/i).map(function(item) {return item.trim();})
            // console.log('locus_array',locus_array)
            if (locus_array.length === 2 && locus_array[0].toLowerCase() === 'ch' && (isNaN(Number(locus_array[1])) !== true || locus_array[1].toLowerCase() === 'x' || locus_array[1].toLowerCase() === 'y')) {
                {locus_array[1].toLowerCase() === 'x' ? chr = 33 : locus_array[1].toLowerCase() === 'y' ? chr = 44 : chr = Number(locus_array[1])}
                end = 1000000000000000
                loci_array.push([chr,start,end])
                // props.locusWarningChangeFalse()
            } else if (locus_array.length === 4 && locus_array[2] !== '' && locus_array[3] !== '' && isNaN(Number(locus_array[2])) !== true && isNaN(Number(locus_array[3])) !== true) {
                if (locus_array[0].toLowerCase() === 'ch' && Number(locus_array[2].replace(/^0*/,'')) < Number(locus_array[3].replace(/^0*/,''))) {
                    {locus_array[1].toLowerCase() === 'x' ? chr = 33 : locus_array[1].toLowerCase() === 'y' ? chr = 44 : chr = Number(locus_array[1])}
                    start = Number(locus_array[2])
                    end = Number(locus_array[3])
                    loci_array.push([chr,start,end])
                } else {
                    setWarningMessage('Locus coordinates were entered in the wrong format')
                    setWarningMessageOpen(true)
                    loci_array = []
                    break;
                }
            } else {
                setWarningMessage('Locus coordinates were entered in the wrong format')
                setWarningMessageOpen(true)
                loci_array = []
                break;
            }
        }
        return loci_array
    }







    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setWarningMessageOpen(false)
        setOpen(false)
    }

    const handleClick = (event, name, experiment_id, track_name) => { //, track_description) => {
        // console.log('selected.length',selected.length)
        // console.log('all_track_description!!!!!!!!!!!!!!!!!!!!!!', all_track_description)
        let newSelected = selected.slice()
        // let name_exp = name+ '___' +experiment_id + '&' + track_name+ '^' + track_description
        let name_exp = name+ '___' +experiment_id + '&' + track_name + '^' + all_track_description[track_name]['track_description']
        // console.log('name_exp',name_exp)
        if (selected.length < 20) {
            if (newSelected.indexOf(name_exp) === -1) {
                newSelected.push(name_exp)
                setSelected(newSelected);
            } else {
                newSelected.splice(selected.indexOf(name_exp), 1)
                setSelected(newSelected)
            }
        } else {
            if (newSelected.indexOf(name_exp) !== -1) {
                newSelected.splice(selected.indexOf(name_exp), 1)
                setSelected(newSelected)
            } else {
                setOpen(true)
            }
        }
    };

    const isSelected = name => selected.indexOf(name) !== -1
    // console.log('props.trackListCheckbox..',props.trackListCheckbox)

    const onClick = (id) => {
        const data = {
          selectedExperiment: [id]
        }
        localStorage.setItem("metadata",  JSON.stringify(data));
        window.open("/metadata", "_blank");
      }

    let column = null;
    let table = null;
    if (message === '') {
        table = 
            <div>
                <Paper className={classes.paper} wrap="nowrap">
                <div className={classes.tableWrapper}> 
                    <ColumnSelector 
                        columns={headCells.map(cell => cell.label)} 
                        columnsMap={columnsMap} 
                        setColumns={handleColumnsChange}
                    />
                    <TableContainer className={classes.container}>
                        <Table
                            className={classes.stickyHeader}
                            aria-labelledby="tableTitle"
                            size={'small'}
                            stickyHeader
                            aria-label="enhanced table"
                            style={{ width: "auto", tableLayout: "auto",}}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                columnsToDisplay={columnsToDisplay}
                            />
                            <TableBody>
                            {rowsToDisplay.map((row, indexR) => {
                                const isSelectedID = isSelected(row.name+'___'+row.experiment_id + '&'+ row.track_name + '^' + all_track_description[row.track_name]['track_description']); //row.track_description
                                // console.log('isSelectedID',isSelectedID)
                                // console.log('rowsToDisplay',rowsToDisplay)
                                return (
                                    <TableRow
                                        hover 
                                        tabIndex={-1} 
                                        className={classes.sizeSmall}
                                        // padding="none"
                                        key={indexR}
                                        // onClick={event => handleClick(event, row.name, row.experiment_id, row.track_name)} //, row.track_description
                                        // role="checkbox"
                                        // aria-checked={isSelectedID}
                                        // selected={isSelectedID} 
                                    >
                                        
                                        {Object.keys(row).map((key, index) => {
                                            // console.log('key',key)
                                            if (key === 'name') {
                                                // console.log('!')
                                                column=
                                                    <TableCell 
                                                        key={index}
                                                        // className={key === 'name' ? classes.sizeSmallChackBox : classes.sizeSmall} 
                                                        className={classes.sizeSmall} 
                                                        size='small' 
                                                        align="left" 
                                                        style={{fontSize: 15, 
                                                            whiteSpace: 'nowrap', 
                                                            border: '1px solid #d3d4d5',
                                                            borderTop: '1px solid #d3d4d5',
                                                            borderLeft: '1px solid #d3d4d5',
                                                        }}>
                                                            <Grid container spacing={0} direction="row" style={{flexWrap: 'nowrap'}}>
                                                                <Grid item xs={2}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox 
                                                                            style={{padding: 0, marginLeft: 10}}
                                                                            checked={isSelectedID} 
                                                                            onClick={event => handleClick(event, row.name, row.experiment_id, row.track_name)}
                                                                            color="secondary"
                                                                            // onChange={}
                                                                        />
                                                                    }
                                                                    // label={<span style={{ fontSize: 15, whiteSpace: 'nowrap'}}>{row.name}</span>}
                                                                />
                                                                </Grid>
                                                                <Grid item xs={10} style={{marginRight: 1}}>
                                                                    <div >
                                                                        <SimpleDialogDemo 
                                                                            RnaSelected={[row.name+ '___' +row.experiment_id + '&' + row.track_name + '^' + all_track_description[row.track_name]['track_description']]} 
                                                                            // selectedNormalization={selectedNormalization} 
                                                                            title={row[key]} 
                                                                            selectedTrackNames={[row.track_name]}
                                                                            experiment_id={row.experiment_id} 
                                                                            // locus={row.locus} 
                                                                            gene_name={row.name}
                                                                            // gene_type={row.gene_type}
                                                                            track_name={row.track_name}
                                                                            // experiment_id={row.experiment_id}
                                                                            // sum_observed_value={row.sum_observed_value}
                                                                            // sum_expected_value={row.sum_expected_value}
                                                                            // sum_value={row.sum_value}
                                                                            // cpkm={row.cpkm}
                                                                            // locus={row.locus}
                                                                            // strand={row.strand}
                                                                            // len={row.len}
                                                                            organism={row.organism}
                                                                            // build={row.build}
                                                                            // from={row.from}
                                                                            />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                    </TableCell>
                                            } else if (key === "experiment_id") {
                                                let description = all_track_description[row.track_name]['description_for_plots']
                                                let res = description.split(", ");
                                                column = 
                                                    <Tooltip
                                                        placement={"right-start"}
                                                        title={
                                                            <React.Fragment>
                                                                <div className={classes.tooltip}>
                                                                    {/* <Typography color="inherit" variant="h5" component="h1"><u>{`Experiment`}</u></Typography> */}
                                                                    <Typography>
                                                                        ID: <b>{res[0]}</b><br/>organism: <b>{res[1]}</b><br/>cell line: <b>{res[2]}</b><br/>exp.protocol: <b>{res[3]}</b><br/>extra: <b>{res[4]}</b>
                                                                    </Typography>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <TooltipCell
                                                            key={index}
                                                            // padding="none" 
                                                            // size='small' 
                                                            align="center" 
                                                            // className={key === 'name' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                whiteSpace: 'nowrap', 
                                                                border: '1px solid #d3d4d5',
                                                                borderTop: '1px solid #d3d4d5',
                                                                borderLeft: '1px solid #d3d4d5',
                                                            }} 
                                                        >
                                                            {/* <Tooltip title="Go to Metadata page"> */}
                                                                <Link component="button" onClick={() => onClick(row[key])}>{row[key]}</Link> 
                                                            {/* </Tooltip> */}
                                                        </TooltipCell>
                                                    </Tooltip>
                                            } else if (key === "track_name") {
                                                let intersection = ''
                                                let description_track = all_track_description[row.track_name]['track_description_for_tooltip']
                                                let description_track_name = description_track.split("Intersection")[0]
                                                if (description_track.split("Intersection").length > 1) {
                                                    intersection = description_track.split("Intersection")[1]
                                                }
                                                    // if (description_track_name !== 'Genome wide') {
                                                        // console.log('description_track_name: ',description_track_name)
                                                let RNA_filter = description_track_name.split(" | ")[0].split("RNA filter: ")[1]
                                                let DNA_filter = description_track_name.split(" | ")[1].split("DNA filter: ")[1]
                                                let DNA_filter_inser = []
                                                if (DNA_filter.indexOf('Gene body') !== -1) {
                                                    DNA_filter_inser.push(<u>Gene body</u>)
                                                    DNA_filter_inser.push(DNA_filter.split('Gene body')[1].split('$')[0] + ' ')
                                                }
                                                if (DNA_filter.indexOf('Upstream:') !== -1) {
                                                    DNA_filter_inser.push(<u>Upstream</u>)
                                                    DNA_filter_inser.push(DNA_filter.split('Upstream')[1].split('$')[0] + ' ')
                                                }
                                                if (DNA_filter.indexOf('Downstream:') !== -1) {
                                                    DNA_filter_inser.push(<u>Downstream</u>)
                                                    DNA_filter_inser.push(DNA_filter.split('Downstream')[1].split('$')[0] + ' ')
                                                }
                                                if (DNA_filter.indexOf('Upstream50kb:') !== -1) {
                                                    DNA_filter_inser.push(<u>Upstream50kb</u>)
                                                    DNA_filter_inser.push(DNA_filter.split('Upstream50kb')[1].split('$')[0] + ' ')
                                                }
                                                if (DNA_filter.indexOf('Downstream50kb:') !== -1) {
                                                    DNA_filter_inser.push(<u>Downstream50kb</u>)
                                                    DNA_filter_inser.push(DNA_filter.split('Downstream50kb')[1].split('$')[0] + ' ')
                                                }
                                                if (DNA_filter.indexOf('GeneDeserts:') !== -1) {
                                                    DNA_filter_inser.push(<u>GeneDeserts</u>)
                                                    DNA_filter_inser.push(DNA_filter.split('GeneDeserts')[1].split('$')[0] + ' ')
                                                }
                                                if (DNA_filter.indexOf('DNA locus:') !== -1) {
                                                    DNA_filter_inser.push(<u>DNA locus</u>)
                                                    DNA_filter_inser.push(DNA_filter.split('DNA locus')[1].split('$')[0] + ' ')
                                                }
                                                if (DNA_filter_inser.length === 0) {
                                                    DNA_filter_inser.push('NaN')
                                                } 
                                                column = 
                                                    <Tooltip
                                                        placement={"right-start"}
                                                        title={
                                                            <React.Fragment>
                                                                <div className={classes.tooltip}>
                                                                    <Typography color="inherit" variant="h5" component="h1"><u>{`Track description:`}</u></Typography>
                                                                    {intersection.length > 0 ?
                                                                        <Typography>
                                                                            RNA filter: <b>{RNA_filter}</b><br/>DNA filter: <b>{DNA_filter_inser}</b><br/>Intersection<b>{intersection.split("between tracks:")[0]}</b>between tracks:<b>{intersection.split("between tracks:")[1]}</b>
                                                                        </Typography> //<br/>Normalization: <b>{selectedNormalization}</b>
                                                                        :
                                                                        <Typography>
                                                                            RNA filter: <b>{RNA_filter}</b><br/>DNA filter: <b>{DNA_filter_inser}</b>
                                                                        </Typography> //<br/>Normalization: <b>{selectedNormalization}</b>
                                                                    }
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <TooltipCell
                                                            key={index} 
                                                            align="center" 
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                whiteSpace: 'nowrap', 
                                                                border: '1px solid #d3d4d5',
                                                                borderTop: '1px solid #d3d4d5',
                                                                borderLeft: '1px solid #d3d4d5',
                                                            }} 
                                                        >
                                                            {row[key]}
                                                        </TooltipCell>
                                                    </Tooltip>
                                            } else  if (key === "organism") {
                                                column=
                                                        <TableCell 
                                                            key={index}
                                                            // padding="none" 
                                                            // size='small' 
                                                            align="center" 
                                                            // className={key === 'name' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                    whiteSpace: 'nowrap', 
                                                                    border: '1px solid #d3d4d5',
                                                                    borderTop: '1px solid #d3d4d5',
                                                                    borderLeft: '1px solid #d3d4d5',
                                                                }} 
                                                            // key={key}
                                                        >
                                                                <i>{row[key]}</i>
                                                        </TableCell>
                                            } else {
                                                column=
                                                        <TableCell 
                                                            key={index}
                                                            // padding="none" 
                                                            // size='small' 
                                                            align="center" 
                                                            // className={key === 'name' ? classes.sizeSmallChackBox : classes.sizeSmall}
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                    whiteSpace: 'nowrap', 
                                                                    border: '1px solid #d3d4d5',
                                                                    borderTop: '1px solid #d3d4d5',
                                                                    borderLeft: '1px solid #d3d4d5',
                                                                }} 
                                                            // key={key}
                                                        >
                                                                {row[key]}
                                                        </TableCell>
                                            }
                                            return (
                                                column
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            <RnaListPagination
                rowsPerPageOptions={[25, 50, 100]}
                count={rowsCount} //rows.length
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            </Paper>
        </div>
    }

    return (
        <div className={classes.root}>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={2}>
                    <Typography variant="h5" component="h1" className={classes.locationBottom}>
                        Selected: {selected.length}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl className={classes.textField}>
                        <InputLabel style={{color: 'black !important'}}>Search by "Name"</InputLabel>
                        <Input
                            type={"text"}
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                            onKeyPress={(ev) => {
                                // console.log('ev.key',ev.key)
                                if (ev.key === 'Enter') {
                                    performSearch()
                                  ev.preventDefault();
                                }
                              }}
                            className={classes.input}
                            classes={{
                                underline: classes.input,
                            }}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    className={classes.searchIcon}
                                    onClick={performSearch}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <RnaListButtons RnaSelected={selected} selectedNormalization={props.trackListCheckbox}/>
                </Grid>
            </Grid>
            <WarningMessage open={props.snackRnaList} severity={"error"} message={props.snackTextRnaList} />
            <WarningMessage open={open} handleClose={handleClose} severity={"warning"} message={'You have reached the threshold of 20 selected RNAs'} />
            <WarningMessage open={warningMessageOpen} handleClose={handleClose} severity={"warning"} message={warningMessage} />
            {!props.loadingRnaList ?
            <div className={classes.paper}>
                <Typography color="error" variant="h2">
                    {message}
                </Typography>
                {table}
            </div>
            : 
            <div className={classes.loading}>
                <LinearProgress color={"primary"}/>
                <Typography variant="h5">
                    Please, wait a while, it may take from a few seconds to a couple of minutes
                </Typography>
            </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        snackRnaList: state[module].snackRnaList,
        snackTextRnaList: state[module].snackTextRnaList,
        loadingRnaList: state[module].loadingRnaList,
        RnaListData: state[module].RnaListData,

        trackListCheckbox: state[moduleFilter].trackListCheckbox,
    }
}

export default connect(mapStateToProps, { submitTableRnaList, SendListGeneType, SendListTrackName })(CustomTableWrapper);