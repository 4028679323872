import React, {useState, useEffect} from 'react';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';

// import EnhancedTABLEpart from '../../components/TableGraphicalSummary';
// import { Track_description, normalizationName } from './Heatmap_data_layout';

// import Grid from '@material-ui/core/Grid';

// const module = "plots";

export const getMax = (arr) => {
    let len = arr.length;
    let max = -Infinity;

    while (len--) {
        max = arr[len] > max ? arr[len] : max;
    }
    return max;
}

export const getMin = (arr) => {
    let len = arr.length;
    let min = Infinity;

    while (len--) {
        min = arr[len] < min ? arr[len] : min;
    }
    return min;
}

const DensityAllTargetGenes = (props) => {

    const { allTargetGenesTableData } = props;

    const [dataForPlot, setDataForPlot] = useState([])

    useEffect(() => {
        if (Object.keys(allTargetGenesTableData).length !== 0) {
            let data = [
                {
                  x: allTargetGenesTableData['densityValues'][props.genomeInterval],
                  type: 'histogram',
                  xbins: {
                    end: getMax(allTargetGenesTableData['densityValues'][props.genomeInterval]),
                    size: 0.1, 
                    start: getMin(allTargetGenesTableData['densityValues'][props.genomeInterval]),
                  },
                  marker: {
                      color: '#1f77b4',
                   },
                }
              ];
            setDataForPlot(data)
        }
                
    }, [allTargetGenesTableData])

    return (
        <div id='myDiv'>
            <Plot 
                data={dataForPlot} 
                layout = {{
                    height: 300,
                    width: window.innerWidth/5.1,
                    // showlegend: false,
                    // showticklabels: false,
                    titlefont: { size: 20 },
                    title: `<b>${props.title}</b>`,
                    yaxis: {
                        title: "N-genes",
                        titlefont: { size: 20 },
                    },
                    xaxis: {
                        title: 'Log10(Density)',
                        titlefont: { size: 20 },
                    },
                    // legend: {traceorder: 'reversed'},
                    // xaxis: {
                    //     // ticks: '',
                    //     showticklabels: false
                    // },
                  }}
                config={{ //layout={layout}
                modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian','lasso2d','select2d'],
                // displayModeBar: false,
                // scrollZoom: true,
                displaylogo: false,
                doubleClickDelay: 1000,
                toImageButtonOptions: {format: 'svg', filename: 'Contacts distribution', scale: 1}
            }}/>
        </div>
    )
}

  
const mapStateToProps = (state) => {
    return {
        // genomeWideDataProfile: state[module].genomeWideDataProfile,
    }
}
    
  
export default connect(mapStateToProps, )(DensityAllTargetGenes);