import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemText from '@material-ui/core/ListItemText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
// import Decorator from '../components/HtmlTooltip';
// import Divider from '@material-ui/core/Divider';
// import DialogActions from '@material-ui/core/DialogActions';
// import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import PersonIcon from '@material-ui/icons/Person';
// import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import { blue } from '@material-ui/core/colors';
import LinearProgress from '@material-ui/core/LinearProgress';

import RNApartProfileBasic from '../components/plots/RNA_list_profile_basic';
// import TableMetadataBasic from '../components/plots/TableMetadataBasic';
import WarningMessage from './MessageSnackbar';
import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';
// import TableAllTargetGenes from '../components/TableAllTargetGenes';
// import RNAAutocomplete from '../components/RNAAutocomplete';
// import InputTwoFromDNA from '../components/InputTwoFromDNA';
// import InputThree from '../components/InputThree';
import RnaTableInfo from '../components/TableRnaListRnaBasic';
import DownloadPlotData from '../components/DownloadPlotData';

import { submitCheckedTracksForRNApartProfile, submitCheckedTracksForRNApartProfileTable, submitAllTargetGenesTable} from '../actions/plotsActions';
import { submitCheckedTracksRnaPart, clearGenomeBrowserRnaPartURL} from '../actions/genomeBrowserActions';
import { textChangeSearchGeneName, SendListGeneType, SendListAnnotationSource, SendListGeneName } from '../actions/generalActions';
import { textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA } from '../actions/generalActions';
import { handleHeader } from '../actions/generalActions';

const module = "plots"
const moduleGenomeBrowser = "genomeBrowser";
const moduleFilter = "filter";
const moduleTable = "table";
const moduleText = "text";


const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    paddingLeft: theme.spacing(),
  },
  dialog: {
    maxWidth: '100%',
    // width: '1000px'
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  loadingGB: {
    marginTop: 10,
    textAlign: 'center',
  },
  wideButton: {
    padding: theme.spacing(),
    width: '100%',
    height: '100%',
    // color: 'white',
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: theme.spacing(),
  },
  locationCenter: {
    margin: '0 auto',
  },
  autocomplete: {
    flexGrow: 1,
    paddingTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const SimpleDialogDemo = (props) => {

  const classes = useStyles();
  document.title = `RNA-parts profiles (${JSON.parse(localStorage.getItem("data_for_rna_parts")).gene_name})`;

  const [openMessage, setOpenMessage] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  // const [RNAdataTable, setRNAdataTable] = useState([])
  const [dataForRnaPartsDistribution, setDataForRnaPartsDistribution] = useState({})
  // console.log('dataForRnaPartsDistribution))))', dataForRnaPartsDistribution)

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data_for_rna_parts"));
    // console.log('data!!!!!!!!!!!!!!!',data)
    props.handleHeader(': distribution of RNA-parts across their source gene body')

    setDataForRnaPartsDistribution(data)
    // props.submitCheckedTracksForRNApartProfileTable(
    //     data.selectedTracks, 
    //     data.typeOfContacts, 
    //     data.selectedTrackNames, 
    //     data.selectedNormalization
    // )  
    props.submitCheckedTracksForRNApartProfile(
        data.selectedTracks, 
        data.typeOfContacts, 
        data.selectedTrackNames, 
        data.selectedNormalization
    )  
  }, []) 

  useEffect(() => {
    props.submitCheckedTracksForRNApartProfile(
        dataForRnaPartsDistribution.selectedTracks, 
        dataForRnaPartsDistribution.typeOfContacts, 
        dataForRnaPartsDistribution.selectedTrackNames, 
        props.trackListCheckbox
    )  
  }, [props.trackListCheckbox])  
  

  const handleCloseMessage = () => {
    setOpenMessage(false)
  }



  return (
    <div > 
        {/* className={classes.root} */}
        <List style={{paddingTop: 0}}>       
            {/* <Typography variant="h4" style={{textAlign: 'center', marginLeft: 10, marginTop: 50}}>Profile of RNA-parts of contacts</Typography>
            <div style={{textAlign: 'center'}}> 
              <TrackListCheckboxes disabled={props.loadingGB || props.loadingRNApartData || props.loadingRNApartDataTable }/>
            </div> */}
            <Grid container spacing={0}>
              <Grid item xs={7} style={{marginTop: 50}}>
                <RnaTableInfo
                  name={dataForRnaPartsDistribution.gene_name}
                  type={dataForRnaPartsDistribution.type}
                  geneLocus={dataForRnaPartsDistribution.geneLocus}
                  dnaFilter={dataForRnaPartsDistribution.dnaFilter}
                  geneNameTarget={dataForRnaPartsDistribution.geneNameTarget}
                  str={dataForRnaPartsDistribution.str}
                  expID_typeOfExperiment={dataForRnaPartsDistribution.expID_typeOfExperiment}
                  length={dataForRnaPartsDistribution.length}
                  genome={dataForRnaPartsDistribution.genome}
                  from={dataForRnaPartsDistribution.from}
                  currentOrganism={dataForRnaPartsDistribution.organism}
                  // parent={'<SimpleDialogDemo />'}
                  // experiment_id={'10'}
                  // track_name={'wffw'}
                  // track_description={'all_track_description'} //all_track_description[row.track_name]['track_description']
                  // allInsert={1}
                />
              </Grid>
              <Grid item xs={1}>
                {props.loadingRNApartData || props.loadingRNApartDataTable ?
                  <Tooltip title="Download data of all charts">
                    <div style={{textAlign: "center", marginTop: 80}}>
                      <IconButton style={{padding: 0}} disabled={true}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                :
                  <Tooltip title="Download data of all charts">
                    <div style={{textAlign: "center",marginTop: 80}}>
                      <DownloadPlotData data={props.geneRNAdataProfile}
                                        plot={'RNA-parts profile'}
                                        dnaFilter={dataForRnaPartsDistribution.dnaFilter}
                        />
                    </div>
                  </Tooltip> 
                }
              </Grid>
              <Grid item xs={4}>
                <div style={{textAlign: 'center', marginTop: 70}}> 
                  <TrackListCheckboxesNew disabled={props.loadingGB || props.loadingRNApartData || props.loadingRNApartDataTable }/>
                </div>
              </Grid>
            </Grid>
                  {props.loadingRNApartData === true || Object.keys(props.geneRNAdataProfile).length === 0 || Object.keys(dataForRnaPartsDistribution).length === 0 ? 
                    <div className={classes.loading}>
                      <LinearProgress color={"primary"} />
                      <Typography variant="h5">
                        Please, wait a while, it may take from a few seconds to a couple of minutes
                      </Typography>
                    </div>
                  :
                  <div >
                    <RNApartProfileBasic 
                        gene_name={dataForRnaPartsDistribution.gene_name}
                        experiment_id={dataForRnaPartsDistribution.experiment_id}
                        organism={dataForRnaPartsDistribution.organism}
                        
                        geneRNAdataProfile={props.geneRNAdataProfile} 
                        // locus={props.locus} 
                        trackListCheckbox={props.trackListCheckbox}

                        dnaFilter={dataForRnaPartsDistribution.dnaFilter}
                    />
                    </div>
                  }
          <Typography variant={"subtitle1"} style={{marginLeft: 10, padding: 5}}>
              If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
          </Typography>
        </List>
        <WarningMessage open={props.snackOpenGenomeBrowser} severity={"error"} message={props.snackTextGenomeBrowser} />
        <WarningMessage open={openMessage} severity={"warning"} handleClose={handleCloseMessage} message={textMessage} />
    </div>
  );
}

const mapStateToProps = (state) => {
    return {
      geneRNAdataProfile: state[module].geneRNAdataProfile,
      geneRNAdataProfileTable: state[module].geneRNAdataProfileTable,
      loadingRNApartData: state[module].loadingRNApartData,
      loadingRNApartDataTable: state[module].loadingRNApartDataTable,

      URLtoGenomeBrowserRnaPart: state[moduleGenomeBrowser].URLtoGenomeBrowserRnaPart,
      loadingGB: state[moduleGenomeBrowser].loadingURLRnaPart,
      snackOpenGenomeBrowser: state[moduleGenomeBrowser].snackOpenGenomeBrowserRnaPart,
      snackTextGenomeBrowser: state[moduleGenomeBrowser].snackTextGenomeBrowserRnaPart,

      trackListCheckbox: state[moduleFilter].trackListCheckbox,

      allTargetGenesTableData: state[module].allTargetGenesTableData,
      snackOpenAllTargetGenesTable: state[module].snackOpenAllTargetGenesTable,
      snackTextAllTargetGenesTable: state[module].snackTextAllTargetGenesTable,
      loadingAllTargetGenesTable: state[module].loadingAllTargetGenesTable,

      RnaListGeneType: state[moduleTable].RnaListGeneType,
      BasicTableAnnotationSource: state[moduleTable].BasicTableAnnotationSource,
      BasicTableGeneName: state[moduleTable].BasicTableGeneName,
      valueSearchGeneName: state[moduleText].valueSearchGeneName,
      valueLocusFromDNA: state[moduleText].valueFromDNA,
      locusWarningFromDNA: state[moduleText].locusWarningFromDNA,
      currentLocusFromDNA: state[moduleText].currentLocusFromDNA,
    }
}

export default connect(mapStateToProps, {
  submitCheckedTracksRnaPart, 
  clearGenomeBrowserRnaPartURL, 
  submitCheckedTracksForRNApartProfile, 
  submitCheckedTracksForRNApartProfileTable,
  submitAllTargetGenesTable,
  textChangeSearchGeneName, SendListGeneType, SendListAnnotationSource, SendListGeneName,
  textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA,
  handleHeader,
                                        })(SimpleDialogDemo);