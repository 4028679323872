import React, { useEffect } from 'react' //useState
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'; //withStyles
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import LinearProgress from '@material-ui/core/LinearProgress';
import logo from './svg/RNA-chrom.png'
import contactExample from './svg/contactExample.png' //svg
// import Data_types from './svg/Data_types.svg'
// import from_rna from './svg/from_rna.svg'
import from_rna_2 from './svg/from_rna_2.svg'
// import from_dna from './svg/from_dna.svg'
import from_dna_2 from './svg/from_dna_2.svg'
import Tooltip from '@material-ui/core/Tooltip';
import Decorator from '../components/HtmlTooltip';
import Divider from '@material-ui/core/Divider';
// import InputsTwo from '../components/TextAdd_two';
// import InputsTwoUpstream from '../components/InputsTwoUpstream';
// import InputsTwoDownstream from '../components/InputsTwoDownstream';
// import InputTwoFromDNA from '../components/InputTwoFromDNA';
// import InputThree from '../components/InputThree';
// import classNames from 'classnames';
// import { connect } from 'react-redux';
// import TableNewSuper from '../components/TableNewSuper';
// import WarningMessage from '../components/MessageSnackbar';
// import TableMetadata from '../components/TableMetadata';
// import BasicTableRNA from '../components/TABLEpartBasic';
// import TrackListCheckboxes from '../components/TrackListCheckboxes';

// import { fetchGenes, handleGeneSelect, changeCurrentGeneInput } from '../actions/genesActions';
// import { fetchGeneTypes, handleGeneTypesSelect, changeCurrentGeneTypesInput } from '../actions/genesActions';
// import { fetchAnnotation, handleAnnotationSelect, changeCurrentAnnotationInput } from '../actions/genesActions';
// import {textChange, locusWarningChangeFalse,locusWarningChangeTrue,currentLocusChange} from '../actions/generalActions';
// import {textChangeFromDNA, locusWarningChangeFalseFromDNA, locusWarningChangeTrueFromDNA, currentLocusChangeFromDNA} from '../actions/generalActions';
// import {textChangeUpstream, textUpstreamWarningChangeFalse, textUpstreamWarningChangeTrue, clearTextInputsTwoUpstream} from '../actions/generalActions';
// import {textChangeDownstream, textDownstreamWarningChangeFalse, textDownstreamWarningChangeTrue} from '../actions/generalActions';
// import { textChangeSearchGeneName, SendListGeneType, SendListAnnotationSource, SendListGeneName } from '../actions/generalActions';
import { handleHeader } from '../actions/generalActions'; //allExpsRequest
// import { clearBasicGeneTableDataFromDNA } from '../actions/basicAnalysisActions';

// import OrganismSelectorSimple from '../components/AutoSuggestOrganismSimple';
// import GeneSelector from '../components/AutoSuggest';
// import GeneTypesSelector from '../components/AutoSuggestTypeGenes';
// import AnnotationSelector from '../components/AutoSuggestAnnotation';
// import RNAAutocomplete from '../components/RNAAutocomplete';

// import { fetchBasicGeneTableFromDNA, selectOneId } from '../actions/basicAnalysisActions';
// import { clearText } from '../actions/generalActions';
// import { clearTextInputsTwo } from '../actions/generalActions';
// import { clearRNAGeneSelectorFromDNA } from '../actions/genesActions';


const moduleTable = "table";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    marginTop: 50,
  },
  link: {
    color: 'rgb(0, 150, 136)',
    '&:hover': {
      color: 'grey',
    }
  },
  divider: {
    marginTop: 10,
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
  loading: {
    marginTop: 150,
    textAlign: 'center',
  },
  // button: {
  //   padding: 10,
  //   // margin: 10,
  //   fontSize: 14,
  //   width: '100%',
  //   marginRight: 20,
  //   marginBottom: 15,
  //   marginTop: 25
  // },
  button: {
    padding: theme.spacing(),
    // textAlign: 'center !important', 
    // width: '60%',
    // marginBottom: 10, 
    marginTop: 10,
    // marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    color: 'white',
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
    },
  },
  buttonImg: {
    // padding: theme.spacing(),
    // marginTop: 10,
    // marginRight: theme.spacing(),
    backgroundColor: 'white',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#b8b9ba',
    },
  },
  // wideButton: {
  //   padding: theme.spacing(),
  //   // marginLeft: theme.spacing(),
  //   // marginRight: theme.spacing(),
  //   // paddingBottom: theme.spacing(),
  //   width: '98%',
  //   height: theme.spacing(12), //'100%'
  //   marginTop: theme.spacing(),
  //   // marginBottom: theme.spacing(),
  // },
  // autocomplete: {
  //   flexGrow: 1,
  //   paddingTop: theme.spacing(),
  //   marginLeft: theme.spacing(),
  //   marginRight: theme.spacing(),
  // }
}));

const Welcome = (props) => {

  const classes = useStyles();
  // const { AllExps } = props;
  // console.log('AllExps',AllExps)
  document.title = "Welcome to the RNA-Chrom database";


  useEffect(() => {
    props.handleHeader(' database')
    // props.allExpsRequest();
  }, [])

  const handleClick = (event) => {
    const data = {} 
    localStorage.setItem("from_all_target_genes",  JSON.stringify(data));
    window.open("/from_dna", "_blank");
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <div style={{textAlign: "center", marginBottom: 0}}>
            <img src={logo} alt="Logo" width={250}/>
          </div>
        </Grid>
        <Grid item xs={4} >
          {/* <Typography variant="h4" style={{fontWeight: 'bold', marginBottom: 0}}> */}
          {/* variant="h6" */}
          {/* <span style={{color: 'red'}}>RNA-Chromatin</span> Interactome Database: */}
          {/* <span style={{fontWeight: 'bold'}}><span style={{color: 'red'}}>RNA-Chromatin</span> Interactome Database</span> allows: */}
          {/* </Typography> */}
          {/* <Typography variant={"subtitle1"} style={{fontWeight: 'bold', marginBottom: 0,marginTop: 15}}>
            The following functionality is available to the user:
          </Typography> */}
          {/* <Grid container spacing={1}>
            <Grid item xs={6}> */}
              <Typography variant={"subtitle1"} style={{ marginBottom: 0, marginLeft: 30, marginTop: 15}}>
                  <li>quick analysis  <Tooltip
                                        placement={"top"}
                                        title={
                                          <React.Fragment>
                                            <Typography>
                                              i.e. get the answer to the question <b>"Where does the selected RNA contact with chromatin?"</b>
                                            </Typography>
                                          </React.Fragment>
                                        }
                                      >
                                        <Link target="_blank" rel="noopener noreferrer" to="/from_rna">
                                          "from RNA"
                                        </Link>
                                      </Tooltip> or <Tooltip
                                                      placement={"top"}
                                                      title={
                                                        <React.Fragment>
                                                          <Typography>
                                                            i.e. get the answer to the question <b>"What RNAs contact with the selected target locus?"</b>
                                                          </Typography>
                                                        </React.Fragment>
                                                      }
                                                    >
                                                      <Link target="_blank" rel="noopener noreferrer" to="/from_dna">
                                                        "from DNA"
                                                      </Link>
                                                    </Tooltip> (<a href="/Tutorial" target="_blank" rel="noopener noreferrer">see tutorial</a>)</li>
                  {/* <li>quick analysis  <Tooltip
                                        placement={"top"}
                                        title={
                                          <React.Fragment>
                                            <Typography>
                                              i.e. get the answer to the question <b>"What RNAs contact with this target locus?"</b>
                                            </Typography>
                                          </React.Fragment>
                                        }
                                      >
                                        <Link target="_blank" rel="noopener noreferrer" to="/from_rna">
                                          "from DNA"
                                        </Link>
                                      </Tooltip> (<a href="/Tutorial" target="_blank" rel="noopener noreferrer">see tutorial</a>)</li> */}
                  {/* <li>quick analysis of <b>RNA-chromatin interactions data</b>: <Link target="_blank" rel="noopener noreferrer" to="/from_rna">"from RNA"</Link> (i.e. get the answer to the question "Where is the selected RNA contacted with chromatin?") or <Link target="_blank" rel="noopener noreferrer" to="/from_dna">"from DNA"</Link> ("What RNAs contact with this target locus?")  (<a href="/overview#Tutorial" target="_blank" rel="noopener noreferrer">see tutorial</a>)</li> */}
                  <li>view contacts in <Decorator 
                                        header={<b>Do the following steps:</b>}
                                        text = {<div>
                                                <Typography>
                                                  <ul>
                                                    <li>if you have chosen <b>"from RNA" analysis</b>:</li>
                                                      <ul>
                                                        <li>select one RNA</li>
                                                        <li>press the "Graphical Summary" button</li>
                                                      </ul>
                                                      <li>if you have chosen <b>"from DNA" analysis</b>:</li>
                                                      <ul>
                                                        <li>choose a DNA locus</li>
                                                        <li>сlick on the RNA name you are interested in</li>
                                                      </ul>
                                                    <li>then in the "Contacts Summary" table, select 1 or more contacts tracks</li>
                                                    <li>and finally, press the "view in Genome Browser" button</li>
                                                  </ul>
                                                </Typography>
                                                </div>
                                                }
                                        component = {
                                            <span style={{color: 'blue'}}>UCSC Genome Browser</span>
                                        }/>
                  </li>
                  <li><a href="http://bioinf.fbb.msu.ru/rna_chrom/download/RNA-chromatin-interactions-data/" target="_blank" rel="noopener noreferrer">download</a> RNA-chromatin interactions data</li> {/* of RNA-chromatin interactions data processed by a universal pipeline <b>(raw, normalized, peaks)</b> */}
                  {/* in order to make user’s own normalization, filtering or any other analysis */}
                  <li><Link target="_blank" rel="noopener noreferrer" to="/experiments">browse</Link> experiments metadata</li>
                  {/* <li>downloading the raw/normalized data in order to make user’s own normalization, filtering or any other analysis</li>
                  <li>downloading the raw/normalized data in order to make user’s own normalization, filtering or any other analysis</li> */}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {null}
            </Grid>
            <Grid item xs={3}>
              <div style={{textAlign: "center", marginBottom: 0}}>
                <img src={contactExample} alt="ContactExample" width={300}/>
              </div>
            </Grid>
            <Grid item xs={1}>
              {null}
            </Grid>
          </Grid>

          <Divider style={{marginTop: 15, marginBottom: 5, width: '100%', height: 2, backgroundColor: 'black'}}/>

          <Grid container spacing={0}>
            <Grid item xs={6} style={{textAlign: 'center'}}>
              <div style={{borderRight: '2px solid #000000'}}>
              <div>
                <Button 
                  className={classes.button}
                  variant="contained"
                  size="small"
                  style={{backgroundColor: '#96000E'}}
                  component={Link} 
                  to="/from_rna"
                >
                  <Typography variant={"subtitle1"}>
                    <b>Select RNA</b> and get its contacts with chromatin
                  </Typography>
                  {/* Where is the <b>selected RNA</b> contacted with <b>chromatin</b>? */}
                </Button>
                </div>
                <div>
                <Tooltip
                  placement={"right-start"}
                  title={
                    <React.Fragment>
                        <Typography>
                          Start analysis <b>"from RNA"</b>
                        </Typography>
                    </React.Fragment>
                  }
                >
                  <Button 
                    className={classes.buttonImg}
                    component={Link} 
                    to="/from_rna"
                  >
                    <img width={450} src={from_rna_2} alt="from_rna" />
                  </Button>
                </Tooltip>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'center'}}>
              <div >
              {/* style={{border: '2px solid #000000'}} */}
              <div>
                <Button 
                  className={classes.button}
                  variant="contained"
                  size="small"
                  style={{backgroundColor: '#96000E'}}
                  // component={Link} 
                  onClick={e => handleClick(e)}
                  // to="/from_dna"
                >
                  <Typography variant={"subtitle1"}>
                    <b>Choose a DNA locus</b> and get all RNAs in contact with it
                  </Typography>
                  {/* What <b>RNAs</b> contact with given <b>DNA locus</b> (target)? */}
                </Button>
                </div>
                <div>
                <Tooltip
                  placement={"left-start"} 
                  title={
                    <React.Fragment>
                        <Typography>
                          Start analysis <b>"from DNA"</b>
                        </Typography>
                    </React.Fragment>
                  }
                >
                  <Button 
                    className={classes.buttonImg}
                    onClick={e => handleClick(e)}
                  >
                    <img width={450} src={from_dna_2} alt="from_dna" />
                  </Button>
                </Tooltip>
                </div>
              </div>
            </Grid> 
          </Grid>
        {/* </Grid>
        <Grid item xs={3} style={{fontWeight: 'lighter'}}> */}
          {/* <div style={{marginBottom: 25, marginRight: 20}}>
            <img src={logo} alt="Logo" width={300}/>
          </div> */}
          {/* {!props.loadingAllExps && Object.keys(AllExps).length !== 0 ?
              <TableMetadata 
                      title={'Database contain:'} 
                      data={AllExps['z']} 
                      columns={AllExps['x']}
                    />
            :
            <div> 
                <LinearProgress color={"primary"} />
                <Typography variant="h5">
                  Please, wait a while, it may take from a few seconds to a couple of minutes
                </Typography>
                <WarningMessage open={props.snackOpenAllExps} severity={"error"} message={props.snackTextAllExps} />
            </div>
          } */}
          <Typography variant={"subtitle1"} style={{padding: 0}}>
            If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b> <br/>
            Last update: 12.02.2024
          </Typography>
        {/* </Grid>
      </Grid>	 */}
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
      loadingAllExps: state[moduleTable].loadingAllExps,
      AllExps: state[moduleTable].AllExps,
      snackOpenAllExps: state[moduleTable].snackOpenAllExps,
      snackTextAllExps: state[moduleTable].snackTextAllExps,
  }
}

export default connect(mapStateToProps,{ handleHeader})(Welcome);