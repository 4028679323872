import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import OnePageIcon from '@material-ui/icons/ChevronRight';
import TenPagesIcon from '@material-ui/icons/DoubleArrow';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '80%',
    },
    textField: {
        width: '70%',
        float: 'left',
    },
    left: {
        transform: 'rotate(180deg);'
    },
    button: {
        float: 'right',
    },
}));

const RnaListPagination = (props) => {
    const classes = useStyles();

    const [currentPage, setCurrentPage] = useState(props.page);
    const [maxPage, setMaxPage] = useState(0);

    useEffect(() => {
        // console.log('props.count, rowsCount',props.count)
        let mp = 0
        if (props.count % props.rowsPerPage === 0) {
            mp = Math.trunc(props.count/props.rowsPerPage)
        } else {
            mp = Math.trunc(props.count/props.rowsPerPage) + 1
        }
        setMaxPage(mp);
        // console.log('mp',mp)
    }, [props.count, props.rowsPerPage]);

    const handleRppChange = (event) => {
        props.onChangeRowsPerPage(event.target.value);
    };

    const handlePageChange = (event) =>{ 
        if (event.target.value === "") {
            setCurrentPage('');
        } else {
            let stringPage = event.target.value.match(/\d/g);
            // console.log(stringPage,typeof(stringPage),"stringPage")
            if (stringPage !== null) {
                // console.log('stringPage.join("")',stringPage.join(""))
                let actualPage = parseInt(stringPage.join(""), 10);
                if (actualPage < 1) {
                    actualPage = 1;
                } else if (actualPage > maxPage) {
                    actualPage = maxPage;
                }
                setCurrentPage(actualPage);
            }
        }
    };

    // types
    // 1 - 1 page
    // 10 - 10 pages
    // 1000000000 - last

    // directions
    // + - add
    // - minus
    const handleButtonPageChange = (type, direction) => {
        let currentPage = props.page;
        // console.log('currentPage',currentPage);
        // console.log('props.rowsPerPage',props.rowsPerPage)
        currentPage = (direction === "+") ? currentPage + type : currentPage - type;
        currentPage = (currentPage > 0 ? currentPage : 0);
        currentPage = (currentPage > maxPage ? (maxPage - 1) : currentPage);
        // console.log('currentPage after',currentPage);
        props.onChangePage(currentPage);
    };

    const goToPage = () => {
        // console.log('currentPage',currentPage);
        props.onChangePage(currentPage - 1);
    };

    return (
        <div className={classes.root}>
            <Toolbar>
                <Grid
                    container
                    spacing={0}
                    direction={"row"}
                    justify={"center"}
                    alignItems={"center"}
                >
                    <Grid item xs={1}>
                        <Button
                            color={"primary"}
                            variant="contained"
                            size="small"
                            onClick={goToPage}
                            className={classes.button}
                            disabled={currentPage === ''}
                        >
                            Go to:
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            inputProps={{style: { textAlign: 'center' }}}
                            value={currentPage === 0 ? currentPage + 1 : currentPage} //Page + 1
                            // value={currentPage + 1}
                            onChange={handlePageChange}
                            className={classes.textField}
                            label={"Page"}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Rows Per Page</InputLabel>
                            <Select
                                value={props.rowsPerPage}
                                onChange={handleRppChange}
                            >
                                {props.rowsPerPageOptions.map((rpp, index) => {
                                    return (
                                        <MenuItem value={rpp} key={index}>
                                            {rpp}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="First page">
                            <IconButton
                                onClick={() => handleButtonPageChange(1000000000, '-')}
                                disabled={props.page === 0}
                            >
                                <LastPageIcon className={classes.left} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Previous 10 pages">
                            <IconButton
                                onClick={() => handleButtonPageChange(10, '-')}
                                disabled={props.page === 0}
                            >
                                <TenPagesIcon className={classes.left} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Previous page">
                            <IconButton
                                onClick={() => handleButtonPageChange(1, '-')}
                                disabled={props.page === 0}
                            >
                                <OnePageIcon className={classes.left}/>
                            </IconButton>
                        </Tooltip>
                        <span>Page {props.page + 1} of {maxPage}</span>
                        <Tooltip title="Next page">
                            <IconButton
                                onClick={() => handleButtonPageChange(1, '+')}
                                disabled={props.page === maxPage - 1}
                            >
                                <OnePageIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Next 10 pages">
                            <IconButton
                                onClick={() => handleButtonPageChange(10, '+')}
                                disabled={props.page === maxPage - 1}
                            >
                                <TenPagesIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Last page">
                            <IconButton
                                onClick={() => handleButtonPageChange(1000000000, '+')}
                                disabled={props.page === maxPage - 1}
                            >
                                <LastPageIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Toolbar>
        </div>
    );
};

export default RnaListPagination;