// export const FETCH_ORGANISM_REQUEST = "FETCH_ORGANISM_REQUEST";
// export const FETCH_ORGANISM_SUCCESS = "FETCH_ORGANISM_SUCCESS";
// export const FETCH_ORGANISM_FAILURE = "FETCH_ORGANISM_FAILURE";

// export const fetchOrganism = () => {
//     return {
//         type: FETCH_ORGANISM_REQUEST,
//         logged,
//     };
// };

export const HANDLE_ORGANISM_SELECT = "HANDLE_ORGANISM_SELECT";

export const handleOrganismSelect = (organismName) => {
    return {
        type: HANDLE_ORGANISM_SELECT,
        organismName,
    };
};

export const HANDLE_CURRENT_ORGANISM_INPUT = "HANDLE_CURRENT_ORGANISM_INPUT"

export const changeCurrentOrganismInput = (value) => {
    return {
        type: HANDLE_CURRENT_ORGANISM_INPUT,
        value,
    };
};

export const CLEAR_EXPERIMENTS_CHACKBOX = "CLEAR_EXPERIMENTS_CHACKBOX";

export const clearExperimentsCheckbox = () => {
    return {
        type: CLEAR_EXPERIMENTS_CHACKBOX,
    };
};