import React from 'react';
import Plot from 'react-plotly.js';
// import Typography from '@material-ui/core/Typography';
// import TableContainer from '@material-ui/core/TableContainer';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Paper from '@material-ui/core/Paper';
// import Toolbar from '@material-ui/core/Toolbar';
// import Tooltip from '@material-ui/core/Tooltip';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
// import Plotly from 'plotly.js'
// import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import Decorator from '../../components/HtmlTooltip';
import EnhancedTABLEpart from '../../components/TableGraphicalSummary';
import { Track_description, normalizationName } from './Heatmap_data_layout';
import { getMax } from './DensityAllTargetGenes';

const module = "plots";

// const Heatmap_description = (data) => {
//     let List = []
//     let List_final = []
//     for (var k = 0; k < data.length; k++) {
//         if (List.indexOf(data[k]) === -1) {
//             List.push(data[k])
//         }
//     }
//     for (var i = 0; i < List.length; i++) {
//         var res = List[i].split(", ")
//         List_final.push(<Typography key={i}>
//                             -the tracks from the experiment <b>({res[2]})</b> obtained by the <b>{res[0]}</b> method on the <b>{res[1]}</b> cell line
//                         </Typography>
//         )
//     }
//     return List_final
// }

const Profile = (props) => {

    //const { classes } = props;
    var steps = [];
    const { genomeWideDataProfile, trackNameAndColor } = props;
    var i = 1
    // var chrom = []
    // var chrom_name = []

    // console.log('genomeWideDataProfile',genomeWideDataProfile)
    for (var organism in genomeWideDataProfile) {
        var table_element_names = []
        var table_rows_color = []
        var table_element_values = []
        var all = []
        var data_for_profile = []
        var contactsType = []
        var shapes_rect = []
        var max_array = []
        var table_element_description = []
        var selectedNormalization = ''
    //     console.log('key', key)
        // for (var organism in genomeWideDataProfile) {
        // console.log('organism:', organism)
        var flag = true;
        // let oooooo = ['Xist 0hr', 'Xist 2hr','Xist 6hr']
        // let plot_count = 0
        for (var RNA_track in genomeWideDataProfile[organism]) {
            // console.log('RNA_track',RNA_track)
            if (RNA_track !== '-111') {
                // plot_count += 1
                // console.log('RNA_track',RNA_track)
                table_element_names.push(genomeWideDataProfile[organism][RNA_track]['name'])
                table_rows_color.push(genomeWideDataProfile[organism][RNA_track]['color'])
                selectedNormalization = genomeWideDataProfile[organism][RNA_track]['selectedNormalization']
                table_element_values.push(genomeWideDataProfile[organism][RNA_track]['sum_value'])
                contactsType.push(genomeWideDataProfile[organism][RNA_track]['typeOfContacts'])
                // console.log('genomeWideDataProfile[organism][RNA_track]',genomeWideDataProfile[organism][RNA_track]['x'])
                var trace = genomeWideDataProfile[organism][RNA_track]['name']
                table_element_description.push(genomeWideDataProfile[organism][RNA_track]['description_for_plots'])
                // console.log('trace', trace)
                if (flag === true && genomeWideDataProfile[organism][RNA_track]['typeOfContacts'] === 'RNA_part' && genomeWideDataProfile[organism][RNA_track]['type_RNAs'] !== 'exist') {
                    var bins = 2;
                    // console.log('bins=2')
                } else {
                    var bins = 2000;
                    var flag = false;
                }
                // ['#1f77b4','#ff7f0e','#2ca02c','#d62728','#9467bd','#8c564b','#e377c2','#7f7f7f','#bcbd22','#17becf']
                max_array.push(getMax(genomeWideDataProfile[organism][RNA_track]['y'])*1.5)
                let a=Array.from(Array(genomeWideDataProfile[organism][RNA_track]['x'].length)).map(i=>1500000)
                all.push(
                    { trace } = {
                        x: genomeWideDataProfile[organism][RNA_track]['x'],
                        y: genomeWideDataProfile[organism][RNA_track]['y'],
                        width: a,
                        xaxis: 'x2',
                        yaxis: 'y2',
                        name: genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0], //oooooo[plot_count-1]
                        type: 'bar',
                        // autobinx: false,
                        // scale: false,
                        // histfunc: "sum",
                        // type: "histogram",
                        opacity: 0.5,
                        hoverinfo: 'y+name',
                        // hoverinfo: 'y',
                        // hovertemplate: '%{y:.3f}',
                        marker: {
                            color: trackNameAndColor[genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0]]//genomeWideDataProfile[organism][RNA_track]['color'],
                        },
                        // nbinsx: bins,
                        }
                    )
                } else {
                // if (RNA_track === 'description_for_plots') {
                //     // var for_description = Heatmap_description(genomeWideDataProfile[organism][RNA_track])
                //     var table_element_description = genomeWideDataProfile[organism][RNA_track]
                //     console.log('table_element_description',table_element_description)
                // } else {

                // var max_heigth = Math.max(...max_array)
                var chrom = []
                var chrom_name = []
                var chromosomes = []
                for (var chr in genomeWideDataProfile[organism][RNA_track]) {
                    chrom.push(genomeWideDataProfile[organism][RNA_track][chr]['title_chr'])
                    chrom_name.push(genomeWideDataProfile[organism][RNA_track][chr]['name_chr'])
                }
                for (var index = 0; index < chrom_name.length; index ++) {
                    chromosomes.push(
                        {
                            x: chrom[index], 
                            y: 0,
                            xref: 'x2',
                            // yref: 'y1',
                            yref: 'paper',
                            text: chrom_name[index],
                            arrowhead: 0.7,
                            arrowsize: 0.7,
                            // showarrow: true,
                            ax: 0,
                            ay: 30,
                            textangle: '-90',
                            font: {
                                family: 'sans serif',
                                size: 11,
                                // color: '#ff7f0e'
                            },
                          }

                    )
                }
                // var chromosomes = {
                //     x: chrom,
                //     // y: [-max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 , -max_heigth/10 ,],
                //     y: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
                //     mode: 'text',
                //     text: chrom_name,
                //     textposition: 'bottom',
                //     // textangle: -45,
                //     textfont: {
                //         // tickangle: -45,
                //         family: 'sans serif',
                //         size: 15,
                //         // color: '#ff7f0e'
                //     },
                //     xaxis: 'x2',
                //     yaxis: 'y2',
                //     // name: 'Chromosomes',
                //     hoverinfo: 'none',
                // };
                // console.log('chromosomes', chromosomes)
                    
                for (var chr_id in genomeWideDataProfile[organism][RNA_track]) {
                    shapes_rect.push(
                        {
                            type: 'rect',
                            yref: 'paper',
                            x0: genomeWideDataProfile[organism][RNA_track][chr_id]['start'],
                            y0: 0, //max_heigth, //0,
                            x1: genomeWideDataProfile[organism][RNA_track][chr_id]['end'],
                            y1: 1, //0.41, //0,
                            opacity: 0.1,
                            line: {
                                color: genomeWideDataProfile[organism][RNA_track][chr_id]['color'],
                                width: 0
                            },
                            fillcolor: genomeWideDataProfile[organism][RNA_track][chr_id]['color'] //'rgba(128, 0, 128, 0.7)'
                        }
                    )
                }
            // }
            }
        }
        
        // }
        // all.push(chromosomes)

        // console.log('table_element_names',table_element_names)
        // console.log('table_element_values', table_element_values)
        // console.log('table_element_description',table_element_description)
        // console.log('contactsType',contactsType)
        // console.log('shapes_rect',shapes_rect)
        // console.log('max_array',max_array)
        let trackDescription = Track_description(table_element_names.map(function(item) {return item.split('|*|')}).map(function(item) {return item[1]}))
        // console.log('trackDescription',trackDescription)
        let trackkk = table_element_names.map(function(item) {return item.split('|*|')}).map(function(item) {return item[0]})
        // console.log('trackkk ',trackkk )
        for (var numberOfcells = 0; numberOfcells < table_element_description.length; numberOfcells ++) {
            // console.log('numberOfcells!!!!!!!!!!!!!!!!',numberOfcells)
            data_for_profile.push(
                {'track': [trackkk[numberOfcells].replace(/<br>/g, ' '), "Track (RNA)"],
                // 'color': table_rows_color[numberOfcells],
                'trackDescription': [trackDescription[numberOfcells].replace(/<br>/g, ' '), "Track description"],
                'datasetDescription': [table_element_description[numberOfcells].replace(/<br>/g, ' '), "Exp.description"],
                'totalContactsCount': [table_element_values[numberOfcells], "n-reads"],
                }
            )
        }
        // console.log('data_for_profile',data_for_profile)
        // all.push(
        //     {
        //         type: 'table',
        //         header: {
        //             values: [["<b>Track (RNAs)</b>"],
        //                     ["<b>Track description</b>"],
        //                     ["<b>Dataset description</b>"], 
        //                     ["<b>Total contacts count</b>"]],
        //             align: ["center", "center", "center", "center"],
        //             line: { width: 1, color: 'black' },
        //             fill: { color: 'grey' },
        //             font: { family: "Arial", size: 12, color: "black" },
        //             height: 27,
        //         },
        //         cells: {
        //             values: [table_element_names.map(function(item) {return item.split('|*|')}).map(function(item) {return item[0]}),
        //                     Track_description(table_element_names.map(function(item) {return item.split('|*|')}).map(function(item) {return item[1]})),
        //                     table_element_description,
        //                     table_element_values],
        //             align: ["left", "left", "left", "center"],
        //             line: { color: "black", width: 1 },
        //             format: ['', '', '', ",.r"],
        //             // fill: { color: ['#25FEFD', 'white'] },
        //             font: { family: "Arial", size: 12, color: ["black"] },
        //             height: 27,
        //         },
        //         domain: {x: [0,1], y: [0.5,1]}
        //         // yaxis: 'y1',
        //     }
        // )

            // chromosomes.push(
            //     // FAKE XAXIS LABEL
            //     {
            //         x: 0.5,
            //         y: -0.16,
            //         xref: 'paper',
            //         yref: 'paper',
            //         // text: 'Chromosomes',
            //         text: '',
            //         showarrow: false,
            //         font: {
            //             family: 'sans serif',
            //             size: 25,
            //             // color: '#ff7f0e'
            //         },
            //       }
            // )
            var layout={
                // grid: {
                //     rows: 2,
                //     columns: 1,
                //     pattern: 'independent', //coupled
                //     roworder: 'top to bottom',
                // },
                height: 450,                            
                width: 1200,  
                titlefont: { size: 25 },
                barmode: "overlay",
                // hovermode:'closest',
                // title: `Contacts profile, exp_id = ${organism}, ${contactsType}`,
                title:`Organism: <b>${organism.slice(1,-1)}</b>, normalization: <b>${normalizationName(selectedNormalization)}</b>`, //, ${contactsType}
                // textangle: -90,
                // xaxis1: {
                //     showticklabels: false,
                // },
                // yaxis1: { 
                //     automargin: true, 
                //     showticklabels: false,
                // },
                xaxis2: {
                    title: "Chromosomes",
                    showticklabels: false,
                    titlefont: { size: 25 },
                },
                yaxis2: { 
                    // domain: [0, 0.65],
                    automargin: true,
                    title: "Contacts Density", 
                    // showticklabels: false,
                    titlefont: { size: 25 },
                },
                shapes: shapes_rect, //?????????
                annotations: chromosomes
            }

            steps.push(
                <div key={i} >
                    {/* <Decorator 
                        header = {<u>{'This plot shows:'}</u>}
                        text = {for_description}
                        component = {
                            <div> */}
                                <div>
                                <Plot data={all} layout={layout} config={{
                                    modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian','lasso2d','select2d'],
                                    // displayModeBar: false,
                                    // scrollZoom: true,
                                    displaylogo: false,
                                    doubleClickDelay: 1000,
                                    toImageButtonOptions: {format: 'svg', filename: 'Contacts distribution', scale: 1}
                                    }}/>
                                </div>
                                <div>
                                    <EnhancedTABLEpart  from={props.from} data={data_for_profile} trackNameAndColor={trackNameAndColor}/>
                                </div>
                            {/* </div>
                        }
                    /> */}
                </div>
            )
            
            i=i+1
            // console.log('i',i)
            // console.log('len(steps)',steps.length)
    }
    return (
        <div id='myDiv'>
            {steps}
        </div>
    )
}

  
const mapStateToProps = (state) => {
    return {
        genomeWideDataProfile: state[module].genomeWideDataProfile,
    }
}
    
  
export default connect(mapStateToProps, )(Profile);