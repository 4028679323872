import React, { useEffect } from 'react'
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import TableContainer from '@material-ui/core/TableContainer';
// import MUIDataTable from "mui-datatables";
// import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
// import { Link } from 'react-router-dom'
// import Button from '@material-ui/core/Button';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import LinearProgress from '@material-ui/core/LinearProgress';

// import 'katex/dist/katex.min.css';
// import { BlockMath } from 'react-katex';

import { handleHeader } from '../actions/generalActions';

// const module = "table"

const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        // padding: 50,
        // paddingTop: 70,
        ...theme.mixins.gutters(),
        // paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        //width: '40%',
        // textAlign: 'center',
        flexGrow: 1,
        // width: '96%',
        marginTop: theme.spacing(7),
    },
    title: {
        marginTop: 30,
        // marginBottom: 0,
        // textAlign: 'center !important',
        marginLeft: 23,
        textAlign: 'left',
        },
    divider: {
        marginTop: 20,
        marginBottom: 20,
        // margin: '10px auto',
        width: '100%',
        height: 2,
        backgroundColor: 'black',
      },
    link: {
      color: 'rgb(0, 150, 136)',
      '&:hover': {
        color: 'grey',
      }
    },
}));

const About = (props) => {

//   var Latex = require('react-latex');
  const classes = useStyles();
  document.title = "RNA-chrom: about";
  
//   const { AllExps } = props;

  useEffect(() => {
    props.handleHeader(': about')
    // props.allExpsRequest();
  }, [])

  return (
    <div className={classes.root}>
        <Typography style={{textAlign: "center"}} variant="h5">
            <b>The project was made by:</b>
        </Typography>
        <Typography variant={"subtitle1"} style={{ marginBottom: 5, marginLeft: 50, marginRight: 30}}>
            <ul>
                <li><b><a href="https://orcid.org/0000-0002-2225-156X" target="_blank" rel="noopener noreferrer">Grigory K. Ryabykh</a></b> [1,2,4], PhD student, сorresponding author.</li>
                <li><b>S.V. Kuznetsov</b> [1], volunteer research assistant.</li>
                <li><b><a href="https://www.linkedin.com/in/andrey-sigorskikh-693b1b171/" target="_blank" rel="noopener noreferrer">A.I. Sigorskikh</a></b> [1], PhD student.</li>
                <li><b><a href="https://www.scopus.com/authid/detail.uri?authorId=26768006800" target="_blank" rel="noopener noreferrer">Yuriy D Korostelev</a></b> [2], junior researcher.</li>
                <li><b><a href="https://orcid.org/0000-0003-0723-0493" target="_blank" rel="noopener noreferrer">A.A. Zharikova</a></b> [1,2,3], assistant professor.</li>
                <li><b><a href="https://www.scopus.com/authid/detail.uri?authorId=55595446700" target="_blank" rel="noopener noreferrer">Andrey A. Mironov</a></b> [1,2], professor.</li>
            </ul>
            [1]: Faculty of Bioengineering and Bioinformatics, Moscow State University, Moscow, 119234 Russia<br/>
            [2]: Kharkevich Institute of Information Transmission Problems, Moscow, 127051 Russia<br/>
            [3]: Belozersky Institute of Physico-Chemical Biology, Moscow State University, Moscow, 119992 Russia<br/>
            [4]: Skolkovo Institute of Science and Technology, Moscow, 121205 Russia
            <ul style={{border: '2px solid #ff0000'}}>
              <li>G.K. Ryabykh, S.V. Kuznetsov, A.I. Sigorskikh, Y.D. Korostelev, A.A. Zharikova, A.A. Mironov. <b>RNA-Chrom: a manually-curated analytical database of RNA–chromatin interactome</b>, Database, Volume 2023, 2023, baad025, <a href="https://doi.org/10.1093/database/baad025" target="_blank" rel="noopener noreferrer">https://doi.org/10.1093/database/baad025</a></li>
            </ul>
              If you have a question or encounter a technical problem, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b>
        </Typography>
        <Divider className={classes.divider}/>
        <Typography style={{textAlign: "center"}} variant="h5">
            <b>Acknowledgements</b> (for contribution to the creation of the RNA-Chrom database)
        </Typography>
        <Typography variant={"subtitle1"} style={{marginBottom: 5, marginLeft: 50, marginRight: 30}}>
            <ul>
                <li><b>Ekaterina Ivanova</b>, Master's student, Center for Mind/Brain Sciences (CIMeC), University of Trento, 38068 Rovereto (TN), Italy</li>
                <li><b>Dr. Mikhail Moldovan</b>, Skolkovo Institute of Science and Technology, Moscow, Russia 121205</li>
                <li><b>Dr. Alina Sofronova</b>, Faculty of Bioengineering and Bioinformatics, Moscow State University, Moscow, 119234 Russia</li>
                <li><b>Dmitry Mylarshchikov</b>, PhD student, Institute of Molecular Biotechnology, Austrian Academy of Sciences, 1030 Vienna, Austria & Vienna BioCenter PhD program, Vienna BioCenter, 1030 Vienna, Austria</li> {/* Evgenevich */}
                <li><b>Aleksandra M Ozerova</b>, PhD student, Skolkovo Institute of Science and Technology, Moscow, Russia 121205</li>
                <li><b>Anna A. Rybina</b>, PhD student, Skolkovo Institute of Science and Technology, Moscow, Russia 121205</li>
            </ul>
        </Typography>
        <Divider className={classes.divider}/>
        <Typography style={{textAlign: "center"}} variant="h5">
            <b>Other publications related to the RNA-DNA interactome</b>
        </Typography>
        <Typography variant={"subtitle1"} style={{ marginBottom: 5, marginLeft: 50, marginRight: 30}}>
            <ul>
              <li>Grigory Ryabykh, Artem Vasilyev, Lidia Garkul, Vladimir Shatskiy, Andrey Mironov. <b>Comparative analysis of the RNA-chromatin interactions data. Completeness and accuracy</b>. BioRxiv, DOI: 10.1101/2023.09.21.558854</li>
              <li>Dmitry E. Mylarshchikov, Arina I. Nikolskaya, Olesja D. Bogomaz, Grigory K. Ryabykh, Anastasia A. Zharikova, Andrey A. Mironov. <b>BaRDIC: robust peak calling for RNA-DNA interaction data</b>. BioRxiv, DOI: 10.1101/2023.09.21.558815</li>
              <li>G.K. Ryabykh, D.E. Mylarshchikov, S.V. Kuznetsov, A.I. Sigorskikh, T.Y. Ponomareva, A.A. Zharikova and A.A. Mironov <b>RNA–Chromatin Interactome: What? Where? When?.</b> Mol Biol 56, 210–228 (2022). DOI: 10.31857/S002689842202015X</li>
              <li>Gavrilov A.A., Zharikova A.A., Galitsyna A.A., Luzhin A.V., Rubanova N.M., Golov A.K., Petrova N.V., Logacheva M.D., Kantidze O.L., Ulianov S.V., Magnitov M.D., <u>Mironov A.A.</u>, Razin S.V. 2020. <b>Studying RNA-DNA interactome by Red-C identifies noncoding RNAs associated with various chromatin types and reveals transcription dynamics.</b> Nucleic Acids Res. 48 (12), 6699–6714.</li>
            </ul>
        </Typography>
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        // loadingAllExps: state[module].loadingAllExps,
        // AllExps: state[module].AllExps,
        // snackOpenAllExps: state[module].snackOpenAllExps,
        // snackTextAllExps: state[module].snackTextAllExps,
    }
  }
  
export default connect(mapStateToProps, { handleHeader })(About);