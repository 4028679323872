import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

import Decorator from '../components/HtmlTooltip';


import { setTrackListCheckbox, setTrackListCheckboxState } from '../actions/filterActions';


const moduleText = "text";
const moduleTable = "table";
const moduleFilter = "filter";

const useStyles = makeStyles(theme => ({
  locationCheckbox: {
    padding: 3,
    // paddingTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
    // width: '90%',
    // marginLeft: theme.spacing(),
    // marginBottom: 2,
    // marginBottom: 10,
    // marginTop: theme.spacing(),
    textAlign: 'center',
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))


const TrackListCheckboxes = (props) => {
    
    const [state, setState] = useState({
      peacks_log_pvalue: false,
      peacks_enrichment: false,
      observed_value: false,
      // expected_value: false,
      norm_value: true,
    })

//   const handleChange = name => event => {
//     props.setRNAState(name, event.target.checked);
//   };
  // console.log('props.trackListCheckboxState',props.trackListCheckboxState)

  const handleChange = name => event => {
    // let list_names = name.split(',')
    // if (state[list_names[0]] === false) {
    //     setState({ [list_names[0]]: true, [list_names[1]]: false, [list_names[2]]: false, [list_names[3]]: false});
    //     props.setTrackListCheckbox(list_names[0])
    // }
    props.setTrackListCheckbox(name)
    // console.log('qqq name',name)
    // console.log('qqq event.target.checked',event.target.checked)
    props.setTrackListCheckboxState(name, event.target.checked)
  };

  const classes = useStyles();
  // const { peacks_log_pvalue, peacks_enrichment, norm_value, observed_value } = state; // expected_value,
  // const { add_to_Track, currentLocus } = props

  // let add_to_Track_list = '|' + currentLocus + '|' + add_to_Track + '&'+Active+Middle+Low+GeneBody+Upstream+Downstream;

  // useEffect(() => {
  //   props.changeCurrent_add_to_Track_list(add_to_Track_list)
  // }, [add_to_Track_list])

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
      <RadioGroup row aria-label="position" name="position" defaultValue="top">
      <div>
          <Decorator 
            key = {1000}
            // text = {<Typography color="inherit">You select one of the normalizations:<br/><b>Normalized contact counts (N-norm)</b></Typography>}
            text = {<Typography color="inherit"><b>Normalization to background contacts.</b><br/>For details, go to the page "Tutorial" in the section "All data information".</Typography>}
            component = {
              <div>
                <FormControlLabel
                  control={
                    // <Checkbox
                    //   className={classes.locationCheckbox}
                    //   checked={norm_value}
                    //   onChange={handleChange('norm_value,expected_value,observed_value')}
                    //   // value="norm_value"
                    //   // color="primary"
                    // />
                    <FormControlLabel
                      disabled={props.disabled}
                      value="norm_value"
                      checked={props.trackListCheckboxState.norm_value}
                      onChange={handleChange('norm_value')} //expected_value,observed_value
                      control={<Radio color="primary" className={classes.locationCheckbox}/>}
                      labelPlacement="end"
                      label="Background Normalization"
                    />
                  }
                  // label="Norm value"
                />
              </div>
            }
          />
        </div>
        <div>
          <Decorator 
            key = {1000}
            text = {<Typography color="inherit">You select one of the normalizations:<br/><b>Observed contact counts (N-obs)</b></Typography>}
            component = {
              <div>
                <FormControlLabel
                  control={
                    <FormControlLabel
                      disabled={props.disabled}
                      value="observed_value"
                      checked={props.trackListCheckboxState.observed_value}
                      onChange={handleChange('observed_value')}
                      control={<Radio color="primary" className={classes.locationCheckbox}/>}
                      labelPlacement="end"
                      label="N-obs"
                    />
                  }
                />
              </div>
            }
          />
        </div>
        {/* <div>
          <Decorator 
            key = {1000}
            text = {<Typography color="inherit">You select one of the normalizations:<br/><b>Expected contact counts (N-exp)</b></Typography>}
            component = {
              <div>
                <FormControlLabel
                  control={
                    <FormControlLabel
                      value="expected_value"
                      checked={expected_value}
                      onChange={handleChange('expected_value,norm_value,observed_value')}
                      control={<Radio color="primary" className={classes.locationCheckbox}/>}
                      labelPlacement="end"
                      label="N-exp"
                    />
                  }
                />
              </div>
            }
          />
        </div> */}
        {/* <div>
          <Decorator 
            key = {2000}
            text = {<Typography color="inherit">You select one of the normalizations:<br/><b>Expected contact counts (N-exp)</b></Typography>}
            component = {
              <div>
                <FormControlLabel
                  control={
                    <FormControlLabel
                      value="peacks_log_pvalue"
                      checked={peacks_log_pvalue}
                      onChange={handleChange('peacks_log_pvalue,observed_value,norm_value,peacks_enrichment')}
                      control={<Radio color="primary" className={classes.locationCheckbox}/>}
                      labelPlacement="end"
                      label="Peaks: log(P-value)"
                    />
                  }
                />
              </div>
            }
          />
        </div>
        <div>
          <Decorator 
            key = {3000}
            text = {<Typography color="inherit">You select one of the normalizations:<br/><b>Expected contact counts (N-exp)</b></Typography>}
            component = {
              <div>
                <FormControlLabel
                  control={
                    <FormControlLabel
                      value="peacks_enrichment"
                      checked={peacks_enrichment}
                      onChange={handleChange('peacks_enrichment,observed_value,peacks_log_pvalue,norm_value')}
                      control={<Radio color="primary" className={classes.locationCheckbox}/>}
                      labelPlacement="end"
                      label="Peaks: enrichment"
                    />
                  }
                />
              </div>
            }
          />
        </div> */}
        </RadioGroup>
    </FormControl>
  </div>
  );
}


const mapStateToProps = (state) => {
  return {
      currentLocus: state[moduleText].currentLocus,
      selectedItemOrganism: state[moduleTable].selectedItemOrganism,
      trackListCheckboxState: state[moduleFilter].trackListCheckboxState,
  }
}

export default connect(mapStateToProps, {setTrackListCheckbox, setTrackListCheckboxState})(TrackListCheckboxes);