import * as ActionTypes from '../actions/genesActions';

const initialState = {
    loadingGenes: false,
    // loadingPlot: false,
    snackOpen: false,
    snackText: '',
    snackOpenGeneTypes: false,
    snackTextGeneTypes: '',
    snackOpenAnnotation: false,
    snackTextAnnotation: '',
    heatmapDataDict: '',
    suggestions: [],
    selectedItem: [],
    currentGeneInput: "",

    loadingGeneTypes: false,
    suggestionTypes: [],
    selectedItemGeneTypes: [],
    currentGeneTypesInput: "",

    loadingAnnotation: false,
    suggestionAnnotation: [],
    selectedItemAnnotation: [],
    currentAnnotationInput: "",

    trackNameList: [],
    selectedTrackNames: [],
    cellTypeList: [],
    selectedCellType: [],
    selectedIndexForCellType: [],

    trackList: [],
    selectedTracks: [],

    typeOfContacts: 'DNA_part',
    // trackList: ["[gene_name in ('AADACL4', ) or gene_type in ('rRNA', )] where experiment_id in ('1', '2', '3', )", "[gene_name in ('BBIP1P1', ) or gene_type in ('rRNA', )] where experiment_id in ('1', '2', '3', )", "[gene_name in ('CCNB1IP1P1', ) or gene_type in ('rRNA', )] where experiment_id in ('1', '2', '3', )"],
}

const genesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_GENES_REQUEST:
            return {
                ...state,
                loadingGenes: true,
            };
        case ActionTypes.FETCH_GENES_SUCCESS:
            return {
                ...state,
                /* suggestions: action.genes, */
                suggestions: action.genes,
                // heatmapDataDict: action.heatmap_data_result,
                loadingGenes: false,
                // snackOpen: true,
                // snackText: "Successfully fetched genes.",
            };
        case ActionTypes.FETCH_GENES_FAILURE:
            return {
                ...state,
                loadingGenes: true,
                snackOpen: true,
                snackText: "An error occurred in the backend, genes data was not sent to the web!",
            };
        
        /* AUTOSUGGEST GENES*/

        case ActionTypes.HANDLE_GENE_SELECT:
            return {
                ...state,
                selectedItem: action.geneName,
            };
        
        case ActionTypes.HANDLE_CURRENT_GENE_INPUT:
            return {
                ...state,
                currentGeneInput: action.value,
            };

        /* GENE TYPES*/

        case ActionTypes.FETCH_GENE_TYPES_REQUEST:
            return {
                ...state,
                loadingGeneTypes: true,
            };
        case ActionTypes.FETCH_GENE_TYPES_SUCCESS:
            // console.log('action.geneTypes',action.geneTypes)
            return {
                ...state,
                /* suggestions: action.genes, */
                suggestionTypes: action.geneTypes,
                // heatmapDataDict: action.heatmap_data_result,
                loadingGeneTypes: false,
                // snackOpenGeneTypes: true,
                // snackTextGeneTypes: "Successfully fetched geneTypes.",
            };
        case ActionTypes.FETCH_GENE_TYPES_FAILURE:
            return {
                ...state,
                loadingGeneTypes: true,
                snackOpenGeneTypes: true,
                snackTextGeneTypes: "An error occurred in the backend, gene types data was not sent to the web!",
            };

        /* AUTOSUGGEST GENE TYPES*/

        case ActionTypes.HANDLE_GENE_TYPES_SELECT:
            return {
                ...state,
                selectedItemGeneTypes: action.geneTypeName, //selectedItem: action.geneName,
            };
        
        case ActionTypes.HANDLE_CURRENT_GENE_TYPES_INPUT:
            return {
                ...state,
                currentGeneTypesInput: action.value, //currentGeneInput: action.value,
            };

        /* ANNOTATION */

        case ActionTypes.FETCH_ANNOTATION_REQUEST:
            return {
                ...state,
                loadingAnnotation: true,
            };
        case ActionTypes.FETCH_ANNOTATION_SUCCESS:
            return {
                ...state,
                suggestionAnnotation: action.annotations,
                loadingAnnotation: false,
            };
        case ActionTypes.FETCH_ANNOTATION_FAILURE:
            return {
                ...state,
                loadingAnnotation: true,
                snackOpenAnnotation: true,
                snackTextAnnotation: "An error occurred in the backend, annotation data was not sent to the web!",
            };

        /* AUTOSUGGEST ANNOTATION */

        case ActionTypes.HANDLE_ANNOTATION_SELECT:
            return {
                ...state,
                selectedItemAnnotation: action.annotationName, //selectedItem: action.geneName,
            };
        
        case ActionTypes.HANDLE_CURRENT_ANNOTATION_INPUT:
            return {
                ...state,
                currentAnnotationInput: action.value, //currentGeneInput: action.value,
            };


        /* TRACK_NAME_ADD and TRACK_NAME_DELETE in FirstWorkingPage.jsx*/
        case ActionTypes.TRACK_NAME_ADD:
            return {
                ...state,
                trackNameList: [...state.trackNameList, action.add_to_Track_name_list],
            };
        case ActionTypes.TRACK_NAME_DELETE:
            let track_name_list = state.trackNameList.slice();
            for (let i = 0; i < action.selected_list.length; i++) {
                track_name_list.splice(track_name_list.indexOf(action.selected_list[i]), 1)
            }
            return {
                ...state,
                trackNameList: track_name_list,
            };
        case ActionTypes.TRACK_NAME_SELECT:
            let selectedName = state.selectedTrackNames.slice()
            for (let i = 0; i < action.selected_list.length; i++) {
                const indexName = selectedName.indexOf(action.selected_list[i]);
                if (indexName === -1) {
                    selectedName.push(action.selected_list[i]);
                } else {
                    selectedName.splice(indexName, 1);
                }   
            }
            return {
                ...state,
                selectedTrackNames: selectedName,
            };

        
        /* CELL_TYPE_ADD and CELL_TYPE_DELETE in FirstWorkingPage.jsx*/
        case ActionTypes.CELL_TYPE_ADD:
            return {
                ...state,
                cellTypeList: [...state.cellTypeList, state.selectedCellType.join(', ')],
            };
        case ActionTypes.CELL_TYPE_DELETE:
            let cell_type_list = state.cellTypeList.slice();
            let index_list = action.selected_indexes.sort().reverse().slice()
            for (let i = 0; i < action.selected_indexes.length; i++) {
                cell_type_list.splice(index_list[i], 1)
            }
            return {
                ...state,
                cellTypeList: cell_type_list,
            };
        case ActionTypes.CELL_TYPE_SELECT_ONE: {
            const selected = state.selectedCellType.slice();
            const selectedIndex = selected.indexOf(action.cellType);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, action.cellType);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
            return {
                ...state,
                selectedCellType: newSelected,
            }
        }
        case ActionTypes.INDEX_FOR_CELL_TYPE_SELECT_ONE: {
            let selected = state.selectedIndexForCellType.sort().slice();
            let index_list = action.i.sort().reverse().slice()
            // console.log(index_list,'index_list')
            for (var index = 0; index < index_list.length; index++) {
                const selectedIndex = selected.indexOf(index_list[index]);
                // console.log('selectedIndex',selectedIndex)
                // console.log('index',index)
                // console.log('index_list[index]',index_list[index])
                // console.log('selected',selected)
                if (selectedIndex === -1) {
                    selected.push(index_list[index]);
                } else {
                    selected = selected.slice(0, selectedIndex).concat(selected.slice(selectedIndex + 1))
                }
            }
            return {
                ...state,
                selectedIndexForCellType: selected,
            }
        }











        /* TRACK_LIST_ADD and TRACK_LIST_DELETE in FirstWorkingPage.jsx*/
        case ActionTypes.TRACK_LIST_ADD:
            return {
                ...state,
                trackList: [...state.trackList, action.add_to_Track_list],
            };

        case ActionTypes.TRACK_LIST_DELETE:
            let track_list = state.trackList.slice();
            for (let i = 0; i < action.selected_list.length; i++) {
                track_list.splice(track_list.indexOf(action.selected_list[i]), 1)
            }
            return {
                ...state,
                trackList: track_list,
            };
        
        case ActionTypes.TRACK_LIST_SELECT:
            let selected = state.selectedTracks.slice()
            for (let i = 0; i < action.selected_list.length; i++) {
                const index = selected.indexOf(action.selected_list[i]);
                if (index === -1) {
                    selected.push(action.selected_list[i]);
                } else {
                    selected.splice(index, 1);
                }   
            }
            return {
                ...state,
                selectedTracks: selected,
            };

        case ActionTypes.TYPE_OF_CONTACTS_CHANGE: 
            return {
                ...state,
                typeOfContacts: action.newTypeOfContacts,
            };
        case ActionTypes.CLEAR_RNA:
            return {
                ...state,  
                selectedItem: [],
                currentGeneInput: "",
                selectedItemGeneTypes: [],
                currentGeneTypesInput: "",
            };



        case ActionTypes.CLEAR_RNA_GENE_SELECTOR_FROM_DNA:
            return {
                ...state,  
                selectedItem: [],
                currentGeneInput: "",
            };
        default:
            return state;
    }
};

export default genesReducer;