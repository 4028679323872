import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Grid from '@material-ui/core/Grid'; 
// import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

// import TableNewSuper from '../components/TableNewSuper';
import WarningMessage from '../components/MessageSnackbar';
import ColumnSelectorAllExperiments from '../components/ColumnSelectorAllExperiments';

import { fetchExpTable, handleHeader } from '../actions/generalActions';

const module = "table";
const moduleMenu = "menu";

// const headCells = [
//     {id:'Exp.ID', numeric: false, disablePadding: false, label: 'Exp.ID'},
//     {id:'Organism', numeric: false, disablePadding: false, label: 'Organism'},
//     {id:'Tissue', numeric: false, disablePadding: false, label: 'Tissue'},
//     {id:'Cell type', numeric: false, disablePadding: false, label: 'Cell type'},
//     {id:'Type of experiment', numeric: false, disablePadding: false, label: 'Type of experiment'},
//     {id:'Method', numeric: false, disablePadding: false, label: 'Method'},
//     {id:'Genome version', numeric: false, disablePadding: false, label: 'Genome version'},
//     {id:'Reference', numeric: false, disablePadding: false, label: 'Reference'},
//     {id:'GEO', numeric: false, disablePadding: false, label: 'GEO'},
//     {id:'Extra information', numeric: false, disablePadding: false, label: 'Extra information'},
// ];

const desc = (a, b, orderBy) => {
    // if (b[orderBy].length > 0 && a[orderBy].length > 0) {
        // if (isNaN(Number('1d234')) === true) {}
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    // }
  }
  
const stableSort = (array, cmp) =>{
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }


  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      textAlign: 'center',
      flexGrow: 1,
      // marginBottom: theme.spacing(5),
      // marginTop: 50,
      // maxHeight: window.innerHeight*0.7,
      paddingTop: "30px !important",
      marginTop: theme.spacing(3),
      // backgroundColor: theme.palette.background.paper,
      
    },
    container: {
      maxHeight: window.innerHeight*0.85,
    },
    table: {
      minWidth: 50,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    loading: {
      marginTop: 5,
      textAlign: 'center',
      // marginLeft: theme.spacing()
    },
    sizeSmall: {
      padding: '0px 5px 0px 5px',
    },
    wideButton: {
      // padding: theme.spacing(),
      marginLeft: theme.spacing(),
      // marginRight: theme.spacing(),
      marginTop: theme.spacing(),
      // marginBottom: theme.spacing(),
      width: '100%',
      height: '98%',
      color: 'white',
    },
}));

const AllExperiments = (props) => {
  
    // const [data_table_value, setData_table_value] = useState([])
    const [dataPerPage, setDataPerPage] = useState([])
    const [order,setOrder] = useState('asc')
    const [percentage, setPercentage] = useState('100%')
    const [orderBy, setOrderBy] = useState('Obtaining data method')
    const [columnsToDisplay, setColumnsToDisplay] = useState([]); 
    const [headCells, setHeadCells] = useState([]); 
    // console.log('props.loading',props.loading)
    // console.log('columnsToDisplay',columnsToDisplay)
    const classes = useStyles();    
    const { data } = props;

    useEffect(() => {
        document.title = "RNA-Chrom: content";
        props.handleHeader(': content') //Data list
        props.fetchExpTable(['all']); //props.logged
        // setColumnsToDisplay([headCells.map((key, index) => {key.label})])
    }, [])
    
    useEffect(() => {
        if (data.length !== 0) {
            setHeadCells(data['x'])
            // setData_table_value(data['z'])
            setDataPerPage(data['z'])
            setPercentage('99%') 
            // setColumnsToDisplay(data['x'])
            let selectedColumnsInit = [
                "Exp.ID",
                "Data type",
                "Method",
                "RNA name (RNA-Chrom DB)",
                "RNA type",
                "Cell line",
                "Cell type / Tissue",
                "Exp.description",
                "Organism",
                "RNA name (article)"
            ];
            if (columnsToDisplay.length === 0) {
                setColumnsToDisplay(data['x'].filter(cell => selectedColumnsInit.indexOf(cell) !== -1));
            }
        }
    }, [data])
  
    const handleRequestSort = (event, property) => {
        const const_orderBy = property;
        let let_order = 'desc';
        if (orderBy === property && order === 'desc') {
        let_order = 'asc';
        }
        setOrder(let_order)
        setOrderBy(const_orderBy)
    };

    const createSortHandler = property => event => {
            handleRequestSort(event, property);
        }; 
    
    let columnsMap = {};
    headCells.map(cell => {
        columnsMap[cell] = cell;
    })

    const filterDataForColumns = (data, cols) => {
        let colIds = columnsToDisplay.slice();
        if (cols !== undefined) {
            colIds = cols.slice();
        }
        let newData = data.map((row, i) => {
            let newRow = {};
            colIds.map(id => newRow[id] = row[id]);
            return newRow;
        })
        return newData;
    }

    const handleColumnsChange = (cols) => {
        // console.log('handleColumnsChange cols',cols)
        // console.log('handleColumnsChange headCells',headCells)
        let newColumns = headCells.filter(cell => cols.indexOf(cell) !== -1);
        setColumnsToDisplay(newColumns);
        // console.log('handleColumnsChange newColumns',newColumns)
        let DATA = filterDataForColumns(data['z'], newColumns);
        // console.log('DATA',DATA)
        setDataPerPage(DATA.slice());
    }

    const onClick = (id) => {
        const data = {
          selectedExperiment: [parseInt(id.split('___')[0],10)],
          dataType: id.split('___')[1]
        }
        localStorage.setItem("metadata",  JSON.stringify(data));
        window.open("/metadata", "_blank");
    }

    const onClickGSE = (GSE) => {
        if (GSE.split("ERR").length > 1) {
            window.open("https://www.ebi.ac.uk/ena/browser/view/PRJEB7307", "_blank");
        } else {
            window.open(`https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${GSE}`, "_blank");
        }
    }

    let headCell = null
    let column = null

    return (
        <div className={classes.root}>
            {props.loading === true || dataPerPage.length === 0 || columnsToDisplay.length === 0 ? 
                <div className={classes.loading}>
                    <LinearProgress color={"primary"} />
                    <Typography variant="h5">
                    Please, wait a while, it may take from a few seconds to a couple of minutes
                    </Typography> 
                </div>
                :
                <div style={{width: percentage, margin: '0 auto', border: '1px solid rgb(224, 224, 224)'}}>
                    <div className={classes.tableWrapper}>
                        <ColumnSelectorAllExperiments
                            // selectedColumnsInit={data['x']}
                            // columns={columnsToDisplay} 
                            selectedColumnsInit={columnsToDisplay}
                            columns={headCells} 
                            columnsMap={columnsMap} 
                            setColumns={handleColumnsChange}
                            data={data['z']}
                        />
                        <TableContainer className={classes.container}>
                            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={'small'}>
                                <TableHead>
                                    <TableRow > 
                                        {columnsToDisplay.map((row, index) => {
                                            if (row === "Exp.ID") {
                                                headCell=
                                                <Tooltip
                                                    placement={"right-start"}
                                                    title={
                                                    <React.Fragment>
                                                        <div >
                                                        <Typography>
                                                            <b>Exp.ID</b> — unique identifier of the corresponding RNA-chromatin interactions experiment in the database
                                                        </Typography>
                                                        </div>
                                                    </React.Fragment>
                                                    }
                                                >
                                                    <TableCell
                                                        className={classes.sizeSmall}
                                                        style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',whiteSpace: 'nowrap'}} //
                                                        key={row + index}
                                                        // size="small"
                                                        align={row.numeric ? 'right' : 'center'}
                                                        padding={row.disablePadding ? 'none' : 'default'}
                                                        sortDirection={orderBy === row ? order : false}
                                                    >
                                                        <TableSortLabel
                                                            active={orderBy === row}
                                                            direction={orderBy === row ? order : 'asc'}
                                                            onClick={createSortHandler(row)}
                                                        >
                                                            {row}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                </Tooltip>
                                            } else {                                        
                                                headCell =
                                                    <TableCell
                                                        className={classes.sizeSmall}
                                                        style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',whiteSpace: 'nowrap'}} //
                                                        key={row + index}
                                                        // size="small"
                                                        align={row.numeric ? 'right' : 'center'}
                                                        padding={row.disablePadding ? 'none' : 'default'}
                                                        sortDirection={orderBy === row ? order : false}
                                                    >
                                                        <TableSortLabel
                                                            active={orderBy === row}
                                                            direction={orderBy === row ? order : 'asc'}
                                                            onClick={createSortHandler(row)}
                                                        >
                                                            {row}
                                                        </TableSortLabel>
                                                    </TableCell>
                                            }
                                        return (
                                            headCell
                                        );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stableSort(dataPerPage, getSorting(order, orderBy)).map((row, index) => {
                                        return (
                                            <TableRow 
                                                hover 
                                                tabIndex={-1} 
                                                key={row.Method + index} 
                                                size='small'
                                                className={classes.sizeSmall}
                                            >
                                            {columnsToDisplay.map((key, index) => {
                                                if (key === "GSE") {
                                                    column=
                                                        <TableCell 
                                                            key={index}
                                                            align="center" 
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                    // whiteSpace: 'nowrap', 
                                                                    // backgroundColor: cellColor,
                                                                    border: '1px solid #d3d4d5',
                                                                    borderTop: '1px solid #d3d4d5',
                                                                    borderLeft: '1px solid #d3d4d5',
                                                                }} 
                                                        >
                                                            <Link style={{fontSize: 15}} component="button" onClick={() => onClickGSE(row[key])}>
                                                                {row[key]}
                                                            </Link>
                                                        </TableCell>
                                                } else  if (key === "Exp.ID") {
                                                    column=
                                                                <TableCell 
                                                                    key={index}
                                                                    align="center" 
                                                                    className={classes.sizeSmall}
                                                                    style={{fontSize: 15, 
                                                                            // whiteSpace: 'nowrap', 
                                                                            // backgroundColor: cellColor,
                                                                            border: '1px solid #d3d4d5',
                                                                            borderTop: '1px solid #d3d4d5',
                                                                            borderLeft: '1px solid #d3d4d5',
                                                                        }} 
                                                                >
                                                                <Tooltip title="Go to Metadata page">
                                                                    <Link style={{fontSize: 15}} component="button" onClick={() => onClick(row[key] + '___' + row["Data type"])}>
                                                                        {row[key]}
                                                                    </Link>
                                                                </Tooltip>
                                                            </TableCell>                                             
                                                } else {
                                                    column=
                                                        <TableCell 
                                                            key={index}
                                                            align="center" 
                                                            className={classes.sizeSmall}
                                                            style={{fontSize: 15, 
                                                                    // whiteSpace: 'nowrap', 
                                                                    // backgroundColor: cellColor,
                                                                    backgroundColor: row[key] === "None" || row[key] === "Unknown" ? '#edeeee' : null,
                                                                    border: '1px solid #d3d4d5',
                                                                    borderTop: '1px solid #d3d4d5',
                                                                    borderLeft: '1px solid #d3d4d5',
                                                                }} 
                                                        >
                                                            {key === "Data type" ? 
                                                                row[key] === "All vs all" ? 
                                                                "All-to-all"
                                                                : 
                                                                "One-to-all"
                                                            :
                                                                row[key] === "None" || row[key] === "Unknown" ? 
                                                                "—"
                                                                : 
                                                                row[key]
                                                            }
                                                        </TableCell>
                                                }
                                                return (
                                                    column
                                                );
                                                
                                            })}
                                        </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            }   
        <WarningMessage open={props.snackOpen} severity={"error"} message={props.snackText} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        data: state[module].expData,
        loading: state[module].loading,
        snackOpen: state[module].snackOpen,
        snackText: state[module].snackText,
        // logged: state[moduleMenu].isLogged,
    }
}

export default connect(mapStateToProps, { fetchExpTable, handleHeader })(AllExperiments);