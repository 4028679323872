import React, { useState, useEffect } from 'react'; //useState,
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'; //lighten
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
// import Toolbar from '@material-ui/core/Toolbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';

import WarningMessage from './MessageSnackbar';

import {submitCheckedTracks, clearGenomeBrowserURL} from '../actions/genomeBrowserActions';

const moduleGenomeBrowser = "genomeBrowser";
const module = "genes";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(),
    flexGrow: 1,
    width: '100%',
    // backgroundColor: lighten(theme.palette.secondary.light, 0.85), //theme.palette.background.paper
  },
  loading: {
    marginTop: 10,
    textAlign: 'center',
  },
  // paper: {
  //   //marginTop: 50,
  //   //margin: '0 auto',
  //   padding: 20,
  //   width: '100%',
  //   textAlign: 'center',
  //   marginBottom: 0,
  // },
  // wideButton: {
  //   paddingTop: theme.spacing(2),
  //   paddingBottom: theme.spacing(2),
  //   width: '90%',
  //   marginBottom: 25,
  //   marginTop: 25,
  // },
  wideButton: {
    padding: theme.spacing(),
    // paddingTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
    width: '90%',
    height: '80%',
    color: 'white',
  },
}));

const RnaListButtons = (props) => {
  
  const { RnaSelected } = props;
  const classes = useStyles();
  
  useEffect(() => {
    // console.log('!!!!!!')
    if (props.URLtoGenomeBrowser !== '') {
      // console.log('props.URLtoGenomeBrowser',props.URLtoGenomeBrowser)
      openRequestedPopup()
      props.clearGenomeBrowserURL()
    }
  }, [props.URLtoGenomeBrowser])
    

  const openRequestedPopup = () => {
    // console.log('??')
    let empty_tracks = ''
    let i = []
    let url_massege = ''
    if (props.URLtoGenomeBrowser.split("|||").length === 1) {
      i = props.URLtoGenomeBrowser.split("_____")
      url_massege = 'Otherwise manually copy and paste these links: ' + i.toString()
    } else {
      i = props.URLtoGenomeBrowser.split("|||")[0].split("_____")
      if (i !== '') {
        url_massege = 'Otherwise manually copy and paste these links: ' + i.toString()
      }
      empty_tracks = props.URLtoGenomeBrowser.split("|||")[1]
      empty_tracks = "       (Tracks: " + empty_tracks + " — don't have contacts with chromatin)"
    }
    for (var k in i) {
      // console.log('i',i)
      if (i[k] !== '') {
        // console.log('i123452123rtsdvdv______')
        var newWin = window.open(i[k])
        if (!newWin || newWin.closed || typeof newWin.closed=='undefined') { 
          alert('You need to allow pop-up windows to open. ' + url_massege + empty_tracks)
          break;
        } else {
          // console.log('oooooooooooooo')
          newWin.focus()
        }
      }
    }
    if (empty_tracks !== '') {
      alert(empty_tracks)
    }
  }

  const handleNewWindowClick = (event, label) => {
    if (RnaSelected.length > 0) {
      // console.log('props.selectedNormalization',props.selectedNormalization)
      if (label === "Graphical summary (contacts)") {
        const data = {
          selectedTracks: RnaSelected,
          typeOfContacts: 'DNA_part___fromRnaList',
          selectedTrackNames: props.selectedTrackNames,
          selectedNormalization: props.selectedNormalization,
        }
        localStorage.setItem("data_graphical_summary",  JSON.stringify(data));
        window.open("/graphical_summary", "_blank");
      }
    } else {
      setMessage('Choose some RNAs from the "RNA-list"')
      setOpen(true)
    }
    // if (label === "Graphical summary (RNA)") {
    //   const data = {
    //     selectedTracks: RnaSelected,
    //     typeOfContacts: 'RNA_part__fromRnaList',
    //   }
    //   localStorage.setItem("data",  JSON.stringify(data));
    //   window.open("/graphical_summary", "_blank");
    // }
  }
  
  const handleClick = (e,label) => {   
    if (RnaSelected.length > 0) { 
      if (label === "Send tracks to Genome Browser (contacts)") {
        props.submitCheckedTracks(
            RnaSelected,
            'DNA_part___fromRnaList',
            props.selectedTrackNames, 
            props.selectedNormalization
          )
      }
    } else {
      setMessage('Choose some RNAs from the "RNA-list"')
      setOpen(true)
    }
    // if (label === "Send tracks to Genome Browser (RNA)") {
    //   props.submitCheckedTracks(RnaSelected,'RNA_part__fromRnaList')
    // }
  };

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const handleClose = () => {
    setOpen(false)
    setMessage('')
  }

  const [buttonColor, setButtonColor] = useState('#bdbdbd')
  const [buttonTextColor, setButtonTextColor] = useState('black')

  useEffect(() => {
    if (RnaSelected.length > 0) {
      setButtonColor('#96000E')
      setButtonTextColor('white')
    } else {
      setButtonColor('#bdbdbd')
      setButtonTextColor('black')
    }
  }, [RnaSelected])

  return (
    <div className={classes.root}> 
        <Grid 
            container 
            spacing={2}
            direction="row"
            // justify="space-between"
            // alignItems="stretch"
        >
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    style={{backgroundColor: buttonColor, color: buttonTextColor}} 
                    className={classes.wideButton}
                    onClick={e => handleNewWindowClick(e, "Graphical summary (contacts)")}
                    >
                    Graphical summary
                </Button>
            </Grid>
            {/* <Grid item xs={3}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.wideButton}
                    // onClick={e => handleNewWindowClick(e, "Graphical summary (RNA)")}
                    >
                    Graphical summary (RNA)
                </Button>
            </Grid> */}
            <Grid item xs={6}>
                

                {!props.loading ?
                  <Button
                    variant="contained"
                    style={{backgroundColor: buttonColor, color: buttonTextColor}} 
                    className={classes.wideButton}
                    onClick={e => handleClick(e, "Send tracks to Genome Browser (contacts)")}
                    >
                      Genome Browser
                  </Button>
                  :
                  <div className={classes.loading}>
                    <LinearProgress color={"primary"} />
                      <Typography variant="subtitle1">
                        Please, wait a while
                      </Typography>
                  </div>
                }

            </Grid>
            {/* <Grid item xs={4}>
                <Button
                    disabled
                    size="small"
                    variant="contained"
                    className={classes.wideButton}
                    >
                    Interaction with all genes
                </Button>
            </Grid> */}
            {/* <Grid item xs={3}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.wideButton}
                    // onClick={e => handleClick(e, "Send tracks to Genome Browser (RNA)")}
                >
                    RNA on the Genome Browser
                </Button>
            </Grid> */}
        </Grid>
        <WarningMessage open={open} severity={"warning"} handleClose={handleClose} message={message} />
        <WarningMessage open={props.snackOpenGenomeBrowser} severity={"error"} message={props.snackTextGenomeBrowser} />
    </div>
  );
}


const mapStateToProps = (state) => {
  return {      
    URLtoGenomeBrowser: state[moduleGenomeBrowser].URLtoGenomeBrowser,
    loading: state[moduleGenomeBrowser].loadingURL,
    snackOpenGenomeBrowser: state[moduleGenomeBrowser].snackOpenGenomeBrowser,
    snackTextGenomeBrowser: state[moduleGenomeBrowser].snackTextGenomeBrowser,
    selectedTrackNames: state[module].selectedTrackNames,
  }
}



export default connect(mapStateToProps, {submitCheckedTracks, clearGenomeBrowserURL})(RnaListButtons);