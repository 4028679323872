import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import MenuItem from '@material-ui/core/MenuItem';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

// import { Link } from 'react-router-dom';
// import Autocomplete from '@material-ui/lab/Autocomplete';

import RNAAutocomplete from './RNAAutocomplete';

// import InputsTwo from './TextAdd_two';
import CustomTableWrapper from '../components/TableRnaList';
import { handleHeader } from '../actions/generalActions';

const moduleTable = "table";

const useStyles = makeStyles(theme => ({
  root: {
    // ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    // backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    flexGrow: 1,
    width: '98%',
    },
  paper: {
    '& > * + *': {
        marginTop: 0,
      },
    // marginTop: 50,
    //margin: '0 auto',
    // paddingRight: theme.spacing(),
    width: '100%',
    // paddingLeft: 20,
    textAlign: 'center',
    // marginBottom: 0,
  },
  locationTop: {
    marginTop: 35,
    textAlign: 'center !important',
  },
  wideButton: {
    marginTop: 10,
    marginBottom: 10,
    width: '50%',
    // height: '100%',
  },
  textField: {
    textAlign: 'center !important',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    // marginLeft: 10,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '90%',
    height: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // height: 40,
  },
  textFieldLocus: {
    textAlign: 'left',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    // marginLeft: 10,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '90%',
    height: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // height: 40,
  },
  reduceHeight: {
    height: 40,
  },
  inputRoot: {
      padding: 0,
  },
  icon: {
    color: 'white',
    float: 'right',
  },
  vertical: {
    padding: '10 10',
    marginBottom: 10,
    marginTop: 10,
    // height: 45,
  },
  autocomplete: {
      width: '90%',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
  }
}));

const RnaListFilter = (props) => {

    // const [disabledAutocomplete, setDisabledAutocomplete] = useState(false)
    // const [disabledNormalizedCount, setDisabledNormalizedCount] = useState(false)
    // const [disabledLength, setDisabledLength] = useState(false)
    // const [disabledCPKM, setDisabledCPKM] = useState(false)

    const [value, setValue] = useState({'value': '', 'from': ''});
    const [currentValue, setCurrentValue] = useState('');
    const [geneType, setGeneType] = useState([]);
    const [geneTypeInput, setGeneTypeInput] = useState('');
    const [trackName,setTrackName] = useState([]);
    const [trackNameInput, setTrackNameInput] = useState('');
    const [currentMinLength, setCurrentMinLength] = useState('')
    const [currentMaxLength, setCurrentMaxLength] = useState('')
    const [currentMinCPKM, setCurrentMinCPKM] = useState('')
    const [currentLocusFilter, setCurrentLocusFilter] = useState('')
    const [filter_parameters, setFilterParameters] = useState({'value': '','minLength': '','maxLength': '','geneType': [],'trackName': [],'cpkm': '', 'locusFilter': ''})
    // const [filter_parameters, setFilterParameters] = useState({'value': '','minLength': '','maxLength': '','geneType': [],'trackName': [],'cpkm': '', locusFilter: ''})

    const { RnaListGeneType, RnaListTrackName } = props
    const classes = useStyles()
    // console.log('RnaListGeneType',RnaListGeneType)
//   console.log('dataTableValue!!!',dataTableValue)
//   console.log('RnaListData!!!',RnaListData)

    useEffect(() => {
        document.title = "RNA list";
        props.handleHeader(': RNA list')
    }, [])

    useEffect(() => {
        if ((isNaN(Number(value['value']))) && !(value['value'] === '.' && currentValue[0] === '.') && !(value['value'] === '.' && currentMinCPKM[0] === '.')) {
            setValue('')
        } else {
            if ((value['value'] !== '' && value['value'] !== ' ') || (currentValue.length === 1 && value['value'] === '') || (currentMinLength.length === 1 && value['value'] === '') || (currentMaxLength.length === 1 && value['value'] === '') || (currentMinCPKM.length === 1 && value['value'] === '')){
                if (value['from'] === 'N_contacts' || value['from'] === 'min_cpkm') {
                    if (!(currentValue === '0' && value['value'] === '00') || !(currentMinCPKM === '0' && value['value'] === '00')) {
                        if (value['from'] === 'N_contacts') {
                            (currentValue === '' && value['value'] === '0') ? setCurrentValue(value['value']+'.') : setCurrentValue(value['value']) 
                        }
                        if (value['from'] === 'min_cpkm') {
                            (currentMinCPKM === '' && value['value'] === '0') ? setCurrentMinCPKM(value['value']+'.') : setCurrentMinCPKM(value['value']) 
                        }
                    }
                } else {
                    if (!((currentMinLength === '' && value['value'] === '0') || (currentMaxLength === '' && value['value'] === '0'))) {
                        if (value['from'] === 'min_length') {
                            setCurrentMinLength(value['value'])
                        }
                        if (value['from'] === 'max_length') {
                            setCurrentMaxLength(value['value'])
                        }
                    }
                }
            }
        }
        }, [value])
    // console.log('value',value)
    // console.log('currentValue',currentValue)
    // console.log('currentMinLength',currentMinLength)
    // console.log('currentMaxLength',currentMaxLength)
    // console.log('currentMinLength',currentMinCPKM)
    // console.log('currentMaxFDR',currentMaxFDR)
    // console.log('geneType',geneType)

    
    // useEffect(() => {
    //     props.selectedColumns.indexOf("Gene type") === -1 ? setDisabledAutocomplete(true) : setDisabledAutocomplete(false)
    //     props.selectedColumns.indexOf("Normalized count") === -1 ? setDisabledNormalizedCount(true) : setDisabledNormalizedCount(false)
    //     props.selectedColumns.indexOf("Length") === -1 ? setDisabledLength(true) : setDisabledLength(false)
    //     props.selectedColumns.indexOf("CPKM") === -1 ? setDisabledCPKM(true) : setDisabledCPKM(false)              
    // }, [props.selectedColumns])
    
    const handleClick = (e) => {
        setFilterParameters({'value': currentValue,'minLength': currentMinLength,'maxLength': currentMaxLength,'cpkm': currentMinCPKM, 'locusFilter': currentLocusFilter, 'geneType': geneType, 'trackName': trackName})
    }
    
    const clearFilters = () => {
        handleGeneType([]);
        handleTrackName([]);
        handleGeneTypeInput('');
        handleTrackNameInput('');
        setCurrentValue('');
        setCurrentMinLength('');
        setCurrentMaxLength('');
        setCurrentMinCPKM('');
        setCurrentLocusFilter('');
    }


  const handleGeneType = (gt) => {
    setGeneType(gt);
  };

  const handleGeneTypeInput = (value) => {
    setGeneTypeInput(value);
};
  const handleTrackName = (tn) => {
      setTrackName(tn);
  };

  const handleTrackNameInput = (value) => {
      setTrackNameInput(value);
  };
  
  return (
    <div className={classes.root}>
        {/* <Typography 
            variant="h5" 
            component="h1" 
            className={classes.locationTop}>
                RNA list!
        </Typography> */}
        <div className={classes.paper}>
            <Grid
                container 
                spacing={0}
                // direction="row"
                // justify="space-between"
                // alignItems="stretch"
            >
                <Grid item xs={3}>
                    <div className={classes.locationTop}>
                        <Toolbar variant="dense" style={{backgroundColor: '#009688'}} > {/* className={classes.root} */}
                            <Typography variant="h5" component="h1" style={{color: 'white',whiteSpace: 'nowrap'}}>
                                Filter
                            </Typography>
                            <Tooltip title="Clear all filters" style={{marginLeft: 180}}>
                                <IconButton className={classes.icon} onClick={clearFilters}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                        <Paper className={classes.paper} square>
                            {/* <Typography variant="h5" component="h1">
                                {'Gene Type:'}
                            </Typography> */}
                            <br />
                            <RNAAutocomplete
                                nameClass={classes.autocomplete}
                                disabled={props.loadingRnaList}
                                suggestions={RnaListTrackName}
                                handleChange={handleTrackName}
                                selectedItems={trackName}
                                currentInput={trackNameInput}
                                onInputChange={handleTrackNameInput}
                                title={"Select track names"}
                            />
                            <RNAAutocomplete
                                nameClass={classes.autocomplete}
                                disabled={props.loadingRnaList}
                                suggestions={RnaListGeneType}
                                handleChange={handleGeneType}
                                selectedItems={geneType}
                                currentInput={geneTypeInput}
                                onInputChange={handleGeneTypeInput}
                                title={"Select gene types"}
                            />
                            {/* <br /> */}
                            {/* <Autocomplete
                                multiple
                                // clearOnEscape={true}
                                // disabled={disabledAutocomplete || props.loadingRnaList}
                                value={geneType}
                                disabled={props.loadingRnaList}
                                id="tags-outlined"
                                options={RnaListGeneType}
                                filterSelectedOptions
                                onChange={handleCh}
                                // className={classes.vertical}
                                // style={{padding: '10 10',marginBottom: 10, marginTop: 10, height: 45}}
                                // classes={{
                                //     inputRoot: classes.inputRoot,
                                //     root: classes.reduceHeight,
                                // }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    className={classes.textField}
                                    variant="outlined"
                                    label="Select Gene Type"
                                    placeholder="next Gene Type"
                                    // margin="normal"
                                    fullWidth
                                />
                                )}
                            /> */}
                            {/* <br />
                            <Autocomplete
                                multiple
                                value={trackName}
                                disabled={props.loadingRnaList}
                                id="track name"
                                options={RnaListTrackName}
                                filterSelectedOptions
                                onChange={handleChTrackName}
                                className={classes.vertical}
                                // classes={{
                                //     inputRoot: classes.inputRoot,
                                //     root: classes.reduceHeight,
                                // }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    className={classes.textField}
                                    variant="outlined"
                                    label="Select Track Name"
                                    placeholder="next Track Name"
                                    // margin="normal"
                                    fullWidth
                                />
                                )}
                            /> */}
                            {/* <br /> */}
                            {/* <br /> */}
                            {/* <Typography variant="h5" component="h1">
                                {'N contacts >'}
                            </Typography> */}
                            <TextField
                                // disabled={disabledNormalizedCount || props.loadingRnaList}
                                disabled={props.loadingRnaList}
                                value={currentValue}
                                label={"Minimum Normalized Count"}
                                className={classes.textField}
                                onChange={(event) => setValue({'value': event.target.value, 'from': 'N_contacts'})}
                                />
                            {/* <br /> */}
                            {/* <Typography variant="h5" component="h1">
                                {'Length >'}
                            </Typography> */}
                            <TextField
                                // disabled={disabledLength || props.loadingRnaList}
                                disabled={props.loadingRnaList}
                                value={currentMinLength}
                                label={"Minimum Length"}
                                className={classes.textField}
                                onChange={(event) => setValue({'value': event.target.value, 'from': 'min_length'})}
                                />
                            {/* <br /> */}
                            {/* <Typography variant="h5" component="h1">
                                {'Length <'}
                            </Typography> */}
                            <TextField
                                // disabled={disabledLength || props.loadingRnaList}
                                disabled={props.loadingRnaList}
                                value={currentMaxLength}
                                label={"Maximum Length"}
                                className={classes.textField}
                                onChange={(event) => setValue({'value': event.target.value, 'from': 'max_length'})}
                                />
                            {/* <br /> */}
                            {/* <Typography variant="h5" component="h1">
                                {'CPKM >'}
                            </Typography> */}
                            {/* <TextField
                                value={currentMinCPKM}
                                label={"Min Expected Value"} // {"Min CPKM"}
                                className={classes.textField}
                                onChange={(event) => setValue({'value': event.target.value, 'from': 'min_cpkm'})}
                                />
                            <br />
                            <br /> */}
                            {/* <Typography variant="h5" component="h1">
                                {'FDR <'}
                            </Typography> */}
                            <TextField
                                // disabled={disabledCPKM || props.loadingRnaList}
                                disabled={props.loadingRnaList}
                                value={currentMinCPKM}
                                label={"Minimum CPKM"}
                                className={classes.textField}
                                onChange={(event) => setValue({'value': event.target.value, 'from': 'min_cpkm'})}
                                />
                            {/* <br /> */}
                            <TextField
                                disabled={props.loadingRnaList}
                                value={currentLocusFilter}
                                label={"Gene locus filter: chr2; chrX:1-900000"}
                                className={classes.textFieldLocus}
                                onChange={(event) => setCurrentLocusFilter(event.target.value)}
                                />
                            {/* <br /> */}
                            <br />
                            <Button
                                disabled={props.loadingRnaList}
                                variant="contained"
                                color ="primary"
                                className={classes.wideButton}
                                onClick={e => handleClick()}
                            >
                                Apply
                            </Button>
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <div>
                        <CustomTableWrapper filter_parameters={filter_parameters}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    </div>
  );
}


const mapStateToProps = (state) => {
    return {
        selectedColumns: state[moduleTable].selectedColumns,
        RnaListGeneType: state[moduleTable].RnaListGeneType,
        RnaListTrackName: state[moduleTable].RnaListTrackName,
        loadingRnaList: state[moduleTable].loadingRnaList,
    }
}


export default connect(mapStateToProps, {handleHeader})(RnaListFilter);