import React, { useState, useEffect } from 'react';
// import { CSVDownload} from 'react-csv'; //CSVLink,
// import axios from 'axios';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CircularProgress from '@material-ui/core/CircularProgress';

// import Checkbox from '@material-ui/core/Checkbox';
// import classNames from 'classnames';
// import Typography from '@material-ui/core/Typography';
// import Toolbar from '@material-ui/core/Toolbar';

// import ShowGraphics from '../components/ShowGraphics';
import WarningMessage from '../components/MessageSnackbar';
// import TrackListCheckboxes from '../components/TrackListCheckboxes';

// import {trackListDelete, trackListSelect, trackNameDelete, trackNameSelect, indexForCellTypeSelect, cellTypeDelete} from '../actions/genesActions';
// import {changeDecreaseMaxNumberTrackName} from '../actions/filterActions';

const module = "genes";
const moduleFilter = "filter";

const EnhancedTableHead = (props) => {
    
    const { classes } = props;
    let geneTarget = ""
    if (props.geneTarget.length !== 0) {
        geneTarget = ": " + props.geneTarget
    }

    return (
            <TableHead className={classes.sizeSmall}>
                <TableRow size='small'>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Organism</TableCell>
                    <TableCell align="center" colSpan={1} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#bbdefb', color: 'black'}}>Target locus</TableCell>
                    <TableCell align="center" colSpan={3} style={{whiteSpace: 'nowrap', padding: 5, border: '1px solid #d3d4d5', borderTop: '1px solid #d3d4d5', borderLeft: '1px solid #d3d4d5',backgroundColor: '#f0f4c3', color: 'black'}}>Target gene{geneTarget}</TableCell>
                </TableRow>
            </TableHead>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        // ...theme.mixins.gutters(),
        paddingTop: theme.spacing(),
        // paddingBottom: theme.spacing(),
        textAlign: 'center',
        flexGrow: 1,
        width: '100%',
    },
    track_list_selected: {
        textAlign: 'left',
        padding: 0,
        marginTop: theme.spacing(),
        // marginBottom: theme.spacing(),
        // paddingBottom: theme.spacing(4),
    },
    download: {
        textAlign: 'right',
        padding: 0,
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(),
        // paddingTop: 10
    },
    locationTop: {
        // paddingLeft: theme.spacing(),
        // marginTop: 10,
        // marginBottom: 10,
        paddingBottom: theme.spacing(),
        textAlign: 'center !important',
    },
    sizeSmall: {
        padding: '0px 5px 0px 5px',
    },
    stickyHeader: {
        width: '100%',
        'border-collapse': 'inherit',
    },
    tableWrapper: {
        overflowX: 'auto',
        margin: '0 auto',
        maxWidth: '100%',
        
    },
    container: {
        maxHeight: window.innerHeight*0.7,
        '& table:nth-child(1)': {
            '& tr': {
                '& td:nth-child(1), td:nth-child(1)': {
                    color: 'white',
                    backgroundColor: '#009688',
                    position: 'sticky',
                    left: 0,
                    zIndex: 99
                },
                '& th:nth-child(2)': {
                    zIndex: 999
                },
            },
            '& thead': {
                '& tr:nth-child(1)': {
                    '& th:nth-child(1), th:nth-child(1)': {
                        backgroundColor: '#009688',
                        color: 'white',
                    },
                },
                '& tr:nth-child(2)': {
                    '& th:nth-child(1), th:nth-child(1)': {
                        color: 'white',
                        backgroundColor: '#009688',
                        position: 'sticky',
                        left: 0,
                        zIndex: 10
                    },
                    '& th:nth-child(1)': {
                        zIndex: 11
                    },
                }
            }
        },
    },
}));


const TableFromDna = (props) => {

    const classes = useStyles();

    const [rows, setRows] = useState([]);
    // console.log('rowsrows TrackListTable',rows)
    const remake_track_list_data = () => {
        // console.log('remake_track_list_data, TableFromDna')
        let rows_array = []
        let target_locus = props.currentLocusCoordinatesForDNA
        let target_gene = props.currentGeneNameForDNA
        let gene_body = ''
        let upstream = props.currentValueUpstream
        let downstream = props.currentValueDownstream

        if (target_locus.length === 0 && target_gene.length === 0 && props.startAnalysis === 1) {
            target_locus = 'whole genome'
        }
        if (target_gene.length !== 0) {
            gene_body = props.geneBody === true ? 'Gene body: yes' : 'Gene body: no'
            upstream = upstream.length !== 0 ? "Upstream: " + upstream + " bp" : upstream = "Upstream: no"
            downstream = downstream.length !== 0 ? downstream = "Downstream: " + downstream + " bp" : downstream = "Downstream: no"
        }
        
        rows_array.push({
            organism: props.currentOrganism,
            targetLocus: target_locus,
            geneBody: gene_body,
            currentValueUpstream: upstream,
            currentValueDownstream: downstream
                    })
        setRows(rows_array)
    }

    useEffect(() => {
        remake_track_list_data()
      }, [props.currentGeneNameForDNA, props.currentValueUpstream, props.currentValueDownstream, props.currentLocusCoordinatesForDNA, props.currentOrganism, props.geneBody]);
    
    

    let column = null;
    let table = 
        <div className={classes.locationTop}>
        {/* <Paper elevation={0} style={{border: '2px solid #009688'}} square> */}
            <div className={classes.tableWrapper}> 
                <TableContainer className={classes.container}>
                    <Table
                        className={classes.stickyHeader}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        // stickyHeader
                        aria-label="enhanced table"
                        style={{ width: "auto", tableLayout: "auto",}}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            geneTarget={props.currentGeneNameForDNA}
                        />
                        <TableBody>
                        {rows.map((n, indexR) => {
                            // const isSelectedID = isSelected(row.description);
                            return (
                                <TableRow
                                    hover 
                                    tabIndex={-1} 
                                    className={classes.sizeSmall}
                                    // padding="none"
                                    key={indexR}
                                >
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}><i>{n.organism}</i></TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>{n.targetLocus}</TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>{n.geneBody}</TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>{n.currentValueUpstream}</TableCell>
                                    <TableCell className={classes.sizeSmall} size='small' align="center" style={{backgroundColor: 'white', color: 'black', fontSize: 15,whiteSpace: 'nowrap', border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5',}}>{n.currentValueDownstream}</TableCell>
                                    {/* {Object.keys(row).map((key, index) => {
                                        if (key === "organism") {
                                            column=
                                                <TableCell 
                                                    key={index}
                                                    // padding="none" 
                                                    // size='small' 
                                                    align="center" 
                                                    className={classes.sizeSmall}
                                                    style={{fontSize: 15, 
                                                            // whiteSpace: 'nowrap', 
                                                            border: '1px solid #d3d4d5',
                                                            borderTop: '1px solid #d3d4d5',
                                                            borderLeft: '1px solid #d3d4d5',
                                                        }} 
                                                >
                                                        <i>{row[key]}</i>
                                                </TableCell>
                                        } else {
                                            column=
                                                <TableCell 
                                                    key={index}
                                                    align="center" 
                                                    className={classes.sizeSmall}
                                                    style={{fontSize: 15, 
                                                            border: '1px solid #d3d4d5',
                                                            borderTop: '1px solid #d3d4d5',
                                                            borderLeft: '1px solid #d3d4d5',
                                                        }} 
                                                >
                                                        {row[key]}
                                                </TableCell>
                                        } 
                                    return (
                                        column
                                        );
                                    })} */}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        {/* </Paper> */}
        </div>
    return (
        <div className={classes.root}>
            <div className={classes.paper}>
                {table}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        // trackNameList: state[module].trackNameList,
        // selectedTrackNames: state[module].selectedTrackNames,
        // // cellTypeList: state[module].cellTypeList,
        // // selectedIndexForCellType: state[module].selectedIndexForCellType,

        // trackList: state[module].trackList,
        // selectedTracks: state[module].selectedTracks,
        // trackListCheckbox: state[moduleFilter].trackListCheckbox,
    }
}

export default connect(mapStateToProps, {})(TableFromDna);