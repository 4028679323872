import React from 'react';
// import Plotly from 'plotly.js'
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// const module = "table";

const MetadataPiePlot = (props) => {

  const { data } = props; // classes
  // console.log('procMeta',data)
//   let processing_metadata_label = [];
//   let processing_metadata_value = [];
//   let download = procMeta[0]['Nreads']; // always first
//   for (var i in procMeta) {
//     // console.log('procMeta[i][0]',procMeta[i])
//     processing_metadata_label.push(preprocessLabel(procMeta[i]['Processingstages']));
//     processing_metadata_value.push(procMeta[i]['Nreads']/download * 100);
//   }
  // console.log("VL", processing_metadata_value);
  // console.log("LBL", processing_metadata_label);
  return (
    <div>
      <Typography variant="h6">
        Shares of different RNA types in the total number of contacts (%)
      </Typography>
        <Plot
          data = {[{
            // values: data['x'],//[46.70071186628319,36.55533160315582,0.2824746474026565,0.13551036788266427,3.0851479865955183,0.11123819551330608,0.14672782387099698,3.493920689795986,0.28600965935513484,1.6566153705299036,4.893137534499175,2.0365499427789153],
            // labels: data['y'],//["protein_coding","Xrna","snoRNA","pseudogene_P1","rRNA","pseudogene_TU3","pseudogene_U1","ncRNA","pseudogene_TU2","vlinc","lncRNA","snRNA"],
            // textinfo: "label+value",
            // textposition: "outside",
            // hoverinfo: 'label+value',
            // automargin: true,
            // type: 'pie'
            y: data['x'],
            x: data['y'],
            mode: 'lines+markers',
            // name: 'GHG Emissions',
            // rotation : -45,
            type: 'bar',
          }]}
          layout = {{
            height: 400,
            width: window.innerWidth/2.3,
            // showlegend: false,
            xaxis: {
              tickangle: 45,
              title: 'RNA types',
              titlefont: { size: 20 },
            },
            yaxis: {
              title: '%',
              titlefont: { size: 20 },
            },
            // title: "RNA-parts origin (%)",
            // showticklabels: false,
            titlefont: { size: 25 }
          
            // title: 'GRID, exp_id=3'
          }}
          config = {{
            modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian','lasso2d','select2d'],
            // displayModeBar: true,
            displaylogo: false,
            // editable: true,
            doubleClickDelay: 1000,
            toImageButtonOptions: {format: 'svg', filename: 'Bar chart', scale: 1},
          }}
        />
    </div>
  )
}


// const mapStateToProps = (state) => {
//   return {
//     processingMetadata: state[module].processingMetadata,
//   }
// }
  
// export default connect(mapStateToProps, )(MetadataPiePlot);
export default MetadataPiePlot