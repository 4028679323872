import React from 'react';
// import Plotly from 'plotly.js'
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import Typography from '@material-ui/core/Typography';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Paper from '@material-ui/core/Paper';
import { getMax } from './DensityAllTargetGenes';

// const module = "table";

const Metadata2Dhistogram = (props) => {
  
  const { data } = props
  
  let bin_lables = []
  
  let a=Array.from(Array(1+Math.ceil(getMax(data['y']))).keys()).map(i=>'10^'+i)
  
  for (var index = 0; index < Array(1+Math.ceil(getMax(data['y']))).length; index ++) {
    bin_lables.push(
        {
            x: 0, 
            y: index,
            text: a[index],
            ax: -15,
            ay: 0,
            font: {
                family: 'sans serif',
                size: 12,
            },
        }
    )
  }
  
  return (
    <div >
      {/* style={{border: '1px solid #d3d4d5'}} */}
      <Typography variant="h6">
        Distribution of the number of RNAs according to the number of contacts with the genome
      </Typography>
        <Plot
          data = {[
            {
            x: data['x'],
            y: data['y'],
            mode: 'markers',
            name: 'points',
            marker: {
              color: '#1f77b4',//'#e377c2',
              size: 4,
              opacity: 1
            },
            type: 'scatter',
            
            // type: 'histogram',
            // xbins: {
            //   end: 6, 
            //   size: 0.1, 
            //   start: 0
            // },

            // width: data['x'].map(i=>0.1),
            // type: 'bar',
          },
          ]}
          layout = {{
            height: 400,
            width: window.innerWidth/2.3,
            showlegend: false,
            autosize: false,
            margin: {t: 50},
            hovermode: 'closest',
            bargap: 0,
            xaxis: {
              domain: [0, 1],
              showgrid: true,
              zeroline: false,
              title: 'Log10(<b>number of contacts</b>)',
              titlefont: { size: 20 },
            },
            yaxis: {
              domain: [0, 1],
              showgrid: true,
              zeroline: false,
              showticklabels: false,
              title: 'Log10(<b>number of RNAs</b>)',
              titlefont: { size: 20 },
            },
            annotations: bin_lables,
            shapes: [{
              type: 'line',
              x0: data['median_of_contacts_plus5'],
              y0: 0,
              x1: data['median_of_contacts_plus5'],
              yref: 'paper',
              y1: 10,
              line: {
                color: 'grey',
                width: 1.5,
                dash: 'dot'
              }
            },]
            // title: 'GRID, exp_id=3'
            // xaxis2: {
            //   domain: [0.85, 1],
            //   showgrid: false,
            //   zeroline: false
            // },
            // yaxis2: {
            //   domain: [0.85, 1],
            //   showgrid: false,
            //   zeroline: false
            // },
          }}
          config = {{
            modeBarButtonsToRemove: ['zoom2d','autoScale2d','pan2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian','lasso2d','select2d'],
            // displayModeBar: true,
            // editable: true,
            displaylogo: false,
            doubleClickDelay: 1000,
            toImageButtonOptions: {format: 'svg', filename: 'Number of contacts distribution', scale: 1},
          }}
        />
    </div>
  )
}


// const mapStateToProps = (state) => {
//   return {
//     processingMetadata: state[module].processingMetadata,
//   }
// }
  
// export default connect(mapStateToProps, )(MetadataPiePlot);
export default Metadata2Dhistogram