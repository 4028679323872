import logger from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from '../reducers';
//import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
// import storageSession from 'redux-persist/lib/storage/session'

// const persistConfig = {
//     key: 'root',
//     stateReconciler: storageSession,
//     storage,
// };

export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();
    //const persistedReducer = persistReducer(persistConfig, rootReducer)

    const store = createStore(
        // persistedReducer,
        rootReducer,
        initialState,
        compose(
            applyMiddleware(
                sagaMiddleware,
                logger
            )
        )
    );


    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);
    //let persistor = persistStore(store)
    return { store }; //, persistor
}