import * as ActionType from '../actions/basicAnalysisActions';

const initialState = {
    basicGeneTableData: {},
    loadingBasicGeneTable: false,
    snackOpenBasicGeneTable: false,
    snackTextBasicGeneTable: '',

    selectedId: 0,

    basicGeneTableDataFromDNA: {},
    loadingBasicGeneTableFromDNA: false,
    snackOpenBasicGeneTableFromDNA: false,
    snackTextBasicGeneTableFromDNA: '',
};

const basicAnalysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.FETCH_BASIC_GENE_TABLE_REQUEST: {
            return {
                ...state,
                loadingBasicGeneTable: true,
            };
        }
        case ActionType.FETCH_BASIC_GENE_TABLE_SUCCESS: {
            // console.log('basicGeneTableData!!!!!!!',initialState.basicGeneTableData)
            // console.log('action.data',action.data)
            return {
                ...state,
                loadingBasicGeneTable: false,
                basicGeneTableData: action.data,
            };
        }
        case ActionType.FETCH_BASIC_GENE_TABLE_FAILURE: {
            return {
                ...state,
                loadingBasicGeneTable: true,
                snackOpenBasicGeneTable: true,
                snackTextBasicGeneTable: 'An error occurred in the backend!',
            };
        }
        case ActionType.EXP_TABLE_SELECT_ONE_ID: {
            return {
                ...state,
                selectedId: action.id,
            }
        }
        case ActionType.FETCH_BASIC_GENE_TABLE_FROM_DNA_REQUEST: {
            return {
                ...state,
                loadingBasicGeneTableFromDNA: true,
            };
        }
        case ActionType.FETCH_BASIC_GENE_TABLE_FROM_DNA_SUCCESS: {
            // console.log('basicGeneTableData!!!!!!!',initialState.basicGeneTableData)
            // console.log('action.data',action.data)
            return {
                ...state,
                loadingBasicGeneTableFromDNA: false,
                basicGeneTableDataFromDNA: action.data,
            };
        }
        case ActionType.FETCH_BASIC_GENE_TABLE_FROM_DNA_FAILURE: {
            return {
                ...state,
                loadingBasicGeneTableFromDNA: true,
                snackOpenBasicGeneTableFromDNA: true,
                snackTextBasicGeneTableFromDNA: 'An error occurred in the backend!',
            };
        }
        case ActionType.CLEAR_BASIC_GENE_TABLE_DATA_FROM_DNA: {
            return {
                ...state,
                basicGeneTableDataFromDNA: {},
            };
        }
        default:
            return state;
    }
}

export default basicAnalysisReducer;