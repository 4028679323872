import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import  { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
// import { connect } from 'react-redux';
// import classNames from 'classnames';
// import Paper from '@material-ui/core/Paper';
// import Header from './components/Header';
// import MainForm from './components/MainForm';

/* import './App.css'; */
import Welcome from './pages/Welcome';
import Overview from './pages/Overview';
import OverviewBasic from './pages/OverviewBasic';
import Tutorial from './pages/Tutorial';
import About from './pages/About';
import Protocol from './pages/Protocol';
import BasicProtocol from './pages/BasicProtocol';
// import FirstWorkingPage from './pages/FirstWorkingPage';
import GraphicalSummary from './pages/GraphicalSummary';
import BasicFromRna from './pages/BasicFromRna';
import BasicFromRnaViewGenes from './pages/BasicFromRnaViewGenes';
import BasicFromDna from './pages/BasicFromDna';

import BasicGraphicalSummary from './pages/BasicGraphicalSummary';
import BasicGraphicalSummaryFromRnaList from './pages/BasicGraphicalSummaryFromRnaList';
import BasicGraphicalSummaryDnaFilter from './pages/BasicGraphicalSummaryDnaFilter';

import TableTracksComparison from './components/TableTracksComparison';
// import LoginRegisterForm from './components/LoginRegisterForm';
import MetadataPage from './pages/MetadataPage';
import AllExperiments from './pages/AllExperiments';
import RnaListFilter from './components/RnaListFilter';
import Menu from './components/Menu';
import RnaPartsDistribution from './components/RnaPartsDistributionBasic';
import AllTargetGenes from './components/AllTargetGenes';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // backgroundColor: '#eeeeee',
  },
  // paper: {
  //   //marginTop: 50,
  //   margin: '0 auto',
  //   padding: 20,
  //   width: '100%',
  //   textAlign: 'center',
  //   marginBottom: 0,
  // },
}))

const App = (props) => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'light' : 'light',
          primary: {
            main: '#009688',
          },
          secondary: {
            main: '#96000E',
          }
        },
        overrides: {
          MuiTooltip: {
            tooltip: {
              backgroundColor: 'white',
              color: 'rgba(0, 0, 0, 0.87)',
              maxWidth: 400,
              border: '1px solid #dadde9',
            }
          },
          MUIDataTableHeadCell: {
            root: {
              color: 'white',
              backgroundColor: "#009688 !important",
              textAlign: 'center !important',
            },
            sortAction: {
              margin: '0 auto',
            },
          },
          
          // MUIDataTableToolbar: {
          //   root: {
          //     backgroundColor: "#009688 !important"
          //   }
          // },
        },
      }),
    [prefersDarkMode],
  );
  // useEffect(() => {
  //   window.onbeforeunload = function() {
  //     return "";
  //   }
  // }, []);
  // const { open } = props; //classes,

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Router>
      {/* <Paper className={classes.paper} elevation={4}> */}
        <div className={classes.root}>
          <Menu />
          <Switch>
            {/* <Route exact path="/tracks" render={ () => <FirstWorkingPage />}/> */}
            <Route exact path="/">
              <Welcome />
            </Route> 
            {/* <Route exact path="/overview_old" render={ () => <Overview />}/>
            <Route exact path="/protocol_old" render={ () => <Protocol />}/> */}
            <Route exact path="/tutorial">
              <Tutorial />
            </Route>
            <Route exact path="/overview">
              <OverviewBasic />
            </Route>
            <Route exact path="/protocol">
              <BasicProtocol />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/experiments">
              <AllExperiments />
            </Route>
            <Route exact path="/metadata">
              <MetadataPage />
            </Route>
            {/* <Route exact path="/tracks_comparison" render={ () => <TableTracksComparison />}/> */}
            <Route exact path="/graphical_summary">
              <GraphicalSummary />
            </Route>
            {/* <Route exact path="/rna_list" render={ () => <RnaListFilter />} /> */}
            <Route exact path="/from_rna">
              <BasicFromRna />
            </Route>
            <Route exact path="/from_rna/browse_rnas">
              <BasicFromRnaViewGenes />
            </Route>
            <Route exact path="/from_dna">
              <BasicFromDna />
            </Route>
            <Route exact path="/basic_graphical_summary">
              <BasicGraphicalSummary />
            </Route>
            <Route exact path="/basic_graphical_summary_dna_filter">
              <BasicGraphicalSummaryDnaFilter />
            </Route>
            <Route exact path="/basic_graphical_summary/from_rna_list">
              <BasicGraphicalSummaryFromRnaList />
            </Route>
            <Route exact path="/basic_graphical_summary/rna_parts">
              <RnaPartsDistribution />
            </Route>
            {/* <Route exact path="/basic_graphical_summary_dna_filter/rna_parts" render={ () => <RnaPartsDistribution />} /> */}
            <Route exact path="/basic_graphical_summary/all_target_genes">
              <AllTargetGenes />
            </Route>
            {/* <Route exact path="/basic_graphical_summary_dna_filter/all_target_genes" render={ () => <AllTargetGenes />} /> */}
            {/* <Route exact path="/register_form" render={ () => <LoginRegisterForm />}/> */}
            {/* <Route component={NotFound} /> */}
          </Switch>
        </div>
      {/* </Paper> */}
      </Router>
    </ThemeProvider> 
  )
}



// const mapStateToProps = (state) => {
//   return {
//     // open: state[moduleMenu].menuOpen,
//   }
// }

// export default connect(mapStateToProps, )(withStyles(styles)(App));
// export default connect(mapStateToProps, )(App);
export default App;