import * as actionTypes from '../actions/generalActions';

const initialState = {
    value: "",
    locusWarning: false,
    currentLocus: "",

    valueGeneBody: "",
    GeneBodyWarning: false,
    currentGeneBody: "",

    valueUp50: "",
    Up50Warning: false,
    currentUp50: "",

    valueUp5: "",
    Up5Warning: false,
    currentUp5: "",

    valueDown5: "",
    Down5Warning: false,
    currentDown5: "",

    valueDown50: "",
    Down50Warning: false,
    currentDown50: "",

    valueLength: "",
    lengthWarning: false,
    currentLength: "",

    valueFromDNA: "",
    locusWarningFromDNA: false,
    currentLocusFromDNA: "",

    valueUpstream: "",
    valueWarningUpstream: false,

    valueDownstream: "",
    valueWarningDownstream: false,

    valueSearchGeneName: "",
    /* loadingGenes: true,
    loadingPlot: false,
    numberOfGenes: '',
    snackOpen: false,
    snackText: 'Empty test',
    suggestions: [
        { label: 'British Indian Ocean Territory' },
        { label: 'Brunei Darussalam' },
    ], */
}

const textReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.TEXT_CHANGE: 
            return {
                ...state,
                value: action.newValue,
            };
        case actionTypes.TEXT_CHANGE_SEARCH_GENE_NAME: 
            return {
                ...state,
                valueSearchGeneName: action.newValueSearchGeneName,
            };
        case actionTypes.CURRENT_LOCUS_CHANGE: 
            return {
                ...state,
                currentLocus: action.newLocus,
            };
        case actionTypes.LOCUS_WARNING_CHANGE_FALSE: 
            return {
                ...state,
                locusWarning: false,
            };
        case actionTypes.LOCUS_WARNING_CHANGE_TRUE: 
            return {
                ...state,
                locusWarning: true,
            };

        
        case actionTypes.TEXT_GENE_BODY_CHANGE: 
            return {
                ...state,
                valueGeneBody: action.newValue,
            };
        case actionTypes.CURRENT_GENE_BODY_CHANGE: 
            return {
                ...state,
                currentGeneBody: action.newGeneBody,
            };
        case actionTypes.GENE_BODY_WARNING_CHANGE_FALSE: 
            return {
                ...state,
                GeneBodyWarning: false,
            };
        case actionTypes.GENE_BODY_WARNING_CHANGE_TRUE: 
            return {
                ...state,
                GeneBodyWarning: true,
            };

        case actionTypes.TEXT_UP50_CHANGE: 
            return {
                ...state,
                valueUp50: action.newValue,
            };
        case actionTypes.CURRENT_UP50_CHANGE: 
            return {
                ...state,
                currentUp50: action.newUp50,
            };
        case actionTypes.UP50_WARNING_CHANGE_FALSE: 
            return {
                ...state,
                Up50Warning: false,
            };
        case actionTypes.UP50_WARNING_CHANGE_TRUE: 
            return {
                ...state,
                Up50Warning: true,
            };

        case actionTypes.TEXT_UP5_CHANGE: 
            return {
                ...state,
                valueUp5: action.newValue,
            };
        case actionTypes.CURRENT_UP5_CHANGE: 
            return {
                ...state,
                currentUp5: action.newUp5,
            };
        case actionTypes.UP5_WARNING_CHANGE_FALSE: 
            return {
                ...state,
                Up5Warning: false,
            };
        case actionTypes.UP5_WARNING_CHANGE_TRUE: 
            return {
                ...state,
                Up5Warning: true,
            };

        case actionTypes.TEXT_DOWN50_CHANGE: 
            return {
                ...state,
                valueDown50: action.newValue,
            };
        case actionTypes.CURRENT_DOWN50_CHANGE: 
            return {
                ...state,
                currentDown50: action.newDown50,
            };
        case actionTypes.DOWN50_WARNING_CHANGE_FALSE: 
            return {
                ...state,
                Down50Warning: false,
            };
        case actionTypes.DOWN50_WARNING_CHANGE_TRUE: 
            return {
                ...state,
                Down50Warning: true,
            };
        

        case actionTypes.TEXT_DOWN5_CHANGE: 
            return {
                ...state,
                valueDown5: action.newValue,
            };
        case actionTypes.CURRENT_DOWN5_CHANGE: 
            return {
                ...state,
                currentDown5: action.newDown5,
            };
        case actionTypes.DOWN5_WARNING_CHANGE_FALSE: 
            return {
                ...state,
                Down5Warning: false,
            };
        case actionTypes.DOWN5_WARNING_CHANGE_TRUE: 
            return {
                ...state,
                Down5Warning: true,
            };

        case actionTypes.TEXT_LENGTH_CHANGE: 
            return {
                ...state,
                valueLength: action.newValue,
            };
        case actionTypes.CURRENT_LENGTH_CHANGE: 
            return {
                ...state,
                currentLength: action.newLength,
            };
        case actionTypes.LENGTH_WARNING_CHANGE_FALSE: 
            return {
                ...state,
                lengthWarning: false,
            };
        case actionTypes.LENGTH_WARNING_CHANGE_TRUE: 
            return {
                ...state,
                lengthWarning: true,
            };


        case actionTypes.TEXT_CHANGE_FROM_DNA: 
            return {
                ...state,
                valueFromDNA: action.newValue,
            };
        case actionTypes.CURRENT_LOCUS_CHANGE_FROM_DNA: 
            return {
                ...state,
                currentLocusFromDNA: action.newLocus,
            };
        case actionTypes.LOCUS_WARNING_CHANGE_FALSE_FROM_DNA: 
            return {
                ...state,
                locusWarningFromDNA: false,
            };
        case actionTypes.LOCUS_WARNING_CHANGE_TRUE_FROM_DNA: 
            return {
                ...state,
                locusWarningFromDNA: true,
            };



        case actionTypes.TEXT_CHANGE_UPSTREAM: 
            return {
                ...state,
                valueUpstream: action.newValue,
            };
        case actionTypes.TEXT_CHANGE_UPSTREAM_WARNING_FALSE: 
            return {
                ...state,
                valueWarningUpstream: false,
            };
        case actionTypes.TEXT_CHANGE_UPSTREAM_WARNING_TRUE: 
            return {
                ...state,
                valueWarningUpstream: true,
            };


    
        case actionTypes.TEXT_CHANGE_DOWNSTREAM: 
            return {
                ...state,
                valueDownstream: action.newValue,
            };
        case actionTypes.TEXT_CHANGE_DOWNSTREAM_WARNING_FALSE: 
            return {
                ...state,
                valueWarningDownstream: false,
            };
        case actionTypes.TEXT_CHANGE_DOWNSTREAM_WARNING_TRUE: 
            return {
                ...state,
                valueWarningDownstream: true,
            };


            

        case actionTypes.CLEAR_RNA:
            return {
                ...state,
                currentLocus: '',
                value: '',
                valueUpstream: "",
                valueDownstream: "",
            }
        case actionTypes.CLEAR_TEXT_INPUTS_TWO_UPSTREAM:
            return {
                ...state,
                valueUpstream: "",
                valueDownstream: "",
            };
        case actionTypes.CLEAR_TEXT:
            return {
                ...state,  
                valueSearchGeneName: "",
                valueFromDNA: "",
                valueLength: "",
            };



        case actionTypes.CLEAR_TEXT_INPUTS_TWO:
            return {
                ...state,
                currentLocus: '',
                value: '',
                // valueLength: "",
            }
        default:
            return state
    }
}


export default textReducer;




/* const genesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_DATA_REQUEST:
            return {
                ...state,
                loadingGenes: true,
            };
        case ActionTypes.FETCH_DATA_SUCCESS:
            return {
                ...state,
                suggestions: action.genes,
                loadingGenes: false,
                snackOpen: true,
                snackText: "Successfully fetched genes.",
            };
        case ActionTypes.FETCH_DATA_FAILURE:
            return {
                ...state,
                loadingGenes: false,
                snackOpen: true,
                snackText: "Whoops, something went wrong.",
            };
        case ActionTypes.SUBMIT_DATA_REQUEST:
            return {
                ...state,
                loadingPlot: true,
            };
        case ActionTypes.SUBMIT_DATA_SUCCESS:
            return {
                ...state,
                numberOfGenes: action.number,
                loadingPlot: false,
                snackOpen: true,
                snackText: "Successfully submitted.",
            };
        case ActionTypes.SUBMIT_DATA_FAILURE:
            return {
                ...state,
                loadingPlot: false,
                snackOpen: true,
                snackText: "Whoops, something went wrong.",
            };
        default:
            return state;
    };
};
 */
//export default genesReducer;