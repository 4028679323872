import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Plot from 'react-plotly.js';
import Grid from '@material-ui/core/Grid';

// import { connect } from 'react-redux';

// import Decorator from '../../components/HtmlTooltip';
import { normalizationName } from './Heatmap_data_layout';
import { getMax } from './DensityAllTargetGenes';
// import { submitCheckedTracksRnaPart, clearGenomeBrowserRnaPartURL} from '../../actions/genomeBrowserActions';
// import Typography from '@material-ui/core/Typography';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import TableMetadataBasic from '../plots/TableMetadataBasic';
// import Button from '@material-ui/core/Button';
import EnhancedTABLEpart from '../../components/TableGraphicalSummary';

// const module = "plots";
// const moduleGenomeBrowser = "genomeBrowser"


const DNApartProfileBasic = (props) => {

    const { geneDNAdataProfile } = props
    // console.log('n= geneDNAdataProfile',geneDNAdataProfile)
    const [data, setData] = useState([])
    const [layout, setLayout] = useState({})
    const [dataForProfile, setDataForProfile] = useState([])
    const [trackNameAndColor, setTrackNameAndColor] = useState({})
    // let colorList = ['#1f77b4','#ff7f0e','#2ca02c','#d62728','#9467bd','#8c564b','#e377c2','#7f7f7f','#bcbd22','#17becf']
    useEffect(() => {
    if (Object.keys(geneDNAdataProfile).length !== 0) { 
        // console.log('geneDNAdataProfile',geneDNAdataProfile)
        // console.log('Object.keys(geneDNAdataProfile)',Object.keys(geneDNAdataProfile))
        var number = Object.keys(geneDNAdataProfile).length
        let colorList = Array(Math.ceil(number/10)).fill(['#1f77b4','#ff7f0e','#2ca02c','#d62728','#9467bd','#8c564b','#e377c2','#7f7f7f','#bcbd22','#17becf']).flat()
        var chromosomes = []
        let index_2 = Object.keys(geneDNAdataProfile)[0]
        // console.log('datadata index_2',index_2)
        // console.log('datadata width_bar',width_bar)
        // console.log('datadata geneDNAdataProfile[index_2][result][y].map(i=>width_bar)',geneDNAdataProfile[index_2]['result']['y'].map(i=>width_bar))
        let gene_end = geneDNAdataProfile[index_2]['result']['end']
        let gene_start = geneDNAdataProfile[index_2]['result']['start']
        // var width_bar = geneDNAdataProfile[index_2]['result_filter_contacts']
        // var a = geneDNAdataProfile[index_2]['result']['y'].map(i=>width_bar)
        var data_for_profile = []
        let trackNameAndColorDict = {}
        var trace = ""
        
        chromosomes.push(
            {
                x: gene_start,
                y: 0,
                xref: 'x1',//'x2',
                yref: 'paper',
                text: `${geneDNAdataProfile[index_2]['result']['chr_name']}:${gene_start}`,
                arrowhead: 0.7,
                arrowsize: 0.7,
                ax: 0,
                ay: 30,
                font: {
                    family: 'sans serif',
                    size: 11,
                },
            },
            {
                x: gene_end, 
                y: 0,
                xref: 'x1',//'x2',
                yref: 'paper',
                text: `${gene_end}`,
                arrowhead: 0.7,
                arrowsize: 0.7,
                ax: 0,
                ay: 30,
                font: {
                    family: 'sans serif',
                    size: 11,
                },
            }
        )
        
        var yaxis = 0
        var all = []

        for (var index in geneDNAdataProfile) {
            var width_bar = geneDNAdataProfile[index]['result_filter_contacts']
            var a = geneDNAdataProfile[index]['result']['y'].map(i=>width_bar)
            yaxis = yaxis + 1        
            trace = geneDNAdataProfile[index]['result']['description_for_plots'].replace(/<br>/g, ' ').split('exp.protocol: ')[1].split('; extra:')[0] + ", " +  "Exp.ID: " + index.toString()
            all.push(
                { trace } = {
                    x: geneDNAdataProfile[index]['result']['x'],
                    y: geneDNAdataProfile[index]['result']['y'],
                    width: a,
                    type: 'bar',
                    // xaxis: 'x2',
                    yaxis: 'y' + yaxis.toString(),
                    name: trace, // + '<br>(all contacts)',
                    opacity: 1,
                    hoverinfo: 'y+name',
                    hovertemplate: '%{y:.3f}',
                    marker: {
                        color: colorList[yaxis-1]//'#ff7f0e'
                    },
                    }
                )
            // console.log('settrackNameAndColor colorList[yaxis-1]',yaxis,colorList[yaxis-1])
            trace = geneDNAdataProfile[index]['result']['description_for_plots'].replace(/<br>/g, ' ').split('exp.protocol: ')[1].split('; extra:')[0] + ", " +  "Exp.ID: " + index.toString()
            chromosomes.push(
                {
                    x: gene_start + (gene_end-gene_start)/2,
                    y: getMax(geneDNAdataProfile[index]['result']['y']),
                    xref: 'x1',
                    yref: 'y' + yaxis.toString(),
                    text: trace + ", " + geneDNAdataProfile[index]['result']['description_for_plots'].replace(/<br>/g, ' ').split('cell line: ')[1].split('; exp.protocol:')[0],
                    showarrow: false,
                }
            )
            trace = "Exp.ID: " + index.toString()
            data_for_profile.push(
                {'track': [trace, "ID"],
                'method': [geneDNAdataProfile[index]['result']['description_for_plots'].replace(/<br>/g, ' ').split('exp.protocol: ')[1].split('; extra:')[0], "Method"],
                'totalContactsCount': [geneDNAdataProfile[index]['result']['sum_value'], "n-reads"],
                // 'color': table_rows_color[numberOfcells],
                'trackDescription': ['psembrkrmld,e', "Track description"],                
                'cellLine': [geneDNAdataProfile[index]['result']['description_for_plots'].replace(/<br>/g, ' ').split('cell line: ')[1].split('; exp.protocol:')[0], "Cell line"],
                'datasetDescription': [geneDNAdataProfile[index]['result']['description_for_plots'].replace(/<br>/g, ' ').split('extra: ')[1], "Exp.description"],
                'typeOfExperiment': [geneDNAdataProfile[index]['result']['typeOfExperiment'], "Data type"],
                }
            )
            trackNameAndColorDict[trace] = colorList[yaxis-1]
            setTrackNameAndColor(trackNameAndColorDict)
        }
        
        var Layoutt_2 = {
            height: number < 5 ? 400 : number*100,
            width: window.innerWidth*0.55, //0.99, 
            titlefont: { size: 20 },
            // title: "OY: NEAT1 contacts density, OX: genomic target (organism)",
            title:`<b>${geneDNAdataProfile[Object.keys(geneDNAdataProfile)[0]]['result']['RNAname']}</b>, <i>${props.organism.split('____')[0]}</i>, <b>${normalizationName(props.trackListCheckbox)}</b>`, //, ${contactsType}
            annotations: chromosomes,
            legend: {traceorder: 'reversed'},
            // xaxis1: {
            //     title: `Genomic target (${props.organism.split('____')[1]})`,
            //     titlefont: { size: 18 },
            // }, 
        }
        
        var start_index = 0
        var end_index = 0
        var step = 1/number
        for (var i = 0; i < number; i++) {
            let yaxis_name = "yaxis" + (i+1).toString() 
            if (start_index === 0 && end_index === 0) {
                end_index = end_index + step
            } else {
                start_index = start_index + step
                end_index = end_index + step
            }
            Layoutt_2[yaxis_name] = {
                                        domain: [start_index, end_index - step/10],
                                        // title: "Contacts Density 1", 
                                        // titlefont: { size: 15 }
                                    }
        }
        // console.log('Layoutt_2',Layoutt_2)
        // var Layoutt = {
        //     height: number < 5 ? 400 : number*100,
        //     width: window.innerWidth, 
        //     titlefont: { size: 25 },
        //     title: "Contacts Distribution", 
        //     "yaxis1": {
        //         domain: [0, 0.2],
        //         // title: "Contacts Density 1", 
        //         // titlefont: { size: 15 }
        //     },
        //     "yaxis2": {
        //         domain: [0.25, 0.45],
        //         // title: "Contacts Density 2", 
        //         // titlefont: { size: 15 }
        //     },
        //     "yaxis3": {
        //         domain: [0.5, 0.7],
        //         // title: "Contacts Density 3", 
        //         // titlefont: { size: 15 }
        //     },
        //     "yaxis4": {
        //         domain: [0.75, 1],
        //         // title: "Contacts Density 4", 
        //         // titlefont: { size: 15 }
        //     },
        //     annotations: chromosomes,
        //     xaxis1: {
        //         title: 'Gene body',
        //         titlefont: { size: 25 },
        //     },
        // }

        setData(all)
        setLayout(Layoutt_2)
        setDataForProfile(data_for_profile)
        // setSteps(temporary_steps)
    }
    }, [geneDNAdataProfile])

    // console.log('datadata geneDNAdataProfile', geneDNAdataProfile)
    // console.log('datadata data',data)
    // console.log('trackNameAndColor',trackNameAndColor)

    return (
        <div id='myDiv'>
            {/* <div> */}
            <Grid container spacing={0}>
                <Grid item xs={7}>
                    <Plot data={data} layout={layout} config={{
                        modeBarButtonsToRemove: ['autoScale2d','pan2d', 'zoom2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian','lasso2d', 'select2d'],
                        // displayModeBar: true,
                        displaylogo: false,
                        // scrollZoom: true,
                        doubleClickDelay: 1000,
                        toImageButtonOptions: {format: 'svg', filename: 'Contacts distribution', scale: 1}
                    }}/>
                </Grid>
                {/* {steps} */}
                {/* </div>
                <div> */}
                <Grid item xs={5}>
                    <EnhancedTABLEpart  from={'basic'} data={dataForProfile} trackNameAndColor={trackNameAndColor}/>
                {/* </div> */}
                </Grid>
            </Grid>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        // loadingRNApartDataTable: state[module].loadingRNApartDataTable,
        // geneDNAdataProfileTable: state[module].geneDNAdataProfileTable,
        // URLtoGenomeBrowserRnaPart: state[moduleGenomeBrowser].URLtoGenomeBrowserRnaPart,
        // loadingGB: state[moduleGenomeBrowser].loadingURLRnaPart,
        // snackOpenGenomeBrowser: state[moduleGenomeBrowser].snackOpenGenomeBrowserRnaPart,
        // snackTextGenomeBrowser: state[moduleGenomeBrowser].snackTextGenomeBrowserRnaPart,
    }
}

export default connect(mapStateToProps, {})(DNApartProfileBasic);
