import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Plot from 'react-plotly.js';
import Grid from '@material-ui/core/Grid';

// import { connect } from 'react-redux';

// import Decorator from '../../components/HtmlTooltip';
// import EnhancedTABLEpart from '../../components/TableGraphicalSummary';
import { normalizationName } from './Heatmap_data_layout';
import { submitCheckedTracksRnaPart, clearGenomeBrowserRnaPartURL} from '../../actions/genomeBrowserActions';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableMetadataBasic from '../plots/TableMetadataBasic';
import Button from '@material-ui/core/Button';

import DownloadTrack from '../DownloadTrack';

// const module = "plots";
const moduleGenomeBrowser = "genomeBrowser"


const RNApartProfileBasic = (props) => {

    // console.log('experiment_id)))))))))))', props.experiment_id)
    // console.log('gene_name)))))))))))', props.gene_name)
    // console.log('organism)))))))))))', props.organism)


    const { geneRNAdataProfile } = props; //locus, gene_name, organism, experiment_id
    // const [RNAdataTable, setRNAdataTable] = useState([])
    const [steps, setSteps] = useState([])
    const [buttonColor, setButtonColor] = useState('#bdbdbd')
    const [buttonTextColor, setButtonTextColor] = useState('black')
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [exps, setExps] = useState([])

    
    var temporary_steps = [];
    
    useEffect(() => {
        if (Object.keys(geneRNAdataProfile).length > 0) {
        //   if (geneRNAdataProfile.result.x.length === 1) {
            // setButtonDisabled(true)
            // setButtonColor('#bdbdbd')
            // setButtonTextColor('black')
        //   } else {
            setButtonDisabled(false)
            setButtonColor('#96000E')
            setButtonTextColor('white')
        //   }
        }
      }, [props.geneRNAdataProfile])

    const handleClick = (e) => { 
        // console.log('handleClick ))))))))')
        // if (props.geneRNAdataProfile.result.x.length > 1) {
        // debugger
        let checkedTracks = props.gene_name + '___' + props.organism + '___' + props.dnaFilter
        let selectedTrackNames = exps
        props.submitCheckedTracksRnaPart(
            checkedTracks, //props.RnaSelected,
            'RNA_part___fromRnaList_basic',
            selectedTrackNames, //props.selectedTrackNames, 
            props.trackListCheckbox
          )
        // }
      };

      useEffect(() => {
        // console.log('props.URLtoGenomeBrowserRnaPart',props.URLtoGenomeBrowserRnaPart)
        if (props.URLtoGenomeBrowserRnaPart !== '') {
        //   console.log('OpenSimple')
          openRequestedPopup()
        }
      }, [props.URLtoGenomeBrowserRnaPart])
        
    
      const openRequestedPopup = () => {
        let empty_tracks = ''
        let i = []
        let url_massege = ''
        if (props.URLtoGenomeBrowserRnaPart.split("|||").length === 1) {
          i = props.URLtoGenomeBrowserRnaPart.split("_____")
          url_massege = 'Otherwise manually copy and paste these links: ' + i.toString()
        } else {
          i = props.URLtoGenomeBrowserRnaPart.split("|||")[0].split("_____")
          if (i !== '') {
            url_massege = 'Otherwise manually copy and paste these links: ' + i.toString()
          }
          empty_tracks = props.URLtoGenomeBrowserRnaPart.split("|||")[1]
          empty_tracks = "       (Tracks: " + empty_tracks + " — don't have contacts with chromatin)"
        }
        for (var k in i) {
        //   console.log('k',i)
          if (i[k] !== '') {
            var newWin = window.open(i[k])
            if (!newWin || newWin.closed || typeof newWin.closed=='undefined') { 
              alert('You need to allow pop-up windows to open. ' + url_massege + empty_tracks)
              break;
            } else {
              newWin.focus()
            }
          }
        }
        if (empty_tracks !== '') {
          alert(empty_tracks)
        }
        props.clearGenomeBrowserRnaPartURL()
      }

    useEffect(() => {
    if (Object.keys(geneRNAdataProfile).length !== 0) { 
        var EXPs_list = []
        var number_of_plots = Object.keys(geneRNAdataProfile).length
        for (var index in geneRNAdataProfile) {
            // console.log('geneRNAdataProfile index',index)
            number_of_plots = number_of_plots - 1
            // console.log('geneRNAdataProfile number_of_plots',number_of_plots)
            var temporary_steps_table = [];
            var all = []
            var all_a = []
            var a=Array.from(Array(geneRNAdataProfile[index]['profile']['result']['y'].length-1)).map(i=>200)
            var b=[]
            var chromosomes = []
            let trace = props.gene_name

            let gene_end = geneRNAdataProfile[index]['profile']['result']['end']
            let gene_start = geneRNAdataProfile[index]['profile']['result']['start']
            let x_array = geneRNAdataProfile[index]['profile']['result']['x']
            // debugger
            EXPs_list.push(geneRNAdataProfile[index]['table']['experiment_id'])

            if (Object.keys(geneRNAdataProfile[index]['table']).indexOf('cpkm_filter_DNA') !== -1) {
                temporary_steps_table.push(
                // {"Name": "RNA name", "Description": geneRNAdataProfile[index]['table']['gene_name']},
                // {"Name": "Gene type", "Description": geneRNAdataProfile[index]['table']['gene_type']},
              //   {"Name": "Track name", "Description": props.track_name},
                {"Name": "Experiment ID", "Description": geneRNAdataProfile[index]['table']['experiment_id']},
                {"Name": "Cell line", "Description": geneRNAdataProfile[index]['table']['description_for_plots'].split('cell line: ')[1].split('; exp.protocol:')[0]},
                {"Name": "Method", "Description": geneRNAdataProfile[index]['table']['description_for_plots'].split('exp.protocol: ')[1].split('; extra:')[0]},
                {"Name": "Exp.description", "Description": geneRNAdataProfile[index]['table']['description_for_plots'].split('extra: ')[1]},
                {"Name": "Type of experiment", "Description": "All-to-all"},
                {"Name": "N-reads (normalized) (all contacts)", "Description": geneRNAdataProfile[index]['table']['sum_value']}, //N-norm (all normalized contacts)
                {"Name": "N-reads (raw) (all contacts)", "Description": geneRNAdataProfile[index]['table']['sum_observed_value']},//N-obs (all observed contacts)
                {"Name": "CPKM.norm (all contacts)", "Description": geneRNAdataProfile[index]['table']['cpkm']},
                {"Name": "CPKM.raw (all contacts)", "Description": geneRNAdataProfile[index]['table']['cpkm_observed']}, //CPKM (all observed contacts)
                {"Name": "RNA-parts of the contacts (all)", "Description": <DownloadTrack typeOfContacts={"RNA_part"} all_insert={""} expId_and_name={geneRNAdataProfile[index]['table']['experiment_id'] + "___" + props.gene_name}/>},
                // {"Name": "N-exp (all contacts)", "Description": geneRNAdataProfile['sum_expected_value']},
                // {"Name": "N-exp (filter DNA)", "Description": geneRNAdataProfile['sum_expected_value_filter_DNA']},
                {"Name": "N-reads (normalized) (DNA filter)", "Description": geneRNAdataProfile[index]['table']['sum_value_filter_DNA']},
                {"Name": "N-reads (raw) (DNA filter)", "Description": geneRNAdataProfile[index]['table']['sum_observed_value_filter_DNA']},
                {"Name": "CPKM.norm (DNA filter)", "Description": geneRNAdataProfile[index]['table']['cpkm_filter_DNA']},
                {"Name": "CPKM.raw (DNA filter)", "Description": geneRNAdataProfile[index]['table']['cpkm_observed_filter_DNA']},
                {"Name": "RNA-parts of the contacts (DNA filter)", "Description": <DownloadTrack typeOfContacts={"RNA_part"} all_insert={props.dnaFilter} expId_and_name={geneRNAdataProfile[index]['table']['experiment_id'] + "___" + props.gene_name}/>},
              //   {"Name": "Normalization", "Description": props.trackListCheckbox},
                // {"Name": "Gene locus", "Description": geneRNAdataProfile[index]['table']['chr_ID'] + ":" + geneRNAdataProfile[index]['table']['start'] + "-" + geneRNAdataProfile[index]['table']['end']},
                // {"Name": "Strand", "Description": geneRNAdataProfile[index]['table']['strand']},
                // {"Name": "Length", "Description": geneRNAdataProfile[index]['table']['len']},
                // {"Name": "Organism", "Description": geneRNAdataProfile[index]['table']['organism']},
                // {"Name": "Genome", "Description": geneRNAdataProfile[index]['table']['build']},
                // {"Name": "Annotation source", "Description": geneRNAdataProfile[index]['table']['from']}
              )
            } else {
                temporary_steps_table.push(
                // {"Name": "RNA name", "Description": geneRNAdataProfile[index]['table']['gene_name']},
                // {"Name": "Gene type", "Description": geneRNAdataProfile[index]['table']['gene_type']},
              //   {"Name": "Track name", "Description": props.track_name},
                {"Name": "Experiment ID", "Description": geneRNAdataProfile[index]['table']['experiment_id']},
                {"Name": "Cell line", "Description": geneRNAdataProfile[index]['table']['description_for_plots'].split('cell line: ')[1].split('; exp.protocol:')[0]},
                {"Name": "Method", "Description": geneRNAdataProfile[index]['table']['description_for_plots'].split('exp.protocol: ')[1].split('; extra:')[0]},
                {"Name": "Exp.description", "Description": geneRNAdataProfile[index]['table']['description_for_plots'].split('extra: ')[1]},
                {"Name": "Type of experiment", "Description": "All-to-all"},
                {"Name": "N-reads (normalized)", "Description": geneRNAdataProfile[index]['table']['sum_value']},
                {"Name": "N-reads (raw)", "Description": geneRNAdataProfile[index]['table']['sum_observed_value']},
                {"Name": "CPKM (normalized)", "Description": geneRNAdataProfile[index]['table']['cpkm']},
                {"Name": "CPKM (raw)", "Description": geneRNAdataProfile[index]['table']['cpkm_observed']},
                // {"Name": "N-exp", "Description": geneRNAdataProfile['sum_expected_value']},
                {"Name": "RNA-parts of the contacts", "Description": <DownloadTrack typeOfContacts={"RNA_part"} all_insert={""} expId_and_name={geneRNAdataProfile[index]['table']['experiment_id'] + "___" + props.gene_name}/> },
              //   {"Name": "Normalization", "Description": props.trackListCheckbox},
                // {"Name": "Gene locus", "Description": geneRNAdataProfile[index]['table']['chr_ID'] + ":" + geneRNAdataProfile[index]['table']['start'] + "-" + geneRNAdataProfile[index]['table']['end']},
                // {"Name": "Strand", "Description": geneRNAdataProfile[index]['table']['strand']},
                // {"Name": "Length", "Description": geneRNAdataProfile[index]['table']['len']},
                // {"Name": "Organism", "Description": geneRNAdataProfile[index]['table']['organism']},
                // {"Name": "Genome", "Description": geneRNAdataProfile[index]['table']['build']},
                // {"Name": "Annotation source", "Description": geneRNAdataProfile[index]['table']['from']}
            )
        }

        if (x_array.length > 1) {
            if (x_array.slice(-1)[0] + 100 > gene_end - gene_start + 1) {//parseInt(locus.split("-")[1])) {
                x_array[x_array.indexOf(x_array.slice(-1)[0])] = x_array[x_array.indexOf(x_array.slice(-1)[0])] - 100 + parseInt((gene_end-gene_start-(x_array[x_array.indexOf(x_array.slice(-1)[0])]-100))/2)
                a.push(gene_end-gene_start-(x_array[x_array.indexOf(x_array.slice(-1)[0])]-100))
            } else {
                a.push(200)
            }
        } else {
            if  (x_array[0] < 100) {
                a[0] = x_array[0]*2
            } else {
                a[0] = 200
            }
        }
        // console.log('a',a)
        if (Object.keys(geneRNAdataProfile[index]['profile']['result_filter_contacts']).length === 0) {
            all.push(
                { trace } = {
                    x: geneRNAdataProfile[index]['profile']['result']['x'],
                    y: geneRNAdataProfile[index]['profile']['result']['y'],
                    width: a,
                    type: 'bar',
                    xaxis: 'x2',
                    yaxis: 'y2',
                    name: trace + '<br>(all contacts)',
                    opacity: 1,
                    hoverinfo: 'y+name',
                    hovertemplate: '%{y:.3f}',
                    marker: {
                        color: '#ff7f0e'
                    },
                    }
                )
        } else {
            b=Array.from(Array(geneRNAdataProfile[index]['profile']['result_filter_contacts']['y'].length-1)).map(i=>200)
            let x_array_filter = geneRNAdataProfile[index]['profile']['result_filter_contacts']['x']
            if (x_array_filter.length > 1) {
                if (x_array_filter.slice(-1)[0] + 100 > gene_end - gene_start + 1) {
                    x_array_filter[x_array_filter.indexOf(x_array_filter.slice(-1)[0])] = x_array_filter[x_array_filter.indexOf(x_array_filter.slice(-1)[0])] - 100 + parseInt((gene_end-gene_start-(x_array_filter[x_array_filter.indexOf(x_array_filter.slice(-1)[0])]-100))/2)
                    b.push(gene_end-gene_start-(x_array_filter[x_array_filter.indexOf(x_array_filter.slice(-1)[0])]-100))
                } else {
                    b.push(200)
                }
            } else {
                if  (x_array_filter[0] < 100) {
                    b[0] = x_array_filter[0]*2
                } else {
                    b[0] = 200
                }
            }
            // console.log('b',b)
            all.push(
                { trace } = {
                    x: geneRNAdataProfile[index]['profile']['result']['x'],
                    y: geneRNAdataProfile[index]['profile']['result']['y'],
                    width: a,
                    type: 'bar',
                    xaxis: 'x2',
                    yaxis: 'y2',
                    name: trace + '<br>(all contacts)',
                    opacity: 0.5,
                    hoverinfo: 'y+name',
                    hovertemplate: '%{y:.3f}',
                    marker: {
                        color: '#ff7f0e'
                    },
                    }
                )

            trace = props.gene_name
            all.push(
                    { trace } = {
                        x: geneRNAdataProfile[index]['profile']['result_filter_contacts']['x'],
                        y: geneRNAdataProfile[index]['profile']['result_filter_contacts']['y'],
                        width: b,
                        type: 'bar',
                        xaxis: 'x2',
                        yaxis: 'y2',
                        name: trace + '<br>(DNA filter)',
                        opacity: 0.5,
                        hoverinfo: 'y+name',
                        hovertemplate: '%{y:.3f}',
                        marker: {
                            color: '#1f77b4'
                        },
                        }
                    )
        }
    
        chromosomes.push(
                        {
                            x: 0,//geneRNAdataProfile['result']['start'],//locus.split("-")[0].split(":")[1], //5824708, 
                            y: 0,
                            xref: 'x2',
                            yref: 'paper',
                            text: `Start (${geneRNAdataProfile[index]['profile']['result']['chr_name']}:${geneRNAdataProfile[index]['profile']['result']['start']})`,
                            arrowhead: 0.7,
                            arrowsize: 0.7,
                            ax: 0,
                            ay: 30,
                            font: {
                                family: 'sans serif',
                                size: 11,
                            },
                        },
                        {
                            x: geneRNAdataProfile[index]['profile']['result']['end']-geneRNAdataProfile[index]['profile']['result']['start'] + 1,//locus.split("-")[1],//5845478, 
                            y: 0,
                            xref: 'x2',
                            yref: 'paper',
                            text: `End (${geneRNAdataProfile[index]['profile']['result']['end']})`,
                            arrowhead: 0.7,
                            arrowsize: 0.7,
                            ax: 0,
                            ay: 30,
                            font: {
                                family: 'sans serif',
                                size: 11,
                            },
                        }
                    )

        var layout={
                height: 400,
                width: window.innerWidth/1.7,
                titlefont: { size: 25 },
                title:`Exp.ID: ${geneRNAdataProfile[index]['table']['experiment_id']}, <b>${geneRNAdataProfile[index]['table']['gene_name']}</b>, ${normalizationName(props.trackListCheckbox)}`,
                barmode: "overlay",
                xaxis2: {
                    title: `Gene body (length: ${geneRNAdataProfile[index]['profile']['result']['end'] - geneRNAdataProfile[index]['profile']['result']['start'] + 1})`, //
                    showticklabels: false,
                    titlefont: { size: 25 },
                },
                yaxis2: { 
                    automargin: true,
                    title: "RNA-parts density", 
                    titlefont: { size: 25 },
                },
                annotations: chromosomes,
            }
        // console.log('index@@@@@@@@@@@@@',index)
        // setSteps(krkr => [...krkr,
        let plot_text = null
        if (number_of_plots === 0) {
            plot_text = 
                <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                    <ul>
                        <li>This bar chart displays distribution of RNA-parts of contacts of the selected RNA in the coordinates of the source gene.</li>
                        <li><b>Y-axis</b>: density of RNA-parts of contacts in the corresponding bin.</li>
                        <li><b>X-axis</b>: coordinate of the bin center relative to the gene.</li>
                        <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                    </ul>
                </Typography>
        }
        temporary_steps.push(
            <div key={index} >
                <Grid container spacing={0}>
                    <Grid item xs={8}>
                        <div>
                            <Plot data={all} layout={layout} config={{
                                modeBarButtonsToRemove: ['autoScale2d','pan2d', 'zoom2d', 'zoomIn2d', 'zoomOut2d','hoverClosestCartesian','hoverCompareCartesian','lasso2d', 'select2d'],
                                // displayModeBar: true,
                                displaylogo: false,
                                // scrollZoom: true,
                                doubleClickDelay: 1000,
                                toImageButtonOptions: {format: 'svg', filename: 'Contacts distribution', scale: 1}
                            }}/>
                            {plot_text}
                        </div>
                    </Grid>
                    <Grid item xs={4} >
                        <div style={{width: '98%', marginTop: 60, textAlign: 'center'}}>
                            <TableMetadataBasic 
                                title={''} 
                                RNAdataTable={temporary_steps_table}
                                // data={props.geneRNAdataProfileTable} 
                                // columns={["Name", "Description","RNA_profile"]}
                                gene_name={props.gene_name}
                                experiment_id={props.experiment_id}
                                organism={props.organism}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        // ]
        )
        }
        setSteps(temporary_steps)
        setExps(EXPs_list)
    }
    }, [geneRNAdataProfile])

    return (
        <div id='myDiv'>
            {steps}
            <Grid container spacing={0}>
                <Grid item xs={8}>
                    <div style={{width: '100%', height: '100%', textAlign: 'center', marginBottom: 10, marginTop: 10}}> 
                        {/* padding: theme.spacing(), */}
                        {!props.loadingGB ?
                            <Button
                                disabled={buttonDisabled}
                                variant="contained"
                                style={{backgroundColor: buttonColor, color: buttonTextColor}}
                                onClick={e => handleClick()}
                            >
                                View All Profiles in Genome Browser
                            </Button>
                            :
                            <div style={{marginTop: 10, textAlign: 'center'}}>
                                <LinearProgress color={"primary"} />
                                <Typography variant="subtitle1">
                                    Please, wait a while
                                </Typography>
                            </div>
                        }
                    </div>
                </Grid>
                <Grid item xs={4} >
                    {null}
                </Grid>
            </Grid>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        // loadingRNApartDataTable: state[module].loadingRNApartDataTable,
        // geneRNAdataProfileTable: state[module].geneRNAdataProfileTable,
        URLtoGenomeBrowserRnaPart: state[moduleGenomeBrowser].URLtoGenomeBrowserRnaPart,
        loadingGB: state[moduleGenomeBrowser].loadingURLRnaPart,
        snackOpenGenomeBrowser: state[moduleGenomeBrowser].snackOpenGenomeBrowserRnaPart,
        snackTextGenomeBrowser: state[moduleGenomeBrowser].snackTextGenomeBrowserRnaPart,
    }
}

export default connect(mapStateToProps, {submitCheckedTracksRnaPart, clearGenomeBrowserRnaPartURL})(RNApartProfileBasic);


// l[l.indexOf(l.slice(-1)[0])] = 
// l[l.indexOf(l.slice(-1)[0])] - 100 + parseInt((parseInt('')-(l[l.indexOf(l.slice(-1)[0])]-100))/2)