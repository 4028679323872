import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
// import classNames from 'classnames';
// import Button from '@material-ui/core/Button';
// import { Link } from 'react-router-dom'
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import WarningMessage from '../components/MessageSnackbar';
import MetadataBarPlot from '../components/plots/MetadataBarPlot';
import MetadataPiePlot from '../components/plots/MetadataPiePlot';
import Metadata2Dhistogram from '../components/plots/Metadata2Dhistogram';
import TableMetadata from '../components/TableMetadata';
import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';

import { fetchExpTable, fetchTableAnnotationRNA, fetchTableProcessingMetada, fetchMetadaPlots, handleHeader } from '../actions/generalActions';

const moduleMenu = "menu";
const module = "table";
const moduleFilter = "filter";

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.mixins.gutters(),
        // paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(1),
        //width: '40%',
        textAlign: 'center',
        paddingTop: '50px !important',
        //margin: '0 auto',
        //marginTop: 60,
        //marginLeft: 100,
        //marginRight: 100,
    },
    root2: {
        ...theme.mixins.gutters(),
        paddingBottom: theme.spacing(1),
        textAlign: 'center',
        paddingTop: theme.spacing(1),
    },
    locationCenter: {
        margin: '0 auto',
    },
    locationCenterDetails: {
        margin: '0 auto',
    },
    locationLeft: {
        marginTop: 55,
        textAlign: 'left',
    },
    locationTop: {
        marginTop: 70,
        textAlign: 'center !important',
        marginBottom: 50,
    },
      grow: {
        flexGrow: 1,
      },
    divider: {
        marginTop: 10,
        width: '100%',
        height: 2,
        backgroundColor: 'black',
      },
}))


const MetadataPage = (props) => {

    const [dataFromLocalStorage, setDataFromLocalStorage] = useState({})
    // console.log('dataFromLocalStorage',dataFromLocalStorage)
    useEffect(() => {
        const metadataExp = JSON.parse(localStorage.getItem("metadata"));
        document.title = `Metadata of Exp.ID: ${metadataExp.selectedExperiment}`;
        // console.log('metadataExp', metadataExp)
        setDataFromLocalStorage(metadataExp)
        props.handleHeader(`: metadata of Exp.ID: ${metadataExp.selectedExperiment}`)
        props.fetchExpTable(metadataExp.selectedExperiment); //props.logged
        props.fetchTableAnnotationRNA(metadataExp.selectedExperiment)
        props.fetchTableProcessingMetada(metadataExp.selectedExperiment)
        if (metadataExp.dataType !== "One vs all") {
            // console.log('props.fetchMetadaPlots 1111')
            props.fetchMetadaPlots(metadataExp.selectedExperiment, 'norm_value')
        }
        setNormalization('Normalized')
        setSelectedExperiment(parseInt(metadataExp.selectedExperiment))
    }, [])

    const [selectedExperiment, setSelectedExperiment] = useState(0)
    const [normalization, setNormalization] = useState('')

    useEffect(() => {
        // console.log('props.trackListCheckbox=',props.trackListCheckbox)
        // console.log('selectedExperiment',selectedExperiment)
        if (JSON.parse(localStorage.getItem("metadata")).dataType !== "One vs all") {
            // console.log('props.fetchMetadaPlots')
            props.fetchMetadaPlots([selectedExperiment], props.trackListCheckbox)
        }
        if (props.trackListCheckbox === 'norm_value') {
            setNormalization('normalized')
        } else if (props.trackListCheckbox === 'observed_value') {
            setNormalization('raw')
        } else {
            setNormalization('Ups..')
        }
    },[props.trackListCheckbox])

    const { data, annotationRNA, processingMetadata, MetadataPlots } = props;
    
    const classes = useStyles();

    
    const labelProcessing = (label) => {

        let new_label = ''
        if (label === 'id') {
            new_label = 'Experiment ID'
        } else if (label === 'organism') {
            new_label = 'Organism'
        } else if (label === 'tissue') {
            new_label = 'Tissue'
        } else if (label === 'cellType') {
            new_label = 'Cell type'
        } else if (label === 'typeOfExperiment') {
            new_label = 'Data type'
        } else if (label === 'meth') {
            new_label = 'Method'
        } else if (label === 'name') {
            new_label = 'Reference' //Article name
        } else if (label === 'genome_version') {
            new_label = 'Genome version'
        } 
        else if (label === 'geo') {
            new_label = 'GEO'
        } else if (label === 'extra_information') {
            new_label = 'Extra information'
        } else {
            new_label = label
        } 
        return new_label
    }
    
    // console.log('data_table_value',data)
    let data_table_value = []
    let data_table_column = [] 
    if (Object.keys(data).indexOf('x') === -1) {
        for (var key in data[0]){
            data_table_value.push(data[0][key]);
            data_table_column.push(labelProcessing(key))
        }
    }   else {
        let data_2 = data['z'][0]
        let columns_2 = data['x']
        for (var key2 in columns_2){
            // console.log('data_table_value key',key)
            data_table_value.push(data_2[columns_2[key2]]);
            data_table_column.push(columns_2[key2])
        }
    }    
    data_table_value=[data_table_value]

    let processing_metadata_column = ['Processing steps','N reads', 'Percentage']
    // const [generalMetadata, setGeneralMetadata] = useState({});
    // let generalMetadata = {};
    // useEffect(() => {
    //     if (typeof(processingMetadata['processingMetadata']) !== "undefined") {
    //         console.log('processingMetadata[processingMetadata]',processingMetadata['processingMetadata'])
    //         processingMetadata['processingMetadata'].map((meta, i) => {
    //             console.log('Object.keys(generalMetadata)',Object.keys(generalMetadata))
    //             let ololo = meta[0]
    //             console.log('meta',meta, ololo)
    //             if (Object.keys(generalMetadata).indexOf(ololo) === -1) {
    //                 // setGeneralMetadata({[meta[0]]: []});
    //                 generalMetadata[ololo] = []
    //             }
    //             // let newelement = {'Processingstages': meta[1], 'Nreads': meta[2], 'Percentage': meta[3]}
    //             generalMetadata[meta[0]].push({
    //                 'Processingstages': meta[1], 
    //                 'Nreads': meta[2],
    //                 'Percentage': meta[3]
    //             });
    //             // setGeneralMetadata({
    //             //     ololo: [...generalMetadata.ololo, newelement]
    //             //   })
    //         })
    //     }
    // }, [processingMetadata])

    // console.log('processingMetadata',processingMetadata )
    // console.log(props.loadingProcessingMetadata || processingMetadata.length === 0)
    // console.log(props.loadingProcessingMetadata)
    // console.log(processingMetadata.length === 0)




    let new_data_table_column = ["Name", "Description"];
    let new_data_table_data = [];

    
    data_table_value[0].map((v, i) => {
        let data = [];
        data.push(data_table_column[i]);
        data.push(v);
        new_data_table_data.push(data);
    });

    let new_data_table = []

    for (var i = 0; i < new_data_table_data.length; i++) {
        let dict = {
                    "Name": new_data_table_data[i][0],
                    "Description": new_data_table_data[i][1]
                    }
        new_data_table.push(dict)
    }

    let new_data_table_annotation_column = ["Gene (RNA) type", "RNAs in the experiment", "Annotated RNAs"]
    const [new_data_table_annotation,setNew_data_table_annotation] = useState([])

    useEffect(() => {
        if (typeof(annotationRNA['z']) !== "undefined") {
            for (var i = 0; i < annotationRNA['z'].length; i++) {
                let dict = {
                            "Gene(RNA)type": annotationRNA['z'][i][0],
                            "RNAsintheexperiment": annotationRNA['z'][i][1],
                            "AnnotatedRNAs": annotationRNA['z'][i][2]
                            }
                setNew_data_table_annotation(rows => [...rows, dict])
            }
        }
        // console.log('new_data_table_annotation',new_data_table_annotation)
    }, [annotationRNA['z']])
    
    // console.log('new_data_table_annotation.length',new_data_table_annotation.length)
    // console.log('props.loadingAnnotationRNA',props.loadingAnnotationRNA)
    
    return (
            <div>
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={6} className={classes.locationCenter}> 
                    {!props.loading ?
                        <div>
                        <TableMetadata 
                            title={'All metadata information on a particular experiment:'} 
                            data={new_data_table} 
                            columns={new_data_table_column}
                        />
                        </div>
                        :
                        <div> 
                            <LinearProgress color={"primary"} />
                            <Typography variant="h5">
                                Please, wait a while, it may take from a few seconds to a couple of minutes
                            </Typography>
                            <WarningMessage open={props.snackOpen} severity={"error"} message={props.snackText} />
                        </div>
                    }
                    </Grid>
                    <Grid item xs={6} className={classes.locationCenter}>
                        {props.loadingAnnotationRNA || new_data_table_annotation.length === 0 ?
                            <div> 
                                <LinearProgress color={"primary"} />
                                <Typography variant="h5">
                                    Please, wait a while, it may take from a few seconds to a couple of minutes
                                </Typography>
                                <WarningMessage open={props.snackOpenAnnotationRNA} severity={"error"} message={props.snackTextAnnotationRNA} />
                            </div> 
                            :
                            <div> 
                                <TableMetadata 
                                    title={'RNAs distribution:'} 
                                    data={new_data_table_annotation} 
                                    columns={new_data_table_annotation_column}
                                />
                            </div>
                        }
                    </Grid> 
                </Grid>
                <Divider className={classes.divider} />
                {dataFromLocalStorage.dataType === "One vs all" ?
                null
                :
                <div style={{margin: 25, border: '1px solid #d3d4d5'}}>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <Typography variant="h4" style={{marginBottom: 10, marginTop: 10, textAlign: "right"}}>
                                Analytical plots
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{textAlign: "left", marginLeft: 5, marginTop: 10}}>
                                <TrackListCheckboxesNew disabled={props.loadingMetadataPlots || props.MetadataPlots.length === 0}/>
                            </div>
                        </Grid>
                    </Grid>
                    {props.loadingMetadataPlots || props.MetadataPlots.length === 0 ? 
                    <div> 
                        <LinearProgress color={"primary"} />
                        <Typography variant="h5">
                            Please, wait a while, it may take from a few seconds to a couple of minutes
                        </Typography>
                        <WarningMessage open={props.snackOpenMetadataPlots} severity={"error"} message={props.snackTextMetadataPlots} />
                    </div>
                    :
                    <Grid container spacing={0} className={classes.root2}>
                        <Grid item xs={6} className={classes.locationCenter}> 
                            <div >
                                <MetadataPiePlot data={MetadataPlots['gene_type_contacts']}/>
                                <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                                            <ul>
                                                <li>This bar chart displays only those RNA types that have a proportion {'>'} 0.5% of the total number of contacts.</li>
                                                <li>Normalization: <b>{normalization}</b></li>
                                            </ul>
                                        </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} className={classes.locationCenter}>
                            <Metadata2Dhistogram data={MetadataPlots['number_of_contacts']}/>
                            <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                                <ul>
                                    <li>This scatter plot displays the distribution of RNAs based on the number of contacts they make with chromatin.</li>
                                    <li>Normalization: <b>{normalization}</b></li>
                                    <li><b>RNAs were grouped by the number of contacts multiple of 10</b>. For example, the first point in the left on the scatter plot (without logarithm) is from 0 (not including) to 10 contacts (including), the second is from 10 (not including) to 20 contacts (including), etc.</li>
                                    <li><b>The vertical dotted line</b> is drawn slightly to the right of <b>the median number of contacts</b>.</li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>
                    }
                </div>
                }
                <Divider className={classes.divider} />
                {props.loadingProcessingMetadata || processingMetadata.length === 0 ?
                    <div> 
                        <LinearProgress color={"primary"} />
                        <Typography variant="h5">
                            Please, wait a while, it may take from a few seconds to a couple of minutes
                        </Typography>
                        <WarningMessage open={props.snackOpenProcessingMetadata} severity={"error"} message={props.snackTextProcessingMetadata} />
                    </div>
                :   
                    <div>
                        <Typography variant="h4" style={{marginBottom: 10, marginTop: 10, textAlign: "center"}}>Summary statistics of the data processing protocol:</Typography>
                        {Object.keys(processingMetadata['processingMetadata']).map((metadata, i) => {
                            return (
                            <Grid container spacing={0} className={classes.root2} key={i}>
                                <Grid item xs={6} className={classes.locationCenter}> 
                                    <div style={{marginBottom: 10}}>
                                        <TableMetadata 
                                            title={`Processing data for ${metadata}`} 
                                            data={processingMetadata['processingMetadata'][metadata][0]} 
                                            columns={processing_metadata_column}
                                        />
                                        <Typography variant="subtitle1" style={{ textAlign: "center"}}>
                                            <i>If the experiment contains multiple replicas, then the analogous summary will be displayed for each. 
                                                The <b>"Processing steps"</b> column lists all data processing steps. 
                                                <b>"N reads"</b> tells us how many reads left after the corresponding processing step and all the previous ones too (one-to-all: n-reads correspond to DNA-parts of contacts; all-to-all: n-reads correspond to RNA-DNA contacts, both RNA- and DNA-parts of which have passed the processing step). 
                                                The last column shows the <b>percentage</b> of reads, processed in a particular stage, in relation to the initial number of reads in the data set.
                                            </i>
                                        </Typography>
                                    </div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{backgroundColor: '#b8b9ba'}}
                                        >
                                            <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>more about processing step:</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#f5f6f7'}}>
                                            {dataFromLocalStorage.dataType === "One vs all" 
                                            ? 
                                                <Typography variant="subtitle1" style={{ textAlign: "justify"}}>
                                                    <ul>
                                                        <li><b>N reads</b> (Raw data) — the initial number of reads in the data set.</li>
                                                        <li><b>N reads</b> (Remove duplicates) — number of reads are left after the removal of possible PCR duplicates.</li> {/* after working out the program "Fastuniq" */}
                                                        <li><b>N reads</b> (Trimmomatic) — number of reads are left after filtering out low-quality reads.</li>
                                                        <li><b>N reads</b> (Mapping) — number of reads are left after unique mapping of reads on the genome.</li>
                                                        <li><b>N reads</b> (BlackList) — number of contacts whose DNA-parts do not intersect with the BlackList annotation.</li>
                                                        <br/>See details of each processing step <a href="/protocol" target="_blank" rel="noopener noreferrer">here</a>.
                                                    </ul>
                                                </Typography>
                                            :
                                                <Typography variant="subtitle1" style={{ textAlign: "justify"}}>
                                                    <ul>
                                                        <li><b>N reads</b> (Raw data) — the initial number of reads in the data set.</li>
                                                        <li><b>N reads</b> (Remove duplicates) — number of reads are left after the removal of possible PCR duplicates.</li> {/* after working out the program "Fastuniq" */}
                                                        <li><b>N reads</b> (Trimmomatic) — number of reads are left after filtering out low-quality reads.</li>
                                                        <li><b>N reads</b> (Mapping) — number of reads are left after unique mapping of reads on the genome.</li>
                                                        <li><b>N reads</b> (CIGAR filter) — number of contacts whose RNA-parts passed the appropriate filtering by the CIGAR field.</li>
                                                        <li><b>N reads</b> (BlackList) — number of contacts whose DNA-parts do not intersect with the BlackList annotation.</li>
                                                        <li><b>N reads</b> (Annotation of RNA-parts of contacts) — number of contacts for which a match was established between RNA-parts and genes from our general gene annotation.</li>
                                                        <li><b>N reads</b> (Singletons) — number of contacts for which no correspondence was established between RNA-parts and genes from our general gene annotation.</li>
                                                        <br/>See details of each processing step <a href="/protocol" target="_blank" rel="noopener noreferrer">here</a>.
                                                    </ul>
                                                    <b>N-reads</b> (BlackList) = <b>N-reads</b> (Annotation of RNA-parts of contacts) + <b>N-reads</b> (Singletons)
                                                    <br/><br/><b>Singletons</b> were not included in the database, but you can download them <a href="http://bioinf.fbb.msu.ru/rna_chrom/download/RNA-chromatin-interactions-data/All-to-all-data/" target="_blank" rel="noopener noreferrer">here</a>.
                                                </Typography> 
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={6} className={classes.locationCenter}>
                                    <MetadataBarPlot procMeta={processingMetadata['processingMetadata'][metadata][0]} />
                                    <Typography variant="subtitle1" style={{ textAlign: "center"}}>
                                        The bar chart shows the percentage of reads, processed in a particular stage, in relation to the initial number of reads in the data set.
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                        })}
                    </div>
                }
            </div>
    );
}


const mapStateToProps = (state) => {
    return {
        data: state[module].expData,
        loading: state[module].loading,
        snackOpen: state[module].snackOpen,
        snackText: state[module].snackText,
        loadingAnnotationRNA: state[module].loadingAnnotationRNA,
        snackOpenAnnotationRNA: state[module].snackOpenAnnotationRNA,
        snackTextAnnotationRNA: state[module].snackTextAnnotationRNA,
        
        processingMetadata: state[module].processingMetadata,
        loadingProcessingMetadata: state[module].loadingProcessingMetadata,
        snackOpenProcessingMetadata: state[module].snackOpenProcessingMetadata,
        snackTextProcessingMetadata: state[module].snackTextProcessingMetadata,

        MetadataPlots: state[module].MetadataPlots,
        loadingMetadataPlots: state[module].loadingMetadataPlots,
        snackOpenMetadataPlots: state[module].snackOpenMetadataPlots,
        snackTextMetadataPlots: state[module].snackTextMetadataPlots,


        // logged: state[moduleMenu].isLogged,
        annotationRNA: state[module].annotationRNA,
        trackListCheckbox: state[moduleFilter].trackListCheckbox,
    }
}

export default connect(mapStateToProps, { fetchExpTable, fetchTableAnnotationRNA, fetchTableProcessingMetada, fetchMetadaPlots, handleHeader })(MetadataPage);