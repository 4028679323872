import * as actionTypes from '../actions/generalActions';

const initialState = {
    // menuOpen: false,
    // isLogged: false,
    // loginOpen: false,
    // loginError: false,
    // loginErrorText: "",
    headerName: "",
}

const menuReducer = (state = initialState, action) => {
    switch(action.type) {
        // case actionTypes.TOGGLE_MENU: 
        //     return {
        //         ...state,
        //         menuOpen: action.newState,
        //     }
        // case actionTypes.LOGIN_SUCCESS:
        //     return {
        //         ...state,
        //         // loginOpen: false,
        //         isLogged: true,
        //         loginError: false,
        //     };
        case actionTypes.HANDLE_HEADER:
            return {
                ...state,
                headerName: action.headerName,
            };
        // case actionTypes.LOGOUT:
        //     return {
        //         ...state,
        //         isLogged: false,
        //         loginError: false,
        //     };
        // case actionTypes.IS_LOGGED_SUCCESS:
        //     return {
        //         ...state,
        //         isLogged: action.logged,
        //     };
        // case actionTypes.LOGOUT_SUCCESS:
        //     return {
        //         ...state,
        //         isLogged: false,
        //     };
        // case actionTypes.LOGIN_FAIL:
        //     return {
        //         ...state,
        //         loginError: true,
        //         loginErrorText: action.errorMessage,
        //     };
        default:
            return state
    }
}


export default menuReducer;