import React, { useState, useEffect } from 'react';
import  { useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import { Link } from 'react-router-dom';

import HeatmapsDATA from '../components/plots/Heatmap_data_layout';
// import Profile from '../components/plots/RNA_DNA_profile';
import DNAprofile from '../components/plots/DNAprofile';
import DistanceDistribution from '../components/plots/DistanceDistribution';
import TableRnaListBasic from '../components/TableRnaListBasic';
import DownloadPlotData from '../components/DownloadPlotData';

import {submitCheckedTracksForProfile} from '../actions/plotsActions';
import {submitCheckedTracksForHeatmap,submitCheckedTracksForHeatmapComparative} from '../actions/plotsActions';
import {submitCheckedTracksForDistanceDistribution} from '../actions/plotsActions';
import { handleHeader } from '../actions/generalActions';

import WarningMessage from '../components/MessageSnackbar';
// import TrackListCheckboxes from '../components/TrackListCheckboxes';
import TrackListCheckboxesNew from '../components/TrackListCheckboxesNew';

const module = "genes";
const module_plots = "plots";
const moduleFilter = "filter";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    textAlign: 'center',
    paddingTop: '30px !important',
    marginTop: theme.spacing(3),
    // backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    color: 'black',
  },
  loading: {
    marginTop: 50,
    textAlign: 'center',
  },
  // paper: {
  //   //marginTop: 50,
  //   //margin: '0 auto',
  //   padding: 20,
  //   width: '100%',
  //   textAlign: 'center',
  //   marginBottom: 0,
  // },
  wideButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '90%',
    marginBottom: 25,
    marginTop: 25,
  },
  divider: {
    // margin: '10px auto',
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const BasicGraphicalSummary = (props) => {

  // const [value, setValue] = useState(0);
  const [trackNameAndColor, setTrackNameAndColor] = useState({})
  const [dataFromLocalStorage, setDataFromLocalStorage] = useState({})

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data_basic_graphical_summary"));
    setDataFromLocalStorage(data)
    // console.log('BasicGraphicalSummary data.RNAname',data.RNAname);
    props.handleHeader(': graphical summary')
    if (queryParameters.get("organism") !== null && queryParameters.get("name") !== null) {
      document.title = `Graphical summary, ${queryParameters.get("name")}`      
      props.submitCheckedTracksForProfile(-666,"DNA_part" + "___" + queryParameters.get("name"), queryParameters.get("organism"), "norm_value")
      props.submitCheckedTracksForHeatmapComparative(-666,"DNA_part" + "___" + queryParameters.get("name"), queryParameters.get("organism"), "norm_value")
      props.submitCheckedTracksForDistanceDistribution(-666,"DNA_part" + "___" + queryParameters.get("name"), queryParameters.get("organism"), "norm_value")
    } else {
      document.title = `Graphical summary, ${data.RNAname}`
      // if (data.selectedId === -666) {
      //   document.title = `Graphical summary, ${data.RNAname}`
      // } else {
      //   document.title = `Graphical summary, gene ID = ${data.selectedId}`
      // }
      props.submitCheckedTracksForProfile(data.selectedId,data.typeOfContacts, data.organism, data.selectedNormalization)
      props.submitCheckedTracksForHeatmapComparative(data.selectedId,data.typeOfContacts, data.organism, data.selectedNormalization)
      props.submitCheckedTracksForDistanceDistribution(data.selectedId,data.typeOfContacts, data.organism, data.selectedNormalization)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(props.genomeWideDataProfile).length !== 0 && Object.keys(props.genomeWideDataProfile).indexOf('exps') === -1) {
      let trackNameAndColorDict = {}
      for (var organism in props.genomeWideDataProfile) {
        for (var RNA_track in props.genomeWideDataProfile[organism]) {
          if (RNA_track !== '-111') {
            trackNameAndColorDict[props.genomeWideDataProfile[organism][RNA_track]['name'].split('|*|')[0]] = props.genomeWideDataProfile[organism][RNA_track]['color']
          }
        }
      }
      // console.log('trackNameAndColorDict',trackNameAndColorDict)
      setTrackNameAndColor(trackNameAndColorDict)
    }
  }, [props.genomeWideDataProfile])

  useEffect(() => {
    props.submitCheckedTracksForProfile(dataFromLocalStorage.selectedId, dataFromLocalStorage.typeOfContacts, dataFromLocalStorage.organism, props.trackListCheckbox)
    props.submitCheckedTracksForHeatmapComparative(dataFromLocalStorage.selectedId, dataFromLocalStorage.typeOfContacts, dataFromLocalStorage.organism, props.trackListCheckbox)
    props.submitCheckedTracksForDistanceDistribution(dataFromLocalStorage.selectedId, dataFromLocalStorage.typeOfContacts, dataFromLocalStorage.organism, props.trackListCheckbox)
  },[props.trackListCheckbox])


  const [dataComparativeHeatmap, setDataComparativeHeatmap] = useState({})

  useEffect(() => {
    if (Object.keys(props.genomeWideDataComparative).length !== 0 || Object.keys(props.genomeWideDataComparative).indexOf('exps') !== -1) {
      setDataComparativeHeatmap(props.genomeWideDataComparative)
    } 
  }, [props.genomeWideDataComparative])
  
  // console.log('q1 props.genomeWideDataDistanceDistribution',props.genomeWideDataDistanceDistribution)
  // console.log('q1 dataComparativeHeatmap',dataComparativeHeatmap)
  // console.log('q1 Object.keys(dataComparativeHeatmap).length === 0',Object.keys(dataComparativeHeatmap).length === 0)
  // console.log('q1 Object.keys(dataComparativeHeatmap).indexOf(exps) !== -1',Object.keys(dataComparativeHeatmap).indexOf('exps') !== -1)
  // console.log('q1 props.loadingHeatmapComparative',props.loadingHeatmapComparative)
  // console.log('q1 __________________________')

  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{marginBottom: 10}}>
        <Typography variant="h4">
          <b>Contacts Summary</b>
        </Typography>
        <TableRnaListBasic />
        <Divider className={classes.divider} />
      </div>
      <div style={{marginBottom: 10}}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography style={{textAlign: "right"}} variant="h4">
              <b>Contacts Distribution</b>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {props.loading 
              || Object.keys(props.genomeWideDataProfile).length === 0 
              || Object.keys(props.genomeWideDataProfile).indexOf('exps') !== -1 
              ?
                <Tooltip title="Download plot data">
                  <div style={{textAlign: "center", marginTop: 10}}>
                    <IconButton style={{padding: 0}} disabled={true}>
                      <CloudDownloadIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              :
                // props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]][1]['y'].length === 0 
                Object.keys(props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]]).length === 1
                ?
                  <Tooltip title="Download plot data">
                    <div style={{textAlign: "center", marginTop: 10}}>
                      <IconButton style={{padding: 0}} disabled={true}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                :
                  <Tooltip title="Download plot data">
                    <div style={{textAlign: "center",marginTop: 10}}>
                      <DownloadPlotData data={props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]]}
                                        plot={'ContactsDistribution'}
                        />
                    </div>
                  </Tooltip> 
            }
          </Grid>
          <Grid item xs={5}>
            <div style={{textAlign: "left", marginLeft: 5}}> 
              <TrackListCheckboxesNew disabled={props.loading || props.loadingHeatmapComparative || props.loadingDistanceDistribution || Object.keys(props.genomeWideDataProfile).indexOf('exps') !== -1}/>
            </div>
          </Grid>
        </Grid>
        {props.loading || Object.keys(props.genomeWideDataProfile).length === 0 ?
          <div className={classes.loading}>
            <LinearProgress color={"primary"} />
            <Typography variant="h5">
                Please, wait a while, it may take from a few seconds to a couple of minutes
            </Typography>
          </div>
          :
            Object.keys(props.genomeWideDataProfile).indexOf('exps') === -1 
            ?
              // props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]][1]['y'].length === 0 
              Object.keys(props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]]).length === 1
              ?
                <Typography color="error" variant="h5">
                  Contacts with the selected normalization were not found
                </Typography>
              :
                <div>
                  <DNAprofile from={'basic'} trackNameAndColor={trackNameAndColor}/> {/* <Profile from={'basic'} trackNameAndColor={trackNameAndColor}/> */}
                  <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                    <ul>
                      <li>This bar chart displays genome-wide contacts density distribution (genome bin size = 5000000 bp).</li>
                      <li><b>Y-axis</b>: contacts density.</li>
                      <li><b>X-axis</b>: the chromosomes with which <b>{props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]][Object.keys(props.genomeWideDataProfile[Object.keys(props.genomeWideDataProfile)[0]])[0]]['name'].split("(")[1].split(")")[0]}</b> RNA interact.</li>
                      <li>The chromosome on which the selected RNA gene is encoded is marked in <span style={{color: 'red'}}>red</span>.</li>
                      <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                    </ul>
                  </Typography>
                </div>
            :
              <Typography color="error" variant="h5">
                Contacts of {props.genomeWideDataProfile['exps']} were not found
              </Typography>
        }
        <WarningMessage open={props.snackOpenGenomeWide} severity={"error"} message={props.snackTextGenomeWide} />
        <Divider className={classes.divider} />
      </div>
      <div style={{marginBottom: 10}}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography style={{textAlign: "right"}} variant="h4">
              <b>Comparative Heatmap</b>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {props.loadingHeatmapComparative  
              || Object.keys(dataComparativeHeatmap).length === 0 
              || Object.keys(dataComparativeHeatmap).indexOf('exps') !== -1 
              ? 
                <Tooltip title="Download plot data">
                  <div style={{textAlign: "center", marginTop: 10}}>
                    <IconButton style={{padding: 0}} disabled={true}>
                      <CloudDownloadIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              :
                dataComparativeHeatmap[Object.keys(dataComparativeHeatmap)[0]]['y'].length === 0 
                ?
                  <Tooltip title="Download plot data">
                    <div style={{textAlign: "center", marginTop: 10}}>
                      <IconButton style={{padding: 0}} disabled={true}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                :
                  <Tooltip title="Download plot data">
                    <div style={{textAlign: "center",marginTop: 10}}>
                      <DownloadPlotData data={[
                                          dataComparativeHeatmap[Object.keys(dataComparativeHeatmap)[0]]['x'],
                                          dataComparativeHeatmap[Object.keys(dataComparativeHeatmap)[0]].count[1].map(value => {return "Exp." + value.split('; cell line:')[0]+ "; " + value.split('exp.protocol: ')[1].split('; extra:')[0] + " (" + dataComparativeHeatmap[Object.keys(dataComparativeHeatmap)[0]]['y'][0].split("(")[1].split(")")[0] + ")"}),
                                          dataComparativeHeatmap[Object.keys(dataComparativeHeatmap)[0]]['z']
                                          ]}
                                        plot={'ComparativeHeatmap'}
                        />
                    </div>
                  </Tooltip>
            }
          </Grid>
          <Grid item xs={5}>
            <div style={{textAlign: "left", marginLeft: 5}}> 
              <TrackListCheckboxesNew disabled={props.loading || props.loadingHeatmapComparative || props.loadingDistanceDistribution || Object.keys(dataComparativeHeatmap).indexOf('exps') !== -1}/>
            </div>
          </Grid>
        </Grid>
        {props.loadingHeatmapComparative || Object.keys(dataComparativeHeatmap).length === 0 ?
          <div className={classes.loading}>
            <LinearProgress color={"primary"} />
            <Typography variant="h5">
              Please, wait a while, it may take from a few seconds to a couple of minutes
            </Typography>
          </div>
          :
            Object.keys(dataComparativeHeatmap).indexOf('exps') === -1 
            ?
              dataComparativeHeatmap[Object.keys(dataComparativeHeatmap)[0]]['y'].length === 0 
              ?
                <Typography color="error" variant="h5">
                  Contacts with the selected normalization were not found
                </Typography>
              :
                <div>
                  <HeatmapsDATA 
                    plotType={'Comparative'} 
                    plotType_2={'Basic'} 
                    genomeWideData={dataComparativeHeatmap} 
                    trackNameAndColor={trackNameAndColor}
                  />
                  <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                    <ul>
                      <li>This heatmap displays contacts density distribution over chromosomes.</li>
                      <li><b>Y-axis</b>: track names.</li>
                      <li><b>X-axis</b>: the chromosomes with which <b>{dataComparativeHeatmap[Object.keys(dataComparativeHeatmap)[0]]['y'][0].split("(")[1].split(")")[0]}</b> RNA interact.</li>
                      <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                    </ul>
                  </Typography>
                </div>
            :
              <Typography color="error" variant="h5">
                Contacts of {dataComparativeHeatmap['exps']} were not found
              </Typography>
        }
        <WarningMessage open={props.snackOpenComparative} severity={"error"} message={props.snackTextComparative} />
        <Divider className={classes.divider} />
      </div> 




      <div style={{marginBottom: 10}}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <Typography style={{textAlign: "right"}} variant="h4">
              <b>Distance Distribution</b>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {props.loadingDistanceDistribution 
              || Object.keys(props.genomeWideDataDistanceDistribution).length === 0 
              ?
                <Tooltip title="Download gene-centric line chart data">
                  <div style={{textAlign: "center", marginTop: 10}}>
                    <IconButton style={{padding: 0}} disabled={true}>
                      <CloudDownloadIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              :
                [].concat.apply([], Object.keys(props.genomeWideDataDistanceDistribution['exps']).map(value => {return props.genomeWideDataDistanceDistribution['exps'][value]['y']})).length === 0
                ?
                  <Tooltip title="Download gene-centric line chart data">
                    <div style={{textAlign: "center", marginTop: 10}}>
                      <IconButton style={{padding: 0}} disabled={true}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                :
                  <Tooltip title="Download gene-centric line chart data">
                    <div style={{textAlign: "center",marginTop: 10}}>
                      <DownloadPlotData data={props.genomeWideDataDistanceDistribution['exps']}
                                        plot={'Gene-centric DistanceDistribution'}
                        />
                    </div>
                  </Tooltip> 
            }
          </Grid>
          <Grid item xs={1}>
            {props.loadingDistanceDistribution 
              || Object.keys(props.genomeWideDataDistanceDistribution).length === 0 
              ?
                <Tooltip title="Download non gene-centric line chart data">
                  <div style={{textAlign: "center", marginTop: 10}}>
                    <IconButton style={{padding: 0}} disabled={true}>
                      <CloudDownloadIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              :
                [].concat.apply([], Object.keys(props.genomeWideDataDistanceDistribution['exps']).map(value => {return props.genomeWideDataDistanceDistribution['exps'][value]['y']})).length === 0
                ?
                  <Tooltip title="Download non gene-centric line chart data">
                    <div style={{textAlign: "center", marginTop: 10}}>
                      <IconButton style={{padding: 0}} disabled={true}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                :
                <Tooltip title="Download non gene-centric line chart data">
                  <div style={{textAlign: "center",marginTop: 10}}>
                    <DownloadPlotData data={props.genomeWideDataDistanceDistribution['exps']}
                                      plot={'non Gene-centric DistanceDistribution'}
                      />
                  </div>
                </Tooltip> 
            }
          </Grid>
          <Grid item xs={5}>
            <div style={{textAlign: "left", marginLeft: 5}}> 
              <TrackListCheckboxesNew disabled={props.loading || props.loadingHeatmapComparative || props.loadingDistanceDistribution }/>
            </div>
          </Grid>
        </Grid>
        {props.loadingDistanceDistribution || Object.keys(props.genomeWideDataDistanceDistribution).length === 0 ?
          <div className={classes.loading}>
            <LinearProgress color={"primary"} />
            <Typography variant="h5">
              Please, wait a while, it may take from a few seconds to a couple of minutes
            </Typography>
          </div>
          :
            Object.keys(props.genomeWideDataDistanceDistribution['exps']).indexOf('exps') === -1 ?
              <div>
                <DistanceDistribution from={'basic'} trackNameAndColor={trackNameAndColor}/>
                <Typography variant={"subtitle1"} style={{ textAlign: "justify"}}>
                  <ul>
                    <li>This line chart displays the contact density depending on the distance between the RNA gene and chromatin target loci (on the same chromosome as the corresponding RNA gene). The interaction of RNA with its source gene is not included in the analysis.</li>
                    <li><b>Y-axis</b>: Log2(Density).</li>
                    <li><b>X-axis</b>: Log2(Distance between <b>{props.genomeWideDataDistanceDistribution['exps'][Object.keys(props.genomeWideDataDistanceDistribution['exps'])[0]]['count'][0][0].split("(")[1].split(")")[0]}</b> gene and loci).</li>
                    <li>See details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>.</li>
                  </ul>
                </Typography>
              </div>
            :
              <Typography color="error" variant="h5">
                Contacts of {props.genomeWideDataDistanceDistribution['exps']['exps']} were not found
              </Typography>
            
          }
        <WarningMessage open={props.snackOpenDistanceDistribution} severity={"error"} message={props.snackTextDistanceDistribution} />
      </div>
      <Divider className={classes.divider} />
      <Typography variant={"subtitle1"} style={{marginLeft: 10,  padding: 5}}>
        If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
      </Typography>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      //trackList: state[module].trackList,
      selectedTracks: state[module].selectedTracks,
      typeOfContacts: state[module].typeOfContacts,
      
      snackOpenGenomeWide: state[module].snackOpenGenomeWide,
      snackTextGenomeWide: state[module].snackTextGenomeWide,
      loading: state[module_plots].loadingGenomeWideData,

      genomeWideData: state[module_plots].genomeWideData,
      snackOpen: state[module_plots].snackOpen,
      snackText: state[module_plots].snackText,
      loadingHeatmap: state[module_plots].loadingGenomeWideHeatmap,

      genomeWideDataProfile: state[module_plots].genomeWideDataProfile,

      genomeWideDataComparative: state[module_plots].genomeWideDataComparative,
      snackOpenComparative: state[module_plots].snackOpenComparative,
      snackTextComparative: state[module_plots].snackTextComparative,
      loadingHeatmapComparative: state[module_plots].loadingGenomeWideHeatmapComparative,

      genomeWideDataDistanceDistribution: state[module_plots].genomeWideDataDistanceDistribution,
      // snackOpenDistanceDistribution: state[module].snackOpenDistanceDistribution,
      // snackTextDistanceDistribution: state[module].snackTextDistanceDistribution,
      loadingDistanceDistribution: state[module_plots].loadingDistanceDistribution,

      trackListCheckbox: state[moduleFilter].trackListCheckbox,
  }
}

// const GraphicalSummaryContainer = connect(mapStateToProps, {submitCheckedTracksForHeatmap,submitCheckedTracksForProfile,submitCheckedTracksForDistanceDistribution})(GraphicalSummary);
// export default GraphicalSummaryContainer;
export default connect(mapStateToProps, {submitCheckedTracksForHeatmap,submitCheckedTracksForHeatmapComparative,
  submitCheckedTracksForProfile,submitCheckedTracksForDistanceDistribution, handleHeader})(BasicGraphicalSummary);