import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
const stableSort = (array, cmp) =>{
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  

const TableHeader = (props) => {
  
  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };
  
  const { order, orderBy, columns} = props;
  const classes = useStyles();
  const [rows, setRows] = useState([])
  // console.log('rows',rows)
  useEffect(() => {  
    if (columns.length !== 0) {
      setRows([])
      for (var numberOfcolumns = 0; numberOfcolumns < columns.length; numberOfcolumns ++) {
        let value = columns[numberOfcolumns]
        if ( value !== 'RNA_profile') {
          setRows(rows => [...rows, { id: value.replace(/\s+/g, ''), numeric: false, disablePadding: false, label: value}])
        }
      }
    }
  }, [columns])  

  let headCell = null
  let headCells = null
  if (columns.indexOf('RNA_profile') === -1) {
    headCells=
    <TableHead>
      <TableRow > 
        {rows.map(row => {
            headCell =
            <TableCell
              className={classes.sizeSmall}
              style={{color: 'white', backgroundColor: '#009688', fontSize: 15, border: '1px solid #d3d4d5',borderTop: '1px solid #d3d4d5',borderLeft: '1px solid #d3d4d5'}} //,whiteSpace: 'nowrap'
              key={row.id}
              // size="small"
              align='center'
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
            <TableSortLabel
                active={orderBy === row.id}
                direction={orderBy === row.id ? order : 'asc'}
                onClick={createSortHandler(row.id)}
            >
                {row.label}
              </TableSortLabel>
          </TableCell>
          
          return (
            headCell
          );
        })}
      </TableRow>
    </TableHead>

  }
  return (
    headCells
  );
}
  
TableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      textAlign: 'center',
      flexGrow: 1,
      // paddingTop: "10px !important",
      
    },
    container: {
      maxHeight: window.innerHeight*0.75,
    },
    table: {
      minWidth: 50,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    loading: {
      marginTop: 5,
      textAlign: 'center',
    },
    sizeSmall: {
      padding: '0px 5px 0px 5px',
    },
}));

const TableMetadata = (props) => {

  const [order,setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('Obtaining data method')
  const classes = useStyles();  
  const {title, data, columns} = props;    

  // console.log('title, data, columns}',title, data, columns)  
  const handleRequestSort = (event, property) => {
        const const_orderBy = property;
        let let_order = 'desc';
        if (orderBy === property && order === 'desc') {
        let_order = 'asc';
        }
        setOrder(let_order)
        setOrderBy(const_orderBy)
    };

    let column = null

    return (
        <div className={classes.root}>
                <div style={{margin: '0 auto', border: '1px solid rgb(224, 224, 224)'}}>
                    <div className={classes.tableWrapper}>
                        {title !== '' ? 
                          <Toolbar variant="dense">
                            <Typography variant="h6">
                                {title}
                            </Typography>
                          </Toolbar>
                        :
                          null
                        }
                        <TableContainer className={classes.container}>
                            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={'small'}>
                                <TableHeader
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    columns={columns}
                                />
                                <TableBody>
                                    {stableSort(data, getSorting(order, orderBy))
                                    .map((row, indexL) => {
                                        return (
                                        <TableRow 
                                            hover 
                                            tabIndex={-1} 
                                            key={indexL} 
                                            size='small'
                                            className={classes.sizeSmall}
                                        >
                                            {columns.map((key, index) => {
                                              // console.log('qqqqqq',row,key)
                                              if (key !== 'RNA_profile') {
                                                if (index === 0) {
                                                  column=
                                                  <TableCell 
                                                      key={index}
                                                      align="left" 
                                                      className={classes.sizeSmall}
                                                      style={{fontSize: 15, 
                                                              whiteSpace: 'nowrap', 
                                                              // backgroundColor: cellColor,
                                                              border: '1px solid #000000',
                                                              borderTop: '1px solid #000000',
                                                              borderLeft: '1px solid #000000', //#d3d4d5
                                                          }} 
                                                  >
                                                      <b>{row[key.replace(/\s+/g, '')]}</b>
                                                  </TableCell>
                                                } else {
                                                  // console.log('row[key]',row[key.replace(/\s+/g, '')])
                                                  column=
                                                  <TableCell 
                                                      key={index}
                                                      align="left" 
                                                      className={classes.sizeSmall}
                                                      style={{fontSize: 15, 
                                                              // whiteSpace: 'nowrap', 
                                                              // backgroundColor: cellColor,
                                                              border: '1px solid #000000',
                                                              borderTop: '1px solid #000000',
                                                              borderLeft: '1px solid #000000',
                                                          }} 
                                                  >
                                                      {row[key.replace(/\s+/g, '')]}
                                                  </TableCell>
                                                }
                                              return (
                                                  column
                                              );
                                              }
                                            }
                                            )}
                                        </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>    
                    </div>
                </div>
        </div>
    );
}

export default TableMetadata