import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import ReactPlayer from 'react-player/youtube'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom'
// import Button from '@material-ui/core/Button';

import { handleHeader } from '../actions/generalActions';

// const module = "table"

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingBottom: theme.spacing(2),
        flexGrow: 1,
        marginTop: theme.spacing(7),
    },
    divider: {
        width: '100%',
        height: 2,
        backgroundColor: 'black',
      },
    // leftIcon: {
    // marginLeft: theme.spacing(),
    // marginRight: theme.spacing(),
    // color: 'white',
    // '&:hover': {
    //     textDecoration: 'none',
    //     color: 'grey',
    // },
    // //textAlign: 'right',
    // },
}));

const Tutorial = (props) => {

  const classes = useStyles();
  document.title = "Tutorial";

  useEffect(() => {
    props.handleHeader(': tutorial')
  }, [])

  const handleClick = (event) => {
    window.open("/protocol", "_blank");
  };

  return (
    <div className={classes.root}>
        <TableContainer style={{maxHeight: window.innerHeight*0.9}}>
            <Typography variant="h5" style={{marginTop: 10, textAlign: "center"}}>
                <b>"From RNA" analysis</b>
            </Typography>
            <Grid container spasing={0} style={{marginTop: 20}}>
                <Grid item xs={6}>
                    <Typography variant="h6" style={{textAlign: "center"}}>
                        <b>Basic</b>
                    </Typography>
                    <TableContainer style={{maxHeight: window.innerHeight*0.6}}>
                        <Typography variant="subtitle1">
                            &emsp;Our gene annotation consists of 232'870 human and 88'914 mouse genes. Using the web-interface, a user can analyse the contacts of any RNA from these subsets.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 1.</b></span> Choose <b>"from RNA"</b> analysis on the <Link target="_blank" to="/">start page</Link>.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 2.</b></span> On the page <Link target="_blank" to="/from_rna">"RNA-Chrom: where does the selected RNA contact with chromatin?"</Link> on the right, in the <b>"Select RNA"</b> field, enter the RNA name you are interested in (for example, <b>NR2F1-AS</b>, <i>Homo sapiens</i>). Click on the RNA name in the drop-down list.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 3a.</b></span> Click on the <b>"GRAPHICAL SUMMARY"</b> button. The "RNA-Chrom: graphical summary" page will open in a new tab, that will contain <b>analytical interactive plots</b> and <b>various additional options for further analysis</b> (see details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>).
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 3b.</b></span> If you are interested to see the <b>Complete table of RNAs</b> (with contact metrics) and to choose an RNA from it for further analysis, click on the <b>"BROWSE ALL RNAS"</b> button.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 4.</b></span> As an example, fill the filters "Select RNA types", "Minimum gene length" and "Organism" with the values <b>Xrna</b>, <b>1000</b> and <b><i>Mus musculus</i></b>, respectively. Then click on the <b>"APPLY FILTERS"</b> button.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 5.</b></span> To go to the <b>"Graphical Summary"</b> page, click on the RNA name you are interested in (for example, <b>X_17_3984_a_mm10</b>, since this RNA has the largest CPKM).
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 6.</b></span> In the "Contacts Summary" table choose, for example, <b>"Exp.ID: 14"</b> (RADICL, mES R08) and click on the <b>"ALL TARGET GENES"</b> button.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 7.</b></span> Use filters to get the final list of genes that can be downloaded for further analysis, such as <b>Gene Ontology</b>.
                        </Typography>
                    </TableContainer>
                </Grid>
                <Grid item xs={6} style={{marginBottom: 5}}>
                    <Typography variant="h6" style={{textAlign: "center"}}>
                        <b>Advanced</b>
                    </Typography>
                    <div style={{marginLeft: 5, border: '2px solid #000000'}}>
                        {/* <ReactPlayer url='https://youtu.be/MooTqyZL9tQ' />  */}
                        <ReactPlayer url='https://youtu.be/O7g7myhPsy0' />
                    </div>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Typography variant="h5" style={{marginTop: 20, textAlign: "center"}}>
                <b>"From DNA" analysis</b>
            </Typography>
            <Grid container spasing={0} style={{marginTop: 20}}>
                <Grid item xs={6}>
                    <Typography variant="h6" style={{textAlign: "center"}}>
                        <b>Basic</b>
                    </Typography>
                    <TableContainer style={{maxHeight: window.innerHeight*0.6}}>
                        <Typography variant="subtitle1">
                            &emsp;<span style={{color: 'red'}}><b>Step 1.</b></span> If you have genomic coordinates of a locus and want to know what RNAs contact with it, сhoose <b>"from DNA"</b> analysis on the <Link target="_blank" to="/">start page</Link>.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 2.</b></span> On the page <Link target="_blank" to="/from_dna">"RNA-Chrom: what RNAs contact with the selected target locus?"</Link> on the right click on the <b>"CHOOSE A DNA LOCUS"</b> button.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 3.</b></span> Select the organism <b><i>M.musculus</i></b>, enter the approximate coordinates of the <b> HoxA-cluster (chr6:52015389-52270886)</b> and click on the <b>"APPLY"</b> button.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 4.</b></span> As an example, fill the filters "Select RNA types" and "Select annotation source" with the values <b>lincRNA</b> and <b>gencode</b>, respectively. click on the <b>"APPLY FILTERS"</b> button.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 5.</b></span> Choose normalization <b>"Norm. & in peaks (one-to-all)"</b> and sort the table by the column <b>"max n-reads"</b> (one-to-all).
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 6.</b></span> To go to the <b>"Graphical Summary"</b> page, click on the RNA name you are interested in (for example, <b>Halr1</b>, since this RNA is at the top of the table and is also known to be involved in modulating HoxA induction [<a href="https://pubmed.ncbi.nlm.nih.gov/25891907/" target="_blank" rel="noopener noreferrer">PMID: 25891907</a>]).
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 7.</b></span> The "RNA-Chrom: graphical summary" page will open in a new tab, which will contain <b>analytical interactive plots</b> and <b>various additional options for further analysis</b> (see details on the <a href="/overview#Available-types-of-data-analysis" target="_blank" rel="noopener noreferrer">"Overview" page</a>).
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 6.</b></span> In the "Contacts Summary" table choose, for example, <b>"Exp.ID: 102"</b> (ChIRP, 46C ES, treatment: LIF withdrawal & 2uM RA - day 1), choose normalization <b>"Norm. & in peaks (one-to-all)"</b> and click on the <b>"VIEW IN GENOME BROWSER"</b> button.
                            <br/>&emsp;<span style={{color: 'red'}}><b>Step 9.</b></span> <b>UCSC Genome Browser</b> will open in a new tab. 
                        </Typography>
                    </TableContainer>
                </Grid>
                <Grid item xs={6} style={{marginBottom: 5}}>
                    <Typography variant="h6" style={{textAlign: "center"}}>
                        <b>Advanced</b>
                    </Typography>
                    <div style={{marginLeft: 5, border: '2px solid #000000'}}>
                        <ReactPlayer url='https://youtu.be/RFWlzayrsNo' /> 
                    </div>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Typography variant="h5" style={{marginTop: 20, textAlign: "center"}}>
                <b>"Content" and "Overview" pages</b>
                {/* <span style={{background: 'yellow'}}></span> */}
            </Typography>
            <TableContainer style={{maxHeight: window.innerHeight*0.6}}>
                <Typography variant="subtitle1" style={{marginTop: 20,marginBottom: 10}}>
                    &emsp;&emsp;The <b>"Content" page</b> is a table with complete meta-information for all experiments from the RNA-chrom database. 
                    Here you can download data for each experiment (contacts with all normalizations, singletons, peaks, etc.). 
                    To find out detailed information on a particular experiment, you should click on the corresponding "Exp.ID" and go to the "Metadata" page.
                    To go to this page, click on the <b>"DATABASE CONTENT" button</b> in the site header.
                    <br/>&emsp;&emsp;The <b>"Overview" page</b> describes the contents of the RNA-Chrom database and the functionality in detail: what is available to the user, what formulas were used to preprocess data for graphs and tables, and what can we extract from these graphs and tables?
                    To go to this page, click on the <b>"OVERVIEW" button</b> in the site header.
                </Typography>
            </TableContainer>
        </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        // loadingAllExps: state[module].loadingAllExps,
        // AllExps: state[module].AllExps,
        // snackOpenAllExps: state[module].snackOpenAllExps,
        // snackTextAllExps: state[module].snackTextAllExps,
    }
  }
  
export default connect(mapStateToProps, { handleHeader })(Tutorial);