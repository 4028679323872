import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import {fetchExpTable} from '../actions/generalActions';
import { handleGeneSelect, handleGeneTypesSelect } from '../actions/genesActions';
import { handleOrganismSelect, changeCurrentOrganismInput } from '../actions/organismActions'; //fetchOrganism
import { handleGeneSelectForGeneBodyFilter, handleGeneTypesSelectForGeneBodyFilter, 
         handleGeneSelectForUpstreamFilter, handleGeneTypesSelectForUpstreamFilter, 
         handleGeneSelectForDownstreamFilter, handleGeneTypesSelectForDownstreamFilter,
         handleGeneSelectForUpstream50kbFilter, handleGeneTypesSelectForUpstream50kbFilter,
         handleGeneSelectForDownstream50kbFilter, handleGeneTypesSelectForDownstream50kbFilter,
         currentLocusFilterDnaChange } from '../actions/filterActions';


import { clearContacts, clearRNACheckboxes } from '../actions/filterActions';
import { clearRNAGenes } from '../actions/genesActions';
import { clearRNAText } from '../actions/generalActions';

const moduleMenu = "menu";
const module = "table";
const moduleGenes = "genes";


const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
    minHeight: 10,
    height: 40,
    padding: 0,
  },
  root: {
    flexGrow: 1,
    // paddingTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
  },
  input: {
    '.MuiOutlinedInput-input' : {
        // padding: '8px 8px',
        padding: 0,
    }
  },
  smallPadding: {
    height: 30,
    paddingTop: '5px !important',
    paddingRight: '110px !important',
    paddingBottom: '5px !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
}));

const OrganismSelectorSimple = (props) => {
  
    const classes = useStyles();
    // const [item, setItem] = useState(props.selectedItemOrganism[0]['label'])
    const [item, setItem] = useState('Homo sapiens')
    
    
    // useEffect(() => {
    //   console.log('www2 props.currentOrganism',props.currentOrganism)
    //   if (typeof(props.currentOrganism) !== "undefined") {
    //     setItem(props.currentOrganism)
    //     props.handleOrganismSelect([{label: props.currentOrganism, value: props.currentOrganism}])
    //   }
    // }, [])

    useEffect(() => {
      // setItem('Homo sapiens');
      // if (props.currentOrganism === "undefined") {
      // console.log('www2 props.selectedItemOrganism[0][label]))))))v))))))))))))))))))))))00',props.selectedItemOrganism[0]['label'], props.selectedItemOrganism[0]['label']==='Mus musculus')
      setItem(props.selectedItemOrganism[0]['label'])
      // }
        // props.handleOrganismSelect([{label: props.organism, value: props.organism}])
    }, [props.selectedItemOrganism])
    // console.log('www2 item',item)
    // console.log('www2 props.selectedItemOrganism',props.selectedItemOrganism)
    
    
    const handleChange = (event) => {
        // console.log('www2 event.target.value',event.target.value)
        setItem(event.target.value);
        props.handleOrganismSelect([{label: event.target.value, value: event.target.value}])
        // if (props.first_working_page === true) {
        //   props.fetchExpTable([{label: event.target.value, value: event.target.value}]) //props.logged
        // }
        //elif basic

        props.clearRNAText();
        props.clearRNAGenes();
        props.clearRNACheckboxes();
        props.clearContacts();
    };
    // console.log('item',item)
    // useEffect(() => {
    //   console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    //   props.fetchOrganism(props.logged);
    //   props.fetchExpTable([{label: item, value: item}], props.logged)
    // }, [])
  // console.log('props.selectedItemOrganism',props.selectedItemOrganism)
    return (
        <div className={classes.root}>
            <FormControl variant="outlined" className={classes.formControl} disabled={props.loadingGenes || props.loadingGeneTypes}>
                {/* <InputLabel id="select-label">Select organism</InputLabel> */}
                <Select
                    value={item}
                    onChange={handleChange}
                    classes={{
                      root: classes.smallPadding,
                      // "MuiInputBase-input": classes.smallPadding
                    }}
                >
                    <MenuItem value={'Homo sapiens'}><i>Homo sapiens</i></MenuItem>
                    <MenuItem value={'Mus musculus'}><i>Mus musculus</i></MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      suggestionTypes: state[module].suggestionTypes,
      //suggestionsFake: state[module].suggestionsFake,
      loadingGeneTypes: state[moduleGenes].loadingGeneTypes,
      loadingGenes: state[moduleGenes].loadingGenes,
      //inputValue: state[module].inputValue,
      selectedItemOrganism: state[module].selectedItemOrganism,
      loadingOrganism: state[module].loadingOrganism, //????
      currentOrganismInput: state[module].currentOrganismInput,    
      // loading: state[module].loading,
      // snackOpen: state[module].snackOpen,
      // snackText: state[module].snackText,
      // logged: state[moduleMenu].isLogged,
    };
  };
  
  export default connect(mapStateToProps, {
    clearRNAGenes, clearRNAText, clearContacts, clearRNACheckboxes,
    handleOrganismSelect, handleGeneSelect, handleGeneTypesSelect, changeCurrentOrganismInput, 
    handleGeneSelectForGeneBodyFilter, handleGeneTypesSelectForGeneBodyFilter, 
    handleGeneSelectForUpstreamFilter, handleGeneTypesSelectForUpstreamFilter, 
    handleGeneSelectForDownstreamFilter, handleGeneTypesSelectForDownstreamFilter,
    handleGeneSelectForUpstream50kbFilter, handleGeneTypesSelectForUpstream50kbFilter,
    handleGeneSelectForDownstream50kbFilter, handleGeneTypesSelectForDownstream50kbFilter,
    currentLocusFilterDnaChange})(OrganismSelectorSimple); //fetchOrganism, fetchExpTable